import React, { useEffect, useState } from "react"

import {
  Button,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Collapse,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import Switch from "@mui/material/Switch"
import modulesList from "src/_mock/memberPermissions"
import { useDispatch } from "react-redux"
import { addMemberType } from "src/redux/actions/addMember"
import { updateProfile } from "src/redux/actions/profile"
import { changeProps } from "find-and"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------

const AddNewMemberModal = ({
  isShowingRole,
  isShowingAddMemberType,
  userPermissionToEdit,
  hide,
  open,
  onClose,
}) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  function RowNew(props) {
    const {
      module,
      handleIndividualValue,
      handleAccordian,
      handleParentValues,
      handleAssociation,
    } = props

    const moduleId = module.id
    const handleInner = (e, moduleId, index) => {
      const { name, checked } = e.target
      handleIndividualValue(moduleId, name, checked, index)
    }

    const handleSelectAll = (e) => {
      const { name, checked } = e.target
      handleParentValues(name, checked)
    }

    // let canEditModulePermissions = false;
    // if (module?.listM && module?.listM.length > 0) {
    //   canEditModulePermissions = module.listM[0].value === true;
    // }
    const [expanded, setExpanded] = useState(false)
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false)
    }

    return (
      <>
        <TableRow
          sx={{
            "& > *": { borderBottom: "unset" },
            justifyContent: "space-around",
            paddingTop: "0",
            paddingBottom: "0",
            borderRadius: "0",
          }}
          className={module.open ? "openClass" : "closedClass"}
        >
          <TableCell
            sx={{
              paddingTop: "5px",
              paddingBottom: "5px",
              borderBottom: "1px solid rgba(241, 243, 244, 1)",
            }}
          >
            <IconButton
              aria-label='expand row'
              size='small'
              expanded={expanded === "panel1"}
              onClick={() => handleAccordian(moduleId, !module.open)}
              sx={{ color: "#475569" }}
            >
              {module.open ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
            <Button
              aria-label='expand row'
              size='medium'
              onClick={() => handleAccordian(moduleId, !module.open)}
              sx={{
                color: "#475569",
                margin: "0px",
                padding: "0px",
                justifyContent: "flex-start",
                "&:hover": {
                  opacity: "0.7",
                  background: "none",
                },
              }}
            >
              {module.name}
            </Button>
          </TableCell>

          {/* <TableCell
            sx={{
              display: "flex",
              borderBottom: "1px solid rgba(241, 243, 244, 1)",
              paddingTop: "5px",
              paddingBottom: "5px",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Switch
              color='info'
              name={module.id}
              value={module.value}
              className='switchCls'
              // checked={userChecked}
              checked={module.value}
              onChange={(e) => handleSelectAll(e)}
            />
          </TableCell> */}
        </TableRow>

        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={6}>
            <Collapse in={module.open} timeout='auto' unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <Table
                  size='small'
                  aria-label='memberType'
                  className='small-table'
                >
                  <TableBody>
                    {module?.listM.map((lisiting, index) => {
                      return (
                        <>
                          <TableRow
                            key={lisiting.listid}
                            className='inner-listing-tr'
                            sx={{ justifyContent: "space-between" }}
                          >
                            <TableCell
                              sx={{ paddingLeft: "3rem", color: "#475569" }}
                            >
                              {t(lisiting.listname)}
                            </TableCell>

                            <TableCell
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Switch
                                color='info'
                                // disabled={
                                //   index > 0 &&
                                //   (lisiting.disabled ||
                                //     !canEditModulePermissions)
                                // }
                                checked={lisiting.value}
                                value={lisiting.value}
                                name={lisiting.listid}
                                onChange={(e) =>
                                  handleInner(e, moduleId, index)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      )
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }

  /******************* */
  const [userModule, setUserModule] = useState([])
  const [user, setUser] = useState({
    name: userPermissionToEdit?.user ? userPermissionToEdit.user : "",
    permission_attributes: "",
  })

  const [error, setError] = useState("")

  const handleModuleList = (data) => {
    let arr = []
    data.forEach((item) => {
      const listM = item.listM.map((i) => ({
        ...i,
        value: userPermissionToEdit ? userPermissionToEdit[i.listid] : false,
      }))
      const obj = {
        listM,
        value: false,
        id: item.id,
        name: t(item.name),
        open: false,
      }
      arr.push(obj)
    })
    return arr
  }

  useEffect(() => {
    if (userPermissionToEdit) {
      const userName = userPermissionToEdit?.user
      setUser({ ...user, name: userName })

      let userModules = [...userModule]

      for (const [key, value] of Object.entries(userPermissionToEdit)) {
        userModules = changeProps(
          userModules,
          { listid: key },
          { value: value },
        )
      }
      setUserModule(userModules)
    }
  }, [userPermissionToEdit])

  useEffect(() => {
    const newModuleList = handleModuleList(modulesList)
    setUserModule(newModuleList)
  }, [])

  const handleIndividualValue = (moduleId, name, checked, index) => {
    let newUserModule = [...userModule]

    const abc = newUserModule.map((item) => {
      if (item.id === moduleId) {
        const isThereAnyEnabled = item.listM.filter((itm) => itm.value === true)

        const listM = item.listM.map((i, indx) => {
          if (name === i.listid || indx === 0) {
            return {
              ...i,
              value:
                indx === 0
                  ? isThereAnyEnabled.length > 2 && index > 0
                    ? true
                    : checked
                  : checked,
            }
          }
          if (index === 0) {
            return { ...i, value: checked, disabled: !checked }
          }
          return { ...i, disabled: false }
        })
        const obj = { ...item, listM }
        return obj
      }
      return item
    })
    setUserModule(abc)
  }

  const handleAccordian = (moduleId, value) => {
    let newUserModule = [...userModule]
    const accordian = newUserModule.map((item) => {
      if (item.id === moduleId) {
        return { ...item, open: value }
      }
      return item
    })
    setUserModule(accordian)
  }

  const handleParentValues = (name, checked) => {
    let newUserModule = [...userModule]
    const abc = newUserModule.map((item) => {
      if (item.id === name) {
        const listM = item.listM.map((i) => {
          return { ...i, value: checked }
        })
        const obj = { ...item, listM, value: checked }
        return obj
      }
      return item
    })
    setUserModule(abc)
  }

  const handleChange = (e) => {
    if (e.target.id === "name") {
      setUser({ ...user, name: e.target.value })
    }
  }

  const assignPermissions = () => {
    if (user.name !== "") {
      let newUserModule = [...userModule]
      let permission_attributes = {}
      newUserModule.forEach((item) => {
        item.listM.forEach((permission) => {
          permission_attributes[permission.listid] = permission.value
        })
      })

      const name = user.name
      const endUser = {
        name,
        permission_attributes,
      }

      if (userPermissionToEdit) {
        dispatch(updateProfile(userPermissionToEdit?.id, endUser))
      } else {
        dispatch(addMemberType(endUser))
      }

      setError("")
      setUser({ ...user, name: "" })
      hide()
    } else {
      setError(t("ErrNameIsRequired"))
    }
  }
  const handleClose = () => {
    hide()
  }

  // test
  const handleAssociation = () => {
    //do something
  }
  return (
    isShowingRole && (
      <Dialog
        className='dialog800'
        onClose={handleClose}
        open={isShowingRole}
        scroll='paper'
        maxWidth='md'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {userPermissionToEdit ? t("UpdateMemberType") : t("AddMemberType")}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          className='dialog-content'
          id='add-memberType-content'
          dividers={true}
          sx={{ paddingRight: "0PX", paddingLeft: "0px" }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Stack
                spacing={3}
                sx={{ paddingRight: "12px", paddingLeft: "12px" }}
              >
                <FormControl
                  sx={{ mb: "0.5rem !important ", mt: "0 !important" }}
                >
                  <FormLabel htmlFor='name' required>
                    {t("MemberType")}
                  </FormLabel>
                  <TextField
                    fullWidth
                    disabled={user?.name?.toLowerCase() === "user"}
                    sx={{ marginTop: "1vh" }}
                    id='name'
                    value={user.name}
                    onChange={(e) => handleChange(e)}
                    name='add-member-type'
                    type='text'
                    placeholder={t("ErrEnterMember")}
                  />
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          {error && (
            <Typography
              variant='span'
              component='body2'
              sx={{ mx: 2 }}
              className='input-error'
            >
              {error}
            </Typography>
          )}

          <Typography
            sx={{
              fontSize: "20px",
              paddingRight: "12px",
              paddingLeft: "12px",
              marginTop: "12px",
              fontWeight: 600,
            }}
            gutterBottom
            component='h6'
          >
            {t("SelectMemberPermissions")}
          </Typography>

          <TableContainer component={Paper}>
            <Table aria-label='collapsible table'>
              <TableBody>
                {userModule.map((module, index) => {
                  return (
                    <RowNew
                      key={module.id}
                      module={module}
                      handleIndividualValue={handleIndividualValue}
                      handleAccordian={handleAccordian}
                      handleParentValues={handleParentValues}
                      handleAssociation={handleAssociation}
                    />
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions className='dialog-footer'>
          <Button onClick={handleClose} variant='outlined' color='primary'>
            {t("Close")}
          </Button>
          <Button
            onClick={assignPermissions}
            autoFocus
            variant='contained'
            color='secondary'
            size='medium'
          >
            {userPermissionToEdit ? t("Update") : t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
export default AddNewMemberModal
