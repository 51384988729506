import React, { useRef, useState, useEffect } from "react"
import * as Yup from "yup"
import {
  Button,
  MenuItem,
  Stack,
  Chip,
  TextField,
  IconButton,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormGroup,
  Switch,
  Select,
  Box,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import validator from "validator"
import { useTheme } from "@mui/material/styles"
import { useFormik, Form, FormikProvider } from "formik"
import { useDispatch, useSelector } from "react-redux"
import {
  createMembers,
  showMemberPrivileges,
  updateMembers,
} from "src/redux/actions/member"
import MemberPrivilege from "./memberPrivilege"
import useModal from "../../settings/subscription/Modals/useModal"
import { CreateDeptModal } from "../../department"
import AddNewMemberModal from "../../settings/permissions/modal/addMemberModal"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import VisibilityIcon from "@mui/icons-material/Visibility"
//************************** */

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
function getStyles(name, dptName, theme) {
  return {
    fontWeight:
      dptName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}
export default function AddMemberModal({
  isShowingMember,
  hide,
  open,
  flag,
  data,
}) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [memberTypes, setMemberTypes] = useState("---")
  const [memberPermission, setMemberPermission] = useState([])
  const [dptName, setDptName] = useState([])
  const [initialState, setInitialState] = useState({
    firstName: "",
    lastName: "",
    userEmail: "",
    memberType: "",
    memberDepartment: [],
  })

  const [isActiveMember, setActiveMember] = useState(true)
  const logged = useSelector((state) => state.login)
  const userType = useSelector((state) => state.profile)
  const userPermissions = logged?.user?.profile?.permission

  useEffect(() => {
    return () => {
      setSelected([])
      setActiveMember(null)
      setMemberTypes("---")
      hide()
      resetForm({ values: "" })
    }
  }, [])

  useEffect(() => {
    if (data) {
      setMemberPermission(data?.profile)
      setMemberTypes(data?.profile?.name)
      setSelected(data?.departments_name)
      setActiveMember(data?.is_active)
      setInitialState({
        firstName: data?.first_name,
        lastName: data?.last_name,
        userEmail: data?.email,
        memberType: data?.profile_id,
        memberDepartment: data?.departments_name?.map((name) => name),
      })
    }
  }, [data, isShowingMember])

  let shape = {
    firstName: Yup.string()
      .required(t("ErrFnameRequired"))
      .max(15, t("TooLong!"))
      .matches(/^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/, t("BlankNotAllowed")),
    lastName: Yup.string()
      .required(t("ErrLnameRequired"))
      .max(15, t("TooLong!"))
      .matches(/^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/, t("BlankNotAllowed")),

    memberType: Yup.string().required(t("MemberTypeRequired")),
    memberDepartment: Yup.array(Yup.string()).min(
      1,
      t("SelectAtleast1Department"),
    ),
  }
  if (flag) {
    shape = {
      ...shape,
      userEmail: Yup.string()
        .required(t("ErrEmailRequired"))
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "It must be a valid email")
    }
  }
  const teamMemberSchema = Yup.object().shape(shape)

  const [isShowingDpt, toggleDpt] = useModal()
  const [isShowingMemberPrivilege, togglePrivilege] = useModal()

  const [error, setError] = useState("")

  const dispatch = useDispatch()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: teamMemberSchema,
    onSubmit: (values, { resetForm }) => {
      const department_ids = []
      for (let i = 0; i < values.memberDepartment.length; i++) {
        if (typeof values.memberDepartment === "string") {
          department_ids.push(
            depts?.data?.departments?.find(
              (dept) => dept?.name === values?.memberDepartment,
            )?.id,
          )
          break
        }

        department_ids.push(
          depts?.data?.departments?.find(
            (dept) => dept?.name === values?.memberDepartment[i],
          )?.id,
        )
      }

      if (isEmailAvailable === false) return

      if ((values.userEmail && values.memberType) === "") {
        setError(t("FillAllFields"))
      } else {
        if (selected.length !== 0) {
          setError("")
          if (flag) {
            const formData = {
              first_name: values?.firstName,
              last_name: values?.lastName,
              email: values?.userEmail,
              profile_id: values?.memberType,
              department_id: department_ids,
              // user_can_manage_billing: canManageBilling,
              // can_view_candidates_feedback: canViewFeedback,
              // can_view_vacancy_salary: canViewVacancySalary
            }
            handleCloseSave()
            dispatch(createMembers(formData))
          } else {
            const formData = {
              first_name: values?.firstName,
              last_name: values?.lastName,
              is_active: isActiveMember,
              email: values?.userEmail,
              profile_id: values?.memberType,
              department_id: department_ids,
              // user_can_manage_billing: canManageBilling,
              // can_view_candidates_feedback: canViewFeedback,
              // can_view_vacancy_salary: canViewVacancySalary
            }
            handleCloseSave()
            dispatch(updateMembers(data?.key, formData))
          }

          // setSelected([]);
          // setActiveMember(null);
          // setMemberTypes("---");
          // hide();
          // resetForm({ values: "" });
        } else {
          setError(t("DepartmentRequired"))
        }
      }
    },
  })

  const depts = useSelector((state) => state.departments)

  const {
    errors,
    touched,
    values,
    resetForm,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleBlur,
  } = formik
  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const [selected, setSelected] = useState([])
  const [privilegeError, setPrivilegeError] = useState("")
  const [isEmailAvailable, setIsEmailAvailable] = useState(true)

  const selectionChangeHandler = (event) => {
    const selectedValues = event.target.value
    const filteredValues = selectedValues.filter((value) => value !== "")

    setSelected(filteredValues)
    setFieldValue("memberDepartment", filteredValues)

    setDptName(
      typeof selectedValues === "string"
        ? selectedValues.split(",")
        : selectedValues,
    )
  }

  const handleCloseSave = () => {
    setMemberTypes("---")
    setSelected([])
    resetForm()
    setIsEmailAvailable(true)
    setDptName([])
    hide()
  }

  const [isShowingRole, toggleRole] = useModal()
  const addNewRole = () => {
    toggleRole()
  }

  const handleUserChange = (e) => {
    const temp = userType?.data?.profiles?.filter(
      (user) => user.name === e.target.value,
    )[0]
    setMemberPermission(temp)
    setMemberTypes(e.target.value)
    setFieldValue("memberType", temp?.id)
  }

  const handleMember = () => {
    togglePrivilege()
    !flag && dispatch(showMemberPrivileges())
  }

  const handleEmailChange = (e) => {
    handleChange(e)
    const { value } = e.target

    if (validator.isEmail(value)) {
      const BASE_URL = process.env.REACT_APP_BASE_URL

      fetch(
        `${BASE_URL}/api/admin/general/verify_email_presence?email=${value}`,
      )
        .then((response) => response.text())
        .then((data) => {
          data = data ? JSON.parse(data) : {}
          setIsEmailAvailable(data?.success)
        })
    }
  }

  return (
    isShowingMember && (
      <Dialog
        className='dialog800'
        open={isShowingMember}
        onClose={handleCloseSave}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {flag ? t("AddCompanyUser") : t("EditCompanyUser")}
          <IconButton
            aria-label='close'
            onClick={handleCloseSave}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormikProvider value={formik}>
                <Form
                  id='addMember'
                  autoComplete='off'
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor='firstName' required>
                          {t("FirstName")}
                        </FormLabel>
                        <TextField
                          fullWidth
                          type='text'
                          variant='outlined'
                          autoComplete='firstName'
                          placeholder={t("FirstName")}
                          name='firstName'
                          {...getFieldProps("firstName")}
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.firstName && errors.firstName)}
                          helperText={touched.firstName && errors.firstName}
                          aria-describedby='firstName-helper-text'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor='lastName' required>
                          {t("LastName")}
                        </FormLabel>
                        <TextField
                          fullWidth
                          type='text'
                          variant='outlined'
                          autoComplete='lastName'
                          placeholder={t("LastName")}
                          name='lastName'
                          {...getFieldProps("lastName")}
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={Boolean(touched.lastName && errors.lastName)}
                          helperText={touched.lastName && errors.lastName}
                          aria-describedby='lastName-helper-text'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        {flag ? (
                          <>
                            <FormLabel htmlFor='userEmail' required>
                              {t("Email")}
                            </FormLabel>

                            <TextField
                              fullWidth
                              type='email'
                              variant='outlined'
                              autoComplete='userEmail'
                              placeholder={t("Email")}
                              name='userEmail'
                              // className={
                              //   userError.emailErr ? "input-outline" : ""
                              // }
                              {...getFieldProps("userEmail")}
                              value={values.userEmail}
                              onChange={(e) => handleEmailChange(e)}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.userEmail && errors.userEmail,
                              )}
                              helperText={touched.userEmail && errors.userEmail}
                              aria-describedby='userEmail-helper-text'
                            />
                            {isEmailAvailable === false && (
                              <Typography sx={{ color: "red !important" }}>
                                {t("EmailAlreadyExists")}
                              </Typography>
                            )}
                          </>
                        ) : (
                          <FormControl fullWidth>
                            <FormLabel htmlFor='userEmail' required>
                              {t("Email")}
                            </FormLabel>
                            <TextField
                              fullWidth
                              type='email'
                              variant='outlined'
                              autoComplete='userEmail'
                              placeholder={t("Email")}
                              name='userEmail'
                              {...getFieldProps("userEmail")}
                              value={values.userEmail}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={Boolean(
                                touched.userEmail && errors.userEmail,
                              )}
                              helperText={touched.userEmail && errors.userEmail}
                              aria-describedby='userEmail-helper-text'
                              disabled={true}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </FormControl>
                        )}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <FormLabel required>{t("SelectUserType")}</FormLabel>
                        <Stack direction='row' alignItems='center'>
                          <TextField
                            select
                            id='outlined-basic'
                            name='memberType'
                            size='large'
                            sx={{
                              width: "100%",
                              textTransform: "capitalize",
                            }}
                            {...getFieldProps("memberType")}
                            error={touched.memberType && errors.memberType}
                            helpertext={touched.memberType && errors.memberType}
                            value={memberTypes}
                            onChange={(e) => {
                              handleUserChange(e)
                            }}
                          >
                            {userType?.data?.profiles?.length > 0 && (
                              <MenuItem
                                sx={{
                                  fontWeight: 600,
                                  color: "#5082dd",
                                  opacity: "1 !important",
                                }}
                                disabled
                                value=''
                              >
                                <em>{t("ChooseUserType")}</em>
                              </MenuItem>
                            )}
                            {memberTypes === "Admin"
                              ? userType?.data?.profiles
                                  ?.filter((type) => type.name === "Admin")
                                  .map((option) => (
                                    <MenuItem
                                      sx={{ textTransform: "capitalize" }}
                                      key={option?.id}
                                      value={option?.name}
                                    >
                                      {option?.name}
                                    </MenuItem>
                                  ))
                              : userType?.data?.profiles
                                  ?.filter((type) => type.name !== "Admin")
                                  .map((option) => (
                                    <MenuItem
                                      sx={{ textTransform: "capitalize" }}
                                      key={option?.id}
                                      value={option?.name}
                                    >
                                      {option?.name}
                                    </MenuItem>
                                  ))}
                          </TextField>

                          {memberTypes !== "---" && (
                            <VisibilityIcon
                              width='40px'
                              onClick={handleMember}
                              sx={{
                                fontSize: "22px",
                                color: "var(--primary)",
                                mx: 1,
                                cursor: "pointer",
                              }}
                              title='view Privileges'
                            />
                          )}

                          <MemberPrivilege
                            selectedUser={memberPermission}
                            open={isShowingMemberPrivilege}
                            onClose={togglePrivilege}
                          />
                          {userPermissions?.settings_user_can_add_member_type && (
                            <AddCircleIcon
                              onClick={addNewRole}
                              sx={{
                                fontSize: "40px",
                                mx: 1,
                                color: "var(--primary)",
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </Stack>

                        {privilegeError && (
                          <FormLabel
                            className='input-error'
                            sx={{ marginTop: "1vh !important" }}
                          >
                            {privilegeError}
                          </FormLabel>
                        )}
                        {touched?.memberType && errors?.memberType && (
                          <p className='input-error'>{errors?.memberType}</p>
                        )}
                      </FormControl>
                      <AddNewMemberModal
                        isShowingRole={isShowingRole}
                        hide={toggleRole}
                      />
                    </Grid>
                    {flag ? null : (
                      <Grid item xs={12} sm={3}>
                        <FormGroup>
                          <FormControlLabel
                            disabled={
                              data?.user_type === "admin" ? true : false
                            }
                            control={
                              <Switch
                                id='switch'
                                checked={isActiveMember}
                                onChange={(e) =>
                                  setActiveMember(e.target.checked)
                                }
                              />
                            }
                            label={t("Active")}
                          />
                        </FormGroup>
                      </Grid>
                    )}
                    {/*  <Grid item xs={12} sm={3}>*/}
                    {/*  <FormGroup>*/}
                    {/*    <FormControlLabel*/}
                    {/*      disabled={*/}
                    {/*        data?.user_type === "admin" ? true : false*/}
                    {/*      }*/}
                    {/*      control={*/}
                    {/*        <Switch*/}
                    {/*          id='switch'*/}
                    {/*          checked={canManageBilling}*/}
                    {/*          onChange={(e) =>*/}
                    {/*            setCanManageBilling(e.target.checked)*/}
                    {/*          }*/}
                    {/*        />*/}
                    {/*      }*/}
                    {/*      label={t("ManageBilling")}*/}
                    {/*    />*/}
                    {/*  </FormGroup>*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={3}>*/}
                    {/*  <FormGroup>*/}
                    {/*    <FormControlLabel*/}
                    {/*      disabled={*/}
                    {/*        data?.user_type === "admin" ? true : false*/}
                    {/*      }*/}
                    {/*      control={*/}
                    {/*        <Switch*/}
                    {/*          id='switch'*/}
                    {/*          checked={canViewFeedback}*/}
                    {/*          onChange={(e) =>*/}
                    {/*            setCanViewFeedback(e.target.checked)*/}
                    {/*          }*/}
                    {/*        />*/}
                    {/*      }*/}
                    {/*      label={t("ViewFeedback")}*/}
                    {/*    />*/}
                    {/*  </FormGroup>*/}
                    {/*</Grid>*/}

                    {/*<Grid item xs={12} sm={3}>*/}
                    {/*  <FormGroup>*/}
                    {/*    <FormControlLabel*/}
                    {/*      disabled={*/}
                    {/*        data?.user_type === "admin" ? true : false*/}
                    {/*      }*/}
                    {/*      control={*/}
                    {/*        <Switch*/}
                    {/*          id='switch'*/}
                    {/*          checked={canViewVacancySalary}*/}
                    {/*          onChange={(e) =>*/}
                    {/*            setCanViewVacancySalary(e.target.checked)*/}
                    {/*          }*/}
                    {/*        />*/}
                    {/*      }*/}
                    {/*      label=t("ViewVacancySalary")*/}
                    {/*    />*/}
                    {/*  </FormGroup>*/}
                    {/*</Grid>*/}

                    <Grid item xs={12} sm={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor='memberDepartment' required>
                          {t("SelectDepartment")}
                        </FormLabel>

                        <Select
                          multiple
                          name='memberDepartment'
                          MenuProps={MenuProps}
                          sx={{ textTransform: "capitalize" }}
                          value={selected}
                          onChange={selectionChangeHandler}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={value}
                                  sx={{ mx: 1 }}
                                />
                              ))}
                            </Box>
                          )}
                          error={Boolean(
                            touched?.memberDepartment &&
                              errors?.memberDepartment,
                          )}
                          helperText={
                            touched?.memberDepartment &&
                            errors?.memberDepartment
                          }
                        >
                          <MenuItem
                            sx={{
                              fontWeight: 600,
                              color: "#5082dd",
                              opacity: "1 !important",
                            }}
                            value=''
                            disabled
                          >
                            <em>{t("ChooseDepartment")}</em>
                          </MenuItem>
                          {depts?.data?.departments?.length === 0 ? (
                            <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                          ) : (
                            depts?.data?.departments?.map((dept) => (
                              <MenuItem
                                key={dept?.id}
                                value={dept?.name}
                                style={getStyles(dept?.name, dptName, theme)}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {dept?.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                        {touched?.memberDepartment &&
                          errors?.memberDepartment && (
                            <p className='input-error'>
                              {errors?.memberDepartment}
                            </p>
                          )}

                        {userPermissions?.departments_user_can_add_department && (
                          <>
                            <Button
                              size='small'
                              variant='outlined'
                              color='primary'
                              sx={{
                                maxHeight: 38,
                                width: "fit-content",
                                fontWeight: 500,
                                mt: 2,
                                justifyContent: "center",
                                pl: 2,
                                pr: 2,
                              }}
                              onClick={toggleDpt}
                              startIcon={<AddIcon />}
                            >
                              {t("CreateNewDepartment")}
                            </Button>

                            <CreateDeptModal
                              isShowingDpt={isShowingDpt}
                              hide={toggleDpt}
                              flag={true}
                            />
                          </>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className='dialog-footer'>
          {flag ? (
            <Button
              onClick={handleCloseSave}
              variant='outlined'
              color='primary'
              size='medium'
            >
              {t("Cancel")}
            </Button>
          ) : (
            <Button
              onClick={handleCloseSave}
              variant='outlined'
              color='primary'
              size='medium'
            >
              {t("Cancel")}
            </Button>
          )}

          <Button
            autoFocus
            onClick={handleSubmit}
            variant='contained'
            size='medium'
            type='submit'
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
