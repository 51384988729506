import PropTypes from "prop-types"
import { Box } from "@mui/material"
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
}

export default function Logo({ disabledLink = false, sx, icon, noIcon }) {
  const logo = (
    <Box sx={{ maxWidth: { xs: 160, sm: 160, md: 194 }, ...sx }}>
      <img
        src={
          icon ? icon : "https://intagleo.com/ASSETS/INTVUE/logosecondary.svg"
        }
        alt='intvue'
        width='100%'
        height='100%'
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src =
            "https://intagleo.com/ASSETS/INTVUE/logosecondary.svg"
        }}
      />
    </Box>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return <Box className='sidebarlogo'>{logo}</Box>
}
