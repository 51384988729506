import React, { useRef, useState, useEffect, useCallback } from "react"
import ReactDOM from "react-dom"

import PropTypes from "prop-types"
import * as Yup from "yup"
// material
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"

import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { useFormik, Form, FormikProvider, setNestedObjectValues } from "formik"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { needHelp } from "src/redux/actions/needHelp"

export default function HelpModal({
  props,
  t,
  isShowingHelp,
  hide,
  open,
  onClose,
}) {
  const [initialState, setInitialState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    question: "",
  })

  const dispatch = useDispatch()

  const helpSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t("ErrFnameRequired"))
      .max(30, "Too Long!"),
    last_name: Yup.string()
      .required(t("ErrLnameRequired"))
      .max(30, "Too Long!"),
    email: Yup.string().email().required(t("ErrEmailRequired")),
    mobile: Yup.string().required(" Contact Number is required"),
    question: Yup.string().required(" Question is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: helpSchema,
    onSubmit: (values, { resetForm }) => {
      const newFormData = new FormData()
      newFormData.append("first_name", values.first_name)
      newFormData.append("last_name", values.last_name)
      newFormData.append("email", values.email)
      newFormData.append("mobile", values.mobile)
      newFormData.append("question", values.question)

      dispatch(needHelp(newFormData))
      resetForm({ newFormData })
      onClose()
    },
  })

  const {
    errors,
    touched,
    values,
    handleSubmit,
    handleBlur,
    getFieldProps,
    handleChange,
    setFieldValue,
  } = formik

  const handleClose = () => {
    values.first_name = ""
    values.last_name = ""
    values.email = ""
    values.mobile = ""
    values.question = ""
    onClose()
  }

  return (
    <Dialog
      className='dialog800'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='xl'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        {t("Helpneeded")}
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' dividers={true}>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel required>{t("FirstName")}</FormLabel>
                  <TextField
                    fullWidth
                    type='text'
                    variant='outlined'
                    autoComplete='title'
                    placeholder={t("FirstName")}
                    name='first_name'
                    {...getFieldProps("first_name")}
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.first_name && Boolean(errors.first_name)}
                    helperText={touched.first_name && errors.first_name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel required>{t("LastName")}</FormLabel>
                  <TextField
                    fullWidth
                    type='text'
                    variant='outlined'
                    autoComplete='LastName'
                    placeholder={t("LastName")}
                    name='last_name'
                    {...getFieldProps("last_name")}
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.last_name && Boolean(errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel required>{t("ContactNumber")}</FormLabel>
                  <PhoneInput
                    country={"us"}
                    className=' '
                    id='phoneNumber'
                    autoComplete='off'
                    autoFormat
                    enableSearch={true}
                    inputClass='phone_input'
                    name='mobile'
                    {...getFieldProps("mobile")}
                    onChange={(value, country, e, formattedValue) => {
                      handleChange(e)

                      setFieldValue("mobile", formattedValue)
                    }}
                    onBlur={handleBlur}
                    error={touched.mobile && Boolean(errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                  />
                  {touched?.mobile && errors?.mobile && (
                    <p className='input-error'>{errors?.mobile}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel required>{t("EmailID")}</FormLabel>
                  <TextField
                    pattern='^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$'
                    fullWidth
                    type='email'
                    variant='outlined'
                    // autoComplete=''
                    placeholder={t("EmailID")}
                    name='email'
                    {...getFieldProps("email")}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel required htmlFor=''>
                    {t("YourQuestion")}
                  </FormLabel>
                  <TextField
                    fullWidth
                    multiline
                    rows={2}
                    variant='outlined'
                    autoComplete=''
                    placeholder={t("Question")}
                    name='question'
                    {...getFieldProps("question")}
                    value={values.question}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.question && Boolean(errors.question)}
                    helperText={touched.question && errors.question}
                    sx={{
                      "&:first-letter": {
                        textTransform: "capitalize",
                      },
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button
          onClick={handleClose}
          variant='outlined'
          color='primary'
          size='medium'
        >
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleSubmit}
          autoFocus
          variant='contained'
          color='secondary'
          size='medium'
        >
          {t("Send")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
