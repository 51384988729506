import React, { useState, useEffect } from "react"

import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Box,
} from "@mui/material"

import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import Scrollbar from "src/components/Scrollbar"
import { teamDataParsing } from "src/utils/dataParsing"
import { getAllMembers } from "src/redux/actions/member"
import PulseLoader from "react-spinners/PulseLoader"

export default function ViewCompanyUser({ hide, open }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const members = useSelector((state) => state.members)
  const [memberList, setMemberList] = useState([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  useEffect(() => {
    if (members?.data?.length === 0 && open) {
      setIsDataLoading(true)
      dispatch(getAllMembers())
    } else {
      setMemberList(
        teamDataParsing(
          members?.data?.users?.filter((usr) => usr.is_active) ?? [],
        ),
      )
      setIsDataLoading(false)
    }
  }, [members, open])

  return (
    <Dialog
      className='dialog800'
      open={open}
      onClose={hide}
      scroll='paper'
      maxWidth='xl'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        {t("ActiveCompanyUsers")}
        <IconButton
          aria-label='close'
          onClick={hide}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' dividers={true}>
        <Scrollbar>
          <TableContainer>
            <Table
              className='simple-table'
              sx={{ minWidth: 650 }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t("UserName")}</TableCell>
                  <TableCell>{t("Email")}</TableCell>
                  <TableCell>{t("Department")}</TableCell>
                  <TableCell>{t("Role")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isDataLoading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mb: 3,
                        }}
                      >
                        <PulseLoader
                          color={"#0066E9"}
                          loading={true}
                          size={16}
                          speedMultiplier='1'
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {memberList?.length > 0 ? (
                      memberList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell component='th' scope='row'>
                              <Typography
                                variant='subtitle2'
                                sx={{
                                  textTransform: "capitalize",
                                }}
                                noWrap
                              >
                                {row?.name}
                              </Typography>
                            </TableCell>
                            <TableCell>{row?.email}</TableCell>
                            <TableCell>{row?.department}</TableCell>
                            <TableCell>{row?.role}</TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell className='searchCell' colSpan={10}>
                          <Typography variant='body2' align='left'>
                            {t("ActiveUsersNotFound")} &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component='div'
            count={memberList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Scrollbar>
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button onClick={hide} variant='outlined' color='primary' size='medium'>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
