import * as ActionTypes from "../constants/interviews"
const initialState = {
  data: [],
  message: "",
  error: "",
  isSucceed: false,
  isCreated: false,
  isDeleted: false,
  isUpdated: false,
  isInvite: false,
  isArchived: false,
  isFailure: false,
}

export const interviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHINTERVIEWS_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }

    case ActionTypes.CREATEINTERVIEW_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isCreated: true,
      }

    case ActionTypes.CREATEINTERVIEW_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      }

    case ActionTypes.ARCHIVEINTERVIEW_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isArchived: true,
      }

    case ActionTypes.DELETEINTERVIEW_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isDeleted: true,
      }

    case ActionTypes.DELETEINTERVIEW_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      }
    case ActionTypes.SENDINVITATION_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isInvite: true,
      }

    case ActionTypes.UPDATEINTERVIEW_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isUpdated: true,
      }
    case ActionTypes.UPDATEINTERVIEW_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      }

    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        message: "",
        isSucceed: false,
        isCreated: false,
        isDeleted: false,
        isUpdated: false,
        isInvite: false,
        isArchived: false,
        isFailure: false,
      }
    case ActionTypes.DELETE_SELECTED_INTERVIEWS_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isDeleted: true,
      }
    default:
      return state
  }
}

export const filteredInterviewReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.FETCH_FILTERD_INTERVIEWS_ON_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  }

  return state
}

export const getInterviewDetailReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case ActionTypes.FETCHINTERVIEWDETAIL_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }

    case ActionTypes.FETCHINTERVIEWDETAIL_ON_FAILURE:
      return {
        ...state,
        data: action.payload,
        isSucceed: false,
      }

    default:
      return state
  }
}

export const finishInterviewReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case ActionTypes.FINISHINTERVIEW_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }

    case ActionTypes.FINISHINTERVIEW_ON_FAILURE:
      return {
        ...state,
        data: null,
        isSucceed: false,
      }

    default:
      return state
  }
}

export const getInterviewFeedbackReducer = (
  state = { data: null, user_comments: {}, flag_question: {} },
  action,
) => {
  if (action.type === ActionTypes.GET_INTERVIEW_FEEDBACK_SUCCESS) {
    if (action.payload.update_action) {
      return {
        ...state,
        user_comments: action.payload.interview.user_comments,
        flag_question: action.payload.interview.flag_question,
        isSucceed: action.payload.success,
      }
    }

    return {
      ...state,
      data: action.payload,
      user_comments: action.payload.interview.user_comments,
      flag_question: action.payload.interview.flag_question,
      isSucceed: action.payload.success,
    }
  }

  return state
}

export const addInterviewCommentReducer = (state = { data: null }, action) => {
  if (action.type === ActionTypes.ADD_INTERVIEW_COMMENT_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  } else {
    return state
  }
}

export const viewQuestionReducer = (state = { data: null }, action) => {
  if (action.type === ActionTypes.VIEW_INTERVIEW_QUESTION_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  } else {
    return state
  }
}

export const deleteQuestionReducer = (state = { data: null }, action) => {
  if (action.type === ActionTypes.DELETE_INTERVIEW_COMMENT_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  } else {
    return state
  }
}

export const editCommentReducer = (state = { data: null }, action) => {
  if (action.type === ActionTypes.EDIT_COMMENT_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  } else {
    return state
  }
}

export const candidateHistoryReducer = (state = { data: null }, action) => {
  if (action.type === ActionTypes.VIEW_CANDIDATE_HISTORY_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  } else {
    return state
  }
}

export const updateIntFeedbackReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_INT_FEEDBACK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        data: "",
        isSucceed: false,
      }
    default:
      return state
  }
}

export const getRecommendationsReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case ActionTypes.RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        data: "",
        isSucceed: false,
      }
    default:
      return state
  }
}

export const onsiteIntFeedbackReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case ActionTypes.ONSITE_INT_FEEDBACK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }
    case ActionTypes.ONSITE_INT_FEEDBACK_FAILURE:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        data: "",
        isSucceed: false,
      }
    default:
      return state
  }
}

export const flagIntQuestionReducer = (state = { data: null }, action) => {
  if (action.type === ActionTypes.ADD_INTERVIEW_QUESTION_FLAG_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  } else {
    return state
  }
}

export const onsiteIntStatusReducer = (state = { data: null }, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_INTERVIEW_STATUS_SUCCEESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        data: "",
        isSucceed: false,
      }
    default:
      return state
  }
}
