import * as ERRORS from "../constants/errorManagement";
import * as ActionTypes from "../constants/loading";
import { logout } from "../actions/logout";
import axios from "axios";
import { isEmpty } from "lodash";

export const request =
  (options, formData = null) =>
  (dispatch) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [successType, failureType] = options.types;
    const API_URL = `${BASE_URL}${options.action}`;

    if (options.request_type !== 'renew_session') {
      dispatch({
        type: ActionTypes.API_LOADING_START,
      })
    }

    const params = {
      method: options.method,
      url: API_URL,
    };

    if (options.headers) {
      params.headers = options.headers;
    }

    if (options.body) {
      params.data = options.body;
    }

    if (options.params) {
      params.params = options.params
    }

    axios(params)
      .then((response) => {
        if (response?.status === 200) {
          let data = response?.data

          if (response?.data?.success) {
            if (!isEmpty(options.update_action)) {
              data = { ...data, update_action: options.update_action}
            }
            dispatch({
              type: successType,
              payload: data,
            });
          } else if (response?.data?.success === false) {
            dispatch({
              type: failureType,
              payload: data,
            });
          } else {
            dispatch({
              type: successType,
              payload: data,
            });
          }
        }

        if (response?.status === 401) {
          dispatch(logout());
        }

        dispatch({
          type: ActionTypes.API_LOADING_END,
        });
        // else {
        //   if (response?.data?.success) {
        //     dispatch({
        //       type: successType,
        //       payload: response.data,
        //     });
        //   } else if (response?.data?.success === false) {
        //     dispatch({
        //       type: ERRORS.ERROR_SCENARIO,
        //       payload: response.data,
        //     });
        //   } else {
        //     dispatch({
        //       type: successType,
        //       payload: response.data,
        //     });
        //   }
        // }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logout());
        } else {
          dispatch({
            type: ERRORS.API_FAILURE,
            error: error,
            url: API_URL,
          });
        }
        dispatch({
          type: failureType,
          payload: error?.response?.data,
        });
        dispatch({
          type: ActionTypes.API_LOADING_END,
        });
      });
  };
