import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/interviewers";

export const getAllInterviewers = () => {
  const endpoint = "/api/v1/interviewers";
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.FETCHINTERVIEWERS_ON_SUCCESS,
    ActionTypes.FETCHINTERVIEWERS_ON_FAILURE,
  ];
  return request(options);
};

export const createInterviewers = (data) => {
  const endpoint = "/api/v1/interviewers";
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CREATEINTERVIEWER_ON_SUCCESS,
    ActionTypes.CREATEINTERVIEWER_ON_FAILURE,
  ];
  return request(options);
};

export const resetInterviewersFlag = () => {
  return {
    type: ActionTypes.RESET_FLAGS,
  };
};
