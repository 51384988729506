import React from "react"
import { styled } from "@mui/material/styles"
import { Typography, Stack, Switch } from "@mui/material"
import PropTypes from "prop-types"
import Label from "src/components/Label"
import { useTranslation } from "react-i18next"
// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  zIndex: 999,
  display: "flex",
  cursor: "pointer",
  position: "relative",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.paper,
  transition: theme.transitions.create("opacity"),
  "&:hover": { opacity: 1 },
}))
ToggleWidget.propTypes = {
  plans: PropTypes.array.isRequired,
}
// ----------------------------------------------------------------------

export default function ToggleWidget({
  plans,
  isChecked,
  setIsChecked,
  ...other
}) {
  const { t } = useTranslation()
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
      "& .MuiSwitch-track": {
        backgroundColor: "#E9E9EA",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "light" ? "#65C466" : "#39393D",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },

    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
      boxSizing: "border-box",
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }))
  return (
    <RootStyle className='toggle-switch'>
      <Stack
        direction='row'
        className='switch-div'
        alignItems='center'
        justifyContent='space-between'
        mb={3}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
        >
          <Typography
            className={`swtich-labels ${
              plans.interval === "month" ? "active" : ""
            }`}
            sx={{ mr: 1 }}
          >
            {t("Monthly")}
          </Typography>
          <AntSwitch
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(!isChecked)
            }}
            inputProps={{ "aria-label": "ant design" }}
            sx={{ m: 1 }}
          />
          <Typography className='swtich-labels' sx={{ ml: 1 }}>
            {t("Yearly")}
          </Typography>
        </Stack>
        {isChecked &&
          <Label
            variant='ghost'
            color='success'
            sx={{ ml: 1, display: { sm: "inlin-flex" } }}
          >
            {t("Save15%")}
          </Label>
        }
      </Stack>
    </RootStyle>
  )
}
