import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import {
  Container,
  Link,
  Stack,
  IconButton,
  AppBar,
  Drawer,
  Toolbar,
  Box,
  Button,
} from "@mui/material"
import Images from "../../assets/img/Images"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Logo from "src/components/Logo/Logo"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92
const drawerWidth = 200

const RootStyle = styled(AppBar)(({ theme }) => ({
  right: 0,
  left: 0,
  zIndex: 1030,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  // backgroundColor: 'transparent',
  backgroundColor: "#fff",
  [theme.breakpoints.up("lg")]: {
    width: `100%`,
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "inherit",
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
  width: "100%",
  borderBottom: "1px solid #aea9a9",
  borderTop: 0,
  marginBottom: "1.5rem",
}))
// ----------------------------------------------------------------------
export default function XpageNavbar(props) {
  const { t } = useTranslation()

  const checkLogin = () => {
    const user = JSON.parse(localStorage.getItem("logged"))

    return !user ? false : true
  }

  const baseLandingUrl = process.env.REACT_APP_FE_BASE_URL
  const homeLink = baseLandingUrl + "/Home/app"
  const aboutLink = baseLandingUrl + "/Home/app/#aboutDiv"
  const featureLink = baseLandingUrl + "/Home/app/#featureDiv"
  const pricingLink = baseLandingUrl + "/Home/app/#pricingDiv"
  const contactLink = baseLandingUrl + "/Home/app/#contactDiv"

  const { window } = props
  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleDrawerClose = () => {
    setMobileOpen(!mobileOpen)
  }
  const navItems = (
    <>
      <Link
        className='anchor-link'
        underline='none'
        offset={() => 100}
        href={homeLink}
      >
        {" "}
        {t("Home")}
      </Link>
      <Link
        className='anchor-link'
        underline='none'
        offset={() => 100}
        href={aboutLink}
      >
        {t("About")}
      </Link>
      <Link
        className='anchor-link'
        underline='none'
        offset={() => 100}
        href={featureLink}
      >
        {t("Features")}
      </Link>
      <Link
        className='anchor-link'
        underline='none'
        offset={() => 100}
        href={pricingLink}
      >
        {t("Pricing")}
      </Link>
      <Link
        className='anchor-link'
        underline='none'
        offset={() => 100}
        href={contactLink}
      >
        {t("Contact")}
      </Link>

      {checkLogin() === false ? (
        <>
          <Link className='anchor-link' underline='none' href='/signup'>
            {t("SignUp")}
          </Link>
          <Button href='/login' variant='contained' color='primary'>
            {t("Login")}
          </Button>
        </>
      ) : (
        <Button href='/dashboard/app' variant='contained' color='primary'>
          {t("Go2Dashboard")}
        </Button>
      )}
    </>
  )

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Stack
        sx={{ textAlign: "left", width: "100%" }}
        direction='row'
        justifyContent='space-between'
        alignItems='flex-start'
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <Toolbar
          sx={{
            display: { xs: "flex", sm: "flex" },
            flexDirection: "column",
            width: "100%",
          }}
          className='navbar-nav'
          component='nav'
          variant='dense'
        >
          {navItems}
        </Toolbar>
      </Stack>
    </Box>
  )
  const container =
    window !== undefined ? () => window().document.body : undefined
  return (
    <RootStyle component='nav'>
      <AppBar className='navbar ' sx={{ backgroundColor: "#fff" }}>
        <ToolbarStyle sx={{ padding: { xs: 0, sm: 0, md: "0", lg: "0 40px" } }}>
          <Container maxWidth='xl'>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={{ xs: 0, sm: 1.5 }}
            >
              <Logo icon={Images.LogoMain} />
              <Box sx={{ flexDirection: "row", display: "flex" }}>
                <IconButton
                  aria-label='open drawer'
                  className='menuBtn'
                  edge='end'
                  color='inherit'
                  onClick={handleDrawerToggle}
                  sx={{
                    mr: 1,
                    color: "text.primary",
                    fontSize: "2rem",
                    zIndex: 1,
                    paddingTop: 0,
                    display: { lg: "none", md: "none" },
                  }}
                >
                  <MenuOpenIcon />
                </IconButton>
              </Box>
              <Toolbar
                sx={{
                  display: { xs: "none", sm: "none", md: "flex" },
                  justifyContent: "space-between",
                  overflowX: "auto",
                }}
                className='navbar-nav navbar-light'
                component='nav'
                variant='dense'
              >
                {navItems}
              </Toolbar>
            </Stack>
          </Container>
        </ToolbarStyle>
      </AppBar>

      <Box component='nav'>
        <Drawer
          id='mobileDrawer'
          container={container}
          variant='temporary'
          anchor='left'
          open={mobileOpen}
          transitionDuration={700}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              overflowY: "auto",
              minHeight: 250,
              width: drawerWidth,
              backgroundColor: "hsla(0, 0%, 100%, 0.9)",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </RootStyle>
  )
}
