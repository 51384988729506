import * as ActionTypes from "src/redux/constants/landing";
import { request } from "src/redux/middlewares/service";

export const sendSuggestion = (data) => {
  const endpoint = `/api/v1/interview_conduction/request_demo`;
  const method = "POST";

  const options = { action: endpoint, method: method };

  options.body = data;
  options.types = [
    ActionTypes.SUGGESTION_ON_SUCCESS,
    ActionTypes.SUGGESTION_ON_FAILURE,
  ];
  return request(options);
};
