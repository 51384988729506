import { useDispatch } from 'react-redux'
import { useEffect } from 'react'

import { logout } from 'src/redux/actions/logout'
import { renewSession } from 'src/redux/actions/login'

const SessionTimeOut = () => {
  let lastActivityTime = Date.now()
  const currentUser = ![null, undefined, "", "null", 'undefined'].includes(localStorage.getItem("user")) ?
    JSON.parse(localStorage.getItem("user")) : undefined

    const dispatch = useDispatch()

  // set session timeout for 8 hours
  const sessionTimeoutDuration =  8 * 60 * 60 * 1000
  const checkSessionTime = 60 * 1000

  useEffect(() => {
    if(currentUser) {
      const sessionTimeoutTimer = setInterval(checkSession, checkSessionTime)

      const timer = setInterval(renewSessionHandler, sessionTimeoutDuration)

      return () => {
        clearInterval(sessionTimeoutTimer)
        clearInterval(timer)
      }
    }
  }, [])

  useEffect(() => {
    if(currentUser) {
      window.addEventListener('mousemove', handleUserActivity)
      window.addEventListener('keydown', handleUserActivity)

      return () => {
        window.removeEventListener('mousemove', handleUserActivity)
        window.removeEventListener('keydown', handleUserActivity)
      }
    }
  }, [])

  const checkSession = () =>  {
    handleSessionTimeout()
  }

  const handleSessionTimeout = () => {
    const elapsedTime = Date.now() - lastActivityTime

    if (elapsedTime > sessionTimeoutDuration) {
      dispatch(logout())
    }
  }

  const renewSessionHandler = () => {
    const elapsedTime = Date.now() - lastActivityTime

    if (elapsedTime < sessionTimeoutDuration) {
      const newFormData = new FormData()
      newFormData.append('time', new Date(lastActivityTime))
      dispatch(renewSession(newFormData))

      const timer = setInterval(renewSessionHandler, sessionTimeoutDuration - elapsedTime)

      return () => clearInterval(timer)
    }
  }

  const handleUserActivity = () =>  {
    lastActivityTime = new Date().getTime()
  }

  return <></>
}

export default SessionTimeOut
