import React, { useEffect, useState } from "react"
import { styled } from "@mui/material/styles"
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from "@mui/material"

import { SelectedCard } from "src/sections/plans/SelectedPlan"
import { useSelector, useDispatch } from "react-redux"
import { selectedPlan } from "src/redux/actions/pickedPlan"
import { fCurrency } from "src/utils/formatNumber"

const StepperInner = styled("div")(({ theme }) => ({
  margin: "0 0 1.5rem 0",
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      whiteSpace: "break-spaces",
    },
  },
}
// ---------
function Step4({
  plans,
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  onSelectPlan,
  onCardAdd,
  setApplyCode,
  ...props
}) {
  const selection = useSelector((state) => state.plans.selected)
  const allPlans = useSelector((state) => state.plansList)

  const [planItem, setPlanItem] = useState([])
  const [planSelected, setPlanSelected] = useState(false)
  const authPlan = planItem[0]
  const [planArr, setPlanArr] = useState("Basic Monthly")

  const dispatch = useDispatch()

  const handlePlans = (plan) => {
    if (onSelectPlan) {
      onSelectPlan(plan)
    }

    let planList = []

    allPlans?.plans?.forEach((item) => {
      if (item?.nickname === plan) {
        setFieldValue("planType", item?.plan_type)
        planList?.push({
          billing_scheme: item?.billing_scheme,
          id: item?.id,
          currency: item?.currency,
          interval: item?.interval,
          name: item?.nickname,
          per_unit_price: item?.per_unit_price,
          plan_type: item?.plan_type,
          price: item?.price,
          saving: item?.saving,
          status: item?.active,
          tier: item?.tiers,
          total_interviews: item?.interview_count,
        })
      }
    })

    if (planList?.length !== 0) {
      dispatch(selectedPlan(planList[0]))
      setPlanItem(planList)
    }
  }
  useEffect(() => {
    if (!planSelected) {
      handlePlans("Basic Monthly")
      setPlanSelected(true)
    }
  }, [selection])

  return (
    <StepperInner>
      <Grid container spacing={2} className='confirmation-page'>
        <Grid item xl={7} lg={6} md={12} sm={12} xs={12} className='inner-note'>
          <Typography
            variant='h4'
            sx={{ fontFamily: "Proxima-Nova-Bold", mb: 2 }}
          >
            Confirmation
          </Typography>
          <Typography component='p' variant='subtitle1' sx={{ mb: 1 }}>
            Get top-notch support and service from IntVue in just a few clicks.
          </Typography>
          <Typography component='p' variant='subtitle1' sx={{ mb: 0 }}>
            To help you succeed, we offer You a
            {selection?.name === "Basic Monthly"
              ? "Free Trial period"
              : selection?.name}
            in which you can:
          </Typography>
          <List className='dot-list' component='ul'>
            <ListItem>
              <ListItemText>
                Have access to our easy-to-use interface.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Conduct {selection?.total_interviews} interviews per{" "}
                {selection?.interval === "month" ? "month" : "year"}.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Pay ${selection?.per_unit_price} per extra interview conducted.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                Spend $
                {Math.round(
                  selection?.price * (selection?.interval !== "year" ? 12 : 1),
                )}{" "}
                annually.
              </ListItemText>
            </ListItem>
          </List>

          {selection?.id === 6 || selection?.plan_type === "basic_monthly" ? (
            ""
          ) : (
            <Typography>
              You're almost done, enter your credit card information to continue
              sign up.
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xl={5}
          lg={6}
          md={12}
          sm={8}
          xs={12}
          className='max-wd-350-sm'
        >
          <FormControl sx={{ width: "100%", mb: 2 }}>
            <FormLabel>Select Plan</FormLabel>
            <Select
              className='demo-select'
              name='role'
              value={planArr}
              input={<OutlinedInput />}
              MenuProps={MenuProps}
              renderValue={(planArr) => {
                return planArr
              }}
              onChange={(e, value) => {
                handlePlans(e.target.value)
                setPlanArr(value.props.children)
              }}
            >
              {allPlans?.plans?.map((option) => (
                <MenuItem
                  key={option?.id}
                  value={option?.nickname}
                  sx={{
                    wordBreak: "break-word",
                    whiteSpace: "break-spaces",
                  }}
                  disabled={
                    process.env.REACT_APP_IS_CARD_ENABLED === "false" &&
                    option?.plan_type !== "basic_monthly"
                  }
                >
                  {option?.nickname} -{" "}
                  {option?.plan_type === "basic_monthly" ? (
                    <>Free for 30 Days, {fCurrency(option?.price)} afterwards</>
                  ) : (
                    <>[ {fCurrency(option?.price)} ]</>
                  )}
                </MenuItem>
              ))}
            </Select>
            {touched?.role && errors?.role && (
              <p className='input-error'>{errors?.role}</p>
            )}
          </FormControl>

          <SelectedCard
            plan={authPlan}
            onCardAdd={onCardAdd}
            setAppliedCode={setApplyCode}
          />
        </Grid>
      </Grid>
    </StepperInner>
  )
}

export default Step4
