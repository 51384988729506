import * as ActionTypes from "src/redux/constants/updatePassword"
import { request } from "src/redux/middlewares/service"

const decodeString = (str) => {
  return JSON.parse(
    '{"' + str.substr(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    (key, value) => (key === "" ? value : decodeURIComponent(value)),
  )
}
export const updatePassword = (data) => {
  let updateHeader = ""
  const userData = localStorage.getItem("setPass")
  if (!userData) {
    let headersToken = localStorage.getItem("location")
    updateHeader = decodeString(headersToken)
  }
  const endPoint = `/api/auth/password`
  const method = `PUT`
  const headers = !!userData
    ? JSON.parse(localStorage.getItem("accessHeader"))
    : updateHeader

  const options = { action: endPoint, method: method, headers: headers }
  options.body = data
  options.types = [ActionTypes.UPDATE_ON_SUCCESS, ActionTypes.UPDATE_ON_FAILURE]
  return request(options)
}
