import React, { useEffect, useState } from "react"
import {
  Grid,
  Alert,
  Stack,
  Button,
  FormControl,
  CircularProgress,
  TextField,
  FormLabel,
  Typography,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material"
import { useFormik, Form, FormikProvider } from "formik"
import {
  getAccountConfig,
  updateFeedbackPoints,
  resetConfigurationsFlag,
} from "src/redux/actions/accountConfig"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { userKey } from "src/utils/userHelper"
import AddIcon from "@mui/icons-material/Add"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
// ----------------------------------------------------------------------

export default function General({
  setDisplayCrash,
  setOpenSnackbar,
  setSeverity,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const config = useSelector((state) => state.accountConfigurations)

  const [initVal, setinitVal] = useState({
    custom_feedback: [],
    current_points: [],
  })
  const [customFeedback, setCustomFeedback] = useState([])
  const [currentPoints, setCurrentPoints] = useState([])
  const [feedbackPoint, setFeedbackPoint] = useState("")
  const [saving, setSaving] = useState(false)
  const [pointExist, setPointExist] = useState(false)

  useEffect(() => {
    if (config?.data.length === 0) {
      dispatch(getAccountConfig(userKey()))
    }

    if (config?.edited && saving) {
      setSeverity("success")
      setOpenSnackbar(true)
      setDisplayCrash(config.message)
      setSaving(false)
      dispatch(getAccountConfig(userKey()))
      dispatch(resetConfigurationsFlag())
    }

    if (config?.isSucceed && !saving) {
      setFieldValue(
        "custom_feedback",
        config.data.account_configuration?.custom_feedback,
      )

      setFieldValue(
        "current_points",
        config.data.account_configuration?.current_points,
      )
      setCustomFeedback(config.data.account_configuration?.custom_feedback)
      setCurrentPoints(config.data.account_configuration?.current_points)
      setinitVal({
        custom_feedback:
          config.data.account_configuration?.custom_feedback ?? [],
        current_points: config.data.account_configuration?.current_points ?? [],
      })
    }
  }, [config])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initVal,
    onSubmit: (values) => {
      const formData = {
        custom_feedback: [...values?.custom_feedback],
        current_points: [...values?.current_points],
      }
      setSaving(true)
      dispatch(updateFeedbackPoints(formData))
    },
  })
  const { handleSubmit, setFieldValue } = formik

  /*    ---------------------------------------- */
  return (
    <>
      <Alert severity='info'>
        {t("In this section, you can add/edit general fields (select 6)")}
      </Alert>

      <FormikProvider value={formik}>
        <Form
          id='generalInfoForm'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
              {customFeedback?.length !== 0 && (
                <Box
                  className='mini-container'
                  sx={{ mb: 2, maxHeight: "400px !important" }}
                >
                  {customFeedback?.map((point, index) => {
                    return (
                      <Box
                        className='ques-list'
                        key={point + "feedback" + index}
                      >
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          sx={{
                            mb: 2,
                            width: "100%",
                          }}
                        >
                          <Typography
                            variant='subtitle1'
                            sx={{
                              mb: 0,
                              mr: "2px",
                              wordBreak: "break-word",
                              display: "inline-flex",
                              width: "98%",
                            }}
                          >
                            <FormControlLabel
                              sx={{ alignItems: "flex-start" }}
                              control={
                                <Checkbox
                                  checked={currentPoints.includes(point)}
                                  onChange={(e) => {
                                    let remainingPoints = [...currentPoints]

                                    if (e.target.checked === false) {
                                      remainingPoints = currentPoints.filter(
                                        (cp) => cp !== point,
                                      )
                                    } else {
                                      remainingPoints.push(point)
                                    }

                                    setCurrentPoints(remainingPoints)
                                    setFieldValue(
                                      "current_points",
                                      remainingPoints,
                                    )
                                  }}
                                  inputProps={{ "aria-label": "controlled" }}
                                  sx={{ py: "0", px: "9px !important" }}
                                />
                              }
                              label={<Typography>{t(point)}</Typography>}
                            />
                          </Typography>

                          <Box className='right-icon' sx={{ minWidth: "70px" }}>
                            <DeleteOutlineIcon
                              onClick={() => {
                                let removed = [...customFeedback]
                                removed.splice(index, 1)
                                setCustomFeedback(removed)
                                setFieldValue("custom_feedback", removed)

                                let remainingPoints = currentPoints.filter(
                                  (cp) => removed.includes(cp),
                                )
                                setCurrentPoints(remainingPoints)
                                setFieldValue("current_points", remainingPoints)
                              }}
                              sx={{
                                width: "24px",
                                height: "26px",
                                color: "#49454F",
                                cursor: "pointer",
                              }}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )
                  })}
                </Box>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <FormControl sx={{ width: "100%", mb: 2 }}>
                <FormLabel sx={{ mb: 1, fontWeight: 500 }}>
                  {t("Add Custom Feedback")}
                </FormLabel>

                <TextField
                  fullWidth
                  type='text'
                  variant='outlined'
                  placeholder={t("Add Feedback")}
                  name='feedbackText'
                  id='feedbackText'
                  value={feedbackPoint}
                  onChange={(e) => {
                    setPointExist(
                      customFeedback.some(
                        (choice) =>
                          choice.toLowerCase().trim() ===
                          e.target.value.toLowerCase().trim(),
                      ),
                    )
                    setFeedbackPoint(e.target.value)
                  }}
                />
                <Button
                  type='button'
                  variant='outlined'
                  color='secondary'
                  className='add-btn'
                  sx={{
                    // maxWidth: 200,
                    my: 1,
                    maxHeight: 33,
                    fontWeight: 500,
                    maxWidth: "max-content",
                  }}
                  startIcon={<AddIcon />}
                  disabled={feedbackPoint.trim() === "" || pointExist}
                  onClick={() => {
                    if (feedbackPoint.trim() !== "" && !pointExist) {
                      let feedback = [...customFeedback, feedbackPoint]
                      setCustomFeedback(feedback)
                      setFeedbackPoint("")
                      setFieldValue("custom_feedback", feedback)
                    }
                  }}
                >
                  {t("Add Feedback")}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <Grid item xs={12} sm={4}>
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={4}
        >
          <Button
            loadingindicator='Loading…'
            variant='contained'
            color='primary'
            type='submit'
            onClick={handleSubmit}
            disabled={saving || currentPoints.length !== 6}
          >
            {t("Save")}
          </Button>

          {saving && <CircularProgress />}
        </Stack>
      </Grid>
    </>
  )
}
