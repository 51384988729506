import PropTypes from "prop-types"
import React from "react"
import { styled } from "@mui/material/styles"
import {
  Toolbar,
  Box,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import FilterSidebar from "./FilterSidebar"
import { useDispatch } from "react-redux"
import { deleteSelectedRoles } from "src/redux/actions/roles"
import RefreshIcon from "@mui/icons-material/Refresh"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SearchIcon from "@mui/icons-material/Search"
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "&.Mui-focused": {
      width: "100%",
    },
  },
}))

// ----------------------------------------------------------------------

RolesListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  roles: PropTypes.array,
  roleCrud: PropTypes.object,
  setRoles: PropTypes.func,
}

export default function RolesListToolbar({
  numSelected,
  roleList,
  resetSelected,
  uniqueDept,
  onFilterName,
  refreshRole,
  searchName,
  onFilterSearch,
  canSearch = true,
  canFilterDept = true,
  openFilter,
  setOpenFilter,
  handleOpenFilter,
  handleCloseFilter,
  setRoles,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      <Box>
        {numSelected > 0 ? (
          <Typography component='div' variant='subtitle1'>
            {numSelected} selected
          </Typography>
        ) : (
          canSearch && (
            <SearchStyle
              id='search'
              value={searchName}
              onChange={onFilterSearch}
              placeholder={t("Search")}
              startAdornment={
                <InputAdornment position='start'>
                  <SearchIcon
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
            />
          )
        )}
      </Box>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
        }}
      >
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton>
              <DeleteOutlineIcon
                width={24}
                height={24}
                onClick={(e) => {
                  resetSelected([])
                  dispatch(deleteSelectedRoles(roleList))
                }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title={t("Refresh")} onClick={refreshRole}>
              <IconButton>
                <RefreshIcon />
              </IconButton>
            </Tooltip>

            <FilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              setRoles={setRoles}
            />
          </>
        )}
      </Box>
    </RootStyle>
  )
}
