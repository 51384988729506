import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Rating,
  Divider,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  TextField,
  FormControl,
  Slider,
} from "@mui/material"
import Scrollbar from "src/components/Scrollbar"
import { useTranslation } from "react-i18next"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { useSelector } from "react-redux"
import { subMonths } from "date-fns"
import moment from "moment"
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined"
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined"
import CloseIcon from "@mui/icons-material/Close"
import FilterListIcon from "@mui/icons-material/FilterList"
// ----------------------------------------------------------------------
export const FILTER_INT_FORMAT_OPTIONS = [
  "All",
  "RecordedbyApplicant",
  "OnSite",
]
export const FILTER_INT_TYPE_OPTIONS = ["All", "FirstContact", "Follow-up"]
export const FILTER_FEEDBACK_OPTIONS = ["Pre-feedback", "Post-feedback"]

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
    },
  },
}
// ----------------------------------------------------------------------

FilterInterviewSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
}

export default function FilterInterviewSidebar({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  setInterviews,
  tabing,
  setTStartDate,
  setTEndDate,
}) {
  const { t } = useTranslation()
  const MAX_LIMIT = 70

  const departmentsInfo = useSelector((state) => state.departments)
  const roles = useSelector((state) => state.roles)
  const candidateSkills = useSelector((state) => state.candidateSkills)
  const userLanguage = useSelector((state) => state.language)
  const logged = useSelector((state) => state.login)

  let monthStart = new Date()
  monthStart = subMonths(monthStart, 1)

  if (
    moment(monthStart)
      .startOf("day")
      .isBefore(moment(logged?.user?.account?.created_at).startOf("day"))
  ) {
    monthStart = logged?.user?.account?.created_at
  }

  const [startDate, setStartDate] = useState(monthStart)
  const [endDate, setEndDate] = useState(new Date())
  const [department, setDepartment] = useState([])
  const [vacancy, setVacancy] = useState([])
  const [reqSkills, setReqSkills] = useState([])
  const [experience, setExperience] = useState([0, MAX_LIMIT])
  const [candidate, setCandidate] = useState("")
  const [qualification, setQualification] = useState("")
  const [interviewType, setInterviewType] = useState(t("All"))
  const [interviewFormat, setInterviewFormat] = useState(t("All"))
  const [feedback, setFeedback] = useState("")
  const [rating, setRating] = useState(0)

  const departmentsData = departmentsInfo?.data?.departments || []
  const mappedDepartments = departmentsData.map(({ name }) => name)

  const vacanciesData = roles?.data?.vacancies || []
  const mappedVacanciesData = vacanciesData.map(({ title }) => title)

  const handleClear = () => {
    setStartDate()
    setEndDate()
    setDepartment([])
    setVacancy([])
    setReqSkills([])
    setExperience([0, MAX_LIMIT])
    setCandidate("")
    setQualification("")
    setInterviewType(t("All"))
    setInterviewFormat(t("All"))
    setFeedback("")
    setRating(0)

    setInterviews()
  }
  const applyFilter = () => {
    const filters = {
      feedback: feedback,
      department: department,
      req_skills: reqSkills,
      vacancy: vacancy,
      interview_format: interviewFormat,
      interview_type: interviewType,
      candidate: candidate,
      qualification: qualification,
      experience: { min: experience[0], max: experience[1] },
      date: { start: startDate, end: endDate },
      rating: rating,
    }
    setInterviews(filters)
    onCloseFilter()
  }

  const onKeyDown = (e) => {
    e.preventDefault()
  }

  //-------
  return (
    <>
      <Button
        disableRipple
        color='inherit'
        className='filter-btn'
        endIcon={<FilterListIcon />}
        onClick={onOpenFilter}
      >
        {t("Filters")}&nbsp;
      </Button>

      <Drawer
        anchor={userLanguage?.selectedLanguage === "Arabic" ? "left" : "right"}
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 300, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant='subtitle1' sx={{ ml: 1 }}>
            {t("Filters")}
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <CloseIcon sx={{ mx: 1 }} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("Department")}
              </Typography>
              <FormControl sx={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  id='department-search'
                  MenuProps={MenuProps}
                  value={department}
                  options={mappedDepartments}
                  filterSelectedOptions
                  onChange={(e, value) => setDepartment(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder={t("AllDepartments")}
                    />
                  )}
                />
              </FormControl>
            </div>

            <Box sx={{ position: "relative", width: "100%" }}>
              <Typography variant='subtitle1' gutterBottom>
                {t("DateRange")}
              </Typography>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    sx={{ direction: "ltr " }}
                  >
                    <DesktopDatePicker
                      inputFormat='MM/dd/yyyy'
                      value={startDate}
                      name='startDate'
                      minDate={new Date(logged?.user?.account?.created_at)}
                      maxDate={new Date(endDate)}
                      onChange={(val) => {
                        setStartDate(val)
                        setTStartDate(val)
                      }}
                      renderInput={(params) => (
                        <TextField
                          size='small'
                          sx={{ width: "122px", mr: 1 }}
                          onKeyDown={onKeyDown}
                          {...params}
                        />
                      )}
                      slotProps={{
                        textField: {
                          helperText: "MM/DD/YYYY",
                        },
                      }}
                    />

                    <DesktopDatePicker
                      inputFormat='MM/dd/yyyy'
                      value={endDate}
                      name='endDate'
                      minDate={new Date(startDate)}
                      onChange={(val) => {
                        setEndDate(val)
                        setTEndDate(val)
                      }}
                      renderInput={(params) => (
                        <TextField
                          size='small'
                          sx={{ width: "120px" }}
                          onKeyDown={onKeyDown}
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </FormControl>
            </Box>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("Vacancy")}
              </Typography>
              <FormControl fullWidth>
                <Autocomplete
                  labelId='vac-multiple-chip-label'
                  id='vac-multiple-chip'
                  multiple
                  disableInput={true}
                  MenuProps={MenuProps}
                  value={vacancy}
                  options={mappedVacanciesData}
                  filterSelectedOptions
                  onChange={(e, value) => setVacancy(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder={t("AllVacancies")}
                    />
                  )}
                />
              </FormControl>
            </div>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("Candidate")}
              </Typography>

              <TextField
                fullWidth
                value={candidate}
                variant='outlined'
                placeholder={t("Candidate")}
                name='candidte_search'
                onChange={(e) => setCandidate(e.target.value)}
              />
            </div>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("InterviewType")}
              </Typography>

              <RadioGroup
                value={interviewType}
                onChange={(e) => setInterviewType(e.target.value)}
              >
                {FILTER_INT_TYPE_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={t(item)}
                  />
                ))}
              </RadioGroup>
            </div>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("InterviewFormat")}
              </Typography>

              <RadioGroup
                value={interviewFormat}
                onChange={(e) => setInterviewFormat(e.target.value)}
              >
                {FILTER_INT_FORMAT_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={t(item)}
                  />
                ))}
              </RadioGroup>
            </div>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("Feedback")}
              </Typography>

              <RadioGroup
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              >
                {FILTER_FEEDBACK_OPTIONS.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={t(item)}
                  />
                ))}
              </RadioGroup>
            </div>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("CandidateExperience(inyears)")}
              </Typography>
              <Box sx={{ mx: 1 }}>
                <Slider
                  value={experience}
                  step={1}
                  min={0}
                  max={MAX_LIMIT}
                  valueLabelDisplay='auto'
                  onChange={(e) => setExperience(e.target.value)}
                />
              </Box>
            </div>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("Skills")}
              </Typography>

              <FormControl sx={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  id='tags-outlined'
                  value={reqSkills}
                  MenuProps={MenuProps}
                  options={candidateSkills?.data?.skills ?? []}
                  filterSelectedOptions
                  onChange={(e, value) => setReqSkills(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder={t("Skills")}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("Qualification")}
              </Typography>

              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  value={qualification}
                  variant='outlined'
                  placeholder={t("Qualification")}
                  name='qualification'
                  onChange={(e) => setQualification(e.target.value)}
                />
              </FormControl>
            </div>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("Rating")}
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Rating
                  name='rating'
                  value={rating}
                  disabled={tabing !== "completed"}
                  onChange={(e) => {
                    if (rating === parseInt(e.target.value)) {
                      setRating(0)
                    } else {
                      setRating(parseInt(e.target.value))
                    }
                  }}
                  sx={{ display: "flex", alignItems: "center" }}
                />
                <span style={{ marginLeft: "0.5rem" }}>{t("&Up")}</span>
              </div>
            </div>
          </Stack>
        </Scrollbar>

        <Box
          className='filter-ftr'
          justifyContent='space-evenly'
          sx={{ display: "flex", p: 3 }}
        >
          <Button
            type='reset'
            color='inherit'
            variant='outlined'
            startIcon={<ClearAllOutlinedIcon />}
            onClick={handleClear}
          >
            {t("ClearAll")}
          </Button>
          <Button
            type='submit'
            color='primary'
            variant='contained'
            startIcon={<ManageHistoryOutlinedIcon />}
            onClick={applyFilter}
          >
            {t("Apply")}
          </Button>
        </Box>
      </Drawer>
    </>
  )
}
