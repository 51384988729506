import {
  Grid,
  Container,
  Typography,
  Link,
  Toolbar,
  Button,
  Stack,
} from "@mui/material"
import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

const FooterStyle = styled("footer")(({ theme }) => ({
  width: "100%",
  padding: "24px 0 0 0",
  backgroundColor: "#2065D1",
  textAlign: "center",
}))
const ButtonLarge = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 16,
  fontWeight: 500,

  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#fff",
  color: "#333",
  borderColor: "#0063cc",
  borderRadius: "50px",
  "&:hover": {
    backgroundColor: "#fff",
    opacity: "0.6",
    borderColor: "#0062cc",
    // boxShadow: 'none',
  },
  "&:active": {
    //   boxShadow: 'none',
    backgroundColor: "#fff",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
})

// ----------------------------------------------------------------------

function Copyright() {
  const { t } = useTranslation()
  return (
    <Typography
      variant='body2'
      className='copyright'
      sx={{
        color: "#9498a8",
        fontSize: "14px !important",
        mt: 0,
        lineHeight: "1.2444em",
        textAlign: { xs: "center", sm: "left" },
      }}
    >
      {"© "}
      {new Date().getFullYear()} IntVue. {t("AllRightsReserved")}
    </Typography>
  )
}

export default function XpageFooter() {
  const [visible, setVisible] = useState(false)

  const { t } = useTranslation()

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  window.addEventListener("scroll", toggleVisible)
  return (
    <Container
      maxWidth='xxl'
      spacing={3}
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      {/* <FooterStyle>
          <Container maxWidth="xl" id='contactDiv'>
            <Grid container spacing={3} id='contactDiv' >
              <Grid item sx={{ pt: 6, pb: 5, mt: 2 }} md={12} lg={12}>
                <Typography variant="h2" sx={{ fontSize: '3.5rem', color: '#fff', fontWeight: 600 }}>
                  Take IntVue For a Test Run</Typography>
                <Typography sx={{ pb: 4 }} variant="body1" sx={{ fontSize: '1.5rem', color: '#fff', mb: 2 }}>
                  See firsthand how IntVue helps companies around the world recruit better.
                </Typography>

                <ButtonLarge href="/signup" size="large" variant="contained">
                  Start your free trail
                </ButtonLarge>
              </Grid>
            </Grid>
          </Container>
        </FooterStyle> */}
      <hr className='ftr-hr' />
      <Container maxWidth='xl' sx={{ pb: 0 }}>
        <Grid
          container
          spacing={0}
          sx={{
            justifyContent: {
              xs: "center",
              sm: "space-between",
              md: "space-between",
            },
          }}
        >
          <Grid item xs={12}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems='center'
              justifyContent={{ xs: "center", sm: "space-between" }}
            >
              <Copyright />
              <Toolbar
                component='nav'
                variant='dense'
                className='footer-links'
                sx={{
                  justifyContent: { xs: "center", sm: "flex-end" },
                  overflowX: "auto",
                }}
              >
                <Link
                  color='inherit'
                  underline='none'
                  noWrap
                  variant='body2'
                  href='/termsconditions/app'
                  sx={{ p: 1, flexShrink: 0 }}
                >
                  {t("TermsService")}
                </Link>
                <Link
                  color='inherit'
                  underline='none'
                  noWrap
                  variant='body2'
                  href='/privacypolicy/app'
                  sx={{ p: 1, flexShrink: 0 }}
                >
                  {t("PrivacyPolicy")}
                </Link>
                <Link
                  color='inherit'
                  underline='none'
                  noWrap
                  variant='body2'
                  href='/cookies-policy/app'
                  sx={{ p: 1, flexShrink: 0 }}
                >
                  {t("Cookie Policy")}
                </Link>
              </Toolbar>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      <button
        type='button'
        className='btn-scroll'
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      >
        {" "}
        <ArrowCircleUpOutlinedIcon
          sx={{ width: "40px", height: "40px" }}
        />{" "}
      </button>
    </Container>
  )
}
