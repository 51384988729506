import React, { useEffect, useState } from "react"
import {
  Grid,
  Alert,
  Stack,
  Button,
  FormControl,
  CircularProgress,
  TextField,
  FormLabel,
  Snackbar,
} from "@mui/material"
import * as Yup from "yup"
import { useFormik, Form, FormikProvider } from "formik"
import {
  getAccountConfig,
  updateSMTPConfig,
  resetConfigurationsFlag
} from "src/redux/actions/accountConfig"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { userKey } from "src/utils/userHelper"
// ----------------------------------------------------------------------

export default function Smtp({
  setDisplayCrash,
  setOpenSnackbar,
  setSeverity,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const config = useSelector((state) => state.accountConfigurations)
  const logged = useSelector((state) => state.login)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [key, setKey] = useState(userKey())
  const [initialState, setInitialState] = useState({
    smtpHost: "",
    smtpPassword: "",
    smtpPort: "",
    smtpHostUsername: "",
    smtpDomain: "",
  })

  const submission = (values, { resetForm }) => {
    const formData = {
      smtp_settings: {
        address: values?.smtpHost,
        password: values?.smtpPassword,
        port: values?.smtpPort,
        user_name: values?.smtpHostUsername,
        domain: values?.smtpDomain,
      },
    }
    setSaving(true)
    setLoading(true)
    dispatch(updateSMTPConfig(formData))
  }

  const addSmtpConfigSchema = () =>
    Yup.object().shape({
      smtpHost: Yup.string().required(t("SMTPHostErr")),
      smtpPassword: Yup.string().required(t("SMTPPasswordErr")),
      smtpPort: Yup.number()
        .required(t("SMTPPortErr"))
        .min(0, t("ValidPortErr")),
      smtpHostUsername: Yup.string()
        .email(t("ValidEmailErr"))
        .required(t("SMTPUsernameErr")),
      smtpDomain: Yup.string().required(t("SMTPDomainErr")),
    })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: addSmtpConfigSchema,
    onSubmit: submission,
  })

  const { errors, touched, values, handleSubmit, handleChange } = formik

  useEffect(() => {
    if (config?.data.length === 0) {
      dispatch(getAccountConfig(key))
    }

    if (config?.edited && saving) {
      setSeverity(config?.isFailed ? "error" : "success")
      setOpenSnackbar(true)
      setDisplayCrash(config?.isFailed ? config.error : config.message)
      setSaving(false)
      setLoading(false)
      dispatch(getAccountConfig(key))
      dispatch(resetConfigurationsFlag())
    }

    if (config?.isSucceed && !saving) {
      setInitialState({
        smtpHost: config.data.account_configuration.smtp_settings?.address,
        smtpPassword: config.data.account_configuration.smtp_settings?.password,
        smtpPort: config.data.account_configuration.smtp_settings?.port,
        smtpHostUsername:
          config.data.account_configuration.smtp_settings?.user_name ??
          loggedUserEmail(),
        smtpDomain:
          config.data.account_configuration.smtp_settings?.domain ??
          loggedUserDomain(),
      })
    }
  }, [config])

  const loggedUserEmail = () => {
    return logged.user.email
  }

  const loggedUserDomain = () => {
    return logged.user.email.split("@")[1]
  }

  /*    ---------------------------------------- */
  return (
    <>
      <Alert severity='info'>{t("SmtpInfo")}</Alert>
      <FormikProvider value={formik}>
        <Form
          id='smtpForm'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel sx={{ mb: 1 }} required>{t("Domain")}</FormLabel>
                <TextField
                  fullWidth
                  type='text'
                  variant='outlined'
                  placeholder={t("Domain")}
                  name='smtpDomain'
                  value={values.smtpDomain}
                  onChange={handleChange}
                  aria-describedby='title-helper-text'
                  error={Boolean(touched.smtpDomain && errors.smtpDomain)}
                  helperText={touched.smtpDomain && errors.smtpDomain}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel sx={{ mb: 1 }} required>{t("smtpHost")}</FormLabel>
                <TextField
                  fullWidth
                  type='text'
                  variant='outlined'
                  placeholder={t("smtpHost")}
                  name='smtpHost'
                  value={values.smtpHost}
                  onChange={handleChange}
                  aria-describedby='title-helper-text'
                  error={Boolean(touched.smtpHost && errors.smtpHost)}
                  helperText={touched.smtpHost && errors.smtpHost}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel sx={{ mb: 1 }} required>{t("smtpHostUsername")}</FormLabel>
                <TextField
                  fullWidth
                  type='text'
                  variant='outlined'
                  placeholder={t("smtpHostUsername")}
                  name='smtpHostUsername'
                  value={values.smtpHostUsername}
                  onChange={handleChange}
                  aria-describedby='title-helper-text'
                  error={Boolean(
                    touched.smtpHostUsername && errors.smtpHostUsername,
                  )}
                  helperText={
                    touched.smtpHostUsername && errors.smtpHostUsername
                  }
                  pattern='^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$'
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel sx={{ mb: 1 }} required>{t("smtpPassword")}</FormLabel>
                <TextField
                  fullWidth
                  type='text'
                  variant='outlined'
                  placeholder={t("smtpPassword")}
                  name='smtpPassword'
                  value={values.smtpPassword}
                  onChange={handleChange}
                  aria-describedby='title-helper-text'
                  error={Boolean(touched.smtpPassword && errors.smtpPassword)}
                  helperText={touched.smtpPassword && errors.smtpPassword}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel sx={{ mb: 1 }} required>{t("smtpPort")}</FormLabel>
                <TextField
                  fullWidth
                  type='number'
                  variant='outlined'
                  placeholder={t("smtpPort")}
                  name='smtpPort'
                  value={values.smtpPort}
                  onChange={handleChange}
                  error={Boolean(touched.smtpPort && errors.smtpPort)}
                  helperText={touched.smtpPort && errors.smtpPort}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <Grid item xs={12} sm={4}>
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-start'
          spacing={4}
        >
          <Button
            loadingindicator='Loading…'
            variant='contained'
            color='primary'
            type='submit'
            onClick={handleSubmit}
            disabled={loading}
          >
            {t("Save")}
          </Button>

          {loading && <CircularProgress />}
        </Stack>
      </Grid>
    </>
  )
}
