import React from "react"
import Countdown from "react-countdown"
import { Stack, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import CircleIcon from "@mui/icons-material/Circle"
const Panel = styled("div")(({ theme }) => ({
  padding: "1.0rem 0",
}))

const TimerInt = React.memo(({ totalTime, stop }) => {
  const _totalTime = stop ? 0 : totalTime

  const interviewTimeHasEnded = () => {
    return (
      <Stack
        className='countdown-time finished'
        direction='column'
        justifyContent='flex-end'
        alignItems='flex-end'
      >
        <Typography
          variant='body2'
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          Recording
        </Typography>
        <Typography variant='h4'>00:00:00</Typography>
      </Stack>
    )
  }

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return interviewTimeHasEnded()
    } else {
      return (
        <Stack
          className='countdown-time'
          direction='column'
          justifyContent='flex-end'
          alignItems='flex-end'
          sx={{ fontSize: "18px", fontWeight: "600", pt: 0 }}
        >
          <Typography
            variant='body2'
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Recording
          </Typography>
          <Typography variant='h4'>
            <CircleIcon
              sx={{ fontSize: "14px", mx: { xs: 0, sm: 1 }, color: "#F87272" }}
            />{" "}
            {hours}:{minutes}:{seconds}
          </Typography>
        </Stack>
      )
    }
  }

  return (
    <Panel sx={{ py: 0, minWidth: "92px" }}>
      <Countdown date={Date.now() + _totalTime} renderer={renderer} />
    </Panel>
  )
})

export default TimerInt
