import React, { useState, useEffect, useCallback } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import LoadingButton from "@mui/lab/LoadingButton"
import Images from "src/assets/img/Images"
import { buyInterviews } from "src/redux/actions/subscription"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from "@mui/icons-material/Add"
import { handleApplyCoupon } from "src/utils/userHelper"
import { useTranslation } from "react-i18next"

import {
  Box,
  Button,
  Typography,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material"

export default function BuyInterviewsModal({
  open,
  planId,
  plans,
  onInterviewsBought,
  onClose,
}) {
  const dispatch = useDispatch()
  const [message, setMessage] = useState("")

  const [isBuying, setIsBuying] = useState(false)
  const [interviewsCount, setInterviewsCount] = useState(1)

  const { t } = useTranslation()
  const buyInterviewsStatus = useSelector((state) => state.buyInterviews)
  const [discountedPrice, setDiscountedPrice] = useState("")

  useEffect(() => {
    if (isBuying) {
      setIsBuying(false)

      if (buyInterviewsStatus?.bought) {
        if (onInterviewsBought) {
          onInterviewsBought()
          handleClose()
        }
      } else {
        setMessage(t("ErrFailedBuyingIntTry"))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyInterviewsStatus])

  useEffect(() => {
    if (!open) {
      setDiscountedPrice("")
    }
  }, [open])
  const getPerInterviewPriceOfSubscribedPlan = () => {
    if (plans.length > 0) {
      return plans.find((p) => p.id === planId)?.per_unit_price
    }
    return 5.09
  }

  const confirmButtonClicked = () => {
    if (planId) {
      setMessage("")
      setIsBuying(true)
      dispatch(
        buyInterviews({
          plan_id: planId,
          interviews_to_buy_count: interviewsCount,
        }),
      )
    }
  }

  const minusButtonClicked = () => {
    if (interviewsCount > 1) {
      setInterviewsCount(interviewsCount - 1)
    }
  }

  const plusButtonClicked = () => {
    setInterviewsCount(interviewsCount + 1)
  }

  const handleClose = () => {
    setMessage("")
    setIsBuying(false)
    setInterviewsCount(1)
    // setDiscountedPrice("")
    onClose()
  }

  const handleApplyCouponLocal = (
    discount,
    perInterviewPrice,
    interviewsCount,
    setDiscountedPrice,
  ) => {
    handleApplyCoupon(
      25,
      getPerInterviewPriceOfSubscribedPlan,
      interviewsCount,
      setDiscountedPrice,
    )
  }
  return (
    <Dialog
      className='dialog400'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='md'
    >
      <DialogTitle className='dialog-title' sx={{ height: "2px", padding: 0 }}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
          disabled={isBuying}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className='dialog-content'
        dividers={true}
        sx={{ border: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "center", mb: 1, mt: 2 }}
              className='image-banner'
            >
              <img src={Images.PurchaseIcon} alt='UnsubImg' />
            </Box>
            <Typography
              variant='h4'
              component='h4'
              sx={{ textAlign: "center", fontWeight: 600, px: 2 }}
            >
              {t("MsgSelectIntToBuy")}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ justifyContent: "center" }}>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <Stack
                spacing={3}
                direction='row'
                justifyContent='center'
                sx={{
                  width: "114px",
                  maxWidth: "120px",
                  display: "inline-flex",
                  border: " 1px solid #d2d2d2",
                  borderRadius: "6px",
                  padding: " 2px",
                  direction: "ltr",
                }}
              >
                <Button
                  onClick={minusButtonClicked}
                  variant='contained'
                  color='info'
                  size='small'
                  sx={{ maxWidth: "25px", minWidth: "25px" }}
                  disabled={isBuying}
                >
                  <RemoveIcon sx={{ fontSize: "14px" }} />
                </Button>

                <Typography> {interviewsCount} </Typography>
                <Button
                  onClick={plusButtonClicked}
                  variant='contained'
                  color='info'
                  size='small'
                  sx={{ maxWidth: "25px", minWidth: "25px" }}
                  disabled={isBuying}
                >
                  <AddIcon sx={{ fontSize: "14px" }} />
                </Button>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Alert
              severity='warning'
              icon={false}
              sx={{
                justifyContent: "center",
                color: "rgb(106 64 59)",
                backgroundColor: "rgb(245 221 200 / 45%)",
                textAlign: "center",
                px: 5,
              }}
            >
              {/* <Stack
                direction='row'
                justifyContent='space-between'
                sx={{ mb: 1 }}
              >
                <TextField
                  variant='outlined'
                  name='coupon-code'
                  placeholder={t("Enter Code")}
                  size='small'
                />
                <Button
                  variant='outlined'
                  onClick={() =>
                    handleApplyCouponLocal(
                      25,
                      getPerInterviewPriceOfSubscribedPlan,
                      interviewsCount,
                      setDiscountedPrice,
                    )
                  }
                >
                  {discountedPrice ? "Applied" : "Apply"}
                </Button>
              </Stack> */}
              {t("YoullBeCharged")} :
              <Typography
                component='span'
                fontWeight='bold'
                // sx={{
                //   textDecoration: discountedPrice ? "line-through" : "none",
                // }}
              >
                {" $"}
                {(
                  getPerInterviewPriceOfSubscribedPlan() * interviewsCount
                ).toFixed(2)}
              </Typography>
              {/* {discountedPrice && (
                <Typography component='span' sx={{ mx: 1, fontWeight: "500" }}>
                  {" $"}
                  {discountedPrice.toFixed(2)}
                </Typography>
              )} */}
            </Alert>
          </Grid>
          {message && (
            <Grid item xs={12}>
              <Alert
                severity='error'
                icon={false}
                sx={{ justifyContent: "center", textAlign: "center", px: 5 }}
              >
                {message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        className='dialog-footer'
        sx={{ justifyContent: "center", pb: 2 }}
      >
        <Button
          onClick={handleClose}
          variant='outlined'
          color='secondary'
          sx={{
            mr: 1,
            backgroundColor: "transparent",
            borderColor: "#8b8e92",
            color: "#64748b",
            "&:hover": {
              opacity: "0.7",
              backgroundColor: "#8b8e92",
              borderColor: "#8b8e92",
              color: "#fff",
            },
          }}
          disabled={isBuying}
          size='medium'
        >
          {t("Cancel")}
        </Button>

        <LoadingButton
          onClick={confirmButtonClicked}
          loading={isBuying}
          variant='contained'
          color='info'
          size='medium'
        >
          {t("Confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
