import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/questions";

export const getAllQuestionSet = () => {
  const endpoint = "/api/v1/question_sets";
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };
  options.body = null;
  options.types = [
    ActionTypes.QUESTIONSETS_ON_SUCCESS,
    ActionTypes.QUESTIONSETS_ON_FAILURE,
  ];

  return request(options);
};

export const createQuestionSet = (data) => {
  const endpoint = "/api/v1/question_sets";
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CREATEQUESTIONSET_ON_SUCCESS,
    ActionTypes.CREATEQUESTIONSET_ON_FAILURE,
  ];

  return request(options);
};

export const updateQuestionSet = (id, data) => {
  const endpoint = `/api/v1/question_sets/${id}`;
  const method = "PUT";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };
  options.body = data;
  options.types = [
    ActionTypes.UPDATEQUESTIONSET_ON_SUCCESS,
    ActionTypes.UPDATEQUESTIONSET_ON_FAILURE,
  ];

  return request(options);
};

export const deleteQuestionSet = (id) => {
  const endpoint = `/api/v1/question_sets/${id}`;
  const method = "DELETE";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };
  options.body = null;
  options.types = [
    ActionTypes.DELETEQUESTIONSET_ON_SUCCESS,
    ActionTypes.DELETEQUESTIONSET_ON_FAILURE,
  ];

  return request(options);
};

export const resetQuestionsFlag = () => {
  return { type: ActionTypes.RESET_FLAGS };
};

export const resetCreateQuestionsFlag = () => {
  return { type: ActionTypes.RESET_CREATED };
};
