import { useState, useEffect, useRef } from "react"

const CAPTURE_OPTIONS = {
  audio: true,
  video: true,
}

export function useUserMedia(requestedMedia = CAPTURE_OPTIONS) {
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [mediaStream, setMediaStream] = useState(null)
  const [retrying, setRetrying] = useState(false)
  const checking = useRef(false)

  const handleSuccess = (stream) => {
    setLoading(false)
    setRetrying(false)
    setMediaStream(stream)
    setCheckingFalseAfterDelay()
  }

  const handleError = (error) => {
    setError(error)
    setLoading(false)
    setRetrying(false)
    setMediaStream(null)
    setCheckingFalseAfterDelay()
    let message = error

    if (
      error.name === "NotFoundError" ||
      error.name === "OverconstrainedError"
    ) {
      message = `Please attach your ${
        requestedMedia.video ? "camera" : "microphone"
      }`
    } else if (error.name === "NotAllowedError") {
      message = `Please grant permission to access your ${
        requestedMedia.video ? "camera" : "microphone"
      }`
    }

    alert(message)
  }

  const startStream = () => {
    navigator.mediaDevices
      .getUserMedia(requestedMedia)
      .then(handleSuccess)
      .catch(handleError)
  }

  const handleDeviceChange = async () => {
    try {
      startStream()
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    navigator.mediaDevices.addEventListener("devicechange", handleDeviceChange)

    if (!mediaStream && !checking.current) {
      checking.current = true
      startStream()
    }

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChange,
      )

      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop()
        })
      }
    }
  }, [retrying])

  const setCheckingFalseAfterDelay = () => {
    setTimeout(() => {
      checking.current = false
    }, 1000)
  }

  const retry = () => {
    setMediaStream(null)
    setLoading(true)
    setRetrying(true)
  }

  return { loading, mediaStream, error, retry }
}
