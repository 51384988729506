import React, { useState } from "react"
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Typography,
  Box,
  Stack,
  InputAdornment,
  IconButton,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import validator from "validator"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import VisibilityIcon from "@mui/icons-material/Visibility"
const StepperInner = styled("div")(({ theme }) => ({
  margin: "0 0 1.5rem 0",
}))

function Step2({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleEmailAvailabilityCheck,
  setFieldValue,
  getFieldProps,
  passwordField,
  passwordStrength,
  handlePasswordChange,
  ...props
}) {
  const [isEmailAvailable, setIsEmailAvailable] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const handleEmailChange = (e) => {
    handleChange(e)

    const { value } = e.target

    if (validator.isEmail(value)) {
      const BASE_URL = process.env.REACT_APP_BASE_URL

      fetch(
        `${BASE_URL}/api/admin/general/verify_email_presence?email=${value}`,
      )
        .then((response) => response.text())
        .then((data) => {
          data = data ? JSON.parse(data) : {}
          setIsEmailAvailable(data?.success)
          handleEmailAvailabilityCheck(data?.success)
        })
    }
  }
  const handleChangePaste = (e) => {
    e.preventDefault()
  }

  const segmentStyle = {
    flex: "1",
    width: "40px",
    backgroundColor: "gray",
    margin: "0 2px",
  }
  const getSegmentColor = (segment) => {
    if (passwordStrength === "Weak") {
      return segment === "Weak" ? "red" : "gray"
    } else if (passwordStrength === "Medium") {
      return segment === "Weak" || segment === "Medium" ? "orange" : "gray"
    } else if (passwordStrength === "Strong") {
      return "green"
    } else return "gray"
  }

  const strengthIndicatorStyle = {
    width: "100%",
    height: "8px",
    display: "flex",
  }
  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show)
  }
  return (
    <StepperInner>
      <Typography variant='h4' sx={{ fontFamily: "Proxima-Nova-Bold", mb: 2 }}>
        User Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <FormLabel htmlFor='firstName' required>
              First Name
            </FormLabel>
            <TextField
              fullWidth
              id='firstName'
              name='firstName'
              value={values.firstName}
              onChange={handleChange}
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              placeholder='First Name'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <FormLabel htmlFor='lastName' required>
              Last Name
            </FormLabel>
            <TextField
              fullWidth
              id='lastName'
              name='lastName'
              value={values.lastName}
              {...getFieldProps("lastName")}
              onChange={handleChange}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              placeholder='Last Name'
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <FormLabel htmlFor='email-account' required>
              Email Address
            </FormLabel>
            <TextField
              fullWidth
              id='emailAccount'
              name='emailAccount'
              value={values.emailAccount}
              {...getFieldProps("emailAccount")}
              onBlur={(e) => {
                handleEmailChange(e)
                handleBlur(e)
              }}
              onChange={handleChange}
              error={Boolean(touched.emailAccount && errors.emailAccount)}
              helperText={touched.emailAccount && errors.emailAccount}
              placeholder='Email Address'
            />
            {isEmailAvailable === false && (
              <Typography sx={{ color: "red" }}>
                Email already exists
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} className='hide-xs'></Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <FormLabel htmlFor='password' required>
              Password
            </FormLabel>
            <TextField
              fullWidth
              id='password'
              name='password'
              type={showPassword ? "text" : "password"}
              value={values.password}
              onChange={handlePasswordChange}
              autoComplete='off'
              onBlur={handleBlur}
              // {...getFieldProps("password")}
              onPaste={handleChangePaste}
              onCopy={handleChangePaste}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              placeholder='It must be a min of 8 characters long(letters and digits).'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleShowPassword} edge='end'>
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {passwordStrength === "" ? null : (
              <Stack
                direction='row'
                justifyContent='space-between'
                sx={{
                  width: "100%",
                  my: 1,
                }}
              >
                <Typography variant='body2' sx={{ width: "40%" }}>
                  Password Strength
                </Typography>

                <Box>
                  <div style={strengthIndicatorStyle}>
                    <div
                      style={{
                        ...segmentStyle,
                        backgroundColor: getSegmentColor("Weak"),
                      }}
                    ></div>
                    <div
                      style={{
                        ...segmentStyle,
                        backgroundColor: getSegmentColor("Medium"),
                      }}
                    ></div>
                    <div
                      style={{
                        ...segmentStyle,
                        backgroundColor: getSegmentColor("Strong"),
                      }}
                    ></div>
                  </div>
                  <Typography variant='body2' sx={{ textAlign: "right" }}>
                    {passwordStrength}
                  </Typography>
                </Box>
              </Stack>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <FormControl fullWidth sx={{ mb: 1 }}>
            <FormLabel htmlFor='confirm_password' required>
              Confirm Password
            </FormLabel>
            <TextField
              autoComplete='off'
              fullWidth
              type={showConfirmPassword ? "text" : "password"}
              id='confirm_password'
              {...getFieldProps("confirm_password")}
              value={values.confirm_password}
              onChange={handleChange}
              onPaste={handleChangePaste}
              onCopy={handleChangePaste}
              error={
                touched.confirm_password && Boolean(errors.confirm_password)
              }
              helperText={touched.confirm_password && errors.confirm_password}
              placeholder='It must be a min of 8 characters long(letters and digits).'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleShowConfirmPassword} edge='end'>
                      {showConfirmPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        </Grid>
      </Grid>
    </StepperInner>
  )
}

export default Step2
