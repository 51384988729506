import * as ActionTypes from "../constants/general"
const initialState = {
  data: [],
  message: "",
  ownershipTransfer: false,
  dataDeleted: false,
  depPresent: false,
}

export const getCompanyInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHACCOUNT_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }
    case ActionTypes.FETCHACCOUNT_ON_FAILURE:
      return {
        ...state,
        data: action.payload,
        isSucceed: false,
      }

    default:
      return state
  }
}

export const updateCompanyInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PUTACCOUNT_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }
    case ActionTypes.TRANSFER_ACCOUNT_OWNERSHIP_ON_SUCCESS:
      return {
        ...state,
        data: action,
        ownershipTransfer: true,
        message: action.payload?.message,
      }
    case ActionTypes.TRANSFER_ACCOUNT_OWNERSHIP_ON_FAILURE:
      return {
        ...state,
        data: action,
        ownershipTransfer: false,
        message: action.payload?.data?.errors,
      }
    case ActionTypes.DELETE_ACCOUNT_DATA_ON_SUCCESS:
      return {
        ...state,
        data: action,
        dataDeleted: true,
        depPresent: action.payload?.dependencies_present,
        message: action.payload?.message,
      }
    case ActionTypes.DELETE_ACCOUNT_DATA_ON_FAILURE:
      return {
        ...state,
        data: action,
        dataDeleted: false,
        depPresent: action.payload?.dependencies_present,
        message: action.payload?.data?.errors ?? action.payload?.errors,
      }
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        data: [],
        isSucceed: false,
        ownershipTransfer: false,
        dataDeleted: false,
        depPresent: false,
      }
    default:
      return state
  }
}
