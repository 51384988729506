const planTier =
    [
        {
            "id": 1,
            "active": true,
            "currency": "usd",
            "interval": "month",
            "nickname": "Basic Monthly",
            "billing_scheme": "per_unit",
            "per_unit_price": "5.99",
            "tiers": null,
            "interview_count": 10,
            "plan_type": "basic_monthly",
            "price": 49.9
        },
        {
            "id": 2,
            "active": false,
            "currency": "usd",
            "interval": "month",
            "nickname": "Pro Monthly",
            "billing_scheme": "per_unit",
            "per_unit_price": "5.99",
            "tiers": null,
            "interview_count": 25,
            "plan_type": "pro_monthly",
            "price": 99.75
        },
        {
            "id": 3,
            "active": false,
            "currency": "usd",
            "interval": "month",
            "nickname": "Pro Plus Monthly",
            "billing_scheme": "per_unit",
            "per_unit_price": "5.99",
            "tiers": null,
            "interview_count": 50,
            "plan_type": "pro_plus_monthly",
            "price": 174.5
        },
        {
            "id": 4,
            "active": false,
            "currency": "usd",
            "interval": "year",
            "nickname": "Basic Yearly",
            "billing_scheme": "per_unit",
            "per_unit_price": "5.09",
            "tiers": null,
            "interview_count": 120,
            "plan_type": "basic_yearly",
            "price": 508.98
        },
        {
            "id": 5,
            "active": false,
            "currency": "usd",
            "interval": "year",
            "nickname": "Pro Yearly",
            "billing_scheme": "per_unit",
            "per_unit_price": "5.09",
            "tiers": null,
            "interview_count": 300,
            "plan_type": "pro_yearly",
            "price": 1017.45
        },
        {
            "id": 6,
            "active": false,
            "currency": "usd",
            "interval": "year",
            "nickname": "Pro Plus Yearly",
            "billing_scheme": "per_unit",
            "per_unit_price": "5.09",
            "tiers": null,
            "interview_count": 600,
            "plan_type": "pro_plus_yearly",
            "price": 1779.9
        }
    ]



const SAVING = ['89.82', '179.55', '314.1'];

// ----------------------------------------------------------------------

const plans = [...planTier].map((key, index) => {
    const setIndex = index + 1;
    return {
        id: planTier[index],
        name: planTier[index].nickname,
        price: planTier[index].price,
        status: planTier[index].active,
        currency: planTier[index].currency,
        interval: planTier[index].interval,
        billing_scheme: planTier[index].billing_scheme,
        per_unit_price: planTier[index].per_unit_price,
        total_interviews: planTier[index].interview_count,
        plan_type: planTier[index].plan_type,
        saving: (setIndex === 4 && SAVING[0]) ||
            (setIndex === 5 && SAVING[1]) ||
            (setIndex === 6 && SAVING[2])
        ,
    };

});

export default plans;
