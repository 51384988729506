import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/subscription";

export const changePlan = (data) => {
  const endpoint = `/api/admin/subscriptions/change_plan`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CHANGEPLAN_ON_SUCCESS,
    ActionTypes.CHANGEPLAN_ON_FAILURE,
  ];
  return request(options);
};

export const activateBilling = () => {
  const endpoint = `/api/admin/subscriptions/activate_billing`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.types = [
    ActionTypes.ACTIVATEBILL_ON_SUCCESS,
    ActionTypes.ACTIVATEBILL_ON_FAILURE,
  ];
  return request(options);
};

export const buyInterviews = (data) => {
  const endpoint = `/api/admin/subscriptions/buy_extra_interview`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.BUYINTERVIEW_ON_SUCCESS,
    ActionTypes.BUYINTERVIEW_ON_FAILURE,
  ];
  return request(options);
};

export const upgradeSubscription = (data) => {
  const endpoint = `/api/admin/subscriptions/upgrade_subscription_plan`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.UPGRADESUBSCRIPTION_ON_SUCCESS,
    ActionTypes.UPGRADESUBSCRIPTION_ON_FAILURE,
  ];
  return request(options);
};

export const getPlans = () => {
  const endpoint = "/api/v1/plans";
  const method = "GET";
  const headers = null;
  const options = { action: endpoint, method: method, headers: headers };

  options.types = [ActionTypes.PLANS_ON_SUCCESS, ActionTypes.PLANS_ON_FAILURE];
  return request(options);
};

export const getSubscriptionInfo = () => {
  const endpoint = "/api/admin/subscriptions/subscription_info";
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.types = [
    ActionTypes.SUBSCRIPTIONINFO_ON_SUCCESS,
    ActionTypes.SUBSCRIPTIONINFO_ON_FAILURE,
  ];
  return request(options);
};

export const createSubscription = (data) => {
  const endpoint = "/api/admin/subscriptions/setup_subscription";
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CREATESUBSCRIPTION_ON_SUCCESS,
    ActionTypes.CREATESUBSCRIPTION_ON_FAILURE,
  ];
  return request(options);
};

export const cancelSubscription = (data) => {
  const endpoint = "/api/admin/subscriptions/cancel";
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CANCELSUBSCRIPTION_ON_SUCCESS,
    ActionTypes.CANCELSUBSCRIPTION_ON_FAILURE,
  ];
  return request(options);
};
