import * as ActionTypes from "../constants/general"
import { request } from "../middlewares/service"

export const getCompanyInfo = (id) => {
  const endpoint = `/api/admin/general/${id}`
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = null
  options.types = [
    ActionTypes.FETCHACCOUNT_ON_SUCCESS,
    ActionTypes.FETCHACCOUNT_ON_FAILURE,
  ]
  return request(options)
}

export const updateCompanyInfo = (id, data) => {
  const endpoint = `/api/admin/general/${id}`
  const method = "PUT"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.PUTACCOUNT_ON_SUCCESS,
    ActionTypes.PUTACCOUNT_ON_FAILURE,
  ]
  return request(options)
}

export const transferOwnership = (id, data) => {
  const endpoint = `/api/admin/general/${id}/transfer_ownership`
  const method = "PUT"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.TRANSFER_ACCOUNT_OWNERSHIP_ON_SUCCESS,
    ActionTypes.TRANSFER_ACCOUNT_OWNERSHIP_ON_FAILURE,
  ]
  return request(options)
}

export const deleteModuleData = (id, data) => {
  const endpoint = `/api/admin/general/${id}/delete_data`
  const method = "DELETE"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.DELETE_ACCOUNT_DATA_ON_SUCCESS,
    ActionTypes.DELETE_ACCOUNT_DATA_ON_FAILURE,
  ]
  return request(options)
}

export const resetSettingsFlag = () => {
  return { type: ActionTypes.RESET_FLAGS }
}
