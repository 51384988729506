import * as ActionTypes from "../constants/container"

export const container = (data) => {
  return {
    type: ActionTypes.SELECTED_ID,
    payload: data,
  }
}

export const checkUser = (data) => {
  return {
    type: data,
  }
}

export const awaitingFeedback = (data) => {
  return {
    type: data,
  }
}
