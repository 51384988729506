import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import TimezonePicker from "react-timezone"
import {
  Button,
  MenuItem,
  Chip,
  TextField,
  IconButton,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  OutlinedInput,
  RadioGroup,
  Radio,
  Autocomplete,
  Typography,
  Box,
} from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"

import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { useTheme, styled } from "@mui/material/styles"
import { useFormik, Form, FormikProvider } from "formik"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { fDate, fDateTime, fTime } from "src/utils/formatTime"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { createInterview, updateInterview } from "src/redux/actions/interviews"
import AddInterviewerModal from "./addInterviewer"
import MuiAlert from "@mui/material/Alert"
import moment from "moment"
import { isEmpty } from "lodash"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import DragDrop from "src/components/dragndrop/DragDrop"
import "moment-timezone"
import { AddQuestionModal } from "src/sections/@dashboard/questions"
import { AddRoleModal } from "src/sections/@dashboard/roles"

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5242880 //1 gb file

let VALID_FILE_TYPES = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "text/plain",
]

const candidateSkillsArr = [
  { name: "hr", value: "HR" },
  { name: "java", value: "JAVA" },
  { name: "csharp", value: "C#" },
  { name: "jscript", value: "JavaScript" },
  { name: "angular", value: "Angular" },
  { name: "reactjs", value: "ReactJs" },
  { name: "reactnative", value: "React Native" },
  { name: "designing", value: "Designing" },
]

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export default function ScheduleIntModal({
  isShowingScheduleInt,
  hide,
  data,
  flag,
  interviews = [],
}) {
  const [isShowingInterviewer, toggleInterviewer] = useModal()
  const [isShowingQuestion, toggleQuestion] = useModal()
  const [isShowingRole, toggleRole] = useModal()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const [selected, setSelected] = useState([])
  const [vacancySkills, setVacancySkills] = useState(
    candidateSkillsArr.map((option) => option.value),
  )
  const [roleArr, setRoleArr] = useState("")
  const [isRecordByAppRadio, setIsRecordByAppRadio] = useState(false)
  const [isFacetoFaceRadio, setIsFacetoFaceRadio] = useState(false)
  const [isVideoRadio, setIsVideoRadio] = useState(false)
  const [value, setValue] = useState(new Date())

  const intVuerList = useSelector((state) => state.interviewer)
  const roleList = useSelector((state) => state.roles)

  const [uploadedResume, setUploadedResume] = useState(null)
  const [resumeUpdated, setResumeUpdated] = useState(false)
  const [fileError, setFileError] = useState("")

  const [initialState, setInitialState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    experience: "",
    role: "",
    status: "",
    interviewers: [],
    intvueTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    intvueType: "",
    intvueDate: fDate(new Date()),
    intvueTime: fDateTime(new Date()),
    intvueExpiry: fDate(new Date()),
    intvueLocation: "",
    intvueMeetingLink: "",
    resume: "",
    candidateNote: "",
    candidateSkills: [],
    qualification: "",
    departments: "",
    questionSet: null,
  })

  useEffect(() => {
    if (data) {
      setRoleArr(data?.candidate_vacancy?.vacancy?.title)
      setSelected(
        data?.interviewers?.map(
          (item) => item?.user?.first_name + " " + item?.user?.last_name,
        ),
      )

      if (data?.interview_format === "recorded_by_applicant") {
        setIsRecordByAppRadio(true)
        setIsFacetoFaceRadio(false)
        setIsVideoRadio(false)
        setFieldValue("intvueFormate", "recorded_by_applicant")
        setFieldValue("intvueType", "recorded_by_applicant")
      } else if (data?.interview_format === "on_site") {
        setIsRecordByAppRadio(false)
        setIsFacetoFaceRadio(true)
        setIsVideoRadio(false)
        setFieldValue("intvueFormate", "on_site")
        setFieldValue("intvueType", "on_site")
      } else if (data?.interview_format == "online_video_call") {
        setIsRecordByAppRadio(false)
        setIsFacetoFaceRadio(false)
        setIsVideoRadio(true)
        setFieldValue("intvueFormate", "online_video_call")
        setFieldValue("intvueType", "online_video_call")
      }

      setTimeZone(data?.timezone)
      const quest = existingQuestionSets?.data?.question_sets.filter(
        (QS) =>
          QS.department_id === data?.candidate_vacancy?.vacancy.department_id,
      )

      const currentQS = quest.find(
        (q) => q.id === data?.interview_questions[0]?.question?.question_set_id,
      )
      setAvailableQS(quest)

      setFieldValue("firstName", data?.candidate_vacancy?.candidate?.first_name)
      setFieldValue("lastName", data?.candidate_vacancy?.candidate?.last_name)
      setFieldValue("email", data?.candidate_vacancy?.candidate?.email)
      setFieldValue("intvueLocation", data?.interview_location ?? "")
      setFieldValue("intvueMeetingLink", data?.interview_video_link)
      setFieldValue("questionSet", currentQS?.id)
      setSelectedQS(currentQS?.id)
      setDateTimeLimts(data?.timezone)

      const currentVacSkills =
        data?.candidate_vacancy?.vacancy.department.skills
      setVacancySkills(currentVacSkills)

      setInitialState({
        firstName: data?.candidate_vacancy?.candidate?.first_name,
        lastName: data?.candidate_vacancy?.candidate?.last_name,
        email: data?.candidate_vacancy?.candidate?.email,
        phone: data?.candidate_vacancy?.candidate?.phone,
        qualification: data?.candidate_vacancy?.candidate?.qualification,
        experience: data?.candidate_vacancy?.candidate?.experience,
        candidateSkills: data?.candidate_vacancy?.candidate?.skills,
        role: data?.candidate_vacancy?.vacancy?.id,
        status: data?.status,
        interviewers: data?.interviewers?.map((item) => item?.user?.full_name),
        intvueTimeZone: data?.timezone,
        intvueType: data?.interview_format,
        intvueDate: data?.interview_date,
        intvueTime: data?.interview_time
          ? data?.interview_time.split(".")[0]
          : data?.interview_time,
        intvueExpiry: data?.expires_on,
        intvueLocation: data?.interview_location ?? "",
        intvueMeetingLink: data?.interview_video_link ?? "",
        resume: data?.candidate_vacancy?.candidate?.resume_details,
        candidateNote: data?.message ?? "",
        intvueFormate: data?.interview_format,
        questionSet:
          data?.interview_questions[0]?.question?.question_set_id ?? "",
      })

      if (data?.candidate_vacancy?.candidate?.resume?.url) {
        const resumeUrl =
          data?.candidate_vacancy?.candidate?.resume?.url?.split("?")[0]
        setUploadedResume(resumeUrl)
      }
    }
  }, [data])

  useEffect(() => {
    if (isShowingScheduleInt && flag !== false) {
      setIsRecordByAppRadio(true)
      setIsFacetoFaceRadio(false)
      setIsVideoRadio(false)
      setFieldValue("intvueFormate", "recorded_by_applicant")
      setFieldValue("intvueType", "recorded_by_applicant")
    }
  }, [isShowingScheduleInt])

  const [error, setError] = useState("")
  const [selectInterviewerError, setSelectInterviewerError] = useState("")
  const [selectVacancyError, setSelectVacancyError] = useState("")
  const [candidateSkillsError, setCandidateSkillsError] = useState("")
  const [selectInterviewTypeError, setSelectInterviewTypeError] = useState("")
  const [timezoneError, setTimezoneError] = useState("")

  const [selectedQS, setSelectedQS] = useState(null)
  const [questionSetSelectedError, setQuestionSetSelectedError] = useState("")
  const existingQuestionSets = useSelector((state) => state.questions)

  const [availableQS, setAvailableQS] = useState([])
  const [deptName, setDeptName] = useState("")

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })

  const [defaultCountry, setDefaultCountry] = useState("")

  useEffect(() => {
    const getTimezoneToCountryCode = (timezone) => {
      const timezoneToCountryCode = {
        "America/New_York": "us",
        "America/Los_Angeles": "us",
        "Asia/Karachi": "pk",
      }
      return timezoneToCountryCode[timezone] || "us"
    }

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const userCountryCode = getTimezoneToCountryCode(userTimeZone)
    setDefaultCountry(userCountryCode)
  }, [])

  useEffect(() => {
    if (existingQuestionSets?.data?.question_sets) {
      if (values.role !== "") {
        const selectedVacancy = roleList?.data?.vacancies?.find(
          (vacancy) => vacancy.id === values.role,
        )
        setAvailableQS(
          existingQuestionSets?.data?.question_sets?.filter(
            (QS) => QS.department_id === selectedVacancy?.department_id,
          ),
        )
      } else {
        setAvailableQS(existingQuestionSets.data.question_sets)
      }
    }
  }, [existingQuestionSets])

  const selectionChangeHandler = (event) => {
    setSelected(event.target.value)
    setFieldValue("interviewers", event.target.value)
    setSelectInterviewerError("")
    setQuestionSetSelectedError("")
  }

  const submission = (values, { resetForm }) => {
    if (isEmpty(values?.intvueTimeZone)) {
      setTimezoneError(t("ErrSelectIntTimezone"))
    } else {
      setTimezoneError("")
    }
    if (
      (values?.questionSet === null || values?.questionSet === undefined) &&
      isRecordByAppRadio
    ) {
      setQuestionSetSelectedError(t("ErrSelectQuestionSet"))
    } else {
      setQuestionSetSelectedError("")
    }
    if (roleArr === "Choose Question Set") {
      setSelectVacancyError(t("ErrSelectVacancy"))
    } else {
      setSelectVacancyError("")
    }
    if (!isRecordByAppRadio && !isFacetoFaceRadio && !isVideoRadio) {
      setSelectInterviewTypeError(t("ErrSelectintType"))
    } else {
      setSelectInterviewTypeError("")
    }
    if (selected?.length === 0) {
      setSelectInterviewerError(t("SelectInterviewers"))
    } else {
      setSelectInterviewerError("")
    }
    if (
      values?.candidateSkills?.length === 0 ||
      values?.candidateSkills === null ||
      values?.candidateSkills === undefined
    ) {
      setCandidateSkillsError(t("ErrSelect1Skill"))
    } else {
      setCandidateSkillsError("")
    }
    const [hours, minutes] = getZoneDate(values.intvueTimeZone)
      .format("HH:mm")
      .split(":")
      .map(Number)
    const intvueTime = fTime(values?.intvueTime).split(":").map(Number)
    const hoursDiff = intvueTime[0] - hours
    const minutesDiff = intvueTime[1] - minutes
    const formatedMinDate = new Date(Date.parse(minDate)).setHours(0, 0, 0, 0)
    const formatedExpiry = new Date(values?.intvueExpiry).setHours(0, 0, 0, 0)
    const formatedDate = new Date(values?.intvueDate).setHours(0, 0, 0, 0)

    if (
      roleArr === "" ||
      (!isRecordByAppRadio && !isFacetoFaceRadio && !isVideoRadio) ||
      selected?.length === 0 ||
      values?.candidateSkills?.length === 0 ||
      isEmpty(selectTimeZone) ||
      ((values?.questionSet === null || values?.questionSet === undefined) &&
        isRecordByAppRadio) ||
      (((((hoursDiff <= 0 && minutesDiff <= 0) ||
        (hoursDiff < 0 && minutesDiff >= 0)) &&
        formatedMinDate == formatedDate) ||
        formatedMinDate > formatedDate) &&
        isFacetoFaceRadio) ||
      (isRecordByAppRadio && formatedMinDate > formatedExpiry)
    ) {
      return
    }

    if (existingCandidateError !== "" || validatingCandidate) {
      return
    }

    if (flag) {
      let ids = []

      for (const interviewer of values.interviewers) {
        for (const intViewer of intVuerList?.data?.interviewers || []) {
          if (interviewer === intViewer?.full_name) {
            ids.push(intViewer?.id)
            break
          }
        }
      }

      const payload = new FormData()

      values.candidateSkills.forEach((value) =>
        payload.append("skills" + "[]", value),
      )
      payload.append("first_name", values.firstName)
      payload.append("last_name", values.lastName)
      payload.append("email", values.email)
      payload.append("phone", values.phone)
      payload.append("qualification", values.qualification)
      payload.append("experience", values.experience)
      payload.append("vacancy_id", values.role)
      payload.append("status", values.status)
      payload.append("interview_type", "first_contact")
      payload.append("interviewers", ids)
      payload.append("resume", values.resume.file)

      payload.append("message", values.candidateNote)
      payload.append("interview_format", values.intvueFormate)
      payload.append("timezone", values.intvueTimeZone)
      payload.append("question_set_id", values.questionSet ?? "")

      if (values.intvueFormate === "recorded_by_applicant") {
        payload.append("expires_on", fDate(values.intvueExpiry))
      }
      if (values.intvueFormate === "on_site") {
        const interviewTime =
          fDate(values.intvueDate) + " " + fTime(values.intvueTime)

        payload.append("interview_date", fDate(values.intvueDate))
        payload.append("interview_time", interviewTime)
        payload.append("interview_location", values.intvueLocation)
      }
      if (values.intvueFormate === "online_video_call") {
        const interviewTime =
          fDate(values.intvueDate) + " " + fTime(values.intvueTime)

        payload.append("interview_date", fDate(values.intvueDate))
        payload.append("interview_time", interviewTime)
        payload.append("interview_video_link", values.intvueMeetingLink)
      }
      dispatch(createInterview(payload))

      hide()
      setIsFacetoFaceRadio(null)
      setIsRecordByAppRadio(null)
      setIsVideoRadio(null)
      resetForm({ values: "" })
      setSelected([])
      setRoleArr("")
      setError("")
      setFileError("")
      setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      setAvailableQS(existingQuestionSets?.data?.question_sets)
      setVacancySkills(candidateSkillsArr.map((option) => option.value))
      setSelectedQS(null)
    } else {
      let ids = []

      for (const interviewer of values.interviewers) {
        for (const intViewer of intVuerList?.data?.interviewers || []) {
          if (interviewer === intViewer?.full_name) {
            ids.push(intViewer?.id)
            break
          }
        }
      }

      const payload = new FormData()

      values.candidateSkills.forEach((value) =>
        payload.append("skills" + "[]", value),
      )

      payload.append("first_name", values.firstName)
      payload.append("last_name", values.lastName)
      payload.append("email", values.email)
      payload.append("phone", values.phone)
      payload.append("qualification", values.qualification)
      payload.append("experience", values.experience)

      payload.append("vacancy_id", values.role)
      payload.append("status", values.status)
      payload.append("interview_type", "first_contact")
      payload.append("interviewers", ids)
      if (resumeUpdated) {
        payload.append("resume", values.resume.file)
      }

      payload.append(
        "message",
        values.candidateNote ? values.candidateNote : "",
      )
      payload.append("interview_format", values.intvueFormate)
      payload.append("timezone", values.intvueTimeZone)
      payload.append("question_set_id", values.questionSet)

      if (values.intvueFormate === "recorded_by_applicant") {
        payload.append("expires_on", fDate(values.intvueExpiry))
      }
      if (values.intvueFormate === "on_site") {
        const interviewTime =
          fDate(values.intvueDate) + " " + fTime(values.intvueTime)

        payload.append("interview_date", fDate(values.intvueDate))
        payload.append("interview_time", interviewTime)
        payload.append("interview_location", values.intvueLocation)
      }
      if (values.intvueFormate === "online_video_call") {
        const interviewTime =
          fDate(values.intvueDate) + " " + fTime(values.intvueTime)

        payload.append("interview_date", fDate(values.intvueDate))
        payload.append("interview_time", interviewTime)
        payload.append("interview_video_link", values.intvueMeetingLink)
      }
      payload.append("key", data?.key)
      dispatch(updateInterview(payload, data?.key))
      hide()
      setAvailableQS(existingQuestionSets?.data?.question_sets)
      setError("")
      setFileError("")
      setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      setVacancySkills(candidateSkillsArr.map((option) => option.value))
    }
  }

  const scheduleInterviewSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(t("ErrFnameRequired"))
      .max(30, t("TooLong!"))
      .matches(/^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/, t("BlankNotAllowed")),
    lastName: Yup.string()
      .required(t("ErrLnameRequired"))
      .max(30, t("TooLong!"))
      .matches(/^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/, t("BlankNotAllowed")),
    email: Yup.string().email().required(t("ErrEmailRequired")),
    role: Yup.string().required(t("RoleRequired")),
    intvueType: Yup.string().required(t("SelectTypeInt")),
    interviewers: Yup.array().of(Yup.string()).min(1, t("IntRequired")),
    intvueTimeZone: Yup.string().required(t("ErrSelectIntTimezone")),
    candidateSkills: Yup.array().of(Yup.string()).min(1, t("ErrSelect1Skill")),
    intvueLocation: Yup.string()
      .nullable()
      .when("intvueType", {
        is: "on_site",
        then: Yup.string().required(t("ErrIntLocRequired")).trim().nullable(),
        otherwise: Yup.string().nullable(),
      }),
    intvueMeetingLink: Yup.string()
      .nullable()
      .when("intvueType", {
        is: "online_video_call",
        then: Yup.string().required(t("ErrIntMeetingRequired")).nullable(),
        otherwise: Yup.string().nullable(),
      }),
    questionSet: Yup.string().required(t("ErrSelectQS")).nullable(),
    candidateNote: Yup.string()
      .trim()
      .max(200, t("TooLong!"))
      .matches(/\w+(?:\s+\w+)+/g, t("TryWriteDecentWord"))
      .nullable(),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: scheduleInterviewSchema,
    onSubmit: submission,
  })

  const {
    errors,
    touched,
    values,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    handleBlur,
  } = formik

  const onInterviewTypeChange = (event) => {
    setSelectInterviewTypeError("")

    if (event.target.value === "Recorded By Applicant") {
      setIsRecordByAppRadio(true)
      setIsFacetoFaceRadio(null)
      setIsVideoRadio(null)
      setFieldValue("intvueFormate", "recorded_by_applicant")
      setFieldValue("intvueType", "recorded_by_applicant")
      setFieldValue("status", "pending")
      setFieldValue("intvueExpiry", new Date())
    } else if (event.target.value === "On Site Face To Face") {
      setIsFacetoFaceRadio(true)
      setIsRecordByAppRadio(null)
      setIsVideoRadio(null)
      setFieldValue("status", "pending")
      setFieldValue("intvueFormate", "on_site")
      setFieldValue("intvueType", "on_site")
      setFieldValue("intvueDate", new Date())
    } else if (event.target.value == "Video Call") {
      setIsFacetoFaceRadio(null)
      setIsRecordByAppRadio(null)
      setIsVideoRadio(true)
      setFieldValue("status", "pending")
      setFieldValue("intvueFormate", "online_video_call")
      setFieldValue("intvueType", "online_video_call")
      setFieldValue("intvueDate", new Date())
    }
  }

  const [selectTimeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )
  const [minTime, setMinTime] = useState(new Date())
  const [minDate, setMinDate] = useState(new Date())
  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: [],
  })
  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files })

  const getZoneDate = (timeZone) => {
    const dateTime = moment(new Date())
    return dateTime.clone().tz(timeZone)
  }

  const setDateTimeLimts = (timeZone) => {
    const dateTimeInTimeZone = getZoneDate(timeZone)
    const date = new Date(dateTimeInTimeZone.format("DD MMM yyyy"))

    setMinDate(date)
    setTimeLimit(values?.intvueDate, date)
  }

  const setTimeLimit = (date, minDate) => {
    if (
      moment(date).startOf("day").isSame(moment(minDate).startOf("day")) &&
      isFacetoFaceRadio
    ) {
      const dateTimeInTimeZone = getZoneDate(values?.intvueTimeZone)

      const time = dateTimeInTimeZone.format("HH:mm")
      const minTimeLimit = new Date()
      minTimeLimit.setHours(time.split(":")[0])
      minTimeLimit.setMinutes(time.split(":")[1])
      setMinTime(minTimeLimit)
    } else {
      setMinTime("")
    }
  }

  const handleFormSubmission = () => {
    setDateTimeLimts(values?.intvueTimeZone)
    handleSubmit()
  }

  const handleClose = () => {
    if (flag) {
      hide()
      values.firstName = ""
      values.lastName = ""
      values.email = ""
      values.phone = ""
      values.candidateNote = ""
      values.interview_location = ""
      values.intvueTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      values.qualification = ""
      values.experience = ""
      values.candidateSkills = []
      values.resume = {}
      values.questionSet = null
      setRoleArr("")
      setIsFacetoFaceRadio(null)
      setIsRecordByAppRadio(null)
      setIsVideoRadio(null)
      setSelected([])
      setError("")
      setFileError("")
      setQuestionSetSelectedError("")
      setAvailableQS(existingQuestionSets?.data?.question_sets)
      setVacancySkills(candidateSkillsArr.map((option) => option.value))
      setSelectedQS(null)
    } else {
      hide()
      setFileError("")
    }
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    resetForm()
    setValidatingCandidate(false)
    setExistingCandidateError("")
  }
  const logged = useSelector((state) => state.login)

  const onKeyDown = (e) => {
    if (e.key !== "Tab") {
      e.preventDefault()
    }
  }

  const resumeUpload = (file) => {
    if (file?.file) {
      setFieldValue("resume", file)
      setResumeUpdated(true)
    } else {
      setFieldValue("resume", "")
      setUploadedResume(false)
    }
  }

  const existingDate = values.intvueDate
  let interviewDate = existingDate ? fDate(existingDate) : fDate(new Date())

  const existingExpiryDate = values.intvueExpiry

  const expiryDate = existingExpiryDate
    ? fDate(existingExpiryDate)
    : fDate(new Date())

  const [existingCandidateError, setExistingCandidateError] = useState("")
  const [validatingCandidate, setValidatingCandidate] = useState(false)

  const validateCandidate = () => {
    const url = new URL(
      `${process.env.REACT_APP_BASE_URL}/api/v1/interviews/validate_candidate`,
    )
    url.searchParams.append("email", values?.email)
    url.searchParams.append("vacancy_id", values?.role)
    url.searchParams.append("action", flag ? "create" : "update")
    url.searchParams.append("interview_key", data?.key)
    url.searchParams.append("account_id", logged?.user?.account?.id)

    setValidatingCandidate(true)

    fetch(url)
      .then((response) => response.text())
      .then((data) => {
        data = data ? JSON.parse(data) : {}
        setExistingCandidateError(data?.error ?? "")
        setValidatingCandidate(false)
      })
  }

  return (
    isShowingScheduleInt && (
      <Dialog
        className='dialog800'
        open={isShowingScheduleInt}
        onClose={handleClose}
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {flag ? t("ScheduleInterview") : t("EditInt")}

          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <FormikProvider value={formik}>
            <Form
              id='scheduleInterview'
              autoComplete='off'
              noValidate
              onSubmit={handleFormSubmission}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("CandidateFirstName")}</FormLabel>
                    <TextField
                      tabIndex='0'
                      fullWidth
                      variant='outlined'
                      name='firstName'
                      id='firstName'
                      placeholder={t("CandidateFirstName")}
                      value={values.firstName}
                      onChange={handleChange}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("CandidateLastName")}</FormLabel>
                    <TextField
                      fullWidth
                      tabIndex='1'
                      variant='outlined'
                      name='lastName'
                      id='lastName'
                      placeholder={t("CandidateLastName")}
                      value={values.lastName}
                      onChange={handleChange}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("Email")}</FormLabel>
                    <TextField
                      tabIndex='2'
                      fullWidth
                      variant='outlined'
                      name='email'
                      placeholder={t("Email")}
                      value={values.email}
                      onBlur={validateCandidate}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                  {existingCandidateError && (
                    <Typography
                      variant='span'
                      sx={{
                        marginTop: "5px",
                        color: "#FF4842",
                        fontWeight: 400,
                        fontSize: "1rem",
                      }}
                    >
                      {existingCandidateError}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel>{t("Phone")}</FormLabel>
                    <PhoneInput
                      tabIndex='3'
                      country={defaultCountry}
                      defaultCountry={defaultCountry}
                      enableAreaCodes={true}
                      value={values.phone}
                      onChange={(value, country, e, formattedValue) =>
                        setFieldValue("phone", formattedValue)
                      }
                      id='phone'
                      autoComplete='off'
                      name='phone'
                      autoFormat
                      enableSearch={true}
                      inputClass='phone_input'
                      isValid={(value, country) => {
                        if (value.startsWith("0")) {
                          return <>{t("ErrPhone#cant0")}</>
                        } else {
                          return true
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("ErrSelectVacancy")} </FormLabel>

                    <Select
                      tabIndex='4'
                      displayEmpty
                      className='demo-select'
                      name='role'
                      value={roleArr}
                      input={<OutlinedInput />}
                      MenuProps={MenuProps}
                      renderValue={(roleArr) => {
                        return roleArr
                      }}
                      onBlur={validateCandidate}
                      onChange={(e, value) => {
                        if (isRecordByAppRadio === true) {
                          setFieldValue("questionSet", "")
                          setSelectedQS(null)
                        }
                        setRoleArr(value.props.children)
                        setFieldValue("role", e.target.value)
                        setSelectVacancyError("")
                        const selectedVacancy = roleList?.data?.vacancies.find(
                          (vacancy) => vacancy.id === e.target.value,
                        )
                        setDeptName(selectedVacancy?.department?.name)
                        setVacancySkills(selectedVacancy?.department?.skills)
                        setAvailableQS(
                          existingQuestionSets?.data?.question_sets?.filter(
                            (QS) =>
                              QS.department_id ===
                              selectedVacancy?.department_id,
                          ),
                        )
                      }}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                    >
                      {roleList?.data?.vacancies?.length !== 0 &&
                        roleList?.data?.vacancies?.filter(
                          (vacancy) => vacancy.status === "active",
                        ) && (
                          <MenuItem
                            sx={{
                              fontWeight: 600,
                              color: "#5082dd",
                              opacity: "1 !important",
                            }}
                            disabled
                            value=''
                          >
                            <em>{t("ChooseVacancy")}</em>
                          </MenuItem>
                        )}
                      {roleList?.data?.vacancies?.length === 0 ? (
                        <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                      ) : (
                        roleList?.data?.vacancies
                          ?.filter((vacancy) => vacancy.status === "active")
                          .map((option) => [
                            <MenuItem
                              sx={{ whiteSpace: "normal" }}
                              disabled={
                                option.status === "active" ? false : true
                              }
                              key={option?.key}
                              value={option?.id}
                            >
                              {option?.title}
                            </MenuItem>,
                          ])
                      )}
                    </Select>
                    {selectVacancyError && (
                      <Typography
                        variant='span'
                        sx={{
                          marginTop: "5px",
                          color: "#FF4842",
                          fontWeight: 400,
                          fontSize: "1rem",
                        }}
                      >
                        {selectVacancyError}
                      </Typography>
                    )}
                    {touched?.role && errors?.role && (
                      <p className='input-error'>{errors?.role}</p>
                    )}
                  </FormControl>
                  {logged?.user?.profile?.permission
                    ?.vacancy_add_new_vacancy && (
                    <>
                      <Button
                        size='small'
                        variant='outlined'
                        color='primary'
                        sx={{
                          maxHeight: 38,
                          width: "fit-content",
                          fontWeight: 500,
                          mt: 2,
                          justifyContent: "center",
                          pl: 2,
                          pr: 2,
                        }}
                        onClick={toggleRole}
                        startIcon={<AddIcon />}
                      >
                        {t("CreateVacancy")}
                      </Button>

                      <AddRoleModal
                        isShowingRole={isShowingRole}
                        hide={toggleRole}
                        flag={true}
                      />
                    </>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("SelectQuestionSet")}</FormLabel>
                    <Select
                      tabIndex='5'
                      id='demo-simple-select'
                      name='question-sets'
                      value={selectedQS}
                      sx={{ textTransform: "capitalize" }}
                      MenuProps={MenuProps}
                      onChange={(e) => {
                        setSelectedQS(e.target.value)
                        setFieldValue("questionSet", e.target.value)
                        setQuestionSetSelectedError("")
                      }}
                      error={Boolean(touched.questionSet && errors.questionSet)}
                      helperText={touched.questionSet && errors.questionSet}
                    >
                      {availableQS?.length === 0 && (
                        <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                      )}
                      {availableQS?.length > 0 && (
                        <MenuItem
                          sx={{
                            fontWeight: 600,
                            color: "#5082dd",
                            opacity: "1 !important",
                          }}
                          disabled
                          value=''
                        >
                          <em>{t("ChooseQuestionSet")}</em>
                        </MenuItem>
                      )}
                      {availableQS?.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          sx={{
                            textTransform: "capitalize",
                            whiteSpace: "normal",
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {logged?.user?.profile?.permission?.question_sets_add && (
                      <>
                        <Button
                          size='small'
                          variant='outlined'
                          color='primary'
                          sx={{
                            maxHeight: 38,
                            width: "fit-content",
                            fontWeight: 500,
                            mt: 2,
                            justifyContent: "center",
                            pl: 2,
                            pr: 2,
                          }}
                          onClick={toggleQuestion}
                          startIcon={<AddIcon />}
                        >
                          {t("CreateQuestionSet")}
                        </Button>
                        <AddQuestionModal
                          isShowingQuestion={isShowingQuestion}
                          hide={toggleQuestion}
                          flag={true}
                          depName={deptName}
                        />
                      </>
                    )}
                    {touched?.questionSet && errors?.questionSet && (
                      <p className='input-error'>{errors?.questionSet}</p>
                    )}
                    {questionSetSelectedError && (
                      <Typography
                        variant='span'
                        sx={{
                          marginTop: "5px",
                          color: "#FF4842",
                          fontWeight: 400,
                          fontSize: "1rem",
                        }}
                      >
                        {questionSetSelectedError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("InterviewType")}</FormLabel>
                    <RadioGroup
                      row
                      name='intvueType'
                      error={Boolean(touched.intvueType && errors.intvueType)}
                      helperText={touched.intvueType && errors.intvueType}
                    >
                      <FormControlLabel
                        value='Recorded By Applicant'
                        onChange={onInterviewTypeChange}
                        control={<Radio />}
                        checked={isRecordByAppRadio}
                        label={t("RecordedbyApplicant")}
                      />
                      <FormControlLabel
                        value='On Site Face To Face'
                        onChange={onInterviewTypeChange}
                        control={<Radio />}
                        checked={isFacetoFaceRadio}
                        label={t("OnSite")}
                      />
                    </RadioGroup>
                  </FormControl>
                  {selectInterviewTypeError && (
                    <Typography
                      variant='span'
                      sx={{
                        marginTop: "5px",
                        color: "#FF4842",
                        fontWeight: 400,
                        fontSize: "1rem",
                      }}
                    >
                      {selectInterviewTypeError}
                    </Typography>
                  )}

                  {touched?.intvueType && errors?.intvueType && (
                    <p className='input-error'>{errors?.intvueType}</p>
                  )}
                </Grid>

                {isRecordByAppRadio === true && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel>{t("IntLinkExpiryDate")}</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat='MM/dd/yyyy 24:00'
                            name='intvueExpiry'
                            value={expiryDate ? expiryDate : value}
                            minDate={minDate}
                            timezone={selectTimeZone}
                            onChange={(val) => {
                              setValue(val)
                              setFieldValue("intvueExpiry", val)
                            }}
                            renderInput={(params) => (
                              <TextField
                                tabIndex={7}
                                onKeyDown={onKeyDown}
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel>{t("CandidateNotes")}</FormLabel>
                        <TextField
                          tabindex='8'
                          fullWidth
                          variant='outlined'
                          autoComplete=''
                          maxLength={200}
                          name='candidateNote'
                          sx={{
                            wordBreak: "break-all",
                            whiteSpace: "break-spaces",
                          }}
                          {...getFieldProps("candidateNote")}
                          error={Boolean(
                            touched.candidateNote && errors.candidateNote,
                          )}
                          helperText={
                            touched.candidateNote && errors.candidateNote
                          }
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("InterviewTimezone")}</FormLabel>
                    <TimezonePicker
                      tabindex='9'
                      value={selectTimeZone}
                      onChange={(time) => {
                        setTimeZone(time)
                        setFieldValue("intvueTimeZone", time)
                        setDateTimeLimts(time)
                      }}
                      className='select-container'
                    />
                  </FormControl>
                  {(errors.intvueTimeZone || timezoneError) && (
                    <Typography
                      variant='span'
                      sx={{
                        marginTop: "5px",
                        color: "#FF4842",
                        fontWeight: 400,
                        fontSize: "1rem",
                      }}
                    >
                      {errors.intvueTimeZone ?? timezoneError}
                    </Typography>
                  )}
                </Grid>
                {isFacetoFaceRadio === true && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel required>{t("InterviewDate")}</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            tabindex={10}
                            inputFormat='MM/dd/yyyy'
                            name='intvueDate'
                            timezone={selectTimeZone}
                            minDate={minDate}
                            value={interviewDate ? interviewDate : value}
                            onChange={(val) => {
                              setValue(val)
                              setFieldValue("intvueDate", val)
                              setTimeLimit(val, minDate)
                            }}
                            renderInput={(params) => (
                              <TextField onKeyDown={onKeyDown} {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel required>{t("InterviewTime")}</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            tabindex='11'
                            value={
                              values?.intvueTime
                                ? values?.intvueTime
                                : fDateTime(new Date())
                            }
                            format='HH:mm:ss'
                            autoOk={true}
                            onChange={(val) => {
                              setValue(val)
                              setFieldValue("intvueTime", val)
                            }}
                            renderInput={(params) => (
                              <TextField onKeyDown={onKeyDown} {...params} />
                            )}
                            minTime={minTime}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel required>{t("InterviewLocation")}</FormLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          name='intvueLocation'
                          value={values.intvueLocation}
                          onChange={handleChange}
                          error={Boolean(
                            touched.intvueLocation && errors.intvueLocation,
                          )}
                          helperText={
                            touched.intvueLocation && errors.intvueLocation
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel>{t("MessagetoCandidate")}</FormLabel>

                        <TextField
                          fullWidth
                          multiline
                          rows={3}
                          variant='outlined'
                          autoComplete=''
                          maxLength={200}
                          placeholder={t("MessagetoCandidate")}
                          name='candidateNote'
                          {...getFieldProps("candidateNote")}
                          error={Boolean(
                            touched.candidateNote && errors.candidateNote,
                          )}
                          helperText={
                            touched.candidateNote && errors.candidateNote
                          }
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("SelectInterviewers")}</FormLabel>
                    <Select
                      multiple
                      value={selected}
                      onChange={selectionChangeHandler}
                      MenuProps={MenuProps}
                      sx={{ textTransform: "capitalize" }}
                      renderValue={(selected) => (
                        <Box sx={{ overflowX: "auto" }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} sx={{ mx: 1 }} />
                          ))}
                        </Box>
                      )}
                      error={Boolean(
                        touched.interviewers && errors.interviewers,
                      )}
                      helperText={touched.interviewers && errors.interviewers}
                    >
                      {intVuerList?.data?.interviewers?.length !== 0 && (
                        <MenuItem
                          sx={{
                            fontWeight: 600,
                            color: "#5082dd",
                            opacity: "1 !important",
                          }}
                          disabled
                        >
                          <em>{t("ChooseInterviewers")}</em>
                        </MenuItem>
                      )}
                      {intVuerList?.data?.interviewers?.length === 0 ? (
                        <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                      ) : (
                        intVuerList?.data?.interviewers?.map((option) => (
                          <MenuItem
                            sx={{
                              textTransform: "capitalize",
                              whiteSpace: "normal",
                            }}
                            key={option?.id}
                            value={option?.full_name}
                          >
                            {option?.full_name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {((errors.interviewers && touched.interviewers) ||
                      selectInterviewerError) && (
                      <Typography
                        variant='span'
                        sx={{
                          marginTop: "5px",
                          color: "#FF4842",
                          fontWeight: 400,
                          fontSize: "1rem",
                        }}
                      >
                        {errors.interviewers ?? selectInterviewerError}
                      </Typography>
                    )}

                    {logged?.user?.profile?.permission
                      ?.interviews_schedule_interview_create_interviewer_action && (
                      <Button
                        size='small'
                        variant='outlined'
                        color='primary'
                        sx={{
                          maxHeight: 38,
                          width: "fit-content",
                          fontWeight: 500,
                          mt: 2,
                          justifyContent: "center",
                          pl: 2,
                          pr: 2,
                        }}
                        onClick={toggleInterviewer}
                        startIcon={<AddIcon />}
                      >
                        {t("CreateTnterviewer")}
                      </Button>
                    )}
                    <AddInterviewerModal
                      isShowingInterviewer={isShowingInterviewer}
                      hide={toggleInterviewer}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel>{t("CandidateQualification")}</FormLabel>
                    <TextField
                      fullWidth
                      rows={2}
                      variant='outlined'
                      autoComplete=''
                      placeholder={t("CandidateQualification")}
                      name='qualification'
                      disabled={flag ? false : true}
                      value={values.qualification}
                      onChange={handleChange}
                      error={Boolean(
                        touched.qualification && errors.qualification,
                      )}
                      helperText={touched.qualification && errors.qualification}
                      {...getFieldProps("qualification")}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel>{t("CandidateExperience(inyears)")}</FormLabel>
                    <TextField
                      tabIndex={16}
                      fullWidth
                      rows={2}
                      variant='outlined'
                      autoComplete=''
                      placeholder={t("ExperienceinYears")}
                      name='experience'
                      type='number'
                      maxLength={2}
                      disabled={flag ? false : true}
                      value={values.experience}
                      onChange={handleChange}
                      error={Boolean(touched.experience && errors.experience)}
                      helperText={touched.experience && errors.experience}
                      {...getFieldProps("experience")}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("CandidateSkills")}</FormLabel>
                    <Autocomplete
                      multiple
                      tabIndex={17}
                      onChange={(e, value) => {
                        setCandidateSkillsError("")

                        const isContainEmptyString = value.find(
                          (val) => val.trim() === "",
                        )
                        if (!isContainEmptyString) {
                          handleChange(e)
                          values.candidateSkills = value
                          setFieldValue("candidateSkills", value)
                        }
                      }}
                      value={
                        values?.candidateSkills ? values?.candidateSkills : []
                      }
                      options={vacancySkills}
                      variant='outlined'
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option}
                            sx={{ mx: 1 }}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          tabIndex={17}
                          {...params}
                          variant='outlined'
                          placeholder={t("TypeToAddSkills")}
                          error={Boolean(
                            touched?.candidateSkills && errors?.candidateSkills,
                          )}
                          helperText={
                            touched?.candidateSkills && errors?.candidateSkills
                          }
                        />
                      )}
                    />
                    {candidateSkillsError && (
                      <Typography
                        variant='span'
                        sx={{
                          marginTop: "5px",
                          color: "#FF4842",
                          fontWeight: 400,
                          fontSize: "1rem",
                        }}
                      >
                        {candidateSkillsError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }} className='video-field'>
                    <FormLabel>{t("Resume")}</FormLabel>
                    <Box sx={{ maxWidth: "100%", fontSize: "12px" }}>
                      <DragDrop
                        tabIndex={18}
                        accept='.pdf, .docx, .doc, .txt'
                        value={values.resume}
                        url={uploadedResume}
                        name='resume'
                        onChange={(file) => resumeUpload(file)}
                        updateFilesCb={updateUploadedFiles}
                        onError={(error) => setFileError(error)}
                        validFileTypes={VALID_FILE_TYPES}
                        maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
                      />
                      <Typography variant='body2' sx={{ my: 2 }}>
                        {t("AlertResumeExt")}
                      </Typography>
                      {fileError && (
                        <Typography sx={{ color: "red !important" }}>
                          {fileError}
                        </Typography>
                      )}
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>

          {error && (
            <Alert
              severity='error'
              variant='outlined'
              sx={{ maxWidth: "200px" }}
            >
              {error}
            </Alert>
          )}
        </DialogContent>

        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Cancel")}
          </Button>

          <Button
            onClick={handleFormSubmission}
            //  autoFocus
            variant='contained'
            color='secondary'
            size='medium'
            disabled={validatingCandidate}
          >
            {flag ? t("Save") : t("Update")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}