import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/department";

export const getAllDepartment = () => {
  const endpoint = "/api/v1/departments";
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.FETCHDEPARTMENTS_ON_SUCCESS,
    ActionTypes.FETCHDEPARTMENTS_ON_FAILURE,
  ];
  return request(options);
};

export const createDepartment = (data) => {
  const endpoint = "/api/v1/departments";
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CREATEDEPARTMENT_ON_SUCCESS,
    ActionTypes.CREATEDEPARTMENT_ON_FAILURE,
  ];
  return request(options);
};

export const updateDepartment = (id, data) => {
  const endpoint = `/api/v1/departments/${id}`;
  const method = "PUT";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.EDITDEPARTMENT_ON_SUCCESS,
    ActionTypes.EDITDEPARTMENT_ON_FAILURE,
  ];
  return request(options);
};

export const deleteDepartment = (id) => {
  const endpoint = `/api/v1/departments/${id}`;
  const method = "DELETE";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.DELETEDEPARTMENT_ON_SUCCESS,
    ActionTypes.DELETEDEPARTMENT_ON_FAILURE,
  ];
  return request(options);
};
export const deleteSelectedDepartments = (departmentIds) => {
  const endpoint = "/api/v1/departments/destroy_selected_departments";
  const method = "DELETE";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = {
    action: endpoint,
    method: method,
    headers: headers,
    body: { department_ids: departmentIds },
    types: [
      ActionTypes.DELETE_SELECTED_DEPARTMENTS_ON_SUCCESS,
      ActionTypes.DELETE_SELECTED_DEPARTMENTS_ON_FAILURE,
    ],
  };

  return request(options);
};
export const resetDepartmentFlag = () => {
  return { type: ActionTypes.RESET_FLAGS };
};

export const resetCreateDepartmentFlag = () => {
  return { type: ActionTypes.RESET_CREATED };
};
