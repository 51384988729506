import React, { useState, useEffect } from "react"
import merge from "lodash/merge"
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom"
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt"
import PulseLoader from "react-spinners/PulseLoader"
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt"
import {
  Card,
  Box,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tab,
  Checkbox,
} from "@mui/material"
import Tabs, { tabsClasses } from "@mui/material/Tabs"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import PropTypes from "prop-types"
import { capitalCase } from "change-case"
import Page from "../components/Page"
import Scrollbar from "../components/Scrollbar"
import Label from "../components/Label"
import { getAllRoles } from "src/redux/actions/roles"
import {
  InterviewListHead,
  InterviewListToolbar,
  InterviewMoreMenu,
  FollowUpIntModal,
  ScheduleIntModal,
} from "../sections/@dashboard/interview"
import { useDispatch, useSelector } from "react-redux"
import {
  getAllInterviews,
  getFilteredInterviews,
  resetInterviewsFlag,
} from "src/redux/actions/interviews"
import { interviewDataParsing } from "src/utils/dataParsing"
import { getAllInterviewers } from "src/redux/actions/interviewers"
import { getAllQuestionSet } from "src/redux/actions/questions"
import { getAllMembers } from "src/redux/actions/member"
import { resetErrors } from "src/redux/actions/resetError"
import Snackbar from "@mui/material/Snackbar"
import Loader from "src/components/Loader"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import { getAllDepartment } from "src/redux/actions/department"
import { getCandidateSkills } from "src/redux/actions/candidate"
import { fDateTime } from "src/utils/formatTime"
import moment from "moment"
import "moment-timezone"
import { isEmpty } from "lodash"
import interviewsSampleArr, {
  interviewsArr,
} from "src/_mock/interviewSampleArr"
import ConfirmationModal from "src/sections/@dashboard/roles/modal/ConfirmationModal"
import { resetConfigurationsFlag } from "src/redux/actions/accountConfig"
import IntFinishedModal from "src/sections/@dashboard/interview/modal/intRunOut/IntRunOutModal"
import RecommendationTooltip from "src/sections/@dashboard/interview/recommendationTooltip"
import ViewFeedbackModal from "src/sections/@dashboard/interview/modal/viewFeedback/ViewFeedbackModal"
import AddIcon from "@mui/icons-material/Add"
import Alert from "src/components/Alert"
import { convertTimeZone } from "src/utils/formatTime"
import { DBTimeZone } from "src/utils/constants"
// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))

const TABLE_HEAD_COMPLETED_INT = (t) => [
  { id: "name", label: t("Candidate"), alignRight: false },
  { id: "candidateEmail", label: t("Email"), alignRight: false },
  { id: "roleApplied", label: t("Vacancy"), alignRight: false },
  { id: "intType", label: t("InterviewType"), alignRight: false },
  { id: "intVueFormat", label: t("InterviewFormat"), alignRight: false },
  { id: "intDate", label: t("ScheduledDate"), alignCenter: true },
  { id: "intExpiresOn", label: t("CompletedOn"), alignCenter: true },
  { id: "intStatus", label: t("Status"), alignCenter: true },
  { id: "votes", label: t("Votes"), alignCenter: true },
  { id: "", label: t("Action"), alignRight: true },
]

const TABLE_HEAD_SCHEDULE_INT = (t) => [
  { id: "name", label: t("Candidate"), alignRight: false },
  { id: "candidateEmail", label: t("Email"), alignRight: false },
  { id: "roleApplied", label: t("Vacancy"), alignRight: false },
  { id: "intType", label: t("InterviewType"), alignRight: false },
  { id: "intVueFormat", label: t("InterviewFormat"), alignRight: false },
  { id: "intDate", label: t("ScheduledDate"), alignCenter: true },
  { id: "intStatus", label: t("Status"), alignCenter: true },
  { id: "refCode", label: t("ReferenceCode"), alignRight: false },
  { id: "", label: t("Action"), alignRight: false },
]

const TABLE_HEAD_EXPIRE_INT = (t) => [
  { id: "name", label: t("Candidate"), alignRight: false },
  { id: "candidateEmail", label: t("Email"), alignRight: false },
  { id: "roleApplied", label: t("Vacancy"), alignRight: false },
  { id: "intType", label: t("InterviewType"), alignRight: false },
  { id: "intVueFormat", label: t("InterviewFormat"), alignRight: false },
  { id: "intDate", label: t("ScheduledDate"), alignCenter: true },
  { id: "intExpiresOn", label: t("ExpiredOn"), alignCenter: true },
  { id: "intStatus", label: t("Status"), alignCenter: true },
  { id: "", label: t("Action"), alignRight: true },
]
const TABLE_HEAD_ARCHIVE_INT = (t) => [
  { id: "name", label: t("Candidate"), alignRight: false },
  { id: "candidateEmail", label: t("Email"), alignRight: false },
  { id: "roleApplied", label: t("Vacancy"), alignRight: false },
  { id: "intType", label: t("InterviewType"), alignRight: false },
  { id: "intVueFormat", label: t("InterviewFormat"), alignRight: false },
  { id: "intDate", label: t("ScheduledDate"), alignCenter: true },
  { id: "intExpiresOn", label: t("CompletedOn"), alignCenter: true },
  { id: "votes", label: t("Votes"), alignCenter: true },
  { id: "", label: t("Action"), alignRight: true },
]

function TabPanel(props) {
  const { children, value, index, tabs, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3, paddingBottom: 3, pl: 0, pr: 0 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "0",
    borderRadius: "8px",
    // boxShadow: theme.shadows[1],
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
  },
}))
export default function Interview(props) {
  const navigate = useNavigate()
  const [isShowingFollowupInt, toggleFollowUpInt] = useModal()
  const [isShowingScheduleInt, toggleScheduleInt] = useModal()
  const [isShowingModal, setIsShowing] = useModal()
  const [isShowingIntFinishModal, setIsShowingIntFinishModal] = useModal()

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("asc")
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState("firstName")
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [filters, setFilters] = useState(null)
  const [interviews, setInterviews] = useState([])
  const [firstRender, setFirstRender] = useState(true)
  const [tabSelected, setTabSelected] = useState(false)
  const [nonFiltered, setNonFiltered] = useState([])
  const [dataLoading, setDataLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isRolesLoading, setIsRolesLoading] = useState(true)
  const [isRolesLoaded, setIsRolesLoaded] = useState(false)
  const [valuetab, setValuetab] = useState(0)
  const [searchName, setSearchName] = useState("")
  const [openFilter, setOpenFilter] = useState(false)
  const [displayCrash, setDisplayCrash] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorSeverity, setSeverity] = useState("")

  const departmentsInfo = useSelector((state) => state.departments)
  const candidateSkills = useSelector((state) => state.candidateSkills)
  const intVue = useSelector((state) => state.interview)
  const filterintVue = useSelector((state) => state.filteredInterview)
  const userFlag = useSelector((state) => state.container)
  const intvuer = useSelector((state) => state.interviewer)
  const existingQuestionSets = useSelector((state) => state.questions)
  const awaitFeedback = useSelector((state) => state.container)
  const members = useSelector((state) => state.members)
  const roles = useSelector((state) => state.roles)
  const checkCrash = useSelector((state) => state.crashManagement)
  const logged = useSelector((state) => state.login)
  const config = useSelector((state) => state.accountConfigurations)
  const companyInfo = useSelector((state) => state.getCompanyInfo)
  const onsiteIntStatus = useSelector((state) => state.onsiteIntStatus)

  let creationInfo = JSON.parse(sessionStorage.getItem("creation_info"))
  const [interviewShows, setInterviewShows] = useState(
    creationInfo?.interviews ?? false,
  )

  const dispatch = useDispatch()
  const { state } = useLocation()
  const { t } = useTranslation()

  const userPermissions = logged?.user?.profile?.permission
  const isUserNotFound = interviews?.length === 0
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - interviews?.length) : 0

  const uniqueDept = Array.from(
    new Set(interviews.map((item) => item?.department)),
  )

  // -------------state for interviews slow loading scenarios-----
  const onLoad = () => {
    setFirstRender(true)
  }

  useEffect(() => {
    if (config?.edited) {
      setInterviewShows(true)
      setSeverity("success")
      setOpenSnackbar(true)
      setDisplayCrash(config.message)
      dispatch(resetConfigurationsFlag())
    }
  }, [config])

  useEffect(() => {
    if (onsiteIntStatus?.isSucceed) {
      setIsLoading(true)
      dispatch(getFilteredInterviews(interviewParams(false, valuetab), filters))
      setOpenSnackbar(true)
      setDisplayCrash("Interview status updated successfully.")
      setSeverity("success")
      dispatch(resetInterviewsFlag())
    }
  }, [onsiteIntStatus])

  useEffect(() => {
    if (departmentsInfo?.data?.lenght === 0) {
      dispatch(getAllDepartment())
    }
  }, [departmentsInfo])

  useEffect(() => {
    if (roles?.data.length === 0 || !isRolesLoaded) {
      setIsRolesLoading(true)
      setIsRolesLoaded(true)
      dispatch(getAllRoles('active'))
    } else {
      setIsRolesLoading(false)
    }
  }, [roles])

  useEffect(() => {
    if (candidateSkills?.data?.length === 0) {
      dispatch(getCandidateSkills())
    }
  }, [candidateSkills])

  useEffect(() => {
    window.addEventListener("beforeunload", onLoad)

    return () => {
      window.removeEventListener("beforeunload", onLoad)
    }
  }, [])

  const processInterviews = (interviews) => {
    if (interviews?.length > 0) {
      interviews = interviewDataParsing(interviews)
      handleAdditionalInterviews(interviews)
      setInterviews(interviews)
    } else {
      handleEmptyInterviews()
    }

    handleTabSelection()
  }

  const handleAdditionalInterviews = (interviews) => {
    if (
      (valuetab === 0 || valuetab === 1) &&
      !interviewShows &&
      !state?.getVacancySpecificInterviews
    ) {
      return [...interviews, ...interviewsSampleArr]
    }
  }

  const handleTabSelection = () => {
    if (!tabSelected) {
      if (
        (state?.vacancyId && state?.getVacancySpecificInterviews) ||
        userFlag.check
      ) {
        setValuetab(1)
      } else if (state?.openCompletedInterviewsTab) {
        setValuetab(4)
      }
    }
  }

  const handleEmptyInterviews = () => {
    if (
      (valuetab === 0 || valuetab === 1) &&
      !interviewShows &&
      !state?.getVacancySpecificInterviews
    ) {
      setInterviews(interviewsSampleArr)
    } else {
      setInterviews([])
    }
  }

  useEffect(() => {
    let interviews = filterintVue?.data?.interviews
    processInterviews(interviews)
  }, [state, filterintVue, interviewShows])

  useEffect(() => {
    if (filterintVue?.data?.success) {
      setDataLoading(false)
    } else {
      setDataLoading(true)
    }
  }, [filterintVue])

  // -----crash errors from backend scenarios---
  useEffect(() => {
    if (checkCrash?.url?.split("/")?.includes("interviews")) {
      if (checkCrash?.succeed === false || checkCrash?.apiError === true) {
        setOpenSnackbar(true)
        setDisplayCrash(checkCrash?.message)
        setSeverity("error")

        dispatch(resetErrors())
      }
    }
  }, [checkCrash])

  // Data Fetching Interviews
  useEffect(() => {
    if (intVue?.isDeleted) {
      setIsLoading(true)
      dispatch(getFilteredInterviews(interviewParams(false, valuetab), filters))
      dispatch(getAllInterviews("followup"))

      setOpenSnackbar(true)
      setDisplayCrash(intVue?.message)
      setSeverity("success")
      dispatch(resetInterviewsFlag())
    }

    if (intVue?.isCreated) {
      setIsLoading(true)
      dispatch(getFilteredInterviews(interviewParams(false, valuetab), filters))
      dispatch(getAllInterviews("followup"))

      setOpenSnackbar(true)
      setDisplayCrash(intVue?.message)
      setSeverity("success")
      dispatch(resetInterviewsFlag())
    }

    if (intVue?.isUpdated) {
      setIsLoading(true)
      dispatch(getFilteredInterviews(interviewParams(false, valuetab), filters))
      dispatch(getAllInterviews("followup"))

      setOpenSnackbar(true)
      setDisplayCrash(intVue?.message)
      setSeverity("success")
      dispatch(resetInterviewsFlag())
    }
    if (intVue?.isArchived) {
      setIsLoading(true)
      dispatch(getFilteredInterviews(interviewParams(false, valuetab), filters))
      dispatch(getAllInterviews("followup"))

      setOpenSnackbar(true)
      setDisplayCrash(intVue?.message)
      setSeverity("success")
      dispatch(resetInterviewsFlag())
    }

    if (intVue?.isInvite) {
      setOpenSnackbar(true)
      setDisplayCrash(intVue?.message)
      setSeverity("success")
      dispatch(resetInterviewsFlag())
    }

    if (intVue?.isFailure) {
      setOpenSnackbar(true)
      setDisplayCrash(intVue?.error)
      setSeverity("error")
      dispatch(resetInterviewsFlag())
    }

    if (intVue?.data?.length === 0) {
      dispatch(getAllInterviews("followup"))
    }

    if (intVue?.data?.success) {
      setNonFiltered(interviewDataParsing(intVue?.data?.interviews))
    } else {
      setNonFiltered([])
    }
  }, [intVue])

  const handleLoadingAndDispatch = () => {
    setFirstRender(false)
    setIsLoading(true)
    dispatch(getFilteredInterviews(interviewParams(), filters))
  }

  const handleInterviewsSetting = (intList) => {
    if (
      (valuetab === 0 || valuetab === 1) &&
      interviewShows !== true &&
      state?.getVacancySpecificInterviews !== true
    ) {
      setInterviews(intList)
    } else {
      setInterviews(interviewDataParsing(filterintVue?.data?.interviews))
    }
  }

  useEffect(() => {
    if (filterintVue?.data?.length === 0 || firstRender) {
      handleLoadingAndDispatch()
    } else {
      const combineIntList = [
        ...interviewDataParsing(filterintVue?.data?.interviews),
        ...interviewsSampleArr,
      ]

      if (filterintVue?.data?.success) {
        handleInterviewsSetting(combineIntList)
      } else {
        handleInterviewsSetting(interviewsSampleArr)
      }

      setIsLoading(false)
    }
  }, [filterintVue, interviewShows])

  useEffect(() => {
    if (awaitFeedback?.awaitFeedback === true) {
      setValuetab(0)
    }
  }, [intVue])

  useEffect(() => {
    if (existingQuestionSets?.data?.length === 0) {
      dispatch(getAllQuestionSet())
    }
  }, [existingQuestionSets])

  useEffect(() => {
    if (intvuer?.data?.length === 0) {
      dispatch(getAllInterviewers())
    }
    if (intvuer?.isCreated) {
      dispatch(resetInterviewsFlag())
      dispatch(getAllInterviewers())
    }
  }, [intvuer])

  useEffect(() => {
    if (members?.data?.length === 0) {
      dispatch(getAllMembers())
    }
  }, [members])

  ///////////////////////////////////////////////////////////////////////
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnackbar(false)
  }

  const interviewParams = (tabChanged = false, value = 0) => {
    let params = {}
    if (state?.vacancyId && state?.getVacancySpecificInterviews)
      params = merge(params, { vacancy: state?.vacancyId })

    return merge(params, {
      type:
        tabSelected === false && tabChanged === false
          ? (state?.openCompletedInterviewsTab && "awaiting") ||
            ((state?.getVacancySpecificInterviews || userFlag.check) &&
              "completed") ||
            renderFlag(value)
          : renderFlag(value),
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event)
    setPage(0)
  }

  const handleChangetab = (event, newValue) => {
    if (!isLoading) {
      setPage(0)
      setValuetab(newValue)
      setTabSelected(true)
      setIsLoading(true)
      setSelected([])
      dispatch(getFilteredInterviews(interviewParams(true, newValue), filters))
    }
  }

  const renderSwitch = (param) => {
    switch (param) {
      case 0:
        return TABLE_HEAD_SCHEDULE_INT(t)
      case 1:
        return TABLE_HEAD_COMPLETED_INT(t)
      case 2:
        return TABLE_HEAD_ARCHIVE_INT(t)
      case 3:
        return TABLE_HEAD_EXPIRE_INT(t)
      case 4:
        return TABLE_HEAD_SCHEDULE_INT(t)
      default:
    }
  }

  const renderFlag = (param) => {
    switch (param) {
      case 0:
        return "active"
      case 1:
        return "completed"
      case 2:
        return "archived"
      case 3:
        return "expired"
      case 4:
        return "awaiting"
      default:
    }
  }

  const getMessage = (value) => {
    switch (value) {
      case 0:
        return "ScheduleIntNotFound"
      case 1:
        return "CompletedIntNotFound"
      case 2:
        return "ArchivedIntNotFound"
      case 3:
        return "ExpriedIntNotFound"
      case 4:
        return "AwatiedIntNotFound"
      default:
    }
  }

  const handlePagination = (value) => {
    return interviews?.filter((item) =>
      item?.firstName?.toLowerCase()?.includes(searchName?.toLowerCase()),
    ).length
  }

  const refreshInt = () => {
    dispatch(getAllInterviewers())
    window.location.reload()
  }

  const applyFilter = (filter = null) => {
    setRowsPerPage(5)
    setPage(0)
    setFilters(filter)
    dispatch(getFilteredInterviews(interviewParams(false, valuetab), filter))
    setIsLoading(true)
  }

  const handleFilterSearch = (event) => {
    setSearchName(event.target.value)
    setPage(0)
  }

  const isThereAnyCompletedInterview = () =>
    nonFiltered?.findIndex((intItem) =>
      ["partially_completed", "completed"].includes(intItem.intStatus),
    ) >= 0

  const getIntVueType = (type) => {
    switch (type) {
      case "on_site":
        return "OnSite"
      case "recorded_by_applicant":
        return "Recorded"
      case "online_video_call":
        return "VideoCall"
      default:
        return type
    }
  }

  const matchedInterview = (id) => {
    for (let i = 0; i < filterintVue?.data?.interviews.length; i++) {
      if (id === filterintVue?.data?.interviews[i]?.id) {
        return filterintVue?.data?.interviews[i]
      }
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = interviews?.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = [...selected, id]
    } else {
      newSelected = selected.filter((selectedId) => selectedId !== id)
    }
    setSelected(newSelected)
  }
  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const handleCloseFilter = () => {
    setOpenFilter(false)
  }

  const showModal = (intType = null) => {
    // const isConfigured = sessionStorage.getItem("smtp_configured")
    // if (
    //   logged?.user?.user_type === "admin" &&
    //   isConfigured !== "true" &&
    //   isEmpty(logged?.user?.account?.configuration.smtp_settings)
    // ) {
    //   setIsShowing()
    // } else
    if (companyInfo?.data?.account?.no_of_interviews <= 0) {
      setIsShowingIntFinishModal()
    } else if (intType === "followup") {
      toggleFollowUpInt()
    } else {
      toggleScheduleInt()
    }
  }

  const naviagateToSMTP = () => {
    window.history.pushState(null, "", "/dashboard/interview")
    navigate("/dashboard/settings", { replace: true, state: { smtp: true } })
  }
  const [isShowingRecModal, toggleRecModal] = useModal()
  const [dataRec, setDataRec] = useState(undefined)

  return (
    <Page title={t("Interviews")} className='roles-page'>
      <Loader respLoading={dataLoading} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={errorSeverity}
          sx={{ width: "100%" }}
        >
          {displayCrash}
        </Alert>
      </Snackbar>
      <Container maxWidth='xl' spacing={3}>
        {userPermissions?.interviews_module ? (
          <>
            <Stack
              className='title-row'
              alignItems={{ xs: "flex-start", sm: "center" }}
              sx={{ mb: { xs: 2, sm: 4, md: 5 } }}
            >
              <Typography
                variant='h5'
                sx={{
                  mb: 0,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  color: "#434e58",
                }}
              >
                {t("Interviews")}: {isLoading ? 0 : handlePagination(valuetab)}{" "}
              </Typography>
              {isRolesLoading === false && (
                <Stack
                  className='rtl-button-row'
                  direction='row'
                  justifyContent='flex-end'
                  width={{ sm: "auto" }}
                >
                  {userPermissions?.interviews_schedule_interview_action && (
                    <Button
                      onClick={() => showModal("")}
                      variant='contained'
                      component={RouterLink}
                      to='#'
                      className='add-btn'
                      sx={{ ml: { xs: 0, sm: 2 }, mr: 2 }}
                      startIcon={<AddIcon />}
                    >
                      <span className='hidden-xs'>
                        {t("ScheduleInterview")}
                      </span>
                      <span className='visible-xs'>{t("Schedule")}</span>
                    </Button>
                  )}
                  <ScheduleIntModal
                    interviews={interviews}
                    isShowingScheduleInt={isShowingScheduleInt}
                    hide={toggleScheduleInt}
                    flag={true}
                  />

                  {isThereAnyCompletedInterview()
                    ? userPermissions?.interviews_schedule_follow_up_action && (
                        <>
                          <Button
                            onClick={() => showModal("followup")}
                            variant='contained'
                            component={RouterLink}
                            className='add-btn'
                            to='#'
                            startIcon={<AddIcon />}
                          >
                            <span className='hidden-xs '>
                              {t("ScheduleFollowup")}
                            </span>

                            <span className='visible-xs'>{t("Followup")}</span>
                          </Button>
                          <FollowUpIntModal
                            isShowingFollowupInt={isShowingFollowupInt}
                            hide={toggleFollowUpInt}
                          />
                        </>
                      )
                    : null}
                </Stack>
              )}
            </Stack>

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  flexGrow: 1,
                  maxWidth: "100%",
                }}
              >
                <Tabs
                  value={valuetab}
                  onChange={handleChangetab}
                  variant='scrollable'
                  scrollButtons='auto'
                  allowScrollButtonsMobile
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  {userPermissions?.interviews_module && (
                    <Tab label={t("ScheduledInterviews")} {...a11yProps(0)} />
                  )}
                  {userPermissions?.interviews_completed_interviews_tab && (
                    <Tab label={t("CompletedInterviews")} {...a11yProps(1)} />
                  )}
                  {userPermissions?.interviews_archived_interviews_tab && (
                    <Tab label={t("ArchivedInterviews")} {...a11yProps(2)} />
                  )}
                  {userPermissions?.interviews_expired_interviews_tab && (
                    <Tab label={t("ExpiredInterviews")} {...a11yProps(3)} />
                  )}
                  {userPermissions?.candidates_user_can_view_feedback &&
                    state?.openCompletedInterviewsTab && (
                      <Tab label={t("Awaiting Feedback")} {...a11yProps(4)} />
                    )}
                </Tabs>
              </Box>
              <TabPanel>
                <Card>
                  <InterviewListToolbar
                    uniqueDept={uniqueDept}
                    filterName={filterName}
                    searchName={searchName}
                    onFilterSearch={handleFilterSearch}
                    refreshInt={refreshInt}
                    onFilterName={handleFilterByName}
                    canSearch={userPermissions?.interviews_search_bar}
                    canFilterDpt={
                      userPermissions?.interviews_departments_dropdown_filter
                    }
                    numSelected={selected.length}
                    resetSelected={setSelected}
                    interviewIds={selected}
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                    handleOpenFilter={handleOpenFilter}
                    handleCloseFilter={handleCloseFilter}
                    tabing={renderFlag(valuetab)}
                    setInterviews={applyFilter}
                  />
                  <Scrollbar>
                    <TableContainer>
                      <Table className='simple-table'>
                        <InterviewListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={renderSwitch(valuetab)}
                          rowCount={interviews?.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />

                        {!isLoading && (
                          <TableBody>
                            {interviews
                              ?.filter((item) =>
                                (item?.firstName + " " + item?.lastName)
                                  ?.toLowerCase()
                                  ?.includes(searchName?.toLowerCase()),
                              )
                              ?.filter((item) =>
                                item?.department
                                  ?.toLowerCase()
                                  ?.includes(filterName?.toLowerCase()),
                              )
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                              )
                              .map((row, index) => {
                                const {
                                  id,
                                  firstName,
                                  lastName,
                                  candidateEmail,
                                  roleApplied,
                                  intType,
                                  intStatus,
                                  intDate,
                                  intCompletedOn,
                                  refCode,
                                  intVueFormat,
                                  intVuekey,
                                  intRecomdation,
                                  intNotRecomdation,
                                } = row
                                const isItemSelected =
                                  selected.indexOf(id) !== -1
                                return (
                                  <TableRow
                                    hover
                                    key={id + "-" + refCode}
                                    tabIndex={-1}
                                    role='checkbox'
                                    selected={isItemSelected}
                                    aria-checked={isItemSelected}
                                  >
                                    <TableCell padding='checkbox'>
                                      <Checkbox
                                        id={id}
                                        checked={isItemSelected}
                                        onChange={(event) =>
                                          handleClick(event, id)
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                      padding='none'
                                    >
                                      <Stack
                                        direction='row'
                                        alignItems='center'
                                        spacing={2}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          sx={{ textTransform: "capitalize" }}
                                          noWrap
                                        >
                                          {firstName + " " + lastName}
                                        </Typography>
                                      </Stack>
                                    </TableCell>

                                    <TableCell align='left'>
                                      {candidateEmail}
                                    </TableCell>
                                    <TableCell align='left' width='230'>
                                      <Stack minWidth='230px'>
                                        {roleApplied}
                                      </Stack>
                                    </TableCell>
                                    <TableCell align='left'>
                                      {intType === "first_contact"
                                        ? t("firstinterview")
                                        : t("followup")}
                                    </TableCell>
                                    <TableCell align='left'>
                                      {t(getIntVueType(intVueFormat))}
                                    </TableCell>

                                    <TableCell align='center'>
                                      {intVueFormat === "on_site" ? (
                                        <Stack
                                          sx={{
                                            color: `${
                                              intStatus === "timed_out" &&
                                              "#B72136"
                                            }`,
                                            whiteSpace: "noWrap",
                                            textAlign: "center",
                                          }}
                                        >
                                          {convertTimeZone(
                                            row?.intTime ?? intDate,
                                            row?.timeZone,
                                          )}
                                        </Stack>
                                      ) : (
                                        <Stack
                                          sx={{
                                            whiteSpace: "noWrap",
                                            textAlign: "center",
                                            color: "#0066e9",
                                          }}
                                        >
                                          {convertTimeZone(
                                            row?.expiresOnConverted,
                                            row?.timeZone,
                                          )}
                                        </Stack>
                                      )}
                                    </TableCell>

                                    {valuetab !== 0 && (
                                      <TableCell align='center'>
                                        <Stack
                                          sx={{
                                            whiteSpace: "noWrap",
                                            textAlign: "center",
                                          }}
                                        >
                                          {valuetab === 3 ? convertTimeZone(
                                            intCompletedOn,
                                            row?.timeZone
                                          ) : convertTimeZone(
                                            intCompletedOn,
                                            DBTimeZone,
                                            row?.timeZone
                                          )}
                                        </Stack>
                                      </TableCell>
                                    )}

                                    {valuetab === 2 ? null : (
                                      <TableCell align='center'>
                                        <Label
                                          variant='ghost'
                                          color={
                                            (intStatus === "timed_out" &&
                                              "error") ||
                                            "success"
                                          }
                                        >
                                          {t(
                                            row.id === "int102" &&
                                              valuetab === 0
                                              ? "Pending"
                                              : capitalCase(intStatus),
                                          )}
                                        </Label>
                                      </TableCell>
                                    )}

                                    {(valuetab === 0 || valuetab === 4) && (
                                      <TableCell>{refCode}</TableCell>
                                    )}
                                    {(valuetab === 1 || valuetab === 2) && (
                                      <TableCell
                                        align='left'
                                        sx={{ textAlign: "center" }}
                                      >
                                        <Stack
                                          direction='row'
                                          alignContent='center'
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "row",
                                              mx: 1,
                                            }}
                                          >
                                            <HtmlTooltip
                                              title={
                                                <React.Fragment>
                                                  <Box
                                                    className='listing-row'
                                                    sx={{
                                                      p: 1,
                                                      maxWidth: "250px",
                                                    }}
                                                  >
                                                    {!isEmpty(
                                                      intRecomdation,
                                                    ) === true ? (
                                                      <RecommendationTooltip
                                                        data={intRecomdation}
                                                        setDataRec={setDataRec}
                                                        toggleRecModal={
                                                          toggleRecModal
                                                        }
                                                      />
                                                    ) : (
                                                      "No Recommendation avaialbe"
                                                    )}
                                                  </Box>
                                                </React.Fragment>
                                              }
                                            >
                                              <Button
                                                sx={{ p: 0, minWidth: "auto" }}
                                              >
                                                <Label
                                                  variant='ghost'
                                                  color='success'
                                                >
                                                  <ThumbUpOffAltIcon
                                                    sx={{
                                                      color: "#64748b",
                                                      fontSize: "16px",
                                                      mx: 1,
                                                    }}
                                                  />

                                                  {
                                                    row?.intVote[0]
                                                      .recomend_votes
                                                  }
                                                </Label>
                                              </Button>
                                            </HtmlTooltip>
                                            {intRecomdation?.find(
                                              (rec, index) =>
                                                rec?.id === dataRec,
                                            ) && (
                                              <ViewFeedbackModal
                                                hide={toggleRecModal}
                                                isShowingViewFeedback={
                                                  isShowingRecModal
                                                }
                                                data={intRecomdation?.find(
                                                  (rec, index) =>
                                                    rec?.id === dataRec,
                                                )}
                                              />
                                            )}
                                          </Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              flexDirection: "row",
                                              mx: 1,
                                            }}
                                          >
                                            <HtmlTooltip
                                              title={
                                                <React.Fragment>
                                                  <Box
                                                    className='listing-row'
                                                    sx={{
                                                      p: 1,
                                                      maxWidth: "250px",
                                                      whiteSpace:
                                                        "break-spaces",
                                                      wordBreak: "break-word",
                                                    }}
                                                  >
                                                    {!isEmpty(
                                                      intNotRecomdation,
                                                    ) === true ? (
                                                      <RecommendationTooltip
                                                        data={intNotRecomdation}
                                                        setDataRec={setDataRec}
                                                        toggleRecModal={
                                                          toggleRecModal
                                                        }
                                                      />
                                                    ) : (
                                                      "No Recommendation avaialbe"
                                                    )}
                                                  </Box>
                                                </React.Fragment>
                                              }
                                            >
                                              <Button
                                                sx={{ p: 0, minWidth: "auto" }}
                                              >
                                                <Label
                                                  variant='ghost'
                                                  color='error'
                                                >
                                                  <ThumbDownOffAltIcon
                                                    sx={{
                                                      color: "#64748b",
                                                      fontSize: "16px",
                                                      mx: 1,
                                                    }}
                                                  />

                                                  {row?.intVote[0].denied_votes}
                                                </Label>
                                              </Button>
                                            </HtmlTooltip>
                                            {intNotRecomdation?.find(
                                              (rec, index) =>
                                                rec?.id === dataRec,
                                            ) && (
                                              <ViewFeedbackModal
                                                hide={toggleRecModal}
                                                isShowingViewFeedback={
                                                  isShowingRecModal
                                                }
                                                data={intNotRecomdation?.find(
                                                  (rec, index) =>
                                                    rec?.id === dataRec,
                                                )}
                                              />
                                            )}
                                          </Box>
                                        </Stack>
                                      </TableCell>
                                    )}

                                    <TableCell
                                      align='right'
                                      className='stickyCell'
                                      width='8%'
                                      stickyHeader={true}
                                    >
                                      {(renderFlag(valuetab) === "active" &&
                                        (logged?.user?.profile?.permission
                                          ?.interviews_schedule_interviews_resend_invitation ||
                                          (logged?.user?.profile?.permission
                                            ?.interviews_completed_interviews_feedback_required &&
                                            intVueFormat === "on_site") ||
                                          logged?.user?.profile?.permission
                                            ?.interviews_schedule_interviews_edit_interview ||
                                          logged?.user?.profile?.permission
                                            ?.interviews_schedule_interviews_delete_interview)) ||
                                      (renderFlag(valuetab) === "completed" &&
                                        (logged?.user?.profile?.permission
                                          ?.interviews_completed_interviews_candidate_history ||
                                          logged?.user?.profile?.permission
                                            ?.interviews_completed_interviews_feedback_required ||
                                          logged?.user?.profile?.permission
                                            ?.candidates_user_can_view_feedback ||
                                          logged?.user?.profile?.permission
                                            ?.interviews_completed_interviews_archive_interview)) ||
                                      (renderFlag(valuetab) === "archived" &&
                                        logged?.user?.profile?.permission
                                          ?.interviews_archived_interviews_delete_interview) ||
                                      (renderFlag(valuetab) === "expired" &&
                                        logged?.user?.profile?.permission
                                          ?.interviews_expired_interviews_delete_interview) ||
                                      (renderFlag(valuetab) === "awaiting" &&
                                        logged?.user?.profile?.permission
                                          ?.candidates_user_can_view_feedback) ? (
                                        <InterviewMoreMenu
                                          disabled={selected?.length > 0}
                                          tabing={renderFlag(valuetab)}
                                          id={id}
                                          intVueFormat={intVueFormat}
                                          intVuekey={intVuekey}
                                          intStatus={intStatus}
                                          isSample={row.id === "int102"}
                                          intVue={
                                            row.id === "int102"
                                              ? interviewsArr.find(
                                                  (int) =>
                                                    int.key === intVuekey,
                                                )
                                              : matchedInterview(id)
                                          }
                                        />
                                      ) : null}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={9} />
                              </TableRow>
                            )}
                          </TableBody>
                        )}

                        {isUserNotFound && !isLoading && (
                          <TableBody>
                            <TableRow>
                              <TableCell className='searchCell' colSpan={10}>
                                <Typography variant='body2' align='left'>
                                  {t(getMessage(valuetab))} &nbsp;
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}

                        {isLoading && !dataLoading && (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mb: 3,
                                  }}
                                >
                                  <PulseLoader
                                    color={"#0066E9"}
                                    loading={true}
                                    size={16}
                                    speedMultiplier='1'
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    labelRowsPerPage={t("RowsPerPage")}
                    count={isLoading ? 0 : handlePagination(valuetab)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </TabPanel>
            </Box>
            <ConfirmationModal
              isShowingModal={isShowingModal}
              hide={setIsShowing}
              message='Please configure your smtp in Settings.'
              isSMPT={true}
              confirmAction={naviagateToSMTP}
            />

            <IntFinishedModal
              isShowingIntRunOut={isShowingIntFinishModal}
              hide={setIsShowingIntFinishModal}
            />
          </>
        ) : (
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("NoAccessPAge")}
            </Typography>

            <Box
              component='img'
              src='/static/illustrations/illustration_404.svg'
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </ContentStyle>
        )}
      </Container>
    </Page>
  )
}
