import React, { useState } from "react"
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"

import CloseIcon from "@mui/icons-material/Close"
import { useTheme } from "@mui/material/styles"
import Images from "src/assets/img/Images"

export default function IntFinishedModal({ isShowingIntRunOut, hide }) {
  const theme = useTheme()

  return (
    isShowingIntRunOut && (
      <Dialog
        className='dialog400'
        open={isShowingIntRunOut}
        onClose={hide}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='md'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ height: "10px", padding: 0 }}
        >
          <IconButton
            aria-label='close'
            onClick={hide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className='dialog-content'
          dividers={true}
          sx={{ border: 0 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 1,
                  mb: 2,
                }}
                className='image-banner'
              >
                <img src={Images.RunoutImg} alt='Run out of interview' />
              </Box>
              <Typography
                variant='h4'
                component='h4'
                sx={{ textAlign: "center", fontWeight: 600, px: 2 }}
              >
                You've run out of interviews
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography variant='body2' component='p'>
                You have reached your subscribed limit.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          className='dialog-footer'
          sx={{ justifyContent: "center", pb: 2 }}
        ></DialogActions>
      </Dialog>
    )
  )
}
