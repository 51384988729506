import * as Yup from "yup"
import Snackbar from "@mui/material/Snackbar"
import Alert from "src/components/Alert"
import React, { useState, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useFormik, Form, FormikProvider } from "formik"
import {
  Button,
  Box,
  Typography,
  Link,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useDispatch, useSelector } from "react-redux"
import { resetAuthData, signUp } from "src/redux/actions/signup"
import { cardDetails } from "src/redux/actions/stripeCardDetails"
import { Step1, Step2, Step4 } from "./index"
import Images from "../../../assets/img/Images"
import { isEmpty } from "lodash"
const steps = ["Company Details", "User Details", "Confirmation"]

export default function SignUpForm({ parsedPlans, onSignUpSuccess }) {
  const [displayCrash, setDisplayCrash] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorSeverity, setSeverity] = useState("")
  const [userSelectedPlan, setUserSelectedPlan] = useState(null)

  // Invoking Redux Structure
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  const dispatch = useDispatch()
  const check = useSelector((state) => state.cardBucket)
  const signed = useSelector((state) => state.signup)
  const card = useSelector((state) => state.addCard)

  const selectedPlanData = useSelector((state) => state.plans)

  useEffect(() => {
    if (selectedPlanData.selected) {
      setUserSelectedPlan(selectedPlanData.selected)
    }
  }, [selectedPlanData])

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  })

  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    dispatch(cardDetails("fetch"))
  }, [check])

  const [activeStep, setActiveStep] = React.useState(0)
  const [lastStep, setLastStep] = useState(false)
  const [sucessful, setIsSuccessful] = useState(false)
  const [cardAdded, setCardAdded] = useState(null)

  const SignupSchema = Yup.object().shape({
    firstName: Yup.string()
      .matches(/^[\p{L}\s'-]+$/u, 'only allows letters, digits "-", "\'", and space')
      .min(2, "Too Short!")
      .max(15, "Too Long!")
      .required("First name is required"),

    lastName: Yup.string()
      .matches(/^[\p{L}\s'-]+$/u, 'only allows letters, digits "-", "\'", and space')
      .min(2, "Too Short!")
      .max(15, "Too Long!")
      .required("Last name is required"),
    emailAccount: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, "It must be a valid email")
      .max(50, "Too Long!")
      .required("Email is required"),
    password: Yup.string()
      .min(
        8,
        "Password must be at least 8 characters (include letters, digit and special character).",
      )
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character",
      )

      .required("Password is required"),
    confirm_password: Yup.string()
      .required("Password is required")
      .oneOf(
        [Yup.ref("password"), null],
        "Those passwords didn’t match. Try again.",
      ),

    companyName: Yup.string()
      .required("Company name should not be empty")
      .matches(
        /^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/,
        "Blank spaces are not allowed",
      ),
    website: Yup.string()
      .matches(
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
        "Enter correct url!",
      )
      .required("Please enter website"),
  })

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      emailAccount: "",
      password: "",
      confirm_password: "",
      uploadAvatar: "",
      cardToken: "",
      companyName: "",
      website: "",
      companyDetail: "",
      uploadLogo: "",
      uploadVideo: "",
      videoUrl: null,
      videoName: "",
      companyType: "",
      planType: "",
      plan_Token: "",
      plan_id: userSelectedPlan ? userSelectedPlan.id : 1,
      free_trial: "",
      free_trial_count: "",
      stripe_token: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values, { resetForm, isSubmitting }) => {
      const newFormData = new FormData()
      newFormData.append("logo", values.uploadLogo.file ?? "")
      newFormData.append("default_video", values.uploadVideo.file ?? "")
      newFormData.append("email", values.emailAccount)
      newFormData.append("first_name", values.firstName)
      newFormData.append("last_name", values.lastName)
      newFormData.append("company_name", values.companyName)
      newFormData.append("website", values.website)
      newFormData.append("plan_id", userSelectedPlan ? userSelectedPlan.id : 1)
      newFormData.append("introduction", values.companyDetail ?? "")
      newFormData.append("password", values.password)
      newFormData.append("avatar", values.uploadAvatar.file ?? "")
      newFormData.append("companyType", values.companyType ?? "")
      newFormData.append("billing_email", cardAdded?.billingEmail ?? "")
      const cardToken = localStorage.getItem("plan_token")
      newFormData.append("card_token", cardToken)
      newFormData.append("auto_renewal", true)
      if(!isEmpty(applyCode)) {
        let promo_code = {
          id: applyCode.id,
          coupon_id: applyCode.coupon.id
        }
        newFormData.append("promo_code", JSON.stringify(promo_code))
      }
      localStorage.removeItem("card_token")

      dispatch(signUp(newFormData))
      setButtonLoading(true)

      setLastStep(true)
    },
  })

  const {
    errors,
    touched,
    getFieldProps,
    values,
    resetForm,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik

  const [loginBtn, setLoginBtn] = useState(false)
  const [signUpBtn, setSignUpBtn] = useState(true)
  const [isBasicPlan, setIsBasicPlan] = useState(true)
  const [isEmailAvailable, setIsEmailAvailable] = useState(null)
  const [isNameAvailable, setIsNameAvailable] = useState(null)
  const [isWebsiteAvailable, setIsWebsiteAvailable] = useState(null)
  const [applyCode, setApplyCode] = useState({})

  useEffect(() => {
    if (signed?.status === 200) {
      if (signed?.data?.success === true) {
        if (signed?.isCreated) {
          dispatch(resetAuthData())
        }
        setIsSuccessful(true)
        setSignUpBtn(false)
        setLoginBtn(true)
        resetForm({ values: "" })
        if (onSignUpSuccess) {
          onSignUpSuccess()
        }
      }
    }
    if (signed?.status === false || signed?.data?.success === false) {
      let message = signed?.message[0]

      if (signed?.data?.errors) {
        message = signed?.data?.errors[0]
      }
      setButtonLoading(false)
      setSeverity("error")
      setOpenSnackbar(true)
      setDisplayCrash(message)
    }
    if (signed?.status === 204 && !signed?.isCreated) {
      setButtonLoading(false)
      setSeverity("error")
      setOpenSnackbar(true)
      setDisplayCrash("Something went wrong please try again!")
    }
  }, [signed])

  const handleNext = () => {
    let data = formik?.values
    let error = formik?.errors
    if (
      activeStep === 0 &&
      (data?.companyName &&
        data?.website &&
        error?.website &&
        error.companyName) === ""
    ) {
      setState({ open: true })
    } else if (
      activeStep === 1 &&
      (data?.firstName &&
        data?.lastName &&
        data?.emailAccount &&
        error.emailAccount) === ""
    ) {
      setState({ open: true })
    } else {
      setActiveStep(activeStep + 1)
      setState({ open: false })
      setLastStep(false)
    }
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }
  const handleClose = () => {
    window.open(`${process.env.REACT_APP_FE_BASE_URL}/Home/app`, "_self")
  }

  const [passwordField, setPasswordField] = useState("")
  const [passwordStrength, setPasswordStrength] = useState("")
  const handlePasswordChange = (e) => {
    handleChange(e)
    const newPassword = e.target.value

    setPasswordField(newPassword)

    if (
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/.test(newPassword)
    ) {
      setPasswordStrength("Strong")
    } else if (
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(newPassword) ||
      /^^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{0,7}$/.test(
        newPassword,
      ) ||
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*#?&]).{8,}$/.test(newPassword) ||
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/.test(newPassword) ||
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/.test(newPassword)
    ) {
      setPasswordStrength("Medium")
    } else {
      setPasswordStrength("Weak")
    }
  }
  const [checked, setChecked] = useState(false)
  const handleChangeTerms = (event) => {
    setChecked(event.target.checked)
  }
  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        {!sucessful && (
          <>
            <Box className='steps' activeStep={activeStep + 1}>
              {steps.map((label, index) => (
                <Stack
                  key={label}
                  id={"sleek" + (index + 1)}
                  className={`sleek-btn ${
                    activeStep === index ? "active" : ""
                  }`}
                >
                  <span> {index + 1}</span>
                  {label}
                </Stack>
              ))}
            </Box>
            {activeStep === 0 && (
              <Box>
                <Step1
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  getFieldProps={getFieldProps}
                  handleNameAvailabilityCheck={(available) =>
                    setIsNameAvailable(available)
                  }
                  handleWebsiteAvailabilityCheck={(available) =>
                    setIsWebsiteAvailable(available)
                  }
                />
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <Step2
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleEmailAvailabilityCheck={(available) =>
                    setIsEmailAvailable(available)
                  }
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  getFieldProps={getFieldProps}
                  passwordField={passwordField}
                  passwordStrength={passwordStrength}
                  handlePasswordChange={handlePasswordChange}
                />
              </Box>
            )}
          </>
        )}

        {activeStep === 2 && (
          <Box>
            {sucessful === true ? (
              <Box className='inner-note' sx={{ maxWidth: "400px" }}>
                <Typography variant='h3' gutterBottom>
                  Thank you!
                </Typography>

                <Typography variant='subtitle1' component='p'>
                  Thank you for subscribing to IntVue. Please follow the
                  instructions in the confirmation email that has been sent to
                  you to activate and login to your account.
                </Typography>
              </Box>
            ) : (
              <Step4
                values={values}
                touched={touched}
                errors={errors}
                setFieldValue={setFieldValue}
                onSelectPlan={(plan) => {
                  setCardAdded(plan === "Basic Monthly" ? null : cardAdded)
                  setIsBasicPlan(plan === "Basic Monthly")
                }}
                onCardAdd={(card) => setCardAdded(card)}
                setApplyCode={setApplyCode}
              />
            )}
          </Box>
        )}
        <Box className='footer-wizard' sx={{ my: 2 }}>
          {sucessful !== true && activeStep === 2 && (
            <FormGroup sx={{ mb: 1, width: "100%", fontSize: { xs: "" } }}>
              <FormControlLabel
                sx={{ alignItems: "flex-start" }}
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleChangeTerms}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ py: "0", px: "9px !important" }}
                  />
                }
                label={
                  <Typography sx={{ wordBreak: "break-word" }}>
                    I agree to the&nbsp;
                    <Link
                      underline='none'
                      variant='body2'
                      target='_blank'
                      href='/termsconditions/app'
                      sx={{ fontSize: "14px", whiteSpace: "break-spaces" }}
                    >
                      Terms & Conditions
                    </Link>
                    &nbsp;and&nbsp;
                    <Link
                      href='/privacypolicy/app'
                      target='_blank'
                      underline='none'
                      variant='body2'
                      sx={{ fontSize: "14px", whiteSpace: "break-spaces" }}
                    >
                      Privacy Policy.
                    </Link>
                  </Typography>
                }
              />
            </FormGroup>
          )}

          <Stack direction='row' justifyContent='space-between'>
            <Box>
              {activeStep === 0 && (
                <>
                  <Typography
                    variant='body2'
                    sx={{ mt: 1 }}
                    className='hide-xs'
                  >
                    Already have an account?{" "}
                    <Link
                      className='bold-link'
                      variant='subtitle2'
                      to='/login'
                      underline='hover'
                      sx={{ my: 1 }}
                      component={RouterLink}
                    >
                      <img src={Images.ArrowCorverBlue} /> Sign In
                    </Link>
                  </Typography>
                  <Button
                    variant='contained'
                    component='a'
                    href='/login'
                    className='hidden-sm'
                    sx={{ mt: 1 }}
                  >
                    Sign In
                  </Button>
                </>
              )}
              {activeStep !== 0 && !sucessful && (
                <Button
                  onClick={handleBack}
                  color='info'
                  variant='contained'
                  sx={{ mt: 1 }}
                >
                  Previous
                </Button>
              )}
            </Box>
            <Box>
              {activeStep < 2 && (
                <Button
                  variant='contained'
                  onClick={handleNext}
                  sx={{ mt: 1, ml: 1 }}
                  disabled={
                    activeStep === 0
                      ? formik.errors.companyName ||
                        formik.errors.website ||
                        isNameAvailable === false ||
                        isWebsiteAvailable === false ||
                        values.companyName === "" ||
                        values.website === ""
                      : activeStep === 1
                      ? formik.errors.firstName ||
                        formik.errors.lastName ||
                        formik.errors.password ||
                        formik.errors.confirm_password ||
                        formik.errors.emailAccount ||
                        isEmailAvailable === false
                      : false
                  }
                >
                  Next
                </Button>
              )}
              {activeStep === 2 && signUpBtn && (
                <LoadingButton
                  sx={{ mt: 1, ml: 1 }}
                  loadingindicator='Loading…'
                  size='medium'
                  type='submit'
                  variant='contained'
                  loading={buttonLoading}
                  disabled={
                    (cardAdded === null && !isBasicPlan) || checked === false
                  }
                >
                  Sign Up
                </LoadingButton>
              )}
            </Box>
            {activeStep === 2 && loginBtn && (
              <Stack sx={{ width: "100%" }}>
                <Button
                  variant='contained'
                  onClick={handleClose}
                  sx={{ maxWidth: "100px" }}
                >
                  Finish
                </Button>
              </Stack>
            )}
          </Stack>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={errorSeverity}
              sx={{ width: "100%" }}
            >
              {displayCrash}
            </Alert>
          </Snackbar>
        </Box>
      </Form>
    </FormikProvider>
  )
}
