import PropTypes from "prop-types"
import { capitalCase } from "change-case"
import React, { useState, useEffect } from "react"
import {
  Button,
  Box,
  Stack,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
} from "@mui/material"
import Icons from "src/components/Icons"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import PieChartIcon from "@mui/icons-material/PieChart"
import WorkIcon from "@mui/icons-material/Work"
import VideoChatIcon from "@mui/icons-material/VideoChat"
import QuizIcon from "@mui/icons-material/Quiz"
import PersonIcon from "@mui/icons-material/Person"
import LanIcon from "@mui/icons-material/Lan"
import PeopleIcon from "@mui/icons-material/People"
import SettingsIcon from "@mui/icons-material/Settings"
import { useSelector } from "react-redux"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import { useTranslation, withTranslation, Trans } from "react-i18next"

export default function MemberPrivilege({
  open,
  selectedUser,
  onClose,
  hide,
  flag,
  data,
}) {
  const { t } = useTranslation()
  const handleClose = () => {
    setActiveItemIndex(0)
    setSelectedModule(permissionModules[0])
    onClose()
  }

  const [value, setValue] = useState(0)
  const permit = useSelector((state) => state.profile)

  const [permissionModules, setPermissionModules] = useState([])
  const [selectedModule, setSelectedModule] = useState([])

  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const [module, setModule] = useState([])
  const team = useSelector((state) => state.members)

  useEffect(() => {
    if (selectedUser?.permission) {
      const permissions = selectedUser.permission
      let permissionModules = []
      let dashboardPermissions = []
      let questionPermissions = []
      let vacancyPermissions = []
      let interviewPermissions = []
      let candidatePermissions = []
      let companyUserPermissions = []
      let departmentPermissions = []
      let settingPermissions = []
      for (const key in permissions) {
        const name = capitalCase(key)
        const value = permissions[key]

        if (key.startsWith("dashboard")) {
          dashboardPermissions.push({ name: name, value: value })
        }
        if (key.startsWith("question_sets")) {
          questionPermissions.push({ name: name, value: value })
        }
        if (key.startsWith("vacancy")) {
          vacancyPermissions.push({ name: name, value: value })
        }
        if (key.startsWith("interviews")) {
          interviewPermissions.push({ name: name, value: value })
        }
        if (key.startsWith("candidates")) {
          candidatePermissions.push({ name: name, value: value })
        }
        if (key.startsWith("company_user")) {
          companyUserPermissions.push({ name: name, value: value })
        }
        if (key.startsWith("departments")) {
          departmentPermissions.push({ name: name, value: value })
        }
        if (key.startsWith("settings") && !key.endsWith("department")) {
          settingPermissions.push({ name: name, value: value })
        }
      }
      permissionModules.push({
        icon: PieChartIcon,
        name: "Dashboard",
        id: "dashboard_module",
        permissions: dashboardPermissions,
      })
      permissionModules.push({
        icon: QuizIcon,
        name: "Questions",
        id: "question_sets_module",
        permissions: questionPermissions,
      })
      permissionModules.push({
        icon: WorkIcon,
        name: "Vacancies",
        id: "",
        permissions: vacancyPermissions,
      })
      permissionModules.push({
        icon: VideoChatIcon,
        name: "Interviews",
        permissions: interviewPermissions,
      })
      permissionModules.push({
        icon: PersonIcon,
        name: "Candidates",
        permissions: candidatePermissions,
      })
      permissionModules.push({
        icon: PeopleIcon,
        name: "Company Users",
        permissions: companyUserPermissions,
      })
      permissionModules.push({
        icon: LanIcon,
        name: "Departments",
        permissions: departmentPermissions,
      })
      permissionModules.push({
        icon: SettingsIcon,
        name: "Settings",
        permissions: settingPermissions,
      })
      setPermissionModules(permissionModules)
      setSelectedModule(permissionModules[0])
    }
  }, [selectedUser])

  const handleModuleSelection = (module) => {
    setSelectedModule(module)
  }

  useEffect(() => {
    handleModuleSelection(permissionModules[0])
  }, [team])
  const [scrollPosition, setScrollPosition] = useState(0)
  const scrollStep = 200 // Adjust this value to control the scroll distance

  const handleScroll = (direction) => {
    const container = document.getElementById("scrollContainer")
    const newScrollPosition =
      direction === "left"
        ? scrollPosition - scrollStep
        : scrollPosition + scrollStep

    container.scrollTo({
      top: 0,
      left: newScrollPosition,
      behavior: "smooth",
    })

    setScrollPosition(newScrollPosition)
  }
  return (
    <Dialog
      className='dialog800'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='xl'
    >
      <DialogTitle
        id='alert-dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        <Stack direction='row' alignItems='baseline'>
          {selectedUser?.name}
          <Box
            sx={{
              color: "var(--info)",
              marginLeft: "0.5rem",
              fontSize: "12px",
              fontWeight: 500,
              mx: 1,
            }}
          >
            - {t("Privileges")}
          </Box>
        </Stack>

        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className='dialog-content' dividers={true}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
          }}
        >
          <Grid container spacing={0} sx={{ position: { xs: "relative" } }}>
            <Grid
              item
              xs={12}
              sm={3}
              md={3}
              id='scrollContainer'
              className='border-right scroll-container'
              sx={{
                maxHeight: { xs: "50px", sm: "100%" },
                position: { md: "sticky", sm: "relative" },
                overflowX: { xs: "scroll", sm: "hidden" },
                display: { xs: "flex" },
                width: { xs: "100%", sm: "inherit" },
                flexDirection: { xs: "row", sm: "column" },
              }}
            >
              <Button className='left-btn' onClick={() => handleScroll("left")}>
                <ArrowBackIosNewIcon sx={{ fontSize: "16px !important" }} />
              </Button>

              {permissionModules.map((module, index) => (
                <Box
                  sx={{
                    borderRight: {
                      xs: "0 solid transparent",
                      sm: "4px solid transparent",
                    },
                    borderBottom: {
                      xs: "4px solid transparent",
                      sm: "0",
                    },

                    whiteSpace: { xs: "nowrap", sm: "normal" },
                    paddingLeft: { xs: "10px" },
                    paddingRight: { xs: "10px" },
                    "&:hover": {
                      color: "#0066E9",
                      borderColor: "#0066E9",
                    },
                  }}
                  className={`navIcons ${
                    activeItemIndex === index && "active"
                  }`}
                  onClick={() => {
                    handleModuleSelection(module)
                    setActiveItemIndex(index)
                  }}
                >
                  {
                    <Icons
                      icon={module.icon}
                      sx={{ height: "18px !important" }}
                    />
                  }
                  {t(module.name)}
                </Box>
              ))}
              <Button
                className='right-btn'
                onClick={() => handleScroll("right")}
              >
                <ArrowForwardIosIcon sx={{ fontSize: "16px !important" }} />
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              md={9}
              sx={{
                height: { xs: "auto", sm: "auto", md: "360px" },
                overflowY: { xs: "hidden", sm: "hidden", md: "scroll" },
              }}
            >
              <Box>
                {selectedModule && (
                  <Typography
                    component='h3'
                    sx={{
                      padding: "6px 16px 6px 16px",
                      fontWeight: 600,
                    }}
                  >
                    {t(selectedModule?.name + " Permissions")}
                  </Typography>
                )}

                <TableContainer width='100%' component={Paper}>
                  <Table
                    sx={{
                      minWidth: { xs: "auto", sm: 450 },
                      width: "94%",
                      marginLeft: "3%",
                      marginRight: "3%",
                      boxSizing: "border-box",
                    }}
                    className='bordered-table'
                    aria-label='caption table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "white !important",
                            color: "var(--slate600) !important",
                            textTransform: "capitalize !important",
                            fontWeight: 600,
                          }}
                        >
                          {t("Feature")}
                        </TableCell>
                        <TableCell
                          width={{ xs: "60", sm: "60", md: "100" }}
                          align='right'
                          sx={{
                            backgroundColor: "white !important",
                            color: "var(--slate600) !important",
                            textTransform: "capitalize !important",
                            fontWeight: 600,
                          }}
                        >
                          <span className='hidden-xs'>{t("Permissions")}</span>
                          <Stack className='visible-xs' alignItems='flex-end'>
                            <ManageAccountsIcon
                              sx={{
                                width: 24,
                                height: 24,
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className='bordered-table-body'>
                      {selectedModule?.permissions &&
                        selectedModule?.permissions.map((permit) => {
                          return (
                            <TableRow className='responsive-row-justify'>
                              <TableCell
                                component='th'
                                scope='row'
                                className='permit-name'
                                sx={{
                                  lineHeight: {
                                    xs: "1.2em",
                                    sm: "1.0em",
                                  },
                                }}
                              >
                                {permit.name.endsWith("Module")
                                  ? t(permit.name)
                                  : permit.name.startsWith("Company User")
                                  ? t(permit.name.replace("Company User", ""))
                                  : t(permit.name)}
                              </TableCell>
                              <TableCell align='center'>
                                {JSON.stringify(permit.value) === "false" ? (
                                  <CloseIcon
                                    sx={{
                                      width: 18,
                                      height: 18,
                                      color: "#d23939",
                                    }}
                                  />
                                ) : (
                                  <DoneIcon
                                    sx={{
                                      width: 18,
                                      height: 18,
                                      color: "#338d57",
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions className='dialog-footer'>
        <Button
          onClick={handleClose}
          variant='outlined'
          color='primary'
          size='medium'
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
