import { React } from "react"
import { styled } from "@mui/material/styles"
import {
  Typography,
  Container,
  Link,
  List,
  ListItem,
  Button,
} from "@mui/material"
import Page from "../components/Page"
import ViewCookies from "src/sections/@landingPage/ViewCookieModal"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: 50,
  borderRadius: 16,
  boxShadow: "0 0 14px rgb(0 0 0 / 4%)",
  background: "#fff",
  [theme.breakpoints.up("md")]: {
    marginTop: 150,
    padding: "4rem",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 100,
    padding: "2rem 2.5rem",
  },
}))

// ----------------------------------------------------------------------

export default function Cookies(props) {
  const [isShowingCookie, toggleCookie] = useModal()
  return (
    <Page title='Cookie Policy'>
      <Container maxWidth='lg'>
        <ContentStyle className='page-content'>
          <Typography variant='h2' component='h2' sx={{ mb: 3 }}>
            Cookie Policy
          </Typography>

          <Typography variant='body1'>
            Our Cookie Policy explains the type of cookies we use, how we use
            them with your consent, the information we collect and how it is
            used, and how you can manage the cookie settings in full
            transparency.
          </Typography>
          <Typography variant='body1'>
            The cookies we use help us improve your experience on our site and
            analyse our site traffic and performance, make it more secure, and
            understand where it needs improvement.
          </Typography>
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            How we use cookies
          </Typography>
          <Typography variant='body1'>
            {" "}
            We use built-in cookies. These cookies are necessary for the correct
            functioning of our website and help us improve your experience.
            These cookies do not collect any personally identifiable data.
          </Typography>
          <Typography variant='body1'>
            The other cookies are mainly for understanding how users interact on
            our website and its performance, keeping our site secure, and
            helping us to deliver an improved user experience.
          </Typography>
          {/* <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Types of Cookies we use
          </Typography> */}

          {/* <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            What Are Cookies
          </Typography>
          <Typography variant='body1'>
            As is common practice with almost all professional websites this
            site uses cookies, which are tiny files that are downloaded to your
            computer, to improve your experience. This page describes what
            information they gather, how we use it and why we sometimes need to
            store these cookies. We will also share how you can prevent these
            cookies from being stored however this may downgrade or 'break'
            certain elements of the sites functionality.
          </Typography> */}
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            How We Use Cookies
          </Typography>
          <Typography variant='h5' component='h5' sx={{ mb: 1, mt: 1 }}>
            Necessary
          </Typography>
          <Typography variant='body1'>
            Browser cookies play a crucial role in saving login details,
            contributing to an enhanced user experience by facilitating seamless
            authentication and personalized interactions on websites."
          </Typography>
          <Typography variant='h5' component='h5' sx={{ mb: 1, mt: 1 }}>
            Analytics
          </Typography>
          <Typography variant='body1'>No cookies to display</Typography>
          <Typography variant='h4' component='h4' sx={{ mb: 1, mt: 1 }}>
            Manage cookie preferences
          </Typography>
          <Typography variant='body1'>
            <Button
              variant='contained'
              size='medium'
              color='primary'
              onClick={toggleCookie}
            >
              Cookie Settings
            </Button>
          </Typography>
          <Typography variant='body1'>
            You can change your cookie preferences any time by clicking the
            above button. This will let you revisit the cookie consent banner
            and change your preferences or withdraw your consent right away. In
            addition to this, different browsers provide different methods to
            block and delete cookies used by websites.
          </Typography>
          <Typography variant='body1'>
            You can change the settings of your browser to block/delete the
            cookies. Listed below are the links to the support documents on how
            to manage and delete cookies from the major web browsers.
          </Typography>
          <List>
            <ListItem sx={{ wordBreak: "break-all" }}>
              <Typography variant='body1'>
                Chrome:{" "}
                <Link
                  color='primary'
                  underline='none'
                  noWrap
                  variant='body1'
                  href='https://support.google.com/accounts/answer/32050'
                  sx={{ px: 1, whiteSpace: "initial" }}
                >
                  https://support.google.com/accounts/answer/32050
                </Link>
              </Typography>
            </ListItem>
            <ListItem sx={{ wordBreak: "break-all" }}>
              <Typography variant='body1'>
                Safari:{" "}
                <Link
                  color='primary'
                  underline='none'
                  noWrap
                  variant='body1'
                  href='https://support.apple.com/en-in/guide/safari/sfri11471/mac'
                  sx={{ px: 1, whiteSpace: "initial" }}
                >
                  https://support.apple.com/en-in/guide/safari/sfri11471/mac
                </Link>
              </Typography>
            </ListItem>
            <ListItem sx={{ wordBreak: "break-all" }}>
              <Typography variant='body1'>
                Firefox:{" "}
                <Link
                  color='primary'
                  underline='none'
                  noWrap
                  variant='body1'
                  href='https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US'
                  sx={{ px: 1, whiteSpace: "initial" }}
                >
                  https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US
                </Link>
              </Typography>
            </ListItem>
            <ListItem sx={{ wordBreak: "break-all" }}>
              <Typography variant='body1'>
                Internet Explorer:
                <Link
                  color='primary'
                  underline='none'
                  noWrap
                  variant='body1'
                  href='https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc'
                  sx={{ px: 1, whiteSpace: "initial" }}
                >
                  https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc
                </Link>
              </Typography>
            </ListItem>
          </List>
          <ViewCookies open={isShowingCookie} hide={toggleCookie} />
        </ContentStyle>
      </Container>
    </Page>
  )
}
