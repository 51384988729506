import { auth_request } from "../middlewares/auth_service"
import * as ERRORS from "../constants/errorManagement"
import * as ActionTypes from "../constants/signup"

export const signUp = (data) => {
  const endpoint = "/api/auth"
  const method = "POST"
  sessionStorage.clear()
  const headers = {
    accept: "application/json",
    "Content-Type": undefined,
    page: "signUp",
  }
  const options = { action: endpoint, method: method, headers: headers }
  options.body = data
  options.types = [
    ActionTypes.SIGNUP_ON_SUCCESS,
    ActionTypes.SIGNUP_ON_FAILURE,
    ERRORS.API_FAILURE,
    ActionTypes.RESET_FLAGS,
  ]

  return auth_request(options)
}

export const resetAuthData = () => {
  return { type: ActionTypes.RESET_FLAGS }
}
