import React, { useState, useEffect } from "react"
import { Card, Box, Tab, Stack, Container, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { TabList, TabContext, TabPanel } from "@mui/lab"
import Page from "../components/Page"
import {
  EmailTemaplate,
  Permissions,
  Subscription,
  CompanyProfile,
  Charges,
  Smtp,
  General,
} from "../sections/@dashboard/settings"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import Snackbar from "@mui/material/Snackbar"
import { useLocation } from "react-router-dom"
import Alert from "src/components/Alert"

// ----------------------------------------------------------------------
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))

export default function Settings() {
  const loggedUser = useSelector((state) => state.login)
  const userPermissions = loggedUser?.user?.profile?.permission

  const [valueOfMainTab, setvalueOfMainTab] = useState("1")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [displayCrash, setDisplayCrash] = useState("")
  const [errorSeverity, setSeverity] = useState("")

  const handleChange = (event, newValue) => {
    setvalueOfMainTab(newValue)
    handleCloseSnackbar()
  }
  const { t } = useTranslation()
  const { state } = useLocation()

  const handleCloseSnackbar = (_event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnackbar(false)
    setDisplayCrash("")
    setSeverity("")
  }

  useEffect(() => {
    handleCloseSnackbar()
    return () => {
      handleCloseSnackbar()
    }
  }, [])

  useEffect(() => {
    if (state?.smtp) setvalueOfMainTab("6")
  }, [state])

  return (
    <Page title={t("Settings")}>
      <Container maxWidth='xl' spacing={3}>
        {userPermissions?.settings_module ? (
          <>
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={errorSeverity}
                sx={{ width: "100%" }}
              >
                {displayCrash}
              </Alert>
            </Snackbar>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              mb={5}
            >
              <Typography
                variant='h5'
                sx={{
                  mb: 0,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  color: "#434e58",
                }}
              >
                {t("Settings")}
              </Typography>
            </Stack>

            <Card>
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={valueOfMainTab}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange}
                      variant='scrollable'
                      scrollButtons='auto'
                      allowScrollButtonsMobile
                    >
                      <Tab label={t("CompanyProfile")} value='1' />
                      {userPermissions?.settings_user_can_view_email && (
                        <Tab label={t("EmailTemplatesTab")} value='2' />
                      )}
                      {userPermissions?.settings_user_can_view_permissions && (
                        <Tab label={t("Permissions")} value='3' />
                      )}
                      {userPermissions?.settings_user_can_view_subscribe && (
                        <Tab label={t("Subscription")} value='4' />
                      )}
                      {userPermissions?.settings_user_can_view_charges && (
                        <Tab label={t("Charges")} value='5' />
                      )}
                      {loggedUser?.user?.user_type === "admin" && (
                        <Tab label={t("SMTPconfig")} value='6' />
                      )}
                      <Tab label={t("General")} value='7' />
                    </TabList>
                  </Box>
                  <TabPanel value='1'>
                    <CompanyProfile
                      setSeverity={setSeverity}
                      setDisplayCrash={setDisplayCrash}
                      setOpenSnackbar={setOpenSnackbar}
                    />
                  </TabPanel>
                  {userPermissions?.settings_user_can_view_email && (
                    <TabPanel value='2'>
                      <EmailTemaplate
                        setSeverity={setSeverity}
                        setDisplayCrash={setDisplayCrash}
                        setOpenSnackbar={setOpenSnackbar}
                      />
                    </TabPanel>
                  )}

                  {userPermissions?.settings_user_can_view_permissions && (
                    <TabPanel value='3'>
                      <Permissions />
                    </TabPanel>
                  )}
                  {userPermissions?.settings_user_can_view_subscribe && (
                    <TabPanel value='4'>
                      <Subscription />
                    </TabPanel>
                  )}
                  {userPermissions?.settings_user_can_view_charges && (
                    <TabPanel value='5' sx={{ p: 0 }}>
                      <Charges t={t} />
                    </TabPanel>
                  )}
                  {loggedUser?.user?.user_type === "admin" && (
                    <TabPanel value='6'>
                      <Smtp
                        setSeverity={setSeverity}
                        setDisplayCrash={setDisplayCrash}
                        setOpenSnackbar={setOpenSnackbar}
                      />
                    </TabPanel>
                  )}
                  <TabPanel value='7'>
                    <General
                      setSeverity={setSeverity}
                      setDisplayCrash={setDisplayCrash}
                      setOpenSnackbar={setOpenSnackbar}
                    />
                  </TabPanel>
                </TabContext>
              </Box>
            </Card>
          </>
        ) : (
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("NoAccessPAge")}
            </Typography>
            <Box
              component='img'
              src='/static/illustrations/illustration_404.svg'
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </ContentStyle>
        )}
      </Container>
    </Page>
  )
}
