import _, { isEmpty } from "lodash"
import { styled } from "@mui/material/styles"
import React, { useState, useEffect } from "react"
import { useFormik, Form, FormikProvider } from "formik"
import * as Yup from "yup"
import {
  Toolbar,
  Stack,
  IconButton,
  Typography,
  ListItemAvatar,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Card,
  Box,
  FormControl,
  TextField,
  Popover,
  Rating,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import FlagIcon from "@mui/icons-material/Flag"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import {
  addInterviewComment,
  deleteComment,
  editInterviewComment,
  flagInterviewQuestion,
  getInterviewFeedback,
  viewInterviewQuestion,
  resetInterviewsFlag,
  getRecommendations,
} from "src/redux/actions/interviews"
import Label from "src/components/Label"
import { secondsToTime } from "src/utils/formatTime"
import UpdateFeedbackModal from "./modal/updateFeedbackModal/updateFeedbackModal"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { awaitingFeedback, checkUser } from "src/redux/actions/container"
import moment from "moment"
import { useAuth } from "src/hooks/useAuth"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import Snackbar from "@mui/material/Snackbar"
import Alert from "src/components/Alert"
import IntReviewModal from "./modal/intReview/IntReviewModal"
import interviewsArr from "src/_mock/interviewArray"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
import CancelIcon from "@mui/icons-material/Cancel"
import SendIcon from "@mui/icons-material/Send"
import PersonIcon from "@mui/icons-material/Person"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import VideocamOffOutlinedIcon from "@mui/icons-material/VideocamOffOutlined"
import SampleFeedback from "./sample/sampleFeedback"
import { currentBrowserName } from "src/utils/userHelper"
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  padding: theme.spacing(0, 1, 0, 3),
}))

// ----------------------------------------------------------------------

export default function IntReviewFeedback() {
  const { user } = useAuth()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const { intId } = useParams()
  const navigate = useNavigate()
  const { state } = useLocation()

  const userFeedback = useSelector((state) => state?.getIntFeedback?.data)
  const userComments = useSelector(
    (state) => state?.getIntFeedback?.user_comments,
  )
  const flagQuestions = useSelector(
    (state) => state?.getIntFeedback?.flag_question,
  )
  const userInfo = userFeedback?.interview
  const flaggedData = useSelector((state) => state.flagInt)
  const addIntComment = useSelector((state) => state.addIntComment)
  const editIntComment = useSelector((state) => state.editIntComment)
  const deleteIntQuestion = useSelector((state) => state.deleteIntQuestion)
  const recommendations = userInfo?.recommendations
  const isVacancyOpen =
    userInfo?.candidate_vacancy?.vacancy?.status === "active" || state?.isSample

  const updateIntFeedback = useSelector((state) => state?.updateIntFeedback)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [displayCrash, setDisplayCrash] = useState("")
  const [errorSeverity, setSeverity] = useState("")

  let questions = userInfo?.interview_questions
    ? userInfo?.interview_questions
    : []
  if (questions.length > 0) {
    questions = _.sortBy(questions, "id", "asc")
  }

  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission

  const [isShowingUpdateFeedback, toggleUpdateFeedback] = useModal()
  const [isShowingInfo, toggleShowingInfo] = useModal()
  const [isShowingDummyInfo, toggleShowingDummyInfo] = useModal()
  const [userItem, setItem] = useState([])
  const [playing, setPlaying] = useState(false)
  const [selectedQuestionNo, setSelectedQuestionNo] = useState(0)
  const [isRecommendedByCurrentUser, setIsRecommendedByCurrentUser] =
    useState(false)
  const [isInterviewer, setIsInterviewer] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [recommendation, setRecommendation] = useState("")
  const [actionUpdated, setActionUpdated] = useState("")
  const [comments, setComments] = useState([])
  const [flagQuestion, setflagQuestion] = useState([])
  const [currentUserRating, setCurrentUserRating] = useState({})

  useEffect(() => setIsAdmin(logged?.user?.user_type === "admin"), [logged])

  useEffect(() => {
    if (recommendations && recommendations.length > 0) {
      const rec = recommendations.find(
        (recommendation) => recommendation.user_id === logged?.user?.id,
      )
      setIsRecommendedByCurrentUser(!isEmpty(rec))
      setCurrentUserRating(rec)
    } else {
      setIsRecommendedByCurrentUser(false)
    }

    setIsInterviewer(
      userInfo?.interviewers?.filter(
        (interviewer) => interviewer.user_id === logged?.user?.id,
      ).length !== 0,
    )
  }, [userFeedback])

  useEffect(() => {
    setComments(userComments)
  }, [userComments])

  useEffect(() => {
    setflagQuestion(flagQuestions)
  }, [flagQuestions])

  useEffect(() => {
    if (state?.isSample !== true)
      dispatch(getInterviewFeedback(intId, actionUpdated))
  }, [flaggedData, addIntComment, editIntComment, deleteIntQuestion])

  useEffect(() => {
    const data = questions[selectedQuestionNo]?.id
    handleQuestion(data, selectedQuestionNo)
  }, [userInfo])

  useEffect(() => {
    if (updateIntFeedback?.isSucceed) {
      setOpenSnackbar(true)
      setSeverity("success")
      setDisplayCrash(updateIntFeedback.data?.message)
      dispatch(resetInterviewsFlag())
      dispatch(getInterviewFeedback(intId))
      dispatch(getRecommendations(intId))
    }
  }, [updateIntFeedback])

  const handleGiveRecommendation = () => {
    setRecommendation(
      recommendations?.find(
        (recommendation) => recommendation.user_id === logged?.user?.id,
      ),
    )
    toggleUpdateFeedback()
  }
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const handleQuestion = (id, index) => {
    // setCommentArr(userItem[0]?.feed)
    // ---question views api---
    const newFormData = new FormData()
    newFormData.append("interview_question", userItem[0]?.intQuestionId)
    if (userItem[0]?.intQuestionId !== undefined) {
      dispatch(viewInterviewQuestion(newFormData))
    }
    // setQuestionId(id);
    let obj = {}
    let arrList = []
    {
      userInfo?.interviewers?.map((inter) => {
        {
          obj.interviewer = inter?.user?.first_name + inter?.user?.last_name
        }
      })

      {
        questions?.map((item, index) => {
          if (item?.id === id) {
            return (
              <>
                {(obj.video = item?.answer_video?.url)}
                {(obj.duration = item?.duration)}
                {(obj.intQuestionId = item?.id)}
                {(obj.title = item?.question?.text)}
                {(obj.choices = item?.question?.choices)}
                {(obj.question_type = item?.question?.question_type)}
                {(obj.candidate_answer = item?.candidate_answer)}
                {(obj.correct_answer = item?.question?.correct_answer)}
                {(obj.index = index + 1)}
                {(obj.id = item?.id)}
                {(obj.transcode_video = item?.transcode_video)}
                {(obj.browser_name = item?.browser_name)}
                {arrList?.push(obj)}
                {(obj = {})}
              </>
            )
          }
        })
      }
    }

    if (arrList?.length !== 0) {
      setItem(arrList)
    }
    setSelectedQuestionNo(index)
  }

  const getCommentsCount = (comments) => {
    return comments?.filter(
      (c) =>
        c.user_id === logged?.user?.id || logged?.user?.user_type === "admin",
    )?.length
  }

  const commentSchema = Yup.object().shape({
    text: Yup.string().required(t("ErrFillField")),
  })

  const formik = useFormik({
    initialValues: {
      interview_id: "",
      question_id: "",
      text: "",
    },
    validationSchema: commentSchema,
    onSubmit: (values, { resetForm }) => {
      const newFormData = new FormData()
      newFormData.append("interview_question_id", userItem[0]?.intQuestionId)
      newFormData.append("text", values.text)
      // let array = userItem[0]?.feed;
      // array.splice(1, 0, values);
      setActionUpdated("CommentAdded")
      dispatch(addInterviewComment(newFormData))
      resetForm({ newFormData })
    },
  })

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
  } = formik

  // ----edit api----
  const [save, setSave] = useState(false)
  const [edit, setEdit] = useState(true)
  const [commentKeyToEdit, setCommentKeyToEdit] = useState(null)
  const [commentArr, setCommentArr] = useState(userItem[0]?.feed)
  const [userComment, setUserComment] = useState({
    text: "",
  })

  const handleEdit = (key, text) => {
    // let userKey
    // for (let i = 0; i < userItem[0]?.feed?.length; i++) {
    //   if (userItem[0]?.feed[i]?.key === key) {
    //     userKey = i

    //     setCommentKeyToEdit(key)
    //     break
    //   }
    // }
    // let ediArr = userItem[0]?.feed[userKey]
    setCommentKeyToEdit(key)
    setUserComment({
      text: text,
    })
    setSave(true)
    setEdit(false)
  }

  const handleCommentValue = (e) => {
    setUserComment({ ...userComment, text: e.target.value })
  }

  const editSaveHandle = (id) => {
    let userKey
    for (let i = 0; i < userItem[0]?.feed?.length; i++) {
      if (userItem[0]?.feed[i]?.key === id) {
        userKey = i
        break
      }
    }

    //  ---------edit api----
    const newFormData = new FormData()
    newFormData.append("interview_question_id", userItem[0]?.intQuestionId)

    newFormData.append("text", userComment?.text)
    dispatch(editInterviewComment(id, newFormData))

    setActionUpdated("CommentUpdated")
    setEdit(true)
    setSave(false)
    setCommentKeyToEdit(null)
  }

  // ----delete api-
  const handleDelete = (key) => {
    setActionUpdated("CommentDeleted")
    dispatch(deleteComment(key))
  }

  const handleFlag = (id) => {
    const newFormData = new FormData()
    // newFormData.append("interview_question", userItem[0]?.intQuestionId);
    newFormData.append("interview_question", id)
    dispatch(flagInterviewQuestion(newFormData))
    setActionUpdated("QuestionFlagged")
  }

  const [active, setActive] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleBack = () => {
    navigate("/dashboard/interviews", { replace: true })
    dispatch(checkUser("Confirm"))
    dispatch(awaitingFeedback("remAwait"))
  }
  const openPopover = Boolean(anchorEl)
  const id = openPopover ? "simple-popover" : undefined

  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    // Filter the data where id is equal to 899
    const filtered = interviewsArr?.filter((item) => item.id === 899)
    setFilteredData(filtered)
  }, [])

  return (
    <RootStyle
      sx={{
        color: "primary.main",
      }}
    >
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={errorSeverity}
          sx={{
            width: "100%",
            marginTop: "5vh",
          }}
        >
          {displayCrash}
        </Alert>
      </Snackbar>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            paddingLeft: "10px !important",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button
            variant='contained'
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
          >
            Back
          </Button>
          {isVacancyOpen === true ? null : (
            <Typography
              variant='body2'
              sx={{
                color: "text.primary",
                wordBreak: "break-word",
                fontWeight: 600,
                mx: 2,
              }}
            >
              -- vacancy is closed
            </Typography>
          )}
        </Grid>

        {state?.isSample === true ? (
          filteredData?.map((int) => {
            return <SampleFeedback int={int} />
          })
        ) : (
          <Card sx={{ my: 3, pl: 1, ml: 1, width: "100%" }}>
            <Stack
              direction={{ sm: "column", md: "row" }}
              justifyContent='space-between'
              sx={{ margin: 2, borderBottom: "1px solid #E5E7EB", pb: 1 }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} sm={7}>
                  <Stack
                    justifyContent='flex-start'
                    direction={{ xs: "column", sm: "row" }}
                  >
                    <Box sx={{ pr: 3 }}>
                      <Typography
                        variant='subtitle1'
                        sx={{
                          color: "text.primary",
                          textTransform: "capitalize",
                          mb: "4px",
                          fontSize: "16px",
                          fontWeight: 600,
                          minWidth: "250px",
                          wordBreak: "break-word",
                        }}
                      >
                        {(userInfo?.candidate_vacancy?.candidate?.first_name ??
                          "") +
                          " " +
                          (userInfo?.candidate_vacancy?.candidate?.last_name ??
                            "")}
                      </Typography>
                      <Typography
                        variant='body2'
                        sx={{
                          fontWeight: 500,
                          mr: 1,
                          color: "#66696E",
                          fontSize: "12px",
                          minWidth: "250px",
                          wordBreak: "break-word",
                          textTransform: "capitalize",
                        }}
                      >
                        {userInfo?.candidate_vacancy?.vacancy?.title ?? ""}
                      </Typography>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          color: "text.primary",
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          mb: 1,
                        }}
                      >
                        <PersonIcon
                          sx={{ mr: 1, fontSize: "18px", color: "#66696E" }}
                        />{" "}
                        <Box className='multi'>
                          {userInfo?.interviewers?.map((userInterviewer) => {
                            return (
                              <Typography
                                variant='subtitle2'
                                component='span'
                                sx={{
                                  my: "4px",
                                  whiteSpace: "break-spaces",
                                  lineHeight: "1.5em",
                                  fontWeight: 500,
                                  wordBreak: "break-word",
                                  textTransform: "capitalize",
                                }}
                              >
                                {(userInterviewer?.user?.first_name ?? "") +
                                  " " +
                                  (userInterviewer?.user?.last_name ?? "")}
                              </Typography>
                            )
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Stack
                    justifyContent='space-between'
                    direction={{ xs: "column", sm: "row" }}
                  >
                    <Box>
                      {isRecommendedByCurrentUser !== true ? (
                        <Label variant='ghost' sx={{ mx: 1 }}>
                          Not provided
                        </Label>
                      ) : (
                        <>
                          {currentUserRating?.is_recommended === true ? (
                            <Label
                              variant='ghost'
                              color='success'
                              sx={{ mx: 1 }}
                            >
                              Recommended
                            </Label>
                          ) : (
                            <Label variant='ghost' sx={{ mx: 1 }}>
                              Not Recommended
                            </Label>
                          )}
                        </>
                      )}

                      <Stack
                        direction='row'
                        justifyContent='flex-end'
                        sx={{ my: 1 }}
                      >
                        <Rating
                          sx={{ mx: 1 }}
                          name='read-only'
                          value={
                            isRecommendedByCurrentUser
                              ? currentUserRating?.rating ?? 0
                              : 0
                          }
                          // value={
                          //   recommendations.find(
                          //     (recommendation) =>
                          //       recommendation.user_id === logged?.user?.id &&
                          //       recommendation.rating !== undefined,
                          //   )?.rating || 0
                          // }
                          readOnly
                        />
                      </Stack>
                    </Box>
                    <Button
                      variant='outlined'
                      color='primary'
                      sx={{ my: 1, mx: 1, maxWidth: "150px" }}
                      onClick={() => toggleShowingInfo()}
                    >
                      {t("Candidate Info")}
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>

            <Grid container spacing={3}>
              {userItem?.map((user) => {
                return (
                  <Grid key={user?.id} item xs={12} md={6} lg={6} xl={7}>
                    <Box className='leftPane'>
                      <Stack direction='row' justifyContent='space-between'>
                        <Typography
                          variant='h5'
                          sx={{
                            fontWeight: 600,
                            mb: 1,
                            width: "88%",
                            display: "block",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Q {user?.index}: {user?.title}
                        </Typography>
                      </Stack>

                      {user.duration === null ? (
                        <>
                          {userInfo?.onsite_recording !== true &&
                          userInfo?.interview_format === "on_site" ? (
                            <Box
                              className='videoPlayer'
                              sx={{
                                height: "350px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                color: "#fff",
                              }}
                            >
                              <VideocamOffOutlinedIcon
                                sx={{ fontSize: "200px" }}
                              />
                              <Typography variant='h3' sx={{ fontWeight: 500 }}>
                                This onsite interview was not recorded
                              </Typography>
                            </Box>
                          ) : (
                            <video
                              className='videoPlayer'
                              width='100%'
                              height='350'
                              autoPlay={false}
                              controls={!isEmpty(user?.video)}
                              poster='/static/videoph.png'
                              key={user?.video}
                              src={user?.video}
                              onPlay={() => setPlaying(true)}
                              onPause={() => setPlaying(false)}
                              playsInline
                              muted={true}
                              loop
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {user?.transcode_video !== true &&
                          user.browser_name !== currentBrowserName() ? (
                            <Box
                              className='videoPlayer'
                              sx={{
                                height: "350px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                color: "#fff",
                                textAlign: "center",
                                padding: "1rem",
                              }}
                            >
                              <VideocamOffOutlinedIcon
                                sx={{ fontSize: "9rem", mt: 1, mb: 1 }}
                              />
                              <Typography
                                variant='h3'
                                sx={{
                                  fontWeight: 500,
                                  padding: "1rem",
                                  fontSize: "1.4rem",
                                  textAlign: "center",
                                  mb: 1,
                                }}
                              >
                                {t("Data streaming is in process")}
                              </Typography>
                            </Box>
                          ) : (
                            <video
                              className='videoPlayer'
                              width='100%'
                              height='350'
                              autoPlay={true}
                              controls={
                                !isEmpty(user?.video) && user.duration !== "0"
                              }
                              poster='/static/videoph.png'
                              key={user?.video}
                              onPlay={() => setPlaying(true)}
                              onPause={() => setPlaying(false)}
                              playsInline
                              muted={true}
                              loop
                            >
                              <source src={user?.video} />
                            </video>
                          )}
                        </>
                      )}
                      {user?.question_type === "Multiple Choice" && (
                        <Box>
                          <Popover
                            id={id}
                            open={openPopover}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            style={{ width: "300px !important" }}
                          >
                            <Box
                              sx={{
                                width: "300px",
                                maxHeight: "300px",
                                overflow: "auto",
                                p: 2,
                              }}
                            >
                              {user?.choices?.map((str, index) => (
                                <Typography
                                  key={str + "-ch"}
                                  variant='body1'
                                  sx={{ wordWrap: "break-word", mb: 1 }}
                                  className={`radio-selectbox ${
                                    user?.correct_answer === str ? "active" : ""
                                  }`}
                                >
                                  {index + 1}: {str}
                                  {user?.correct_answer === str && (
                                    <CheckCircleRoundedIcon
                                      sx={{
                                        width: 16,
                                        height: 16,
                                        ml: 1,
                                        mb: "-3px",
                                      }}
                                    />
                                  )}
                                </Typography>
                              ))}
                            </Box>
                          </Popover>
                        </Box>
                      )}

                      {["Multiple Choice", "Problem Solving"].includes(
                        user?.question_type,
                      ) && (
                        <Stack>
                          <Box>
                            <Stack
                              justifyContent='space-between'
                              alignContent='center'
                              direction={{
                                xs: "column",
                                sm: "row",
                              }}
                            >
                              <Typography
                                variant='body1'
                                component='p'
                                sx={{
                                  pt: 1,
                                  fontWeight: 600,
                                  minHeight: "33px",
                                  minWidth: "120px",
                                  mr: 1,
                                }}
                              >
                                Question Type:{" "}
                                <Typography variant='body2' component='span'>
                                  {" "}
                                  {user?.question_type}
                                </Typography>
                              </Typography>
                              {user?.question_type === "Multiple Choice" && (
                                <Button
                                  aria-describedby={id}
                                  onClick={handleClick}
                                >
                                  Show Options
                                </Button>
                              )}
                            </Stack>

                            {[null, "null", "", undefined].includes(
                              user?.candidate_answer,
                            ) ? (
                              <Typography
                                sx={{
                                  my: 1,
                                  borderLeft: "2px solid #637481",
                                  pl: 1,
                                }}
                              >
                                No written answer was provided
                              </Typography>
                            ) : (
                              <Stack
                                direction='column'
                                sx={{
                                  wordBreak: "break-word",
                                  my: 1,
                                  borderLeft: "2px solid #637481",
                                  pl: 1,
                                }}
                              >
                                <Typography
                                  variant='body1'
                                  component='p'
                                  sx={{
                                    wordBreak: "break-word",
                                    fontWeight: 600,
                                    minWidth: "120px",
                                    mr: 1,
                                  }}
                                >
                                  {t("CandAns")}
                                </Typography>

                                <Typography
                                  variant='body1'
                                  component='p'
                                  sx={{ wordBreak: "break-word" }}
                                >
                                  {user?.candidate_answer}
                                  {user?.question_type ===
                                    "Multiple Choice" && (
                                    <Label
                                      sx={{ mx: 1 }}
                                      variant='ghost'
                                      color={
                                        (user?.candidate_answer !==
                                          user?.correct_answer &&
                                          "error") ||
                                        "success"
                                      }
                                    >
                                      {user?.candidate_answer ===
                                      user?.correct_answer
                                        ? "Correct"
                                        : "Incorrect"}
                                    </Label>
                                  )}
                                </Typography>
                              </Stack>
                            )}
                          </Box>
                        </Stack>
                      )}

                      <Box sx={{ mb: 1 }}>
                        <Stack
                          direction='row'
                          justifyContent='space-between'
                          alignItems='center'
                          sx={{ my: 1 }}
                        >
                          <Typography component='h5' variant='h5'>
                            {getCommentsCount(comments[user?.id]) === 0
                              ? "No feedback"
                              : "Feedback"}{" "}
                            on this question
                          </Typography>
                          <Label
                            variant='ghost'
                            sx={{ marginLeft: "4px", mb: 1, mt: 1 }}
                            color='warning'
                          >
                            {getCommentsCount(comments[user?.id])}{" "}
                            {getCommentsCount(comments[user?.id]) > 1
                              ? t("Comments")
                              : t("Comment")}
                          </Label>
                        </Stack>

                        {userPermissions?.candidates_user_can_add_comments_to_interview &&
                          isVacancyOpen && (
                            <FormikProvider value={formik}>
                              <Form
                                autoComplete='off'
                                noValidate
                                onSubmit={handleSubmit}
                              >
                                <FormControl
                                  sx={{
                                    width: "100%",
                                    mt: 0,
                                    mb: 1,
                                    flexDirection: "row",
                                  }}
                                  className='comment-area'
                                >
                                  <TextField
                                    label={t("AddComment")}
                                    fullWidth
                                    type='text'
                                    variant='outlined'
                                    name='text'
                                    value={values.text}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    size='small'
                                  />

                                  <Button
                                    type='submit'
                                    variant='contained'
                                    color='secondary'
                                    size='large'
                                    sx={{
                                      maxHeight: 38,
                                      width: "60px",
                                      fontWeight: 500,
                                      justifyContent: "center",
                                      pl: 1,
                                      pr: 1,
                                      borderBottomLeftRadius: "0px",
                                      borderTopLeftRadius: " 0px",

                                      fontSize: "30px",
                                    }}
                                    disabled={values.text.trim() === ""}
                                  >
                                    <SendIcon />
                                  </Button>
                                </FormControl>
                              </Form>
                            </FormikProvider>
                          )}
                      </Box>

                      {comments[userItem[0]?.id]?.length > 0 && (
                        <List
                          className='feed-list'
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            bgcolor: "background.paper",
                          }}
                        >
                          {comments[userItem[0]?.id]
                            ?.filter(
                              (c) =>
                                c.user_id === logged?.user?.id ||
                                logged?.user?.user_type === "admin",
                            )
                            .map((text) => {
                              return (
                                <ListItem
                                  key={text?.id}
                                  alignItems='flex-start'
                                  sx={{ px: 0 }}
                                >
                                  <ListItemAvatar>
                                    <Avatar
                                      sx={{
                                        fontSize: 12,
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      {text?.user_name?.slice(0, 1)}
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    primary={
                                      <React.Fragment>
                                        <Typography
                                          variant='subtitle1'
                                          sx={{
                                            display: "inline-flex",
                                            color: "text.primary",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {text?.user_name}
                                        </Typography>

                                        <Typography
                                          sx={{
                                            display: "inline-block",
                                            mx: 1,
                                          }}
                                          component='span'
                                          variant='body2'
                                          color='text.primary'
                                        >
                                          {moment
                                            .unix(text?.time)
                                            .format("DD/MM/YYYY")}
                                          {" - "}
                                          {moment
                                            .unix(text?.time)
                                            .format("h:mm:ss A")}
                                        </Typography>
                                      </React.Fragment>
                                    }
                                    secondary={
                                      <React.Fragment>
                                        {commentKeyToEdit !== text?.key &&
                                          text?.text}

                                        {commentKeyToEdit === text?.key && (
                                          <TextField
                                            id='standard-basic'
                                            //label={userComment?.text}
                                            variant='standard'
                                            value={userComment?.text}
                                            onChange={(e) => {
                                              handleCommentValue(e)
                                            }}
                                          />
                                        )}
                                      </React.Fragment>
                                    }
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      minWidth: "60px",
                                    }}
                                  >
                                    {userPermissions?.candidates_user_can_edit_comments &&
                                      isVacancyOpen &&
                                      text.user_id === logged?.user?.id && (
                                        <>
                                          {commentKeyToEdit !== text?.key && (
                                            <EditIcon
                                              sx={{
                                                mt: "8px",
                                                ml: 1,
                                                color: "#637381",
                                                cursor: "pointer",
                                                fontSize: "20px",
                                              }}
                                              width={24}
                                              onClick={() =>
                                                handleEdit(text?.key, text.text)
                                              }
                                            />
                                          )}

                                          {commentKeyToEdit === text?.key && (
                                            <SaveOutlinedIcon
                                              sx={{
                                                mt: "8px",
                                                ml: 1,
                                                color: "#637381",
                                                cursor: "pointer",
                                                fontSize: "18px",
                                              }}
                                              onClick={() =>
                                                editSaveHandle(text?.key)
                                              }
                                              disabled={
                                                userComment?.text?.trim() === ""
                                              }
                                            />
                                          )}
                                        </>
                                      )}

                                    {userPermissions?.candidates_user_can_delete_comments &&
                                      isVacancyOpen &&
                                      text.user_id === logged?.user?.id && (
                                        <DeleteOutlineIcon
                                          width={20}
                                          height={20}
                                          sx={{
                                            mt: "8px",
                                            ml: 1,
                                            color: "#637381",
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleDelete(text?.key)
                                          }
                                        />
                                      )}

                                    {commentKeyToEdit === text?.key && (
                                      <CancelIcon
                                        sx={{
                                          mt: "8px",
                                          ml: 1,
                                          cursor: "pointer",
                                          lineHeight: "1.0em",
                                          fontSize: "18px",
                                          opacity: 0.6,
                                          "&:hover": { opacity: 1 },
                                        }}
                                        height={20}
                                        onClick={() =>
                                          setCommentKeyToEdit(null)
                                        }
                                      />
                                    )}
                                  </Box>
                                </ListItem>
                              )
                            })}
                        </List>
                      )}
                    </Box>
                  </Grid>
                )
              })}

              <Grid item xs={12} md={6} lg={6} xl={5} sx={{ pt: 6, pb: 5 }}>
                <Box
                  sx={{
                    mr: 2,
                    ml: { xs: 2, sm: 2, md: 0 },
                  }}
                >
                  <Stack
                    direction={{ sm: "column", md: "row" }}
                    alignItems='center'
                    justifyContent={{ sm: "flex-start", md: "space-between" }}
                    sx={{
                      borderBottom: "1px solid #E5E7EB",
                      p: 1,
                    }}
                  >
                    <Typography variant='h5' component='h5'>
                      Interview Questions
                    </Typography>
                    {userPermissions.candidates_user_can_give_recommendations &&
                      (isInterviewer ||
                        logged?.user?.user_type === "admin") && (
                        <Button
                          disabled={!isVacancyOpen}
                          onClick={handleGiveRecommendation}
                          variant='outlined'
                          sx={{
                            mb: 2,
                          }}
                          size='medium'
                        >
                          {isRecommendedByCurrentUser
                            ? t("EditRecommendation")
                            : t("GiveRecommendation")}
                        </Button>
                      )}
                  </Stack>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent='space-between'
                    alignItems={{
                      xs: "flex-start",
                      sm: "center",
                    }}
                    sx={{ my: 1, px: 2, mt: 2 }}
                  >
                    <Typography
                      variant='h6'
                      component='div'
                      sx={{ fontWeight: 500, fontSize: 12 }}
                      color='text.secondary'
                    >
                      {t("TotalQuestions")}: {questions?.length}
                    </Typography>
                    <Typography
                      variant='h6'
                      color='text.secondary'
                      sx={{
                        fontWeight: 500,
                        fontSize: 12,
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                    >
                      <AccessTimeIcon sx={{ mr: 1 }} /> {t("Duration")}:&nbsp;
                      {
                        userInfo?.candidate_vacancy?.vacancy?.interview_time
                      }{" "}
                      {t("minutes")}
                    </Typography>
                  </Stack>

                  <Box
                    sx={{
                      maxWidth: "800px",
                      maxHeight: 600,
                      overflow: "auto",
                    }}
                  >
                    <List
                      className='clips-list'
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                      }}
                    >
                      {questions?.map((item, index) => {
                        return (
                          <ListItem
                            key={item?.id}
                            className={`clips-list-item ${
                              active === index && "active-list"
                            }`}
                            sx={{
                              alignItems: "flex-start",
                              mb: "1rem !important",
                              borderRadius: "8px",
                              paddingRight: "50px",
                              background:
                                flagQuestion[item?.id] !== true
                                  ? "white"
                                  : "#FEF2F2",
                              color:
                                flagQuestion[item?.id] !== true
                                  ? "#637381"
                                  : "#b91c1cde",
                            }}
                            onClick={() => {
                              handleQuestion(item?.id, index)
                              setActive(index)
                            }}
                          >
                            <Box sx={{ width: "100%" }}>
                              <Stack direction='row'>
                                <ListItemAvatar>
                                  <Avatar
                                    sx={{
                                      borderRadius: "4px",
                                      border: "1px solid #D3D3D3",
                                      width: {
                                        xs: "44px",
                                        sm: "60px",
                                        md: "84px",
                                      },
                                      height: {
                                        xs: "auto",
                                        sm: "auto",
                                        md: "54px",
                                      },
                                      mr: 2,
                                    }}
                                  >
                                    <video
                                      playsInline
                                      muted={true}
                                      autoPlay={false}
                                      loop
                                      style={{
                                        objectFit: "cover",
                                        width: "84px",
                                        height: "54px",
                                      }}
                                      width='100%'
                                      poster='/static/videoph.png'
                                    >
                                      <source src={item?.answer_video?.url} />
                                    </video>
                                  </Avatar>
                                </ListItemAvatar>

                                <ListItemText
                                  sx={{ color: "#000", fontWeight: 600 }}
                                >
                                  <Typography sx={{ fontWeight: 600 }}>
                                    Q {index + 1}: {item?.question?.text}
                                  </Typography>

                                  <Typography
                                    variant='subtitle2'
                                    color='text.secondary'
                                    className='comment-row'
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      fontSize: "12px",
                                      mt: "4px",
                                      color:
                                        flagQuestion[item?.id] !== true
                                          ? "#82888E"
                                          : "#b91c1cde",
                                    }}
                                  >
                                    {secondsToTime(item?.duration)}
                                    <span>.</span>
                                    {getCommentsCount(comments[item?.id])}{" "}
                                    {getCommentsCount(comments[item?.id]) > 1
                                      ? t("Comments")
                                      : t("Comment")}
                                    <span>&nbsp;.&nbsp;</span>
                                    {item?.question_views?.length}{" "}
                                    {item?.question_views?.length > 1
                                      ? t("Views")
                                      : t("View")}
                                  </Typography>
                                </ListItemText>
                              </Stack>
                            </Box>

                            <IconButton
                              edge='end'
                              aria-label='Flag'
                              className='flag-icon'
                              sx={{
                                position: "absolute",
                                right: "20px",
                                top: "13px",
                              }}
                              disabled={!isVacancyOpen}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleFlag(item?.id)
                              }}
                            >
                              <FlagIcon
                                sx={{
                                  color:
                                    flagQuestion[item?.id] !== true
                                      ? "#637381"
                                      : "#b91c1cde !important",
                                }}
                              />
                            </IconButton>
                          </ListItem>
                        )
                      })}
                    </List>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Card>
        )}
      </Grid>
      <UpdateFeedbackModal
        isShowingUpdateFeedback={isShowingUpdateFeedback}
        hide={toggleUpdateFeedback}
        setIsRecommendedByCurrentUser={setIsRecommendedByCurrentUser}
        isRecommendedByCurrentUser={isRecommendedByCurrentUser}
        data={recommendation}
      />
      <IntReviewModal
        isShowingIntReview={isShowingInfo}
        hide={() => {
          toggleShowingInfo()
        }}
        data={userInfo}
        type={"info"}
      />
    </RootStyle>
  )
}
