import "simplebar/src/simplebar.css"
import "./styles/App.css"
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import { HelmetProvider } from "react-helmet-async"
import { Provider } from "react-redux"
import store from "./redux/Store.js"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import reportWebVitals from "./reportWebVitals"
// import CookieUnload from "./components/CookieUnload"
import { generateNonce } from "./utils/uniqueId"

if (!process.env.REACT_APP_NONCE) {
  process.env.REACT_APP_NONCE = generateNonce()
}
// Set nonce value on the window object
const nonce = process.env.REACT_APP_NONCE
window.__nonce__ = nonce

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback={<div></div>}>
          <App />

          {/* <CookieUnload /> */}
        </Suspense>
      </Provider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root"),
)

// If you want to enable client cache, register instead.
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// Set the nonce value in the meta tag
// document.querySelector('meta[name="nonce"]').setAttribute("content", nonce)

const styleTags = document.querySelectorAll("style, style[data-emption]")

styleTags.forEach((styleTags) => {
  styleTags.setAttribute("nonce", nonce)
})
