import _, { filter } from "lodash"
import React, { useState, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import {
  Card,
  Box,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Checkbox,
} from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import Page from "../components/Page"
import Scrollbar from "../components/Scrollbar"
import AddIcon from "@mui/icons-material/Add"
import SearchNotFound from "../components/SearchNotFound"
import {
  QuestionListHead,
  QuestionListToolbar,
  QuestionMoreMenu,
  AddQuestionModal,
} from "../sections/@dashboard/questions"
import { questionsDataParsing } from "src/utils/dataParsing"
import {
  getAllQuestionSet,
  resetQuestionsFlag,
} from "src/redux/actions/questions"
import { resetErrors } from "src/redux/actions/resetError"
import Loader from "src/components/Loader"
import {
  getAllDepartment,
  resetDepartmentFlag,
} from "src/redux/actions/department"
import { DeleteInterviewModal } from "src/sections/@dashboard/interview"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import questionSetArr from "src/_mock/questionSetArr"
import { fDateFormatYearlong } from "src/utils/formatTime"
import { resetConfigurationsFlag } from "src/redux/actions/accountConfig"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import TableRowsIcon from "@mui/icons-material/TableRows"
import GridViewIcon from "@mui/icons-material/GridView"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import Alert from "src/components/Alert"
// ----------------------------------------------------------------------
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))
const TABLE_HEAD = (t) => [
  { id: "name", label: t("QuestionSetTitle"), alignRight: false },
  { id: "department", label: t("Department"), alignRight: false },
  { id: "totalQuestions", label: t("TotalQuestions"), alignCenter: true },
  { id: "updaedDate", label: t("UpdatedOn"), alignRight: false },
  { id: "", label: t("Action"), alignRight: true },
]
//-----------------------------------------

export default function Questions() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission
  const loadingState = useSelector((state) => state.loadingManagement)
  const checkCrash = useSelector((state) => state.crashManagement)
  const quest = useSelector((state) => state.questions)
  const createDepartment = useSelector((state) => state.createDepart)
  const config = useSelector((state) => state.accountConfigurations)

  let creationInfo = JSON.parse(sessionStorage.getItem("creation_info"))
  const [QSShows, setQSShows] = useState(creationInfo?.question_set ?? false)

  useEffect(() => {
    if (createDepartment?.data?.lenght === 0) {
      dispatch(getAllDepartment())
    }
    if (createDepartment?.isCreated) {
      setOpenSnackbar(true)
      setDisplayCrash(createDepartment?.message)
      setSeverity("success")
      dispatch(resetDepartmentFlag())
    }
    if (createDepartment?.isFailure) {
      setOpenSnackbar(true)
      setDisplayCrash(createDepartment?.error)
      setSeverity("error")
      dispatch(resetDepartmentFlag())
    }
  }, [createDepartment])

  useEffect(() => {
    if (quest?.data?.length === 0) {
      dispatch(getAllQuestionSet())
    }
    if (quest?.data?.success) {
      if (QSShows !== true)
        setParsedQuestion([
          ...questionsDataParsing(quest?.data?.question_sets),
          questionSetArr,
        ])
      else setParsedQuestion(questionsDataParsing(quest?.data?.question_sets))
    }
    if (quest?.isCreated) {
      setSeverity("success")
      setOpenSnackbar(true)
      setDisplayCrash(quest?.message)
      dispatch(resetQuestionsFlag())
    }
    if (quest?.isUpdated) {
      dispatch(getAllQuestionSet())
      setSeverity("success")
      setOpenSnackbar(true)
      setDisplayCrash(quest?.message)
      dispatch(resetQuestionsFlag())
    }
    if (quest?.isFailure) {
      setOpenSnackbar(true)
      setDisplayCrash(quest.error)
      setSeverity("error")
      dispatch(resetQuestionsFlag())
    }
    if (quest?.isDeleted) {
      setSeverity("success")
      setOpenSnackbar(true)
      setDisplayCrash(quest?.message)
      dispatch(resetQuestionsFlag())
      dispatch(getAllQuestionSet())
    }
  }, [quest, QSShows])

  useEffect(() => {
    if (checkCrash?.url?.split("/")?.includes("question_sets")) {
      if (checkCrash?.succeed === false || checkCrash?.apiError === true) {
        setOpenSnackbar(true)
        setDisplayCrash(checkCrash.message)
        setSeverity("error")
        dispatch(resetErrors())
      }
    }
  }, [checkCrash])

  useEffect(() => {
    if (config?.edited) {
      setQSShows(true)
      setSeverity("success")
      setOpenSnackbar(true)
      setDisplayCrash(config.message)
      dispatch(resetConfigurationsFlag())
    }
  }, [config])

  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("asc")
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState("name")

  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [displayCrash, setDisplayCrash] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorSeverity, setSeverity] = useState("")
  const [parsedQuestion, setParsedQuestion] = useState([])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = parsedQuestion?.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = [...selected, id]
    } else {
      newSelected = selected.filter((selectedId) => selectedId !== id)
    }
    setSelected(newSelected)
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - parsedQuestion?.length)
      : 0

  const isUserNotFound = parsedQuestion?.length === 0

  const [isShowingQuestion, toggleQuestion] = useModal()
  const [isShowingDeleteInt, toggleDeleteInt] = useModal()

  //--------------- views code-------------------------------
  const [list, setList] = useState(true)
  const [grid, setGrid] = useState(false)
  const [matchedData, setMatchedData] = useState()

  const matchedQuestionSet = (id) => {
    for (let i = 0; i < quest?.data?.question_sets?.length; i++) {
      if (id === quest?.data?.question_sets[i]?.id) {
        return [quest?.data?.question_sets[i], i]
      }
    }
  }

  const handleEdit = (id) => {
    toggleQuestion()
    const [match] = matchedQuestionSet(id)
    setMatchedData(match)
  }

  const handleDelete = (id) => {
    const [match] = matchedQuestionSet(id)
    setMatchedData(match)
    toggleDeleteInt()
  }

  const handleGrid = () => {
    setGrid(true)
    setList(false)
    setSearchFilter(null)
    setDepartmentFilter(null)
  }

  const handleList = () => {
    setList(true)
    setGrid(false)
    setSearchFilter(null)
    setDepartmentFilter(null)
  }

  const refreshQuestion = () => {
    dispatch(getAllQuestionSet())
  }

  const getQuestionSetsDepartments = () => {
    if (parsedQuestion) {
      return _.uniq(parsedQuestion.map((question) => question.department?.name))
    }
    return []
  }
  const [searchFilter, setSearchFilter] = useState(null)
  const [departmentFilter, setDepartmentFilter] = useState(null)

  const handleFilterByQuestionName = (event) => {
    setSearchFilter(event.target.value)
    setDepartmentFilter(null)
    setPage(0)
  }

  const handleFilterByDepartmentName = (value) => {
    setSearchFilter(null)
    setDepartmentFilter(value)
    setPage(0)
  }
  const filteredItems = parsedQuestion?.filter((item) => {
    const isSearchFilterMatch =
      searchFilter &&
      item?.name?.toLowerCase()?.includes(searchFilter?.toLowerCase())

    const isDepartmentFilterMatch =
      departmentFilter &&
      item?.department?.name
        ?.toLowerCase()
        ?.includes(departmentFilter?.toLowerCase())

    if (searchFilter) {
      return isSearchFilterMatch
    }

    if (departmentFilter) {
      return isDepartmentFilterMatch
    }

    return true
  })

  const filteredItemsCount = filteredItems.length

  ///----------------------------

  return (
    <Page title={t("Questions")} className='question-page'>
      <Loader respLoading={loadingState?.loading} />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={errorSeverity}
          sx={{ width: "100%" }}
        >
          {displayCrash}
        </Alert>
      </Snackbar>
      {userPermissions?.question_sets_module ? (
        <Container maxWidth='xl' spacing={3}>
          <Stack
            alignItems={{ xs: "flex-start", sm: "center" }}
            sx={{ mb: { xs: 2, sm: 4, md: 5 } }}
            direction='row'
            justifyContent='space-between'
          >
            <Typography
              variant='h5'
              sx={{
                mb: 0,
                fontWeight: 600,
                display: "flex",
                flexDirection: "row",
                color: "#434e58",
              }}
            >
              {t("QuestionSet")}: {parsedQuestion?.length}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Stack
                direction='row'
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  background: "white",
                  borderRadius: "6px",
                  padding: "1px",
                  mx: { xs: "5px", sm: 2 },
                }}
              >
                <TableRowsIcon
                  sx={{
                    color: list ? "white" : "#aab5c4",
                    cursor: "pointer",
                    backgroundColor: list ? "var(--primary)" : "white",
                    padding: "6px 4px",
                    borderRadius: "4px",
                    boxSizing: "content-box",
                    minHeight: 20,
                    width: { xs: "20", sm: "24" },
                  }}
                  onClick={handleList}
                />

                <GridViewIcon
                  sx={{
                    color: grid ? "white" : "#aab5c4",
                    cursor: "pointer",
                    backgroundColor: grid ? "var(--primary)" : "white",
                    padding: "6px 4px",
                    borderRadius: "4px",
                    boxSizing: "content-box",
                    minHeight: 20,
                    width: { xs: "20", sm: "24" },
                  }}
                  onClick={handleGrid}
                />
              </Stack>
              {userPermissions?.question_sets_add && (
                <Button
                  variant='contained'
                  onClick={toggleQuestion}
                  component={RouterLink}
                  className='add-btn'
                  to='#'
                  startIcon={<AddIcon />}
                >
                  <span className='visible-xs'>{t("Add")}</span>
                  <span className='hidden-xs'>{t("NewQuestionSet")}</span>
                </Button>
              )}
            </Box>
          </Stack>

          {list && (
            <Card sx={{ marginTop: "2vh" }}>
              <QuestionListToolbar
                refreshQuestion={refreshQuestion}
                departments={getQuestionSetsDepartments()}
                numSelected={selected?.length}
                searchFilterName={searchFilter}
                onFilterSearch={handleFilterByQuestionName}
                onFilterDeptName={handleFilterByDepartmentName}
                questions={selected}
                canSearch={userPermissions?.question_sets_search_bar}
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table className='simple-table'>
                    <QuestionListHead
                      t={t}
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD(t)}
                      rowCount={parsedQuestion?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />

                    <TableBody>
                      {parsedQuestion
                        .filter((item) => {
                          const isSearchFilterMatch =
                            searchFilter &&
                            item?.name
                              ?.toLowerCase()
                              ?.includes(searchFilter?.toLowerCase())

                          const isDepartmentFilterMatch =
                            departmentFilter &&
                            item?.department?.name
                              ?.toLowerCase()
                              ?.includes(departmentFilter?.toLowerCase())

                          if (searchFilter) {
                            return isSearchFilterMatch
                          } else if (departmentFilter) {
                            return isDepartmentFilterMatch
                          } else {
                            return true
                          }
                        })
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )

                        .map((row) => {
                          const {
                            id,
                            name,
                            updatedDate,
                            department,
                            totalQuestions,
                          } = row
                          const isItemSelected = selected.indexOf(id) !== -1

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role='checkbox'
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding='checkbox'>
                                <Checkbox
                                  key={id}
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>
                              <TableCell
                                component='th'
                                scope='row'
                                padding='none'
                              >
                                <Stack
                                  direction='row'
                                  alignItems='center'
                                  spacing={2}
                                >
                                  <Typography
                                    variant='subtitle2'
                                    noWrap
                                    sx={{
                                      textTransform: "capitalize",
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell sx={{ width: "150px" }} align='left'>
                                {department?.name ?? ""}
                              </TableCell>
                              <TableCell sx={{ width: "150px" }} align='center'>
                                {row.id === 0
                                  ? row.total_questions
                                  : totalQuestions}
                              </TableCell>
                              <TableCell align='left' sx={{ width: "160px" }}>
                                {row.id === 0
                                  ? fDateFormatYearlong(
                                      logged?.user?.account?.created_at,
                                    )
                                  : updatedDate}
                              </TableCell>
                              <TableCell
                                align='right'
                                width='8%'
                                sx={{ width: "150px" }}
                                className='stickyCell'
                              >
                                {logged?.user?.profile?.permission
                                  ?.question_sets_delete ||
                                logged?.user?.profile?.permission
                                  ?.question_sets_update ? (
                                  <QuestionMoreMenu
                                    disabled={selected?.length > 0}
                                    id={id}
                                    canDelete={
                                      logged?.user?.profile?.permission
                                        ?.question_sets_delete
                                    }
                                    canEdit={
                                      logged?.user?.profile?.permission
                                        ?.question_sets_update
                                    }
                                  />
                                ) : null}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell className='searchCell' colSpan={6}>
                            <SearchNotFound searchQuery={searchFilter ?? ""} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                labelRowsPerPage={t("RowsPerPage")}
                count={filteredItemsCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          )}

          {grid && (
            <>
              <Card sx={{ marginTop: "2vh", mb: 2 }}>
                <QuestionListToolbar
                  refreshQuestion={refreshQuestion}
                  departments={getQuestionSetsDepartments()}
                  numSelected={selected?.length}
                  searchFilterName={searchFilter}
                  onFilterSearch={handleFilterByQuestionName}
                  onFilterDeptName={handleFilterByDepartmentName}
                  questions={selected}
                  canSearch={userPermissions?.question_sets_search_bar}
                />
              </Card>
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {parsedQuestion
                  ?.filter((item) => {
                    const hasSearchMatch =
                      searchFilter &&
                      item?.name
                        ?.toLowerCase()
                        ?.includes(searchFilter?.toLowerCase())
                    const hasDepartmentMatch =
                      departmentFilter &&
                      item?.department?.name
                        ?.toLowerCase()
                        ?.includes(departmentFilter?.toLowerCase())
                    return (
                      hasSearchMatch ||
                      (departmentFilter ? hasDepartmentMatch : true)
                    )
                  })
                  ?.map((user) => {
                    return (
                      <Grid
                        className='cube'
                        item
                        key={user?.id}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                        sx={{ mt: 1 }}
                      >
                        <Card
                          sx={{
                            background: "#fff",
                            padding: "12px",
                            height: "100%",
                            paddingBottom: "30px",
                            "&:hover": {
                              opacity: "0.7",
                              backgroundColor: "#2065D1",
                              color: "#fff",
                            },
                          }}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={5}>
                              <Typography
                                variant='subtitle1'
                                sx={{ fontWeight: 600 }}
                              >
                                {t("Title")}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  marginRight: "0px",
                                  textTransform: "none",
                                  fontWeight: 400,
                                  display: "flex",
                                }}
                              >
                                {user?.name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <Typography
                                variant='subtitle1'
                                sx={{ fontWeight: 600 }}
                              >
                                {t("TotalQuestions")}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  marginRight: "0px",
                                  textTransform: "capitalize",
                                  fontWeight: 400,
                                  display: "flex",
                                }}
                              >
                                {user?.totalQuestions}&nbsp;
                                {user?.totalQuestions === 1
                                  ? t("Question")
                                  : t("Questions")}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={5}>
                              <Typography
                                variant='subtitle1'
                                sx={{ fontWeight: 600 }}
                              >
                                {t("LastUpdated")} :
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                              <Typography
                                variant='subtitle1'
                                sx={{
                                  marginRight: "0px",
                                  textTransform: "capitalize",
                                  fontWeight: 400,
                                  display: "flex",
                                }}
                              >
                                {user?.updatedDate}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Stack
                            className='cube-icon-row'
                            direction='row'
                            justifyContent='flex-end'
                            sx={{
                              position: "absolute",
                              bottom: "7px",
                              right: "8px",
                            }}
                          >
                            {userPermissions?.question_sets_update && (
                              <EditOutlinedIcon
                                onClick={() => handleEdit(user?.id)}
                              />
                            )}
                            {userPermissions?.question_sets_delete && (
                              <DeleteOutlineIcon
                                sx={{
                                  cursor: "pointer",
                                  margin: "2px",
                                  width: "24px",
                                  height: "20px",
                                }}
                                onClick={() => handleDelete(user?.id)}
                              />
                            )}
                          </Stack>
                        </Card>
                        {isUserNotFound && (
                          <Grid sx={{ textAlign: "left" }}>
                            <SearchNotFound searchQuery={searchFilter ?? ""} />
                          </Grid>
                        )}
                      </Grid>
                    )
                  })}
              </Grid>
              {isUserNotFound && (
                <Grid sx={{ textAlign: "left" }}>
                  <SearchNotFound searchQuery={searchFilter ?? ""} />
                </Grid>
              )}{" "}
            </>
          )}
        </Container>
      ) : (
        <Container maxWidth='xl' spacing={3}>
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("NoAccessPAge")}
            </Typography>
            <Box
              component='img'
              src='/static/illustrations/illustration_404.svg'
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </ContentStyle>
        </Container>
      )}


      <AddQuestionModal
        isShowingQuestion={isShowingQuestion}
        hide={toggleQuestion}
        flag={true}
        data={matchedData}
      />

      <DeleteInterviewModal
        isShowingDeleteInt={isShowingDeleteInt}
        hide={toggleDeleteInt}
        data={matchedData}
        invoke={"question"}
      />
    </Page>
  )
}
