import PropTypes from "prop-types"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { styled } from "@mui/material/styles"
import { Box, Drawer, Typography, Stack } from "@mui/material"

import useResponsive from "../../hooks/useResponsive"
import Logo from "../../components/Logo/Logo-Sidebar"
import Scrollbar from "../../components/Scrollbar"
import NavSection from "../../components/NavSection"
import navConfig from "./NavConfig"
import Images from "../../assets/img/Images"
import { getCompanyInfo } from "src/redux/actions/general"
import { useDispatch, useSelector } from "react-redux"
import { useLocalStorage } from "src/hooks/useLocalStorage"
import { useTranslation } from "react-i18next"
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}))

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}))

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
  selectedItem: PropTypes.string,
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  selectedItem,
}) {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const isDesktop = useResponsive("up", "lg")
  const logged = useSelector((state) => state.login)
  const companyInfo = useSelector((state) => state.getCompanyInfo)
  const companyLogo = companyInfo?.data?.account?.logo?.url
  const [userKey] = useLocalStorage("key", "")
  const { t } = useTranslation()
  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem("user"))
    if (document.readyState === "interactive" && currentUser?.user)
      dispatch(getCompanyInfo(userKey))
  }, [logged])

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: "inline-flex" }}>
        {companyLogo === null || undefined ? (
          <Logo icon={Images.LogoMain} noIcon={Images.LogoMain} />
        ) : (
          <Logo icon={companyLogo} noIcon={Images.LogoMain} />
        )}
      </Box>

      <NavSection navConfig={navConfig} />
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems='center'
          spacing={0}
          className='product-logo'
          sx={{ pt: 5, position: "relative" }}
        >
          <Box component='img' src={Images.LogoSm} sx={{ width: 100 }} />
          <Typography
            className='version-font'
            variant='body2'
            sx={{ color: "text.secondary" }}
          >
            {t("Version")} - 1.5.1
          </Typography>
        </Stack>
      </Box>
    </Scrollbar>
  )

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              zIndex: 999,
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "solid",
            },
          }}
          anchor={selectedItem === "Arabic" ? "right" : "left"}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  )
}
