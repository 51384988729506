import React, { useState } from "react"
import {
  Grid,
  Dialog,
  DialogContent,
  Typography,
  IconButton,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import GuideModal from "./guideModal"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation, withTranslation, Trans } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"

export default function WelcomeModal({
  isShowingRole,
  setIsShowingRole,
  hide,
}) {
  const { t } = useTranslation()
  const [isShowingGuide, toggleGuide] = useModal()

  const handleGetStarted = async () => {
    toggleGuide()
    setTimeout(() => {
      // setIsShowingRole(false)
      isShowingRole = ""
    }, 1000)
  }

  return (
    isShowingRole && (
      <Dialog
        className='dialog600 welcome-screen'
        open={isShowingRole}
        onClose={hide}
        scroll='paper'
        maxWidth='md'
      >
        <DialogContent
          className='dialog-content'
          dividers={true}
          sx={{ padding: "0px" }}
        >
          <GuideModal
            isShowingGuide={isShowingGuide}
            handleClose={toggleGuide}
          />
          <IconButton
            aria-label='close'
            onClick={hide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={0} className='h-300'>
            <Grid item sm={5} sx={{ display: { xs: "none", sm: "block" } }}>
              <div className='welcome-background'></div>
            </Grid>
            <Grid item sm={7} className='welcome-note'>
              <Typography variant='h4' sx={{ pb: 2 }}>
                {t("WelcometoIntVue")}!
              </Typography>
              <Typography variant='p' sx={{ pb: 3, color: "text.secondary" }}>
                {t("Tagline")}
              </Typography>

              <LoadingButton
                size='medium'
                type='submit'
                variant='contained'
                sx={{ maxWidth: "124px", marginBottom: 0 }}
                onClick={handleGetStarted}
                className='btn-primary '
              >
                {t("GetStarted")}
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  )
}
