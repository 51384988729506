import PropTypes from "prop-types"
import React from "react"
import { Grid } from "@mui/material"
import SubsPlanCard from "./PlansCard"

// ----------------------------------------------------------------------

PlansList.propTypes = {
  plans: PropTypes.array.isRequired,
  handleNext: PropTypes.func,
}
export default function PlansList({
  values,
  plans,
  isChecked,
  handleNext,
  ...other
}) {
  return (
    <Grid container spacing={3} {...other}>
      {plans
        .filter((plan) => {
          return isChecked
            ? plan.interval === "year"
            : plan.interval === "month"
        })
        .map((plan, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} className='package-div'>
            <SubsPlanCard handleNext={handleNext} plan={plan} values={values} />
          </Grid>
        ))}
    </Grid>
  )
}
