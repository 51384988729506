import { React, useState } from "react"
import { Outlet } from "react-router-dom"
import { styled } from "@mui/material/styles"
import LandingNavbar from "./LandingNavbar"
// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  width: "100%",
  overflow: "hidden",
})

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
  },
}))

// ----------------------------------------------------------------------

export default function LandingLayout() {
  const [selectedItem, setSelectedItem] = useState(null)

  return (
    <>
      <RootStyle className='body-container'>
        <div className='landing-page'>
          <LandingNavbar
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
          <MainStyle component='main' className='main-area'>
            <Outlet />
          </MainStyle>
        </div>
      </RootStyle>
    </>
  )
}
