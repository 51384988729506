import React, { useState, useEffect } from "react"
import { useFormik, Form, FormikProvider } from "formik"
import {
  Stack,
  IconButton,
  Typography,
  ListItemAvatar,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Button,
  Grid,
  Card,
  Box,
  FormControl,
  Popover,
  TextField,
} from "@mui/material"
import FlagIcon from "@mui/icons-material/Flag"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { useTranslation } from "react-i18next"
import Label from "src/components/Label"
import { secondsToTime } from "src/utils/formatTime"
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded"
import { useAuth } from "src/hooks/useAuth"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SendIcon from "@mui/icons-material/Send"
import PersonIcon from "@mui/icons-material/Person"

export default function SampleFeedback(int) {
  const { user } = useAuth()
  const { t } = useTranslation()

  // const [comments, setComments] = useState([])
  const [selectedQuestionNo, setSelectedQuestionNo] = useState(0)
  const [active, setActive] = useState(0)
  const [anchorEl, setAnchorEl] = useState(null)

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? "simple-popover" : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const formik = useFormik({
    initialValues: {
      interview_id: "",
      question_id: "",
      text: "",
    },
    onSubmit: () => {},
  })

  const { values, handleSubmit } = formik

  const QuestionList = ({ questions, onQuestionClick }) => {
    return questions?.map((question, index) => {
      return (
        <ListItem
          key={question.id}
          className={`clips-list-item ${active === index && "active-list"}`}
          sx={{
            alignItems: "flex-start",
            mb: "1rem !important",
            borderRadius: "8px",
            paddingRight: "50px",
            background: "white",
            color: "#637381",
          }}
          onClick={() => {
            onQuestionClick(question)
            setActive(index)
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Stack direction='row'>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    borderRadius: "4px",
                    border: "1px solid #D3D3D3",
                    width: {
                      xs: "44px",
                      sm: "60px",
                      md: "84px",
                    },
                    height: {
                      xs: "auto",
                      sm: "auto",
                      md: "54px",
                    },
                    mr: 2,
                  }}
                >
                  <video
                    playsInline
                    muted={true}
                    autoPlay={false}
                    loop
                    style={{
                      objectFit: "cover",
                      width: "84px",
                      height: "54px",
                    }}
                    width='100%'
                    poster='/static/videoph.png'
                  >
                    <source src='/static/videoph.png' />
                  </video>
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                sx={{
                  color: "#000",
                  fontWeight: 600,
                }}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  Q {index + 1}: {question.question.text}
                </Typography>

                <Typography
                  variant='subtitle2'
                  color='text.secondary'
                  className='comment-row'
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: "12px",
                    mt: "4px",
                    color: "#82888E",
                  }}
                >
                  {secondsToTime(question.duration)}
                  <span>.</span>
                  {question?.user_comments?.length} {t("Comments")}
                  <span>&nbsp;.&nbsp;</span>
                  {question?.question_views?.length}{" "}
                  {question?.question_views?.length > 1
                    ? t("Views")
                    : t("View")}
                </Typography>
              </ListItemText>
            </Stack>
          </Box>

          <IconButton
            edge='end'
            aria-label='Flag'
            className='flag-icon'
            sx={{
              position: "absolute",
              right: "20px",
              top: "13px",
            }}
            disabled
          >
            <FlagIcon
              sx={{
                color: "#637381",
              }}
            />
          </IconButton>
        </ListItem>
      )
    })
  }

  const UserComments = ({ comments }) => {
    return (
      <List
        className='feed-list'
        sx={{
          width: "100%",
          maxWidth: "100%",
          bgcolor: "background.paper",
        }}
      >
        {comments.map((comment) => (
          <ListItem key={comment?.id} alignItems='flex-start' sx={{ px: 0 }}>
            <ListItemAvatar>
              <Avatar
                sx={{
                  fontSize: 12,
                  textTransform: "uppercase",
                }}
              >
                {comment?.user_name?.slice(0, 1)}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <React.Fragment>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      display: "inline-flex",
                      color: "text.primary",
                      textTransform: "capitalize",
                    }}
                  >
                    {comment.user.first_name} {comment.user.last_name}
                  </Typography>

                  <Typography
                    sx={{
                      display: "inline-block",
                      mx: 1,
                    }}
                    component='span'
                    variant='body2'
                    color='text.primary'
                  >
                    20/10/2023 - 8:16:14 PM
                  </Typography>
                </React.Fragment>
              }
              secondary={comment.text}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                minWidth: "60px",
              }}
            >
              <EditIcon
                sx={{
                  mt: "8px",
                  ml: 1,
                  color: "#637381",
                  cursor: "no-drop",
                  fontSize: "20px",
                }}
                width={24}
              />
              <DeleteOutlineIcon
                width={20}
                height={20}
                sx={{
                  mt: "8px",
                  ml: 1,
                  color: "#637381",
                  cursor: "no-drop",
                }}
              />
            </Box>
          </ListItem>
        ))}
      </List>
    )
  }

  const [selectedQuestion, setSelectedQuestion] = useState(null)

  useEffect(() => {
    if (int?.int?.interview_questions.length > 0 && !selectedQuestion) {
      setSelectedQuestion(int?.int?.interview_questions[0])
    }
  }, [int?.int?.interview_questions, selectedQuestion])

  const handleQuestionClick = (question) => {
    setSelectedQuestion(question)
  }

  return (
    <Card key={int?.id} sx={{ my: 3, pl: 1, ml: 1, width: "100%" }}>
      <Grid item xs={12}>
        <Stack
          direction={{ sm: "column", md: "row" }}
          justifyContent='space-between'
          sx={{
            margin: 2,
            borderBottom: "1px solid #E5E7EB",
            pb: 1,
          }}
        >
          <Stack
            justifyContent='flex-start'
            direction={{ xs: "column", sm: "row" }}
          >
            <Box sx={{ pr: 3 }}>
              <Typography
                variant='subtitle1'
                sx={{
                  color: "text.primary",
                  textTransform: "capitalize",
                  mb: "4px",
                  fontSize: "16px",
                  fontWeight: 600,
                  minWidth: "250px",
                  wordBreak: "break-word",
                }}
              >
                {int?.int?.candidate_vacancy?.candidate?.first_name +
                  " " +
                  int?.int?.candidate_vacancy?.candidate?.last_name}
              </Typography>
              <Typography
                variant='body2'
                sx={{
                  fontWeight: 500,
                  mr: 1,
                  color: "#66696E",
                  fontSize: "12px",
                  minWidth: "250px",
                  wordBreak: "break-word",
                  textTransform: "capitalize",
                }}
              >
                {int?.int?.candidate_vacancy?.vacancy?.title ?? ""}
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  color: "text.primary",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  mb: 1,
                }}
              >
                <PersonIcon
                  sx={{
                    mr: 1,
                    fontSize: "18px",
                    color: "#66696E",
                  }}
                />{" "}
                <Box className='multi'>
                  {int?.int?.interviewers?.map((userInterviewer) => {
                    return (
                      <Typography
                        variant='subtitle2'
                        component='span'
                        sx={{
                          my: "4px",
                          whiteSpace: "break-spaces",
                          lineHeight: "1.5em",
                          fontWeight: 500,
                          wordBreak: "break-word",
                          textTransform: "capitalize",
                        }}
                      >
                        {(userInterviewer?.user?.first_name ?? "") +
                          " " +
                          (userInterviewer?.user?.last_name ?? "")}
                      </Typography>
                    )
                  })}
                </Box>
              </Box>
            </Box>
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }}>
            <Button
              variant='outlined'
              color='primary'
              disabled
              sx={{ my: 1, mx: 1, maxWidth: "150px" }}
            >
              {t("Candidate Info")}
            </Button>
          </Stack>
        </Stack>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6} xl={7}>
          <Box className='leftPane'>
            <Stack direction='row' justifyContent='space-between'>
              <Typography
                variant='h5'
                sx={{
                  fontWeight: 600,
                  mb: 1,
                  width: "88%",
                  display: "block",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Q {selectedQuestion?.question?.id}:{" "}
                {selectedQuestion?.question?.text}
              </Typography>
            </Stack>
            <video
              className='videoPlayer'
              width='100%'
              height='350'
              autoPlay={true}
              controls
              poster='/static/videoph.png'
              playsInline
              muted={true}
              loop
              src='/static/demo.mp4'
            ></video>

            {selectedQuestion?.question?.question_type ===
              "Multiple Choice" && (
              <Box>
                <Popover
                  id={id}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  style={{ width: "300px !important" }}
                >
                  <Box
                    sx={{
                      width: "300px",
                      maxHeight: "300px",
                      overflow: "auto",
                      p: 2,
                    }}
                  >
                    {selectedQuestion?.question?.choices?.map((str, index) => (
                      <Typography
                        key={str + "-ch"}
                        variant='body1'
                        sx={{ wordWrap: "break-word", mb: 1 }}
                        className={`radio-selectbox ${
                          selectedQuestion?.question?.correct_answer === str
                            ? "active"
                            : ""
                        }`}
                      >
                        {index + 1}: {str}
                        {selectedQuestion?.question?.correct_answer === str && (
                          <CheckCircleRoundedIcon
                            sx={{
                              width: 16,
                              height: 16,
                              ml: 1,
                              mb: "-3px",
                            }}
                          />
                        )}
                      </Typography>
                    ))}
                  </Box>
                </Popover>
              </Box>
            )}
            {selectedQuestion?.question?.id === 2 ||
              (selectedQuestion?.question?.id === 1 && (
                <Stack>
                  <Box>
                    <Stack
                      justifyContent='space-between'
                      alignContent='center'
                      direction={{
                        xs: "column",
                        sm: "row",
                      }}
                    >
                      <Typography
                        variant='body1'
                        component='p'
                        sx={{
                          pt: 1,
                          fontWeight: 600,
                          minHeight: "33px",
                          minWidth: "120px",
                          mr: 1,
                        }}
                      >
                        Question Type:{" "}
                        <Typography variant='body2' component='span'>
                          {" "}
                          {selectedQuestion?.question?.question_type}
                        </Typography>
                      </Typography>
                      {selectedQuestion?.question.question_type ===
                        "Multiple Choice" && (
                        <Button aria-describedby={id} onClick={handleClick}>
                          Show Options
                        </Button>
                      )}
                    </Stack>
                    {selectedQuestion?.question.question_type ===
                      "Problem Solving" &&
                    selectedQuestion?.question?.correct_answer === null ? (
                      <Typography
                        sx={{
                          my: 1,
                          borderLeft: "2px solid #637481",
                          pl: 1,
                        }}
                      >
                        No written answer was provided
                      </Typography>
                    ) : (
                      <Stack
                        direction='column'
                        sx={{
                          wordBreak: "break-word",
                          my: 1,
                          borderLeft: "2px solid #637481",
                          pl: 1,
                        }}
                      >
                        <Typography
                          variant='body1'
                          component='p'
                          sx={{
                            wordBreak: "break-word",
                            fontWeight: 600,
                            minWidth: "120px",
                            mr: 1,
                          }}
                        >
                          {t("CandAns")}
                        </Typography>

                        <Typography
                          variant='body1'
                          component='p'
                          sx={{ wordBreak: "break-word" }}
                        >
                          {selectedQuestion?.candidate_answer}
                          {selectedQuestion?.question?.question_type ===
                            "Multiple Choice" && (
                            <Label
                              sx={{ mx: 1 }}
                              variant='ghost'
                              color='success'
                            >
                              Correct
                            </Label>
                          )}
                        </Typography>
                      </Stack>
                    )}
                  </Box>
                </Stack>
              ))}

            <Box sx={{ mb: 1 }}>
              <Stack
                direction='row'
                justifyContent='space-between'
                sx={{ my: 1 }}
              >
                <Typography component='h5' variant='h5'>
                  {t("FeedbackOnQuest")}
                </Typography>
                <Label
                  variant='ghost'
                  sx={{ marginLeft: "4px", mb: 1, mt: 1 }}
                  color='warning'
                >
                  {selectedQuestion?.user_comments?.length} {t("Comment")}
                </Label>
              </Stack>

              <FormikProvider value={formik}>
                <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                  <FormControl
                    sx={{
                      width: "100%",
                      mt: 0,
                      mb: 1,
                      flexDirection: "row",
                    }}
                    className='comment-area'
                  >
                    <TextField
                      label={t("AddComment")}
                      fullWidth
                      type='text'
                      variant='outlined'
                      name='text'
                      value={values.text}
                      disabled
                      size='small'
                    />

                    <Button
                      type='submit'
                      variant='contained'
                      color='secondary'
                      size='large'
                      sx={{
                        maxHeight: 38,
                        width: "60px",
                        fontWeight: 500,
                        justifyContent: "center",
                        pl: 1,
                        pr: 1,
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: " 0px",

                        fontSize: "30px",
                      }}
                      disabled={values.text.trim() === ""}
                    >
                      <SendIcon />
                    </Button>
                  </FormControl>
                </Form>
              </FormikProvider>
            </Box>
            {selectedQuestion && (
              <UserComments comments={selectedQuestion.user_comments} />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6} lg={6} xl={5} sx={{ pt: 6, pb: 5 }}>
          <Box
            sx={{
              mr: 2,
              ml: { xs: 2, sm: 2, md: 0 },
            }}
          >
            <Stack
              direction={{ sm: "column", md: "row" }}
              alignItems='center'
              justifyContent={{
                sm: "flex-start",
                md: "space-between",
              }}
              sx={{
                borderBottom: "1px solid #E5E7EB",
                p: 1,
              }}
            >
              <Typography variant='h5' component='h5'>
                Interview Questions
              </Typography>

              <Button
                variant='outlined'
                disabled
                sx={{
                  mb: 2,
                }}
                size='medium'
              >
                {t("GiveRecommendation")}
              </Button>
            </Stack>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent='space-between'
              alignItems={{
                xs: "flex-start",
                sm: "center",
              }}
              sx={{ my: 1, px: 2, mt: 2 }}
            >
              <Typography
                variant='h6'
                component='div'
                sx={{ fontWeight: 500, fontSize: 12 }}
                color='text.secondary'
              >
                {t("TotalQuestions")}: {int?.int?.interview_questions?.length}
              </Typography>
              <Typography
                variant='h6'
                color='text.secondary'
                sx={{
                  fontWeight: 500,
                  fontSize: 12,
                  alignItems: "center",
                  display: "inline-flex",
                }}
              >
                <AccessTimeIcon sx={{ mr: 1 }} /> {t("Duration")}
                :&nbsp; 15 {t("minutes")}
              </Typography>
            </Stack>

            <Box
              sx={{
                maxWidth: "800px",
                maxHeight: 600,
                overflow: "auto",
              }}
            >
              <List
                className='clips-list'
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <QuestionList
                  questions={int?.int?.interview_questions}
                  onQuestionClick={handleQuestionClick}
                />
              </List>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}
