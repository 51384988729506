import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import { Card, Typography } from "@mui/material"
import React from "react"
// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "0",
  display: "flex",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "flex-start",
  marginBottom: theme.spacing(2),
}))

// ----------------------------------------------------------------------

AppAbout.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  sx: PropTypes.object,
}

export default function AppAbout({
  title,
  content,
  icon,
  color = "primary",
  sx,
  ...other
}) {
  return (
    <Card
      className='block-height'
      sx={{
        py: 5,
        background: "#fff",
        borderRadius: " 30px",
        textAlign: "left",
        color: (theme) => theme.palette[color].darker,
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        className='block-icon'
        sx={{ color: (theme) => theme.palette[color].dark }}
      >
        <img alt='txt' src={icon} width={24} height={24} />
      </IconWrapperStyle>

      <Typography
        variant='h4'
        sx={{
          color: "rgba(0,0,0,.85)",
          fontWeight: 500,
          mb: "1.5rem",
        }}
      >
        {title}
      </Typography>
      <Typography
        variant='body2'
        sx={{
          color: "#475569",
          fontWeight: 400,
          px: 0,
          fontSize: "16px !important",
        }}
      >
        {content}
      </Typography>
    </Card>
  )
}
