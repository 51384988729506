const vacanciesArr = {
  id: "001v",
  key: "ttgti2w",
  title: "Sample Vacancy",
  type: null,
  code: "V-002004",
  qualification: "MSCS",
  salary_range_from: "1000",
  salary_range_to: "5000",
  salary_currency: "EUR ( € ) ",
  interview_time: "30",
  description:
    "As a Software Engineer at ABC Tech Solutions, you'll be part of a dynamic team responsible for designing, developing, and maintaining cutting-edge software applications. You'll work on challenging projects, collaborate with cross-functional teams, and have the opportunity to contribute to the development of high-impact products.",
  time_limit: null,
  years_experience: "1",
  skills: ["Managment", "Strong communication", "Team Player"],
  salary_period: "monthly",
  status: "active",
  creator_id: null,
  is_hired: false,
  completion_comment: null,
  user_id: 1,
  department_id: 1,
  interviewers: [],
  max_experience: "5",
  reports_to: {
    report_to: "Eddie Murphy",
    report_to_id: 1,
  },
  pending_interviews: 1,
  department: {
    id: 1,
    key: "9235qwx",
    name: "Sample Department",
    description:
      "Our Marketing Department at XYZ Company is a dynamic team dedicated to promoting our brand and products. We're on a mission to connect with our target audience, drive growth, and enhance the company's reputation. Our team is passionate about creativity, innovation, and achieving measurable results.",
    skills: [],
  },
  vacancy_hired_candidates: [
    {
      candidate: [{ first_name: "Emilly", last_name: "Macky" }],
      candidate_id: 502,
      created_at: "2023-09-12T10:43:27.929Z",
      id: 12,
      updated_at: "2023-09-12T10:43:27.929Z",
      vacancy_id: 142,
    },
  ],
}

export default vacanciesArr
