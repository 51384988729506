import React from "react"
import { Navigate, useRoutes, useLocation } from "react-router-dom"
import DashboardLayout from "./layouts/dashboard"
import LogoOnlyLayout from "./layouts/LogoOnlyLayout"

import Login from "./pages/Login"
import Terms from "./pages/Terms"
import XpageLayout from "./layouts/Xpages"
import Policy from "./pages/Policy"
import NotFound from "./pages/Page404"
import DashboardApp from "./pages/DashboardApp"
import LandingPage from "./pages/LandingPage"
import LandingLayout from "./layouts/landing"
import Questions from "./pages/QuestionsPage"
import Roles from "./pages/RolesPage"
import Interviews from "./pages/InterviewsPage"
import Candidates from "./pages/CandidatesPage"
import Team from "./pages/TeamPage"
import Departments from "./pages/DepartmentPage"
import Settings from "./pages/SettingsPage"
import SignUp from "./pages/SignUp"

import {
  CandidateInterviewHome,
  InterviewDetailsScreen,
  StartScreen,
  RecordedInt,
  InterviewRecordingsReview,
} from "./sections/@dashboard/candidate/intConduction"

import SetPassword from "./pages/SetPassword"
import { IntReviewFeedback } from "./sections/@dashboard/interview"
import { useSelector } from "react-redux"
import Cookies from "./pages/cookie"
// import IntReviewFromGuest from "./sections/@dashboard/interview/IntReviewFromGuest"

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation()
  const state = location.state

  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "app", element: <DashboardApp isComplete={state?.isComplete}/> },
        { path: "settings", element: <Settings /> },
        { path: "question-sets", element: <Questions /> },
        { path: "vacancies", element: <Roles /> },
        { path: "interviews", element: <Interviews /> },
        { path: "candidates", element: <Candidates /> },
        { path: "companyUsers", element: <Team /> },
        { path: "departments", element: <Departments /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to='/404' /> },
        { path: "", element: <Navigate to='/404' /> },
      ],
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/Home",
      element: <LandingLayout />,
      children: [{ path: "app", element: <LandingPage /> }],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to='/Home/app' /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to='/404' /> },
      ],
    },
    { path: "/login", element: <Login /> },
    { path: "/setpassword", element: <SetPassword /> },

    { path: "/signup", element: <SignUp /> },
    {
      path: "/termsconditions",
      element: <XpageLayout />,
      children: [{ path: "app", element: <Terms /> }],
    },

    {
      path: "/cookies-policy",
      element: <XpageLayout />,
      children: [{ path: "app", element: <Cookies /> }],
    },
    {
      path: "/candidate-interview/candidate/:interviewId/:isInterviewer",
      element: <CandidateInterviewHome />,
    },
    {
      path: "/candidate-interview/candidate/:interviewId",
      element: <CandidateInterviewHome />,
    },
    {
      path: "/candidate-interview-detail",
      element: <InterviewDetailsScreen />,
    },

    { path: "/interview/start", element: <StartScreen /> },
    { path: "/record-interview/start", element: <RecordedInt /> },
    {
      path: "/recorded-interview/review",
      element: <InterviewRecordingsReview />,
    },

    {
      path: "/interview-feedback",
      element: <DashboardLayout />,
      children: [{ path: "app/:intId", element: <IntReviewFeedback /> }],
    },
    // {
    //   path: "/guest-feedback/:intId",
    //   element: <IntReviewFromGuest />,

    //  },
    {
      path: "/privacypolicy",
      element: <XpageLayout />,
      children: [{ path: "app", element: <Policy /> }],
    },
    { path: "*", element: <Navigate to='/404' replace /> },
  ])
}
