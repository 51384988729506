import { faker } from "@faker-js/faker"

const currencyArr = [
  {
    currencyTitle: "Albania Lek",
    // symbolImg:  `/static/mock-images/plans_iclude/${PF_IMAGES[index]}.svg`,
    // photoURL: '/static/mock-images/avatars/avatar_default.jpg',
    code: "ALL",
    symbol: "Lek",
  },
  {
    currencyTitle: "Afghanistan Afghani",
    code: "AFN",
    symbol: "؋",
  },
  {
    currencyTitle: "Argentina Peso",
    code: "ARS",
    symbol: "$",
  },

  {
    currencyTitle: "Aruba Guilder",
    code: "AWG",
    symbol: "ƒ",
  },

  {
    currencyTitle: "Australia Dollar",
    code: "AUD",
    symbol: "$",
  },
  {
    currencyTitle: "Azerbaijan Manat",
    code: "AZN",
    symbol: "₼",
  },

  {
    currencyTitle: "Bahamas Dollar",
    code: "BSD",
    symbol: "$",
  },

  {
    currencyTitle: "Barbados Dollar",
    code: "BBD",
    symbol: "$",
  },
  {
    currencyTitle: "Belarus Ruble",
    code: "BYN",
    symbol: "Br",
  },
  {
    currencyTitle: "Belize Dollar",
    code: "BZD",
    symbol: "BZ$",
  },

  {
    currencyTitle: "Bermuda Dollar",
    code: "BMD",
    symbol: "$",
  },
  {
    currencyTitle: "Bolivia Bolíviano",
    code: "BOB",
    symbol: "$b",
  },
  {
    currencyTitle: "Bosnia and Herzegovina Convertible Mark",
    code: "BAM",
    symbol: "KM",
  },
  {
    currencyTitle: "Botswana Pula",
    code: "BWP",
    symbol: "P",
  },
  {
    currencyTitle: "Bulgaria Lev",
    code: "BGN",
    symbol: "лв",
  },
  {
    currencyTitle: "Brazil Real	",
    code: "BRL",
    symbol: "R$",
  },
  {
    currencyTitle: "Brunei Darussalam Dollar",
    code: "BND",
    symbol: "$",
  },
  {
    currencyTitle: "Cambodia Riel",
    code: "KHR",
    symbol: "៛",
  },
  {
    currencyTitle: "Canada Dollar",
    code: "CAD",
    symbol: "$",
  },
  {
    currencyTitle: "Cayman Islands Dollar",
    code: "KYD",
    symbol: "$",
  },
  {
    currencyTitle: "Chile Peso",
    code: "CLP",
    symbol: "$",
  },
  {
    currencyTitle: "China Yuan Renminbi",
    code: "CNY",
    symbol: "¥",
  },
  {
    currencyTitle: "Colombia Peso",
    code: "COP",
    symbol: "$",
  },
  {
    currencyTitle: "Costa Rica Colon",
    code: "CRC",
    symbol: "₡",
  },
  {
    currencyTitle: "Croatia Kuna",
    code: "HRK",
    symbol: "kn",
  },
  {
    currencyTitle: "Cuba Peso",
    code: "CUP",
    symbol: "₱",
  },
  {
    currencyTitle: "Czech Republic Koruna",
    code: "CZK",
    symbol: "Kč",
  },

  {
    currencyTitle: "Denmark Krone",
    code: "DKK",
    symbol: "kr",
  },
  {
    currencyTitle: "Dominican Republic Peso",
    code: "DOP",
    symbol: "RD$",
  },

  {
    currencyTitle: "East Caribbean Dollar",
    code: "XCD",
    symbol: "$",
  },
  {
    currencyTitle: "Egypt Pound",
    code: "EGP",
    symbol: "£",
  },
  {
    currencyTitle: "El Salvador Colon",
    code: "SVC",
    symbol: "$",
  },
  {
    currencyTitle: "Euro Member Countries",
    code: "EUR",
    symbol: "€",
  },
  {
    currencyTitle: "Falkland Islands (Malvinas) Pound",
    code: "FKP",
    symbol: "£",
  },
  {
    currencyTitle: "Fiji Dollar",
    code: "FJD",
    symbol: "$",
  },
  {
    currencyTitle: "Ghana Cedi",
    code: "GHS",
    symbol: "¢",
  },

  {
    currencyTitle: "Gibraltar Pound",
    code: "GIP",
    symbol: "£",
  },
  {
    currencyTitle: "Guatemala Quetzal",
    code: "GTQ",
    symbol: "Q",
  },
  {
    currencyTitle: "Guernsey Pound",
    code: "GGP",
    symbol: "£",
  },
  {
    currencyTitle: "Guyana Dollar",
    code: "GYD",
    symbol: "$",
  },
  {
    currencyTitle: " Honduras Lempira",
    code: "HNL",
    symbol: "L",
  },
  {
    currencyTitle: "Hong Kong Dollar",
    code: "HKD",
    symbol: "$",
  },
  {
    currencyTitle: "Hungary Forint",
    code: "HUF",
    symbol: "Ft",
  },
  {
    currencyTitle: "Iceland Krona",
    code: "ISK",
    symbol: "kr",
  },
  {
    currencyTitle: "India Rupee",
    code: "INR",
    symbol: "₹",
  },
  {
    currencyTitle: "Indonesia Rupiah",
    code: "IDR",
    symbol: "Rp",
  },
  {
    currencyTitle: "Iran Rial",
    code: "	IRR",
    symbol: "﷼",
  },
  {
    currencyTitle: "Isle of Man Pound",
    code: "IMP",
    symbol: "£",
  },
  {
    currencyTitle: "Israel Shekel",
    code: "ILS",
    symbol: "₪",
  },
  {
    currencyTitle: "Jamaica Dollar",
    code: "JMD",
    symbol: "J$",
  },
  {
    currencyTitle: "Japan Yen",
    code: "JPY",
    symbol: "¥",
  },
  {
    currencyTitle: "Jersey Pound",
    code: "JEP",
    symbol: "£",
  },
  {
    currencyTitle: "Kazakhstan Tenge",
    code: "KZT",
    symbol: "лв",
  },
  {
    currencyTitle: "Korea (North) Won",
    code: "KPW",
    symbol: "₩",
  },

  {
    currencyTitle: "Korea (South) Won",
    code: "KRW",
    symbol: "₩",
  },
  {
    currencyTitle: "Kyrgyzstan Som",
    code: "KGS",
    symbol: "лв",
  },
  {
    currencyTitle: "Laos Kip",
    code: "LAK",
    symbol: "₭",
  },
  {
    currencyTitle: "Lebanon Pound",
    code: "LBP",
    symbol: "£",
  },
  {
    currencyTitle: "Liberia Dollar",
    code: "LRD",
    symbol: "$",
  },
  {
    currencyTitle: "Macedonia Denar",
    code: "MKD",
    symbol: "ден",
  },
  {
    currencyTitle: "Malaysia Ringgit",
    code: "MYR",
    symbol: "RM",
  },

  {
    currencyTitle: "Mauritius Rupee",
    code: "MUR",
    symbol: "₨",
  },
  {
    currencyTitle: "Mexico Peso",
    code: "MXN",
    symbol: "$",
  },
  {
    currencyTitle: "Mongolia Tughrik",
    code: "MNT",
    symbol: "₮",
  },
  {
    currencyTitle: "Moroccan-dirham",
    code: "MNT",
    symbol: "د.إ",
  },
  {
    currencyTitle: "Mozambique Metical",
    code: "MZN",
    symbol: "MT",
  },

  {
    currencyTitle: "Namibia Dollar",
    code: "NAD",
    symbol: "$",
  },
  {
    currencyTitle: "Nepal Rupee",
    code: "NPR",
    symbol: "₨",
  },
  {
    currencyTitle: "Netherlands Antilles Guilder",
    code: "ANG",
    symbol: "ƒ",
  },
  {
    currencyTitle: "New Zealand Dollar",
    code: "NZD",
    symbol: "$",
  },
  {
    currencyTitle: "Nicaragua Cordoba",
    code: "NIO",
    symbol: "C$",
  },
  {
    currencyTitle: "Nigeria Naira",
    code: "NGN",
    symbol: "₦",
  },
  {
    currencyTitle: "Norway Krone",
    code: "NOK",
    symbol: "kr",
  },
  {
    currencyTitle: "Oman Rial",
    code: "OMR",
    symbol: "﷼",
  },
  {
    currencyTitle: "Pakistan Rupee",
    code: "	PKR",
    symbol: "₨",
  },
  {
    currencyTitle: "Panama Balboa",
    code: "PAB",
    symbol: "	B/.",
  },
  {
    currencyTitle: "Paraguay Guarani",
    code: "PYG",
    symbol: "Gs",
  },
  {
    currencyTitle: "Peru Sol",
    code: "PEN",
    symbol: "S/.",
  },
  {
    currencyTitle: "Philippines Peso",
    code: "PHP",
    symbol: "₱",
  },
  {
    currencyTitle: "Poland Zloty",
    code: "PLN",
    symbol: "zł",
  },
  {
    currencyTitle: "Qatar Riyal",
    code: "QAR",
    symbol: "﷼",
  },
  {
    currencyTitle: "Romania Leu",
    code: "RON",
    symbol: "lei",
  },
  {
    currencyTitle: "Russia Ruble",
    code: "RUB",
    symbol: "₽",
  },
  {
    currencyTitle: "Saint Helena Pound",
    code: "SHP",
    symbol: "£",
  },
  {
    currencyTitle: "Saudi Arabia Riyal",
    code: "SAR",
    symbol: "﷼",
  },
  {
    currencyTitle: "Serbia Dinar",
    code: "RSD",
    symbol: "Дин.",
  },
  {
    currencyTitle: "Seychelles Rupee",
    code: "SCR",
    symbol: "₨",
  },
  {
    currencyTitle: "Singapore Dollar",
    code: "SGD",
    symbol: "$",
  },
  {
    currencyTitle: "Solomon Islands Dollar",
    code: "SBD",
    symbol: "$",
  },
  {
    currencyTitle: "Somalia Shilling",
    code: "SOS",
    symbol: "S",
  },
  {
    currencyTitle: "South Korean Won",
    code: "KRW",
    symbol: "₩",
  },
  {
    currencyTitle: "South Africa Rand",
    code: "ZAR",
    symbol: "R",
  },
  {
    currencyTitle: "Sri Lanka Rupee",
    code: "LKR",
    symbol: "₨",
  },
  {
    currencyTitle: "Sweden Krona",
    code: "SEK",
    symbol: "kr",
  },
  {
    currencyTitle: "Switzerland Franc",
    code: "CHF",
    symbol: "CHF",
  },
  {
    currencyTitle: "Suriname Dollar",
    code: "SRD",
    symbol: "$",
  },
  {
    currencyTitle: "Syria Pound",
    code: "SYP",
    symbol: "£",
  },
  {
    currencyTitle: "Taiwan New Dollar",
    code: "TWD",
    symbol: "NT$",
  },
  {
    currencyTitle: "Thailand Baht",
    code: "THB",
    symbol: "฿",
  },
  {
    currencyTitle: "Trinidad and Tobago Dollar",
    code: "TTD",
    symbol: "TT$",
  },
  {
    currencyTitle: "Turkey Lira",
    code: "TRY",
    symbol: "₺",
  },
  {
    currencyTitle: "Tuvalu Dollar",
    code: "TVD",
    symbol: "$",
  },
  {
    currencyTitle: "Ukraine Hryvnia",
    code: "UAH",
    symbol: "₴",
  },
  {
    currencyTitle: "UAE-Dirham",
    code: "AED",
    symbol: "د.إ",
  },
  {
    currencyTitle: "United Kingdom Pound",
    code: "GBP",
    symbol: "£",
  },
  {
    currencyTitle: "United States Dollar",
    code: "USD",
    symbol: "$",
  },
  {
    currencyTitle: "Uruguay Peso",
    code: "UYU",
    symbol: "$U",
  },
  {
    currencyTitle: "Uzbekistan Som",
    code: "UZS",
    symbol: "лв",
  },
  {
    currencyTitle: "Venezuela Bolívar",
    code: "VEF",
    symbol: "Bs",
  },
  {
    currencyTitle: "Viet Nam Dong",
    code: "VND",
    symbol: "₫",
  },
  {
    currencyTitle: "Yemen Rial",
    code: "YER",
    symbol: "﷼",
  },
  {
    currencyTitle: "Zimbabwe Dollar",
    code: "ZWD",
    symbol: "Z$",
  },
]
const currency = [...currencyArr].map((_, index) => {
  return {
    id: faker.datatype.uuid(),
    currencyTitle: currencyArr[index].currencyTitle,
    symbol: currencyArr[index].symbol,
    code: currencyArr[index].code,
  }
})
export default currency
