export const FETCHROLES_ON_SUCCESS = "FETCHROLES_ON_SUCCESS";
export const FETCHROLES_ON_FAILURE = "FETCHROLES_ON_FAILURE";
export const CREATEROLE_ON_SUCCESS = "CREATEROLE_ON_SUCCESS";
export const CREATEROLE_ON_FAILURE = "CREATEROLE_ON_FAILURE";
export const FETCHROLE_ON_SUCCESS = "FETCHROLE_ON_SUCCESS";
export const FETCHROLE_ON_FAILURE = "FETCHROLE_ON_FAILURE";
export const DELETEROLE_ON_FAILURE = "DELETEROLE_ON_FAILURE";
export const DELETEROLE_ON_SUCCESS = "DELETEROLE_ON_SUCCESS";
export const UPDATEROLE_ON_SUCCESS = "UPDATEROLE_ON_SUCCESS";
export const UPDATEROLE_ON_FAILURE = "UPDATEROLE_ON_FAILURE";
export const COMPLETEROLE_ON_SUCCESS = "COMPLETEROLE_ON_SUCCESS";
export const COMPLETEROLE_ON_FAILURE = "COMPLETEROLE_ON_FAILURE";
export const ARCHIVEDROLE_ON_SUCCESS = "ARCHIVEDROLE_ON_SUCCESS";
export const ARCHIVEDROLE_ON_FAILURE = "ARCHIVEDROLE_ON_FAILURE";
export const RESET_FLAGS = "RESET_FLAGS"
export const ROLESKILLS_ON_SUCCESS = "ROLESKILLS_ON_SUCCESS"
export const ROLESKILLS_ON_FAILURE = "ROLESKILLS_ON_FAILURE"
export const DELETE_SELECTED_ROLLS_ON_SUCCESS = 'DELETE_SELECTED_ROLLS_ON_SUCCESS'
export const DELETE_SELECTED_ROLLS_ON_FAILURE = 'DELETE_SELECTED_ROLLS_ON_FAILURE'
export const RESET_CREATED = "RESET_CREATED"