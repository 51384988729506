export function planDataParsing(planTier) {
  const PLANS = [...planTier]?.map((key, index) => {
    const basicYealy = 598.8
    const proYearly = 1197
    const proPlusYearly = 2094

    return {
      id: planTier[index]?.id,
      sequence_number: planTier[index]?.sequence_number,
      name: planTier[index]?.nickname,
      price: planTier[index]?.price,
      status: planTier[index]?.active,
      currency: planTier[index]?.currency,
      interval: planTier[index]?.interval,
      billing_scheme: planTier[index]?.billing_scheme,
      per_unit_price: planTier[index]?.per_unit_price,
      total_interviews: planTier[index]?.interview_count,
      plan_type: planTier[index]?.plan_type,
      tier: null,
      saving:
        (key?.plan_type === "basic_yearly" ? basicYealy * 0.15 : "") ||
        (key?.plan_type === "pro_yearly" ? proYearly * 0.15 : "") ||
        (key?.plan_type === "pro_plus_yearly" ? proPlusYearly * 0.15 : ""),
    }
  })
  return PLANS
}

export function departmentDataParsing(departments) {
  const dept = [...departments]?.map((_, index) => {
    return {
      id: departments[index]?.id,
      name: departments[index]?.name,
      description: departments[index]?.description,
      role: departments[index]?.roles,
      skills: departments[index]?.skills,
    }
  })

  return dept
}

export function questionsDataParsing(question_sets) {
  const questionsSets = [...question_sets].map((questionSet) => {
    return {
      id: questionSet?.id,
      name: questionSet?.name,
      creator: questionSet?.creator_id,
      interviewCount: questionSet?.interviews_count,
      qustionTypeId: questionSet?.question_type_id,
      isEditable: questionSet?.is_editable,
      isVersioned: questionSet?.is_versioned,
      interviewID: questionSet?.interview_id,
      createdDate: questionSet?.created_at,
      updatedDate: questionSet?.updated_at?.split("T")[0],
      totalQuestions: questionSet?.total_questions,
      questions: questionSet?.questions?.map((question) => question),
      department: questionSet?.department,
    }
  })

  return questionsSets
}

export function questionSetParsing(createQuestions) {
  let qSet = []
  let titles = []
  let quests = []
  createQuestions?.data?.question_sets?.map((obj) => {
    titles.push(obj?.name)
    obj.questions.map((text) => quests?.push(text?.text))

    qSet.push({
      value: quests,
      label: titles,
    })
    titles = []
    quests = []
    return ""
  })
  return qSet
}

export function roleParsing(rolesArr) {
  const rolesList = [...rolesArr]?.map((_, index) => {
    const setIndex = index + 1
    return {
      id: rolesArr[index]?.id,
      key: rolesArr[index]?.key,
      code: rolesArr[index]?.code,
      title: rolesArr[index]?.title,
      updatedDate: rolesArr[index]?.updated_at,
      createdDate: rolesArr[index]?.created_at,
      isArchive: rolesArr[index]?.archive,
      relatedDepartmentId: rolesArr[index]?.department_id,
      completionCommment: rolesArr[index]?.completion_comment,
      isHired: rolesArr[index]?.is_hired,
      roleUniqueId: rolesArr[index]?.uniq_id,
      salaryPeriod: rolesArr[index]?.salary_period,
      status: rolesArr[index]?.status,
      interviewsCount: rolesArr[index]?.interviews_count,
      isVideoUploading: rolesArr[index]?.is_video_uploading,
      videoMessage: rolesArr[index]?.video_message,
      creator: rolesArr[index]?.creator_id,
      isDisabled: rolesArr[index]?.disabled,
      reportTo: rolesArr[index]?.reports_to?.report_to,
      requiredExperience: rolesArr[index]?.required_experience,
      qualification: rolesArr[index]?.qualification,
      salaryRangeFrom: rolesArr[index]?.salary_range_from,
      salaryRangeTo: rolesArr[index]?.salary_range_to,
      salary_currency: rolesArr[index]?.salary_currency,
      interview_time: rolesArr[index]?.interview_time,
      timeLimit: rolesArr[index]?.time_limit,
      description: rolesArr[index]?.description,
      videoUrl: rolesArr[index]?.video_url,
      accountId: rolesArr[index]?.account_id,
      questionSetId: rolesArr[index]?.question_set_id,
      isEditable: rolesArr[index]?.is_editable,
      yearsExperience: rolesArr[index]?.years_experience,
      maxExperience: rolesArr[index]?.max_experience,
      roleHasSkills: rolesArr[index]?.skills[setIndex + 1],
      interviewers: rolesArr[index]?.interviewers[setIndex + 1],
      rolesDepartmentName: rolesArr[index]?.department?.name,
      rolesDepartmentDescription: rolesArr[index]?.department?.description,
      hired: rolesArr[index]?.vacancy_hired_candidates,
    }
  })

  return rolesList
}

export function permissionDataParsing(permissionArr) {
  const permissionList = [...permissionArr].map((user, index) => {
    return {
      id: user?.key,
      user: user?.name,
      default: user?.default,
      auto: user?.auto,
      // dashboard module
      dashboard_module: user?.permission?.dashboard_module,

      dashboard_active_vacancies_counter:
        user?.permission?.dashboard_active_vacancies_counter,
      dashboard_active_users_counter:
        user?.permission?.dashboard_active_users_counter,
      dashboard_interview_pending_counter:
        user?.permission?.dashboard_interview_pending_counter,
      dashboard_interview_awaiting_feedbacks_counter:
        user?.permission?.dashboard_interview_awaiting_feedbacks_counter,
      dashboard_question_sets_counter:
        user?.permission?.dashboard_question_sets_counter,
      dashboard_popular_skills: user?.permission?.dashboard_popular_skills,
      dashboard_candidate_ratings:
        user?.permission?.dashboard_candidate_ratings,
      dashboard_interviews_by_status:
        user?.permission?.dashboard_interviews_by_status,
      dashboard_completed_interviews_graph:
        user.permission?.dashboard_completed_interviews_graph,
      dashboard_completed_interviews_list:
        user.permission?.dashboard_completed_interviews_list,
      dashboard_completed_interviews_counter:
        user.permission?.dashboard_completed_interviews_counter,

      // question module

      question_sets_module: user?.permission?.question_sets_module,
      question_sets_search_bar: user?.permission?.question_sets_search_bar,
      question_sets_add: user?.permission?.question_sets_add,
      question_sets_update: user?.permission?.question_sets_update,
      question_sets_delete: user?.permission?.question_sets_delete,
      question_sets_departments_dropdown_filter:
        user?.permission?.question_sets_departments_dropdown_filter,

      // vacany modul
      vacancy_module: user?.permission?.vacancy_module,
      vacancy_search_bar: user?.permission?.vacancy_search_bar,
      vacancy_departments_dropdown_filter:
        user?.permission?.vacancy_departments_dropdown_filter,
      vacancy_add_new_vacancy: user?.permission?.vacancy_add_new_vacancy,
      vacancy_active_vacancy_edit_vacancy:
        user?.permission?.vacancy_active_vacancy_edit_vacancy,
      vacancy_archive_vacancy_delete_vacancy:
        user?.permission?.vacancy_archive_vacancy_delete_vacancy,

      vacancy_active_vacancy_view_candidates:
        user?.permission?.vacancy_active_vacancy_view_candidates,
      vacancy_active_vacancy_complete_vacancy:
        user?.permission?.vacancy_active_vacancy_complete_vacancy,
      vacancy_completed_vacancy_tab:
        user?.permission?.vacancy_completed_vacancy_tab,

      vacancy_completed_vacancy_view_candidates:
        user?.permission?.vacancy_completed_vacancy_view_candidates,
      vacancy_completed_vacancy_archive_vacancy:
        user?.permission?.vacancy_completed_vacancy_archive_vacancy,
      vacancy_archived_vacancy_tab:
        user?.permission?.vacancy_archived_vacancy_tab,
      vacancy_active_vacancy_view_interviews:
        user?.permission?.vacancy_active_vacancy_view_interviews,
      user_can_view_vacancy_salary:
        user?.permission?.user_can_view_vacancy_salary,

      // interview module
      interviews_module: user?.permission?.interviews_module,
      interviews_search_bar: user?.permission?.interviews_search_bar,
      interviews_departments_dropdown_filter:
        user?.permission?.interviews_departments_dropdown_filter,

      interviews_schedule_interview_action:
        user?.permission?.interviews_schedule_interview_action,
      interviews_schedule_interview_create_interviewer_action:
        user?.permission
          ?.interviews_schedule_interview_create_interviewer_action,
      interviews_schedule_follow_up_action:
        user?.permission?.interviews_schedule_follow_up_action,
      // interviews_schedule_give_feedback:
      //   user?.permission?.interviews_schedule_give_feedback,

      interviews_schedule_interviews_edit_interview:
        user?.permission?.interviews_schedule_interviews_edit_interview,

      interviews_schedule_interviews_delete_interview:
        user?.permission?.interviews_schedule_interviews_delete_interview,
      interviews_schedule_interviews_resend_invitation:
        user?.permission?.interviews_schedule_interviews_resend_invitation,
      interviews_completed_interviews_tab:
        user?.permission?.interviews_completed_interviews_tab,
      interviews_completed_interviews_feedback_required:
        user?.permission?.interviews_completed_interviews_feedback_required,

      interviews_completed_interviews_archive_interview:
        user?.permission?.interviews_completed_interviews_archive_interview,

      interviews_completed_interviews_candidate_history:
        user?.permission?.interviews_completed_interviews_candidate_history,

      interviews_archived_interviews_tab:
        user?.permission?.interviews_archived_interviews_tab,

      interviews_archived_interviews_delete_interview:
        user?.permission?.interviews_archived_interviews_delete_interview,

      interviews_expired_interviews_tab:
        user?.permission?.interviews_expired_interviews_tab,

      interviews_expired_interviews_delete_interview:
        user?.permission?.interviews_expired_interviews_delete_interview,

      // candidate module

      candidates_module: user?.permission?.candidates_module,

      // candidates_departments_dropdown_filter:
      //   user?.permission?.candidates_departments_dropdown_filter,

      // candidates_provide_feedback_action:
      //   user?.permission?.candidates_provide_feedback_action,

      candidates_user_can_view_feedback:
        user?.permission?.candidates_user_can_view_feedback,

      candidates_user_can_search_for_candidates:
        user?.permission?.candidates_user_can_search_for_candidates,

      candidates_user_can_give_recommendations:
        user?.permission?.candidates_user_can_give_recommendations,

      candidates_user_can_view_recommendations:
        user?.permission?.candidates_user_can_view_recommendations,

      candidates_user_can_edit_comments:
        user?.permission?.candidates_user_can_edit_comments,

      candidates_user_can_delete_comments:
        user?.permission?.candidates_user_can_delete_comments,

      candidates_user_can_add_comments_to_interview:
        user?.permission?.candidates_user_can_add_comments_to_interview,

      candidates_user_can_review_candidates:
        user?.permission?.candidates_user_can_review_candidates,

      //company user moule
      company_user_module: user?.permission?.company_user_module,
      company_user_user_can_add_company_users:
        user?.permission?.company_user_user_can_add_company_users,

      company_user_user_can_edit_company_users:
        user?.permission?.company_user_user_can_edit_company_users,

      company_user_user_can_delete_company_users:
        user?.permission?.company_user_user_can_delete_company_users,

      company_user_user_can_search_company_users:
        user?.permission?.company_user_user_can_search_company_users,

      //department module
      departments_module: user?.permission?.departments_module,
      departments_user_can_add_department:
        user?.permission?.departments_user_can_add_department,
      departments_user_can_delete_department:
        user?.permission?.departments_user_can_delete_department,
      departments_user_can_edit_department:
        user?.permission?.departments_user_can_edit_department,
      departments_user_can_search_department:
        user?.permission?.departments_user_can_search_department,

      //setting module
      settings_module: user?.permission?.settings_module,
      settings_user_can_edit_the_company_profile:
        user?.permission?.settings_user_can_edit_the_company_profile,

      settings_user_can_view_email:
        user?.permission?.settings_user_can_view_email,

      settings_user_can_edit_email_templates:
        user?.permission?.settings_user_can_edit_email_templates,

      settings_user_can_edit_permissions:
        user?.permission?.settings_user_can_edit_permissions,

      settings_user_can_view_permissions:
        user?.permission?.settings_user_can_view_permissions,

      settings_user_can_add_member_type:
        user?.permission?.settings_user_can_add_member_type,

      settings_user_can_add_or_edit_the_card:
        user?.permission?.settings_user_can_add_or_edit_the_card,

      settings_user_can_view_subscribe:
        user?.permission?.settings_user_can_view_subscribe,
      settings_user_can_subscribe_to_other_plans:
        user?.permission?.settings_user_can_subscribe_to_other_plans,

      settings_user_can_view_charges:
        user?.permission?.settings_user_can_view_charges,

      settings_user_can_download_charges_receipt:
        user?.permission?.settings_user_can_download_charges_receipt,
      settings_user_can_contact_the_super_admin:
        user?.permission?.settings_user_can_contact_the_super_admin,
    }
  })

  return permissionList
}

export function teamDataParsing(users) {
  const teams = [...users]?.map((user) => {
    return {
      id: user?.id,
      key: user?.key,
      avatarUrl: user?.image,
      name: user?.full_name,
      first_name: user?.first_name,
      last_name: user?.last_name,
      user_type: user?.user_type,
      email: user?.email,
      department: user?.departments_name?.map((name) => name),
      company: user?.account?.company_name,
      status: user?.is_active,
      role: user?.profile?.name,
    }
  })

  return teams
}

export function candidateDataParsing(candidates) {
  const candidate = [...candidates]?.map((data) => {
    return {
      id: data?.id,
      key: data?.key,
      name: data?.first_name + " " + data?.last_name,
      email: data?.email,
      candidate_vacancies: [
        ...data?.candidate_vacancies?.map((item) => {
          return {
            roleAppliedFor: item?.vacancy?.title,
            vacancyId: item?.vacancy?.id,
            interviews: [
              ...item.interviews.map((user) => {
                return {
                  interview: user?.interview_type,
                  date: user?.created_at,
                  status: user?.status,
                }
              }),
            ],
          }
        }),
      ],
    }
  })
  return candidate
}

export function chargesDataParsing(invoices) {
  const newInvoice = [
    ...invoices?.map((invoice, index) => {
      return {
        firstName: invoice?.first_name,
        lastName: invoice?.last_name,
        customerName: invoice?.customer_name,
        accountName: invoice?.account_name,
        email: invoice?.customer_email,
        amountPaid: invoice?.amount_paid,
        paidOn: invoice?.status_transitions?.paid_at,
        description: invoice?.lines?.data[0]?.description,
        card: invoice?.number,
        status: invoice?.status,
        paidCheck: invoice?.paid,
        receiptPdf: invoice?.invoice_pdf,
      }
    }),
  ]

  return newInvoice
}

export function interviewDataParsing(interviews) {
  const interviewsArr = [...interviews]?.map((intvue) => {
    return {
      id: intvue?.id,
      intVuekey: intvue?.key,
      candidateKey: intvue?.candidate_vacancy?.candidate?.key,
      firstName: intvue?.candidate_vacancy?.candidate?.first_name,
      lastName: intvue?.candidate_vacancy?.candidate?.last_name,
      candidateEmail: intvue?.candidate_vacancy?.candidate.email,
      roleApplied: intvue?.candidate_vacancy?.vacancy?.title,
      vacancyId: intvue?.candidate_vacancy?.vacancy?.id,
      intType: intvue?.interview_type,
      intStatus: intvue?.status,
      intDate: intvue?.scheduled_on,
      intExpireOn: intvue?.expires_on,
      intCompletedOn: intvue?.completed_on,
      intVote: intvue?.votes_count,
      refCode: intvue?.code,
      intVueFormat: intvue?.interview_format,
      department: intvue?.candidate_vacancy?.vacancy?.department?.name,
      expiresOnConverted: intvue?.expires_on_converted,
      timeZone: intvue?.timezone,
      intTime: intvue?.interview_time,
      intRecomdation: intvue?.recommend_and_feedback?.filter(
        (r) => r.is_recommended === true,
      ),
      // const [isShowingModal, toggleModal] = useModal()

      //   return (
      //     <Box
      //       className='listing'
      //       sx={{
      //         mb: 1,
      //         pb: 1,
      //       }}
      //     >
      //       <Typography
      //         variant='body1'
      //         sx={{
      //           marginBottom: "5px",
      //           fontWeight: "600 !important",
      //           wordWrap: "normal",
      //           // wordBreak: "break-word",
      //         }}
      //       >
      //         {rec?.user?.first_name + " " + rec?.user?.last_name}
      //       </Typography>

      //       <Typography
      //         variant='body1'
      //         sx={{
      //           mb: { xs: 2, sm: 0 },
      //           display: "flex",
      //           alignItems: "center",
      //         }}
      //       >
      //         <Rating name='read-only' value={rec?.rating} readOnly />
      //       </Typography>
      //       <div>
      //         <Label
      //           sx={{
      //             fontWeight: "600 !important",
      //             my: 1,
      //             marginBottom: "5px",
      //           }}
      //           variant='ghost'
      //           color={rec?.is_recommended ? "success" : "error"}
      //         >
      //           {rec?.is_recommended ? "Recommended" : "Not Recommended"}
      //         </Label>
      //       </div>
      //       {rec?.text && (
      //         <Typography
      //           variant='body1'
      //           component='p'
      //           sx={{
      //             mt: 1,
      //             mb: 1,
      //             borderLeft: "4px solid gray",
      //             wordBreak: "break-word",
      //             pl: 1,
      //           }}
      //         >
      //           {rec?.text}
      //         </Typography>
      //       )}
      //       {rec?.feedback_details &&
      //         <Button
      //           variant='outlined'
      //           sx={{ mt: 1 }}
      //           color='info'
      //           size='small'
      //           onClick={toggleModal}
      //         >
      //           Details
      //         </Button>
      //       }
      //       <ViewFeedbackModal
      //         hide={toggleModal}
      //         isShowingViewFeedback={isShowingModal}
      //         data={rec}
      //       />
      //     </Box>
      //   )
      // }),
      intNotRecomdation: intvue?.recommend_and_feedback?.filter(
        (r) => r.is_recommended !== true,
      ),
    }
  })
  return interviewsArr
}

export function dashboardDataParsing(dashboard) {
  if (!dashboard) {
    return []
  }
  const dashboardArr = [...dashboard]?.map((user) => {
    return {
      id: user?.id,
      key: user?.key,
      interviewFormat: user?.interview_format,
      name:
        user?.candidate_vacancy?.candidate?.first_name +
        " " +
        user?.candidate_vacancy?.candidate?.last_name,
      email: user?.candidate_vacancy?.candidate?.email,
      role: user?.candidate_vacancy?.vacancy?.title,
      scheduleDate: user?.scheduled_on,
      completedOn: user?.completed_on,
      intType: user?.interview_type,
      intStatus: user?.status,
      intDate: user?.scheduled_on,
      intExpireOn: user?.expires_on,
      intVote: user?.votes_count,
      archive: user?.archive,
      refCode: user?.code,
      department: user?.candidate_vacancy?.vacancy?.department?.name,
      qualification: user?.candidate_vacancy?.candidate?.qualification,
      skills: user?.candidate_vacancy?.candidate?.skills,
      experience: user?.candidate_vacancy?.candidate?.experience,
    }
  })

  return dashboardArr
}

export function candidateHistroy(candidateData) {
  const list = [...candidateData]?.map((data) => {
    return {
      vacancy: data?.vacancy,
      interviews: [
        ...data?.interviews?.map((user) => {
          return {
            interviewFormat: user.interview_format,
            interviewType: user.interview_type,
            scheduleDate: user.scheduled_on,
            completedOn: user.completed_on,
            votes: user.votes_count[0],
          }
        }),
      ],
    }
  })
  return list
}
