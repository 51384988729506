import * as ActionTypes from "../constants/addMember"

const initialState = { data: [], message: "", status: 200 }
const addMemberReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.ADD_MEMBER_ON_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
      status: action.payload.status,
    }
  }
  return state
}
export default addMemberReducer
