import PropTypes from "prop-types"
import React from "react"
import { styled } from "@mui/material/styles"
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  Autocomplete,
  TextField,
  OutlinedInput,
  InputAdornment,
  Stack,
} from "@mui/material"
import { Box } from "@mui/system"
import { useTranslation } from "react-i18next"
import RefreshIcon from "@mui/icons-material/Refresh"
import SearchIcon from "@mui/icons-material/Search"
import DeleteIcon from "@mui/icons-material/Delete"
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
  [theme.breakpoints.down("sm")]: {
    height: "auto !important",
    padding: theme.spacing(1, 2, 1, 2),
    alignItems: "flex-start",
    flexDirection: "column",
  },
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "&.Mui-focused": {
      width: "100%",
    },
    marginBottom: "0.4rem",
  },
}))

// ----------------------------------------------------------------------

QuestionListToolbar.propTypes = {
  numSelected: PropTypes.number,
  searchFilerName: PropTypes.string,
  onFilterName: PropTypes.func,
  onFilterDeptName: PropTypes.func,
  questions: PropTypes.array,
  questCrud: PropTypes.array,
}

export default function QuestionListToolbar({
  numSelected,
  searchFilerName,
  onFilterSearch,
  onFilterDeptName,
  departments,
  refreshQuestion,
  canSearch = true,
}) {
  const { t } = useTranslation()
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      <Box sx={{ width: { xs: "100%", sm: "auto" } }}>
        {numSelected > 0 ? (
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography component='div' variant='subtitle1'>
              {numSelected} selected
            </Typography>

            <Tooltip title='Delete'>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        ) : (
          canSearch && (
            <SearchStyle
              id='search'
              value={searchFilerName}
              onChange={onFilterSearch}
              placeholder={t("Search")}
              startAdornment={
                <InputAdornment position='start'>
                  <SearchIcon
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
            />
          )
        )}
      </Box>
      <Box
        sx={{
          justifyContent: { xs: "flex-start", sm: "center" },
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <Autocomplete
          onChange={(event, newValue, reason) => {
            onFilterDeptName(newValue)
            if (reason === "clear") {
              onFilterDeptName("")
            }
          }}
          id='controllable-states-demo'
          options={departments?.filter((element) => element !== undefined)}
          sx={{ width: { xs: "80%", sm: 150, md: 300 } }}
          renderInput={(params) => (
            <TextField {...params} placeholder={t("AllDepartments")} />
          )}
        />
        <Tooltip title={t("Refresh")} onClick={refreshQuestion}>
          <IconButton>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </RootStyle>
  )
}
