import React, { useRef, useState } from "react"
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { deleteMembers } from "src/redux/actions/member"

import AddMemberModal from "./modal/addMemberModal"
import DeleteInterviewModal from "src/sections/@dashboard/interview/modal/deleteInterview/DeleteInterviewModal"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import { resendMemberInvitation } from "src/redux/actions/member"
import OwnershipModal from "./modal/transfersOwnership"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
// ----------------------------------------------------------------------

export default function UserMoreMenu({
  id,
  flag,
  tabing,
  canDelete = true,
  canEdit = true,
}) {
  const { t } = useTranslation()
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [matchedData, setMatchedData] = useState()
  const [isShowingMember, toggleMember] = useModal()
  const [isShowingDeleteInt, toggleDeleteInt] = useModal()
  const [isShowingOwnersip, toggleOwnership] = useModal()
  const memberCrud = useSelector((state) => state.members)
  const dispatch = useDispatch()

  const currentAccount = useSelector((state) => state.login)
  const matchedMember = (id) => {
    for (let i = 0; i < memberCrud?.data?.users.length; i++) {
      if (id === memberCrud?.data?.users[i]?.id) {
        return [memberCrud?.data?.users[i], i]
      }
    }
  }
  const handleDelete = () => {
    toggleDeleteInt()
    const [match, index] = matchedMember(id)
    setMatchedData(match)
    setIsOpen(false)
  }

  const handleEdit = () => {
    toggleMember()
    const [match, index] = matchedMember(id)
    setMatchedData(match)
    setIsOpen(false)
  }
  const handleOwnershipTransfer = () => {
    toggleOwnership()
    const [match, index] = matchedMember(id)
    setMatchedData(match)
    setIsOpen(false)
  }

  const handleResendInvitation = () => {
    const [match, index] = matchedMember(id)
    dispatch(resendMemberInvitation(match.key))
    setIsOpen(false)
  }

  const isAdmin = () => {
    return (
      currentAccount?.user?.user_type === "admin" &&
      id === currentAccount?.user?.id
    )
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {tabing === "inactive" && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            disable
            onClick={handleResendInvitation}
          >
            <ListItemIcon>
              <ForwardToInboxIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t("ResendInvitation")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {canEdit && (
          <MenuItem sx={{ color: "text.secondary" }} onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon width={24} height={24} fontSize='medium' />
            </ListItemIcon>
            <ListItemText
              primary={t("Edit")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {canDelete && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            disable
            onClick={handleDelete}
          >
            <ListItemIcon>
              <DeleteIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t("Delete")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {isAdmin() && (
          <MenuItem
            sx={{ color: "text.secondary" }}
            disable
            onClick={handleOwnershipTransfer}
          >
            <ListItemIcon>
              <AdminPanelSettingsIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t("Ownership Transfer")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>

      <AddMemberModal
        isShowingMember={isShowingMember}
        hide={toggleMember}
        flag={false}
        data={matchedData}
      />
      <DeleteInterviewModal
        isShowingDeleteInt={isShowingDeleteInt}
        hide={toggleDeleteInt}
        data={matchedData}
        invoke={"member"}
      />
      <OwnershipModal
        isShowingOwnersip={isShowingOwnersip}
        hide={toggleOwnership}
      />
    </>
  )
}
