import { useState } from "react"
import {
  Link as RouterLink,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom"
import { styled } from "@mui/material/styles"
import DashboardNavbar from "./DashboardNavbar"
import DashboardSidebar from "./DashboardSidebar"
import { useAuth } from "src/hooks/useAuth"
import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import CookieConsentBanner from "src/components/CookieConsentBanner"

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  background: "#ebebeb",
})

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    paddingLeft: "calc(100vw - 100%)",
    paddingRight: theme.spacing(2),
  },
}))

export default function DashboardLayout() {
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)

  const { t } = useTranslation()
  const location = useLocation()

  const { user, isExpiredSubscription } = useAuth()

  const renderComponent = () => {
    if (!user) {
      return <Navigate to='/login' />
    }
    if (isExpiredSubscription) {
      if (location?.pathname === "/dashboard/settings") {
        return <Outlet />
      }

      return (
        <Box sx={{ mx: 2, p: 1 }}>
          {t("Your subscription has expired, please renew your subscription.")}
          <p>
            <Button
              variant='contained'
              component={RouterLink}
              to='/dashboard/settings'
              sx={{ mt: 1 }}
            >
              {t("GotToSettings")}
            </Button>
          </p>
        </Box>
      )
    }
    return <Outlet />
  }

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
      <DashboardSidebar
        selectedItem={selectedItem}
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle>{renderComponent()}</MainStyle>
      <CookieConsentBanner />
    </RootStyle>
  )
}
