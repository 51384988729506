import React, { useState, useEffect } from "react"
import { styled } from "@mui/material/styles"
import {
  Box,
  Button,
  Container,
  FormLabel,
  TextField,
  Typography,
  FormControl,
} from "@mui/material"
import LiveHeader from "../../liveHeader"
import Page from "src/components/Page"
import useResponsive from "src/hooks/useResponsive"
import { Navigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getInterviewDetail } from "src/redux/actions/interviews"
import Loader from "src/components/Loader"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"

const RootStyle = styled("div")(({ theme }) => ({
  border: " 1px solid #e2e8f0",
  background: "white",
  boxSizing: "border-box",
  borderRadius: " 10px",
  width: "100%",
}))

const Panel = styled("div")(({ theme }) => ({
  padding: "1.8rem 2.8rem 1rem 2.8rem ",
}))

export default function CandidateInterviewHome() {
  const dispatch = useDispatch()
  const { interviewId: paramInterViewId, isInterviewer: isInterviewer } =
    useParams()
  const [interviewDetails, setInterviewDetails] = useState(null)
  const [interviewId, setInterviewId] = useState(paramInterViewId ?? "")
  const [isCompleted, setIsCompleted] = useState(false)
  const [isVerificationFailed, setIsVerificationFailed] = useState(false)
  const [verificationFailedMessage, setVerificationFailedMessage] = useState("")
  const isDesktop = useResponsive("up", "lg")

  const [isLoading, setIsLoading] = useState(false)
  const [color, setColor] = useState("#0066E9")

  const getInterviewDetailsData = useSelector(
    (state) => state.getInterviewDetail,
  )

  const fetchInterviewDetails = () => {
    if (interviewId && !isLoading) {
      setIsLoading(true)
      setIsCompleted(false)
      setIsVerificationFailed(false)
      dispatch(getInterviewDetail(interviewId, isInterviewer))
    }
  }

  useEffect(() => {
    fetchInterviewDetails()
  }, [])

  useEffect(() => {
    const interviewData = getInterviewDetailsData?.data

    if (interviewData) {
      setIsLoading(false)

      if (interviewData.success === true) {
        const interview = interviewData?.interview
        setInterviewDetails(interview)
      } else if (interviewData.success === false) {
        const message = interviewData.message ? interviewData.message : ""
        if (message.includes("panel")) {
          window.open(`${process.env.REACT_APP_FE_BASE_URL}/Home/app`, "_self")
        } else {
          setIsCompleted(
            message.includes("completed") || message.includes("progress"),
          )
          setIsVerificationFailed(
            message.includes("failed") || message.includes("expired"),
          )

          if (message) {
            setVerificationFailedMessage(message)
          } else {
            setVerificationFailedMessage("")
          }
        }
      }
    }
  }, [getInterviewDetailsData])

  const interviewIdTextFieldDidChange = (event) => {
    setInterviewId(event.target.value)
  }

  const verifyButtonPressed = () => {
    if (interviewId !== "") {
      fetchInterviewDetails()
    } else {
      alert("Please enter interview id.")
    }
  }

  if (interviewDetails) {
    return (
      <Navigate
        to='/candidate-interview-detail'
        replace={true}
        state={{ interview: interviewDetails, isInterviewer: isInterviewer }}
      />
    )
  }

  return (
    <Page title='Intvue'>
      <Container
        maxWidth='md'
        spacing={3}
        sx={{
          minHeight: " 100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <Loader respLoading={isLoading} />
        ) : (
          <RootStyle className='welcometoIntvuw'>
            <Panel sx={{ textAlign: "center" }}>
              <LiveHeader />
              <form className='starter-form'>
                <FormControl sx={{ width: "80%" }}>
                  {isCompleted && (
                    <Typography
                      variant={isDesktop ? "h3" : "h4"}
                      sx={{ color: "#1E293B", fontWeight: 600, mb: 3 }}
                    >
                      {verificationFailedMessage}
                    </Typography>
                  )}

                  {isLoading && (
                    <>
                      {/* <Typography
                        variant={isDesktop ? "h3" : "h4"}
                        sx={{ color: "#1E293B", fontWeight: 600, mb: 3 }}
                      >
                        Please wait we are setting up your interview...
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mb: 3,
                        }}
                      >
                        <PulseLoader
                          color={color}
                          loading={true}
                          size={16}
                          speedMultiplier='1'
                        />
                      </Box> */}
                    </>
                  )}
                </FormControl>

                {!isCompleted && (
                  <FormControl
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <FormLabel sx={{ marginRight: "1.6rem", fontWeight: 600 }}>
                      Interview ID
                    </FormLabel>
                    <TextField
                      type='text'
                      id='interviewId'
                      //  placeholder='Enter Interview ID '
                      variant='outlined'
                      autoComplete='title'
                      value={interviewId}
                      name='roleTitle'
                      required
                      onChange={interviewIdTextFieldDidChange}
                      disabled
                    />
                  </FormControl>
                )}

                {isVerificationFailed && (
                  <Typography
                    variant='subtitle2'
                    sx={{ mt: 2, mb: 3, fontWeight: 500, color: "red" }}
                  >
                    <WarningAmberIcon
                      color='#dc2626'
                      sx={{ mr: 1, fontSize: "22px", mb: "-6px" }}
                    />
                    {verificationFailedMessage
                      ? verificationFailedMessage
                      : "Sorry we are unable to verify your interview id. Please contact our support team."}
                  </Typography>
                )}
              </form>
            </Panel>
          </RootStyle>
        )}
      </Container>
    </Page>
  )
}
