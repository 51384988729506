import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material"

import { useTranslation } from "react-i18next"
import { onsiteIntStatus } from "src/redux/actions/interviews"
import { useDispatch } from "react-redux"
import { useState } from "react"
import ConfirmationModal from "../../roles/modal/ConfirmationModal"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"

export default function UpdateStatusModal({ isShowingModal, hide, intKey }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [isShowingConfirmModal, setIsShowingConfirm] = useModal()
  const [status, setStatus] = useState("")

  const updateStatus = () => {
    const payload = {
      status: status,
    }
    dispatch(onsiteIntStatus(payload, intKey))
    setStatus("")
    hide()
  }

  return (
    <>
      {isShowingModal && (
        <Dialog
          className='dialog400'
          open={isShowingModal}
          onClose={hide}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          scroll='paper'
          maxWidth='md'
        >
          <DialogTitle
            className='dialog-title'
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("Update interview status")}
          </DialogTitle>
          <DialogContent className='dialog-content' dividers={true}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: "left" }}>
                <Typography
                  variant='p'
                  component='p'
                  className='dialog-txt'
                  sx={{ paddingLeft: "5px", paddingRight: "5px" }}
                >
                  Please select a status from the following
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className='dialog-footer' sx={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                setStatus("completed")
                setIsShowingConfirm()
              }}
              variant='outlined'
              color='primary'
              size='medium'
            >
              {t("Completed")}
            </Button>

            <Button
              onClick={() => {
                setStatus("expired")
                setIsShowingConfirm()
              }}
              autoFocus
              variant='contained'
              color='secondary'
              size='medium'
            >
              {t("Expired")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <ConfirmationModal
        isShowingModal={isShowingConfirmModal}
        hide={setIsShowingConfirm}
        confirmAction={updateStatus}
        isStatus={true}
        message={`Are you sure you want to update status to ${status}?`}
      />
    </>
  )
}
