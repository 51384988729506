import * as ActionTypes from "../constants/needHelp";

const initialState = { data: [] };

const userNeedHelpReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.NEED_HELP_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    case ActionTypes.NEED_HELP_FAILURE:
      return {
        ...state,
        message: action.payload.data.errors,
      };

    default:
      return state;
  }
};

export default userNeedHelpReducer;
