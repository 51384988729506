import React, { useState, useEffect } from "react"
import {
  Button,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
} from "@mui/material"
import { capitalCase } from "change-case"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { getFilteredInterviews } from "src/redux/actions/interviews"
import { useTranslation } from "react-i18next"
import Scrollbar from "src/components/Scrollbar"
import { interviewDataParsing } from "src/utils/dataParsing"
import Label from "src/components/Label"
import PulseLoader from "react-spinners/PulseLoader"

export default function ViewAwaitsInt({ hide, open }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [interviews, setInterviews] = useState([])
  const [firstRender, setFirstRender] = useState(true)

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [isDataLoading, setIsDataLoading] = useState(false)

  const filterintVue = useSelector((state) => state.filteredInterview)
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const closeModal = () => {
    setFirstRender(true)
    hide()
  }

  useEffect(() => {
    if (open && firstRender) {
      setFirstRender(false)
      setIsDataLoading(true)
      dispatch(getFilteredInterviews({ type: "awaiting" }))
    } else if (open && !firstRender) {
      let interviews = filterintVue?.data?.interviews ?? []

      interviews = interviewDataParsing(interviews)
      setInterviews(interviews)
      setIsDataLoading(false)
    }
  }, [filterintVue, open])

  return (
    <Dialog
      className='dialog800'
      open={open}
      onClose={closeModal}
      scroll='paper'
      maxWidth='xl'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        {t("InterviewsAwaitingFeedback")}

        <IconButton
          aria-label='close'
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className='dialog-content'
        dividers={true}
        sx={{ borderTop: 0 }}
      >
        <Scrollbar>
          <TableContainer>
            <Table
              className='simple-table'
              sx={{ minWidth: 650 }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t("Candidate")} </TableCell>
                  <TableCell>{t("Email")}</TableCell>
                  <TableCell>{t("Vacancy")}</TableCell>
                  <TableCell>{t("Format")}</TableCell>
                  <TableCell>{t("ScheduledDate")}</TableCell>
                  <TableCell>{t("CompletedOn")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {isDataLoading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mb: 3,
                        }}
                      >
                        <PulseLoader
                          color={"#0066E9"}
                          loading={true}
                          size={16}
                          speedMultiplier='1'
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {interviews?.length > 0 ? (
                      interviews
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell component='th' scope='row'>
                              <Typography
                                variant='subtitle2'
                                sx={{
                                  textTransform: "capitalize",
                                }}
                                noWrap
                              >
                                {row?.firstName + " " + row?.lastName}
                              </Typography>
                            </TableCell>
                            <TableCell>{row?.candidateEmail}</TableCell>
                            <TableCell sx={{ width: 160, minWidth: 180 }}>
                              {row?.roleApplied}
                            </TableCell>
                            <TableCell>
                              {row?.intVueFormat === "on_site"
                                ? t("OnSite")
                                : t("Recorded")}
                            </TableCell>
                            <TableCell>{row?.intDate}</TableCell>
                            <TableCell>
                              {row?.intDate || row?.intExpireOn}
                            </TableCell>

                            <TableCell sx={{ textTransform: "capitalize" }}>
                              <Label
                                variant='ghost'
                                color={
                                  (row?.intStatus === "timed_out" && "error") ||
                                  "success"
                                }
                              >
                                {t(capitalCase(row?.intStatus))}
                              </Label>
                            </TableCell>
                          </TableRow>
                        ))
                    ) : (
                      <TableRow>
                        <TableCell className='searchCell' colSpan={10}>
                          <Typography variant='body2' align='left'>
                            {t("AwatiedIntNotFound")} &nbsp;
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component='div'
            count={interviews.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Scrollbar>
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button
          onClick={closeModal}
          variant='outlined'
          color='primary'
          size='medium'
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
