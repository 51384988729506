import Label from "src/components/Label"
import CardMedia from "@mui/material/CardMedia"
import { fDateShort } from "src/utils/formatTime"
import CardContent from "@mui/material/CardContent"
import { useVideoUpload } from "src/hooks/useVideoUpload"
import { Box, Typography, Card, Button } from "@mui/material"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import PlayArrowIcon from "@mui/icons-material/PlayArrow"
import PauseIcon from "@mui/icons-material/Pause"
import { useSelector, useDispatch } from "react-redux"
import { finishInterview } from "src/redux/actions/interviews"
import { currentBrowserName } from "src/utils/userHelper"

export default function VideoSlider({
  videos,
  questions,
  videoFilesSources,
  onVideoPlay,
  playingVideoSrc,
  playing,
  interviewCode,
  status,
  onRetry,
}) {
  const dispatch = useDispatch()
  const { startUploading } = useVideoUpload()
  const finishedInterview = useSelector((state) => state.finishInterview)

  const retryButtonClicked = (video) => {
    const vid = Object.assign(video, {})

    vid.uploadedOn = new Date()
    vid.progress = 0.0
    vid.uploaded = false
    vid.failed = false
    vid.failedErrorMessage = ""
    vid.fulfilled = false

    if (finishedInterview.isSucceed === false) {
      dispatch(finishInterview({ code: interviewCode, status: status }))
    }

    startUploading(vid, vid?.params)

    if (onRetry) {
      onRetry()
    }
  }

  const renderVideos = (videosList) => {
    return videosList?.map((video, index) => {
      const uploadingProgress = video?.progress
      const uploaded = video?.uploaded
      const failed = video?.failed
      const fulfilled = video?.fulfilled
      const uploadedOn = video?.uploadedOn ? fDateShort(video.uploadedOn) : ""
      const statusColor = uploaded ? "success" : failed ? "warning" : "info"
      const fileSrc = videoFilesSources[index] ?? ""
      const duration = new Date((video?.duration ?? 0) * 1000)
        .toISOString()
        .substring(14, 19)

      return (
        <Card
          key={index}
          className='media-card'
          onClick={() => onVideoPlay(fileSrc)}
        >
          <CardMedia
            component='div'
            sx={{
              width: 200,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "black",
            }}
          >
            <video
              // controls={video?.duration > 0}
              playsInline
              muted={true}
              loop
              width='100%'
              poster='/static/video-placeholder.png'
            >
              <source src={fileSrc + "#t=0.1"} />
            </video>

            <Box sx={{ position: "absolute" }}>
              {fileSrc === playingVideoSrc && playing ? (
                <PauseIcon
                  sx={{
                    width: "20px",
                    marginRight: "10px",
                    marginBottom: "-1px",
                  }}
                />
              ) : (
                <PlayArrowIcon
                  sx={{
                    width: "20px",
                    marginRight: "10px",
                    marginBottom: "-1px",
                  }}
                />
              )}
            </Box>
          </CardMedia>

          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxHeight: "150px",
              overflowY: "hidden",
            }}
          >
            <Box>
              <Typography
                component='h6'
                variant='h6'
                fontSize='14px'
                fontWeight='500'
                sx={{
                  lineHeight: "1.0em",
                  width: "100%",
                  display: "block",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                Q {index + 1}:{" "}
                {(questions[index]?.question?.text).substring(0, 30)}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                justifyContent: "space-between",
                mt: 1,
              }}
            >
              <Typography
                variant='subtitle2'
                sx={{
                  color: "#1E293B",
                  fontSize: 12,
                  lineHeight: "16px",
                  fontWeight: 500,
                  display: "inline-flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <CalendarTodayIcon
                  fontSize='small'
                  sx={{ width: { xs: "0.8rem", md: "1rem" }, mr: 1 }}
                />
                {uploadedOn}
              </Typography>

              <Typography
                variant='subtitle2'
                sx={{
                  color: "#1E293B",
                  fontSize: 12,
                  lineHeight: "16px",
                  fontWeight: 500,
                  display: "inline-flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <AccessTimeIcon fontSize='small' sx={{ mr: 1 }} />
                {duration}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Label
                variant='ghost'
                color={uploaded ? "success" : failed ? "warning" : "info"}
                startIcon={
                  uploaded ? (
                    <CheckCircleIcon color={statusColor} />
                  ) : (
                    <FiberManualRecordIcon color={statusColor} />
                  )
                }
              >
                {uploaded
                  ? "Uploaded"
                  : failed
                  ? "Failed"
                  : !fulfilled
                  ? "Uploading"
                  : "Unknown"}
                {!fulfilled && ` ${uploadingProgress} %`}
              </Label>

              {failed && (
                <Button
                  variant='outlined'
                  onClick={() => retryButtonClicked(video)}
                >
                  Retry
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>
      )
    })
  }

  return (
    <Box sx={{ marginTop: "20px", maxHeight: "440px", overflow: "auto" }}>
      {renderVideos(videos)}
    </Box>
  )
}
