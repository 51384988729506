import * as Yup from "yup"
import { useFormik, Form, FormikProvider } from "formik"
import React from "react"
import { Stack, TextField, Button, Alert } from "@mui/material"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { sendSuggestion } from "src/redux/actions/landing"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

export default function ContactForm() {
  const { t } = useTranslation()
  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t("TooShort!"))
      .max(30, t("TooLong!"))
      .required(t("ErrFnameRequired"))
      .matches(/^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/, t("BlankNotAllowed")),
    email: Yup.string()
      .email(t("EmailmustbevalidEmail"))
      .required(t("ErrEmailRequired")),
    company: Yup.string()
      .required(t("ErrCompanyReq"))
      .matches(/^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,100}$/, t("BlankNotAllowed")),
    message: Yup.string().required(t("ErrMsgReq")),
  })

  const [msg, setMsg] = useState()
  const contactRes = useSelector((state) => state.landing)

  useEffect(() => {
    if (contactRes.data) {
      if (contactRes.data.success) {
        setMsg(t("ContactUsToast"));
        setTimeout(() => {
          setMsg("")
        }, 5000);
      }
    }
  }, [contactRes])

  const dispatch = useDispatch()

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      company: "",
      message: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (values, { resetForm }) => {
      const newFormData = new FormData()
      newFormData.append("name", values.name)
      newFormData.append("email", values.email)
      newFormData.append("company", values.company)
      newFormData.append("message", values.message)
      dispatch(sendSuggestion(newFormData))
      resetForm({ newFormData })
    },
  })

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik

  return (
    <>
      <FormikProvider value={formik}>
        <Form className='footer-contact' noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                fullWidth
                placeholder='Name'
                name='name'
                {...getFieldProps("name")}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                autoComplete='username'
                type='email'
                name='email'
                className='mx-rtl'
                placeholder='Email'
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
            </Stack>

            <TextField
              fullWidth
              type='text'
              name='company'
              placeholder='Company'
              {...getFieldProps("company")}
              error={Boolean(touched.company && errors.company)}
              helperText={touched.company && errors.company}
            />
            <TextField
              fullWidth
              type='text'
              multiline
              rows='3'
              name='message'
              placeholder={t("Message")}
              {...getFieldProps("message")}
              error={Boolean(touched.message && errors.message)}
              helperText={touched.message && errors.message}
            />
            <Button
              variant='contained'
              type='submit'
              size='medium'
              sx={{ width: 100 }}
            >
              {t("Send")}
            </Button>
          </Stack>
        </Form>
      </FormikProvider>
      {msg && (
        <Alert severity='success' sx={{ mt: 2 }}>
          {msg}
        </Alert>
      )}
    </>
  )
}
