import * as ActionTypes from "../constants/profile";
const initialState = { data: [] };
const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHPROFILES_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      };

    case ActionTypes.FETCHPROFILE_ON_SUCCESS:
      return { ...state, profile: action.payload, loaded: true };

    case ActionTypes.CREATEPROFILE_ON_SUCCESS:
      return { ...state, created: true };

    case ActionTypes.UPDATEPROFILE_ON_SUCCESS:
      return {
        ...state,
        updated: true,
        updatedProfile: action?.payload?.profile,
      };
    case ActionTypes.RESET:
      return {
        ...state,
        isSucceed: false,
        updated: false,
        created: false,
        loaded: false,
      }

    default:
      return state;
  }
};

export default profileReducer;
