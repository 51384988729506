import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/candidate";

export const getCandidates = (id) => {
  const endpoint = `/api/v1/candidates`;
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.params = { vacancy_id: id }
  options.types = [
    ActionTypes.FETCHCANDIDATES_ON_SUCCESS,
    ActionTypes.FETCHCANDIDATES_ON_FAILURE,
  ];
  return request(options);
};

export const getNotHiredCandidates = (id) => {
  const endpoint = `/api/v1/candidates/unselected_candidates`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = { vacancy_id: id };
  options.types = [
    ActionTypes.FETCHNOTHIREDCANDIDATES_ON_SUCCESS,
    ActionTypes.FETCHNOTHIREDCANDIDATES_ON_FAILURE,
  ];
  return request(options);
};

export const getCandidateSkills = () => {
  const endpoint = `/api/v1/candidates/candidate_skills`
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.types = [
    ActionTypes.CANDIDATESKILLS_ON_SUCCESS,
    ActionTypes.CANDIDATESKILLS_ON_FAILURE,
  ];

  return request(options);
}