import { useCallback } from "react"

export function userDetail() {
  const user = JSON.parse(localStorage.getItem("user"))
  return [user.user.first_name, user.user.last_name]
}

export function userKey() {
  return JSON.parse(localStorage.getItem("key"))
}

export const decodeString = (str) => {
  return JSON.parse(
    '{"' + str.substr(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    (key, value) => (key === "" ? value : decodeURIComponent(value)),
  )
}

export const currentBrowserName = () => {
  const browserInfo = navigator.userAgent.toLowerCase()

  if (browserInfo.includes("opr") || browserInfo.includes("opera")) {
    return "Opera"
  } else if (browserInfo.includes("edg")) {
    return "Edge"
  } else if (browserInfo.includes("chrome")) {
    return "Chrome"
  } else if (browserInfo.includes("safari")) {
    return "Safari"
  } else if (browserInfo.includes("firefox")) {
    return "Firefox"
  } else {
    return "unknown"
  }
}

//discount on plan
export const handleApplyCoupon = (
  percentage,
  getPerInterviewPriceOfSubscribedPlan,
  interviewsCount,
  setDiscountedPrice,
) => {
  let calculatedAmount = (
    getPerInterviewPriceOfSubscribedPlan() * interviewsCount
  ).toFixed(2)
  let perctileValue = calculatedAmount * (percentage / 100)
  let resultAmount = calculatedAmount - perctileValue

  setDiscountedPrice(resultAmount)
}

export const applyDiscountPlan = (
  percentage,
  priceBeforeDiscount,
  setDiscount,
) => {
  let percentageAmount = priceBeforeDiscount * (percentage / 100)
  let amount = priceBeforeDiscount - percentageAmount
  setDiscount(amount ?? 0)
}
