import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import {
  Radio,
  FormHelperText,
  RadioGroup,
  FormControlLabel,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Rating,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { useFormik, Form, FormikProvider } from "formik"
import { useTranslation } from "react-i18next"
import ReviewForm from "../../reviewForm"
import { updateIntFeedback } from "src/redux/actions/interviews"
import { getAccountConfig } from "src/redux/actions/accountConfig"
import { userKey } from "src/utils/userHelper"
//-----------------------------------------
export default function UpdateFeedbackModal({
  hide,
  isShowingUpdateFeedback,
  setIsRecommendedByCurrentUser,
  isRecommendedByCurrentUser,
  data = null,
}) {
  const [isRating, setIsRating] = useState(0)
  const [configuration, setConfiguration] = useState({})
  const [isRecommended, setIsRecommended] = useState("")
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const userId = localStorage.getItem("match")
  const newId = JSON.parse(userId)
  const interviewId = newId?.id
  const { intKey } = useParams()
  const config = useSelector((state) => state.accountConfigurations)
  const userFeedback = useSelector((state) => state?.getIntFeedback)
  const userInfo = userFeedback?.data?.interview
  const userFeedbackData = userInfo?.feedback ?? {}
  const isVacancyOpen =
    userInfo?.candidate_vacancy?.vacancy?.status === "active"

  const getAccountCustomFeedback = () => {
    return configuration?.data?.account_configuration?.current_points?.reduce(
      (items, item) => {
        return { ...items, [item]: "", [`${item} Comment`]: "" }
      },
      {},
    )
  }

  const mergeExistingColumns = () => {
    let keysToKeep = []
    Object.keys(userFeedbackData).forEach((key) => {
      if (
        key !== "user_id" &&
        !keysToKeep.includes(key.replace("Comment", ""))
      ) {
        keysToKeep.push(key.replace(" Comment", ""))
      }
    })

    return [
      ...new Set(
        keysToKeep.concat(
          configuration?.data?.account_configuration?.current_points,
        ),
      ),
    ]
  }

  const setCustomFeedbackValue = () => {
    return mergeExistingColumns().reduce((items, item) => {
      return { ...items, [item]: userFeedbackData[item] ?? "" }
    }, {})
  }

  const [initialState, setInitialState] = useState({
    text: "",
    rating: 0,
    is_recommended: "",
    interview_id: interviewId,
    time: "12321",
    user_id: "",
    feedback_details: getAccountCustomFeedback(),
  })

  const customColumns = () => {
    return mergeExistingColumns().reduce((acc, item) => {
      return { ...acc, [item]: Yup.string().required("Select any") }
    }, {})
  }

  const recommendationSchema = Yup.object().shape({
    ...customColumns(),
    is_recommended: Yup.string().required(t("SelectAboveOption")),
    rating: Yup.number().min(1, t("PleaseRate")),
  })

  const submission = (values) => {
    if (isRecommended !== "" && isRating !== 0) {
      const payload = {
        interview_id: values.interview_id,
        recommendation: {
          text: values.text,
          is_recommended: values.is_recommended,
          rating: values.rating,
          interview_id: values.interview_id,
          time: values.time,
          final_decisions_attributes: [
            { interview_id: values.interview_id, vote: values.is_recommended },
          ],
        },
        feedback: {
          feedback_details: values?.feedback_details,
          user_id: values?.user_id,
        },
      }

      const method = isRecommendedByCurrentUser ? "PUT" : "POST"

      dispatch(updateIntFeedback(payload, method, data?.key))
      setIsRecommendedByCurrentUser(true)
      hide()
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: recommendationSchema,
    onSubmit: submission,
  })

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    setFieldValue,
  } = formik

  const resetFields = () => {
    hide()
  }

  useEffect(() => {
    if (data) {
      setInitialState({
        ...setCustomFeedbackValue(),
        text: data?.text,
        rating: data?.rating,
        is_recommended: data.is_recommended,
        interview_id: interviewId,
        user_id: userFeedbackData?.user_id ?? "",
        feedback_details: userFeedbackData ?? getAccountCustomFeedback(),
      })
      setIsRating(data?.rating)
      setIsRecommended(data.is_recommended ? "Yes" : "No")
    }
  }, [data, userFeedbackData])

  useEffect(() => {
    if (config?.data.length === 0) {
      dispatch(getAccountConfig(userKey()))
    } else {
      setConfiguration(config)
    }
  }, [config])

  return (
    isShowingUpdateFeedback && (
      <Dialog
        className='dialog800'
        open={isShowingUpdateFeedback}
        onClose={resetFields}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {t("Recommendation")}{" "}
          <IconButton
            aria-label='close'
            onClick={resetFields}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <FormikProvider value={formik}>
            <Form id='completeRole' autoComplete='off' noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} component='h6' variant='h6'>
                    {t("RateCandidate?")}
                  </Typography>
                  <Rating
                    name='half-rating'
                    value={isRating}
                    onChange={(e) => {
                      if (isRating === parseInt(e.target.value)) {
                        setIsRating(0)
                        setFieldValue("rating", "0")
                      } else {
                        setIsRating(parseInt(e.target.value))
                        setFieldValue("rating", e.target.value)
                      }
                    }}
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  />
                  {touched.rating && errors.rating && (
                    <FormHelperText
                      className='error'
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        ml: 0,
                        mb: 1,
                      }}
                    >
                      {touched.rating && errors.rating}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                    }}
                  >
                    <FormLabel
                      sx={{
                        mr: { xs: 0, sm: 2 },

                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 0.6)",
                        "&:focus": {
                          color: "rgba(0, 0, 0, 0.6)",
                        },
                      }}
                      required
                    >
                      {t("RecommendCandidate")}
                    </FormLabel>

                    <RadioGroup
                      row
                      sx={{ flexDirection: "row" }}
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      id=''
                    >
                      <FormControlLabel
                        value='true'
                        checked={values.is_recommended === true}
                        control={<Radio />}
                        onChange={() => {
                          setIsRecommended("Yes")
                          setFieldValue("is_recommended", true)
                        }}
                        label={t("Yes")}
                      />
                      <FormControlLabel
                        value='false'
                        checked={values.is_recommended === false}
                        control={<Radio />}
                        onChange={() => {
                          setIsRecommended("No")
                          setFieldValue("is_recommended", false)
                        }}
                        label={t("No")}
                      />
                    </RadioGroup>
                  </FormControl>

                  {touched.is_recommended && errors.is_recommended && (
                    <FormHelperText
                      className='error'
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        ml: 0,
                        mb: 1,
                      }}
                    >
                      {touched.is_recommended && errors.is_recommended}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: 500,
                        mb: 1,
                        mr: 2,

                        color: "rgba(0, 0, 0, 0.6)",
                        "&:focus": {
                          color: "rgba(0, 0, 0, 0.6)",
                        },
                      }}
                    >
                      {t("EnterComment")}
                    </FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      value={values.text}
                      rows={2}
                      variant='outlined'
                      disabled={!isVacancyOpen}
                      autoComplete=''
                      placeholder={t("ShareThoughts")}
                      name='text'
                      onChange={(e) => setFieldValue("text", e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <ReviewForm
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                    intKey={intKey}
                    isVacancyOpen={isVacancyOpen}
                    userInfo={userInfo}
                    userFeedback={userFeedback}
                    customColumns={mergeExistingColumns}
                  />
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>

        <DialogActions className='dialog-footer'>
          <Button
            onClick={resetFields}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              handleSubmit()
            }}
            variant='contained'
            color='secondary'
            size='medium'
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
