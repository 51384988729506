import Backend from "i18next-http-backend"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

const Languages = ["en", "ar", "fr"]
// const location = window.location.pathname.split('/').slice(0, 3).join('/')
const candidateScreenPaths = [
  '/candidate-interview/candidate',
  '/candidate-interview-detail',
  '/interview/start',
  '/record-interview/start',
  '/recorded-interview/review'
]

const initializeI18n = (location) => {
  const isTranslationEnabled = !candidateScreenPaths.includes(location)

  if (isTranslationEnabled) {
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: "en",
        debug: true,
        whitelist: Languages,
        detection: {
          order: ["htmlTag", "cookie", "localStorage", "path", "subdomain"],
          caches: ["cookie"],
        },
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
          wait: false,
          useSuspense: true,
        },
      })
  } else {
    i18n.init({
      lng: 'en',
      resources: {},
      fallbackLng: 'en',
    })
  }
}

const reloadI18n = () => {
  i18n.store.data = {}
  initializeI18n(window.location.pathname.split('/').slice(0, 3).join('/'))
  window.location.reload()
}

initializeI18n(window.location.pathname.split('/').slice(0, 3).join('/'))

export  { i18n, reloadI18n }
