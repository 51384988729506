import axios, { CancelToken } from "axios"
import { useSessionStorage } from "./useSessionStorage"

export function useVideoUpload(
  endpoint = "/api/v1/interview_conduction/save_candidate_answer",
) {
  const [videos, setVideos] = useSessionStorage("recordings", [])

  const generateId = () => {
    return (Math.random() + 1).toString(36).substring(7)
  }

  const bytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2)
  }

  const updateVideoObject = (videoObj) => {
    let videosList = JSON.parse(sessionStorage.getItem("recordings"))
    const index = videosList.findIndex((video) => video.id === videoObj.id)

    if (index >= 0) {
      videosList[index] = videoObj
      setVideos(videosList)
    }
  }

  const uploadVideo = (file, duration, params) => {
    if (file) {
      const fileSrc = URL.createObjectURL(file)

      const video = {
        id: generateId(),
        file: file,
        fileSrc: fileSrc,
        name: file.name,
        size: bytesToMB(file.size),
        duration: duration,
        uploadedOn: new Date(),
        progress: 0.0,
        uploaded: false,
        failed: false,
        failedErrorMessage: "",
        fulfilled: false,
        cancelToken: null,
        params: params,
      }

      let videosList = JSON.parse(sessionStorage.getItem("recordings"))
      videosList.push(video)
      setVideos(videosList)

      startUploading(video, params)

      return video
    }
  }

  const startUploading = (videoObj, params) => {
    const header = {
      accept: "application/json",
      "Content-Type": "application/json",
    }
    const formData = new FormData()

    for (let [key, value] of Object.entries(params)) {
      formData.append(key, value)
    }

    if (videoObj?.file?.name) {
      formData.append("answer_video", videoObj.file, videoObj.file.name)
    } else if (videoObj?.fileSrc) {
      const file = new File([videoObj.fileSrc], videoObj.name ?? "ans")
      formData.append("answer_video", file, file.name)
    }

    axios({
      baseURL: process.env.REACT_APP_BASE_URL,
      url: endpoint,
      headers: header,
      method: "post",
      data: formData,
      onUploadProgress: (progress) => {
        const { loaded, total } = progress
        const percentageProgress = ((loaded / total) * 100).toFixed(2)
        videoObj.progress = percentageProgress

        updateVideoObject(videoObj)
      },
      cancelToken: new CancelToken((cancel) => (videoObj.cancelToken = cancel)),
    })
      .then(function (response) {
        videoObj.fulfilled = true

        if (response && response.data) {
          videoObj.uploaded = true
        } else {
          if (videoObj.progress === "100.00") {
            videoObj.failed = false
            videoObj.uploaded = true
          }
        }

        if (response && response.error) {
          videoObj.failedErrorMessage = response.error
          videoObj.failed = true
          videoObj.uploaded = false
        }

        updateVideoObject(videoObj)
      })
      .catch(function (error) {
        videoObj.fulfilled = true

        videoObj.failedErrorMessage = error
        videoObj.failed = true
        videoObj.uploaded = false

        updateVideoObject(videoObj)
      })
  }

  const clear = () => {
    setVideos([])
  }

  return { videos, uploadVideo, startUploading, clear }
}
