import PropTypes from "prop-types"
import React from "react"
import { Typography, Card, CardActions } from "@mui/material"
import { fCurrency } from "../../../utils/formatNumber"
import { cardDetails } from "src/redux/actions/stripeCardDetails"
import * as ActionType from "src/redux/constants/stripe"
import Label from "src/components/Label"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

SubsPlanCard.propTypes = {
  plan: PropTypes.object,
  handleNext: PropTypes.func,
}
export default function SubsPlanCard({ plan, values, handleNext }) {
  const {
    name,
    price,
    interval,
    per_unit_price,
    total_interviews,
    plan_type,
    saving,
    id,
  } = plan

  const { t } = useTranslation()

  return (
    // <Card className={`package-block-sub ${plan_type === 'basic_monthly' ? 'selected' : ''}`}>
    <Card className='package-block-sub'>
      <Typography
        component='h5'
        variant='h5'
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {name.slice(0, -7)}
        {plan_type === "basic_monthly" && (
          <Label
            variant='ghost'
            color='info'
            sx={{
              color: "#fff",
              backgroundColor: "#1d4ed8",
              fontWeight: 600,
            }}
          >
            {t("30DaysFreeTrial")}
          </Label>
        )}
      </Typography>
      <Typography variant='h2' sx={{ display: "flex" }}>
        {fCurrency(price)}
        <span> {interval === "month" ? t("/month") : t("/year")}</span>
      </Typography>
      <ul
        className='plan-list'
        sx={{
          backgroundColor: "light",
        }}
      >
        <Typography
          component='li'
          variant='subtitle1'
          sx={{ fontWeight: "400" }}
        >
          <span className='plan'>
            {interval === "month"
              ? t("InterviewsPerMonth")
              : t("InterviewsPerYear")}
          </span>
          <span className='count'>{total_interviews}</span>
        </Typography>

        <Typography
          component='li'
          variant='subtitle1'
          color='red'
          sx={{ fontWeight: "400" }}
        >
          <span className='plan'>{t("ChargePerExtraInterview")}</span>
          <span className='count'>{fCurrency(per_unit_price)} </span>
        </Typography>
        <Typography
          component='li'
          variant='subtitle1'
          color='red'
          sx={{ fontWeight: "400" }}
        >
          <span className='plan'>
            {interval === "month" ? t("AnnualCharges") : t("Savings")}
          </span>
          <span className='count'>
            {interval === "month" ? (
              <>{fCurrency(price * 12)}</>
            ) : (
              <>{fCurrency(saving)}</>
            )}
          </span>
        </Typography>
      </ul>
      <CardActions></CardActions>
    </Card>
  )
}
