import * as React from "react"
import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} variant='filled' {...props} />
})

export default function Toast({
  onClose,
  message = "",
  isSuccess = false,
  isInfo = false,
  isError = false,
  isWarning = false,
  autoHideDuration = 6000,
  vertical = "top",
  horizontal = "center",
  top = "20vh !important",
}) {
  const [open, setOpen] = React.useState(true)

  const handleClose = () => {
    setOpen(false)

    if (onClose) {
      onClose()
    }
  }

  let severity = ""

  if (isSuccess) {
    severity = "success"
  } else if (isError) {
    severity = "error"
  } else if (isWarning) {
    severity = "warning"
  } else if (isInfo) {
    severity = "info"
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message={message}
      sx={{ zIndex: "9999", top: { top } }}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  )
}
