import { useRef, useState } from "react"
import React from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"
import AddQuestionModal from "./modal/AddQuestionModal"
import DeleteInterviewModal from "src/sections/@dashboard/interview/modal/deleteInterview/DeleteInterviewModal"

import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import ViewQuestionModal from "./modal/ViewQuestionModal"
import questionSetArr from "src/_mock/questionSetArr"
import { deleteSampleData } from "src/redux/actions/accountConfig"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import VisibilityIcon from "@mui/icons-material/Visibility"
// ----------------------------------------------------------------------

QuestionMoreMenu.propTypes = {
  id: PropTypes.number,
  questCrud: PropTypes.array,
}

export default function QuestionMoreMenu({
  id,
  canEdit = true,
  canDelete = true,
  disabled = false,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const quest = useSelector((state) => state.questions)
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isShowingQuestion, toggleQuestion] = useModal()
  const [isShowingQuesView, toggleViewQuestion] = useModal()
  const [isShowingDeleteInt, toggleDeleteInt] = useModal()
  const [matchedData, setMatchedData] = useState()

  const matchedQuestionSet = (id) => {
    for (let i = 0; i < quest?.data?.question_sets?.length; i++) {
      if (id === quest?.data?.question_sets[i]?.id) {
        return [quest?.data?.question_sets[i], i]
      }
    }
  }

  const handleEdit = () => {
    toggleQuestion()
    const [match, index] = matchedQuestionSet(id)
    setMatchedData(match)
    setIsOpen(false)
  }
  const handleDelete = () => {
    if (id !== 0) {
      const [match, index] = matchedQuestionSet(id)
      setMatchedData(match)
    }

    toggleDeleteInt()
    setIsOpen(false)
  }


  const handleSampleDelete = () => {
    let creationInfo = JSON.parse(sessionStorage.getItem("creation_info"))
    creationInfo = { ...creationInfo, question_set: true }
    sessionStorage.setItem("creation_info", JSON.stringify(creationInfo))
    const formData = {
      sample_data: creationInfo,
    }
    dispatch(deleteSampleData(formData))
  }
  const handleView = () => {
    toggleViewQuestion()

    setMatchedData(questionSetArr[0])
    setIsOpen(false)
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} disabled={disabled}>
        <MoreVertIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          component={RouterLink}
          to='#'
          sx={{ color: "text.secondary", display: "none" }}
          onClick={handleView}
        >
          <ListItemIcon>
            <VisibilityIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={t("View")}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>

        {canEdit && (
          <MenuItem
            component={RouterLink}
            to='#'
            sx={{ color: "text.secondary" }}
            onClick={handleEdit}
            disabled={id === 0}
          >
            <ListItemIcon>
              <EditIcon width={24} height={24} fontSize='medium' />
            </ListItemIcon>
            <ListItemText
              primary={t("Edit")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
        {canDelete && (
          <MenuItem sx={{ color: "text.secondary" }} onClick={handleDelete}>
            <ListItemIcon>
              <DeleteOutlineIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t("Delete")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
      <AddQuestionModal
        isShowingQuestion={isShowingQuestion}
        hide={toggleQuestion}
        data={matchedData}
        flag={false}
      />
      <ViewQuestionModal
        isShowingQuesView={isShowingQuesView}
        hide={toggleViewQuestion}
        data={questionSetArr}
        flag={false}
      />

      <DeleteInterviewModal
        t={t}
        isShowingDeleteInt={isShowingDeleteInt}
        hide={toggleDeleteInt}
        data={matchedData}
        invoke={"question"}
        isSample={id === 0}
        confirmAction={handleSampleDelete}
      />
    </>
  )
}
