import { useRef, useState, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { CreateDeptModal } from "src/sections/@dashboard/department"
import { useDispatch, useSelector } from "react-redux"
import DeleteInterviewModal from "src/sections/@dashboard/interview/modal/deleteInterview/DeleteInterviewModal"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import { deleteSampleData } from "src/redux/actions/accountConfig"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
// ----------------------------------------------------------------------

DepartmentMoreMenu.propTypes = {
  name: PropTypes.string,
  deptCrud: PropTypes.object,
}

export default function DepartmentMoreMenu({
  name,
  userPermissions,
  isSample = false,
}) {
  const ref = useRef(null)
  const dispatch = useDispatch()

  const { t, i18n } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isAssociated, setAssociated] = useState(false)
  const dept = useSelector((state) => state.departments)
  const [isShowingDpt, toggleDpt] = useModal()
  const [isShowingDeleteInt, toggleDeleteInt] = useModal()
  const [initialVal, setInitialVal] = useState(null)
  const [matchedObject, setMatchedObject] = useState({})
  const [index, setIndex] = useState(null)

  // useEffect(() => {
  //   const matchedDptAssociated = (rowName) => {
  //     for (let i = 0; i < dept?.data?.departments?.length; i++) {
  //       if (rowName.match(dept?.data?.departments[i]?.name)) {
  //         return [dept?.data?.departments[i], i], setAssociated(true);
  //       }
  //     }
  //   };
  // }, []);

  const matchedDepartment = (rowName) => {
    for (let i = 0; i < dept?.data?.departments?.length; i++) {
      if (rowName.match(dept?.data?.departments[i]?.name)) {
        return [dept?.data?.departments[i], i]
      }
    }
  }
  const handleEdit = () => {
    toggleDpt()
    const [match, index] = matchedDepartment(name)
    setMatchedObject(match)
    const editData = {
      name: match?.name,
      description: match?.description,
    }
    setInitialVal(editData)
    setIsOpen(false)
  }

  const handleDelete = (isSample) => {
    if (!isSample) {
      const [match, index] = matchedDepartment(name)
      setMatchedObject(match)
    }

    toggleDeleteInt()
    setIsOpen(false)
  }

  const handleSampleDelete = () => {
    let creationInfo = JSON.parse(sessionStorage.getItem("creation_info"))
    creationInfo = { ...creationInfo, departments: true }
    sessionStorage.setItem("creation_info", JSON.stringify(creationInfo))
    const formData = {
      sample_data: creationInfo,
    }
    dispatch(deleteSampleData(formData))
  }

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <MoreVertIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {userPermissions?.departments_user_can_edit_department && (
          <MenuItem
            component={RouterLink}
            to='#'
            sx={{ color: "text.secondary" }}
            onClick={handleEdit}
            disabled={isSample}
          >
            <ListItemIcon>
              <EditIcon fontSize='medium' width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t("Edit")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {userPermissions?.departments_user_can_delete_department && (
          <MenuItem
            onClick={() => handleDelete(isSample)}
            // disabled={isAssociated}
            // disabled={isSample}
            sx={{ color: "text.secondary" }}
          >
            <ListItemIcon>
              <DeleteOutlineIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t("Delete")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
      <CreateDeptModal
        isShowingDpt={isShowingDpt}
        hide={toggleDpt}
        flag={false}
        initialVal={initialVal}
        data={matchedObject}
      />
      <DeleteInterviewModal
        isShowingDeleteInt={isShowingDeleteInt}
        hide={toggleDeleteInt}
        data={matchedObject}
        invoke={"department"}
        isSample={isSample}
        confirmAction={handleSampleDelete}
      />
    </>
  )
}
