import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Paper, Typography } from "@mui/material"

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
}

export default function SearchNotFound({ searchQuery = "", ...other }) {
  const { t } = useTranslation()
  return (
    <Paper {...other} sx={{ p: 2 }}>
      <Typography gutterBottom align='left' variant='subtitle1'>
        {t("Notfound")}
      </Typography>
      <Typography variant='body2' align='left'>
        {t("NoShowHere!")} &nbsp;
      </Typography>
    </Paper>
  )
}
