/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from "lodash"
import React, { useState, useEffect, Fragment, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ToggleWidget, PlansList } from "src/sections/plans"
import { planDataParsing } from "src/utils/dataParsing"
import { subscriptionStatus } from "src/utils/constants"
import { styled } from "@mui/material/styles"
import Images from "src/assets/img/Images"
import useModal from "./Modals/useModal"
import SubscribePlanModal from "./Modals/subscribePlan/subscribePlanModal"
import UpgradePlanModal from "./Modals/upgradePlan/upgradePlanModal"
import UnsubscribePlanModal from "./Modals/unsubscribePlan/unsubscribePlanModal"
import DowngradePlanModal from "./Modals/downgradePlan/downgradePlanModal"
import BuyInterviewsModal from "./Modals/buyInterviews/buyInterviewsModal"
import ChangeCardModal from "./Modals/changeCard/changeCardModal"
import { useLocalStorage } from "src/hooks/useLocalStorage"
import { getPlans, getSubscriptionInfo } from "src/redux/actions/subscription"
import { getCompanyInfo, updateCompanyInfo } from "src/redux/actions/general"
import validator from "validator"
import Toast from "src/components/Toast"
import {
  Box,
  Card,
  Grid,
  Stack,
  Button,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  Paper,
  CircularProgress,
} from "@mui/material"
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip"
import HelpModal from "./Modals/help/helpModals"
import DeleteAccount from "./Modals/deletAccount/deleteAccountModal"
import Snackbar from "@mui/material/Snackbar"
import Alert from "src/components/Alert"
import { useTranslation } from "react-i18next"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined"
import { RemoveCard, resetCardFlag } from "src/redux/actions/paymentMethod"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}))

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgb(255, 255, 255)",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "0",
    borderRadius: "8px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
  },
}))

export default function Subscription() {
  const { t } = useTranslation()

  const [userKey] = useLocalStorage("key", "")
  const [user, setUser] = useLocalStorage("user", {})
  const [fetchingPlans, setIsFetchingPlans] = useState(false)
  const [isYearlyChecked, setIsYearlyChecked] = useState(false)
  const [edittingBillingInfo, setEdittingBillingInfo] = useState(false)
  const [parsedPlans, setParsedPlans] = useState([])
  const [subscribedPlan, setSubscribedPlan] = useState(null)
  const [isOnTrial, setIsOnTrial] = useState(false)
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false)
  const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState(null)
  const [planIdToSubscribe, setPlanIdToSubscribe] = useState(null)
  const [planIdToUnsubscribe, setPlanIdToUnsubscribe] = useState(null)

  const [billingEmail, setBillingEmail] = useState("")
  const [interViewStatistics, setInterViewsStatistics] = useState({
    purchasedInterviews: 0,
    usedInterviews: 0,
    availableInterviews: 0,
    interviewsCount: {},
  })
  const [cardNumber, setCardNumber] = useState("****************")
  const [toastProps, setToastProps] = useState(null)

  const [isShowingUpgradePlan, toggleUpgradePlan] = useModal()
  const [isShowingSubscribePlan, toggleSubscribePlan] = useModal()
  const [isShowingUnsubscribePlan, toggleUnsubscribePlan] = useModal()
  const [isShowingDowngradePlan, toggleDowngradePlan] = useModal()
  const [isShowingBuyInterviews, toggleBuyInterviews] = useModal()
  const [isShowingChangeCard, toggleChangeCard] = useModal()
  const [isShowingHelp, toggleHelp] = useModal()
  const [isShowingDataDelete, toggleDataDelete] = useModal()
  const getPlansData = useSelector((state) => state.plansList)
  const getSubscriptionInfoData = useSelector((state) => state.subscriptionInfo)
  const getCompanyInfoData = useSelector((state) => state.getCompanyInfo)
  const updateCompanyInfoData = useSelector((state) => state.updateCompanyInfo)

  const addUpdateCardState = useSelector((state) => state.addCard)
  const removeCard = useSelector((state) => state.removeCard)
  const logged = useSelector((state) => state.login)
  const userLoggedPermissions = logged?.user?.profile?.permission

  const dispatch = useDispatch()

  useEffect(() => {
    if (addUpdateCardState.cardAdded) {
      dispatch(getSubscriptionInfo())
      dispatch(getCompanyInfo(userKey))
    }
  }, [addUpdateCardState])

  useEffect(() => {
    const plans = getPlansData?.plans
    if (plans && plans.length > 0) {
      setIsFetchingPlans(false)
      setParsedPlans(planDataParsing(plans))
    } else {
      setIsFetchingPlans(true)
      dispatch(getSubscriptionInfo())
    }
  }, [getPlansData])

  useEffect(() => {
    const subscriptionInfo =
      getSubscriptionInfoData?.subscription_info?.subscription

    if (subscriptionInfo) {
      if (parsedPlans.length === 0) {
        dispatch(getPlans())
      }
      setSubscribedPlan(subscriptionInfo?.plan)
      setIsYearlyChecked(subscriptionInfo?.plan?.sequence_number > 3 ?? true)
      setIsOnTrial(subscriptionInfo?.status === subscriptionStatus.trialing)
      setIsSubscriptionExpired(subscriptionInfo?.remaining_days < 0)
      setSubscriptionExpiryDate(
        subscriptionInfo?.current_period_end
          ? new Date(subscriptionInfo?.current_period_end).toDateString()
          : "",
      )
      updateSubscriptionInfoInLocalStorageUser(subscriptionInfo)
    }
  }, [getSubscriptionInfoData])

  useEffect(() => {
    const companyInfoData = getCompanyInfoData?.data

    const billingContactEmail =
      companyInfoData?.account?.billing_contact_email ??
      user?.user?.account?.billing_contact_email ??
      ""
    const cardLastDigits = companyInfoData?.account?.card_last_digts ?? ""

    const purchasedInterviews =
      companyInfoData?.account?.purchased_interviews ?? 0
    const usedInterviews = companyInfoData?.account?.interviews_used ?? 0
    const availableInterviews = companyInfoData?.account?.no_of_interviews ?? 0

    setBillingEmail(billingContactEmail)
    setCardNumber(
      cardLastDigits ? "************" + cardLastDigits : "************xxxx",
    )
    setInterViewsStatistics({
      purchasedInterviews: purchasedInterviews,
      usedInterviews: usedInterviews,
      availableInterviews: availableInterviews,
      interviewsCount: companyInfoData?.account?.interviews_count,
    })
  }, [getCompanyInfoData])

  useEffect(() => {
    if (removeCard.cardRemoved) {
      setToastProps({
        message: removeCard?.message,
        isSuccess: true,
      })
      dispatch(getCompanyInfo(userKey))
      dispatch(resetCardFlag())
    }
  }, [removeCard])

  useEffect(() => {
    if (edittingBillingInfo) {
      const companyInfoData = updateCompanyInfoData?.data
      if (companyInfoData?.success) {
        const userToUpdate = user
        userToUpdate.user.account.billing_contact_email = billingEmail
        setUser(userToUpdate)
        setBillingEmail(billingEmail)
        setToastProps({
          message: "Billing email updated successfully",
          isSuccess: true,
        })
      } else if (
        companyInfoData?.errors &&
        companyInfoData?.errors.length > 0
      ) {
        setToastProps({
          message: companyInfoData?.errors[0],
          isError: true,
        })
        setBillingEmail(user?.user?.account?.billing_contact_email ?? "")
      }
      setEdittingBillingInfo(false)
    }
  }, [updateCompanyInfoData])

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [displayCrash, setDisplayCrash] = useState("")
  // const [canManageBilling, setCanManageBilling] = useState(false)

  const helpRes = useSelector((state) => state.helpNeeded)
  const [messageDisplayed, setMessageDisplayed] = useState(false)
  const isInitialRender = useRef(true)

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSnackbar(false)
  }

  useEffect(() => {
    handleCloseSnackbar()
    return () => {
      handleCloseSnackbar()
    }
  }, [])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    if (helpRes?.data?.success === true && !messageDisplayed) {
      setOpenSnackbar(true)
      setDisplayCrash(helpRes?.data?.message)

      setMessageDisplayed(true)
    }
  }, [helpRes, messageDisplayed])

  const updateSubscriptionInfoInLocalStorageUser = (subscriptionInfo) => {
    const userToUpdate = user
    userToUpdate.user.subscription_info = subscriptionInfo
    setUser(userToUpdate)
  }

  const isCardAttached = () => {
    if (!cardNumber?.endsWith("xxxx")) {
      return true
    }
    const last4 = getCompanyInfoData?.data?.account?.card_last_digts
    return last4 !== undefined && last4 !== null
  }

  const isCardExpired = () => {
    const inputMonth = parseInt(
      getCompanyInfoData?.data?.account?.card_expiration_month,
    )
    const inputYear = parseInt(
      getCompanyInfoData?.data?.account?.card_expiration_year,
    )

    const currentDate = new Date()
    const currentMonth = currentDate.getMonth() + 1
    const currentYear = currentDate.getFullYear()

    if (inputYear < currentYear) {
      return true
    }

    if (inputYear === currentYear && inputMonth < currentMonth) {
      return true
    }

    return false
  }

  const editBillingInformation = () => {
    const isValidBillingEmail = validator.isEmail(billingEmail)
    if (
      user?.user?.account?.billing_contact_email !== billingEmail &&
      billingEmail !== "" &&
      isValidBillingEmail
    ) {
      setEdittingBillingInfo(true)

      dispatch(
        updateCompanyInfo(userKey, {
          billing_contact_email: billingEmail,
        }),
      )
    } else {
      if (billingEmail === "") {
        setBillingEmail(user?.user?.account?.billing_contact_email ?? "")
      }
    }
  }

  const handleToastOnClose = () => {
    setToastProps(null)
  }

  const handleCardUpdate = (updatedCard) => {
    // setCardNumber("************" + updatedCard)
    toggleChangeCard(false)
    dispatch(getCompanyInfo(userKey))
    setToastProps({
      message: t(`Card ${isCardAttached() ? "updated" : "added"} successfully`),
      isSuccess: true,
    })
  }

  const proceedAccordingToAppropriateSubscriptionFlow = (planIdToSubscribe) => {
    const planSequenceNumber = getPlanSeqNoToSubscribe(planIdToSubscribe)

    if (subscribedPlan && planSequenceNumber) {
      if (planSequenceNumber === subscribedPlan.sequence_number) {
        toggleSubscribePlan()
      } else if (planSequenceNumber > subscribedPlan.sequence_number) {
        toggleUpgradePlan()
      } else if (planSequenceNumber < subscribedPlan.sequence_number) {
        toggleDowngradePlan()
      }
    } else {
      toggleSubscribePlan()
    }
  }

  const getPlanSeqNoToSubscribe = (planId) => {
    const plan = parsedPlans.find((plan) => plan.id === planId)
    if (plan?.sequence_number) {
      return plan.sequence_number
    }
    return null
  }

  const subscribePlan = (planId) => {
    if (isCardAttached()) {
      if (isCardExpired()) {
        setToastProps({
          message: t("expired"),
          isError: true,
        })

        return
      }
      setPlanIdToSubscribe(planId)
      setTimeout(() => {
        proceedAccordingToAppropriateSubscriptionFlow(planId)
      }, 0)
    } else {
      setToastProps({
        message: t("addCreditDebitcardMsg"),
        isError: true,
      })
    }
  }

  const unsubscribePlan = (planId) => {
    setPlanIdToUnsubscribe(planId)
    setTimeout(() => {
      toggleUnsubscribePlan()
    }, 0)
  }

  const onPlanSubscribed = (subscriptionInfo) => {
    if (!isCardAttached()) {
      setToastProps({
        message: t("addCreditDebitcardMsg"),
        isError: true,
      })

      return
    }

    if (isCardExpired()) {
      setToastProps({
        message: t("expired"),
        isError: true,
      })

      return
    }

    dispatch(getSubscriptionInfo())
    dispatch(getCompanyInfo(userKey))

    setToastProps({
      message: "Subscribed successfully",
      isSuccess: true,
    })

    if (subscriptionInfo?.plan) {
      setSubscribedPlan(subscriptionInfo.plan)
    }

    if (isShowingSubscribePlan) {
      toggleSubscribePlan()
    } else if (isShowingUpgradePlan) {
      toggleUpgradePlan()
    } else if (isShowingDowngradePlan) {
      toggleDowngradePlan()
    } else {
    }
  }

  const onPlanUnsubscribed = (message) => {
    setToastProps({
      message: message ? message : "Unsubscribed successfully",
      isSuccess: isEmpty(message),
      isWarning: !isEmpty(message),
    })

    if (!message) {
      setSubscribedPlan(null)
      updateSubscriptionInfoInLocalStorageUser(null)
    }

    toggleUnsubscribePlan()
  }

  const buyInterviewsForPlan = (planId) => {
    if (isCardAttached()) {
      if (isCardExpired()) {
        setToastProps({
          message: t("expired"),
          isError: true,
        })

        return
      }
      toggleBuyInterviews()
    } else {
      setToastProps({
        message: t("addCreditDebitcardMsg"),
        isError: true,
      })
    }
  }

  const onInterviewsBought = () => {
    dispatch(getCompanyInfo(userKey))

    setToastProps({
      message: "Interviews bought successfully",
      isSuccess: true,
    })
    toggleBuyInterviews()
  }

  const renderSpinner = (title) => {
    return (
      <Box display='flex' justifyContent='center'>
        <CircularProgress size={20} />
        <Typography variant='subtitle2' sx={{ marginLeft: "5px" }}>
          {title}
        </Typography>
      </Box>
    )
  }

  const getInterviewsText = () => {
    return (
      <Box sx={{ px: 2, py: 1, minWidth: "150px" }} className='popover-stats'>
        <Typography variant='body2'>
          <strong> {t("Pending")}:</strong>{" "}
          {interViewStatistics?.interviewsCount?.pending ?? 0}
        </Typography>
        <Typography variant='body2'>
          <strong> {t("Completed")}:</strong>{" "}
          {interViewStatistics?.interviewsCount?.completed ?? 0}
        </Typography>
        <Typography variant='body2'>
          <strong> {t("Archived")}:</strong>{" "}
          {interViewStatistics?.interviewsCount?.archived ?? 0}
        </Typography>
      </Box>
    )
  }

  const IconWrapperStyle = styled("div")(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 0,
    marginright: "1rem",
    height: "62px",
    borderRadius: " 14px",
    width: "62px",
    minWidth: " 62px",
  }))
  const [openTootltip, setOpenTootltip] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const handleTooltipClose = () => {
    setOpenTootltip(false)
  }

  const handleTooltipOpen = () => {
    setOpenTootltip(true)
  }
  const handleAddCoupen = () => {}

  const handleRemoveCard = () => {
    dispatch(RemoveCard())
  }
  return (
    <>
      <Grid container spacing={5}>
        {
          <Grid item xs={12} md={4}>
            <Item sx={{ padding: "0px" }}>
              <Stack direction='row' justifyContent='space-between'>
                <Typography
                  variant='h6'
                  component='h6'
                  sx={{
                    color: (theme) => theme.palette.grey[800],
                  }}
                >
                  {t("Credit/DebitCardInformation")}
                </Typography>
              </Stack>

              <Stack spacing={2} justifyContent='space-between' marginTop='5px'>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel required>{t("BillingEmail")}</FormLabel>
                  <TextField
                    sx={{ marginTop: "5px" }}
                    fullWidth
                    type='email'
                    variant='outlined'
                    name='billingEmail'
                    value={billingEmail}
                    disabled={true}
                    onChange={(e) => setBillingEmail(e.target.value)}
                    error={
                      !validator.isEmail(billingEmail) && billingEmail !== ""
                    }
                    helperText={
                      !validator.isEmail(billingEmail) &&
                      billingEmail !== "" &&
                      t("Invalidemail")
                    }
                  />
                </FormControl>
                <FormControl sx={{ width: "100%" }}>
                  <Stack direction='row' justifyContent='space-between'>
                    <FormLabel required>{t("CardNumber")}</FormLabel>
                    {userLoggedPermissions?.settings_user_can_add_or_edit_the_card &&
                      isCardAttached() && (
                        <Button
                          variant='text'
                          size='small'
                          sx={{}}
                          onClick={handleRemoveCard}
                        >
                          remove card
                        </Button>
                      )}
                  </Stack>

                  <TextField
                    sx={{ marginTop: "5px" }}
                    fullWidth
                    readOnly={true}
                    type='text'
                    name='cardValue'
                    value={cardNumber}
                    disabled={
                      process.env.REACT_APP_IS_CARD_ENABLED === "false" ||
                      !userLoggedPermissions?.settings_user_can_add_or_edit_the_card
                    }
                    onClick={toggleChangeCard}
                  />
                </FormControl>
                {console.log(
                  "cardsss",
                  process.env.REACT_APP_FE_BASE_URL,
                  process.env.REACT_APP_IS_CARD_ENABLED,
                  userLoggedPermissions?.settings_user_can_add_or_edit_the_card,
                )}
              </Stack>
            </Item>
          </Grid>
        }
        <Grid item xs={12} md={8}>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity='success'
              sx={{ width: "100%", marginTop: "5vh" }}
            >
              {t(displayCrash)}
            </Alert>
          </Snackbar>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='h6' component='h6'>
              {t("InterviewsStatistics")}
            </Typography>
            {logged.user.user_type === "admin" &&
              isSubscriptionExpired === false && (
                <Button
                  variant='contained'
                  onClick={toggleDataDelete}
                  sx={{ minWidth: 138 }}
                  startIcon={<DeleteOutlineIcon sx={{ width: 26 }} />}
                >
                  {t("Erase Data")}
                </Button>
              )}
          </Stack>

          <Stack
            direction='row'
            flexWrap='wrap'
            justifyContent='space-between'
            className='stats-row'
          >
            <Item className='col'>
              <Card
                sx={{
                  py: 5,
                  boxShadow: "none",
                  flexDirection: "row",
                  display: "flex",
                  padding: "28px 20px",
                  minHeight: "118px",
                  bgcolor: "#e9f3ff",
                }}
              >
                <IconWrapperStyle
                  className='iconWrap'
                  sx={{ backgroundColor: "#c3e0ff", mr: 2 }}
                >
                  <img src={Images.CartLg} alt='cart' className='pbox-image' />
                </IconWrapperStyle>
                <Box sx={{}}>
                  <Typography variant='h3' sx={{ color: "black" }}>
                    {interViewStatistics.purchasedInterviews}
                  </Typography>
                  <Typography variant='subtitle2' component='p'>
                    {t("Purchased")}
                  </Typography>
                </Box>
              </Card>
            </Item>

            <Item className='col'>
              <HtmlTooltip
                onMouseEnter={handleTooltipOpen}
                onMouseLeave={handleTooltipClose}
                onClick={handleTooltipOpen}
                onTouchStart={handleTooltipOpen}
                open={showTooltip}
                onOpen={() => setShowTooltip(true)}
                onClose={() => setShowTooltip(false)}
                title={<React.Fragment>{getInterviewsText()}</React.Fragment>}
                disableFocusListener
                leaveTouchDelay={3000}
              >
                <Card
                  sx={{
                    py: 5,
                    boxShadow: "none",
                    flexDirection: "row",
                    display: "flex",
                    padding: "28px 20px",
                    minHeight: "118px",
                    bgcolor: "#eafffb",
                  }}
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  <IconWrapperStyle
                    className='iconWrap'
                    sx={{ backgroundColor: "#bffaef", mr: 2 }}
                  >
                    <img
                      src={Images.TrashLg}
                      alt='cart'
                      className='pbox-image'
                    />
                  </IconWrapperStyle>
                  <Box sx={{}}>
                    <Typography variant='h3' sx={{ color: "black" }}>
                      {interViewStatistics.usedInterviews}
                    </Typography>
                    <Typography variant='subtitle2' component='p'>
                      {t("Usedinterviews")}
                    </Typography>
                  </Box>
                </Card>
              </HtmlTooltip>
            </Item>

            <Item className='col'>
              <Card
                sx={{
                  py: 5,
                  boxShadow: "none",
                  flexDirection: "row",
                  display: "flex",
                  padding: "28px 20px",
                  minHeight: "118px",
                  bgcolor: "#fff3ec",
                }}
              >
                <IconWrapperStyle
                  className='iconWrap'
                  sx={{ backgroundColor: "#ffd9c2", mr: 2 }}
                >
                  <img src={Images.ClipLg} alt='cart' className='pbox-image' />
                </IconWrapperStyle>
                <Box sx={{}}>
                  <Typography variant='h3' sx={{ color: "black" }}>
                    {interViewStatistics.availableInterviews}
                  </Typography>
                  <Typography variant='subtitle2' component='p'>
                    {t("AvailableInterviews")}
                  </Typography>
                </Box>
              </Card>
            </Item>
          </Stack>
        </Grid>
      </Grid>

      {fetchingPlans
        ? renderSpinner("Fetching plans...")
        : parsedPlans.length > 0 && (
            <>
              <Box
                display='flex'
                sx={{
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                }}
                alignItems='center'
                justifyContent='space-between'
                marginBottom='15px'
                marginTop='20px'
              >
                <Stack direction={{ xs: "column" }}>
                  <Typography variant='h4'>{t("Subscription")}</Typography>
                  <Typography variant='subtitlte2'>
                    {t("ChoosePlanForYou")}
                  </Typography>
                  {/* <Stack direction='row' className='copen-box'>
                    <Box>
                      <Typography
                        component='h4'
                        sx={{ fontWeight: 600, mb: 1 }}
                      >
                        Apply coupon for your next billing
                      </Typography>
                      <Typography sx={{ color: "var(--success)" }}>
                        VDSRTE{" "}
                        <Typography sx={{ fontSize: "0.8rem", color: "#333" }}>
                          Get 50% discount
                        </Typography>
                      </Typography>
                    </Box>
                    <Button onClick={handleAddCoupen}>{t("Apply")}</Button>
                  </Stack> */}
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }}>
                  <ToggleWidget
                    plans={parsedPlans}
                    isChecked={isYearlyChecked}
                    setIsChecked={setIsYearlyChecked}
                  />
                  <Button onClick={toggleHelp}>
                    <HelpOutlineOutlinedIcon
                      sx={{ width: "28px", height: "28px", marginRight: "3px" }}
                    />
                    {t("NeedHelp")}
                  </Button>
                </Stack>
              </Box>
              <Grid container className='setting-subs'>
                <Grid item xs={12}>
                  <PlansList
                    t={t}
                    plans={parsedPlans}
                    subscribedPlan={subscribedPlan}
                    isOnTrial={isOnTrial}
                    isSubscriptionExpired={isSubscriptionExpired}
                    isYearlyChecked={isYearlyChecked}
                    // canManageBilling={canManageBilling}
                    onClickSubscribePlan={(planId) => subscribePlan(planId)}
                    onClickUnsubscribePlan={(planId) => unsubscribePlan(planId)}
                    onClickBuyInterviews={(planId) =>
                      buyInterviewsForPlan(planId)
                    }
                  />
                </Grid>
              </Grid>
            </>
          )}

      <ChangeCardModal
        open={isShowingChangeCard}
        isCardAttached={isCardAttached()}
        onCardChange={(updatedCard) => handleCardUpdate(updatedCard.last4)}
        onClose={toggleChangeCard}
      />

      <SubscribePlanModal
        open={isShowingSubscribePlan}
        plans={parsedPlans}
        planIdToSubscribe={planIdToSubscribe}
        isOnTrial={isOnTrial}
        onSubscribed={onPlanSubscribed}
        onClose={toggleSubscribePlan}
      />

      <UpgradePlanModal
        open={isShowingUpgradePlan}
        plans={parsedPlans}
        subscribedPlanId={subscribedPlan?.id}
        planIdToSubscribe={planIdToSubscribe}
        isOnTrial={isOnTrial}
        onSubscribed={onPlanSubscribed}
        onClose={toggleUpgradePlan}
      />

      <DowngradePlanModal
        open={isShowingDowngradePlan}
        plans={parsedPlans}
        subscribedPlanId={subscribedPlan?.id}
        planIdToSubscribe={planIdToSubscribe}
        currentSubscriptionExpiryDate={subscriptionExpiryDate}
        onSubscribed={onPlanSubscribed}
        onClose={toggleDowngradePlan}
      />

      <UnsubscribePlanModal
        open={isShowingUnsubscribePlan}
        planId={planIdToUnsubscribe}
        onUnsubscribed={onPlanUnsubscribed}
        onClose={toggleUnsubscribePlan}
      />

      <BuyInterviewsModal
        open={isShowingBuyInterviews}
        planId={subscribedPlan?.id}
        plans={parsedPlans}
        onInterviewsBought={onInterviewsBought}
        onClose={toggleBuyInterviews}
      />
      <HelpModal open={isShowingHelp} onClose={toggleHelp} t={t} />
      <DeleteAccount open={isShowingDataDelete} onClose={toggleDataDelete} />

      {toastProps && <Toast {...toastProps} onClose={handleToastOnClose} />}
    </>
  )
}
