import { useRef, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { alpha } from "@mui/material/styles"
import { Box, MenuItem, Stack, IconButton } from "@mui/material"
import MenuPopover from "../../components/MenuPopover"
import { useDispatch } from "react-redux"
import { updateLanguage } from "src/redux/actions/login"
import { useAuth } from "src/hooks/useAuth"
import "src/i18next"
// ----------------------------------------------------------------------

const LANGS = [
  {
    label: "English",
    icon: "/static/icons/ic_flag_en.svg",
    func: "en",
  },

  {
    label: "French",
    icon: "/static/icons/ic_flag_fr.svg",
    func: "fr",
  },
  {
    label: "Arabic",
    icon: "/static/icons/ic_flag_ar.svg",
    func: "ar",
  },
]

// ----------------------------------------------------------------------

export default function LanguagePopover({ selectedItem, setSelectedItem }) {
  const anchorRef = useRef(null)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  const { user } = useAuth()
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleLanguageSelect = (lang, item) => {
    i18n.changeLanguage(lang)
    setOpen(false)
    setSelectedItem(item)
    // Update the direction based on the selected language
    document.body.className = lang === "ar" ? "rtl" : "ltr"
    document.documentElement.dir = lang === "ar" ? "rtl" : "ltr"

    if (user && open) {
      const newFormData = new FormData()
      newFormData.append("lang", item)
      dispatch(updateLanguage(newFormData))
    }
  }

  useEffect(() => {
    if (user) {
      const selected = LANGS.find(
        (language) => language.label === user?.selected_language,
      )
      if (selected) handleLanguageSelect(selected.func, selected.label)
    }
  }, [user?.selected_language])

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          mx: "2px !important",
          padding: 0,
          width: "28px",
          height: "20px",
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity,
              ),
          }),
        }}
      >
        <img
          src={
            selectedItem
              ? LANGS.find((language) => language.label === selectedItem).icon
              : LANGS[0].icon
          }
          alt={LANGS[0].label}
          width='28px'
          height='20px'
        />
      </IconButton>

      <MenuPopover
        className='multiLanguageDropdown'
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option, index) => (
            <MenuItem
              key={option?.label}
              selected={option.value === LANGS[0].value}
              onClick={() => handleClose()}
              sx={{ minHeight: "30px" }}
            >
              <Stack
                direction='row'
                sx={{ minWidth: "100%" }}
                onClick={() => {
                  handleLanguageSelect(option.func, option.label)
                }}
              >
                <Box
                  component='img'
                  alt={option.label}
                  src={option.icon}
                  className='mx2'
                  sx={{ width: 28 }}
                  onClick={() => {
                    handleLanguageSelect(option.func, option.label)
                  }}
                />
                {t(option.label)}
              </Stack>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
