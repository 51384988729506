import * as ActionTypes from "../constants/subscription";
const initialState = { data: [] };

export const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.PLANS_ON_SUCCESS:
      return { ...state, data: action.payload, loaded: true };
    case ActionTypes.PLANS_ON_FAILURE:
      return action.error;
    case ActionTypes.ACTIVATEBILL_ON_SUCCESS:
      return { ...state, billing: true };
    case ActionTypes.ACTIVATEBILL_ON_FAILURE:
      return action.error;
    case ActionTypes.CHANGEPLAN_ON_SUCCESS:
      return { ...state, changed: true };
    case ActionTypes.CHANGEPLAN_ON_FAILURE:
      return action.error;
    case ActionTypes.UPGRADESUBSCRIPTION_ON_SUCCESS:
      return { ...state, upgraded: true };
    case ActionTypes.UPGRADESUBSCRIPTION_ON_FAILURE:
      return action.error;
    case ActionTypes.SELECTED_PLAN:
      return { ...state, selected: action.payload };

    default:
      return state;
  }
};

export const getPlansReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.PLANS_ON_SUCCESS:
      return { ...state, plans: action.payload };
    case ActionTypes.PLANS_ON_FAILURE:
      return action.error;

    default:
      return state;
  }
};

export const getSubscriptionInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.SUBSCRIPTIONINFO_ON_SUCCESS:
      return { ...state, subscription_info: action.payload };
    case ActionTypes.SUBSCRIPTIONINFO_ON_FAILURE:
      return action.error;

    default:
      return state;
  }
};

export const activateBillingReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.ACTIVATEBILL_ON_SUCCESS:
      return { ...state, subscription_info: action.payload, activated: true };
    case ActionTypes.ACTIVATEBILL_ON_FAILURE:
      return action.error;

    default:
      return state;
  }
};

export const createSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CREATESUBSCRIPTION_ON_SUCCESS:
      return { ...state, subscription_info: action.payload };
    case ActionTypes.CREATESUBSCRIPTION_ON_FAILURE:
      return {...state, error: action.payload.errors};

    default:
      return state;
  }
};

export const cancelSubscriptionReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.CANCELSUBSCRIPTION_ON_SUCCESS:
      return { ...state, cancel_subscription_status: action.payload };
    case ActionTypes.CANCELSUBSCRIPTION_ON_FAILURE:
      return action.error;

    default:
      return state;
  }
};

export const buyInterviewsReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.BUYINTERVIEW_ON_SUCCESS:
      return { ...state, bought: true };
    case ActionTypes.BUYINTERVIEW_ON_FAILURE:
      return action.error;

    default:
      return state;
  }
};
