import React, { useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import LoadingButton from "@mui/lab/LoadingButton"
import { loadStripe } from "@stripe/stripe-js/pure"
import MuiAlert from "@mui/material/Alert"
import validator from "validator"
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import {
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Snackbar,
} from "@mui/material"
import { useTranslation } from "react-i18next"

loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)

const WrapperSignupCardModal = (props) => (
  <Elements stripe={stripePromise}>
    <ConnectSignUpCard {...props} />
  </Elements>
)
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function ConnectSignUpCard({
  open,
  isCardAttached,
  onCardChange,
  onClose,
  setFieldValue,
  getFieldProps,
  onCardAdd,
}) {
  const stripe = useStripe()
  const elements = useElements()

  const { t } = useTranslation()
  const [message, setMessage] = useState("")
  const [isValidatingCard, setIsValidatingCard] = useState(false)
  const [isCardElementReady, setIsCardElementReady] = useState(false)

  const [displayCrash, setDisplayCrash] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorSeverity, setSeverity] = useState("")

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  const [cardHolderName, setCardHolderName] = useState("")
  const [billingEmail, setBillingEmail] = useState("")
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const CardElementOptions = {
    style: {
      base: {},
      invalid: {},
    },
    hidePostalCode: true,
  }

  const handleSubmit = async () => {
    setSubmitted(true)

    if (cardHolderName === "") {
      setMessage(t("EnterCardHolderName"))
      return
    } else if (billingEmail === "") {
      setMessage(t("EnterBillingEmail"))
      return
    } else if (!isValidEmail) {
      setMessage(t("ErrInvalidEmail"))
      return
    }

    if (stripe && elements && elements.getElement(CardElement)) {
      setMessage("")
      setIsValidatingCard(true)
      const card = elements.getElement(CardElement)
      await stripe
        .createToken(card, { name: cardHolderName })
        .then((result) => {
          if (result.token) {
            const { name, last4, exp_month, exp_year, brand } =
              result.token.card
            if (result?.token) {
              setSeverity("success")
              setOpenSnackbar(true)
              setTimeout(handleClose, 1000)
              setDisplayCrash(t("CardConnectedSucessfully"))
            }
            setIsValidatingCard(false)
            localStorage.setItem("plan_token", result?.token?.id)
            const cardData = {
              name: name,
              last4,
              expMonth: exp_month,
              expYear: exp_year,
              brand,
              billingEmail: billingEmail,
            }

            if (onCardAdd) {
              onCardAdd(cardData)
            }
          } else if (result.error) {
            setIsValidatingCard(false)
            setMessage(result?.error?.message)
          }
        })
    }
  }

  const handleClose = () => {
    setIsValidatingCard(false)
    setMessage("")
    setSeverity("")
    setOpenSnackbar(false)
    setDisplayCrash("")
    onClose()
  }

  const handleCardHolderNameChange = (e) => {
    setSubmitted(false)
    setCardHolderName(e.target.value)
  }

  const handleBillingEmailChange = (e) => {
    const { value } = e.target

    const isValid = validator.isEmail(value)
    setIsValidEmail(isValid)
    setBillingEmail(value)
    setSubmitted(false)
  }

  return (
    <Dialog
      className='dialog400'
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      scroll='paper'
      maxWidth='md'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        {t("AddCardNumber")}
        <IconButton
          aria-label='close'
          onClick={!isValidatingCard && handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={errorSeverity}
          sx={{ width: "100%" }}
        >
          {displayCrash}
        </Alert>
      </Snackbar>
      <DialogContent
        className='dialog-content'
        dividers={true}
        sx={{ minHeight: "100px" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel
                required
                htmlFor='card-holder-name'
                sx={{ marginBottom: "5px" }}
              >
                {t("CardHolderName")}
              </FormLabel>
              <TextField
                id='card-holder-name'
                fullWidth
                variant='outlined'
                name='name'
                placeholder={t("CardHolderName")}
                value={cardHolderName}
                onChange={handleCardHolderNameChange}
              />

              <FormLabel
                required
                htmlFor='card-holder-billing-email'
                sx={{ marginTop: "20px", marginBottom: "5px" }}
              >
                {t("BillingEmail")}
              </FormLabel>
              <TextField
                id='card-holder-billing-email'
                fullWidth
                variant='outlined'
                name='name'
                type='email'
                placeholder={t("BillingEmail")}
                value={billingEmail}
                onChange={handleBillingEmailChange}
              />

              <FormLabel
                required
                htmlFor='card-element'
                sx={{ marginTop: "20px", marginBottom: "10px" }}
              >
                {t("CardNumber")}
              </FormLabel>
              <CardElement
                id='card-element'
                option={CardElementOptions}
                onReady={() => setIsCardElementReady(true)}
              />
            </FormControl>
          </Grid>
          {message && submitted && (
            <Grid item xs={12}>
              <Alert variant='outlined' severity='error'>
                {message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button
          onClick={handleClose}
          //   disabled={isValidatingCard}
          variant='outlined'
          sx={{ mx: 1 }}
          color='primary'
          size='medium'
        >
          {t("Cancel")}
        </Button>

        <LoadingButton
          onClick={handleSubmit}
          loading={isValidatingCard}
          autoFocus
          variant='contained'
          color='secondary'
          size='medium'
          disabled={!isCardElementReady}
        >
          {t("Add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default WrapperSignupCardModal
