import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "react-router-dom"
import jsPDF from "jspdf"
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { container } from "src/redux/actions/container"
import { useNavigate } from "react-router-dom"
import { AddRoleModal, CompleteRoleModal, ViewVcancyModal } from "../roles"
import DeleteInterviewModal from "src/sections/@dashboard/interview/modal/deleteInterview/DeleteInterviewModal"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import { isEmpty } from "lodash"
import "jspdf-autotable"
import { deleteSampleData } from "src/redux/actions/accountConfig"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined"
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined"
import WorkOffOutlinedIcon from "@mui/icons-material/WorkOffOutlined"
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined"
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined"
// ----------------------------------------------------------------------

export default function RolesMoreMenu({
  id,
  flag,
  match,
  canViewInterviewModule = true,
  canViewCompletedInterview = true,
  canViewCandidateModule = true,
  canViewCandidateActiveVac = true,
  canViewCandidateCompletedVac = true,
  canArchiveCompleted = true,
  canCloseOpenVac = true,
  canDeleteArchiveVac = true,
  canEditOpenVac = true,
  disabled = false,
  isSample = false,
}) {
  const navigate = useNavigate()
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isShowingRole, toggleRole] = useModal()
  const [isShowingCompleteRole, toggleCompleteRole] = useModal()
  const [isViewVacancy, toggleViewVacancy] = useModal()

  const [matchedData, setMatchedData] = useState()
  const dispatch = useDispatch()
  const [isShowingDeleteInt, toggleDeleteInt] = useModal()
  const [type, setType] = useState()
  const [arcFlag, setArcFlag] = useState(false)
  const [showCandidate, setShowCandidate] = useState(false)

  const handleArchived = () => {
    toggleDeleteInt()
    setMatchedData(match)
    setType("role-archive")
    setArcFlag(true)
  }
  const handleDelete = () => {
    toggleDeleteInt()
    setMatchedData(match)
    setType("role")
  }

  const handleEdit = () => {
    toggleRole()

    setMatchedData(match)
    setIsOpen(false)
  }

  const roleStatus = (selectedCandidates = false) => {
    toggleCompleteRole()
    setShowCandidate(selectedCandidates)
    setMatchedData(match)
    setIsOpen(false)
  }

  const handleCandidate = () => {
    dispatch(container(match?.id))
    window.history.pushState(null, "", "/dashboard/vacancies")

    navigate("/dashboard/candidates", {
      replace: true,
      state: { vacancyId: id, getVacancySpecificCandidates: true },
    })
  }
  const handleViewVacancy = () => {
    toggleViewVacancy()
    setMatchedData(match)
    setIsOpen(false)
  }

  const handleInterview = () => {
    dispatch(container(match?.id))
    window.history.pushState(null, "", "/dashboard/vacancies")

    navigate("/dashboard/interviews", {
      replace: true,
      state: { vacancyId: id, getVacancySpecificInterviews: true },
    })
  }

  const handleSampleDelete = () => {
    let creationInfo = JSON.parse(sessionStorage.getItem("creation_info"))
    creationInfo = { ...creationInfo, vacancy: true }
    sessionStorage.setItem("creation_info", JSON.stringify(creationInfo))
    const formData = {
      sample_data: creationInfo,
    }
    setIsOpen(false)
    dispatch(deleteSampleData(formData))
  }

  const logged = useSelector((state) => state.login)
  const handleScheduleInterview = () => {}
  const { t } = useTranslation()
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} disabled={disabled}>
        <MoreVertIcon width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {[
          flag === "active" &&
            canViewInterviewModule &&
            canViewCompletedInterview && (
              <MenuItem
                key='viewInterviews'
                sx={{ color: "text.secondary" }}
                onClick={handleInterview}
                disabled={isSample}
              >
                <ListItemIcon>
                  <ContentPasteSearchOutlinedIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("ViewInterviews")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            ),
          <MenuItem
            key='scheduleInterview'
            sx={{ color: "text.secondary", display: "none" }}
            onClick={handleScheduleInterview}
            disabled={isSample}
          >
            <ListItemIcon>
              <VideoCallOutlinedIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t("ScheduleInterview")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>,
          flag === "active" &&
            canViewCandidateModule &&
            canViewCandidateActiveVac && (
              <MenuItem
                key='viewCandidates'
                sx={{ color: "text.secondary" }}
                disabled={isSample}
              >
                <ListItemIcon>
                  <AssignmentIndOutlinedIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  onClick={handleCandidate}
                  primary={t("ViewCandidates")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            ),
          flag === "active" &&
            canCloseOpenVac && [
              <MenuItem
                key='closeVacancy'
                sx={{ color: "text.secondary" }}
                component={RouterLink}
                to='#'
                onClick={(e) => roleStatus(false)}
                disabled={isSample}
              >
                <ListItemIcon>
                  <WorkOffOutlinedIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("CloseVacancy")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
              <MenuItem
                key='selectCandidates'
                sx={{ color: "text.secondary" }}
                component={RouterLink}
                to='#'
                onClick={(e) => roleStatus(true)}
                disabled={isSample}
              >
                <ListItemIcon>
                  <PersonAddOutlinedIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("SelectCandidates")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ],
          flag === "active" && canEditOpenVac && (
            <MenuItem
              key='edit'
              component={RouterLink}
              to='#'
              sx={{ color: "text.secondary" }}
              onClick={handleEdit}
              disabled={isSample}
            >
              <ListItemIcon>
                <EditIcon width={24} height={24} fontSize='medium' />
              </ListItemIcon>
              <ListItemText
                primary={t("Edit")}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>
          ),
          flag === "active" &&
            isSample && [
              <MenuItem
                key='delete'
                sx={{ color: "text.secondary" }}
                onClick={() => {
                  setType("role")
                  toggleDeleteInt()
                }}
              >
                <ListItemIcon>
                  <DeleteOutlineIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("Delete")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ],
          flag === "completed" &&
            canViewCandidateModule &&
            canViewCandidateCompletedVac && (
              <MenuItem key='viewCandidates' sx={{ color: "text.secondary" }}>
                <ListItemIcon>
                  <AssignmentIndOutlinedIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  onClick={handleCandidate}
                  primary={t("ViewCandidates")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>
            ),
          flag === "completed" &&
            canArchiveCompleted && [
              <MenuItem
                key='archive'
                sx={{ color: "text.secondary" }}
                onClick={handleArchived}
              >
                <ListItemIcon>
                  <Inventory2OutlinedIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("Archive")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ],
          flag === "archived" &&
            canDeleteArchiveVac && [
              <MenuItem
                key='delete'
                sx={{ color: "text.secondary" }}
                onClick={handleDelete}
              >
                <ListItemIcon>
                  <DeleteOutlineIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("Delete")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ],
          <MenuItem
            key='viewVacancy'
            sx={{ color: "text.secondary" }}
            onClick={handleViewVacancy}
          >
            <ListItemIcon>
              <SimCardDownloadOutlinedIcon width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={t("View")}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>,
        ]}
      </Menu>

      {isShowingRole && (
        <AddRoleModal
          isShowingRole={isShowingRole}
          hide={toggleRole}
          flag={false}
          data={matchedData}
        />
      )}

      <CompleteRoleModal
        data={matchedData}
        isShowingCompleteRole={isShowingCompleteRole}
        showCandidate={showCandidate}
        hide={() => {
          setMatchedData(null)
          toggleCompleteRole()
        }}
      />

      <ViewVcancyModal
        match={match}
        isViewVacancy={isViewVacancy}
        hide={() => {
          toggleViewVacancy()
        }}
      />

      <DeleteInterviewModal
        isShowingDeleteInt={isShowingDeleteInt}
        hide={toggleDeleteInt}
        data={matchedData}
        invoke={type}
        isArc={arcFlag}
        isSample={isSample}
        confirmAction={handleSampleDelete}
      />
    </>
  )
}
