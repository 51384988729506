import {  styled } from "@mui/material";
import PulseLoader from "react-spinners/PulseLoader";

const Spinner = styled("div")(({ theme }) => ({
  margin: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "fixed",
  right: "0",
  top: "0",
  left: 0,
  bottom: 0,
  background: "rgba(0,0,0,0.6)",
  zIndex: "99999",
}));
export default function Loader({ respLoading }) {

  return (
    <>
      {respLoading ? (
        <Spinner>
          <PulseLoader
            color='#2966EA'
            cssOverride={{}}
            loading={respLoading}
            margin={6}
            size={24}
            speedMultiplier={1}
          />
        </Spinner>
      ) : null}
    </>
  );
}
