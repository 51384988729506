import React, { useState, useEffect } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import LoadingButton from "@mui/lab/LoadingButton"
import Images from "src/assets/img/Images"
import MicRecorder from "mic-recorder-to-mp3"
import { checks } from "src/redux/actions/handler"
import {
  Box,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"

export default function AudioCheckModal({ open, onClose, hide }) {
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 }),
  )

  const dispatch = useDispatch()
  const [isRecording, setIsRecording] = useState(false)
  const [blobURL, setBlobURL] = useState(null)
  const [isBlocked, setIsBlocked] = useState(false)

  useEffect(() => {
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        setIsBlocked(false)
      },
      () => {
        setIsBlocked(true)
      },
    )
  }, [])
  const handleClose = () => {
    onClose()
  }

  const start = () => {
    if (isBlocked) {
      console.log("Permission Denied")
    } else {
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true)
        })
        .catch((e) => console.error(e))
    }
  }

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob)
        setBlobURL(blobURL)
        setIsRecording(false)
      })
      .catch((e) => console.log(e))
  }

  return (
    <Dialog
      className='dialog400'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='md'
    >
      <DialogTitle
        className='dialog-title'
        sx={{
          justifyContent: "space-between",
          display: "flex",
          height: "46px",
        }}
      >
        Please Check your Audio
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className='dialog-content' dividers={true}>
        <Grid container spacing={2} className='audio-record-modal'>
          <Grid item xs={12}>
            <Box>
              <Typography
                variant='subtitle2'
                component='p'
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "20px",
                  justifyContent: "center",
                  color: "#64748b",
                }}
              >
                {isRecording ? (
                  <>
                    <img
                      alt=''
                      hidden={isRecording === false}
                      src={Images.Recording}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "5px",
                        marginTop: "-3px",
                      }}
                    />
                    {"Recording"}
                  </>
                ) : (
                  <>
                    <audio src={blobURL} controls='controls' />
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions
        className='dialog-footer'
        sx={{ justifyContent: "space-between" }}
      >
        {blobURL ? (
          <>
            <Button
              onClick={() => {
                setBlobURL(null)
                setIsRecording(true)
                setTimeout(() => {
                  setIsRecording(false)
                }, 50)
                dispatch(checks("MIC", false))
                // handleClose();
              }}
              variant='outlined'
              color='secondary'
              size='medium'
            >
              Not Working
            </Button>

            <LoadingButton
              //
              onClick={() => {
                dispatch(checks("MIC", true))
                handleClose()
              }}
              variant='contained'
              color='secondary'
              size='medium'
            >
              It's Working
            </LoadingButton>
          </>
        ) : (
          <>
            {isRecording ? (
              <>
                <Button
                  onClick={stop}
                  variant='contained'
                  color='secondary'
                  size='medium'
                >
                  Stop Recording
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={start}
                  variant='contained'
                  color='secondary'
                  size='medium'
                >
                  Start Recording
                </Button>
              </>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}
