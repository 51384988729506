import { LiveHeader } from ".."
import TimerInt from "./TimerInt"
import { useDispatch } from "react-redux"
import { styled } from "@mui/material/styles"
import { useCamera } from "src/hooks/useCamera"
import getBlobDuration from "get-blob-duration"
import LoadingButton from "@mui/lab/LoadingButton"
import { useVideoUpload } from "src/hooks/useVideoUpload"
import { useNavigate, useLocation } from "react-router-dom"
import { useState, useEffect, useRef, createRef, useMemo } from "react"
import { finishInterview } from "src/redux/actions/interviews"
import {
  Box,
  Container,
  Typography,
  Grid,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Stack,
} from "@mui/material"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { TextField } from "@mui/material"
import { currentBrowserName } from "src/utils/userHelper"
import { reloadI18n } from "src/i18next"

const RootStyle = styled("div")(({ theme }) => ({
  border: " 1px solid #e2e8f0",
  background: "white",
  boxSizing: "border-box",
  borderRadius: "10px",
}))

const Panel = styled("div")(({ theme }) => ({
  padding: "1.8rem 2.8rem 0 2.8rem ",
}))

export default function RecordedInt() {
  const minRecordingDuration = 500 // 0.5 seconds
  const recordingFilesSources = useRef([])
  const isTimeUp = useRef(false)
  const questionNo = useRef(1)
  const [question, setQuestion] = useState(null)
  const [interviewQuestion, setInterviewQuestion] = useState(null)
  const [started, setStarted] = useState(false)
  const [finished, setFinished] = useState(false)
  const [canProceed, setCanProceed] = useState(false)
  let uploadingVideos = useRef([])
  const activeQuestionRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const defaultInterview = {
    candidate_vacancy: { vacancy: { interview_time: "0" } },
    interview_questions: [],
  }
  const interview = location?.state?.interview ?? defaultInterview
  const interviewCode = location?.state?.interview?.code ?? ""
  const [candidateAnswer, setCandidateAnswer] = useState(null)
  const [isSafari, setIsSafari] = useState(null)

  const {
    camera,
    loading: cameraLoading,
    detected: cameraDetected,
    startRecording,
    stopRecording,
    getSupportedMediaType,
  } = useCamera()

  const { uploadVideo, clear: clearUploadingVideosList } = useVideoUpload()

  const {
    candidate_vacancy: {
      vacancy: { interview_time: interviewTime },
    },
    interview_questions: questions,
  } = interview

  const totalInterviewTimeInMilliseconds = useMemo(() => {
    return parseInt(interviewTime.replace(" min", "")) * 60 * 1000
  })

  useEffect(() => {
    clearUploadingVideosList()
    setIsSafari(
      window?.navigator?.userAgent?.includes("Safari") &&
        !window?.navigator?.userAgent.includes("Chrome"),
    )
    const onBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = ""
    }
    window.onbeforeunload = onBeforeUnload
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  useEffect(() => {
    const handleTabRefresh = () => {
      dispatch(finishInterview({ code: interviewCode }))
      window.history.replaceState({}, document.title)
    }

    if (!location?.state?.interview) {
      sessionStorage.removeItem("recordings")
      navigate("/Home/app", {
        replace: true,
      })
      reloadI18n()
    }

    window.addEventListener("beforeunload", handleTabRefresh)

    return () => {
      window.removeEventListener("beforeunload", handleTabRefresh)
    }
  }, [])

  useEffect(() => {
    if (!interviewQuestion && questions && questions.length > 0) {
      setInterviewQuestion(questions[0])
    }
  }, [questions])

  useEffect(() => {
    if (cameraDetected && !cameraLoading) {
      startRecording()
      disableNextButtonForSomeTime()
      setOnTimesUp()
      setStarted(true)
    }
  }, [cameraDetected, cameraLoading])

  const disableNextButtonForSomeTime = () => {
    setCanProceed(false)

    setTimeout(() => {
      setCanProceed(true)
    }, minRecordingDuration)
  }

  const proceedToNext = () => {
    const currentQuesIndex = questionNo.current - 1
    const nextQuesIndex = questionNo.current
    const currentQuesNo = questionNo.current

    stopRecording((recordedBlob) => {
      let blob = new Blob([recordedBlob], { type: getSupportedMediaType() })

      const file = new File([blob], `ans_${currentQuesNo}.mp4`, {
        type: "video/mp4",
      })
      const fileSrc = URL.createObjectURL(file)

      recordingFilesSources.current.push(fileSrc)

      getBlobDuration(blob)
        .then((duration) => {
          const params = {
            // interview_id: interviewId,
            // question_id: questions[currentQuesIndex]?.id,
            interview_question: questions[currentQuesIndex]?.id,
            duration: duration,
            candidate_answer: candidateAnswer,
            transcode: isSafari,
            browser_name: currentBrowserName(),
          }
          uploadVideo(file, duration, params)

          const uploadingVideo = {
            file: file,
            duration: duration,
            params: params,
            candidate_answer: candidateAnswer,
            transcode: isSafari,
          }

          let uploadingVideosList = [...uploadingVideos.current]
          uploadingVideosList.push(uploadingVideo)
          uploadingVideos.current = uploadingVideosList

          if (currentQuesNo < questions.length && !isTimeUp.current) {
            questionNo.current = questionNo.current + 1
            setInterviewQuestion(questions[nextQuesIndex])
            startRecording()
            disableNextButtonForSomeTime()
            setCandidateAnswer("")

            // Scroll to the active question
            if (activeQuestionRef.current) {
              activeQuestionRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
              })
            }
          } else {
            endInterview()
          }
        })
        .catch((err) => {
          alert(err?.message)
        })
    })
  }

  const endInterview = () => {
    setStarted(false)
    setFinished(true)

    dispatch(
      finishInterview({
        code: interviewCode,
        transcode: isSafari,
        status:
          questionNo.current === questions.length
            ? "completed"
            : "partially_completed",
      }),
    )

    setTimeout(() => {
      navigate("/recorded-interview/review", {
        replace: true,
        state: {
          interview: interview,
          recordingFilesSources: recordingFilesSources.current,
          uploadingVideos: uploadingVideos.current,
          status:
            questionNo.current === questions.length
              ? "completed"
              : "partially_completed",
        },
      })
    }, 5000)
  }

  // const loadNextQuestion = () => {
  //   const currentQuesIndex = questionNo.current - 1;
  //   const nextQuesIndex = questionNo.current;
  //   const currentQuesNo = questionNo.current;

  //   stopRecordingForQuestion(questions[currentQuesIndex], currentQuesNo);

  //   if (currentQuesNo < questions.length) {
  //     questionNo.current = questionNo.current + 1;
  //     setQuestion(questions[nextQuesIndex]);
  //     startRecording();
  //   } else {
  //     endInterview();
  //   }
  // };

  // const stopRecordingForQuestion = (question, questionNo) => {
  //   stopRecording((blob) => {
  //     uploadAnswerRecordingForQuestion(blob, question, questionNo);
  //   });
  // };

  // const uploadAnswerRecordingForQuestion = (blob, question, questionNo) => {
  //   const file = new File([blob], `ans_${questionNo}`);

  //   getBlobDuration(blob).then((duration) => {
  //     const params = {
  //       interview_id: interviewId,
  //       question_id: question?.id,
  //       duration: duration,
  //     };
  //     uploadVideo(file, duration, params);
  //   });
  // };

  const setOnTimesUp = () => {
    setTimeout(() => {
      isTimeUp.current = true
      proceedToNext()
    }, totalInterviewTimeInMilliseconds)
  }

  const [selectedOption, setSelectedOption] = useState("")
  const handleOptionChange = (event) => {
    const selectedValue = event.target.value
    setSelectedOption(selectedValue)
    setCandidateAnswer(selectedValue)
  }
  const handleChangePaste = (e) => {
    e.preventDefault()
  }

  const [selectedLink, setSelectedLink] = useState(null)
  const questionRefs = useRef(questions.map(() => createRef()))
  useEffect(() => {
    if (questionRefs.current[questionNo.current - 1]) {
      const ref = questionRefs.current[questionNo.current - 1].current

      if (ref) {
        ref.scrollIntoView({
          behavior: "smooth",
          block: "start",
        })
      }
    }
  }, [questionNo])

  const handleLinkClick = (id) => {
    setSelectedLink(id)
  }
  return (
    <Container
      className='int-startup xxl-screen'
      maxWidth='xl'
      spacing={3}
      sx={{
        maxHeight: "100vh",
        pb: 2,
        boxSizing: "border-box",
      }}
    >
      <Box
        className='candidate-sc-logo recorded-screen'
        sx={{ justifyContent: "center", display: "flex" }}
      >
        <LiveHeader />
      </Box>
      <RootStyle>
        <Panel
          className='parent'
          sx={{
            pl: { xs: "1.0rem", sm: "1.4rem" },
            pr: { xs: "1.0rem", sm: "1.4rem" },
          }}
        >
          <Grid container spacing={2} className='sub-parent'>
            <Grid item lg={7} md={6} sm={12} xs={12} className='item-child'>
              <Box>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent={{ xs: "felx-start", sm: "space-between" }}
                  sx={{
                    position: "sticky",
                    top: 0,
                    background: "#fff",
                    borderBottom: "1px solid #E5E7EB",
                    paddingBottom: "3px",
                    marginBottom: "8px",
                  }}
                >
                  <Box>
                    <Typography
                      variant='h5'
                      component='h5'
                      sx={{ fontSize: "16px", fontWeight: "600" }}
                    >
                      {interview?.candidate_vacancy?.candidate?.first_name +
                        " " +
                        interview?.candidate_vacancy?.candidate?.last_name}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "#66696E",
                      }}
                    >
                      {interview?.candidate_vacancy?.vacancy?.title}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      pt: 0,
                    }}
                  >
                    {started && (
                      <TimerInt
                        totalTime={totalInterviewTimeInMilliseconds}
                        stop={finished}
                      />
                    )}
                  </Box>
                </Stack>
                <Stack>
                  <Typography
                    variant='h5'
                    component='h5'
                    sx={{
                      color: "#1E293B",
                      fontWeight: 600,
                      fontSize: {
                        xs: "0.9rem",
                        md: "18px !important",
                      },
                      lineHeight: { sm: "20px", md: "28px" },
                      width: { sm: "100%", md: "88%" },
                      display: "block",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: { sm: "normal", md: "nowrap" },
                    }}
                  >
                    Q {questionNo.current}.&nbsp;
                    {interviewQuestion?.question?.text}
                  </Typography>
                </Stack>
                <Box
                  className='-item-child-sub'
                  sx={{
                    overflowY: "auto",
                    height: {
                      xs: "auto",
                      sm: "auto",
                      md: "calc(90vh - 205px)",
                    },
                    pr: 2,
                    pb: { sm: "8px", md: "46px" },
                    overflowX: "hidden",
                  }}
                >
                  <Stack
                    sx={{ pt: 1, pb: { xs: 1, sm: 2 }, mt: 0 }}
                    className='video-for-candidate'
                  >
                    {camera()}
                  </Stack>

                  <Box className='first-opt'>
                    {interviewQuestion?.question?.question_type ===
                    "Multiple Choice" ? (
                      <Stack className='option-part'>
                        <Box
                          style={{
                            justifyContent: "flex-start",
                            mt: "1rem",
                            mb: "1rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant='body1'
                            sx={{
                              fontSize: "12px",
                              fontWeight: "600",
                              pb: 2,
                              mb: 0,
                              background: "#eff6ff",
                              position: "sticky",
                            }}
                          >
                            Select from the following
                          </Typography>

                          <FormControl
                            fullWidth
                            sx={{
                              overflowY: "auto",
                            }}
                          >
                            <RadioGroup
                              name='radio-group'
                              sx={{ mt: 1, mb: 0 }}
                              value={selectedOption}
                              onChange={handleOptionChange}
                              className='recorded-mcq'
                            >
                              {interviewQuestion?.question?.choices.map(
                                (str, index) => (
                                  <FormControlLabel
                                    key={str}
                                    id={`radio-${index}`}
                                    control={<Radio />}
                                    label={str}
                                    checked={selectedOption === str}
                                    value={str}
                                    sx={{
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      color: "#49454F",
                                      lineHeight: "24px",
                                      letterSpacing: "0.5px",
                                      textTransform: "capitalize",
                                      alignItems: "flex-start",
                                      mb: 1,
                                    }}
                                  />
                                ),
                              )}
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Stack>
                    ) : (
                      <Stack className='option-part'>
                        <Box sx={{ pb: 0 }} xs={12}>
                          <FormControl fullWidth sx={{ mb: 0 }}>
                            <Typography
                              variant='body1'
                              sx={{
                                fontSize: "12px",
                                fontWeight: "600",
                                marginBottom: "10px",
                              }}
                            >
                              Insert answer below (optional)
                            </Typography>
                            <TextField
                              fullWidth
                              multiline
                              rows={4}
                              maxLength={200}
                              className='candidate_answer'
                              name='candidate_answer'
                              placeholder='Type answer...'
                              value={candidateAnswer}
                              onChange={(event) =>
                                setCandidateAnswer(event.target.value)
                              }
                              onPaste={handleChangePaste}
                              onCopy={handleChangePaste}
                            />
                          </FormControl>
                        </Box>
                      </Stack>
                    )}
                  </Box>
                </Box>
                <Stack
                  justifyContent='flex-end'
                  direction='row'
                  sx={{
                    display: "flex",
                    my: 1,
                    position: "sticky",
                    bottom: 0,
                    pb: 1,
                    background: "var(--white)",
                  }}
                >
                  <LoadingButton
                    loading={finished}
                    variant='contained'
                    size='medium'
                    sx={{
                      fontSize: "14px !important",
                      maxHeight: "38px",
                      whiteSpace: "noWrap",
                    }}
                    endIcon={<ArrowForwardIosIcon sx={{ height: "16px" }} />}
                    onClick={() => {
                      proceedToNext()
                    }}
                    disabled={!canProceed}
                  >
                    Next
                  </LoadingButton>
                </Stack>
              </Box>
            </Grid>
            <Grid item lg={5} md={6} sm={12} xs={12} className='item0-child'>
              <Box
                className='item-child-sub2'
                sx={{
                  mb: { xs: 0, sm: 2 },
                  maxHeight: { xs: "200px", sm: "200px", md: "100%" },
                  overflowY: { xs: "auto" },
                }}
              >
                <Stack
                  direction='column'
                  justifyContent='space-between'
                  sx={{
                    borderBottom: "1px solid #E5E7EB",
                    paddingBottom: "3px",
                    marginBottom: "8px",
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    background: "#fff",
                  }}
                >
                  <Typography
                    variant='h5'
                    component='h5'
                    sx={{ fontSize: "16px", fontWeight: "600" }}
                  >
                    Interview Questions
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "500",
                      color: "#66696E",
                      marginBottom: "7px",
                    }}
                  >
                    Question no.{" "}
                    <strong>
                      {questionNo.current}/{questions.length}
                    </strong>
                  </Typography>
                </Stack>
                <Box
                  className='item-child-sub right-pane-scroll'
                  sx={{
                    pb: 2,
                    height: "calc(100% - 53px)",
                    maxHeight: {
                      // xs: "200px",
                      sm: "100%",
                      md: "calc(100vh - 250px)",
                    },
                  }}
                >
                  {questions?.map((question, index) => (
                    <Stack
                      key={question?.question?.id}
                      ref={
                        questionNo.current === index + 1
                          ? activeQuestionRef
                          : null
                      }
                      id={`question-${index + 1}`}
                      className={`list-col ${
                        questionNo.current === index + 1 ? "active" : ""
                      }`}
                      sx={{
                        mb: 1,
                        py: 1,
                        px: 2,
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: 600, wordWrap: "break-word" }}
                      >
                        Q {index + 1}.&nbsp;
                        {question?.question?.text}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Panel>
      </RootStyle>
    </Container>
  )
}
