import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import {
  Button,
  Alert,
  TextField,
  IconButton,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  CircularProgress,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { LoadingButton } from "@mui/lab"
import { useFormik, Form, FormikProvider, setNestedObjectValues } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { resetPassword } from "src/redux/actions/resetPassword"
import { useTranslation } from "react-i18next"

//************************** */

export default function PasswordResetModal({ isShowingResetPassword, hide }) {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const [flag, setFlag] = useState(null)
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  const resetPassData = useSelector((state) => state.resetPassword)

  const [passwordSent, setPasswordSent] = useState(false)
  const resetPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required(t("ErrEmailRequired")),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: (values, { resetForm, isSubmitting }) => {
      setLoading(true)
      setFlag(false)
      const newFormData = new FormData()
      newFormData.append("email", values.email)
      dispatch(resetPassword(newFormData))
    },
  })

  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik

  useEffect(() => {
    if (
      resetPassData?.data?.data?.success === true &&
      resetPassData?.status === 200
    ) {
      setLoading(false)
      setPasswordSent(true)
      setTimeout(() => {
        setPasswordSent(false)
      }, 5000)
    }

    if (
      resetPassData?.status === 404 ||
      resetPassData?.status === false ||
      resetPassData?.success === false
    ) {
      let errorMessage = "Enter correct email address"
      if (resetPassData?.message) {
        errorMessage = resetPassData.message
      }
      setFlag(true)
      setLoading(false)
      setError(errorMessage)
      setTimeout(() => {
        setFlag(false)
        setError("")
      }, 5000)
    }
    if (resetPassData?.response?.status === 200) {
      setFlag(null)
      setLoading(false)
    }
  }, [resetPassData])

  const onCloseModal = () => {
    setFlag(false)
    setError("")
    resetForm()
    hide()
  }

  /******************* */

  return (
    isShowingResetPassword && (
      <Dialog
        className='dialog400'
        open={isShowingResetPassword}
        onClose={onCloseModal}
        scroll='paper'
        maxWidth='md'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          Forgot Password
          <IconButton
            aria-label='close'
            onClick={onCloseModal}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <FormikProvider value={formik}>
            <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {passwordSent === true && (
                    <Alert
                      severity='success'
                      sx={{
                        mb: 3,
                        fontSize: 14,
                        lineHeight: "20px",
                        fontWeight: 400,
                      }}
                    >
                      A password reset link has been sent to your email address.
                    </Alert>
                  )}

                  <FormControl sx={{ width: "100%" }}>
                    <TextField
                      fullWidth
                      variant='outlined'
                      autoComplete=''
                      placeholder='Email Address'
                      name='forgotemail'
                      id='email'
                      {...getFieldProps("email")}
                      error={touched.email && Boolean(errors.email)}
                      helperText={touched.email && errors.email}
                    />
                  </FormControl>
                  {flag && (
                    <Alert
                      variant='outlined'
                      sx={{ marginTop: "2vh" }}
                      severity='error'
                    >
                      {error}
                    </Alert>
                  )}
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>

        <DialogActions className='dialog-footer'>
          {passwordSent === true ? (
            <Button
              onClick={onCloseModal}
              variant='outlined'
              color='primary'
              size='medium'
            >
              Close
            </Button>
          ) : (
            <LoadingButton
              size='medium'
              type='submit'
              variant='contained'
              onClick={handleSubmit}
              disabled={loading}
              loading={loading}
              loadingIndicator={<CircularProgress size={15} />}
            >
              Reset Password
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    )
  )
}
