import * as ActionTypes from "src/redux/constants/loading";

const initialState = {
  loading: false,
};
const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.API_LOADING_START:
      return {
        ...state,
        loading:true,
      };
      case ActionTypes.API_LOADING_END:
        return {
          ...state,
          loading:false,
        };
    default:
      return state;
  }
};

export default loadingReducer;