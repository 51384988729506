import styled from "styled-components"

export const FileUploadContainer = styled.section`
  position: relative;
  margin: 0px 0 10px;
  border: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: white;
`

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 192px;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`

export const InputLabel = styled.label`
  top: -21px;
  font-size: 13px;
  color: black;
  left: 0;
  position: absolute;
`

export const DragDropText = styled.p`
  font-weight: bold;
  line-height: 1.4rem;
  margin-top: 0;
  text-align: center;
  text-transform: capitalize;
  span {
    font-size: 0.8rem;
    text-transform: lowercase;
    display: block;
    margin-top: 0;
    text-align: center;
  }
  &:hover {
    color: #fff !important;
  }
`

export const UploadFileBtn = styled.button`
  box-sizing: border-box;
  margin-top: 0;
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--slate300);
  cursor: pointer;
  font-size: 1rem;
  line-height: 1;
  padding: 1.4em 2.8em;
  color: #637381;
  text-align: center;
  text-transform: capitalize;
  font-weight: 700;
  border-radius: 6px;

  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 250ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  @media only screen and (max-width: 460px) {
    flex-direction: column;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: var(--info);
    z-index: -1;
    transition: width 250ms ease-in-out;
  }

  svg {
    font-size: 20px;
    margin: 5px;
    width: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    color: #fff;
    outline: 0;
    background: transparent;

    &:after {
      width: 110%;
    }
  }

  &:focus {
    outline: 0;
    background: transparent;
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
`

export const FilePreviewContainer = styled.article`
  margin-bottom: 10px;
  direction: ltr;
  margin-top: 10px;
  background: #eeeff0;
  padding: 0.8rem;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  span {
    font-size: 12px;
    width: 88%;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`

export const PreviewList = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`

export const RemoveFileIcon = styled.i`
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 2px;
  &:hover {
    transform: scale(1.3);
  }
`

export const PreviewContainer = styled.section`
  padding: 0.25rem;
  max-width: 54px;
  max-height: 54px;
  border-radius: 6px;
  box-sizing: border-box;
  margin-right: 4px;
  & > div:first-of-type {
    height: 100%;
    position: relative;
  }

  @media only screen and (max-width: 750px) {
    width: 130px;
  }

  @media only screen and (max-width: 500px) {
    width: 130px;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    padding: 0 0 0.4em;
  }
`

export const ImagePreview = styled.img`
  border-radius: 6px;
  object-fit: cover;
  width: 100%;
  height: 100%;
`
