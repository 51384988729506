import Icons from "src/components/Icons"
import PieChartIcon from "@mui/icons-material/PieChart"
import WorkIcon from "@mui/icons-material/Work"
import VideoChatIcon from "@mui/icons-material/VideoChat"
import QuizIcon from "@mui/icons-material/Quiz"
import PersonIcon from "@mui/icons-material/Person"
import LanIcon from "@mui/icons-material/Lan"
import PeopleIcon from "@mui/icons-material/People"
import SettingsIcon from "@mui/icons-material/Settings"
// ----------------------------------------------------------------------

const getIcon = (name) => <Icons icon={name} width={22} height={22} />
const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard/app",
    icon: getIcon(PieChartIcon),
    key: "dashboard_module",
  },
  {
    title: "Vacancies",
    path: "/dashboard/vacancies",
    icon: getIcon(WorkIcon),
    key: "vacancy_module",
  },
  {
    title: "Interviews",
    path: "/dashboard/interviews",
    icon: getIcon(VideoChatIcon),
    key: "interviews_module",
  },
  {
    title: "Questions",
    path: "/dashboard/question-sets",
    icon: getIcon(QuizIcon),
    key: "question_sets_module",
  },
  {
    title: "Candidates",
    path: "/dashboard/candidates",
    icon: getIcon(PersonIcon),
    key: "candidates_module",
  },
  {
    title: "Departments",
    path: "/dashboard/departments",
    icon: getIcon(LanIcon),
    key: "departments_module",
  },

  {
    title: "CompanyUsers",
    path: "/dashboard/companyUsers",
    icon: getIcon(PeopleIcon),
    key: "company_user_module",
  },
  {
    title: "Settings",
    path: "/dashboard/settings",
    icon: getIcon(SettingsIcon),
    key: "settings_module",
  },
]

export default navConfig
