import { request } from "../middlewares/service"
import * as ActionTypes from "../constants/invoices"

export const getAllInvoices = () => {
  const endpoint = `/api/admin/invoices`
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = null
  options.types = [
    ActionTypes.FETCHINVOICES_ON_SUCCESS,
    ActionTypes.FETCHINVOICES_ON_FAILURE,
  ]
  return request(options)
}

export const getInvoice = (id) => {
  const endpoint = `/api/admin/invoices/${id}`
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = null
  options.types = [
    ActionTypes.FETCHINVOICE_ON_SUCCESS,
    ActionTypes.FETCHINVOICE_ON_FAILURE,
  ]
  return request(options)
}

export const getMonthlyStatement = (data) => {
  const endpoint = `/api/admin/generate_monthly_statement`
  const method = "POST"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.MONTHLYSTATEMENT_ON_SUCCESS,
    ActionTypes.MONTHLYSTATEMENT_ON_FAILURE,
  ]
  return request(options)
}
