import * as ActionTypes from "../constants/updatePassword";
const initialState = { data: [], status: null };

const updatePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: action.payload.status,
        isSucceed: action.payload.success,
      };

      case ActionTypes.UPDATE_ON_FAILURE:
      return {
        ...state,
        data: action.payload,
        status: action.payload.status,
        isSucceed: action.payload.success,
      };

    default:
      return state;
  }
};

export default updatePasswordReducer;
