export const FETCHDEPARTMENTS_ON_SUCCESS = "FETCHDEPARTMENTS_ON_SUCCESS";
export const FETCHDEPARTMENTS_ON_FAILURE = "FETCHDEPARTMENTS_ON_FAILURE";
export const CREATEDEPARTMENT_ON_SUCCESS = "CREATEDEPARTMENT_ON_SUCCESS";
export const CREATEDEPARTMENT_ON_FAILURE = "CREATEDEPARTMENT_ON_FAILURE";
export const DELETEDEPARTMENT_ON_SUCCESS = "DELETEDEPARTMENT_ON_SUCCESS";
export const DELETE_SELECTED_DEPARTMENTS_ON_SUCCESS = "DELETE_SELECTED_DEPARTMENTS_ON_SUCCESS";
export const DELETEDEPARTMENT_ON_FAILURE = "DELETEDEPARTMENT_ON_FAILURE";
export const DELETE_SELECTED_DEPARTMENTS_ON_FAILURE = "DELETE_SELECTED_DEPARTMENTS_ON_FAILURE";
export const EDITDEPARTMENT_ON_SUCCESS = "EDITDEPARTMENT_ON_SUCCESS";
export const EDITDEPARTMENT_ON_FAILURE = "EDITDEPARTMENT_ON_FAILURE";
export const RESET_FLAGS = "RESET_FLAGS"
export const RESET_CREATED = "RESET_CREATED"