import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/roles";

export const getAllRoles = (status=null, filters=null) => {
  const endpoint = "/api/v1/vacancies";
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };
  options.body = null;
  options.params = { status: status, filters: filters }
  options.types = [
    ActionTypes.FETCHROLES_ON_SUCCESS,
    ActionTypes.FETCHROLES_ON_FAILURE,
  ];

  return request(options);
};

export const createRole = (data) => {
  const endpoint = "/api/v1/vacancies";
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CREATEROLE_ON_SUCCESS,
    ActionTypes.CREATEROLE_ON_FAILURE,
  ];

  return request(options);
};

export const updateRole = (id, data) => {
  const endpoint = `/api/v1/vacancies/${id}`;
  const method = "PUT";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.UPDATEROLE_ON_SUCCESS,
    ActionTypes.UPDATEROLE_ON_FAILURE,
  ];

  return request(options);
};

export const getRole = (id) => {
  const endpoint = `/api/v1/vacancies/${id}`;
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };
  options.body = null;
  options.types = [
    ActionTypes.FETCHROLE_ON_SUCCESS,
    ActionTypes.FETCHROLE_ON_FAILURE,
  ];

  return request(options);
};

export const deleteRole = (id) => {
  const endpoint = `/api/v1/vacancies/${id}`;
  const method = "DELETE";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };
  options.body = null;
  options.types = [
    ActionTypes.DELETEROLE_ON_SUCCESS,
    ActionTypes.DELETEROLE_ON_FAILURE,
  ];

  return request(options);
};

export const completeRole = (id, data) => {
  const endpoint = `/api/v1/vacancies/${id}/update_status`;
  const method = "PUT";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };
  options.body = data;
  options.types = [
    ActionTypes.COMPLETEROLE_ON_SUCCESS,
    ActionTypes.COMPLETEROLE_ON_FAILURE,
  ];

  return request(options);
};

export const archivedRole = (id, data) => {
  const endpoint = `/api/v1/vacancies/${id}`;
  const method = "PUT";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };
  options.body = data;
  options.types = [
    ActionTypes.ARCHIVEDROLE_ON_SUCCESS,
    ActionTypes.ARCHIVEDROLE_ON_FAILURE,
  ];

  return request(options);
};

export const roleSkills = () => {
  const endpoint = `/api/v1/vacancies/vacancies_skills`
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.types = [
    ActionTypes.ROLESKILLS_ON_SUCCESS,
    ActionTypes.ROLESKILLS_ON_FAILURE,
  ];

  return request(options);
}

export const deleteSelectedRoles = (ids) => {
  const endpoint = `/api/v1/vacancies/destroy_selected_vacancies`
  const method = "PUT"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }
  options.body = { vacancy_ids: ids }
  options.types = [
    ActionTypes.DELETE_SELECTED_ROLLS_ON_SUCCESS,
    ActionTypes.DELETE_SELECTED_ROLLS_ON_FAILURE,
  ]

  return request(options)
}

export const resetRolesFlag = () => {
  return { type: ActionTypes.RESET_FLAGS };
};

export const resetCreateRolesFlag = () => {
  return { type: ActionTypes.RESET_CREATED };
};
