import React, { useState } from "react"
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { useTheme } from "@mui/material/styles"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import {
  archivedInterview,
  deleteInterview,
} from "src/redux/actions/interviews"
import { deleteDepartment } from "src/redux/actions/department"
import { archivedRole, deleteRole } from "src/redux/actions/roles"
import { deleteMembers } from "src/redux/actions/member"
import {
  deleteQuestionSet,
} from "src/redux/actions/questions"
import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"
import { useTranslation } from "react-i18next"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"

export default function DeleteInterviewModal({
  isShowingDeleteInt,
  hide,
  data,
  invoke,
  isArc,
  isSample=false,
  confirmAction=null
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const confirmInterviewDelete = () => {
    if(isSample) {
      confirmAction()
    } else {
      if (invoke === "role-archive") {
        dispatch(
          archivedRole(data.key, {
            status: "archived",
          }),
        )
      }
      if (invoke === "department") {
        dispatch(deleteDepartment(data.key))
      }
      if (invoke === "interview") {
        dispatch(deleteInterview(data.key))
      }

      if (invoke === "interview-archive") {
        dispatch(archivedInterview(data.key, { status: "archived" }))
      }

      if (invoke === "role") {
        dispatch(deleteRole(data.key))
      }

      if (invoke === "member") {
        dispatch(deleteMembers(data.key))
      }

      if (invoke === "question") {
        dispatch(deleteQuestionSet(data.key))
      }
    }
    hide()
  }

  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [displayCrash, setDisplayCrash] = useState("")

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })

  return (
    <>
      {isShowingDeleteInt ? (
        <>
          <Dialog
            className='dialog400'
            open={isShowingDeleteInt}
            onClose={hide}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            scroll='paper'
            maxWidth='md'
          >
            <DialogTitle
              className='dialog-title '
              sx={{
                display: "flex",
                alignItems: { xs: "flex-start", sm: "center" },
                color: "#ff4960",
              }}
            >
              {isArc ? (
                <Inventory2OutlinedIcon
                  width={24}
                  sx={{ height: { xs: "18px", sm: "24px" }, mr: 1 }}
                />
              ) : (
                <DeleteOutlinedIcon sx={{ color: "#ff4960", mx: "5px" }} />
              )}

              {isArc ? t("ArchiveConfirmation") : t("DeleteConfirmation")}
            </DialogTitle>

            <DialogContent className='dialog-content' dividers={true}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                  <Typography
                    variant='p'
                    component='p'
                    className='dialog-txt'
                    sx={{ paddingLeft: "5px", paddingRight: "5px" }}
                  >
                    {isArc ? (
                      t("SureWanaArchive?")
                    ) : (
                      <>
                        {invoke === "question"
                          ? t("SureWanaDeleteQuestion")
                          : invoke === "interview"
                          ? t("SureWanaDeleteInterview")
                          : invoke === "member"
                          ? t("SureWanaDeleteMember")
                          : invoke === "role"
                          ? t("SureWanaDeleteRole")
                          : invoke === "interview-archive"
                          ? t("SureWanaArchive")
                          : invoke === "department"
                          ? t("SureWanaDeleteDepartment")
                          : invoke === "role-archive"
                          ? t("SureWanaArchive")
                          : t("SureWanaDelete")}
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              className='dialog-footer'
              sx={{ textAlign: "center" }}
            >
              <Button
                onClick={hide}
                variant='outlined'
                color='primary'
                size='medium'
              >
                {isArc ? t("NoKeepItem") : t("NoKeepItem")}
              </Button>

              <Button
                onClick={confirmInterviewDelete}
                autoFocus
                variant='contained'
                color='secondary'
                size='medium'
              >
                {isArc ? t("YesArchive") : t("YesDel")}
              </Button>
            </DialogActions>
          </Dialog>

          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity='success'
              sx={{ width: "100%", background: "#16a34a !important" }}
            >
              {displayCrash}
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </>
  )
}
