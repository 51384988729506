import * as ActionTypes from "../constants/userType"
const initialState = { data: [] }

const userTypeReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.FETCHUSERS_ON_SUCCESS) {
    return { ...state, data: action.payload, isSucceed: action.payload.success }
  }
  return state
}
export default userTypeReducer
