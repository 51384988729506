import * as ActionTypes from "../constants/container";
const initialState = {
  Id: null,
  flag: null,
  check: null,
  awaitFeedback: null,
  authData:null,
};

const containerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SELECTED_ID:
      return { ...state, id: action.payload, flag: true };
    case "FLAG":
      const obj = { ...state, flag: false };
      return obj;
    case "Confirm":
      const obj2 = { ...state, check: true };
      return obj2;
    case "AwaitFeedback":
      const obj3 = { ...state, awaitFeedback: true };
      return obj3;
    case "remConfirm":
      const obj4 = { ...state, check: null };
      return obj4;
    case "remAwait":
      const obj5 = { ...state, awaitFeedback: null };
      return obj5;
    default:
      return state;
  }
};

export default containerReducer;
