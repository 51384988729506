import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/dashboard";

export const dashboardInfo = (month) => {
  const endpoint = "/api/v1/dashboard";
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.params = { month: month }
  options.types = [
    ActionTypes.FETCHDASHBOARD_ON_SUCCESS,
    ActionTypes.FETCHDASHBOARD_ON_FAILURE,
  ];
  return request(options);
};
