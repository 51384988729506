import PropTypes from "prop-types"
import { Link as RouterLink } from "react-router-dom"
import { Box } from "@mui/material"
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
}

export default function Logo({ disabledLink = false, sx, icon, noIcon }) {
  const logo = (
    <Box sx={{ maxWidth: { xs: 160, sm: 160, md: 194 }, ...sx }}>
      <img
        src={icon ? icon : "/static/logo.svg"}
        alt='intvue'
        width='100%'
        height='100%'
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = "/static/logo.svg"
        }}
      />
    </Box>
  )

  if (disabledLink) {
    return <>{logo}</>
  }

  return (
    <RouterLink to='/dashboard/app' className='sidebarlogo'>
      {logo}
    </RouterLink>
  )
}
