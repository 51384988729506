import { request } from "../middlewares/service"
import * as ActionTypes from "../constants/interviews"
import merge from "lodash/merge"

export const getAllInterviews = (type = null) => {
  const endpoint = "/api/v1/interviews"
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = null
  options.params = { type: type }
  options.types = [
    ActionTypes.FETCHINTERVIEWS_ON_SUCCESS,
    ActionTypes.FETCHINTERVIEWS_ON_FAILURE,
  ]

  return request(options)
}

export const getFilteredInterviews = (params, filters = null) => {
  const endpoint = "/api/v1/interviews"
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = null
  options.params = merge(params, { filters: filters })
  options.types = [
    ActionTypes.FETCH_FILTERD_INTERVIEWS_ON_SUCCESS,
    ActionTypes.FETCH_FILTERD_INTERVIEWS_ON_FAILURE,
  ]

  return request(options)
}

export const createInterview = (data) => {
  const endpoint = "/api/v1/interviews"
  const method = "POST"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.CREATEINTERVIEW_ON_SUCCESS,
    ActionTypes.CREATEINTERVIEW_ON_FAILURE,
  ]

  return request(options)
}

export const updateInterview = (data, key) => {
  const endpoint = `/api/v1/interviews/${key}`
  const method = "PUT"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.UPDATEINTERVIEW_ON_SUCCESS,
    ActionTypes.UPDATEINTERVIEW_ON_FAILURE,
  ]

  return request(options)
}

export const deleteInterview = (id) => {
  const endpoint = `/api/v1/interviews/${id}`
  const method = "DELETE"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = null
  options.types = [
    ActionTypes.DELETEINTERVIEW_ON_SUCCESS,
    ActionTypes.DELETEINTERVIEW_ON_FAILURE,
  ]

  return request(options)
}

export const archivedInterview = (id, data) => {
  const endpoint = `/api/v1/interviews/${id}/archiving`
  const method = "PATCH"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.ARCHIVEINTERVIEW_ON_SUCCESS,
    ActionTypes.ARCHIVEINTERVIEW_ON_FAILURE,
  ]

  return request(options)
}

export const resendInvitation = (id) => {
  const endpoint = `/api/v1/interviews/${id}/invitation_email`
  const method = "POST"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = null
  options.types = [
    ActionTypes.SENDINVITATION_ON_SUCCESS,
    ActionTypes.SENDINVITATION_ON_FAILURE,
  ]
  return request(options)
}

export const getInterviewDetail = (interviewId, isInterviewer=null) => {
  const endpoint = `/api/v1/interview_conduction/interview_detail/${interviewId}`
  const method = "GET"
  const headers = isInterviewer === 'true' ? JSON.parse(localStorage.getItem("accessHeader")) : null
  const options = { action: endpoint, method: method, headers: headers }

  options.params = { is_interviewer: isInterviewer }
  options.types = [
    ActionTypes.FETCHINTERVIEWDETAIL_ON_SUCCESS,
    ActionTypes.FETCHINTERVIEWDETAIL_ON_FAILURE,
  ]
  return request(options)
}

export const resetInterviewsFlag = () => {
  return { type: ActionTypes.RESET_FLAGS }
}

export const finishInterview = (data) => {
  const endpoint = `/api/v1/interview_conduction/end_interview`
  const method = "POST"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.FINISHINTERVIEW_ON_SUCCESS,
    ActionTypes.FINISHINTERVIEW_ON_FAILURE,
  ]
  return request(options)
}

export const getInterviewFeedback = (intId, update_action = null) => {
  const endpoint = `/api/v1/interviews/${intId}`
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))
  const options = {
    action: endpoint,
    method: method,
    headers: headers,
    update_action: update_action,
  }
  options.body = null
  options.types = [
    ActionTypes.GET_INTERVIEW_FEEDBACK_SUCCESS,
    ActionTypes.GET_INTERVIEW_FEEDBACK_FAILURE,
  ]
  return request(options)
}

export const addInterviewComment = (data) => {
  const endpoint = `/api/v1/comments`
  const method = "POST"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.ADD_INTERVIEW_COMMENT_SUCCESS,
    ActionTypes.ADD_INTERVIEW_COMMENT_FAILURE,
  ]
  return request(options)
}

export const viewInterviewQuestion = (data) => {
  const endpoint = `/api/v1/interviews/view_answer`
  const method = "POST"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.VIEW_INTERVIEW_QUESTION_SUCCESS,
    ActionTypes.VIEW_INTERVIEW_QUESTION_FAILURE,
  ]
  return request(options)
}

export const deleteComment = (key) => {
  const endpoint = `/api/v1/comments/${key}`
  const method = "DELETE"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))
  const options = { action: endpoint, method: method, headers: headers }

  options.body = null
  options.types = [
    ActionTypes.DELETE_INTERVIEW_COMMENT_SUCCESS,
    ActionTypes.DELETE_INTERVIEW_COMMENT_FAILURE,
  ]
  return request(options)
}

export const editInterviewComment = (id, data) => {
  const endpoint = `/api/v1/comments/${id}`
  const method = "PUT"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.EDIT_COMMENT_SUCCESS,
    ActionTypes.EDIT_COMMENT_FAILURE,
  ]

  return request(options)
}

export const viewCandidateHistory = (userId) => {
  const endpoint = `/api/v1/candidates/${userId}`
  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }
  options.body = null
  options.types = [
    ActionTypes.VIEW_CANDIDATE_HISTORY_SUCCESS,
    ActionTypes.VIEW_CANDIDATE_HISTORY_FAILURE,
  ]
  return request(options)
}

export const updateIntFeedback = (data, actionType, key = null) => {
  let endpoint = `/api/v1/recommendations`

  if (key) endpoint = endpoint + `/${key}`

  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: actionType, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.UPDATE_INT_FEEDBACK_SUCCESS,
    ActionTypes.UPDATE_INT_FEEDBACK_FAILURE,
  ]
  return request(options)
}

export const getRecommendations = (key) => {
  let endpoint = `/api/v1/recommendations`

  const method = "GET"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.params = { interview_key: key }
  options.types = [
    ActionTypes.RECOMMENDATIONS_SUCCESS,
    ActionTypes.RECOMMENDATIONS_FAILURE,
  ]
  return request(options)
}

export const onsiteIntFeedback = (data, key) => {
  const endpoint = `/api/v1/interviews/${key}/followup_interview_feedback`
  const method = "POST"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))
  const options = { action: endpoint, method: method, headers: headers }
  options.body = data
  options.types = [
    ActionTypes.ONSITE_INT_FEEDBACK_SUCCESS,
    ActionTypes.ONSITE_INT_FEEDBACK_FAILURE,
  ]
  return request(options)
}

export const flagInterviewQuestion = (data) => {
  const endpoint = `/api/v1/flagged_questions`
  const method = "POST"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.ADD_INTERVIEW_QUESTION_FLAG_SUCCESS,
    ActionTypes.ADD_INTERVIEW_QUESTION_FLAG_FAILURE,
  ]
  return request(options)
}

export const onsiteIntStatus = (data, key) => {
  const endpoint = `/api/v1/interviews/${key}/update_onsite_status`
  const method = "PATCH"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))
  const options = { action: endpoint, method: method, headers: headers }
  options.body = data
  options.types = [
    ActionTypes.CHANGE_INTERVIEW_STATUS_SUCCEESS,
    ActionTypes.CHANGE_INTERVIEW_STATUS_FAILURE,
  ]
  return request(options)
}

export const deleteSelectedInterview = (ids, tab) => {
  const endpoint = `/api/v1/interviews/destroy_selected_interviews`
  const method = "PUT"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }
  options.body = { interview_ids: ids, tab: tab }
  options.types = [
    ActionTypes.DELETE_SELECTED_INTERVIEWS_ON_SUCCESS,
    ActionTypes.DELETE_SELECTED_INTERVIEWS_ON_FAILURE,
  ]

  return request(options)
}

export const updateInterviewStatus = (interviewId) => {
  const endpoint = `/api/v1/interview_conduction/update_status/${interviewId}`
  const method = "PUT"

  const options = { action: endpoint, method: method, headers: null }

  options.body = null
  options.types = [
    ActionTypes.FETCHINTERVIEWDETAIL_ON_SUCCESS,
    ActionTypes.FETCHINTERVIEWDETAIL_ON_FAILURE,
  ]
  return request(options)
}
