import React, { useState } from "react"
import { Box, Typography, Button } from "@mui/material"
import { useTranslation } from "react-i18next"

const ParagraphWithSeeMoreLink = ({ text, maxLength }) => {
  const [expanded, setExpanded] = useState(false)
  const [hover, setHover] = useState(false)
  const displayText = expanded ? text : text.slice(0, maxLength)

  const { t } = useTranslation()
  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    setHover(false)
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Box>
      <Typography>
        {displayText}
        {text.length > maxLength && !expanded && "..."}
      </Typography>
      {text.length > maxLength && (
        <Button
          variant='text'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={toggleExpand}
          disableRipple
          sx={{
            p: "0px",
            "&:hover": {
              background: "transparent",
            },
            "& .MuiButton-label": {
              textDecoration: "underline",
              fontWeight: hover ? "bold" : "normal",
            },
          }}
        >
          {expanded ? t("ReadLess") : t("ReadMore")}
        </Button>
      )}
    </Box>
  )
}

export default ParagraphWithSeeMoreLink
