import React from "react"
import { fCurrency } from "../../utils/formatNumber"
import {
  Box,
  Button,
  Typography,
  Card,
  CardActions,
  Stack,
} from "@mui/material"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

export default function PlansCard({
  plan,
  isSubscribedPlan,
  isOnTrial,
  isSubscriptionExpired,
  onClickSubscribePlan,
  onClickUnsubscribePlan,
  onClickBuyInterviews,
}) {
  const {
    name,
    price,
    interval,
    per_unit_price,
    total_interviews,
    plan_type,
    saving,
    id,
  } = plan

  const { t } = useTranslation()
  const isTrialValid = isSubscribedPlan && isOnTrial && !isSubscriptionExpired
  const isActive = isSubscribedPlan && !isSubscriptionExpired
  const isActiveButExpired = isSubscribedPlan && isSubscriptionExpired
  const logged = useSelector((state) => state.login)
  const userLoggedPermissions = logged?.user?.profile?.permission

  const subscribeButtonClicked = (id) =>
    onClickSubscribePlan && onClickSubscribePlan(id)
  const unsubscribeButtonClicked = (id) =>
    onClickUnsubscribePlan && onClickUnsubscribePlan(id)
  const buyInterviewsButtonClicked = (id) =>
    onClickBuyInterviews && onClickBuyInterviews(id)

  const renderActiveStatusBadge = () => (
    <Typography
      variant='subtitle1'
      className='badgeTop'
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: isActiveButExpired ? "#ef4444" : "#059669",
        color: "white",
        width: "65px",
        height: "30px",
        borderRadius: "18px",
        position: "absolute",
        top: 0,
        right: "24px",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        textTransform: "capitalize",
      }}
    >
      {isActiveButExpired ? t("expired") : t("Active")}
    </Typography>
  )
  const renderSubscriptionDetails = () => (
    <Typography variant='h2' sx={{ display: "flex" }}>
      {fCurrency(price)}
      <span className='mr3'>
        {" "}
        {interval === "month" ? t("/month") : t("/year")}
      </span>
    </Typography>
  )

  const renderPlanList = () => (
    <ul className='plan-list' sx={{ backgroundColor: "light" }}>
      <Typography component='li' variant='subtitle1' sx={{ fontWeight: "400" }}>
        <span className='plan'>
          {interval === "month"
            ? t("InterviewsPerMonth")
            : t("InterviewsPerYear")}
        </span>
        <span className='count'>{total_interviews}</span>
      </Typography>

      <Typography
        component='li'
        variant='subtitle1'
        color='red'
        sx={{ fontWeight: "400" }}
      >
        <span className='plan'>{t("ChargePerExtraInterview")}</span>
        <span className='count'>{fCurrency(per_unit_price)} </span>
      </Typography>

      <Typography
        component='li'
        variant='subtitle1'
        color='red'
        sx={{ fontWeight: "400" }}
      >
        <span className='plan'>
          {interval === "month" ? t("AnnualCharges") : t("Savings")}
        </span>
        <span className='count'>
          {interval === "month" ? fCurrency(price * 12) : fCurrency(saving)}
        </span>
      </Typography>
    </ul>
  )

  const renderActionButton = () => (
    <CardActions sx={{ paddingX: "0px", paddingTop: "10px" }}>
      {userLoggedPermissions?.settings_user_can_subscribe_to_other_plans &&
        (isActive ? (
          <Box display='flex' flexWrap='wrap' gap='10px'>
            <Button
              variant='contained'
              color='primary'
              size='medium'
              onClick={() => buyInterviewsButtonClicked(id)}
            >
              {t("BuyInterviews")}
            </Button>

            <Button
              variant='outlined'
              color='info'
              size='medium'
              onClick={() => unsubscribeButtonClicked(id)}
            >
              {t("Unsubscribe")}
            </Button>
          </Box>
        ) : (
          onClickSubscribePlan && (
            <Button
              variant={isActiveButExpired ? "outlined" : "contained"}
              color='info'
              size='medium'
              onClick={() => subscribeButtonClicked(id)}
            >
              {isActiveButExpired ? t("RenewSubscription") : t("Subscribe")}
            </Button>
          )
        ))}
    </CardActions>
  )
  return (
    <Card
      className='package-block-sub'
      sx={{
        borderColor: isActive ? "var(--info) !important" : "none",
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography component='h5' variant='h5'>
          {t(name.slice(0, -7))}
          {plan_type === "basic_monthly" && isTrialValid && (
            <small className='xs-badge'> {t("30DaysFreeTrial")} </small>
          )}
        </Typography>
        {(isActive || isActiveButExpired) && renderActiveStatusBadge()}
      </Stack>

      {renderSubscriptionDetails()}
      {renderPlanList()}
      {renderActionButton()}
    </Card>
  )
}
