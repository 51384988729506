import React, { useEffect, useState } from "react"
import Router from "./routes"
import ThemeProvider from "./theme"
import ScrollToTop from "./components/ScrollToTop"
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart"
import SessionTimeOut from "./components/SessionTimeOut"
import { generateNonce } from "./utils/uniqueId"

export default function App() {
  const nonce = generateNonce()
  useEffect(() => {
    document.nonce = nonce
    window.__nonce__ = nonce
  }, [])
  const [uniqueId, setUniqueId] = useState("")

  return (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      <SessionTimeOut />
    </ThemeProvider>
  )
}
