import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Grid, Container, Stack } from "@mui/material"
import { ToggleWidget, PlansList } from "../plansSect"
import { planDataParsing } from "src/utils/dataParsing"
import { getPlans } from "../../../redux/actions/subscription"

// ----------------------------------------------------------------------

export default function LandingPlans() {
  const [isChecked, setIsChecked] = useState(false)

  // State Management
  const plan = useSelector((state) => state.plansList)
  const [parsedPlans, setParsedPlans] = useState([])
  const dispatch = useDispatch()
  // Invoking Redux Structure
  useEffect(() => {
    const plans = plan?.plans
    if (plans && plans.length > 0) {
      setParsedPlans(planDataParsing(plans))
    } else {
      dispatch(getPlans())
    }
  }, [plan])
  return (
    <Container maxWidth='xl' component='main'>
      <Grid
        container
        sx={{ pb: 1, pt: 2, mb: 3 }}
        alignItems='center'
        justifyContent='center'
        id='priceDiv'
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          flexWrap='wrap-reverse'
          sx={{ mb: { lg: 5, sm: 1 } }}
        >
          <Stack direction='row' spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ToggleWidget
              plans={parsedPlans}
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </Stack>
        </Stack>
        <PlansList plans={parsedPlans} isChecked={isChecked} />
      </Grid>
    </Container>
  )
}
