import React from "react"
import Images from "src/assets/img/Images"
import Logo from "src/components/Logo/Logo"
import { Box } from "@mui/material"

export default function LiveHeader({ logoUrl }) {
  return (
    <Box className='live-logo' sx={{ px: 0, pb: 0, display: "inline-flex" }}>
      <Logo
        icon={logoUrl ? logoUrl : Images.LogoMain}
        sx={{ maxWidth: 122, maxHeight: 100, height: "100%" }}
      />
    </Box>
  )
}
