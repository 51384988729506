import React, { useState, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  Box,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Paper,
  Collapse,
  IconButton,
} from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import PropTypes from "prop-types"
import DoneIcon from "@mui/icons-material/Done"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import modulesList from "src/_mock/memberPermissions"
import { useDispatch, useSelector } from "react-redux"
import { getAllProfiles } from "src/redux/actions/profile"
import { permissionDataParsing } from "src/utils/dataParsing"
import { resetErrors } from "src/redux/actions/resetError"
import AddNewMemberModal from "../permissions/modal/addMemberModal"
import { useAuth } from "src/hooks/useAuth"
import EditIcon from "@mui/icons-material/Edit"
import { useTranslation } from "react-i18next"

export default function Permissions() {
  const { user: currentUser } = useAuth()
  const { t } = useTranslation()
  const permit = useSelector((state) => state.profile)
  const [permitList, setPermissionList] = useState()
  const dispatch = useDispatch()
  const [isShowingRole, toggleRole] = useModal()
  const [userPermissionToEdit, setUserPermissionToEdit] = useState(null)
  const logged = useSelector((state) => state.login)
  const userLoggedPermissions = logged?.user?.profile?.permission
  const [open, setOpen] = useState()
  const addMemberRes = useSelector((state) => state.addMember)
  const checkCrash = useSelector((state) => state.crashManagement)

  const [displayCrash, setDisplayCrash] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorSeverity, setSeverity] = useState("")
  const headItem = permitList?.length + 1

  useEffect(() => {
    handleCloseSnackbar()
    return () => {
      handleCloseSnackbar()
    }
  }, [])

  useEffect(() => {
    if (permit.data.length === 0) {
      dispatch(getAllProfiles())
    }

    if (permit?.data?.success) {
      let profiles = permit?.data?.profiles
      if (permit?.updated) {
        const updatedProfile = permit?.updatedProfile
        const index = profiles.findIndex(
          (profile) => profile.id === updatedProfile.id,
        )
        if (index >= 0) {
          profiles[index] = updatedProfile
          const currentUser = JSON.parse(localStorage.getItem("user"))

          if (updatedProfile?.key === currentUser?.user?.profile?.key) {
            currentUser.user.profile = updatedProfile
            sessionStorage.setItem("user", JSON.stringify(currentUser))
            localStorage.setItem("user", JSON.stringify(currentUser))

            window.location.reload()
          }
        }
        setOpenSnackbar(true)
        setDisplayCrash("Permissions successfully updated")
        setSeverity("success")
        dispatch(resetErrors())
      }
      setPermissionList(permissionDataParsing(profiles))
    } else {
      setPermissionList([])
    }
  }, [permit])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })

  useEffect(() => {
    if (checkCrash?.succeed === false) {
      setOpenSnackbar(true)
      setDisplayCrash(checkCrash.message)
      setSeverity("error")
      dispatch(resetErrors())
    }
  }, [checkCrash])

  useEffect(() => {
    if (addMemberRes?.status === 200 || addMemberRes?.data?.success === true) {
      dispatch(getAllProfiles())
    } else if (
      addMemberRes?.status === 422 ||
      addMemberRes?.status === 500 ||
      addMemberRes?.data?.success === false
    ) {
      setOpenSnackbar(true)
      setDisplayCrash(checkCrash.message)
      setSeverity("error")
      dispatch(resetErrors())
    }
  }, [addMemberRes])

  const editMemberTypeButtonClicked = (userPermission) => {
    setUserPermissionToEdit(userPermission)
    toggleRole()
  }

  return (
    <>
      <div className='tabular-section'>
        <Stack
          direction={{ xs: "column", sm: "column", md: "row" }}
          justifyContent='space-between'
          alignItems='center'
          spacing={2}
        >
          {openSnackbar === true && (
            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              sx={{ zIndex: "2", mt: "10vh !important" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={errorSeverity}
                sx={{ width: "100%" }}
              >
                {displayCrash}
              </Alert>
            </Snackbar>
          )}
          <Typography variant='h6' gutterBottom component='h6'>
            {t("UserPermissions")}
          </Typography>
          <div>
            {currentUser.user_type === "admin" ||
            userLoggedPermissions?.settings_user_can_add_member_type ? (
              <Button
                onClick={() => {
                  setUserPermissionToEdit(null)
                  toggleRole()
                }}
                variant='contained'
                component={RouterLink}
                className='add-btn'
                to='#'
                startIcon={<AddIcon />}
              >
                {t("AddMemberType")}
              </Button>
            ) : null}
          </div>
        </Stack>
      </div>

      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table
          stickyHeader
          aria-label='collapsible table'
          className='fixed-table'
        >
          <TableHead className='th-fixedtable'>
            <TableRow className='fixed-th-row'>
              <TableCell className='fixed-cell' width='300'>
                {t("Features")}
              </TableCell>

              {permitList?.map((userPermission) => {
                return (
                  <TableCell
                    width='160'
                    align='center'
                    sx={{ textAlign: "center", maxWidth: 160, minWidth: 130 }}
                    key={userPermission.id}
                  >
                    <Stack
                      direction='row'
                      justifyContent='space-around'
                      alignItems='center'
                    >
                      {t(userPermission.user)}

                      <div>
                        {((currentUser.user_type === "admin" ||
                          userLoggedPermissions?.settings_user_can_edit_permissions) && (
                            <EditIcon
                              onClick={() =>
                                editMemberTypeButtonClicked(userPermission)
                              }
                              fontSize='medium'
                              sx={{
                                mx: 1,
                                color: "var(--white)",
                                cursor: "pointer",
                              }}
                              className={
                                userPermission.user === "Admin"
                                  ? "noicon"
                                  : null
                              }
                            />
                          ))}
                      </div>
                    </Stack>
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {modulesList.map((module, index) => {
              return (
                <RowNew
                  t={t}
                  key={module.id + "moduleRow"}
                  module={module}
                  prList={permitList}
                  headItem={headItem}
                  open={open}
                  setOpen={setOpen}
                />
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isShowingRole && (
        <AddNewMemberModal
          isShowingRole={isShowingRole}
          hide={() => {
            toggleRole()
            setUserPermissionToEdit(null)
          }}
          userPermissionToEdit={userPermissionToEdit}
        />
      )}
    </>
  )
}

function RowNew(props) {
  const { module, prList, headItem, setOpen, open, t } = props

  return (
    <>
      <TableRow
        className={module.id}
        onClick={() => {
          if (open === module.id) {
            setOpen("")
          } else {
            setOpen(module.id)
          }
        }}
        hover
        sx={{ "& > *": { borderBottom: "unset" } }}
      >
        <TableCell
          width='160'
          align='right'
          className='card-table'
          sx={{
            textAlign: "center",
            //  minWidth: 130,
            maxWidth: 160,
            borderRadius: 0,
            border: 0,
          }}
          colSpan={headItem ? headItem : 1}
        >
          <Box className='card-header' sx={{ mb: 1 }}>
            <IconButton
              aria-label='expand row'
              size='small'
              sx={{
                height: 23,
                width: 23,
                border: "1px solid #637381",
                marginLeft: 2,
              }}
              // onClick={() => setOpen(!open)}
            >
              {open === module.id ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>

            <Button
              aria-label='expand row'
              size='medium'
              onClick={() => setOpen(!open)}
              sx={{
                ml: 2,
                color: "#475569",
                padding: 0,
                justifyContent: "flex-start",
                fontSize: "12.6px",
                fontWeight: "600",
                "&:hover": {
                  opacity: "0.7",
                  backgroundColor: "transparent",
                },
              }}
            >
              {t(module.name)}
            </Button>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={headItem ? headItem : 1}>
          <Collapse in={open === module.id} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 0, maxHeight: "300px", overflow: "auto" }}>
              <Table
                size='small'
                aria-label='permissions'
                className='fixed-tbl-inner'
              >
                <TableBody>
                  {module.listM.map((lisiting, index) => {
                    return (
                      <TableRow key={lisiting.listid + index}>
                        <TableCell
                          className='fixed-cell'
                          width='300'
                          sx={{
                            paddingLeft: "3rem",
                            borderRight: "1px solid #dce0e2",
                            borderBottom: "1px solid #dce0e2",
                          }}
                        >
                          {t(lisiting.listname)}
                        </TableCell>
                        {prList?.map((permit, index) => {
                          let checkProperty = lisiting.listid
                          return (
                            <TableCell
                              key={lisiting.listid + "_child_" + index}
                              width='160'
                              className='inner-cell'
                              sx={{
                                textAlign: "center",
                                minWidth: 130,
                                borderRight: "1px solid #dce0e2",
                                borderBottom: "1px solid #dce0e2",
                              }}
                            >
                              {permit[checkProperty] ? (
                                <DoneIcon
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    ml: 1,
                                    mr: 1,
                                    color: "#338d57",
                                  }}
                                />
                              ) : (
                                <CloseIcon
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    ml: 1,
                                    mr: 1,
                                    color: "#d23939",
                                  }}
                                />
                              )}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
