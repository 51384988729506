import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback } from "react"
import { Typography, Card, Button, Box, TextField, Stack } from "@mui/material"
import { fCurrency } from "../../utils/formatNumber"
import useModal from "../@dashboard/settings/subscription/Modals/useModal"
import WrapperSignupCardModal from "../auth/signup/connectSigncard/signupCard"
import Label from "src/components/Label"
import { useDispatch, useSelector } from "react-redux"
import { validateCoupon } from "src/redux/actions/paymentMethod"
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep"

SelectedCard.propTypes = {
  plan: PropTypes.object,
}

export function SelectedCard({ plan, onCardAdd, setAppliedCode }) {
  const [isShowingChangeCard, toggleChangeCard] = useModal()
  const [cardLast4Digits, setCardLast4Digits] = useState(null)
  const [cardBrand, setCardBrand] = useState(null)
  const [discountedPrice, setDiscountedPrice] = useState(null)
  // const [discount, setDiscount] = useState(0)
  const [code, setCode] = useState("")
  const [codeError, setCodeError] = useState("")

  const validCoupon = useSelector((state) => state.validCoupon)

  const dispatch = useDispatch()

  const handleCardUpdate = (updatedCard) => {
    toggleChangeCard(false)
  }

  const handleCardAdded = (card) => {
    setCardLast4Digits(`xxxx-xxxx-xxxx-${card?.last4}`)
    setCardBrand(card?.brand)
    if (onCardAdd) {
      onCardAdd(card)
    }
  }

  const handleCouponSingup = useCallback((discount, price) => {
    let percentageValue = price * (discount / 100)
    let resultAmount = price - percentageValue
    setDiscountedPrice(resultAmount ?? 0)
    // setDiscount(discount)
  }, [])

  const resetDiscountFields = () => {
    setAppliedCode({})
    setDiscountedPrice(null)
  }

  const handleClear = () => {
    setCode("")
    setCodeError("")
    resetDiscountFields()
  }

  const validateCode = () => {
    if (!code) {
      setCodeError("No Code Entered")
      return
    }

    const payload = {
      code: code,
      is_sign_up: true,
      plan_prod_id: plan?.stripe_product_id,
    }

    dispatch(validateCoupon(payload))
  }

  useEffect(() => {
    if (plan?.plan_type === "basic_monthly") {
      localStorage.removeItem("plan_token")
      setCardLast4Digits(null)
      setCardBrand(null)
    }
  }, [plan])

  useEffect(() => {
    if (validCoupon.isValid) {
      let val = validCoupon?.data?.coupon?.percent_off
      handleCouponSingup(val, plan?.price)
      setCodeError("")
      setAppliedCode(validCoupon?.data)
    } else if (validCoupon.isError) {
      setCode("")
      setCodeError(validCoupon.error)
      resetDiscountFields()
    }
  }, [validCoupon, plan])

  return (
    <Card className='package-block-sub'>
      <Typography
        component='h6'
        variant='h6'
        sx={{
          display: "flex",
          justifyContent: "fle-start",
          alignContent: "center",
          mb: "10px",
        }}
      >
        {plan?.name}
      </Typography>
      <Typography
        variant='h2'
        sx={{ display: "flex", lineHeight: "1.0em !important" }}
      >
        {plan?.plan_type === "basic_monthly" ? (
          <>
            Free{" "}
            <Typography
              component='p'
              variant='body1'
              sx={{
                fontSize: "0.99rem",
                fontWeight: "500",
                textTransform: "none",
                color: "var(--slate600)",
                ml: 1,
              }}
            >
              (30 Days Free then {fCurrency(plan?.price)} /month)
            </Typography>
          </>
        ) : discountedPrice !== null ? (
          <>
            <Typography
              variant='h2'
              sx={{
                textDecoration: "line-through",
                display: "flex",
                lineHeight: "1.0em !important",
              }}
            >
              {fCurrency(plan?.price)}
            </Typography>
            <Typography
              sx={{
                fontSize: "0.99rem",
                fontWeight: "600",
                textTransform: "none",
                color: "var(--slate600)",
                mx: 1,
              }}
            >
              {fCurrency(discountedPrice)}{" "}
            </Typography>
          </>
        ) : (
          fCurrency(plan?.price)
        )}
      </Typography>

      <ul
        className='plan-list'
        sx={{
          backgroundColor: "light",
        }}
      >
        <Typography
          component='li'
          variant='subtitle1'
          sx={{ fontWeight: "400" }}
        >
          <span className='plan'>
            {plan?.interval === "month"
              ? "Interviews Per Month"
              : "Interviews Per Year"}
          </span>
          <span className='count'>{plan?.total_interviews}</span>
        </Typography>

        <Typography
          component='li'
          variant='subtitle1'
          color='red'
          sx={{ fontWeight: "400" }}
        >
          <span className='plan'>Charge Per Extra Interview</span>
          <span className='count'>{fCurrency(plan?.per_unit_price)} </span>
        </Typography>

        <Typography
          component='li'
          variant='subtitle1'
          color='red'
          sx={{ fontWeight: "400" }}
        >
          <span className='plan'>
            {plan?.interval === "month" ? "Annual Charges" : "Savings"}
          </span>
          <span className='count'>
            {plan?.interval === "month"
              ? discountedPrice !== null
                ? fCurrency(plan?.price * 11 + discountedPrice)
                : fCurrency(plan?.price * 12)
              : fCurrency(
                  plan?.price * 0.15 +
                    (discountedPrice !== null
                      ? plan?.price - discountedPrice
                      : 0),
                )}
          </span>
        </Typography>
      </ul>
      {plan?.plan_type !== "basic_monthly" && (
        <>
          <Stack
            className='adone'
            direction='row'
            justifyContent='space-between'
            sx={{ mt: 1 }}
          >
            <TextField
              id='coupon-signup'
              fullWidth
              variant='outlined'
              label='Apply Coupon'
              size='small'
              value={code}
              onChange={(e) => {
                setCode(e.target.value)
                resetDiscountFields()
              }}
            />
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              <Button variant='outlined' sx={{ mx: 1 }} onClick={validateCode}>
                Apply
              </Button>
              {discountedPrice !== null && (
                <DeleteSweepIcon
                  sx={{
                    cursor: "pointer",
                    color: "var(--primary)",
                  }}
                  onClick={handleClear}
                />
              )}
            </Stack>
          </Stack>
          {codeError && (
            <Typography
              sx={{
                marginTop: "5px",
                color: "#FF4842",
                fontWeight: 400,
                fontSize: "1rem",
              }}
            >
              {codeError}
            </Typography>
          )}
        </>
      )}

      {plan?.plan_type === "basic_monthly" ? (
        ""
      ) : (
        <>
          <Button onClick={handleCardUpdate} variant='contained' sx={{ mt: 2 }}>
            {cardLast4Digits ? "Update Card" : "Add Card"}
          </Button>
          {cardLast4Digits && (
            <Box sx={{ justifyContent: "flex-start", display: "flex", my: 1 }}>
              <Label sx={{ mr: 1 }}>{cardBrand}</Label>
              <Typography variant='body1'> {cardLast4Digits}</Typography>
            </Box>
          )}
        </>
      )}

      <WrapperSignupCardModal
        open={isShowingChangeCard}
        onClose={toggleChangeCard}
        onCardAdd={handleCardAdded}
      />
    </Card>
  )
}
