import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { styled } from "@mui/material/styles"
import {
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from "@mui/material"
import {
  deleteMembers,
  getAllMembers,
  resetMembersFlag,
} from "src/redux/actions/member"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import RefreshIcon from "@mui/icons-material/Refresh"
import SearchIcon from "@mui/icons-material/Search"
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "&.Mui-focused": {
      width: "100%",
    },
  },
}))

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
}

export default function UserListToolbar({
  numSelected,
  filterName,
  onFilterName,
  refreshTeam,
  canSearch = true,
}) {
  const { t } = useTranslation()
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {canSearch && (
        <SearchStyle
          id='search'
          value={filterName}
          onChange={onFilterName}
          placeholder={t("Search")}
          startAdornment={
            <InputAdornment position='start'>
              <SearchIcon
                sx={{ color: "text.disabled", width: 20, height: 20 }}
              />
            </InputAdornment>
          }
        />
      )}

      <Tooltip title={t("Refresh")} onClick={refreshTeam}>
        <IconButton>
          <RefreshIcon />
        </IconButton>
      </Tooltip>
    </RootStyle>
  )
}
