import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  IconButton,
  Accordion,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Typography,
  Stack,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  ListItemText,
  ListItem,
  Rating,
  List,
  Link,
} from "@mui/material"
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt"
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt"
import Label from "src/components/Label"
import CloseIcon from "@mui/icons-material/Close"
import { useSelector } from "react-redux"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useNavigate } from "react-router-dom"
import ParagraphWithSeeMoreLink from "src/components/ParagraphWithSeeMoreLink"
import { useTranslation } from "react-i18next"
import { fDateFormatYearlong } from "src/utils/formatTime"

export default function ViewCandidateDetails({
  props,
  hide,
  open,
  onClose,
  canViewRecomendation = true,
  canViewFeedback = true,
}) {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [progress, setProgress] = useState(0)
  const navigate = useNavigate()
  const candidateData = useSelector((state) => state?.viewCandidateHistory)
  const candidateInfo = candidateData?.data?.candidates
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [userItem, setItem] = useState([])
  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission
  const handleChangeVac = (panel, id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false)
  }

  const handleClose = () => {
    setActiveItemIndex(0)
    onClose()
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10,
      )
    }, 800)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const handleIntReview = (id, key, interview_format) => {
    const obj = { id: id, key: key }
    localStorage.setItem("match", JSON.stringify(obj))

    window.history.pushState(null, "", "/dashboard/candidates")

    navigate(`/interview-feedback/app/${key}`, { replace: false })
  }

  const handleData = (id) => {
    let obj = {}
    let arrList = []
    {
      candidateInfo?.candidate_vacancies?.map((user) => {
        if (user?.vacancy_id === id) {
          user.interviews.map((item) => {
            const recommendations = item?.recommendations

            return (
              <>
                {(obj.id = item?.id)}
                {(obj.key = item?.key)}
                {(obj.interview_type = item?.interview_type)}
                {(obj.status = item?.status)}
                {(obj.completed_on = item?.completed_on)}
                {(obj.expires_on = item?.expires_on)}
                {(obj.interview_format = item?.interview_format)}
                {(obj.scheduled_on = item?.scheduled_on)}
                {(obj.votes_count = item?.votes_count[0])}
                {(obj.recommendations = item?.recommendations)}

                {arrList?.push(obj)}

                {(obj = {})}
              </>
            )
          })
        }
      })
    }
    if (arrList?.length !== 0) {
      setItem(arrList)
    } else {
      setItem([])
    }
  }

  useEffect(() => {
    const vacancyId = candidateInfo?.candidate_vacancies[0]?.vacancy_id
    handleData(vacancyId)
  }, [candidateInfo])

  return (
    <Dialog
      className='dialog800'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='xl'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className='dialog-content'
        dividers={true}
        sx={{ borderTop: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                background: "#e6e6e6",
                padding: "24px",
                borderRadius: "18px",
                display: "flex",
                alignItems: "center",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Avatar
                sx={{
                  width: { xs: 60, sm: 100 },
                  height: { xs: 60, sm: 100 },
                  bgcolor: "#1890FF",
                  mb: { xs: 1, sm: 0 },
                  mx: 2,
                  fontSize: "1.7rem",
                  textTransform: "uppercase",
                }}
              >
                {candidateInfo?.first_name?.slice(0, 1) +
                  "" +
                  candidateInfo?.last_name?.slice(0, 1)}
              </Avatar>

              <Box sx={{ flexDirection: "row", display: "flex" }}>
                <Grid container spacing={1}>
                  <Grid item md={6}>
                    <Stack direction='row'>
                      <Typography
                        component='h6'
                        variant='h6'
                        sx={{
                          fontWeight: 600,
                          fontSize: "1.0rem !important",
                          mx: 1,
                        }}
                      >
                        {t("Name")}:
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#2D2D2D",
                          textTransform: "capitalize",
                          wordBreak: "break-all",
                        }}
                      >
                        {candidateInfo?.first_name +
                          " " +
                          candidateInfo?.last_name}
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item md={6}>
                    <Stack direction='row'>
                      <Typography
                        component='h6'
                        variant='h6'
                        sx={{
                          fontWeight: 600,
                          fontSize: "1.0rem !important",
                          mx: 1,
                        }}
                      >
                        {t("Email")}:
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#2D2D2D",
                          wordBreak: "break-all",
                        }}
                      >
                        {candidateInfo?.email}
                      </Typography>
                    </Stack>
                  </Grid>
                  {candidateInfo?.experience && (
                    <Grid item md={6}>
                      <Stack direction='row'>
                        <Typography
                          component='h6'
                          variant='h6'
                          sx={{
                            fontWeight: 600,
                            mx: 1,
                            fontSize: "1.0rem !important",
                          }}
                        >
                          {t("Experience")}:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "#2D2D2D",
                          }}
                        >
                          {candidateInfo?.experience} {t("years")}
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                  {candidateInfo?.phone && (
                    <Grid item md={6}>
                      <Stack direction='row'>
                        <Typography
                          component='h6'
                          variant='h6'
                          sx={{
                            fontWeight: 600,
                            mx: 1,
                            fontSize: "1.0rem !important",
                          }}
                        >
                          {t("Phone")}:
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "#2D2D2D",
                          }}
                        >
                          {candidateInfo?.phone}
                        </Typography>
                      </Stack>
                    </Grid>
                  )}
                  {candidateInfo?.qualification && (
                    <Grid item md={6}>
                      <Stack direction='row'>
                        <Typography
                          component='h6'
                          variant='h6'
                          sx={{
                            fontWeight: 600,
                            mx: 1,
                            fontSize: "1.0rem !important",
                          }}
                        >
                          {t("Qualification")}:
                        </Typography>

                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "#2D2D2D",
                          }}
                        >
                          {candidateInfo?.qualification}
                        </Typography>
                      </Stack>
                    </Grid>
                  )}

                  {candidateInfo?.skills !== null && (
                    <Grid item md={6}>
                      <Stack direction='row'>
                        <Typography
                          component='h6'
                          variant='h6'
                          sx={{
                            fontWeight: 600,
                            mx: 1,
                          }}
                        >
                          {t("Skill")}:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: "16px",
                            color: "#2D2D2D",
                          }}
                        >
                          {candidateInfo?.skills?.map((candidateSkills) => {
                            return (
                              <Label
                                key={candidateSkills + "_skill"}
                                variant='ghost'
                                color='warning'
                                sx={{ mx: "5px", mb: 1 }}
                              >
                                {candidateSkills}
                              </Label>
                            )
                          })}
                        </Typography>
                      </Stack>
                    </Grid>
                  )}

                  <Grid item md={12}>
                    {candidateInfo?.resume.url && (
                      <Stack direction='row' sx={{ my: 1, mx: 1 }}>
                        <Link
                          rel='noopener noreferrer'
                          target='_blank'
                          href={candidateInfo?.resume.url}
                          underline='none'
                          variant='contained'
                          sx={{
                            color: "#2065D1",
                            fontWeight: 500,
                            borderRadius: "8px",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative",
                            outline: 0,
                            cursor: "pointer",
                            userSelect: "none",
                            lineHeight: "1.7142",
                            fontSize: "0.875rem",
                            textTransform: "capitalize",
                            minWidth: "64px",
                            padding: "5px 15px",
                            boxSizing: "border-box",
                            backgroundColor: "transparent",
                            "&:hover": {
                              backgroundColor: "var(--primary)",
                              color: "var(--white)",
                              opacity: 0.8,
                            },
                            border: "1px solid rgba(32, 101, 209, 0.5)",
                            transition:
                              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          }}
                        >
                          {t("Resume")}
                        </Link>
                      </Stack>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                flexGrow: 1,
                bgcolor: "background.paper",
                display: "flex",
                height: 224,
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={3} className='tabsButton'>
                  <Typography
                    variant='h5'
                    sx={{
                      color: { xs: "#000", sm: "#AAB5C4" },
                      textTransform: "capitalize",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: " 20px",
                      borderBottom: "1px solid #E5E8EB",
                      padding: {
                        xs: "0 0.8rem 13px 0.8rem",
                        sm: "0 1.8rem 13px 1.8rem",
                      },
                      mb: { xs: 1, sm: 2 },
                    }}
                  >
                    {t("Vacancies")}
                  </Typography>

                  <List
                    className='verticle-list'
                    sx={{ p: 0, borderRight: 1, borderColor: "divider" }}
                  >
                    {candidateInfo?.candidate_vacancies?.map((item, index) => (
                      <ListItem
                        key={item.id}
                        sx={{
                          cursor: "pointer",
                          textTransform: "capitalize",
                          color: "#000",
                          borderRight: "4px solid transparent",
                          "&:hover": {
                            color: "#0066E9",
                            borderColor: "#0066E9",
                          },
                        }}
                        className={`list-group-item ${
                          activeItemIndex === index && "active"
                        }`}
                        onClick={() => {
                          handleData(item?.vacancy?.id)
                          setActiveItemIndex(index)
                        }}
                      >
                        <ListItemText>{item?.vacancy?.title}</ListItemText>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={12} sm={9} className='tabPanel'>
                  <Typography
                    variant='h5'
                    sx={{
                      color: { xs: "#000", sm: "#AAB5C4" },
                      textTransform: "capitalize",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: " 20px",
                      borderBottom: "1px solid #E5E8EB",
                      padding: {
                        xs: "0 0.8rem 13px 0.8rem",
                        sm: "0 1.8rem 13px 1.8rem",
                      },
                      mb: { xs: 1, sm: 2 },
                    }}
                  >
                    {t("Details")}
                  </Typography>
                  {userItem.length !== 0 ? (
                    userItem?.map((item) => (
                      <Box sx={{ ml: 2 }} key={item?.id + "key"}>
                        {item.length !== 0 ? (
                          <Accordion
                            expanded={expanded === item?.id}
                            onChange={handleChangeVac("panel1", item?.id)}
                            key={item?.id}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls='panel1bh-content'
                              id='panel1bh-header'
                              className='panelHeader'
                            >
                              <Typography
                                sx={{
                                  width: "33%",
                                  flexShrink: 0,
                                  fontWeight: 500,
                                  color: "#000c !important",
                                  textTransform: "capitalize",
                                  mb: { xs: 1, sm: 0 },
                                }}
                              >
                                {item?.interview_type === "first_contact"
                                  ? t("FirstContact")
                                  : t("followup")}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "text.secondary",
                                }}
                              >
                                {item?.status === "partially_completed"
                                  ? t("PartiallyCompleted")
                                  : item?.status === "pending"
                                  ? t("Pending")
                                  : item?.status === "in_progress"
                                  ? t("inProgress")
                                  : item?.status === "completed"
                                  ? t("Completed")
                                  : item?.status === "expired"
                                  ? t("expired")
                                  : item?.status === "archived"
                                  ? t("archived")
                                  : null}
                              </Typography>
                              <Box
                                sx={{
                                  mx: { xs: 0, sm: 1 },
                                  mt: { xs: 1, sm: 0 },
                                }}
                              >
                                <Label
                                  variant='ghost'
                                  color='success'
                                  sx={{ mx: { xs: "4px", sm: 1 } }}
                                >
                                  <ThumbUpOffAltIcon
                                    sx={{
                                      color: "#64748b",
                                      fontSize: "16px",
                                      mx: { xs: 0, sm: 1 },
                                    }}
                                  />
                                  {item?.votes_count?.recomend_votes}
                                </Label>
                                <Label
                                  variant='ghost'
                                  color='error'
                                  sx={{ mx: { xs: "4px", sm: 1 } }}
                                >
                                  <ThumbDownOffAltIcon
                                    sx={{
                                      color: "#64748b",
                                      fontSize: "16px",
                                      mx: { xs: 0, sm: 1 },
                                    }}
                                  />
                                  {item?.votes_count?.denied_votes}
                                </Label>
                              </Box>
                            </AccordionSummary>

                            <AccordionDetails
                              sx={{
                                border: "1px solid #efefef",
                                borderTop: 0,
                                mb: 2,
                              }}
                            >
                              <Grid container spacing='3'>
                                {canViewRecomendation &&
                                  item?.recommendations.length !== 0 && (
                                    <Grid item xs={12} sm={6}>
                                      <Box sx={{ p: { xs: 1, sm: 2 } }}>
                                        {item?.recommendations?.map((rec) => {
                                          return (
                                            <Box
                                              key={rec?.id + rec?.key}
                                              sx={{
                                                borderBottom:
                                                  "1px solid #d0d0d0",
                                                mb: 1,
                                                pb: 1,
                                              }}
                                            >
                                              <Stack
                                                direction={{
                                                  xs: "column",
                                                  sm: "column",
                                                  md: "row",
                                                }}
                                                alignItems='flex-start'
                                                justifyContent={{
                                                  xs: "flex-start",
                                                  sm: "flex-start",
                                                  md: "space-between",
                                                }}
                                              >
                                                <Typography
                                                  variant='body1'
                                                  sx={{
                                                    mt: 1,
                                                    mb: 1,
                                                    fontWeight:
                                                      "600 !important",
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  {rec?.user?.first_name +
                                                    " " +
                                                    rec?.user?.last_name}
                                                </Typography>
                                                <Typography
                                                  variant='body1'
                                                  sx={{
                                                    mb: 2,
                                                    fontWeight:
                                                      "600 !important",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <Rating
                                                    sx={{ ml: 1 }}
                                                    name='read-only'
                                                    value={rec?.rating}
                                                    readOnly
                                                  />
                                                </Typography>
                                              </Stack>
                                              <Typography>
                                                <Label
                                                  sx={{
                                                    fontWeight:
                                                      "600 !important",
                                                    my: 1,
                                                  }}
                                                  variant='ghost'
                                                  color={
                                                    rec?.is_recommended
                                                      ? "success"
                                                      : "error"
                                                  }
                                                >
                                                  {rec?.is_recommended
                                                    ? t("Recommended")
                                                    : t("NotRecommended")}
                                                </Label>
                                              </Typography>
                                              {rec?.text && (
                                                <Typography
                                                  variant='body1'
                                                  sx={{
                                                    mt: 1,
                                                    mb: 1,
                                                    fontWeight:
                                                      "600 !important",
                                                    wordBreak: "break-word",
                                                  }}
                                                >
                                                  {t("Comments")}
                                                  :
                                                  <ParagraphWithSeeMoreLink
                                                    text={rec?.text}
                                                    maxLength={45}
                                                  />
                                                </Typography>
                                              )}
                                            </Box>
                                          )
                                        })}
                                      </Box>
                                    </Grid>
                                  )}

                                <Grid item xs={12} sm={6}>
                                  <Box sx={{ p: { xs: 1, sm: 2 } }}>
                                    <Typography
                                      variant='subtitle2'
                                      sx={{
                                        fontWeight: " 400",
                                        fontSize: "12px",
                                        color: "#000c",
                                      }}
                                    >
                                      <b>{t("ScheduleDate")}</b>:&nbsp;
                                      {item?.scheduled_on}
                                    </Typography>
                                    <Typography
                                      variant='subtitle2'
                                      sx={{
                                        fontWeight: " 400",
                                        color: "#000c",
                                      }}
                                    >
                                      <b>{t("CompletedOn")}</b>:&nbsp;
                                      {fDateFormatYearlong(item?.completed_on)}
                                    </Typography>
                                    <Typography
                                      variant='subtitle2'
                                      sx={{
                                        fontWeight: " 400",
                                        color: "#000c",
                                      }}
                                    >
                                      <b>{t("ExpiresOn")}</b>:&nbsp;
                                      {item?.expires_on}
                                    </Typography>
                                    {canViewFeedback &&
                                      (item?.status === "pending" ||
                                      item?.status === "expired" ? (
                                        ""
                                      ) : (
                                        <Button
                                          size='small'
                                          variant='outlined'
                                          color='primary'
                                          sx={{
                                            maxHeight: 38,
                                            width: "fit-content",
                                            fontWeight: 500,
                                            mt: 2,
                                            justifyContent: "center",
                                            pl: 2,
                                            pr: 2,
                                          }}
                                          onClick={() =>
                                            handleIntReview(
                                              item?.id,
                                              item?.key,
                                              item?.interview_format,
                                            )
                                          }
                                        >
                                          {t("ReviewInterview")}
                                        </Button>
                                      ))}
                                  </Box>
                                </Grid>
                              </Grid>
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          <Typography
                            variant='body2'
                            sx={{
                              padding: 1,
                              backgroundColor: "#eeeeee",
                              ml: 2,
                              mr: 2,
                            }}
                          >
                            {t("NoInterviewShow")}
                          </Typography>
                        )}
                      </Box>
                    ))
                  ) : (
                    <Typography
                      variant='body2'
                      sx={{
                        padding: 1,
                        backgroundColor: "#eeeeee",
                        ml: 1,
                        mr: 1,
                      }}
                    >
                      {t("NoInterviewShow")}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button
          onClick={handleClose}
          variant='outlined'
          color='primary'
          size='medium'
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
