import PropTypes from "prop-types"
import React from "react"
import { styled } from "@mui/material/styles"
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  Box,
  InputAdornment,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import FilterInterviewSidebar from "./FilterInterviewSidebar"
import { useDispatch, useSelector } from "react-redux"
import { deleteSelectedInterview } from "src/redux/actions/interviews"
import { fDateShort } from "src/utils/formatTime"
import { useState } from "react"
import { subMonths } from "date-fns"
import moment from "moment"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import DeleteIcon from "@mui/icons-material/Delete"
import SearchIcon from "@mui/icons-material/Search"
import DateRangeIcon from "@mui/icons-material/DateRange"
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "&.Mui-focused": {
      width: "100%",
    },
  },
}))

// ----------------------------------------------------------------------

InterviewListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
}

export default function InterviewListToolbar({
  numSelected,
  resetSelected,
  tabing,
  interviewIds,
  uniqueDept,
  filterName,
  onFilterName,
  refreshInt,
  searchName,
  onFilterSearch,
  canSearch = true,
  canFilterDpt = true,
  openFilter,
  setOpenFilter,
  handleOpenFilter,
  handleCloseFilter,
  setInterviews,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const logged = useSelector((state) => state.login)

  let monthStart = new Date()
  monthStart = subMonths(monthStart, 1)

  if (
    moment(monthStart)
      .startOf("day")
      .isBefore(moment(logged?.user?.account?.created_at).startOf("day"))
  ) {
    monthStart = logged?.user?.account?.created_at
  }
  const [startDate, setStartDate] = useState(monthStart)
  const [endDate, setEndDate] = useState(new Date())
  return (
    <RootStyle
      sx={{
        flexDirection: {
          xs: `${tabing !== "active" && numSelected === 0 ? "column" : "row"}`,
          sm: "row",
        },
        p: { xs: 1, sm: "0 8px 0 24px" },
        alignItems: {
          xs: `${
            tabing !== "active" && numSelected === 0 ? "flex-start" : "center"
          }`,
          sm: "center",
        },
        height: { xs: "auto", sm: "auto", md: 96 },
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component='div' variant='subtitle1'>
          {numSelected} selected
        </Typography>
      ) : (
        canSearch && (
          <Box sx={{ pb: { xs: 1, sm: 0 } }}>
            <SearchStyle
              id='search'
              value={searchName}
              onChange={onFilterSearch}
              placeholder={t("Search")}
              startAdornment={
                <InputAdornment position='start'>
                  <SearchIcon
                    sx={{ color: "text.disabled", width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
            />
          </Box>
        )
      )}

      <Box
        className='last'
        sx={{
          justifyContent: { xs: "flex-start", sm: "center" },
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
        }}
      >
        {numSelected > 0 ? (
          <Tooltip title={tabing === "completed" ? "Archive" : "Delete"}>
            <IconButton
              onClick={(e) => {
                dispatch(deleteSelectedInterview(interviewIds, tabing))
                resetSelected([])
              }}
            >
              {tabing === "completed" ? (
                <Inventory2OutlinedIcon />
              ) : (
                <DeleteIcon />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <>
            {tabing !== "active" && (
              <Typography
                variant='body2'
                component='span'
                sx={{
                  display: "flex",
                  fontWeight: 600,
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mr: 1,

                  color: "#212B36",
                }}
              >
                {fDateShort(startDate)} to {fDateShort(endDate)}
                <DateRangeIcon
                  sx={{ marginLeft: "6px" }}
                  width={20}
                  height={20}
                />
              </Typography>
            )}
            <FilterInterviewSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
              setInterviews={setInterviews}
              tabing={tabing}
              setTStartDate={setStartDate}
              setTEndDate={setEndDate}
            />
          </>
        )}
      </Box>
    </RootStyle>
  )
}
