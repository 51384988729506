import { Link as RouterLink, Navigate } from "react-router-dom"
import { styled, theme } from "@mui/material/styles"
import { Card, Link, Container, Typography } from "@mui/material"
import Slider from "react-slick"
import Page from "../components/Page"
import Logo from "../components/Logo/Logo"
import { LoginForm } from "../sections/auth/login"
import Images from "../assets/img/Images"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 920,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "0 auto",
  minHeight: 830,
  height: "100vh",
}))

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: 0,
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  paddingRight: "3rem",
  height: "100%",
  paddingLeft: "2rem",
}))
const BannerBox = styled("div")(({ theme }) => ({}))
const BannerBoxImg = styled("img")(({ theme }) => ({
  maxWidth: 400,
  width: "100%",
}))
const FooterLink = styled("div")(({ theme }) => ({
  pt: 1,
  pb: 1,
  margin: "1rem 0",
  textAlign: "left",
}))

// ----------------------------------------------------------------------

export default function Login() {
  const { t } = useTranslation()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const user = localStorage.getItem("user")
  if (user?.success === true) {
    return <Navigate to='/dashboard/app' />
  }

  return (
    <Page title={t("Login")} className='auth-screen login-screen'>
      <RootStyle className='auth-screen-row'>
        <SectionStyle className='left-panel'>
          <div className='left-panel-inner'>
            <Typography
              className='logo-box'
              variant='h3'
              sx={{ pr: 5, pl: 0, pt: 10, mb: 4 }}
            >
              <Logo icon={Images.LogoWhite} />
            </Typography>
            <BannerBox className='banner' sx={{ mt: 4, mb: 2 }}>
              <BannerBoxImg src={Images.SingupImage} alt='login' />
            </BannerBox>

            <Slider {...settings}>
              <div className='carousel-item active'>
                <Typography variant='h4'>Easy Interview Scheduling</Typography>
                <Typography component='p'>
                  Create job openings, select desired candidates and send
                  personalized invites with just one click.
                </Typography>
              </div>

              <div className='carousel-item '>
                <Typography variant='h4'>Live Interview Simulation</Typography>
                <Typography component='p'>
                  Authentic and recorded face-to-face interviewing. One take to
                  answer all questions.
                </Typography>
              </div>

              <div className='carousel-item '>
                <Typography variant='h4'>Questionnaire Builder</Typography>
                <Typography component='p'>
                  Set up unlimited unique question sets. Pick, reuse and tweak
                  the question sets as needed.
                </Typography>
              </div>

              <div className='carousel-item '>
                <Typography variant='h4'>
                  Recruitment Dashboards And Reports
                </Typography>
                <Typography component='p'>
                  Get insight into interviewing efforts and easily optimize the
                  recruitment strategies.
                </Typography>
              </div>
              <div className='carousel-item '>
                <Typography variant='h4'>Plug And Play Solution</Typography>
                <Typography component='p'>
                  Access the web with internet connection & a PC or a mobile
                  phone.
                </Typography>
              </div>
            </Slider>
          </div>
        </SectionStyle>

        <Container
          maxWidth='lg'
          className='right-panel'
          sx={{ background: "#fff" }}
        >
          <ContentStyle className='right-panel-inner'>
            <Typography
              className='logo-mb'
              variant='h3'
              sx={{
                px: 0,
                pt: { xs: 2, sm: 4, md: 10 },
                mb: { xs: 2, sm: 2, md: 5 },
                display: { sm: "block", lg: "block", xl: "none" },
              }}
            >
              <img src={Images.LogoMain} alt='LogoMain' />
            </Typography>

            <Typography variant='h3' sx={{ pb: 5 }}>
              Connect to your
              <br />
              IntVue account
            </Typography>

            <LoginForm />

            <FooterLink>
              <Typography
                className='terms-txt'
                variant='body2'
                align='left'
                sx={{
                  mt: 3,
                  color: "#9e9ea7",
                  fontSize: "12px",
                  margin: 0,
                  fontFamily: "Proxima-Nova-Semibold",
                }}
              >
                Copyright &copy; {new Date().getFullYear()} Intvue.
              </Typography>
              <Typography
                className='terms-txt'
                variant='body2'
                align='left'
                sx={{
                  mt: 3,
                  color: "#9e9ea7",
                  fontSize: "12px",
                  margin: 0,
                  fontFamily: "Proxima-Nova-Semibold",
                }}
              >
                <Link
                  underline='hover'
                  component={RouterLink}
                  to='/termsconditions/app'
                >
                  Terms of service
                </Link>{" "}
                and{" "}
                <Link
                  underline='hover'
                  component={RouterLink}
                  to='/privacypolicy/app'
                >
                  Privacy Policy
                </Link>{" "}
                apply.
              </Typography>
            </FooterLink>
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  )
}
