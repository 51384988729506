import * as ActionTypes from "../constants/errorManagement";

const initialState = { message: "", succeed: true, apiError:false, url: ""};
const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET:
      const obj = {
        ...state,
        message: "",
        succeed: true,
        apiError: false,
      };

      return obj;
    case ActionTypes.ERROR_SCENARIO:
      const obj1 = {
        ...state,
        message: action.payload.errors[0],
        succeed: action.payload.success,
      };

      return obj1;
    case ActionTypes.API_FAILURE:
      const obj3 ={
        APImessage: action.error?.message,
        message: action.error?.response?.data?.errors[0],
        status: action.error?.response?.status,
        apiError: true,
        url: action.url
      };
    return obj3
    default:
      return state;
  }
};

export default errorsReducer;
