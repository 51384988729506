import * as ActionTypes from "src/redux/constants/resetPassword";
import { auth_request } from "src/redux/middlewares/auth_service";
import * as ERRORS from "src/redux/constants/errorManagement";

export const resetPassword = (data) => {
  const endPoint = `/api/auth/password`;
  const method = `POST`;

  const options = { action: endPoint, method: method  };
  options.body = data;
  options.types = [
    ActionTypes.RESET_ON_SUCCESS,
    ActionTypes.RESET_ON_FAILURE,
    ERRORS.API_FAILURE,
  ];
  return auth_request(options);
};
