import { Link as RouterLink } from "react-router-dom"
import { styled } from "@mui/material/styles"
import { Button, Typography, Container, Box } from "@mui/material"
import Page from "../components/Page"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))

// ----------------------------------------------------------------------

export default function Page404() {
  const { t } = useTranslation()
  return (
    <Page title='404 Page Not Found'>
      <Container>
        <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
          <Typography variant='h3' paragraph>
            {t("PageNotFound")}
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            {t("PageNotFoundTxt1")}
          </Typography>

          <Box
            component='img'
            src='/static/illustrations/illustration_404.svg'
            sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
          />

          <Button
            to='/'
            size='large'
            variant='contained'
            component={RouterLink}
          >
            {t("GoHome")}
          </Button>
        </ContentStyle>
      </Container>
    </Page>
  )
}
