import React, { useRef, useState } from "react"
import { Box, Typography } from "@mui/material"
import {
  FileUploadContainer,
  FormField,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  RemoveFileIcon,
  DragDropText,
} from "../../components/dragndrop/dragndrop.style"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { useTranslation } from "react-i18next"
import FilePresentIcon from "@mui/icons-material/FilePresent"
const KILO_BYTES_PER_BYTE = 1000

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key])

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE)

const DragDrop = ({
  updateFilesCb,
  value,
  onError,
  onChange,
  url,
  validFileTypes,
  maxFileSizeInBytes,
  isImage = false,
  ...otherProps
}) => {
  const [urlImage, setUrl] = useState(true)
  const [dragging, setDragging] = useState(false)
  const fileImgInputField = useRef(null)
  const { t } = useTranslation()

  const handleUploadImgBtnClick = () => {
    fileImgInputField.current.click()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    setDragging(false)
  }

  const handleDrop = (e) => {
    e.preventDefault()
    setDragging(false)

    const files = e.dataTransfer.files
    handleFiles(files)
  }

  const addNewFiles = (newFiles) => {
    for (let file of newFiles) {
      if (file.size > maxFileSizeInBytes) {
        onError(`Max size exceeded ${maxFileSizeInBytes} bytes`) // eslint-disable-
      } else if (!validFileTypes.includes(file.type)) {
        onError(t("This file type is not allowed"))
      } else {
        onError("")
      }
      return { file }
    }
    return { ...value }
  }

  const handleFiles = (files) => {
    if (files.length) {
      let updatedFiles = addNewFiles(files)
      onChange(updatedFiles)
    }
  }
  const removeFile = (fileName) => {
    delete value[fileName]
    onChange({ ...value })
  }

  return (
    <>
      <FileUploadContainer>
        <Box
          className={`file-uploader ${dragging ? "dragging" : ""}`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <UploadFileBtn
            type='button'
            onClick={handleUploadImgBtnClick}
            className='full-wd-btn dragndrop-btn'
          >
            <FileUploadIcon />
            <DragDropText>{t("Drag & Drop or Choose File")}</DragDropText>
          </UploadFileBtn>
          <FormField
            type='file'
            ref={fileImgInputField}
            id='fileInput'
            style={{ display: "none" }}
            onChange={(e) => handleFiles(e.target.files)}
            multiple={false}
            {...otherProps}
          />
        </Box>
      </FileUploadContainer>

      {url
        ? urlImage && (
            <FilePreviewContainer>
              <PreviewList>
                <PreviewContainer>
                  {isImage && <ImagePreview src={url} alt={`file preview`} />}
                </PreviewContainer>
                <Box sx={{ pr: 3, maxWidth: "80%" }}>
                  <Typography
                    variant='body1'
                    sx={{ display: "block", wordBreak: "break-word" }}
                  >
                    {value?.name?.length > 25
                      ? `${value?.name?.slice(0, 20)}...${
                          value?.name?.split(".").reverse()[0]
                        }`
                      : value?.name}
                  </Typography>

                  <Typography variant='body1' sx={{ fontSize: "0.8rem" }}>
                    <FilePresentIcon
                      sx={{ fontSize: "14px", mr: "2px", mb: "-2px" }}
                    />{" "}
                    {convertBytesToKB(value?.size)} kb
                  </Typography>
                </Box>
                <RemoveFileIcon>
                  <i
                    onClick={() => {
                      onChange({ ...value, removed: true })
                      setUrl(false)
                    }}
                  >
                    {" "}
                    <DeleteOutlineIcon />
                  </i>
                </RemoveFileIcon>
              </PreviewList>
            </FilePreviewContainer>
          )
        : Object.keys(value).map((fileName, index) => {
            let file = value[fileName]

            let isImageFile = validFileTypes.includes(file?.type)
            return (
              isImageFile && (
                <FilePreviewContainer key={file?.name}>
                  <PreviewList>
                    <PreviewContainer key={fileName}>
                      {isImageFile && isImage && (
                        <ImagePreview
                          src={URL.createObjectURL(file)}
                          alt={`file preview ${index}`}
                          style={{ maxWidth: "130px" }}
                        />
                      )}
                    </PreviewContainer>
                    <Box sx={{ pr: 3, maxWidth: "80%" }}>
                      <Typography
                        variant='body1'
                        sx={{ display: "block", wordBreak: "break-word" }}
                      >
                        {file?.name?.length > 35
                          ? `${file?.name?.slice(0, 30)}...${
                              file?.name?.split(".").reverse()[0]
                            }`
                          : file?.name}
                      </Typography>

                      <Typography variant='body1' sx={{ fontSize: "0.8rem" }}>
                        <FilePresentIcon
                          sx={{ fontSize: "14px", mr: "2px", mb: "-2px" }}
                        />{" "}
                        {convertBytesToKB(file?.size)} kb
                      </Typography>
                    </Box>
                    <RemoveFileIcon>
                      <DeleteOutlineIcon onClick={() => removeFile(fileName)} />
                    </RemoveFileIcon>
                  </PreviewList>
                </FilePreviewContainer>
              )
            )
          })}
    </>
  )
}
export default DragDrop
