import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useTranslation } from "react-i18next"

export default function ViewCookies({ hide, open }) {
  const { t } = useTranslation()
  const setValues = (response) => {
    localStorage.setItem("cookies", response)
    hide()
  }

  return (
    <Dialog
      className='dialog800'
      open={open}
      onClose={hide}
      scroll='paper'
      maxWidth='xl'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        Customize Consent Preferences
        <IconButton
          aria-label='close'
          onClick={hide}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className='dialog-content  page-content '
        dividers={true}
        sx={{ borderTop: 0 }}
      >
        <Typography variant='body1'>
          The cookies we use help us improve your experience on our site and
          analyse our site traffic and browsing performance, make it more
          secure, and understand where it needs improvement.
        </Typography>
        <Typography variant='body1'>
          The cookies categorized as "Necessary" enable the basic functionality
          of the site and are stored on your browser. We use cookies that help
          analyse our browsing performance, and store your preferences. These
          cookies are only stored on your browser with your prior consent. You
          can select to enable or disable some or all of these cookies.
        </Typography>
        <hr />
        <Typography variant='h5' component='h5' sx={{ my: 1 }}>
          Necessary
        </Typography>
        <Typography variant='body1'>
          Necessary cookies are required to enable the basic functionality of
          the site, secure log-in, and managing your consent preferences. These
          cookies do not store personally identifiable data.
        </Typography>
        <List>
          <ListItem>No cookies used.</ListItem>
        </List>

        <Typography variant='h5' component='h5' sx={{ my: 1 }}>
          Analytics
        </Typography>
        <Typography variant='body1'>
          {/* Analytical cookies help us understand how users interact with our
          website and provide information such as the number of visitors, bounce
          rate, traffic source, etc. */}
        </Typography>
        <List>
          <ListItem>No cookies used.</ListItem>
        </List>

        <Typography variant='h5' component='h5' sx={{ my: 1 }}>
          Functional
        </Typography>
        <Typography variant='body1'>
          <List>
            <ListItem>No cookies used.</ListItem>
          </List>
          {/* Functional cookies help perform specific site functionality; including
          sharing site content on social media platforms, collecting feedback,
          etc.{" "} */}
        </Typography>

        <Typography variant='h5' component='h5' sx={{ my: 1 }}>
          Performance
        </Typography>
        <Typography variant='body1'>
          Performance cookies help us understand and analyze the key performance
          indicators that help us to deliver an enhanced user experience for our
          site visitors
        </Typography>
        <List>
          <ListItem>No cookies used.</ListItem>
        </List>
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button
          onClick={() => setValues("declined")}
          variant='outlined'
          color='primary'
          size='medium'
        >
          {t("Reject All")}
        </Button>
        <Button
          onClick={() => setValues("accepted")}
          variant='contained'
          color='primary'
          size='medium'
        >
          {t("Accept and Continue")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
