import * as ActionTypes from "../constants/interviewers"
const initialState = { data: [], isSucceed: false, isCreated: false }
const interviewersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHINTERVIEWERS_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }
    case ActionTypes.CREATEINTERVIEWER_ON_SUCCESS:
      const obj1 = {
        ...state,
        isCreated: true,
      }
      return obj1

    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        isSucceed: false,
        isCreated: false,
      }
    default:
      return state
  }
}

export default interviewersReducer
