import PlansCard from "./PlansCard"
import React from "react"
import { Card, Grid, Typography, List, ListItem } from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
import { useSelector } from "react-redux"
export default function PlansList({
  plans,
  t,
  subscribedPlan,
  isOnTrial,
  isSubscriptionExpired,
  isYearlyChecked,
  onClickSubscribePlan,
  onClickUnsubscribePlan,
  onClickBuyInterviews,
}) {
  const logged = useSelector((state) => state.login)

  return (
    <Grid container spacing={3}>
      {plans &&
        plans?.length > 0 &&
        plans
          ?.filter((plan) => {
            return isYearlyChecked
              ? plan.interval === "year"
              : plan.interval === "month"
          })
          .map((plan) => (
            <Grid
              key={plan?.id}
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={3}
              className='package-div'
            >
              <PlansCard
                t={t}
                plan={plan}
                isSubscribedPlan={plan?.id === subscribedPlan?.id}
                isOnTrial={isOnTrial}
                // canManageBilling={canManageBilling}
                isSubscriptionExpired={isSubscriptionExpired}
                onClickSubscribePlan={onClickSubscribePlan}
                onClickUnsubscribePlan={onClickUnsubscribePlan}
                onClickBuyInterviews={onClickBuyInterviews}
              />
            </Grid>
          ))}
      <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
        <Card className='package-block-sub lt-gray-box'>
          <Typography
            variant='h3'
            component='h3'
            sx={{ mb: 1, fontWeight: 500 }}
          >
            {t("AllPlansInclude")}
          </Typography>
          <List sx={{ width: "100%", py: 0 }}>
            <ListItem>
              <DoneIcon
                className='iconWrap'
                sx={{
                  color: "#059669",
                  width: "20px",
                  minWidth: "20px",
                  height: "20px",
                  mr: 1,
                }}
              />
              {t("UnlimitedQuestionSets")}
            </ListItem>
            <ListItem>
              <DoneIcon
                className='iconWrap'
                sx={{
                  color: "#059669",
                  width: "20px",
                  minWidth: "20px",
                  height: "20px",
                  mr: 1,
                }}
              />

              {t("Dashboard")}
            </ListItem>
            <ListItem>
              <DoneIcon
                className='iconWrap'
                sx={{
                  color: "#059669",
                  width: "20px",
                  minWidth: "20px",
                  height: "20px",
                  mr: 1,
                }}
              />

              {t("FeedbackManagement")}
            </ListItem>
            <ListItem>
              <DoneIcon
                className='iconWrap'
                sx={{
                  color: "#059669",
                  width: "20px",
                  minWidth: "20px",
                  height: "20px",
                  mr: 1,
                }}
              />

              {t("CompanyBranding")}
            </ListItem>
            <ListItem>
              <DoneIcon
                className='iconWrap'
                sx={{
                  color: "#059669",
                  width: "20px",
                  minWidth: "20px",
                  height: "20px",
                  mr: 1,
                }}
              />
              {t("CustomEmailMessages")}
            </ListItem>
            <ListItem>
              <DoneIcon
                className='iconWrap'
                sx={{
                  color: "#059669",
                  width: "20px",
                  minWidth: "20px",
                  height: "20px",
                  mr: 1,
                }}
              />
              {t("DownloadInterviews")}
            </ListItem>
          </List>
        </Card>
      </Grid>
    </Grid>
  )
}
