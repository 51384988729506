import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/accountConfig";

export const getAccountConfig = (id) => {
  const endpoint = `/api/admin/account_configurations/h0hghdoq`;
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.FETCHACCOUNTCONFIG_ON_SUCCESS,
    ActionTypes.FETCHACCOUNTCONFIG_ON_FAILURE,
  ];
  return request(options);
};

export const updateAccountConfig = (id, data) => {
  const endpoint = `/api/admin/account_configurations/${id}`;
  const method = "PUT";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.EDITACCOUNT_ON_SUCCESS,
    ActionTypes.EDITACCOUNT_ON_FAILURE,
  ];
  return request(options);
};

export const updateSMTPConfig = (data) => {
  const endpoint = `/api/admin/account_configurations/smtp_settings`;
  const method = "PATCH";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.SMTP_ON_SUCCESS,
    ActionTypes.SMTP_ON_FAILURE,
  ];
  return request(options);
};

export const deleteSampleData = (data) => {
  const endpoint = `/api/admin/account_configurations/deleted_sample_data`;
  const method = "PATCH";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.SAMPLE_ON_SUCCESS,
    ActionTypes.SAMPLE_ON_FAILURE,
  ];
  return request(options);
};

export const resetConfigurationsFlag = () => {
  return { type: ActionTypes.RESET_FLAGS };
};

export const updateFeedbackPoints = (data) => {
  const endpoint = `/api/admin/account_configurations/custom_feedback`;
  const method = "PATCH";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.SMTP_ON_SUCCESS,
    ActionTypes.SMTP_ON_FAILURE,
  ];
  return request(options);
};