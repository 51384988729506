import * as ActionTypes from "../constants/stripe";

export const cardDetails = (action, data) => {
  if (action === ActionTypes.CARD_VERIFIED) {
    return {
      type: action,
      payload: data,
    };
  }

  if (action === ActionTypes.CARD_DETAILS) {
    return {
      type: action,
      payload: data,
    };
  }

  if (action === ActionTypes.CARD_ERROR) {
    return {
      type: action,
      payload: data,
    };
  }

  if (action === "fetch") {
    return { type: action };
  }
};
