import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/needHelp";

export const needHelp = (data) => {
  const endpoint = `/api/v1/helps/need_help`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };
  options.body = data;
  options.types = [
    ActionTypes.NEED_HELP_SUCCESS,
    ActionTypes.NEED_HELP_FAILURE,
  ];
  return request(options);
};
