import React, { useState } from "react"
import {
  Button,
  Typography,
  MenuItem,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  OutlinedInput,
  Box,
  Chip,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useFormik, Form, FormikProvider } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { createInterviewers } from "src/redux/actions/interviewers"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"

//************************** */
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

// ------------
export default function AddInterviewerModal({ isShowingInterviewer, hide }) {
  const [initialState, setInitialState] = useState({
    user: [],
  })

  const { t } = useTranslation()

  const members = useSelector((state) => state.members)
  const intVuerList = useSelector((state) => state.interviewer)

  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues: initialState,
    onSubmit: (values, { resetForm }) => {
      if (isEmpty(values?.user) || isEmpty(user)) {
        setSelectUserError("Select atleast one user")
        return
      }
      let formData = {}
      formData.user = values?.user
      dispatch(createInterviewers(formData))
      hide()
      setSelectUserError("")
      setUser([])
      resetForm()
    },
  })

  const { handleSubmit, setFieldValue, values } = formik

  const [user, setUser] = useState([])
  const [selectUserError, setSelectUserError] = useState("")

  const handleClose = () => {
    values.user = ""
    hide()
    setUser([])
    setSelectUserError("")
  }

  return (
    isShowingInterviewer && (
      <Dialog
        className='dialog400'
        open={isShowingInterviewer}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='sm'
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {"Select Users"}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent className='dialog-content' dividers={true}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormikProvider value={formik}>
                <Form
                  id='addInterviewer'
                  autoComplete='off'
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel htmlFor='memberType' required>
                          Select a User
                        </FormLabel>
                        <Select
                          className='demo-select'
                          name='Users'
                          multiple
                          MenuProps={MenuProps}
                          value={user}
                          sx={{ textTransform: "capitalize" }}
                          input={<OutlinedInput />}
                          renderValue={(user) => (
                            <Box
                              sx={{
                                overflow: "auto",
                                maxWidth: "300px",
                                wordWrap: "break-word",
                                whiteSpace: "normal",
                                maxHeight: "250px",
                              }}
                            >
                              {user.map((value) => (
                                <Chip
                                  key={value.split(",")[1]}
                                  label={value.split(",")[0]}
                                  sx={{ mx: 1, mb: 1 }}
                                />
                              ))}
                            </Box>
                          )}
                          onChange={(e, value) => {
                            let ids = e.target.value.map(
                              (val) => val.split(",")[1],
                            )
                            let selectedUser = members?.data?.users?.filter(
                              (mem) => ids.includes(mem.id.toString()),
                            )
                            setSelectUserError(
                              isEmpty(e.target.value)
                                ? "Select atleast one user"
                                : "",
                            )
                            setUser(e.target.value)

                            setFieldValue(
                              "user",
                              selectedUser?.map((user) => ({
                                user_id: user.id,
                                department_id: user.department_ids[0],
                              })),
                            )
                          }}
                        >
                          {members?.data?.users
                            ?.filter(
                              (usr) =>
                                usr.is_active === true &&
                                !intVuerList?.data?.interviewers?.some(
                                  (intvuer) => intvuer?.user_id === usr.id,
                                ),
                            ).length === 0 &&
                            <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                          }
                          {members?.data?.users
                            ?.filter(
                              (usr) =>
                                usr.is_active === true &&
                                !intVuerList?.data?.interviewers?.some(
                                  (intvuer) => intvuer?.user_id === usr.id,
                                ),
                            )
                            .map((option) => (
                              <MenuItem
                                sx={{ textTransform: "capitalize" }}
                                key={option?.id}
                                value={option?.full_name + "," + option?.id}
                              >
                                <Typography sx={{ whiteSpace: "normal" }}>
                                  {option?.full_name +
                                    `${
                                      isEmpty(option.departments_name)
                                        ? ""
                                        : (" - " + option?.departments_name)
                                    } `}
                                </Typography>
                              </MenuItem>
                            ))}
                        </Select>
                        {selectUserError && (
                          <Typography
                            variant='span'
                            sx={{
                              marginTop: "5px",
                              color: "#FF4842",
                              fontWeight: 400,
                              fontSize: "1rem",
                            }}
                          >
                            {selectUserError}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='primary'
            size='medium'
          >
            Cancel
          </Button>

          <Button
            autoFocus
            onClick={handleSubmit}
            variant='contained'
            size='medium'
            type='submit'
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
