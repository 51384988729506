import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/profile";

export const getAllProfiles = () => {
  const endpoint = `/api/admin/profiles`;
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.FETCHPROFILES_ON_SUCCESS,
    ActionTypes.FETCHPROFILES_ON_FAILURE,
  ];
  return request(options);
};

export const getProfile = (id) => {
  const endpoint = `/api/admin/profiles/${id}`;
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.FETCHPROFILE_ON_SUCCESS,
    ActionTypes.FETCHPROFILE_ON_FAILURE,
  ];
  return request(options);
};

export const createProfile = (data) => {
  const endpoint = `/api/admin/profiles`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CREATEPROFILE_ON_SUCCESS,
    ActionTypes.CREATEPROFILE_ON_FAILURE,
  ];
  return request(options);
};

export const updateProfile = (id, data) => {
  const endpoint = `/api/admin/profiles/${id}`;
  const method = "PUT";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.UPDATEPROFILE_ON_SUCCESS,
    ActionTypes.UPDATEPROFILE_ON_FAILURE,
  ];
  return request(options);
};
