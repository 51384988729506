import axios from "axios"

// Function to handle success case
const handleSuccess = (dispatch, successType, response) => {
  dispatch({
    type: successType,
    payload: response,
  })
}

// Function to handle failure case
const handleFailure = (dispatch, failureType, response) => {
  dispatch({
    type: failureType,
    payload: response,
  })
}

// Function to handle API failure
const handleApiFailure = (dispatch, apiFailure, error) => {
  dispatch({
    type: apiFailure,
    error: error,
  })
}

// Function to handle login headers
const handleLoginHeaders = (response) => {
  localStorage.setItem("user", JSON.stringify(response.data))
  localStorage.setItem("accessHeader", JSON.stringify(response.headers))
  localStorage.setItem("logged", true)
  sessionStorage.setItem("logged", true)
  sessionStorage.setItem("user", JSON.stringify(response.data))
  sessionStorage.setItem(
    "creation_info",
    JSON.stringify(response?.data?.sample_data),
  )
  sessionStorage.setItem("accessHeader", JSON.stringify(response.headers))
  if (response.data.success) {
    localStorage.setItem("key", JSON.stringify(response.data.user.key))
    sessionStorage.setItem("key", JSON.stringify(response.data.user.key))
  }
}

export const auth_request =
  (options, formData = null) =>
  (dispatch) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const [successType, failureType, apiFailure, resetFlags] = options.types
    const API_URL = `${BASE_URL}${options.action}`

    const params = {
      method: options.method,
      url: API_URL,
    }

    if (options.body) {
      params.data = options.body
    }

    if (!options.headers) {
      const header = {
        accept: "application/json",
        "Content-Type": "application/json",
      }
      params.headers = header
    }

    axios(params)
      .then((response) => {
        if (options.headers === "login") {
          handleLoginHeaders(response)
        }

        if (options.headers?.page === "signUp" && response?.status === 204) {
          dispatch({
            type: resetFlags,
            payload: response,
          })
        }

        if (response?.status === 200) {
          if (response.data.success === true) {
            handleSuccess(dispatch, successType, response)
          } else {
            handleFailure(dispatch, failureType, response)
          }
        }
      })
      .catch((error) => {
        handleApiFailure(dispatch, apiFailure, error)
      })
  }
