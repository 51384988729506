import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import { Card, Typography } from "@mui/material"
import { fShortenNumber } from "../../../utils/formatNumber"

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: 0,
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  position: "absolute",
  right: "8px",
  top: "15px",
}))

// ----------------------------------------------------------------------

AppStates.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  sx: PropTypes.object,
}

export default function AppStates({ dstats, sx, t, ...other }) {
  const { title, total, func, icon, color } = dstats
  return (
    <Card
      className='dashboard-stats-header'
      sx={{
        cursor: "pointer",
        py: 5,
        boxShadow: "0px 15px 14px rgb(20 33 49 / 15%)",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingLeft: "20px",
        paddingTop: "30px",
        paddingBottom: "12px",
        paddingRight: "20px",
        minHeight: "120px",
        bgcolor: color,
        ...sx,
      }}
      onClick={func}
      {...other}
    >
      <IconWrapperStyle className='icon-wraper'>
        <img
          src={`/static/mock-images/stats/` + icon + `.svg`}
          alt='img'
          className='icon-cls'
        />
      </IconWrapperStyle>
      <Typography
        variant='h3'
        sx={{ color: color === "white" ? "black" : "#fff" }}
      >
        {t(fShortenNumber(total))}
      </Typography>
      <Typography
        variant='p'
        sx={{
          color:
            color === "white" ? "rgba(0, 0, 0, 0.8);" : "hsla(0,0%,100%,.8)",
        }}
      >
        {t(title)}
      </Typography>
    </Card>
  )
}
