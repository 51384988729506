import PropTypes from "prop-types"
import merge from "lodash/merge"
import ReactApexChart from "react-apexcharts"
import { useTheme, styled } from "@mui/material/styles"
import { Card, CardHeader, Box } from "@mui/material"
import { fNumber } from "../../../utils/formatNumber"
import { BaseOptionChart } from "../../../components/chart"
import { useTranslation } from "react-i18next"
// ----------------------------------------------------------------------

const CHART_HEIGHT = 372
const LEGEND_HEIGHT = 72

const ChartWrapperStyle = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}))

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
}

export default function AppCurrentVisits({
  title,
  subheader,
  chartColors,
  chartData,
  ...other
}) {
  const theme = useTheme()
  const { t } = useTranslation()
  const chartLabels = chartData.map((i) => i.label)

  const chartSeries = chartData.map((i) => i.value)
  // Check if all values are zero
  const allValuesAreZero = chartData && chartData.every((i) => i?.value === 0)
  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: "center" },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  })

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} />

      <ChartWrapperStyle dir='ltr'>
        {allValuesAreZero ? (
          <Box sx={{ px: 3, pb: 1 }}>{t("DataNotFound")}</Box>
        ) : (
          <ReactApexChart
            type='pie'
            series={chartSeries}
            options={chartOptions}
            height={280}
          />
        )}
      </ChartWrapperStyle>
    </Card>
  )
}
