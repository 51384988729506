import React, { useEffect, useState } from "react"
import {
  Grid,
  Alert,
  Stack,
  Button,
  FormControl,
  CircularProgress,
  TextField,
  FormLabel,
  Typography,
  Link,
} from "@mui/material"
import * as Yup from "yup"
import { useFormik, Form, FormikProvider } from "formik"
import VideoUploadCompanyPage from "src/components/video-upload/VideoUploadCompanyPage"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { getCompanyInfo, updateCompanyInfo, resetSettingsFlag } from "src/redux/actions/general"
import { useDispatch, useSelector } from "react-redux"
import { useLocalStorage } from "src/hooks/useLocalStorage"
import { useTranslation } from "react-i18next"

import DragDrop from "src/components/dragndrop/DragDrop"
import { isEmpty } from "lodash"
// ----------------------------------------------------------------------
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1073741824 //1 gb file

const ALLOWED_IMAGES_FORMAT = [
  "image/svg",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
]

export default function CompanyProfile({
  setDisplayCrash,
  setOpenSnackbar,
  setSeverity,
}) {
  const { t } = useTranslation()

  const logged = useSelector((state) => state.login)
  const updateCompanyInfoState = useSelector((state) => state.updateCompanyInfo)

  const userPermissions = logged?.user?.profile?.permission
  const [newUserInfo, setNewUserInfo] = useState({
    profileImages: [],
  })
  const updateUploadedFiles = (files) =>
    setNewUserInfo({ ...newUserInfo, profileImages: files })

  const [userKey] = useLocalStorage("key", "")
  const [loading, setLoading] = useState(false)
  const [videoFileError, setVideoFileError] = useState("")
  const [videoUpdated, setVedioUpdated] = useState(false)

  const [initVal, setinitVal] = useState({
    companyName: "",
    companyWebsite: "",
    companyOverview: "",
    emailAliasForInterview: "",
    defaultContactEmail: "",
    defaultContactNumber: "",
    uploadVideo: {},
    uploadLogo: {},
  })

  const companyInfo = useSelector((state) => state.getCompanyInfo)
  const dispatch = useDispatch()

  useEffect(() => {
    if (companyInfo?.data?.length === 0) {
      dispatch(getCompanyInfo(userKey))
    }

    if (companyInfo?.data?.success) {
      setinitVal({
        companyName: companyInfo?.data?.account?.company_name,
        companyWebsite: companyInfo?.data?.account?.website,
        companyOverview: companyInfo?.data?.account?.introduction,
        emailAliasForInterview: companyInfo?.data?.account?.email_alias,
        defaultContactEmail: companyInfo.data.account.default_contact_email,
        defaultContactNumber: companyInfo.data.account.default_contact_num,
        uploadLogo: companyInfo.data.account.logo_url,
        uploadVideo: companyInfo.data.account.default_video_url,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInfo])

  useEffect(() => {
    if (updateCompanyInfoState) {
      if (updateCompanyInfoState?.isSucceed) {
        setOpenSnackbar(true)
        setDisplayCrash(t("CompanyProfileUpdate"))
        setSeverity("success")
        dispatch(getCompanyInfo(userKey))
        dispatch(resetSettingsFlag())
      } else if (updateCompanyInfoState?.data.success === false) {
        setOpenSnackbar(true)
        setDisplayCrash(updateCompanyInfoState?.data.errors)
        setSeverity("error")
        dispatch(resetSettingsFlag())
      }
      setLoading(false)
    }
  }, [updateCompanyInfoState])

  const RegisterSchema = Yup.object().shape({
    emailAliasForInterview: Yup.string().required(t("ErrFnameRequired")),
    defaultContactEmail: Yup.string()
      .email(t("ErrValidEmail"))
      .required(t("ErrEmailRequired")),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initVal,
    validationSchema: RegisterSchema,
    onSubmit: (values, { isSubmitting, resetForm }) => {
      if (isValid === false) return

      setLoading(true)
      const newFormData = new FormData()

      if (values.uploadLogo.file === undefined) {
        if (values.uploadLogo.removed) {
          newFormData.append("logo", "")
        }
      } else {
        newFormData.append("logo", values.uploadLogo.file)
      }

      if (videoUpdated) {
        newFormData.append("default_video_url", values.uploadVideo.file)
        setVedioUpdated(false)
      }
      newFormData.append("company_name", values.companyName)
      newFormData.append("website", values.companyWebsite)
      newFormData.append("default_contact_email", values.defaultContactEmail)
      newFormData.append("email_alias", values.emailAliasForInterview)
      newFormData.append("default_contact_num", values.defaultContactNumber)
      newFormData.append("introduction", values.companyOverview)
      newFormData.append("transcode", false)

      dispatch(updateCompanyInfo(userKey, newFormData))
    },
  })
  const {
    errors,
    touched,
    isSubmitting,
    getFieldProps,
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    handleBlur,
  } = formik

  const canEditCompanyProfile =
    userPermissions?.settings_user_can_edit_the_company_profile

  const [imageFileError, setImageFileError] = useState("")
  const [isValid, setIsValid] = useState(true)
  const [validLength, setValidLength] = useState(15)
  const getUrl = (url) => `https://${url}`

  const defaultNumberHandler = (value, country, e, data, formattedValue) => {
    setIsValid(
      country?.format?.length === e.target.value.length || isEmpty(value),
    )

    setValidLength(country?.format?.length ?? 15)
    setFieldValue("defaultContactNumber", e.target.value)
  }

  const onKeyDown = (e) => {
    if (e.target.value.length === validLength && !isNaN(e.key)) {
      e.preventDefault()
    }
  }
  /*    ---------------------------------------- */
  return (
    <>
      <Alert severity='info'>{t("UpdateCompanyOverview")}</Alert>

      <FormikProvider value={formik}>
        <Form
          id='companyInfoForm'
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel>{t("CompanyName")}</FormLabel>
                <Typography
                  variant='h5'
                  id='companyName'
                  name='companyName'
                  sx={{ color: "#637381", fontWeight: 600 }}
                >
                  {values?.companyName}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel>{t("Website")}</FormLabel>
                <Typography
                  variant='h5'
                  id='companyWebsite'
                  name='companyWebsite'
                  sx={{ color: "#637381" }}
                >
                  <Link href={getUrl(values?.companyWebsite)} target='_blank'>
                    {values?.companyWebsite}
                  </Link>
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel htmlFor='companyOverview'>
                  {t("CompanyOverview")}
                </FormLabel>
                <TextField
                  fullWidth
                  rows='3'
                  multiline
                  id='companyOverview'
                  name='companyOverview'
                  {...getFieldProps("companyOverview")}
                  disabled={!canEditCompanyProfile}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel htmlFor='emailAliasForInterview'>
                  {t("SenderName")}
                </FormLabel>
                <TextField
                  fullWidth
                  name='emailAliasForInterview'
                  id='emailAliasForInterview'
                  value={values.emailAliasForInterview}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.emailAliasForInterview &&
                    Boolean(errors.emailAliasForInterview)
                  }
                  helperText={
                    touched.emailAliasForInterview &&
                    errors.emailAliasForInterview
                  }
                  {...getFieldProps("emailAliasForInterview")}
                  disabled={!canEditCompanyProfile}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel htmlFor='defaultContactEmail'>
                  {t("DefaultContactEmail")}
                </FormLabel>
                <TextField
                  fullWidth
                  id='defaultContactEmail'
                  name='defaultContactEmail'
                  value={values.defaultContactEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.defaultContactEmail &&
                    Boolean(errors.defaultContactEmail)
                  }
                  helperText={
                    touched.defaultContactEmail && errors.defaultContactEmail
                  }
                  {...getFieldProps("defaultContactEmail")}
                  // disabled={!canEditCompanyProfile}
                  disabled
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel htmlFor='defaultContactNumber'>
                  {t("DefaultContactNumber")}
                </FormLabel>
                <PhoneInput
                  country={"pak"}
                  value={values.defaultContactNumber}
                  onChange={defaultNumberHandler}
                  id='defaultContactNumber'
                  autoComplete='off'
                  name='defaultContactNumber'
                  autoFormat
                  enableLongNumbers={true}
                  enableSearch={true}
                  inputClass='phone_input'
                  disabled={!canEditCompanyProfile}
                  onKeyDown={onKeyDown}
                  searchNotFound={t("No entries to show")}
                  searchPlaceholder={t("Search country")}
                />
              </FormControl>
              {!isValid && (
                <Typography
                  variant='span'
                  sx={{
                    marginTop: "5px",
                    color: "#FF4842",
                    fontWeight: 400,
                    fontSize: "1rem",
                  }}
                >
                  {t("Invalid phone number")}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                display: { xs: "block", sm: "block", md: "none", lg: "none" },
              }}
            ></Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl sx={{ mb: 1 }} className='logo-field'>
                <FormLabel>{t("CompanyLogo")}</FormLabel>
                {canEditCompanyProfile === true ? (
                  <>
                    <DragDrop
                      accept='image/*'
                      value={values.uploadLogo}
                      url={values.uploadLogo.url}
                      name='uploadLogo'
                      onChange={(file) => {
                        setFieldValue("uploadLogo", file)
                      }}
                      updateFilesCb={updateUploadedFiles}
                      onError={(error) => setImageFileError(error)}
                      validFileTypes={ALLOWED_IMAGES_FORMAT}
                      maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
                      isImage={true}
                    />
                    {imageFileError && (
                      <Typography sx={{ color: "red" }}>
                        {imageFileError}
                      </Typography>
                    )}
                  </>
                ) : (
                  <img src={values.uploadLogo.url} alt='image' width='150' />
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <FormControl sx={{ mb: 1 }} className='video-field'>
                <FormLabel htmlFor='uploadVideo'>{t("DefaultVideo")}</FormLabel>

                <VideoUploadCompanyPage
                  t={t}
                  value={values.uploadVideo}
                  url={values.uploadVideo.url}
                  name='uploadVideo'
                  onChange={(video) => {
                    setFieldValue("uploadVideo", video)
                    setVedioUpdated(true)
                  }}
                  onError={(error) => setVideoFileError(error)}
                  width={200}
                  height={124}
                  canEdit={canEditCompanyProfile}
                />
                {videoFileError && (
                  <Typography sx={{ color: "red" }}>
                    {videoFileError}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
      <Grid item xs={12} sm={4}>
        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={4}
        >
          {canEditCompanyProfile && (
            <Button
              loadingindicator='Loading…'
              variant='contained'
              color='primary'
              type='submit'
              onClick={handleSubmit}
              disabled={loading}
            >
              {t("Save")}
            </Button>
          )}

          {loading && <CircularProgress />}
        </Stack>
      </Grid>
    </>
  )
}
