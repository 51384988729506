import * as ActionTypes from "../constants/department";

const initialState = {
  data: [],
  message: "",
  error: "",
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  isSucceed: false,
  isFailure: false,
  created: false,
};
const departmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHDEPARTMENTS_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: true,
      };

      // case ActionTypes.CREATEDEPARTMENT_ON_SUCCESS:
      //   return {
      //     ...state,
      //     message: action.payload.message,
      //     isCreated: true,
      //   };
      // case ActionTypes.CREATEDEPARTMENT_ON_FAILURE:
      //   return {
      //     ...state,
      //     isFailure: true,
      //     error: action.payload.errors,
      //   };

    case ActionTypes.EDITDEPARTMENT_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isUpdated: true,
      };

    case ActionTypes.EDITDEPARTMENT_ON_FAILURE:
      return {
        ...state,
        isFailure: true,
        error: action.payload.errors,
      };

    case ActionTypes.DELETE_SELECTED_DEPARTMENTS_ON_SUCCESS:
      return { ...state, message: action.payload.message, isDeleted: true };

    case ActionTypes.DELETEDEPARTMENT_ON_SUCCESS:
      return { ...state, message: action.payload.message, isDeleted: true };

    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        message: "",
        isCreated: false,
        isUpdated: false,
        isDeleted: false,
        isSucceed: false,
        isFailure: false,
      };
    default:
      return state;
  }
};

export default departmentReducer;



export const createDepartmentReducer=(state=initialState,action)=>{
  switch (action.type){
    case ActionTypes.CREATEDEPARTMENT_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isCreated: true,
        created: true
      };
    case ActionTypes.CREATEDEPARTMENT_ON_FAILURE:
      return {
        ...state,
        isFailure: true,
        error: action.payload.errors,
      };
    case ActionTypes.RESET_CREATED:
      return {
        ...state,
        created: false
      }
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        message: "",
        isCreated: false,
        isUpdated: false,
        isDeleted: false,
        isSucceed: false,
        isFailure: false,
      };
    default :
      return state;
  }
}