import * as ActionTypes from "../constants/member";

const initialState = {
  data: [],
  error: "",
  message: "",
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  isSucceed: false,
  isActivated: false,
  isFailure: false,
  memberPrivilege: false,
  isResend: false,
  profileEdit: false,
};

const membersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHMEMBERS_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: true,
      };

    case ActionTypes.CREATEMEMBER_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      };
    case ActionTypes.CREATEMEMBER_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isCreated: true,
      };

    case ActionTypes.DELETEMEMBER_ON_SUCCESS:
      return { ...state, message: action.payload.message, isDeleted: true };

    case ActionTypes.DELETEMEMBER_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      };

    case ActionTypes.EDITEMEMBER_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isUpdated: true,
      };

    case ActionTypes.EDITEMEMBER_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      };

    case ActionTypes.EDITPROFILE_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        user: action.payload.user,
        profileEdit: true,
      };

    case ActionTypes.EDITPROFILE_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      };
    case ActionTypes.ACTIVATEMEMBER_ON_SUCCESS:
      return {
        ...state,
        isActivated: true,
        message: action.payload.message,
      };

    case ActionTypes.INVITEMEMBER_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isResend: true,
      };

    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        isCreated: false,
        isUpdated: false,
        isDeleted: false,
        isSucceed: false,
        isActivated: false,
        isFailure: false,
        isResend: false,
        profileEdit: false,
      };

    case ActionTypes.MEMBER_PRIVILEGES:
      return {
        ...state,
        memberPrivilege: true,
      };
    default:
      return state;
  }
};

export default membersReducer;
