import React, { useEffect, useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import { useFormik, Form, FormikProvider } from "formik"
import { LoadingButton } from "@mui/lab"
import * as Yup from "yup"
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Alert,
  Box,
  CircularProgress,
} from "@mui/material"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { useSelector, useDispatch } from "react-redux"

import { updatePassword } from "src/redux/actions/updatePassword"
import { decodeString } from "src/utils/userHelper"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

export default function SetPasswordForm() {
  // State Management
  const logged = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [errorSeverity, setSeverity] = useState("")

  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [flag, setFlag] = useState(null)
  const [error, setError] = useState("")
  const [isSubmitting, setIsSubmitting] = useState(false)

  const newPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(
        8,
        "Password must include 8 characters, letters, digit and special character.",
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must include at least one letter, one digit, and one special character",
      )
      .required(t("ErrPasswordRequired")),
    confirm_password: Yup.string()
      .required(t("ErrPasswordRequired"))
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  })

  const updatedPass = useSelector((state) => state.updatePassword)
  useEffect(() => {
    if (updatedPass.data.success === true) {
      setLoading(false)
      setFlag(true)
      setSeverity("success")
      setError(updatedPass.data.message)
    }
    if (updatedPass?.data.success === false) {
      setLoading(false)
      setFlag(true)
      setSeverity("error")
      setError(
        updatedPass.data.errors === ""
          ? logged.message
          : updatedPass.data?.data?.errors ?? updatedPass.data?.errors,
      )
    }
  }, [updatedPass])

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      remember: true,
    },
    validationSchema: newPasswordSchema,
    onSubmit: (values, { resetForm }) => {
      const userData = localStorage.getItem("setPass")
      setLoading(true)
      setIsSubmitting(true)
      const headersToken = localStorage.getItem("location")
      const invite = localStorage.getItem("invitation")
      const updateHeader = decodeString(headersToken)
      const newFormData = new FormData()

      if (!!userData) {
        newFormData.append("password", values.password)
        newFormData.append("password_confirmation", values.confirm_password)
        newFormData.append(
          "timezone",
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        )
        newFormData.append(
          "reset_password_token",
          updateHeader.reset_password_token,
        )
        dispatch(updatePassword(newFormData))
        resetForm({ newFormData })
      } else {
        newFormData.append("password", values.password)
        newFormData.append("password_confirmation", values.confirm_password)
        newFormData.append(
          "timezone",
          Intl.DateTimeFormat().resolvedOptions().timeZone,
        )
        newFormData.append(
          "reset_password_token",
          updateHeader.reset_password_token,
        )
        if (invite === "?invitation_token") {
          newFormData.append("invitation_token", updateHeader.invitation_token)
        }
        dispatch(updatePassword(newFormData))
        resetForm({ newFormData })
      }
      localStorage.removeItem("setPass")
    },
  })

  const { errors, touched, handleChange, handleSubmit, getFieldProps } = formik

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show)
  }
  const [passwordField, setPasswordField] = useState("")
  const [passwordStrength, setPasswordStrength] = useState("")
  const handlePasswordChange = (e) => {
    handleChange(e)
    const newPassword = e.target.value

    setPasswordField(newPassword)

    if (
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/.test(newPassword)
    ) {
      setPasswordStrength("Strong")
    } else if (
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/.test(newPassword) ||
      /^^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{0,7}$/.test(
        newPassword,
      ) ||
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[@$!%*#?&]).{8,}$/.test(newPassword) ||
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/.test(newPassword) ||
      /^(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/.test(newPassword)
    ) {
      setPasswordStrength("Medium")
    } else {
      setPasswordStrength("Weak")
    }
  }
  const handleChangePaste = (e) => {
    e.preventDefault()
  }

  const segmentStyle = {
    flex: "1",
    width: "40px",
    backgroundColor: "gray",
    margin: "0 2px",
  }
  const getSegmentColor = (segment) => {
    if (passwordStrength === "Weak") {
      return segment === "Weak" ? "red" : "gray"
    } else if (passwordStrength === "Medium") {
      return segment === "Weak" || segment === "Medium" ? "orange" : "gray"
    } else if (passwordStrength === "Strong") {
      return "green"
    } else return "gray"
  }

  const strengthIndicatorStyle = {
    width: "100%",
    height: "8px",
    display: "flex",
  }
  return (
    <FormikProvider value={formik}>
      <Form autoComplete='new-password' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            disableAutoFill
            autoFill='off'
            type={showPassword ? "text" : "password"}
            id='password'
            name='password'
            label='Password'
            autoComplete='chrome-off'
            {...getFieldProps("password")}
            InputProps={{
              "x-autocompletetype": "off",
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleShowPassword} edge='end'>
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onPaste={handleChangePaste}
            onCopy={handleChangePaste}
            onChange={handlePasswordChange}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          {passwordStrength === "" ? null : (
            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{
                width: "100%",
                my: 1,
                display: `${isSubmitting ? "none" : "flex"}`,
              }}
            >
              <Typography variant='body2' sx={{ width: "40%" }}>
                Password Strength
              </Typography>

              <Box>
                <div style={strengthIndicatorStyle}>
                  <div
                    style={{
                      ...segmentStyle,
                      backgroundColor: getSegmentColor("Weak"),
                    }}
                  ></div>
                  <div
                    style={{
                      ...segmentStyle,
                      backgroundColor: getSegmentColor("Medium"),
                    }}
                  ></div>
                  <div
                    style={{
                      ...segmentStyle,
                      backgroundColor: getSegmentColor("Strong"),
                    }}
                  ></div>
                </div>
                <Typography variant='body2' sx={{ textAlign: "right" }}>
                  {passwordStrength}
                </Typography>
              </Box>
            </Stack>
          )}
          <TextField
            fullWidth
            autoComplete='off'
            disableAutoFill
            id='confirm_password'
            type={showConfirmPassword ? "text" : "password"}
            label='Confirm Password'
            {...getFieldProps("confirm_password")}
            onPaste={handleChangePaste}
            onCopy={handleChangePaste}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={handleShowConfirmPassword} edge='end'>
                    {showConfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={touched.confirm_password && Boolean(errors.confirm_password)}
            helperText={touched.confirm_password && errors.confirm_password}
          />
        </Stack>

        {flag && (
          <Alert
            variant='outlined'
            sx={{ marginTop: "2vh" }}
            severity={errorSeverity}
          >
            {error}
          </Alert>
        )}
        <br />
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={4}
        >
          <LoadingButton
            size='medium'
            type='submit'
            variant='contained'
            disabled={loading}
            loading={loading}
            loadingIndicator={<CircularProgress size={15} />}
          >
            Set Password
          </LoadingButton>

          <Typography variant='body2' align='center' sx={{ my: 1 }}>
            Remember Password?&nbsp;
            <Link
              variant='subtitle2'
              sx={{ marginLeft: "3px" }}
              underline='hover'
              component={RouterLink}
              to='/login'
            >
              {t("Login")}
            </Link>
          </Typography>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
