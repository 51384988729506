import React, { useRef, useState, useEffect } from "react"
import * as Yup from "yup"
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt"
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt"
import { useTranslation } from "react-i18next"

import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Stack,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Chip,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import Label from "src/components/Label"
import { useDispatch, useSelector } from "react-redux"
import { completeRole, resetRolesFlag } from "src/redux/actions/roles"
import { getNotHiredCandidates } from "src/redux/actions/candidate"
import ConfirmationModal from "./ConfirmationModal"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"

import { useFormik, Form, FormikProvider } from "formik"

//************************** */

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
    className: "select-cls",
  },
}

/************** */
export default function CompleteRoleModal({
  hide,
  open,
  data,
  isShowingCompleteRole,
  showCandidate,
}) {
  let candidates = useSelector((state) => state.notHiredCandidates)
  const [candidatesList, setCandidatesList] = useState(candidates)

  const { t, i18n } = useTranslation()
  const role = useSelector((state) => state.roles)

  const [isShowingModal, setIsShowing] = useModal()
  const dispatch = useDispatch()
  const [initialState, setInitialState] = useState({
    roleTitle: "",
    is_hired: "",
    candidate_id: null,
    role_id: null,
    role_status: null,
    comment: "",
    candidates: "",
  })

  const [selectedCandidates, setSelectedCandidates] = useState([])
  const [selectedCandidateIds, setSelectedCandidateIds] = useState([])

  const [selectedSuffledCandidates, setSelectedSuffledCandidates] = useState([])
  const [selectedSuffledCandidateIds, setSelectedSuffledCandidateIds] =
    useState([])

  const submission = (values, { resetForm }) => {
    let ids = selectedCandidateIds.concat(selectedSuffledCandidateIds)
    const payload = {
      completion_comment: values.comment,
      is_hired: values.is_hired,
      status: "completed",
    }
    payload.candidate_id = ids

    dispatch(completeRole(data?.key, payload))
    setSelectedCandidates([])
    setSelectedCandidateIds([])
    setSelectedSuffledCandidateIds([])
    setSelectedSuffledCandidates([])
    resetForm({ values: "" })
    hide()
  }

  const followUpSchema = Yup.object().shape({
    is_hired: Yup.string().required(t("SelectAboveOption")),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: followUpSchema,
    onSubmit: submission,
  })
  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  useEffect(() => {
    if (data) {
      // && candidates?.data?.length === 0
      setCandidatesList({})
      dispatch(getNotHiredCandidates(data?.id))
    }
  }, [data]) //candidates,

  useEffect(() => {
    if (candidates) {
      setCandidatesList(candidates)
    }
  }, [candidates])

  /****************** */

  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  // ---------candidate selection---

  const selectionChangeHandler = (event) => {
    const { value } = event.target

    let ids = []

    if (value && value.length > 0) {
      ids = value.map((val) => Number(val.split(":")[0]))
    }
    setSelectedCandidateIds(ids)
    setSelectedCandidates(value)

    // const valuee =
    //   value[
    //     selectedCandidateIds.length > 0 ? selectedCandidateIds.length - 1 : 0
    //   ];

    // let selectedIds = [...selectedCandidateIds];
    // const val = Number(valuee.split(":")[0]);
    // selectedIds.push(val);
    // setSelectedCandidateIds(selectedIds);
    // setSelectedCandidates(value);
    // setFieldValue("candidate_id", valuee.split(":")[1]);
  }

  const selectionSuffledChangeHandler = (event) => {
    const { value } = event.target

    let ids = []

    if (value && value.length > 0) {
      ids = value.map((val) => Number(val.split(":")[0]))
    }
    setSelectedSuffledCandidateIds(ids)
    setSelectedSuffledCandidates(value)
  }

  const handleClose = () => {
    values.is_hired = ""
    setSelectedCandidates([])
    setSelectedCandidateIds([])
    setSelectedSuffledCandidateIds([])
    setSelectedSuffledCandidates([])
    hide()
  }

  const complete = () => {
    if (values?.is_hired === true) {
      setIsShowing()
    } else {
      handleSubmit()
    }
  }

  /******************* */
  return (
    <>
      {isShowingCompleteRole && (
        <Dialog
          className='dialog800'
          open={isShowingCompleteRole}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          scroll='paper'
          maxWidth='xl'
        >
          <DialogTitle
            className='dialog-title'
            sx={{ justifyContent: "space-between", display: "flex" }}
          >
            {showCandidate ? t("SelectCandidates") : t("CloseVacancy")}
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className='dialog-content' dividers={true}>
            <FormikProvider value={formik}>
              <Form
                id='completeRole'
                autoComplete='off'
                noValidate
                onSubmit={complete}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                      }}
                    >
                      <FormLabel>{t("JobTitle")}&nbsp;:&nbsp;</FormLabel>
                      <Typography
                        name='roleTitle'
                        variant='body1'
                        component='span'
                        sx={{ mx: { xs: 0, sm: 1 } }}
                      >
                        {data?.title}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: { xs: "column", sm: "row" },
                        alignItems: { xs: "flex-start", sm: "center" },
                      }}
                    >
                      <FormLabel
                        sx={{
                          mr: 2,
                          color: "rgba(0, 0, 0, 0.6)",
                          "&:focus": {
                            color: "rgba(0, 0, 0, 0.6)",
                          },
                        }}
                        required
                      >
                        {t("WantCloseJob?")}
                      </FormLabel>

                      <Box sx={{ display: "flex", alignContent: "center" }}>
                        <RadioGroup
                          row
                          aria-labelledby='demo-row-radio-buttons-group-label'
                          name='row-radio-buttons-group'
                          id=''
                          sx={{ mx: 1 }}
                        >
                          <FormControlLabel
                            value='hire'
                            control={<Radio />}
                            onChange={() => setFieldValue("is_hired", true)}
                            label={t("Yes")}
                          />
                          <FormControlLabel
                            value='reject'
                            control={<Radio />}
                            onChange={() => setFieldValue("is_hired", false)}
                            label={t("No")}
                          />
                        </RadioGroup>
                      </Box>
                    </FormControl>
                    {touched?.is_hired && errors?.is_hired && (
                      <p className='input-error'>{errors?.is_hired}</p>
                    )}
                  </Grid>
                  {showCandidate && (
                    <>
                      <Grid item xs={12}>
                        <FormControl sx={{ width: "100%" }}>
                          <FormLabel>{t("SelectCandidateToHire")}</FormLabel>
                          <Select
                            multiple
                            MenuProps={MenuProps}
                            value={selectedCandidates}
                            className='select-multi'
                            onChange={selectionChangeHandler}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {selected.map((value) => {
                                  const val = value.split(":")[1]
                                  return (
                                    <Chip
                                      key={val}
                                      label={val}
                                      sx={{ mx: 1 }}
                                    />
                                  )
                                })}
                              </Box>
                            )}
                          >
                            {candidates?.data?.candidates?.length === 0 ? (
                              <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                            ) : (
                              candidates?.data?.candidates
                                ?.filter((candidate) =>
                                  candidate?.candidate_vacancies?.find(
                                    (vacancy) =>
                                      vacancy?.interviews?.every(
                                        (interview) =>
                                          interview?.status == "completed",
                                      ),
                                  ),
                                )
                                ?.map((option) => (
                                  <MenuItem
                                    key={option?.id}
                                    value={
                                      option?.id +
                                      ":" +
                                      option?.first_name +
                                      " " +
                                      option?.last_name +
                                      ", " +
                                      option?.email
                                    }
                                    sx={{
                                      justifyContent:
                                        "space-between !important",
                                    }}
                                  >
                                    <Box className='name-info'>
                                      <Typography
                                        variant='subtitle1'
                                        component='h6'
                                        sx={{
                                          ml: 1,
                                          fontWeight: 600,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {option?.first_name +
                                          " " +
                                          option?.last_name}
                                      </Typography>
                                      <Typography
                                        variant='subtitle2'
                                        component='span'
                                        sx={{ ml: 1, fontWeight: 400 }}
                                      >
                                        {option?.email}
                                      </Typography>
                                    </Box>
                                    <Stack
                                      direction='row'
                                      sx={{ mx: "4px" }}
                                      alignContent='center'
                                    >
                                      <Label
                                        variant='ghost'
                                        color='success'
                                        sx={{ mx: "4px" }}
                                      >
                                        <ThumbUpOffAltIcon
                                          sx={{
                                            color: "#64748b",
                                            fontSize: "16px",
                                            mx: 1,
                                          }}
                                        />
                                        {option?.candidate_vacancies[0]?.interviews.slice(
                                          -1,
                                        )[0]?.votes_count[0]?.recomend_votes ??
                                          0}
                                      </Label>

                                      <Label
                                        variant='ghost'
                                        color='error'
                                        sx={{ mx: "4px" }}
                                      >
                                        {option?.candidate_vacancies[0]?.interviews.slice(
                                          -1,
                                        )[0]?.votes_count[0]?.denied_votes ?? 0}

                                        <ThumbDownOffAltIcon
                                          sx={{
                                            color: "#64748b",
                                            fontSize: "16px",
                                            mx: 1,
                                          }}
                                        />
                                      </Label>
                                    </Stack>
                                  </MenuItem>
                                ))
                            )}
                          </Select>
                        </FormControl>
                      </Grid>
                      {candidates?.data?.shuffled_candidates?.length !== 0 && (
                        <Grid item xs={12}>
                          <FormControl sx={{ width: "100%" }}>
                            <FormLabel>
                              {t("SelectSuffledCandidateToHire")}
                            </FormLabel>
                            <Select
                              multiple
                              MenuProps={MenuProps}
                              value={selectedSuffledCandidates}
                              className='select-multi'
                              onChange={selectionSuffledChangeHandler}
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                  }}
                                >
                                  {selected.map((value) => {
                                    const val = value.split(":")[1]
                                    return (
                                      <Chip
                                        key={val}
                                        label={val}
                                        sx={{ mx: 1 }}
                                      />
                                    )
                                  })}
                                </Box>
                              )}
                            >
                              {candidates?.data?.shuffled_candidates?.length ===
                              0 ? (
                                <MenuItem disabled>
                                  {t("DataNotFound")}
                                </MenuItem>
                              ) : (
                                candidates?.data?.shuffled_candidates
                                  ?.filter((candidate) =>
                                    candidate?.candidate_vacancies?.find(
                                      (vacancy) =>
                                        vacancy?.interviews?.every(
                                          (interview) =>
                                            interview?.status == "completed",
                                        ),
                                    ),
                                  )
                                  ?.map((option) => (
                                    <MenuItem
                                      key={option?.id}
                                      value={
                                        option?.id +
                                        ":" +
                                        option?.first_name +
                                        " " +
                                        option?.last_name +
                                        ", " +
                                        option?.email
                                      }
                                      sx={{
                                        flexDirection: {
                                          sm: "column",
                                          md: "row",
                                        },
                                        justifyContent:
                                          "space-between !important",
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          variant='subtitle1'
                                          component='h6'
                                          sx={{
                                            ml: 1,
                                            fontWeight: 600,
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {option?.first_name +
                                            " " +
                                            option?.last_name}
                                        </Typography>
                                        <Typography
                                          variant='subtitle2'
                                          component='span'
                                          sx={{ ml: 1, fontWeight: 400 }}
                                        >
                                          {option?.email}
                                        </Typography>
                                      </Box>

                                      <Stack
                                        direction='row'
                                        sx={{ mx: "4px" }}
                                        alignContent='center'
                                      >
                                        <Label
                                          variant='ghost'
                                          color='success'
                                          sx={{ mx: "4px" }}
                                        >
                                          <ThumbUpOffAltIcon
                                            sx={{
                                              color: "#64748b",
                                              fontSize: "16px",
                                              mx: 1,
                                            }}
                                          />
                                          {option?.candidate_vacancies[0]?.interviews.slice(
                                            -1,
                                          )[0]?.votes_count[0]
                                            ?.recomend_votes ?? 0}
                                        </Label>

                                        <Label
                                          variant='ghost'
                                          color='error'
                                          sx={{ mx: "4px" }}
                                        >
                                          {option?.candidate_vacancies[0]?.interviews.slice(
                                            -1,
                                          )[0]?.votes_count[0]?.denied_votes ??
                                            0}

                                          <ThumbDownOffAltIcon
                                            sx={{
                                              color: "#64748b",
                                              fontSize: "16px",
                                              mx: 1,
                                            }}
                                          />
                                        </Label>
                                      </Stack>
                                    </MenuItem>
                                  ))
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      )}
                    </>
                  )}

                  <Grid item xs={12}>
                    {candidatesList?.data?.hired_candidates?.length === 0 ? (
                      <Label
                        variant='ghost'
                        sx={{
                          marginRight: "8px",
                          mb: 1,
                        }}
                      >
                        <Typography
                          component='span'
                          sx={{
                            fontWeight: 700,
                            fontSize: "0.75rem",
                          }}
                        >
                          {t("NoCandidateHired")}
                        </Typography>
                      </Label>
                    ) : (
                      <>
                        <Typography variant='' component='label'>
                          {t("HiredCandidates")}
                        </Typography>

                        {candidatesList?.data?.hired_candidates?.map(
                          (hiredCand) => {
                            return (
                              <Label
                                variant='ghost'
                                color='info'
                                sx={{
                                  py: "4px",
                                  marginRight: "8px",
                                  mb: 1,
                                  whiteSpace: "normal",
                                  wordBreak: "break-word",
                                  height: "auto",
                                  lineHeight: "1.2rem",
                                }}
                              >
                                {hiredCand?.candidate?.first_name +
                                  " " +
                                  hiredCand?.candidate?.last_name +
                                  " -"}{" "}
                                {hiredCand?.candidate?.email}
                              </Label>
                            )
                          },
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </DialogContent>

          <DialogActions className='dialog-footer'>
            <Button
              onClick={handleClose}
              variant='outlined'
              color='primary'
              size='medium'
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                complete()
                // window.location.reload();
              }}
              variant='contained'
              color='secondary'
              size='medium'
              disabled={
                (values.is_hired === false || values.is_hired === "") &&
                showCandidate === true &&
                selectedCandidates.length === 0 &&
                selectedSuffledCandidates.length === 0
              }
            >
              {t("Save")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <ConfirmationModal
        isShowingModal={isShowingModal}
        hide={setIsShowing}
        confirmAction={handleSubmit}
        message={`Are you sure you want to close this vacancy${
          data?.pending_interviews > 0
            ? `? This vacancy has ${data?.pending_interviews} pending interview(s).`
            : "."
        }`}
      />
    </>
  )
}
