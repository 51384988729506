import { Button, Typography, Link } from "@mui/material"
import CookieConsent from "react-cookie-consent"
import CookieIcon from "@mui/icons-material/Cookie"
import { useEffect, useState } from "react"

import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import ViewCookies from "src/sections/@landingPage/ViewCookieModal"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"

const CookieConsentBanner = ({ isLandingPage = false }) => {
  const [isCookie, setIsCookie] = useState(false)
  const [isShowingCookie, toggleCookie] = useModal()
  const { t } = useTranslation()
  const setValues = (response) => {
    setIsCookie(true)
    localStorage.setItem("cookies", response)
  }

  useEffect(() => {
    setIsCookie(!isEmpty(localStorage.getItem("cookies")))
  }, [])

  return (
    <>
      {!isCookie && (
        <CookieConsent
          debug
          location='bottom'
          buttonText={t("Accept And Continue")}
          cookieValue={true}
          cookieName='cookieBanner'
          declineButtonText={t("Reject All")}
          enableDeclineButton={true}
          declineButtonClasses='btn btn-warning'
          declineCookieValue={false}
          buttonWrapperClasses='btn-wrapper'
          disableButtonStyles={true}
          buttonClasses='btn btn-primary'
          containerClasses='sticky-box col-12'
          contentClasses='text-capitalize'
          expires={150}
          hideOnAccept={true}
          hideOnDecline={true}
          onAccept={(acceptedByScrolling) => {
            if (acceptedByScrolling === false) {
              setValues("accepted")
            }
          }}
          onDecline={() => setValues("declined")}
        >
          <Typography variant='h4' component='h4'>
            {t("We value your privacy")}
          </Typography>
          <Typography variant='body1'>
            {t("we use cookies and similar technologies")} {t("Our")}{" "}
            <Link underline='none' href='/cookies-policy/app' rel='noreferrer'>
              {t("Cookie Policy")}
            </Link>{" "}
          </Typography>
        </CookieConsent>
      )}

      {isCookie && isLandingPage && (
        <Button variant='contained' className='btn-fix' onClick={toggleCookie}>
          <CookieIcon />
        </Button>
      )}
      <ViewCookies open={isShowingCookie} hide={toggleCookie} />
    </>
  )
}

export default CookieConsentBanner
