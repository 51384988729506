import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"

import {
  Box,
  Stack,
  Button,
  Typography,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Avatar,
  Rating,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import ParagraphWithReadMore from "src/components/ParagraphWithReadMore"
import { useFormik, Form, FormikProvider } from "formik"
import { useTranslation } from "react-i18next"
import { deepOrange } from "@mui/material/colors"
import Label from "src/components/Label"
import StarIcon from "@mui/icons-material/Star"
import { useNavigate } from "react-router-dom"
import { convertTimeZone } from "src/utils/formatTime"
import { DBTimeZone } from "src/utils/constants"

//************************** */

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

/************** */
export default function IntReviewModal({
  data,
  isShowingIntReview,
  type,
  hide,
  open,
  hasViewPermission = false,
  canGiveFeedback = false,
}) {
  const maxLength = 150

  const navigate = useNavigate()
  const { t } = useTranslation()

  const descriptionElementRef = useRef(null)
  const [value, setValue] = useState(0)

  const submission = (values, { resetForm }) => {
    resetForm({ values: "" })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: submission,
  })

  const { handleSubmit } = formik

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
      setValue(type === "info" ? 0 : 1)
    }
  }, [open])

  /******************* */

  const handleReview = () => {
    const userId = localStorage.getItem("match")
    const newId = JSON.parse(userId)
    const intId = newId?.key
    window.history.pushState(null, "", "/dashboard/interviews")

    navigate(`/interview-feedback/app/${intId}`, { replace: true })
  }

  return (
    isShowingIntReview && (
      <Dialog
        open={isShowingIntReview}
        className='dialog800'
        onClose={hide}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle sx={{ justifyContent: "space-between", display: "flex" }}>
          {type === "info" ? "Candidate Info" : "Remarks"}
          <IconButton
            aria-label='close'
            onClick={hide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <FormikProvider value={formik}>
            <Form
              id='addRole'
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
            >
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      background: "#F8F8F8",
                      padding: "11px",
                      borderRadius: "18px",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 80,
                        height: 80,
                        bgcolor: "#1890FF",
                        mx: 3,
                        fontSize: "1.7rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {data?.candidate_vacancy?.candidate?.first_name?.slice(
                        0,
                        1,
                      ) +
                        data?.candidate_vacancy?.candidate?.last_name?.slice(
                          0,
                          1,
                        )}{" "}
                    </Avatar>
                    <Box sx={{ flexDirection: "column", display: "flex" }}>
                      <Typography
                        variant='h3'
                        component='h3'
                        sx={{ textTransform: "capitalize" }}
                      >
                        {data?.candidate_vacancy?.candidate?.first_name +
                          " " +
                          data?.candidate_vacancy?.candidate?.last_name}
                      </Typography>
                      <Typography
                        variant='h5'
                        component='h5'
                        sx={{ color: "#969a9d" }}
                      >
                        {data?.vacancy?.title}
                      </Typography>

                      <Stack
                        sx={{ flexDirection: { xs: "column", sm: "row" } }}
                      >
                        <Typography variant='subtitle2'>
                          {t("ScheduledDate")}:
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          sx={{ fontWeight: 400, mx: { xs: 0, sm: 1 } }}
                        >
                          {data?.scheduled_on}
                        </Typography>
                      </Stack>

                      <Stack
                        sx={{ flexDirection: { xs: "column", sm: "row" } }}
                      >
                        <Typography variant='subtitle2'>
                          {t("CompletedOn")}:
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          sx={{ fontWeight: 400, mx: { xs: 0, sm: 1 } }}
                        >
                          {convertTimeZone(data?.completed_on, DBTimeZone, data.timezone)}
                        </Typography>
                      </Stack>

                      {data?.candidate_vacancy?.candidate?.resume?.url !==
                        null && (
                        <Button
                          href={data?.candidate_vacancy?.candidate?.resume?.url}
                          variant='contained'
                          color='info'
                          size='small'
                          target='_blank'
                          sx={{ mt: 1, mb: 1, maxWidth: "150px" }}
                        >
                          {t("ViewResume")}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ width: "100%" }}>
                    {type === "info" ? (
                      <TabPanel>
                        <Stack>
                          <FormControl
                            sx={{
                              flexDirection: { xs: "column", sm: "row" },
                              alignItems: { xs: "flex-start", sm: "center" },
                              width: "100%",
                              mb: 1,
                            }}
                          >
                            <FormLabel
                              sx={{
                                mb: "0 !important",
                                mr: 1,
                                width: "150px",
                                minWidth: "150px",
                              }}
                            >
                              {t("Email")}:
                            </FormLabel>
                            <Typography
                              sm={9}
                              variant='subtitle2'
                              component='span'
                              sx={{
                                whiteSpace: "normal",
                                wordBreak: "break-all",
                              }}
                            >
                              {data?.candidate_vacancy?.candidate?.email}
                            </Typography>
                          </FormControl>

                          {data?.candidate_vacancy?.candidate?.phone && (
                            <FormControl
                              sx={{
                                flexDirection: { xs: "column", sm: "row" },
                                alignItems: { xs: "flex-start", sm: "center" },
                                width: "100%",
                                mb: 1,
                              }}
                            >
                              <FormLabel
                                sx={{
                                  mb: "0 !important",
                                  mr: 1,
                                  width: "150px",
                                  minWidth: "150px",
                                }}
                              >
                                {t("Phone")}:
                              </FormLabel>
                              <Typography
                                sm={9}
                                variant='subtitle2'
                                component='span'
                              >
                                {data?.candidate_vacancy?.candidate?.phone}
                              </Typography>
                            </FormControl>
                          )}
                          {data?.candidate_vacancy?.vacancy
                            ?.years_experience && (
                            <FormControl
                              sx={{
                                flexDirection: { xs: "column", sm: "row" },
                                alignItems: { xs: "flex-start", sm: "center" },
                                width: "100%",
                                mb: 1,
                              }}
                            >
                              <FormLabel
                                sx={{
                                  mb: "0 !important",
                                  mr: 1,
                                  width: "150px",
                                  minWidth: "150px",
                                }}
                              >
                                {t("Experience")}:
                              </FormLabel>
                              <Typography variant='subtitle2' component='span'>
                                {data?.candidate_vacancy?.candidate?.experience}{" "}
                                {data.candidate_vacancy.vacancy
                                  .years_experience > 1
                                  ? t("years")
                                  : t("year")}
                              </Typography>
                            </FormControl>
                          )}

                          {data?.candidate_vacancy?.candidate
                            ?.qualification && (
                            <FormControl
                              sx={{
                                flexDirection: { xs: "column", sm: "row" },
                                alignItems: { xs: "flex-start", sm: "center" },
                                width: "100%",
                                mb: 1,
                              }}
                            >
                              <FormLabel
                                sx={{
                                  mb: "0 !important",
                                  mr: 1,
                                  width: "150px",
                                  minWidth: "150px",
                                }}
                              >
                                {t("Qualification")}:
                              </FormLabel>
                              <Typography variant='subtitle2' component='span'>
                                {
                                  data?.candidate_vacancy?.candidate
                                    ?.qualification
                                }
                              </Typography>
                            </FormControl>
                          )}

                          {data?.candidate_vacancy?.candidate?.skills !==
                          null ? (
                            <FormControl
                              sx={{
                                flexDirection: { xs: "column", sm: "row" },
                                alignItems: { xs: "flex-start", sm: "center" },
                                width: "100%",
                                mb: 1,
                              }}
                            >
                              <FormLabel
                                sx={{
                                  mb: "0 !important",
                                  mr: 1,
                                  width: "150px",
                                  minWidth: "150px",
                                }}
                              >
                                {t("Skills")}:
                              </FormLabel>
                              <Typography variant='subtitle2' component='span'>
                                {data?.candidate_vacancy?.candidate?.skills?.map(
                                  (experties) => (
                                    <Label
                                      key={experties}
                                      variant='ghost'
                                      color='info'
                                      sx={{ mr: 1, mb: 1 }}
                                    >
                                      {experties}
                                    </Label>
                                  ),
                                )}
                              </Typography>
                            </FormControl>
                          ) : null}

                          <FormControl
                            sx={{
                              flexDirection: { xs: "column", sm: "row" },
                              alignItems: { xs: "flex-start", sm: "center" },
                              width: "100%",
                              mb: 1,
                            }}
                          >
                            <FormLabel
                              sx={{
                                mb: "0 !important",
                                mr: 1,
                                width: "150px",
                                minWidth: "150px",
                              }}
                            >
                              {t("JobManager")}:
                            </FormLabel>
                            <Typography
                              variant='subtitle2'
                              component='span'
                              sx={{ textTransform: "capitalize" }}
                            >
                              {data?.user?.first_name +
                                " " +
                                data?.user?.last_name}
                            </Typography>
                          </FormControl>

                          {data?.candidate_vacancy?.vacancy?.description && (
                            <FormControl
                              sx={{
                                width: "100%",
                                flexDirection: { xs: "column", sm: "row" },
                                alignItems: { xs: "flex-start", sm: "center" },
                                mb: 1,
                              }}
                            >
                              <FormLabel
                                sx={{
                                  mb: "0 !important",
                                  mr: 1,
                                  width: "150px",
                                  minWidth: "150px",
                                }}
                              >
                                {t("JobDescription")}:
                              </FormLabel>
                              <Typography
                                variant='subtitle2'
                                component='span'
                                sx={{ wordBreak: "break-word" }}
                              >
                                <ParagraphWithReadMore
                                  text={
                                    data?.candidate_vacancy?.vacancy
                                      ?.description
                                  }
                                  maxLength={maxLength}
                                />
                              </Typography>
                            </FormControl>
                          )}
                        </Stack>
                      </TabPanel>
                    ) : (
                      <TabPanel>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Label variant='ghost' color='info'>
                              {data?.recommendations?.length} {t("Feedback")}
                            </Label>
                          </Grid>

                          {data?.recommendations?.map((item) => {
                            return (
                              <>
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    justifyContent: "space-between",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      sx={{
                                        width: 40,
                                        height: 40,
                                        bgcolor: deepOrange[500],
                                        mr: 2,
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item?.user?.first_name?.slice(0, 1) +
                                        item?.user?.last_name?.slice(0, 1)}
                                    </Avatar>
                                    <Typography
                                      variant='subtitle2'
                                      component='span'
                                      sx={{
                                        mr: 2,
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item?.user?.first_name +
                                        " " +
                                        item?.user?.last_name}
                                    </Typography>

                                    {item?.is_recommended ? (
                                      <Label
                                        variant='ghost'
                                        color='success'
                                        sx={{ mx: 1 }}
                                      >
                                        {t("Recommended")}
                                      </Label>
                                    ) : (
                                      <Label
                                        variant='ghost'
                                        color='error'
                                        sx={{ mx: 1 }}
                                      >
                                        {t("NotRecommended")}
                                      </Label>
                                    )}
                                  </Box>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    id='commentID'
                                    className='bloque'
                                    variant='body2'
                                  >
                                    {item?.text ? item?.text : "No comment"}
                                  </Typography>

                                  <Stack direction='row' sx={{ mt: 2 }}>
                                    <Box
                                      sx={{
                                        width: 200,
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Rating
                                        name='text-feedback'
                                        value={item?.rating}
                                        readOnly
                                        precision={0.5}
                                        emptyIcon={
                                          <StarIcon
                                            style={{ opacity: 0.55 }}
                                            fontSize='inherit'
                                          />
                                        }
                                      />
                                    </Box>
                                  </Stack>
                                </Grid>
                              </>
                            )
                          })}
                        </Grid>
                      </TabPanel>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions className='dialog-footer'>
          <Button
            onClick={hide}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Close")}
          </Button>

          {canGiveFeedback ? (
            <Button
              autoFocus
              variant='contained'
              size='medium'
              onClick={handleReview}
            >
              {t("GiveFeedback")}
            </Button>
          ) : (
            hasViewPermission && (
              <Button
                autoFocus
                variant='contained'
                size='medium'
                onClick={handleReview}
              >
                {t("ViewFeedback")}
              </Button>
            )
          )}
        </DialogActions>
      </Dialog>
    )
  )
}
