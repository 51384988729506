import { LiveHeader } from "../.."
import VideoSlider from "./videoSlider"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { styled } from "@mui/material/styles"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { useVideoUpload } from "src/hooks/useVideoUpload"
import React, { useState, useEffect, useRef } from "react"
import {
  Box,
  Container,
  Typography,
  Grid,
  Alert,
  Button,
  Stack,
  SvgIcon,
} from "@mui/material"
import useResponsive from "src/hooks/useResponsive"
import { useNetwork } from "src/hooks/useNetwork"
import TaskAltIcon from "@mui/icons-material/TaskAlt"
import BubbleLoader from "src/assets/img/BubbleLoader"
import { currentBrowserName } from "src/utils/userHelper"

const RootStyle = styled("div")(({ theme }) => ({
  border: " 1px solid #e2e8f0",
  background: "white",
  boxSizing: "border-box",
  borderRadius: " 10px",
  width: "100%",
  marginLeft: "1.34rem",
  marginRight: "1.34rem",
}))

const Panel = styled("div")(({ theme }) => ({
  padding: "1.8rem 2.8rem 1rem 2.8rem ",
}))

const MainVideo = styled("div")(({ theme }) => ({
  marginLeft: "3rem",
}))

const LineTrough = styled("hr")(({ theme }) => ({
  borderTop: "1px solid #CBD5E1 !important",
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0,
  marginTop: "24px",
  marginBottom: "30px",
}))

const UploadStatusRow = ({ children }) => {
  return (
    <Typography
      variant='subtitle2'
      alignItems='center'
      component='p'
      sx={{
        display: "flex",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        marginBottom: { xs: "10px", md: "30px" },
      }}
    >
      {children}
    </Typography>
  )
}

export default function InterviewRecordingsReview() {
  const isMobile = useResponsive("down", "sm")
  const location = useLocation()
  const { videos } = useVideoUpload()
  const isOnline = useNetwork()
  const navigate = useNavigate()

  const [videosList, setVideosList] = useState(videos)

  const [allVideosFulfilled, setAllVideosFulfilled] = useState(false)
  const [uploadedVideos, setUploadedVideos] = useState([])
  const [failedVideos, setFailedVideos] = useState([])

  const [playing, setPlaying] = useState(false)
  const [retrying, setRetrying] = useState(false)

  const defaultInterview = {
    candidate_vacancy: { candidate: { first_name: "", last_name: "" } },
    interview_questions: [],
  }
  const interview = location?.state?.interview ?? defaultInterview
  const recordingFilesSources = location?.state?.recordingFilesSources ?? []
  const uploadingVideos = location?.state?.uploadingVideos ?? []

  // const {
  //   interview,
  //   recordingFilesSources = [],
  //   uploadingVideos = [],
  // } = location?.state;

  const {
    candidate_vacancy: {
      candidate: { first_name: firstName, last_name: lastName },
    },
    interview_questions: questions,
    code: interviewCode,
  } = interview
  const candidateName = `${firstName} ${lastName}`

  const [currentVideoPlay, setCurrentVideoPlay] = useState(
    recordingFilesSources && recordingFilesSources.length > 0
      ? recordingFilesSources[0]
      : null,
  )

  const videoPlayerRef = useRef()

  let timer = null

  useEffect(() => {
    if (timer) {
      clearInterval(timer)
    }

    timer = setInterval(() => {
      let recordings = JSON.parse(sessionStorage.getItem("recordings"))

      recordings = recordings.map((recording, index) => {
        return { ...recording, file: uploadingVideos[index].file }
      })
      setVideosList(recordings)

      const isThereAnyPendingUpload =
        recordings.filter((recording) => recording.fulfilled === false)
          ?.length > 0

      const uploadedVideos = recordings.filter(
        (recording) => recording.uploaded === true,
      )
      const failedVideos = recordings.filter(
        (recording) => recording.failed === true,
      )
      setUploadedVideos(uploadedVideos)
      setFailedVideos(failedVideos)

      setAllVideosFulfilled(!isThereAnyPendingUpload)

      if (!isThereAnyPendingUpload) {
        setRetrying(false)
        clearInterval(timer)
      }
    }, 2000)

    return () => {
      clearInterval(timer)
    }
  }, [retrying])

  useEffect(() => {
    if (location?.state?.interview) {
      window.history.replaceState({}, document.title)
    } else {
      sessionStorage.removeItem("recordings")
      navigate("/Home/app", {
        replace: true,
      })
    }
  }, [])

  useEffect(() => {
    const onBeforeUnload = (event) => {
      if (!allVideosFulfilled) {
        event.preventDefault()
        event.returnValue = ""
      }
    }
    window.onbeforeunload = allVideosFulfilled ? null : onBeforeUnload
    return () => {
      window.onbeforeunload = null
    }
  }, [allVideosFulfilled])

  const videoPlayButtonClicked = (fileSrc) => {
    videoPlayerRef.current.currentTime = 0
    setCurrentVideoPlay(fileSrc)
    const sameVideoClicked = fileSrc === currentVideoPlay
    sameVideoClicked ? setPlaying(!playing) : setPlaying(true)

    setTimeout(() => {
      sameVideoClicked && playing
        ? videoPlayerRef.current.pause()
        : videoPlayerRef.current.play()
    }, 200)
  }

  const handleClose = () => {
    window.open("https://www.google.com", "_self")
  }
  return (
    <Container
      className='center-screen'
      maxWidth='lg'
      spacing={3}
      sx={{
        maxWidth: "1116px",
        minHeight: " 100vh",
      }}
    >
      <RootStyle>
        <Panel>
          <Grid container spacing={4}>
            <Grid
              item
              sx={{ pt: 6, pb: { xs: 1, md: 5 }, mt: 2 }}
              md={5}
              xs={12}
            >
              <Box>
                <LiveHeader />
                <Typography
                  variant='subtitle2'
                  component='p'
                  sx={{
                    marginBottom: "1.2rem",
                    color: "#475569",
                    lineHeight: "24px",
                    fontSize: "16px !important",
                    fontWeight: 400,
                    whiteSpace: "break-spaces",
                    wordBreak: "break-word",
                  }}
                >
                  {failedVideos.length > 0 && !retrying
                    ? `${failedVideos.length} ${
                        failedVideos.length > 1
                          ? "recordings have"
                          : "recording has"
                      } failed to upload. Please check your internet connectivity and press retry button.`
                    : allVideosFulfilled &&
                      !retrying &&
                      failedVideos.length === 0
                    ? "Thank you for your time. Our team will review the interview and get back to you as soon as possible."
                    : "Please wait, the recordings of your interview are being uploaded."}
                </Typography>

                {allVideosFulfilled && failedVideos.length === 0 && (
                  <>
                    <Typography
                      variant='h6'
                      sx={{
                        mb: 1,
                        color: "#1E293B",
                        lineHeight: "24px",
                        fontSize: "16px !important",
                        fontWeight: 600,
                      }}
                    >
                      Good Luck!
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      component='p'
                      sx={{
                        mb: 1,
                        color: "#475569",
                        lineHeight: "24px",
                        fontSize: "16px !important",
                        fontWeight: 400,
                      }}
                    >
                      Recruitment Team
                    </Typography>
                  </>
                )}

                <LineTrough />

                {(!allVideosFulfilled || retrying) && (
                  <UploadStatusRow>
                    <SvgIcon sx={{ color: "#059669", fontSize: 35, mr: 2 }}>
                      {" "}
                      <BubbleLoader sx={{ borderColor: "#059669" }} />{" "}
                    </SvgIcon>
                    Uploading...
                  </UploadStatusRow>
                )}

                <UploadStatusRow>
                  <TaskAltIcon sx={{ color: "#059669", fontSize: 19, mr: 1 }} />
                  <strong>{`${questions.length}`}</strong>
                  &nbsp;Total Questions
                </UploadStatusRow>

                <UploadStatusRow>
                  <TaskAltIcon sx={{ color: "#059669", fontSize: 19, mr: 1 }} />
                  <strong>
                    {`${uploadedVideos.length} ${
                      uploadedVideos.length > 1 ? "Recordings" : "Recording"
                    } `}
                  </strong>
                  &nbsp;Uploaded
                </UploadStatusRow>

                <UploadStatusRow>
                  <ErrorOutlineIcon
                    sx={{ color: "#059669", fontSize: 19, mr: 1 }}
                  />
                  <strong>
                    {`${failedVideos.length} ${
                      failedVideos.length > 1 ? "Recordings" : "Recording"
                    } `}
                  </strong>
                  &nbsp;Failed
                </UploadStatusRow>

                {(!allVideosFulfilled || retrying) && (
                  <Alert
                    variant='filled'
                    severity='warning'
                    sx={{
                      fontSize: 14,
                      lineHeight: "20px",
                      fontWeight: 400,
                    }}
                  >
                    Please wait whilst your recording is being uploaded. Do not
                    refresh or close this page until the upload has completed.
                  </Alert>
                )}

                {failedVideos.length > 0 && !retrying && (
                  <Alert
                    severity='error'
                    color='error'
                    sx={{
                      border: "1px solid #BFDBFE",
                      fontSize: 14,
                      lineHeight: "20px",
                      fontWeight: 400,
                    }}
                  >
                    {failedVideos.length +
                      ` ${
                        failedVideos.length > 1
                          ? "recordings have"
                          : "recording has"
                      } failed to upload. Please check your internet connectivity and click Retry. If the issue persists, please contact our support team for assistance.`}
                  </Alert>
                )}
              </Box>
            </Grid>

            <Grid
              item
              sx={{ pt: { xs: 1, md: 6 }, pb: 5, mt: { xs: 0, md: 2 } }}
              md={7}
              xs={12}
            >
              <MainVideo sx={{ marginLeft: { xs: 0, sm: 0, md: "3rem" } }}>
                {currentVideoPlay ? (
                  <video
                    key={currentVideoPlay}
                    className='videoPlayer'
                    width='100%'
                    height='258'
                    autoPlay={true}
                    loop
                    muted={true}
                    controls
                    poster='/static/video-placeholder.png'
                    ref={videoPlayerRef}
                    play={playing === true}
                    pause={playing === false}
                    onPlay={() => setPlaying(true)}
                    onPause={() => setPlaying(false)}
                    playsInline
                  >
                    <source src={currentVideoPlay} />
                  </video>
                ) : (
                  <Typography
                    variant='subtitle2'
                    component='p'
                    sx={{
                      mb: 1,
                      color: "#475569",
                      lineHeight: "24px",
                      fontSize: "16px !important",
                      fontWeight: 400,
                    }}
                  >
                    No video has been selected! Please select from the below
                    section.
                  </Typography>
                )}
              </MainVideo>
              <Box
                sx={{
                  marginLeft: { xs: 0, sm: 0, md: "3rem" },
                }}
              >
                {!isOnline && (
                  <Typography
                    component='h6'
                    variant='h6'
                    fontSize='14px'
                    fontWeight='500'
                    sx={{
                      lineHeight: "1em",
                      color: "red",
                      width: "100%",
                      textAlign: "center",
                      marginTop: "1em",
                    }}
                  >
                    No Internet Connection!
                  </Typography>
                )}
                <VideoSlider
                  videos={videosList}
                  questions={questions}
                  videoFilesSources={recordingFilesSources}
                  onVideoPlay={videoPlayButtonClicked}
                  playingVideoSrc={currentVideoPlay}
                  playing={playing}
                  interviewCode={interviewCode}
                  status={location?.state?.status}
                  onRetry={() => setRetrying(!retrying)}
                />
                <Stack
                  justifyContent='flex-end'
                  direction='row'
                  sx={{ mt: 2, mb: 1 }}
                >
                  {allVideosFulfilled && !retrying && (
                    <Alert severity='info' sx={{ width: "100%" }}>
                      You can close the window now
                    </Alert>
                  )}

                  {/* <Button
                    variant='contained'
                    onClick={handleClose}
                    sx={{ maxWidth: "160px" }}
                    disabled={!allVideosFulfilled || retrying}
                  >
                    Finish
                  </Button> */}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Panel>
      </RootStyle>
    </Container>
  )
}
