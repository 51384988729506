import React, { useState, useEffect } from "react"
import Images from "src/assets/img/Images"
import CloseIcon from "@mui/icons-material/Close"
import LoadingButton from "@mui/lab/LoadingButton"
import { useDispatch, useSelector } from "react-redux"
import { cancelSubscription } from "src/redux/actions/subscription"
import {
  Box,
  Button,
  Typography,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"
import { useTranslation, withTranslation, Trans } from "react-i18next"

export default function UnsubscribePlanModal({
  open,
  planId,
  onUnsubscribed,
  onClose,
}) {
  const dispatch = useDispatch()
  const [message, setMessage] = useState("")
  const [isUnsubscribing, setIsUnsubscribing] = useState(false)

  const { t } = useTranslation()
  const cancelSubscriptionStatus = useSelector(
    (state) => state.cancelSubscription,
  )

  useEffect(() => {
    if (isUnsubscribing) {
      setIsUnsubscribing(false)
      const failedMessage = "Failed to unsubscribe. Please try again."

      if (cancelSubscriptionStatus?.cancel_subscription_status?.success) {
        if (onUnsubscribed) {
          onUnsubscribed(
            cancelSubscriptionStatus?.cancel_subscription_status?.message,
          )
        }
      } else {
        setMessage(failedMessage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelSubscriptionStatus])

  const confirmButtonClicked = () => {
    if (planId) {
      setMessage("")
      setIsUnsubscribing(true)
      dispatch(cancelSubscription({ plan_id: planId }))
    }
  }

  const handleClose = () => {
    setMessage("")
    setIsUnsubscribing(false)
    onClose()
  }

  return (
    <Dialog
      className='dialog400'
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      scroll='paper'
      maxWidth='md'
    >
      <DialogTitle className='dialog-title' sx={{ height: "2px", padding: 0 }}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
          disabled={isUnsubscribing}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className='dialog-content'
        dividers={true}
        sx={{ border: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "center", mb: 1, mt: 2 }}
              className='image-banner'
            >
              <img src={Images.UnsubImg} alt='Unsubscribe' />
            </Box>
            <Typography
              variant='h4'
              component='h4'
              sx={{ textAlign: "center", fontWeight: 600, px: 2 }}
            >
              {t("Oh!YouWanttoUnsubscribe")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant='subtitle2'
              component='p'
              sx={{ textAlign: "center", px: 4, fontWeight: 500 }}
            >
              {t("msgunsubscribe")}
            </Typography>
          </Grid>
          {message && (
            <Grid item xs={12}>
              <Alert
                severity='error'
                icon={false}
                sx={{ justifyContent: "center", textAlign: "center", px: 5 }}
              >
                {message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        className='dialog-footer'
        sx={{ justifyContent: "center", pb: 2 }}
      >
        <Button
          onClick={handleClose}
          variant='outlined'
          color='secondary'
          sx={{
            mr: 1,
            backgroundColor: "transparent",
            borderColor: "#8b8e92",
            color: "#64748b",
            "&:hover": {
              opacity: "0.7",
              backgroundColor: "#8b8e92",
              borderColor: "#8b8e92",
              color: "#fff",
            },
          }}
          size='medium'
          disabled={isUnsubscribing}
        >
          {t("Cancel")}
        </Button>

        <LoadingButton
          onClick={confirmButtonClicked}
          loading={isUnsubscribing}
          variant='contained'
          color='info'
          size='medium'
        >
          {t("Unsubscribe")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
