import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/member";

export const getAllMembers = () => {
  const endpoint = "/api/admin/members";
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.types = [
    ActionTypes.FETCHMEMBERS_ON_SUCCESS,
    ActionTypes.FETCHMEMBERS_ON_FAILURE,
  ];
  return request(options);
};

export const createMembers = (data) => {
  const endpoint = "/api/admin/members";
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.CREATEMEMBER_ON_SUCCESS,
    ActionTypes.CREATEMEMBER_ON_FAILURE,
  ];
  return request(options);
};

export const deleteMembers = (id) => {
  const endpoint = `/api/admin/members/${id}/delete_user`;
  const method = "DELETE";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.DELETEMEMBER_ON_SUCCESS,
    ActionTypes.DELETEMEMBER_ON_FAILURE,
  ];
  return request(options);
};

export const updateMembers = (id, data) => {
  const endpoint = `/api/admin/members/${id}`;
  const method = "PUT";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.EDITEMEMBER_ON_SUCCESS,
    ActionTypes.EDITEMEMBER_ON_FAILURE,
  ];
  return request(options);
};

export const updateProfile = (id, data) => {
  const endpoint = `/api/admin/members/${id}/update_profile`;
  const method = "PATCH";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.EDITPROFILE_ON_SUCCESS,
    ActionTypes.EDITPROFILE_ON_FAILURE,
  ];
  return request(options);
};

export const activateMember = () => {
  const endpoint = `/api/admin/members/activate_or_deactivate`;
  const method = "PATCH";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.ACTIVATEMEMBER_ON_SUCCESS,
    ActionTypes.ACTIVATEMEMBER_ON_FAILURE,
  ];
  return request(options);
};

export const resendMemberInvitation = (id) => {
  const endpoint = `/api/admin/members/${id}/resend_invite`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = null;
  options.types = [
    ActionTypes.INVITEMEMBER_ON_SUCCESS,
    ActionTypes.INVITEMEMBER_ON_FAILURE,
  ];
  return request(options);
};

export const resetMembersFlag = () => {
  return { type: ActionTypes.RESET_FLAGS };
};
export const showMemberPrivileges = () => {
  return { type: ActionTypes.MEMBER_PRIVILEGES };
};
