import { useEffect } from "react"
import { LiveHeader } from "../.."
import Label from "src/components/Label"
import { useSelector } from "react-redux"
import Images from "src/assets/img/Images"
import { styled } from "@mui/material/styles"
import { useCamera } from "src/hooks/useCamera"
import DoneIcon from "@mui/icons-material/Done"
import MicIcon from "@mui/icons-material/Mic"
import MicOffIcon from "@mui/icons-material/MicOff"
import AudioCheckModal from "./Modal/audioCheckModal"
import {
  Stack,
  Button,
  Typography,
  Grid,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
} from "@mui/material"
import VideocamIcon from "@mui/icons-material/Videocam"
import VideocamOffIcon from "@mui/icons-material/VideocamOff"
import { fDateTime, fDate } from "src/utils/formatTime"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import { useUserMedia } from "src/hooks/useUserMedia"
import LoopIcon from "@mui/icons-material/Loop"
import { useNetwork } from "src/hooks/useNetwork"

import WifiIcon from "@mui/icons-material/Wifi"
import WifiOffIcon from "@mui/icons-material/WifiOff"

const Jumbotron = styled("div")(({ theme }) => ({
  padding: 0,
}))

const PanelInner = styled("div")(({ theme }) => ({
  // padding: '1.8rem 2.8rem 1rem 2.8rem ',
}))
const IntructionListing = styled("div")(({ theme }) => ({}))

const LineTrough = styled("hr")(({ theme }) => ({
  borderTop: "1px solid #CBD5E1 !important",
  borderBottom: 0,
  borderLeft: 0,
  borderRight: 0,
  marginTop: "24px",
  marginBottom: "30px",
}))

export default function Checking({ interview, onCanProceed, isInterviewer }) {
  const [isShowingAudio, toggleAudio] = useModal()
  const checks = useSelector((state) => state.handler)
  const isOnline = useNetwork()

  const AUDIO_CAPTURE_OPTIONS = {
    audio: true,
    video: false,
  }
  const { loading: loadingMicrophone, mediaStream: micMediaStream } =
    useUserMedia(AUDIO_CAPTURE_OPTIONS)
  const {
    camera,
    detected: cameraDetected,
    loading: loadingCamera,
  } = useCamera()

  const {
    candidate_vacancy: {
      candidate: { first_name: firstName, last_name: lastName },
      vacancy: {
        title: title,
        interview_time: interviewTime,
        department: {
          account: {
            logo: { url: logoUrl },
            company_name: companyName,
          },
        },
      },

      vacancy: { title: roleTitle },
    },
    interview_questions: questions,
    interview_format: interviewFormat,
    expires_on: expiresOn,
    interview_time: createdAt,
  } = interview

  const renderIcons = (state, name) => {
    return <>{state ? name[0] : name[1]}</>
  }

  useEffect(() => {
    if (onCanProceed) {
      onCanProceed(cameraDetected && micMediaStream && navigator?.onLine)
    }
  }, [checks, cameraDetected, navigator?.onLine])

  const renderNetworkState = () => {
    if (isOnline) {
      return (
        <Label
          variant='ghost'
          color='success'
          sx={{
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 500,
          }}
          startIcon={<FiberManualRecordIcon />}
        >
          Connected
        </Label>
      )
    } else {
      return (
        <Label
          variant='ghost'
          color='error'
          sx={{
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 500,
          }}
          startIcon={<FiberManualRecordIcon />}
        >
          Not Connected
        </Label>
      )
    }
  }

  return (
    <>
      <PanelInner>
        <Grid container spacing={3}>
          <Grid item sx={{ pt: 6, pb: { xs: 0, sm: 2 }, mt: 2 }} sm={12} md={6}>
            <Jumbotron>
              <LiveHeader logoUrl={logoUrl} />
              <Typography
                variant='h5'
                sx={{
                  marginBottom: "0.29rem",
                  lineHeight: "24px",
                  fontSize: "16px !important",
                  fontWeight: 600,
                  mt: 1,
                  textTransform: "capitalize",
                }}
              >
                Welcome {firstName} {lastName}!
              </Typography>
              <Typography
                variant='h4'
                component='h4'
                sx={{
                  marginBottom: "1rem",
                  color: "#1E293B",
                  fontWeight: 600,
                }}
              >
                {" "}
                Let's Check Everything is Working
              </Typography>
              <Typography
                variant='subtitile2'
                component='p'
                sx={{
                  mb: 1,
                  color: "#475569",
                  lineHeight: "24px",
                  fontSize: "16px !important",
                  fontWeight: 400,
                }}
              >
                Thank you for applying for the role of{" "}
                <strong>{roleTitle}</strong> with <strong>{companyName}</strong>
                .{" "}
                {isInterviewer !== "true" ? (
                  <>This interview expires at {fDate(expiresOn)} 24:00.</>
                ) : (
                  <>This interview is scheduled for {fDateTime(createdAt)}.</>
                )}
              </Typography>

              <LineTrough />

              <Typography
                variant='h6'
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#1E293B",
                  marginBottom: "15px",
                }}
              >
                Checking Devices
              </Typography>
              <Stack direction={{ xs: "column", sm: "row", md: "column" }}>
                <Typography
                  variant='subtitile2'
                  alignItems='center'
                  component='p'
                  sx={{
                    display: "flex",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: " 24px",
                    mb: 0,
                  }}
                >
                  {loadingCamera ? (
                    <LoopIcon sx={{ fontSize: 24, mr: 2 }} />
                  ) : (
                    renderIcons(cameraDetected, [
                      <VideocamIcon
                        fontSize='large'
                        sx={{
                          mr: 2,
                          color: "#229A16",
                          width: 22,
                        }}
                      />,
                      <VideocamOffIcon
                        fontSize='large'
                        sx={{
                          mr: 2,
                          color: "#dd3434",
                          width: 22,
                        }}
                      />,
                    ])
                  )}
                  <span className='hide-xs'>Camera</span>
                </Typography>
              </Stack>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Typography
                  variant='subtitle2'
                  alignItems='center'
                  component='p'
                  sx={{
                    display: "flex",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: " 24px",
                    flexWrap: "wrap",
                    mb: 0,
                  }}
                >
                  {loadingMicrophone ? (
                    <LoopIcon sx={{ fontSize: 24, mr: 2 }} />
                  ) : (
                    renderIcons(micMediaStream !== null, [
                      <MicIcon
                        fontSize='large'
                        sx={{
                          mr: 2,
                          color: "#229A16",
                          width: 22,
                        }}
                      />,
                      <MicOffIcon
                        fontSize='large'
                        sx={{
                          mr: 2,
                          color: "#dd3434",
                          width: 22,
                        }}
                      />,
                    ])
                  )}

                  <span className='hide-xs'> Microphone</span>
                </Typography>
                <Button
                  onClick={toggleAudio}
                  className='lightBlueBtn'
                  sx={{ my: 1, ml: 1 }}
                  variant='outlined'
                  startIcon={<img alt='' src={Images.PlayBlue} />}
                >
                  Check Audio
                </Button>
                <AudioCheckModal open={isShowingAudio} onClose={toggleAudio} />
              </Stack>
              <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
              >
                <Typography
                  variant='subtitile2'
                  alignItems='center'
                  component='p'
                  sx={{
                    display: "flex",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: " 24px",
                    mb: 0,
                  }}
                >
                  {renderIcons(isOnline, [
                    <WifiIcon
                      fontSize='large'
                      sx={{
                        mr: 2,
                        color: "#229A16",
                        width: 22,
                      }}
                    />,
                    <WifiOffIcon
                      fontSize='large'
                      sx={{
                        mr: 2,
                        color: "#dd3434",
                        width: 22,
                      }}
                    />,
                  ])}

                  <span className='hide-xs'>Network</span>
                </Typography>
                {renderNetworkState()}
              </Stack>
            </Jumbotron>
          </Grid>

          <Grid
            item
            sx={{
              pt: { xs: 0, sm: 2, md: 6 },
              pb: { sm: 1, md: 2 },
              mt: { xs: 0, sm: 0, md: 2 },
            }}
            sm={12}
            md={6}
          >
            <Jumbotron>
              <Stack>
                <Box className='video-box-test'>{camera()}</Box>
              </Stack>

              <IntructionListing
                className='welcome-area'
                sx={{
                  marginTop: { xs: "10px", sm: "14px" },
                }}
              >
                <List>
                  <ListItem sx={{ px: 0, pt: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <DoneIcon
                        sx={{ color: "#059669", fontSize: 20, mr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "24px",
                        color: "#475569",
                      }}
                    >
                      This interview consists of{" "}
                      <strong>{questions?.length}</strong> questions
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ px: 0, pt: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <DoneIcon
                        sx={{ color: "#059669", fontSize: 20, mr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "24px",
                        color: "#475569",
                      }}
                    >
                      This interview will last{" "}
                      <strong>{interviewTime.replace(" min", "")}</strong>{" "}
                      minutes
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ px: 0, pt: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <DoneIcon
                        sx={{ color: "#059669", fontSize: 20, mr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "24px",
                        color: "#475569",
                      }}
                    >
                      Your answers will be{" "}
                      <strong>recorded individually</strong>
                    </ListItemText>
                  </ListItem>
                </List>
              </IntructionListing>
            </Jumbotron>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item>
            <Alert
              variant='filled'
              severity='warning'
              sx={{
                fontSize: 14,
                lineHeight: "20px",
                fontWeight: 400,
              }}
            >
              Once you start the interview, you will not be able to
              <b> pause</b> it. <br />
            </Alert>
          </Grid>
        </Grid>
      </PanelInner>
    </>
  )
}
