import PropTypes from "prop-types"
import merge from "lodash/merge"
import ReactApexChart from "react-apexcharts"
import { Box, Card, CardHeader } from "@mui/material"
import { fNumber } from "../../../utils/formatNumber"
import { BaseOptionChart } from "../../../components/chart"

// ----------------------------------------------------------------------

AppConversionRates.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartData: PropTypes.array.isRequired,
}

export default function AppConversionRates({
  title,
  t,
  subheader,
  chartData,
  isLoading,
  ...other
}) {
  const chartLabels = chartData.map((i) => t(i.label))

  const chartSeries = chartData.map((i) => i.value)

  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: true },
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: () => "",
        },
      },
    },
    plotOptions: {
      // bar: { horizontal: true, barHeight: "28%", borderRadius: 2 },
      line: { horizontal: true, barHeight: "28%", borderRadius: 2 },
    },
    xaxis: {
      categories: chartLabels,
    },
  })

  return (
    <Card {...other} sx={{ height: "100%" }}>
      <CardHeader title={title} subheader={subheader} sx={{ mb: 3 }} />

      <Box sx={{ mx: 3 }} dir='ltr'>
        <ReactApexChart
          type='line'
          series={[{ data: chartSeries }]}
          options={chartOptions}
          height={364}
        />
      </Box>
    </Card>
  )
}
