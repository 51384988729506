import * as Yup from "yup"
import React, { useEffect, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Box,
  OutlinedInput,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormGroup,
  Switch,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { Form, FormikProvider, useFormik } from "formik"
import { useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"
import { useDispatch, useSelector } from "react-redux"
import {
  createQuestionSet,
  getAllQuestionSet,
  updateQuestionSet,
  resetCreateQuestionsFlag,
} from "src/redux/actions/questions"
import { questionSetParsing } from "src/utils/dataParsing"
import { createTheme } from "@mui/material/styles"
import { grey } from "@mui/material/colors"
import { makeStyles } from "@material-ui/core/styles"
import Alert from "src/components/Alert"
import { CreateDeptModal } from "../../department"
import { getAllDepartment } from "src/redux/actions/department"
import Toast from "src/components/Toast"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import EditIcon from "@mui/icons-material/Edit"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined"
const theme = createTheme({
  palette: {
    secondary: {
      main: grey[400],
    },
  },
})

//************************** */

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name, deptName, theme) {
  return {
    fontWeight:
      deptName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    backgroundColor: deptName.indexOf(name) === -1 ? "inherit" : "#1976d214",
  }
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuPaper: {
    maxHeight: 150,
  },
}))

/************** */
export default function AddQuestionModal({
  isShowingQuestion = null,
  open,
  hide,
  data,
  flag,
  vacancyTitle = "",
  depName = "",
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [initialState, setInitialState] = useState({
    questionSetTitle: vacancyTitle,
    questions: [],
    choices: [],
    departments: "",
  })

  const [questionText, setQuestionText] = useState("")
  const [choices, setChoices] = useState([])
  const [newChoice, setNewChoice] = useState("")
  const [choiceExist, setChoiceExist] = useState(false)
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(-1)
  const [editedTexts, setEditedTexts] = useState([])

  const handleAddChoice = () => {
    setChoices([...choices, newChoice])
    setNewChoice("")
  }

  useEffect(() => {
    setInitialState({
      ...initialState,
      questionSetTitle: vacancyTitle,
    })
  }, [isShowingQuestion])

  useEffect(() => {
    if (flag && depName) {
      let department = existingDept?.data?.departments?.find(
        (dept) => dept.name === depName,
      )

      setDeptName(depName)
      setFieldValue("departments", department?.id)
    }

    if (data) {
      setDeptName(data?.department?.name)
      setInitialState({
        questionSetTitle: data?.name ?? vacancyTitle,
        questions: data?.questions?.map((q) => {
          return {
            id: q.id,
            text: q.text,
            question_type: q.question_type,
            choices: q.choices,
            correct_answer: q.correct_answer,
          }
        }),
        departments: data?.department_id,
      })
    }
  }, [data, isShowingQuestion, vacancyTitle, depName])

  const [error, setError] = useState("")

  const submission = (values, { resetForm }) => {
    let uniqueset = new Set(values?.questions)
    let uniqueList = [...uniqueset]

    if (uniqueList?.length === 0) {
      setError(t("ErrAtleastQuestionrequired"))
    } else {
      if (flag) {
        if (uniqueList?.length !== 0) {
          let formData = {}
          formData.name = values.questionSetTitle
          formData.questions_attributes = uniqueList?.map((question) => {
            return { question }
          })

          formData.department_id = values.departments
          resetForm({ values: "" })
          dispatch(createQuestionSet(formData))
          hide()
          setError("")
          setQuestionId("")
          setPreErr("")
          setDeptName("")
        }
      } else {
        resetForm({ values: "" })
        let formData = {}
        formData.name = values?.questionSetTitle
        formData.questions_attributes = values?.questions
        formData.department_id = values.departments
        dispatch(updateQuestionSet(data.key, formData))
        hide()
        setPreErr("")
        setQuestionId("")
      }
    }
    setEditedTexts([])
    setEligibleArrayForAppending([])
  }

  const QuestionSchema = Yup.object().shape({
    questionSetTitle: Yup.string()
      .min(2, t("TooShort!"))
      .max(50, t("TooLong!"))
      .required(t("ErrTitleRequired"))
      .matches(/[^\s]/, t("BlankNotAllowed")),

    departments: Yup.string().required(t("DepartmentRequired")),
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: QuestionSchema,
    onSubmit: submission,
  })

  const {
    errors,
    touched,
    values,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleBlur,
  } = formik
  const existingDept = useSelector((state) => state.departments)

  const quest = useSelector((state) => state.questions)
  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const [preview, setPreview] = useState([])
  const [questionId, setQuestionId] = useState("")
  const [preErr, setPreErr] = useState("")
  const [eligibleArrayForAppending, setEligibleArrayForAppending] = useState([])
  const [toastProps, setToastProps] = useState(null)

  const handleToastOnClose = () => {
    setToastProps(null)
  }

  const handleChange = (e) => {
    setQuestionId(e.target.value)
    let newQuestions = quest?.data?.question_sets?.find(
      (questionSet) => questionSet.id === e.target.value,
    )?.questions
    setPreview(newQuestions)
    setPreErr(null)
  }

  const handleAppend = () => {
    setError("")
    let newQuestions = quest?.data?.question_sets?.find(
      (questionSet) => questionSet.id === questionId,
    )?.questions

    let tempQuestions = [...values.questions]
    let questionsImported = false

    newQuestions.forEach((nq) => {
      const index = values.questions.findIndex((q) => q.text === nq.text)

      if (index < 0 || values.questions[index]._destroy === true) {
        let question = {
          text: nq.text,
          question_type: nq.question_type,
          choices: nq.choices,
          correct_answer: nq.correct_answer,
        }
        tempQuestions.push(question)
        questionsImported = true
      }
    })

    if (!questionsImported) {
      setToastProps({
        message: t("QuestionsAlreadyAdded"),
        isError: true,
      })
    }

    setFieldValue("questions", tempQuestions)
    setEligibleArrayForAppending([...eligibleArrayForAppending, questionId])
  }

  const classes = useStyles()

  const handleClose = () => {
    if (flag) {
      setQuestionId(questionId)
      setDeptName("")
      values.departments = ""
      values.questionSetTitle = ""
      values.questions = ""
      setEditedTexts([])
    } else {
      setQuestionId("")
      setEditedTexts(
        data?.questions?.map((q) => {
          return {
            id: q.id,
            text: q.text,
            question_type: q.question_type,
            choices: q.choices,
            correct_answer: q.correct_answer,
          }
        }),
      )
    }
    setError("")
    setQuestionText("")
    resetForm()
    setSelectedQuestionIndex(-1)
    setSelectedOption(null)
    setChoices([])
    setQuestionType("Problem Solving")
    hide()
  }

  const removeOption = (index) => {
    setSelectedOption(null)
    const ch = [...choices]
    ch.splice(index, 1)
    setChoices(ch)
  }
  const [isShowingDpt, toggleDpt] = useModal()

  const [deptName, setDeptName] = useState(t("Select"))

  const [questionSetError, setQuestionSetError] = useState("")
  const [questionLimitError, setQuestionLimitError] = useState("")

  const [questionType, setQuestionType] = useState("Problem Solving")
  const [showRadioButtons, setShowRadioButtons] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)

  const handleOptionChange = (index) => {
    setSelectedOption(index)
  }

  const toggleRadioButtons = () => {
    setShowRadioButtons(!showRadioButtons)
    setChoices([])
    setQuestionType("Problem Solving")
    setSelectedOption(null)
  }

  const handleQuestionTypeChange = (event) => {
    // When the user selects a different value, update the questionType state
    setQuestionType(event.target.value)
  }

  useEffect(() => {
    if (existingDept?.data?.length === 0) {
      dispatch(getAllDepartment())
    }
  }, [existingDept])

  useEffect(() => {
    if (quest?.created === true && flag) {
      dispatch(resetCreateQuestionsFlag())
      dispatch(getAllQuestionSet())
    }
  }, [quest])

  const checkIfQuestionAlreadyExists = () => {
    const lowercaseQuestionText = questionText.toLowerCase().trim()

    for (let i = 0; i < values?.questions.length; i++) {
      const lowercaseQuestion = values?.questions[i].text.toLowerCase().trim()

      if (lowercaseQuestion === lowercaseQuestionText) {
        return true
      }
    }

    return false
  }

  const checkIfChoiceAlreadyExists = (val) => {
    const choiceExists = choices.some(
      (choice) => choice.toLowerCase().trim() === val.toLowerCase().trim(),
    )
    setChoiceExist(choiceExists)
  }

  const [valueChoice, setValueChoice] = useState()
  const handleChangeChoice = (event) => {
    setValueChoice(event.target.value)
  }

  return (
    isShowingQuestion && (
      <Dialog
        className='dialog800'
        open={isShowingQuestion}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
      >
        {toastProps && (
          <Toast {...toastProps} onClose={handleToastOnClose} top='' />
        )}
        <DialogTitle
          id='alert-dialog-title'
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {flag ? t("AddQuestionSet") : t("EditQuestionSet")}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormikProvider value={formik}>
                <Form
                  id='addQuesion'
                  autoComplete='off'
                  onSubmit={handleSubmit}
                >
                  <Stack spacing={3}>
                    <FormControl
                      sx={{ mb: "1.5rem !important ", mt: "0 !important" }}
                    >
                      <FormLabel htmlFor='name' required>
                        {t("QuestionSetTitle")}
                      </FormLabel>
                      <TextField
                        fullWidth
                        type='text'
                        variant='outlined'
                        placeholder={t("QuestionSetTitle")}
                        name='questionSetTitle'
                        id='questionSetTitle'
                        {...getFieldProps("questionSetTitle")}
                        error={Boolean(
                          touched.questionSetTitle && errors.questionSetTitle,
                        )}
                        helperText={
                          touched.questionSetTitle && errors.questionSetTitle
                        }
                      />
                    </FormControl>
                    <FormControl
                      sx={{
                        width: "100%",
                        mb: "1.5rem !important ",
                        mt: "0 !important",
                      }}
                    >
                      <FormLabel required>{t("Department")}</FormLabel>
                      <Stack
                        className='input-adornment'
                        direction={{ xs: "column", sm: "column" }}
                      >
                        <Select
                          className='demo-select'
                          name='Users'
                          value={deptName}
                          input={<OutlinedInput />}
                          MenuProps={{
                            classes: { paper: classes.menuPaper },
                          }}
                          renderValue={(deptName) => {
                            if (deptName.length === 0) {
                              return <em>{t("SelectDepartment")}</em>
                            }
                            return deptName
                          }}
                          onChange={(e, value) => {
                            setDeptName(value.props.children)
                            setFieldValue("departments", e.target.value)
                          }}
                          error={Boolean(
                            touched.departments && errors.departments,
                          )}
                          helperText={touched.departments && errors.departments}
                        >
                          {existingDept?.data?.departments?.length > 0 && [
                            <MenuItem
                              sx={{
                                fontWeight: 600,
                                color: "#5082dd",
                                opacity: "1 !important",
                              }}
                              disabled
                              value=''
                            >
                              <em>{t("ChooseDepartment")}</em>
                            </MenuItem>,
                          ]}
                          {existingDept?.data?.departments?.length === 0
                            ? [
                                <MenuItem disabled>
                                  {t("DataNotFound")}
                                </MenuItem>,
                              ]
                            : existingDept?.data?.departments?.map((option) => [
                                <MenuItem
                                  key={option.id}
                                  value={option.id}
                                  sx={getStyles(option.name, deptName, theme)}
                                >
                                  {option.name}
                                </MenuItem>,
                              ])}
                        </Select>
                        {touched?.departments && errors?.departments && (
                          <Typography
                            className='input-error'
                            sx={{ mt: "0.5rem !important" }}
                          >
                            {errors?.departments}
                          </Typography>
                        )}
                        <Button
                          type='button'
                          variant='outlined'
                          color='secondary'
                          size='small'
                          className='add-btn'
                          sx={{
                            maxHeight: 38,
                            width: "fit-content",
                            fontWeight: 500,
                            mt: 2,
                            justifyContent: "center",
                            pl: 2,
                            pr: 2,
                          }}
                          onClick={toggleDpt}
                          startIcon={<AddIcon />}
                        >
                          <span className='visible-xs'>{t("Add")}</span>
                          <span className='hidden-xs'>
                            {t("CreateNewDepartment")}
                          </span>
                        </Button>

                        <CreateDeptModal
                          isShowingDpt={isShowingDpt}
                          hide={toggleDpt}
                          flag={true}
                        />
                      </Stack>
                    </FormControl>

                    {values?.questions.length !== 0 && values?.questions && (
                      <Box className='mini-container'>
                        {values?.questions?.map((question, index) => {
                          let editedQuestion = editedTexts[index] || question
                          editedQuestion = {
                            ...editedQuestion,
                            new_choice: editedQuestion.new_choice ?? "",
                          }

                          return question._destroy === true ? null : (
                            <Box className='ques-list'>
                              <Stack
                                key={index}
                                direction='row'
                                justifyContent='space-between'
                                sx={{
                                  mb: 2,
                                  width: "100%",
                                }}
                              >
                                <Typography
                                  variant='subtitle1'
                                  sx={{
                                    mb: 1,
                                    mr: "2px",
                                    wordBreak: "break-word",
                                    display: "inline-flex",
                                    width: "98%",
                                  }}
                                >
                                  {index + 1}.&nbsp;
                                  {selectedQuestionIndex === index ? (
                                    <TextField
                                      value={editedQuestion.text}
                                      name='editQuestion'
                                      variant='standard'
                                      multiline
                                      sx={{
                                        pl: 1,
                                        boxSizing: "border-box",
                                        pt: "0 !important ",
                                        width: "100%",
                                      }}
                                      onChange={(e) => {
                                        editedQuestion = {
                                          ...editedQuestion,
                                          text: e.target.value,
                                        }
                                        setEditedTexts((prevTexts) => ({
                                          ...prevTexts,
                                          [index]: editedQuestion,
                                        }))
                                      }}
                                    />
                                  ) : (
                                    <Typography
                                      component='span'
                                      sx={{ fontWeight: 600, ml: 1 }}
                                    >
                                      {question.text}
                                    </Typography>
                                  )}
                                </Typography>

                                <Box
                                  className='right-icon'
                                  sx={{ minWidth: "70px" }}
                                >
                                  {selectedQuestionIndex === index ? (
                                    <SaveOutlinedIcon
                                      sx={{
                                        width: "24px",
                                        height: "26px",
                                        color: "#49454F",
                                      }}
                                      onClick={() => {
                                        if (
                                          editedQuestion.question_type !==
                                            "Multiple Choice" ||
                                          (editedQuestion.question_type ===
                                            "Multiple Choice" &&
                                            editedQuestion.choices.includes(
                                              editedQuestion.correct_answer,
                                            ) &&
                                            editedQuestion.choices.length > 1)
                                        ) {
                                          const updatedQuestions =
                                            values?.questions?.map((q, i) =>
                                              index === i ? editedQuestion : q,
                                            )
                                          setFieldValue(
                                            "questions",
                                            updatedQuestions,
                                          )
                                          setSelectedQuestionIndex(-1)
                                          setEditedTexts((prevTexts) => ({
                                            ...prevTexts,
                                            [index]: null,
                                          }))
                                        }
                                      }}
                                    />
                                  ) : (
                                    <EditIcon
                                      onClick={(e) =>
                                        setSelectedQuestionIndex(index)
                                      }
                                      sx={{
                                        width: "24px",
                                        height: "26px",
                                        color: "#49454F",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}

                                  <DeleteOutlineIcon
                                    onClick={(e) => {
                                      let tempQuestions = [...values?.questions]

                                      let removed = values?.questions[index]
                                      if (removed.id) {
                                        removed = {
                                          ...removed,
                                          _destroy: true,
                                        }

                                        tempQuestions = tempQuestions.map(
                                          (element, i) =>
                                            index === i ? removed : element,
                                        )
                                      } else {
                                        tempQuestions.splice(index, 1)
                                      }

                                      if (
                                        tempQuestions.length === 0 ||
                                        tempQuestions.filter(
                                          (q) => q._destroy !== true,
                                        ).length === 0
                                      ) {
                                        setEligibleArrayForAppending([])
                                        setError(
                                          t("ErrAtleastQuestionrequired"),
                                        )
                                      }
                                      setFieldValue("questions", tempQuestions)
                                    }}
                                    sx={{
                                      width: "24px",
                                      height: "26px",
                                      color: "#49454F",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Box>
                              </Stack>

                              {question.question_type === "Multiple Choice" && (
                                <Box sx={{}} className='option-box'>
                                  <Typography variant='body1' sx={{ mb: 1 }}>
                                    MCQ Choices:
                                  </Typography>
                                  {selectedQuestionIndex === index ? (
                                    <div>
                                      {editedQuestion.choices.map(
                                        (choice, i) => (
                                          <Stack
                                            direction='row'
                                            alignItems='center'
                                            key={i}
                                            className='choice-row'
                                          >
                                            <FormControlLabel
                                              name='options'
                                              value={choice}
                                              control={<Radio />}
                                              checked={
                                                editedQuestion.correct_answer ===
                                                choice
                                              }
                                              onChange={() => {
                                                editedQuestion = {
                                                  ...editedQuestion,
                                                  correct_answer: choice,
                                                }
                                                setEditedTexts((prevTexts) => ({
                                                  ...prevTexts,
                                                  [index]: editedQuestion,
                                                }))
                                              }}
                                              label={choice}
                                            />

                                            <DeleteOutlineIcon
                                              onClick={() => {
                                                const ch = [
                                                  ...editedQuestion.choices,
                                                ]
                                                ch.splice(i, 1)
                                                editedQuestion = {
                                                  ...editedQuestion,
                                                  choices: ch,
                                                }
                                                setEditedTexts((prevTexts) => ({
                                                  ...prevTexts,
                                                  [index]: editedQuestion,
                                                }))
                                              }}
                                              sx={{ width: "20px" }}
                                            />
                                          </Stack>
                                        ),
                                      )}

                                      {editedQuestion.choices.length < 4 && (
                                        <>
                                          <Box
                                            justifyContent='flex-start'
                                            alignItems='center'
                                            direction='row'
                                            sx={{ my: 2 }}
                                          >
                                            <TextField
                                              type='text'
                                              variant='outlined'
                                              size='small'
                                              value={editedQuestion.new_choice}
                                              onChange={(e) => {
                                                editedQuestion = {
                                                  ...editedQuestion,
                                                  new_choice: e.target.value,
                                                }
                                                setEditedTexts((prevTexts) => ({
                                                  ...prevTexts,
                                                  [index]: editedQuestion,
                                                }))
                                              }}
                                            />

                                            <Button
                                              type='button'
                                              variant='outlined'
                                              color='secondary'
                                              className='add-btn'
                                              sx={{
                                                maxWidth: 200,
                                                mx: 1,
                                                maxHeight: 38,
                                                fontWeight: 500,
                                              }}
                                              startIcon={<AddIcon />}
                                              onClick={(e) => {
                                                const Exist =
                                                  editedQuestion.choices.some(
                                                    (choice) =>
                                                      choice
                                                        .toLowerCase()
                                                        .trim() ===
                                                      editedQuestion.new_choice
                                                        .toLowerCase()
                                                        .trim(),
                                                  )
                                                if (
                                                  editedQuestion.new_choice.trim() !==
                                                    "" &&
                                                  !Exist
                                                ) {
                                                  editedQuestion = {
                                                    ...editedQuestion,
                                                    choices: [
                                                      ...editedQuestion.choices,
                                                      editedQuestion.new_choice,
                                                    ],
                                                    new_choice: "",
                                                  }
                                                  setEditedTexts(
                                                    (prevTexts) => ({
                                                      ...prevTexts,
                                                      [index]: editedQuestion,
                                                    }),
                                                  )
                                                }
                                              }}
                                            >
                                              {t("Add")}
                                            </Button>
                                          </Box>
                                        </>
                                      )}

                                      <Alert severity='success' color='info'>
                                        {t("MCQAlert")}
                                      </Alert>
                                    </div>
                                  ) : (
                                    question.choices.map((choice, index) => (
                                      <FormControl>
                                        <RadioGroup
                                          aria-labelledby='demo-radio-buttons-group-label'
                                          //   defaultValue='female'
                                          row
                                          name='radio-buttons-group'
                                          key={index}
                                        >
                                          <FormControlLabel
                                            name='options'
                                            value={choice}
                                            control={<Radio />}
                                            checked={
                                              question.correct_answer === choice
                                            }
                                            disabled={true}
                                            label={choice}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    ))
                                  )}
                                </Box>
                              )}
                            </Box>
                          )
                        })}
                      </Box>
                    )}

                    <Grid item xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel required> {t("AddQuestion")}</FormLabel>
                        <TextField
                          fullWidth
                          type='text'
                          variant='outlined'
                          placeholder={t("AddQuestion")}
                          name='questionText'
                          id='questionText'
                          {...getFieldProps("questionText")}
                          value={questionText}
                          onChange={(e) => {
                            setQuestionText(e.target.value)

                            if (questionText.length >= 500) {
                              setQuestionLimitError(
                                t("ErrQuestionCantExceed500Chr"),
                              )
                            } else {
                              setQuestionLimitError("")
                            }

                            if (checkIfQuestionAlreadyExists()) {
                              setQuestionSetError(t("MatchFound!"))
                            } else {
                              setQuestionSetError("")
                            }
                          }}
                        />

                        {(questionSetError ||
                          questionLimitError ||
                          errors.questions) && (
                          <Typography
                            variant='span'
                            sx={{
                              marginTop: "5px",
                              color: "#FF4842",
                              fontWeight: 400,
                              fontSize: "1rem",
                            }}
                          >
                            {questionSetError || questionLimitError}
                          </Typography>
                        )}

                        <Stack
                          justifyContent='space-between'
                          direction={{ xs: "column-reverse", sm: "row" }}
                          sx={{ mt: 1 }}
                        >
                          <FormGroup
                            sx={{ minWidth: { xs: "170px", sm: "220px" } }}
                          >
                            <FormControlLabel
                              className='switch-control'
                              sx={{
                                maxWidth: "300px",
                                mr: 0,
                              }}
                              control={
                                <Switch
                                  defaultChecked
                                  id='switch'
                                  checked={showRadioButtons}
                                  onChange={toggleRadioButtons}
                                />
                              }
                              label={t("Show Type")}
                            />

                            {showRadioButtons && (
                              <RadioGroup
                                row
                                sx={{
                                  flexDirection: { xs: "column", sm: "row" },
                                }}
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='row-radio-buttons-group'
                                value={questionType}
                                onChange={handleQuestionTypeChange}
                              >
                                <FormControlLabel
                                  value='Problem Solving'
                                  control={<Radio />}
                                  checked={Boolean(
                                    questionType === "Problem Solving",
                                  )}
                                  label={t("Problem Solving")}
                                />
                                <FormControlLabel
                                  value='Multiple Choice'
                                  control={<Radio />}
                                  checked={Boolean(
                                    questionType === "Multiple Choice",
                                  )}
                                  label={t("Multiple Choice")}
                                />
                              </RadioGroup>
                            )}
                          </FormGroup>

                          <Button
                            type='button'
                            variant='outlined'
                            color='secondary'
                            className='add-btn'
                            sx={{
                              maxWidth: 200,
                              my: 1,
                              maxHeight: 33,
                              fontWeight: 500,
                            }}
                            disabled={
                              questionText.trim() === "" ||
                              questionLimitError !== "" ||
                              questionSetError !== "" ||
                              (showRadioButtons &&
                                questionType === "Multiple Choice" &&
                                (choices.length < 2 || selectedOption === null))
                            }
                            startIcon={<AddIcon />}
                            onClick={(e) => {
                              if (
                                questionType === "Multiple Choice" &&
                                (choices.length < 2 || selectedOption === null)
                              ) {
                                setQuestionSetError(t("MatchFound!"))
                              } else {
                                setQuestionSetError("")
                                let tempQuestions = [...values?.questions]
                                let question = {
                                  text: questionText,
                                  question_type: showRadioButtons
                                    ? questionType
                                    : "",
                                  correct_answer:
                                    showRadioButtons &&
                                    questionType === "Multiple Choice"
                                      ? choices[selectedOption]
                                      : "",
                                  choices: choices,
                                }
                                tempQuestions.push(question)
                                setFieldValue("questions", tempQuestions)
                                setQuestionText("")
                                setError("")
                                setChoices([])
                                setShowRadioButtons(false)
                                setQuestionType("Problem Solving")
                                setSelectedOption(null)
                              }
                            }}
                          >
                            <span className='visible-xs'> {t("Add")}</span>
                            <span className='hidden-xs'>
                              {" "}
                              {t("AddQuestion")}
                            </span>
                          </Button>
                        </Stack>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sx={{ mt: "0 !important " }}>
                      {["Problem Solving", ""].includes(questionType) ? null : (
                        <FormControl
                          fullWidth
                          sx={{ mb: "0.5rem !important ", mt: "0 !important" }}
                        >
                          <FormLabel id='choice-controlled-radio-buttons-group'>
                            {t("Add Choices:")}
                          </FormLabel>
                          {choices.length !== 0 && (
                            <RadioGroup
                              aria-labelledby='demo-controlled-radio-buttons-group'
                              name='options'
                              value={valueChoice}
                              onChange={handleChangeChoice}
                              className='mini-container'
                              sx={{ mb: 1 }}
                            >
                              {choices.map((choice, index) => (
                                <Box
                                  key={index}
                                  direction='row'
                                  justifyContent='space-between'
                                  alignItems='center'
                                  sx={{
                                    display: "flex",
                                    fontSize: "12px",
                                    alignItems: "center",
                                    wordBreak: "break-word",
                                    borderBottom: "1px solid #E6E6E6",
                                    width: "100%",
                                    "&:last-of-type": {
                                      border: "none",
                                    },
                                  }}
                                >
                                  <FormControlLabel
                                    value={choice}
                                    control={<Radio />}
                                    checked={selectedOption === index}
                                    onChange={() => handleOptionChange(index)}
                                    label={choice}
                                    sx={{
                                      mb: "0 !important",
                                      fontSize: "12px",
                                      paddingRight: "8px",
                                    }}
                                  />
                                  <DeleteOutlineIcon
                                    onClick={() => removeOption(index)}
                                    sx={{
                                      height: "20px",
                                      fontSize: "24px",
                                      width: "34px",
                                      minWidth: "34px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Box>
                              ))}
                            </RadioGroup>
                          )}

                          {choices.length < 4 && (
                            <Box
                              justifyContent='flex-start'
                              alignItems='center'
                              direction='row'
                              sx={{ mb: 2, mt: "4px" }}
                            >
                              <TextField
                                type='text'
                                variant='outlined'
                                sx={{ width: { xs: "100%", sm: "auto" } }}
                                value={newChoice}
                                onChange={(e) => {
                                  setNewChoice(e.target.value)
                                  checkIfChoiceAlreadyExists(e.target.value)
                                }}
                              />
                              <Button
                                type='button'
                                variant='outlined'
                                color='secondary'
                                className='add-btn'
                                sx={{
                                  top: 10,
                                  maxWidth: 200,
                                  mx: { xs: 0, sm: 2 },
                                  maxHeight: 38,
                                  fontWeight: 500,
                                }}
                                disabled={
                                  newChoice.trim() === "" || choiceExist
                                }
                                startIcon={<AddIcon />}
                                onClick={handleAddChoice}
                              >
                                {t("Add")}
                              </Button>
                            </Box>
                          )}

                          <Alert severity='success' color='info'>
                            {t("MCQAlert")}
                          </Alert>
                        </FormControl>
                      )}
                      {quest?.data?.question_sets?.length > 0 && (
                        <FormControl
                          sx={{ mb: "1.5rem !important ", mt: 0, mx: 0 }}
                          className={classes.formControl}
                        >
                          <FormLabel>{t("CopyQuesExistingQS")}</FormLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            name='question-sets'
                            className='demo-select'
                            onChange={(e) => handleChange(e)}
                            MenuProps={{
                              classes: { paper: classes.menuPaper },
                            }}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {quest?.data?.question_sets?.filter(
                              (user) => user.name !== values.questionSetTitle,
                            ).length > 0 && (
                              <MenuItem
                                sx={{
                                  fontWeight: 600,
                                  color: "#5082dd",
                                  opacity: "1 !important",
                                }}
                                disabled
                                value=''
                              >
                                <em>{t("ChooseQuestionSet")}</em>
                              </MenuItem>
                            )}
                            {quest?.data?.question_sets?.filter(
                              (user) => user.name !== values.questionSetTitle,
                            ).length === 0
                              ? [
                                  <MenuItem disabled>
                                    {t("DataNotFound")}
                                  </MenuItem>,
                                ]
                              : quest?.data?.question_sets
                                  ?.filter(
                                    (user) =>
                                      user.name !== values.questionSetTitle,
                                  )
                                  .map((option) => (
                                    <MenuItem
                                      key={option.id}
                                      value={option.id}
                                      sx={{ textTransform: "capitalize" }}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  ))}
                          </Select>
                        </FormControl>
                      )}

                      {preErr && <p className='input-error'>{preErr}</p>}

                      {!!questionId && (
                        <Box
                          sx={{
                            mb: "1.5rem !important ",
                            mt: "0 !important",
                          }}
                        >
                          <Stack
                            sx={{
                              mb: "1.5rem !important ",
                              mt: "0 !important",
                              width: "100%",
                              flexDirection: "row",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              type='button'
                              variant='outlined'
                              color='secondary'
                              sx={{
                                maxWidth: 100,
                                ml: 2,
                                maxHeight: 38,
                                fontWeight: 500,
                              }}
                              onClick={handleAppend}
                            >
                              {t("Import")}
                            </Button>
                          </Stack>

                          <Box className='mini-container'>
                            {preview?.map((question, index) => {
                              return (
                                <Typography
                                  className='preview'
                                  key={index}
                                  variant='h4'
                                  sx={{ display: "flex", mr: 2, mb: 1 }}
                                >
                                  Q {index + 1}:&nbsp; {question.text}
                                </Typography>
                              )
                            })}
                          </Box>
                        </Box>
                      )}
                    </Grid>
                  </Stack>
                </Form>
              </FormikProvider>
            </Grid>
          </Grid>
          {error && (
            <Alert severity='error' variant='outlined'>
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Cancel")}
          </Button>
          <Button
            onClick={() => {
              if (values.questions.length === 0) {
                setError(t("ErrAtleastQuestionrequired"))
              }
              handleSubmit()
            }}
            autoFocus
            variant='contained'
            color='secondary'
            size='medium'
            disabled={questionSetError !== "" || questionLimitError !== ""}
          >
            {flag ? t("Save") : t("Update")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
