export const subscriptionStatus = {
  trialing: "trialing",
  canceled: "canceled",
};
export const Constants = {
  STATE_IDLE: 0,
  STATE_IN_PROGRESS: 1,
  STATE_NOT_WORKING: 2,
  STATE_WORKING: 3
};
export const DBTimeZone = 'UTC'