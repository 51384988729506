import * as ActionTypes from "../constants/login"
import * as ERRORS from "../constants/errorManagement"

const userObj = ![null, undefined, "", "null", "undefined"].includes(
  localStorage.getItem("user"),
)
  ? JSON.parse(localStorage.getItem("user"))
  : undefined
const user = userObj?.user

const initialState = user
  ? { loggedIn: true, user, interviewsCreated: false }
  : { status: 0, loggedIn: false, user: null, interviewsCreated: false }

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_ON_SUCCESS:
      let loggedInUser = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : undefined
      loggedInUser = loggedInUser?.user

      let obj = {
        ...state,
        message: action.payload?.data.errors,
        status: action.payload?.status,
        error: action.payload,
        loggedIn: action.payload?.data.success,
        user: loggedInUser,
        interviewsCreated: action.payload?.data?.interviews_created,
      }
      return obj
    case ActionTypes.LOGIN_ON_FAILURE:
      let obj1 = {
        ...state,
        message: action.payload?.data.errors,
        status: action.payload?.status,
        loggedIn: action.payload?.data.success,
      }
      return obj1

    case ERRORS.API_FAILURE:
      const obj3 = {
        ...state,
        status: action?.error?.response?.status,
        message: action?.error?.response?.data?.errors,
      }
      return obj3
    case ActionTypes.RESET_lOGIN_ERROR:
      let obj4 = {
        ...state,
        message: null,
        status: action.payload?.status,
        error: action.payload,
        loggedIn: action.payload?.data?.success,
      }
      return obj4
    case ActionTypes.RESET_USER:
      localStorage.setItem("user", JSON.stringify(action.payload?.data))

      const obj5 = {
        ...state,
        user: action.payload?.data.user,
      }
      return obj5
    case ActionTypes.RESET_SUBSCRIPTION:
      const user = {
        ...state.user,
        subscription_info: action.payload?.data.user.subscription_info,
      }

      const currentUser = JSON.parse(localStorage.getItem("user"))
      localStorage.setItem(
        "user",
        JSON.stringify({ ...currentUser, user: user }),
      )

      return { ...state, user: user }

    case ActionTypes.RESET_PROFILE:
      const updatedUser = { ...state.user, profile: action.payload?.data.user }

      const currUser = JSON.parse(localStorage.getItem("user"))
      localStorage.setItem(
        "user",
        JSON.stringify({ ...currUser, user: updatedUser }),
      )

      return { ...state, user: updatedUser }

    case ActionTypes.REINTITALIZE_SESSION:
      let userToBeUpdated = JSON.parse(localStorage.getItem("user"))
      localStorage.setItem("user", JSON.stringify({...userToBeUpdated, user: action.payload}))

      return { ...state, user: action.payload }
    default:
      return state
  }
}

export const languageReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.LANGUAGE_ON_SUCCESS) {
    localStorage.setItem("user", JSON.stringify(action.payload?.data))

    return {
      ...state,
      user: action.payload.data.user,
      selectedLanguage: action.payload.data.user.selected_language,
    }
  }
  return state
}
