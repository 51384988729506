import { useSelector } from "react-redux"

export const useAuth = () => {
  const user = useSelector((state) => state.login?.user)
  const profile = useSelector((state) => state.login?.user?.profile)
  const permission = useSelector(
    (state) => state.login?.user?.profile?.permission,
  )

  const isExpiredSubscription = user?.subscription_info?.remaining_days < 0

  return { user, profile, permission, isExpiredSubscription }
}
