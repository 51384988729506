import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Grid, Container, Typography, Button } from "@mui/material"
import AnchorLink from "react-anchor-link-smooth-scroll"
import Page from "../components/Page"
import Images from "../assets/img/Images"
import LandingFooter from "../layouts/footer/LandingFooter/LandingFooter"
import { LandingPlans } from "src/sections/@landingPage/plansSect/index"
import plansInclude from "../_mock/plansInclude"
import { AppAbout, AppWidgetPlansInclude } from "../sections/@landingPage/app"
import useResponsive from "src/hooks/useResponsive"
import Loader from "src/components/Loader"
import CookieConsentBanner from "src/components/CookieConsentBanner"
// ----------------------------------------------------------------------

export default function LandingPage() {
  const xlUp = useResponsive("up", "xl")
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(true)

  const checkLogin = () => {
    const user = JSON.parse(localStorage.getItem("logged"))
    return !!user
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <Page title={t("TaglineTitle")}>
      <Loader respLoading={isLoading} />
      <Container
        maxWidth='xxl'
        className='bluehero relative'
        id='homeDiv'
        spacing={2}
      >
        <Container
          maxWidth={xlUp ? "xl" : "lg"}
          className='h-100 container1370'
        >
          <Grid
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
            className='h-100 hero-heading-row'
          >
            <Grid
              className='hero-heading h-100'
              item
              xs={12}
              sm={6}
              md={6}
              lg={5}
              xl={6}
            >
              <Typography variant='h1' sx={{ mb: 5 }}>
                {t("The Future Of Video Interviews")}
              </Typography>
              <Typography variant='h2' sx={{ mb: 3 }}>
                {t("A More Efficient Solution")}
              </Typography>
              <Typography className='sub-head' variant='body1' sx={{ mb: 4 }}>
                {t(
                  "You set the questions, the candidate interviews on their schedule, and you review the results on yours.",
                )}
              </Typography>

              {checkLogin() === false && (
                <Button
                  variant='contained'
                  size='large'
                  href='/signup'
                  sx={{
                    color: "rgb(44, 148, 255) !important",
                    maxWidth: "180px",
                    boxShadow: "none",
                    fontSize: 16,
                    lineHeight: 1.5,
                    fontWeight: 600,
                    backgroundColor: "#fff !important",
                    borderColor: "#fff !important",
                    transition: "all 150ms ease-in",
                    "&:hover": {
                      opacity: "0.7",
                    },
                    "&:focus": {
                      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
                    },
                  }}
                >
                  {t("GetStarted")}
                </Button>
              )}
            </Grid>

            <Grid
              className='img-banner'
              sx={{ mt: 5 }}
              item
              xs={12}
              sm={6}
              md={6}
              lg={7}
              xl={6}
            >
              <img alt='txt' src={Images.hero1} className='pc-image-mobile' />
            </Grid>
          </Grid>
          <img
            src={Images.largeImage}
            alt='large imge'
            className='fixed-banner'
          />
        </Container>
      </Container>
      <Container maxWidth='xxl' className='container-xbox'>
        <Container
          className='second-head container1370'
          id='aboutDiv'
          maxWidth={xlUp ? "xl" : "lg"}
        >
          <Grid container sx={{ mt: 3 }} spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6}>
              <Typography variant='h3'>
                {t("Better candidate experiences, smarter hiring decisions.")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                className='para'
                variant='body2'
                sx={{
                  paddingRight: { xs: 0, sm: 0, md: "98px" },
                  paddingLeft: { xs: 0, sm: 0, md: "34px" },
                }}
              >
                {t(
                  "IntVue empowers companies around the globe to streamline their entire recruitment workflow and find the best candidates for the job, faster!",
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 3 }} spacing={3}>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 1 }}>
              <AppAbout
                title={t("AboutSectionHd1")}
                content={t("AboutSectionTxt1")}
                icon={Images.interviewIcon}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 1 }}>
              <AppAbout
                title={t("AboutSectionHd2")}
                content={t("AboutSectionTxt2")}
                icon={Images.outline}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 1 }}>
              <AppAbout
                title={t("AboutSectionHd3")}
                content={t("AboutSectionTxt3")}
                icon={Images.lossIcon}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 1 }}>
              <AppAbout
                title={t("AboutSectionHd4")}
                content={t("AboutSectionTxt4")}
                icon={Images.mldicon}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 1 }}>
              <AppAbout
                title={t("AboutSectionHd5")}
                content={t("AboutSectionTxt5")}
                icon={Images.connectionIcon}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={4} sx={{ mb: 1 }}>
              <AppAbout
                title={t("AboutSectionHd6")}
                content={t("AboutSectionTxt6")}
                icon={Images.peaceIcon}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container
        maxWidth={xlUp ? "xl" : "lg"}
        className='container1370'
        id='featureDiv'
      >
        <Grid
          container
          spacing={3}
          textAlign='center'
          direction='column'
          sx={{ mt: 3 }}
        >
          <Grid item xs={12}>
            <Typography
              className='dashboard-head'
              variant='h2'
              sx={{ mb: 2, mt: 4 }}
            >
              <span className='font300'>{t("Incredible")}</span> {t("Features")}
            </Typography>
            <Typography
              variant='body2'
              component='p'
              sx={{ pb: { lg: 5, md: 2 } }}
            >
              {t("featureTagline")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          sx={{ pb: 3, mt: 1 }}
          justifyContent='center'
        >
          <Grid item xs={12} lg={7} xl={8} className='feature-banner'>
            <img
              alt='banner-side'
              className='img-fluid'
              src={Images.ImageBanner2}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            sx={{ pt: { sm: 0, md: 5 }, mt: { sm: 0, md: 5 } }}
            data-class-in=''
            data-class-out='block-right'
          >
            <Typography
              variant='h3'
              className='feature-head'
              sx={{ pb: 3, mt: 3 }}
            >
              {t("featureHd1")}
            </Typography>
            <Typography variant='body1' sx={{ pb: 4 }}>
              {" "}
              {t("featureTxt1")}
            </Typography>

            <Typography className='demo-btn'>
              <AnchorLink
                className='anchor-link'
                href='#contactDiv'
                offset={() => 100}
              >
                <Button
                  className='btn-default'
                  size='large'
                  variant='outlined'
                  sx={{
                    mb: 2,
                    color: "#000",
                    paddingRight: "35px",
                    paddingLeft: "35px",
                    borderColor: "#cccfd9",
                  }}
                >
                  {t("RequestDemoBtn")}
                </Button>
              </AnchorLink>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{ pb: 3, mt: { xs: 0, sm: 2 } }}
          alignItems=''
          justifyContent='center'
        >
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            sx={{
              pt: { xs: "0 !important", sm: 2, md: 5 },
              mt: { sm: 0, md: 5 },
            }}
            data-class-in=''
            data-class-out='block-left'
          >
            <Typography
              variant='h3'
              className=' feature-head'
              sx={{ pb: 3, mt: 3, xs: { mt: 0 } }}
            >
              {t("featureHd2")}
            </Typography>
            <Typography variant='body1' sx={{ pb: 4 }}>
              {t("featureTxt2")}
            </Typography>

            <Typography className='demo-btn'>
              <AnchorLink
                className='anchor-link'
                href='#contactDiv'
                offset={() => 100}
              >
                <Button
                  className='btn-default'
                  size='large'
                  variant='outlined'
                  sx={{
                    mb: 2,
                    color: "#000",
                    paddingRight: "35px",
                    paddingLeft: "35px",
                    borderColor: "#cccfd9",
                  }}
                >
                  {t("RequestDemoBtn")}
                </Button>
              </AnchorLink>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7} xl={8} className='col-md-pull-8'>
            <img
              alt='banner-side'
              className='img-fluid'
              src={Images.ImageBanner1}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{ pb: 3, mt: { xs: 0, sm: 0 } }}
          alignItems=' '
          justifyContent='center'
        >
          <Grid item xs={12} lg={7} xl={8} className='order-xs-12'>
            <img
              alt='banner-side'
              className='img-fluid'
              src={Images.ImageBanner3}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            sx={{ pt: { xs: 0, sm: 0, md: 5 }, mt: { xs: 0, sm: 0, md: 5 } }}
            data-class-in=''
            data-class-out='block-right'
          >
            <Typography
              variant='h3'
              className=' feature-head'
              sx={{ pb: 3, mt: 3 }}
            >
              {t("featureHd3")}
            </Typography>
            <Typography variant='body1' sx={{ pb: 4 }}>
              {t("featureTxt3")}
            </Typography>

            <Typography className='demo-btn'>
              <AnchorLink
                className='anchor-link'
                href='#contactDiv'
                offset={() => 100}
              >
                <Button
                  className='btn-default'
                  size='large'
                  variant='outlined'
                  sx={{
                    mb: 2,
                    color: "#000",
                    paddingRight: "35px",
                    paddingLeft: "35px",
                    borderColor: "#cccfd9",
                  }}
                >
                  {t("RequestDemoBtn")}
                </Button>
              </AnchorLink>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{ pb: 3 }}
          alignItems=''
          justifyContent='center'
        >
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            sx={{ pt: { xs: 1, sm: 0, md: 5 }, mt: { xs: 0, sm: 0, md: 5 } }}
            data-class-in=''
            data-class-out='block-left'
          >
            <Typography variant='h3' className=' feature-head' sx={{ pb: 3 }}>
              {t("featureHd4")}
            </Typography>
            <Typography variant='body1' sx={{ pb: 4 }}>
              {t("featureTxt4")}
            </Typography>

            <Typography className='demo-btn'>
              <AnchorLink
                className='anchor-link'
                href='#contactDiv'
                offset={() => 100}
              >
                <Button
                  className='btn-default'
                  size='large'
                  variant='outlined'
                  sx={{
                    mb: 2,
                    color: "#000",
                    paddingRight: "35px",
                    paddingLeft: "35px",
                    borderColor: "#cccfd9",
                  }}
                >
                  {t("RequestDemoBtn")}
                </Button>
              </AnchorLink>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7} xl={8} sx={{}} className=''>
            <img
              alt='banner-side'
              className='img-fluid'
              src={Images.ImageBanner4}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{ pb: 3 }}
          alignItems=' '
          justifyContent='center'
        >
          <Grid item xs={12} lg={7} xl={8} className='order-xs-12'>
            <img
              alt='banner-side'
              className='img-fluid'
              src={Images.ImageBanner5}
            />
          </Grid>

          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            sx={{ pt: { sm: 0, md: 5 }, mt: { sm: 0, md: 5 } }}
            className='order-xs-1'
            data-class-in=''
            data-class-out='block-right'
          >
            <Typography variant='h3' className=' feature-head' sx={{ pb: 3 }}>
              {t("featureHd5")}
            </Typography>

            <Typography variant='body1' sx={{ pb: 4 }}>
              {t("featureTxt5")}
            </Typography>

            <Typography className='demo-btn'>
              <AnchorLink
                className='anchor-link'
                href='#contactDiv'
                offset={() => 100}
              >
                <Button
                  className='btn-default'
                  size='large'
                  variant='outlined'
                  sx={{
                    mb: 2,
                    color: "#000",
                    paddingRight: "35px",
                    paddingLeft: "35px",
                    borderColor: "#cccfd9",
                  }}
                >
                  {t("RequestDemoBtn")}
                </Button>
              </AnchorLink>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          sx={{ pb: 3 }}
          alignItems=''
          justifyContent='center'
        >
          <Grid
            item
            xs={12}
            lg={5}
            xl={4}
            sx={{ pt: { sm: 0, md: 5 }, mt: { sm: 0, md: 5 } }}
            data-class-in=''
            data-class-out='block-left'
          >
            <Typography variant='h3' className=' feature-head' sx={{ pb: 3 }}>
              {t("featureHd6")}
            </Typography>
            <Typography variant='body1' sx={{ pb: 4 }}>
              {" "}
              {t("featureTxt6")}
            </Typography>

            <Typography className='demo-btn'>
              <AnchorLink
                className='anchor-link'
                href='#contactDiv'
                offset={() => 100}
              >
                <Button
                  className='btn-default'
                  size='large'
                  variant='outlined'
                  sx={{
                    mb: 2,
                    color: "#000",
                    paddingRight: "35px",
                    paddingLeft: "35px",
                    borderColor: "#cccfd9",
                  }}
                >
                  {t("RequestDemoBtn")}
                </Button>
              </AnchorLink>
            </Typography>
          </Grid>
          <Grid item xs={12} lg={7} xl={8} className=''>
            <img
              alt='banner-side'
              className='img-fluid'
              src={Images.ImageBanner6}
            />
          </Grid>
        </Grid>
      </Container>

      <Container
        maxWidth='xxl'
        className='sub-gradient pricing-blue-struct'
        id='pricingDiv'
      >
        <Container maxWidth={xlUp ? "xl" : "lg"} className='container1370'>
          <Grid
            container
            spacing={2}
            textAlign='center'
            alignItems='center'
            justifyContent='center'
          >
            <Grid item xs={12} sm={6} sx={{ pb: 2 }}>
              <Typography
                variant='h2'
                className='dashboard-head'
                sx={{ pt: 5 }}
              >
                <span className='font300'>{t("Flexible")}</span> {t("Plans")}
              </Typography>
              <Typography
                variant='h4'
                className='tagline'
                sx={{ fontWeight: "400" }}
              >
                {t("ChoosePlanForYou")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container
          maxWidth={xlUp ? "xl" : "lg"}
          className='container1370'
          spacing={2}
        >
          <LandingPlans />
        </Container>

        <Container
          maxWidth={xlUp ? "xl" : "lg"}
          className='container1370'
          spacing={3}
        >
          <Grid container alignItems='center' textAlign='center'>
            <Grid item xs={12}>
              <Typography
                className='secondary-heading'
                variant='h2'
                textAlign='center'
                sx={{ mt: 2, mb: 2 }}
              >
                <span className='font300'>{t("allPlan")} </span>
                {t("include")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            textAlign='center'
            sx={{
              pb: 3,
              mt: 4,
              direction: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {plansInclude?.map((post, index) => (
              <Grid item md={2} key={post.id}>
                <AppWidgetPlansInclude
                  post={post}
                  icon={post.cover}
                  title={t(post.pftitle)}
                  index={index}
                  iconHeight={post?.iconHeight}
                  iconWidth={post?.iconWidth}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
      <CookieConsentBanner isLandingPage={true} />
      <LandingFooter id='contactDiv' />
    </Page>
  )
}
