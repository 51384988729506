import React, { useState, useEffect } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import LoadingButton from "@mui/lab/LoadingButton"
import Images from "src/assets/img/Images"
import { createSubscription } from "src/redux/actions/subscription"
import { applyDiscountPlan } from "src/utils/userHelper"
import {
  Box,
  Button,
  Typography,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  Stack,
  TextField,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep"
import { useTranslation } from "react-i18next"
import { validateCoupon } from "src/redux/actions/paymentMethod"

export default function DowngradePlanModal({
  open,
  subscribedPlanId,
  planIdToSubscribe,
  currentSubscriptionExpiryDate,
  plans,
  onSubscribed,
  onClose,
}) {
  const dispatch = useDispatch()
  const [message, setMessage] = useState("")
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [discountPrice, setDiscountPrice] = useState(null)
  const [code, setCode] = useState("")
  const [appliedCode, setAppliedCode] = useState("")
  const [codeError, setCodeError] = useState("")

  const { t } = useTranslation()
  const subscriptionStatus = useSelector((state) => state.createSubscription)
  const validCoupon = useSelector((state) => state.validCoupon)

  useEffect(() => {
    setCode("")
    setMessage("")
    setCodeError("")
    resetDiscountFields()
  }, [open])
  useEffect(() => {
    if (isSubscribing) {
      setIsSubscribing(false)
      const failedMessage = t("ErrFailedSubscribe")

      if (subscriptionStatus?.subscription_info?.success) {
        if (onSubscribed) {
          onSubscribed(subscriptionStatus.subscription_info?.subscription)
        }
      } else {
        setMessage(failedMessage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionStatus])

  useEffect(() => {
    if (validCoupon.isValid) {
      let val = validCoupon?.data?.coupon?.percent_off
      applyDiscountPlan(val, getPriceOfPlanToSubscribe(), setDiscountPrice)
      setCodeError("")
      setAppliedCode(validCoupon?.data)
    } else if (validCoupon.isError) {
      resetDiscountFields()
      setCode("")
      setCodeError(validCoupon.error)
    }
  }, [validCoupon])

  const getTitleOfSubscribedPlan = () => {
    if (plans.length > 0) {
      return plans.find((p) => p.id === subscribedPlanId)?.name
    }
    return ""
  }

  const getTitleOfPlanToSubscribe = () => {
    if (plans.length > 0) {
      return plans.find((p) => p.id === planIdToSubscribe)?.name
    }
    return ""
  }

  const getPriceOfPlanToSubscribe = () => {
    if (plans.length > 0) {
      return plans.find((p) => p.id === planIdToSubscribe)?.price
    }
    return ""
  }

  const confirmButtonClicked = () => {
    if (planIdToSubscribe) {
      setMessage("")
      setIsSubscribing(true)
      let promo_code = {
        id: appliedCode?.id,
        coupon_id: appliedCode?.coupon?.id,
      }
      dispatch(
        createSubscription({
          plan_id: planIdToSubscribe,
          promo_code: promo_code,
        }),
      )
    }
  }

  const handleClose = () => {
    setMessage("")
    setIsSubscribing(false)
    resetDiscountFields()
    onClose()
  }

  const validateCode = () => {
    if (!code) {
      setCodeError("No Code Entered")
      return
    }

    const payload = {
      code: code,
      plan_id: planIdToSubscribe,
    }

    dispatch(validateCoupon(payload))
  }
  const resetDiscountFields = () => {
    setAppliedCode({})
    setDiscountPrice(null)
  }

  const handleClear = () => {
    setCode("")
    setCodeError("")
    resetDiscountFields()
  }

  return (
    <Dialog
      className='dialog400'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='md'
    >
      <DialogTitle className='dialog-title' sx={{ height: "2px", padding: 0 }}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
          disabled={isSubscribing}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className='dialog-content'
        dividers={true}
        sx={{ border: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "center", mb: 2, mt: 2 }}
              className='image-banner'
            >
              <img src={Images.DownGradeImg} alt='DownGradeImg' />
            </Box>
            <Typography
              variant='h4'
              component='h4'
              sx={{ textAlign: "center", fontWeight: 600, px: 2 }}
            >
              {t("DowngradingFromlbl")}{" "}
              <span className='blue-typo'>
                "{t(getTitleOfSubscribedPlan())}"{" "}
              </span>
              {t("to")}{" "}
              <span className='blue-typo'>
                "{t(getTitleOfPlanToSubscribe())}"
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert
              severity='warning'
              icon={false}
              sx={{
                justifyContent: "center",
                color: "rgb(106 64 59)",
                backgroundColor: "rgb(245 221 200 / 45%)",
                textAlign: "center",
                px: 5,
              }}
            >
              <Typography component='span'>
                {getTitleOfPlanToSubscribe() === "Basic Monthly"
                  ? t("SubMonthlyBasicAfterExpire")
                  : getTitleOfPlanToSubscribe() === "Pro Monthly"
                  ? t("SubProMonthlyAfterExpire")
                  : getTitleOfPlanToSubscribe() === "Pro Plus Monthly"
                  ? t("SubProPlusMonthlyAfterExpire")
                  : getTitleOfPlanToSubscribe() === "Basic Yearly"
                  ? t("SubBasicYearlyAfterExpire")
                  : getTitleOfPlanToSubscribe() === "Pro Yearly"
                  ? t("SubProYearlyAfterExpire")
                  : getTitleOfPlanToSubscribe() === "Pro Plus Yearly"
                  ? t("SubProPlusYearlyAfterExpire")
                  : null}
              </Typography>
              <Typography component='span' sx={{ mx: "0.4rem" }}>
                {getTitleOfSubscribedPlan() === "Basic Monthly"
                  ? t("CurrentBasicMonthlyExpireOn")
                  : getTitleOfSubscribedPlan() === "Pro Monthly"
                  ? t("CurrentProMonthlyExpireOn")
                  : getTitleOfSubscribedPlan() === "Pro Plus Monthly"
                  ? t("CurrentProPlusMonthlyExpireOn")
                  : getTitleOfSubscribedPlan() === "Basic Yearly"
                  ? t("CurrentBasicYearlyExpireOn")
                  : getTitleOfSubscribedPlan() === "Pro Yearly"
                  ? t("CurrentProYearlyExpireOn")
                  : getTitleOfSubscribedPlan() === "Pro Plus Yearly"
                  ? t("CurrentProPlusYearlyExpireOn")
                  : null}
                <strong>&nbsp;{currentSubscriptionExpiryDate}</strong>
              </Typography>
              <Stack
                direction='row'
                justifyContent='space-between'
                sx={{ my: 1 }}
              >
                <TextField
                  variant='outlined'
                  name='coupon-code'
                  label='Apply Coupon'
                  placeholder={t("Enter Code")}
                  size='small'
                  fullWidth
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value)
                    resetDiscountFields()
                  }}
                />
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  {" "}
                  <Button
                    variant='outlined'
                    sx={{ mx: 1 }}
                    onClick={validateCode}
                  >
                    {discountPrice !== null ? "Applied" : "Apply"}
                  </Button>
                  {discountPrice !== null && (
                    <DeleteSweepIcon
                      sx={{
                        cursor: "pointer",
                        color: "var(--primary)",
                      }}
                      onClick={handleClear}
                    />
                  )}
                </Stack>
              </Stack>
              {codeError && (
                <Typography
                  sx={{
                    marginTop: "5px",
                    color: "#FF4842 !important",
                    fontWeight: 400,
                    textAlign: "left",
                  }}
                >
                  {codeError}
                </Typography>
              )}
              {t("YouBeCharged")}:
              <Typography
                component='span'
                fontWeight='bold'
                sx={{
                  textDecoration:
                    discountPrice !== null ? "line-through" : "none",
                }}
              >
                {" $"}
                {getPriceOfPlanToSubscribe()}
              </Typography>
              {discountPrice !== null && (
                <Typography component='span' sx={{ mx: 1, fontWeight: 600 }}>
                  {" $"}
                  {discountPrice.toFixed(2)}
                </Typography>
              )}
            </Alert>
          </Grid>

          {message && (
            <Grid item xs={12}>
              <Alert variant='outlined' severity='error'>
                {message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        className='dialog-footer'
        sx={{ justifyContent: "center", pb: 2 }}
      >
        <Button
          onClick={handleClose}
          variant='outlined'
          color='secondary'
          sx={{
            mr: 1,
            backgroundColor: "transparent",
            borderColor: "#8b8e92",
            color: "#64748b",
            "&:hover": {
              opacity: "0.7",
              backgroundColor: "#8b8e92",
              borderColor: "#8b8e92",
              color: "#fff",
            },
          }}
          size='medium'
          disabled={isSubscribing}
        >
          {t("Cancel")}
        </Button>

        <LoadingButton
          onClick={confirmButtonClicked}
          loading={isSubscribing}
          variant='contained'
          color='info'
          size='medium'
        >
          {t("Confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
