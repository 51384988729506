import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import {
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
  Box,
  Typography,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { useFormik, Form, FormikProvider } from "formik"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import ConfirmationModal from "src/sections/@dashboard/roles/modal/ConfirmationModal"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { useLocalStorage } from "src/hooks/useLocalStorage"
import { deleteModuleData, resetSettingsFlag } from "src/redux/actions/general"

export default function DeleteAccount({ open, onClose }) {
  const [userKey] = useLocalStorage("key", "")

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const logged = useSelector((state) => state.login)
  const updateCompanyInfo = useSelector((state) => state.updateCompanyInfo)

  const [module, setModule] = useState("")
  const [message, setMessage] = useState("")
  const [val, setVal] = useState(new Date())
  const [moduleNo, setModuleNo] = useState(0)
  const [progress, setProgress] = useState(false)
  const [currentModule, setCurrentModule] = useState(0)
  const [needConfirm, setNeedConfirm] = useState(false)
  const [isAllTimeRadio, setAllTimeRadio] = useState(false)
  const [disabledValues, setDisabledValues] = useState(false)
  const [isSelectedDateRadio, setSelectedDateRadio] = useState(false)

  const [isShowingModal, setIsShowing] = useModal()

  const onDeletionCriteriaCahnge = (event) => {
    if (event.target.value === "alltime") {
      setAllTimeRadio(true)
      setSelectedDateRadio(false)
    } else {
      setSelectedDateRadio(true)
      setAllTimeRadio(false)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: () => {},
  })

  const { handleSubmit } = formik

  const handleClose = () => {
    setModule("")
    setModuleNo(0)
    setMessage("")
    setVal(new Date())
    setProgress(false)
    setCurrentModule(0)
    setNeedConfirm(false)
    setAllTimeRadio(false)
    setDisabledValues(false)
    setSelectedDateRadio(false)

    onClose()
  }

  const removeData = () => {
    setProgress(true)
    setCurrentModule(moduleNo)
    setDisabledValues(true)

    const newFormData = new FormData()
    newFormData.append("module", module)

    if (isSelectedDateRadio) newFormData.append("date", val)
    if (needConfirm) newFormData.append("confirmed", true)

    dispatch(deleteModuleData(userKey, newFormData))
  }
  const onKeyDown = (e) => {
    e.preventDefault()
  }

  const setModules = () => {
    // eslint-disable-next-line no-unused-vars
    let crrModule
    switch (moduleNo) {
      case -1:
        crrModule = 3
        break
      case 1:
        crrModule = 0
        break
      case 2:
        crrModule = 1
        break
      case 3:
        crrModule = 2
        break
      default:
        break
    }

    setModuleNo(crrModule)
    setCurrentModule(crrModule)
  }

  const dispMessage = () => {
    return `${message} Are you sure you want to erase ${module} data?`
  }

  useEffect(() => {
    if (updateCompanyInfo?.depPresent) {
      setNeedConfirm(true)
      setProgress(false)
      setMessage(updateCompanyInfo?.message)
      setModules()
      setIsShowing()
      dispatch(resetSettingsFlag())
    }
    if (updateCompanyInfo?.dataDeleted && !updateCompanyInfo?.depPresent) {
      setMessage("")
      setProgress(false)
      setNeedConfirm(false)
      dispatch(resetSettingsFlag())

      if (module === "vacancy") {
        handleClose()
      }
    }
  }, [updateCompanyInfo])
  return (
    <Dialog
      className='dialog800'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='xl'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        {t("Erase Data")}
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' dividers={true}>
        <FormikProvider value={formik}>
          <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <FormLabel id='demo-radio-buttons-group-label'>
                    {t("Erase criteria")}
                  </FormLabel>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    sx={{ mb: 2 }}
                  >
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      defaultValue='alltime'
                      name='radio-buttons-group'
                      row
                    >
                      <FormControlLabel
                        disabled={disabledValues}
                        value='alltime'
                        control={<Radio />}
                        checked={isAllTimeRadio}
                        onChange={onDeletionCriteriaCahnge}
                        label={t("All Time")}
                        sx={{ mb: { xs: 1, sm: "0 !important" } }}
                      />
                      <FormControlLabel
                        disabled={disabledValues}
                        value='selectedDate'
                        control={<Radio />}
                        checked={isSelectedDateRadio}
                        onChange={onDeletionCriteriaCahnge}
                        label={t("Erase data created before")}
                        sx={{ mb: { xs: 1, sm: "0 !important" } }}
                      />
                    </RadioGroup>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        disabled={disabledValues || !isSelectedDateRadio}
                        minDate={logged?.user?.account?.created_at}
                        disableFuture={true}
                        value={val}

                        onChange={(value) => {
                          setVal(value)
                        }}
                        renderInput={(params) => (
                          <TextField onKeyDown={onKeyDown} {...params} />
                        )}
                      />
                    </LocalizationProvider>

                  </Stack>
                </FormControl>
                <Box>
                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    sx={{ mb: 2 }}
                  >
                    <Typography variant='body1' sx={{ mb: 1 }}>
                      {t("Data associated with Interviews.")}
                    </Typography>
                    {(isAllTimeRadio || isSelectedDateRadio) &&
                      currentModule === 0 &&
                      !progress && (
                        <Button
                          variant='contained'
                          onClick={() => {
                            setIsShowing()
                            setModuleNo(1)
                            setModule("interview")
                          }}
                        >
                          {t("Erase")}
                        </Button>
                      )}
                  </Stack>

                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    sx={{ mb: 2 }}
                  >
                    <Typography variant='body1' sx={{ mb: 1 }}>
                      {t("Data associated with Candidates.")}
                    </Typography>
                    {currentModule === 1 && !progress && (
                      <Button
                        variant='contained'
                        onClick={() => {
                          setIsShowing()
                          setModuleNo(2)
                          setModule("candidate")
                        }}
                      >
                        {t("Erase")}
                      </Button>
                    )}
                  </Stack>

                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    sx={{ mb: 2 }}
                  >
                    <Typography variant='body1' sx={{ mb: 1 }}>
                      {t("Data associated with Question Sets.")}
                    </Typography>
                    {currentModule === 2 && !progress && (
                      <Button
                        variant='contained'
                        onClick={() => {
                          setIsShowing()
                          setModuleNo(3)
                          setModule("question")
                        }}
                      >
                        {t("Erase")}
                      </Button>
                    )}
                  </Stack>

                  <Stack
                    direction='row'
                    justifyContent='space-between'
                    sx={{ mb: 2 }}
                  >
                    <Typography variant='body1' sx={{ mb: 1 }}>
                      {t("Data associated with Vacancies.")}
                    </Typography>
                    {currentModule === 3 && !progress && (
                      <Button
                        variant='contained'
                        onClick={() => {
                          setIsShowing()
                          setModuleNo(-1)
                          setModule("vacancy")
                        }}
                      >
                        {t("Erase")}
                      </Button>
                    )}
                  </Stack>
                  {progress && (
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <LinearProgress />
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
        <ConfirmationModal
          isShowingModal={isShowingModal}
          hide={setIsShowing}
          message={dispMessage()}
          confirmAction={removeData}
        />
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button
          onClick={handleClose}
          variant='outlined'
          color='primary'
          size='medium'
        >
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
