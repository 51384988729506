import * as ActionTypes from "../constants/candidate"

const initialState = { data: [], message: "" }
export const candidateReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.FETCHCANDIDATES_ON_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  }
  return state
}

export const notHiredCandidateReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.FETCHNOTHIREDCANDIDATES_ON_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  }
  return state
}

export const candidateSkillsReducer = (state = { data: [] }, action) => {
  if (action.type === ActionTypes.CANDIDATESKILLS_ON_SUCCESS) {
    return {
      ...state,
      data: action.payload,
    }
  }

  return state
}
