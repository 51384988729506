import React, { useRef, useState, useEffect, useCallback } from "react"
import * as Yup from "yup"
import Snackbar from "@mui/material/Snackbar"
import {
  Stack,
  Button,
  MenuItem,
  useMediaQuery,
  Autocomplete,
  Chip,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  OutlinedInput,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import { useTheme } from "@mui/material/styles"
import { useFormik, Form, FormikProvider } from "formik"
import { CreateDeptModal } from "../../department"
import { useDispatch, useSelector } from "react-redux"
import { getAllDepartment } from "src/redux/actions/department"
import { getAllMembers, resetMembersFlag } from "src/redux/actions/member"
import { getAllProfiles } from "src/redux/actions/profile"
import {
  createRole,
  updateRole,
  getAllRoles,
  resetCreateRolesFlag,
} from "src/redux/actions/roles"
// import AddMemberModal from "../../user/modal/addMemberModal"
import MuiAlert from "@mui/material/Alert"
import { any } from "prop-types"
import currency from "src/_mock/currency"
import { useAuth } from "src/hooks/useAuth"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
//************************** */

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
const MenuPropsMd = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 170,
    },
  },
}
const MenuPropsXs = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 100,
    },
  },
}

const requiredSkills = [
  { name: "hr", value: "HR" },
  { name: "java", value: "JAVA" },
  { name: "csharp", value: "C#" },
  { name: "jscript", value: "JavaScript" },
  { name: "angular", value: "Angular" },
  { name: "reactjs", value: "ReactJs" },
  { name: "reactnative", value: "React Native" },
  { name: "designing", value: "Designing" },
]
const interviewLength = [
  { value: "10", name: "10" },
  { value: "15", name: "15" },
  { value: "20", name: "20" },
  { value: "25", name: "25" },
  { value: "30", name: "30" },
  { value: "35", name: "35" },
  { value: "40", name: "40" },
  { value: "45", name: "45" },
  { value: "50", name: "50" },
  { value: "55", name: "55" },
  { value: "60", name: "60" },
  { value: "75", name: "75" },
  { value: "80", name: "80" },
  { value: "85", name: "85" },
  { value: "90", name: "90" },
  { value: "95", name: "95" },
  { value: "100", name: "100" },
]

const expYear = Array.from({ length: 21 }, (_, index) => index)
/************** */
export default function AddRoleModal({
  isShowingRole,
  hide,
  open,
  flag,
  data,
}) {
  const { user } = useAuth()
  const { t } = useTranslation()
  const existingDept = useSelector((state) => state.departments)
  const userType = useSelector((state) => state.profile)
  const member = useSelector((state) => state.members)
  const existingRoles = useSelector((state) => state.roles)

  const [deptName, setDeptName] = useState("---")
  const [questName, setQuestName] = useState("---")
  const [repTo, setRepTo] = useState("---")

  const dispatch = useDispatch()
  const theme = useTheme()
  const [vacancySkills, setVacancySkills] = useState(
    requiredSkills.map((option) => option.value),
  )
  const [initialState, setInitialState] = useState({
    roleTitle: "",
    departments: "",
    lengthOfInterview: "",
    experienceReq: "",
    maxExperienceReq: "",
    reportTo: "",
    reqSkills: [],
    roleDescription: "",
    qualification: "",
    currency: "",
    salaryTenure: "",
    rangeFrom: 0,
    rangeTo: 0,
  })

  useEffect(() => {
    if (existingRoles?.created) {
      dispatch(getAllRoles())
      dispatch(resetCreateRolesFlag())
    }
  }, [existingRoles])

  useEffect(() => {
    if (member?.data?.length === 0) {
      dispatch(getAllMembers())
    }
  }, [member])

  useEffect(() => {
    if (userType?.data?.length === 0) {
      dispatch(getAllProfiles())
    }
  }, [userType])

  useEffect(() => {
    if (data) {
      setDeptName(data?.department?.name)
      setQuestName(data?.question_set?.name)
      setRepTo(data?.reports_to?.report_to)
      setDuration(data?.interview_time)
      setMinExp(data?.years_experience)
      setMaxExp(data?.max_experience)

      setCurrency(data?.salary_currency)
      setSalaryT(data?.salary_period)
      const currentVacSkills = data?.department.skills
      setVacancySkills(currentVacSkills)
      values.reqSkills = data?.skills ?? []
      setRepTo(data?.reports_to?.report_to)
      setInitialState({
        roleTitle: data?.title,
        departments: data?.department?.id,
        lengthOfInterview: data?.interview_time,
        experienceReq: data?.years_experience,
        maxExperienceReq: data?.max_experience,
        reportTo: data?.reports_to?.report_to_id,

        reqSkills: data?.skills ?? [],
        roleDescription: data?.description,
        qualification: data?.qualification,
        currency: data?.salary_currency,
        salaryTenure: data?.salary_period,
        rangeFrom: data?.salary_range_from,
        rangeTo: data?.salary_range_to,
      })
    }
  }, [data])

  const submission = (values, { resetForm }) => {
    const formData = {
      title: values?.roleTitle,
      department_id: values?.departments,
      report_to: values?.reportTo,
      years_experience: values?.experienceReq,
      max_experience: values?.maxExperienceReq,
      qualification: values?.qualification,
      salary_range_from: values?.rangeFrom,
      salary_range_to: values?.rangeTo,
      salary_currency: values?.currency,
      salary_period: values?.salaryTenure,
      description: values?.roleDescription,
      interview_time: values?.lengthOfInterview,
      skills: values?.reqSkills ?? [],
    }

    if (flag) {
      dispatch(createRole(formData))
    } else {
      dispatch(updateRole(data?.key, formData))
    }
    hide()
    resetForm({ values: "" })
    setDuration("---")
    setMinExp("---")
    setMaxExp("---")
    setDeptName("---")
    setRepTo("---")
    setQuestName("---")
    setCurrency("---")
    setSalaryT("---")
    setVacancySkills(requiredSkills.map((option) => option.value))
  }

  const addRoleSchema = (currentUser) =>
    Yup.object().shape({
      roleTitle: Yup.string()
        .matches(/^\S.*\S$/, t("ErrInputCantbspaces"))
        .required(t("ErrTitleRequired")),

      lengthOfInterview: Yup.string().required(t("ErrInterviewLengthRequired")),

      departments: Yup.string().required(t("DepartmentRequired")),
      reqSkills: Yup.array()
        .of(Yup.string())
        .min(1, t("ErrSkillsField1item"))
        .required(t("ErrSkillsRequired")),

      rangeFrom: Yup.number().when([], {
        is: () =>
          userLoggedPermissions?.user_can_view_vacancy_salary ||
          currentUser?.user_type === "admin",
        then: Yup.number()
          .required("Salary range from is required")
          .min(0, "Input value should not be negative.")
          .lessThan(Yup.ref("rangeTo"), t("ErrMinSalary")),
        otherwise: Yup.number().nullable(),
      }),

      experienceReq: Yup.number()
        .lessThan(
          Yup.ref("maxExperienceReq"),
          "Must be less than max experience",
        )
        .required(t("Required")),
      maxExperienceReq: Yup.number()
        .moreThan(
          Yup.ref("experienceReq"),
          "Must be greater than min experience",
        )
        .required(t("Required")),

      rangeTo: Yup.number().when([], {
        is: () =>
          userLoggedPermissions?.user_can_view_vacancy_salary ||
          currentUser?.user_type === "admin",
        then: Yup.number()
          .required(t("ErrSalaryRangeRequired"))
          .min(0, t("ErrValueNotNegative"))
          .moreThan(Yup.ref("rangeFrom"), t("ErrRangeToGreaterFrom")),
        otherwise: Yup.number().nullable(),
      }),
    })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: addRoleSchema(user),
    onSubmit: submission,
  })
  const {
    errors,
    touched,
    values,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
  } = formik

  /****************** */

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))
  const [receivers, setReceivers] = useState([])

  const [minExp, setMinExp] = useState("---")
  const [maxExp, setMaxExp] = useState("---")
  const salaryType = [
    { name: t("YearlyBasis"), value: "yearly" },
    { name: t("MonthlyBasis"), value: "monthly" },
    { name: t("WeeklyBasis"), value: "weekly" },
    { name: t("HourlyBasis"), value: "hourly" },
  ]
  const [currencySelected, setCurrency] = useState("---")
  const [salaryT, setSalaryT] = useState("---")
  const [durationSelected, setDuration] = useState("---")
  const members = useSelector((state) => state.members)

  const [displayCrash, setDisplayCrash] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorSeverity, setSeverity] = useState("")

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value)
    setFieldValue("currency", event.target.value)
  }

  const handleSalaryTypeChange = (event) => {
    setSalaryT(event.target.value)
    setFieldValue("salaryTenure", event.target.value)
  }

  const handleDurationChange = (event) => {
    setDuration(event.target.value)
    setFieldValue("lengthOfInterview", event.target.value)
  }
  const handleMinExpChange = (event) => {
    setMinExp(event.target.value)
    setFieldValue("experienceReq", event.target.value)
  }
  const handleMaxExpChange = (event) => {
    setMaxExp(event.target.value)
    setFieldValue("maxExperienceReq", event.target.value)
  }

  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
  })

  useEffect(() => {
    if (members?.isFailure) {
      setOpenSnackbar(true)
      setDisplayCrash(members?.error)
      setSeverity("error")
      dispatch(resetMembersFlag())
    }
    //  else {
    //   if (members?.isSucceed && isShowingMember) {
    //     toggleMember()
    //   }
    // }
  }, [members])

  const [isShowingDpt, toggleDpt] = useModal()
  // const [isShowingMember, toggleMember] = useModal()

  /******************* */

  const handleClose = () => {
    if (flag) {
      hide()
      setDuration("---")
      setMinExp("---")
      setMaxExp("---")
      setDeptName("---")
      setRepTo("---")
      setQuestName("---")
      setCurrency("---")
      setSalaryT("---")
      setVacancySkills(requiredSkills.map((option) => option.value))
      values.roleTitle = ""
      values.experienceReq = ""
      values.maxExperienceReq = ""
      values.rangeFrom = ""
      values.rangeTo = ""
      values.roleDescription = ""
      values.qualification = ""
      values.reqSkills = []
    } else {
      hide()
    }
    resetForm()
  }

  const logged = useSelector((state) => state.login)
  const userLoggedPermissions = logged?.user?.profile?.permission

  useEffect(() => {
    if (existingDept?.data?.length === 0) {
      dispatch(getAllDepartment())
    }
  }, [existingDept])

  const filterActiveMember = member?.data?.users?.filter(
    (memberActive) => memberActive.is_active === true,
  )

  const handleSkillsInputChange = (e, value) => {
    const isContainEmptyString = value.find((val) => val.trim() === "")
    if (!isContainEmptyString) {
      handleChange(e)
      values.reqSkills = value
      setFieldValue("reqSkills", value)
    }
    e.persist()
  }

  return (
    isShowingRole && (
      <Dialog
        className='dialog800'
        open={isShowingRole}
        onClose={handleClose}
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {flag ? t("AddNewVacancyBtn") : t("EditVacancy")}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <FormikProvider value={formik}>
            <Form
              id='addRole'
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel htmlFor='title' required>
                      {t("VacancyTitle")}
                    </FormLabel>
                    <TextField
                      fullWidth
                      type='text'
                      variant='outlined'
                      autoComplete='title'
                      placeholder={t("VacancyTitle")}
                      id='roleTitle'
                      name='roleTitle'
                      value={values.roleTitle}
                      onChange={handleChange}
                      aria-describedby='title-helper-text'
                      error={Boolean(touched.roleTitle && errors.roleTitle)}
                      helpertext={touched.roleTitle && errors.roleTitle}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("Department")}</FormLabel>
                    <Stack
                      className='input-adornment'
                      direction={{ xs: "column", sm: "column" }}
                    >
                      <Select
                        className='demo-select'
                        name='Users'
                        value={deptName}
                        input={<OutlinedInput />}
                        renderValue={(deptName) => {
                          return deptName
                        }}
                        MenuProps={MenuProps}
                        sx={{ textTransform: "none" }}
                        onChange={(e, value) => {
                          handleChange(e)
                          setDeptName(value.props.children)
                          const selectedDept =
                            existingDept?.data?.departments?.find(
                              (department) => department.id === e.target.value,
                            )
                          setVacancySkills(selectedDept.skills)
                          setFieldValue("departments", e.target.value)
                        }}
                        error={Boolean(
                          touched.departments && errors.departments,
                        )}
                        helpertext={touched.departments && errors.departments}
                      >
                        {existingDept?.data?.departments?.length > 0 && (
                          <MenuItem
                            sx={{
                              fontWeight: 600,
                              color: "#5082dd",
                              opacity: "1 !important",
                            }}
                            disabled
                            value=''
                          >
                            <em>{t("ChooseDepartment")}</em>
                          </MenuItem>
                        )}
                        {existingDept?.data?.departments?.length === 0 ? (
                          <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                        ) : (
                          existingDept?.data?.departments?.map((option) => (
                            <MenuItem
                              key={option.id}
                              value={option.id}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {option.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>

                      <Button
                        onClick={toggleDpt}
                        size='small'
                        variant='outlined'
                        color='primary'
                        sx={{
                          maxHeight: 38,
                          width: "fit-content",
                          fontWeight: 500,
                          mt: 2,
                          justifyContent: "center",
                          pl: 2,
                          pr: 2,
                        }}
                        startIcon={<AddIcon />}
                      >
                        {t("CreateNewDepartment")}
                      </Button>

                      {touched?.departments && errors?.departments && (
                        <p className='input-error'>{errors?.departments}</p>
                      )}
                      <CreateDeptModal
                        isShowingDpt={isShowingDpt}
                        hide={toggleDpt}
                        flag={true}
                      />
                    </Stack>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel htmlFor='yearsExperience'>
                      {t("ReportsTo")}
                    </FormLabel>

                    <Select
                      className='demo-select'
                      name='Users'
                      value={repTo}
                      input={<OutlinedInput />}
                      renderValue={(repTo) => {
                        return repTo?.split(":")[0]
                      }}
                      MenuProps={MenuProps}
                      sx={{ textTransform: "capitalize" }}
                      onChange={(e, value) => {
                        setRepTo(value.props.children)
                        setFieldValue("reportTo", e.target.value?.split(":")[1])
                      }}
                    >
                      {member?.data?.users?.length > 0 && (
                        <MenuItem
                          sx={{
                            fontWeight: 600,
                            color: "#5082dd",
                            opacity: "1 !important",
                          }}
                          disabled
                          value=''
                        >
                          <em>{t("SelectSupervisor")}</em>
                        </MenuItem>
                      )}
                      {member?.data?.users?.length === 0 ? (
                        <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                      ) : (
                        filterActiveMember?.map((option) => (
                          <MenuItem
                            key={option?.id}
                            value={option?.full_name + ":" + option?.id}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {option?.full_name}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {/* <Button
                      onClick={toggleMember}
                      size='small'
                      variant='outlined'
                      color='primary'
                      sx={{
                        maxHeight: 38,
                        width: "fit-content",
                        fontWeight: 500,
                        mt: 2,
                        justifyContent: "center",
                        pl: 2,
                        pr: 2,
                      }}
                      startIcon={<AddIcon />}
                    >
                      {t("CreateNewUser")}
                    </Button>

                    {isShowingMember && (
                      <AddMemberModal
                        departmentList={existingDept?.data?.departments}
                        isShowingMember={isShowingMember}
                        userType={userType?.data?.profiles}
                        hide={toggleMember}
                        flag={true}
                      />
                    )} */}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel htmlFor='lengthOfinterview' required>
                      {t("LengthInterview")}
                    </FormLabel>
                    <Stack direction='row' alignItems='center'>
                      <Select
                        className='demo-select'
                        name='lengthOfInterview'
                        id='lengthOfInterview'
                        value={durationSelected}
                        sx={{ width: "180px", textTransform: "capitalize" }}
                        onChange={(e) => {
                          handleChange(e)
                          handleDurationChange(e)
                        }}
                        input={<OutlinedInput />}
                        renderValue={(durationSelected) => {
                          return durationSelected
                        }}
                        MenuProps={MenuPropsMd}
                        inputProps={{ "aria-label": "Without label" }}
                        error={Boolean(
                          touched.lengthOfInterview && errors.lengthOfInterview,
                        )}
                        helpertext={
                          touched.lengthOfInterview && errors.lengthOfInterview
                        }
                      >
                        {interviewLength?.length > 0 && (
                          <MenuItem
                            sx={{
                              fontWeight: 600,
                              color: "#5082dd",
                              opacity: "1 !important",
                              whiteSpace: "break-spaces",
                            }}
                            disabled
                            value=''
                          >
                            <em>{t("SelectInterviewLength")}</em>
                          </MenuItem>
                        )}

                        {interviewLength.map((lenthInt) => (
                          <MenuItem
                            key={lenthInt.name}
                            value={lenthInt.name}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {t(lenthInt.value)}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        variant='body1'
                        sx={{
                          mx: "1rem !important ",
                        }}
                      >
                        {t("minutes")}
                      </Typography>
                    </Stack>

                    {touched?.lengthOfInterview &&
                      errors?.lengthOfInterview && (
                        <p className='input-error'>
                          {errors?.lengthOfInterview}
                        </p>
                      )}
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("MinExperienceRequired")}</FormLabel>
                    <Stack direction='row' alignItems='center'>
                      <Select
                        sx={{ width: "86px" }}
                        MenuProps={MenuPropsXs}
                        name='experienceReq'
                        value={minExp}
                        input={<OutlinedInput />}
                        renderValue={(minExp) => {
                          return minExp
                        }}
                        onChange={(e) => {
                          handleChange(e)
                          handleMinExpChange(e)
                        }}
                        error={touched.experienceReq && errors.experienceReq}
                      >
                        <MenuItem
                          sx={{
                            fontWeight: 600,
                            color: "#5082dd",
                            opacity: "1 !important",
                          }}
                          disabled
                          value=''
                        >
                          <em>{t("Select")}</em>
                        </MenuItem>
                        {expYear.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        variant='body1'
                        sx={{ mx: "1rem !important " }}
                      >
                        {t("year(s)")}
                      </Typography>
                    </Stack>

                    {touched?.experienceReq && errors?.experienceReq && (
                      <p className='input-error'>{errors?.experienceReq}</p>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel htmlFor='' required>
                      {t("MaxExperienceRequired")}
                    </FormLabel>
                    <Stack direction='row' alignItems='center'>
                      <Select
                        name='maxExperienceReq'
                        MenuProps={MenuPropsXs}
                        sx={{ width: "86px" }}
                        value={maxExp}
                        onChange={(e) => {
                          handleChange(e)
                          handleMaxExpChange(e)
                        }}
                        error={Boolean(
                          touched.maxExperienceReq && errors.maxExperienceReq,
                        )}
                      >
                        <MenuItem
                          sx={{
                            fontWeight: 600,
                            color: "#5082dd",
                            opacity: "1 !important",
                          }}
                          disabled
                          value=''
                        >
                          <em>{t("Select")}</em>
                        </MenuItem>
                        {expYear.map((year) => (
                          <MenuItem key={year} value={year}>
                            {year}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography
                        variant='body1'
                        sx={{ mx: "1rem !important " }}
                      >
                        {t("year(s)")}
                      </Typography>
                    </Stack>
                    {touched?.maxExperienceReq && errors?.maxExperienceReq && (
                      <p className='input-error'>{errors?.maxExperienceReq}</p>
                    )}
                  </FormControl>
                </Grid>

                <Snackbar
                  open={openSnackbar}
                  autoHideDuration={6000}
                  onClose={handleCloseSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert
                    onClose={handleCloseSnackbar}
                    severity={errorSeverity}
                    sx={{ width: "100%" }}
                  >
                    {displayCrash}
                  </Alert>
                </Snackbar>

                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel htmlFor='roleskills' required>
                      {t("EnterRequiredSkills")}
                    </FormLabel>
                    <Autocomplete
                      multiple
                      onChange={handleSkillsInputChange}
                      value={values.reqSkills}
                      options={vacancySkills}
                      variant='outlined'
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option}
                            sx={{ mx: 1 }}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            variant='outlined'
                            placeholder={t("TypeToAddSkills")}
                            error={Boolean(
                              touched.reqSkills && errors.reqSkills,
                            )}
                            helpertext={touched.reqSkills && errors.reqSkills}
                          />
                        )
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel>{t("JobDescription")}</FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={6}
                      variant='outlined'
                      autoComplete=''
                      placeholder={t("JobDescription")}
                      name='roleDescription'
                      {...getFieldProps("roleDescription")}
                      aria-describedby='name-helper-text'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel htmlFor=''>{t("Qualification")}</FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      variant='outlined'
                      autoComplete=''
                      placeholder={t("Qualification")}
                      name='qualification'
                      {...getFieldProps("qualification")}
                      aria-describedby='name-helper-text'
                    />
                  </FormControl>
                </Grid>

                {(userLoggedPermissions?.user_can_view_vacancy_salary ||
                  user?.user_type === "admin") && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel htmlFor=''>{t("Currency")}</FormLabel>
                        <Select
                          //displayEmpty
                          className='demo-select'
                          sx={{ textTransform: "capitalize" }}
                          value={currencySelected}
                          onChange={handleCurrencyChange}
                          input={<OutlinedInput />}
                          renderValue={(currencySelected) => {
                            return currencySelected
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {currency?.length > 0 && (
                            <MenuItem
                              sx={{
                                fontWeight: 600,
                                color: "#5082dd",
                                opacity: "1 !important",
                              }}
                              disabled
                              value=''
                            >
                              <em>{t("ChooseCurrency")}</em>
                            </MenuItem>
                          )}
                          {currency?.map((currencyItem, index) => (
                            <MenuItem
                              key={currencyItem.id}
                              value={
                                currencyItem.code +
                                " ( " +
                                currencyItem.symbol +
                                " ) "
                              }
                              sx={{ textTransform: "capitalize" }}
                            >
                              {currencyItem.code +
                                " ( " +
                                currencyItem.symbol +
                                " ) "}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel>{t("SalaryTenure")}</FormLabel>
                        <Select
                          className='demo-select'
                          displayEmpty
                          value={salaryT}
                          sx={{ textTransform: "capitalize" }}
                          onChange={handleSalaryTypeChange}
                          input={<OutlinedInput />}
                          renderValue={(salaryT) => {
                            return t(salaryT)
                          }}
                          MenuProps={MenuProps}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {salaryType?.length > 0 && (
                            <MenuItem
                              sx={{
                                fontWeight: 600,
                                color: "#5082dd",
                                opacity: "1 !important",
                              }}
                              disabled
                              value=''
                            >
                              <em>{t("ChooseTenure")}</em>
                            </MenuItem>
                          )}
                          {salaryType.map((names) => (
                            <MenuItem
                              key={names.name}
                              value={names.value}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {names.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel htmlFor='' required>
                          {t("MinSalary")}
                        </FormLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          autoComplete=''
                          placeholder={t("From")}
                          name='rangeFrom'
                          type='number'
                          id='rangeFrom'
                          value={values.rangeFrom || ""}
                          onChange={handleChange}
                          error={Boolean(touched.rangeFrom && errors.rangeFrom)}
                          helpertext={touched.rangeFrom && errors.rangeFrom}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel htmlFor='' required>
                          {t("MaxSalary")}
                        </FormLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          defaultValue='Default Value'
                          autoComplete=''
                          placeholder={t("To")}
                          name='rangeTo'
                          id='rangeTo'
                          type='number'
                          value={values.rangeTo || ""}
                          onChange={handleChange}
                          error={Boolean(touched.rangeTo && errors.rangeTo)}
                          helpertext={touched.rangeTo && errors.rangeTo}
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Cancel")}
          </Button>

          <Button
            onClick={handleSubmit}
            autoFocus
            variant='contained'
            color='secondary'
            size='medium'
          >
            {flag ? t("Save") : t("Update")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
