import React, { useState } from "react"
import TimezonePicker from "react-timezone"
import * as Yup from "yup"

import {
  Button,
  Box,
  MenuItem,
  Chip,
  TextField,
  IconButton,
  FormControlLabel,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Select,
  OutlinedInput,
  RadioGroup,
  Radio,
  Autocomplete,
  Typography,
} from "@mui/material"
import moment from "moment"
import AddIcon from "@mui/icons-material/Add"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch, useSelector } from "react-redux"
import { useTheme } from "@mui/material/styles"
import { useFormik, Form, FormikProvider } from "formik"
import "react-phone-input-2/lib/style.css"
import { fDate, fDateTime, fTime } from "src/utils/formatTime"
import { AddQuestionModal } from "src/sections/@dashboard/questions"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { TimePicker } from "@mui/x-date-pickers/TimePicker"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { createInterview, updateInterview } from "src/redux/actions/interviews"
import AddInterviewerModal from "../scheduleInterview/addInterviewer"
import MuiAlert from "@mui/material/Alert"
import { isEmpty } from "lodash"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import "moment-timezone"

const candidateSkillsArr = [
  { name: "hr", value: "HR" },
  { name: "java", value: "JAVA" },
  { name: "csharp", value: "C#" },
  { name: "jscript", value: "JavaScript" },
  { name: "angular", value: "Angular" },
  { name: "reactjs", value: "ReactJs" },
  { name: "reactnative", value: "React Native" },
  { name: "designing", value: "Designing" },
]

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

// ------------

export default function FollowUpIntModal({
  isShowingFollowupInt,
  hide,
  flag,
  data,
}) {
  const dateL = new Date()

  const { t } = useTranslation()
  const [isShowingInterviewer, toggleInterviewer] = useModal()
  const [isShowingQuestion, toggleQuestion] = useModal()
  const dispatch = useDispatch()
  const theme = useTheme()
  const [roleArr, setRoleArr] = useState(t("Select"))
  const [candidateArr, setCandidateArr] = useState("")
  const [candidates, setCandidates] = useState([])
  const [isRecordByAppRadio, setIsRecordByAppRadio] = useState(null)
  const [isFacetoFaceRadio, setIsFacetoFaceRadio] = useState(null)
  const [isVideoRadio, setIsVideoRadio] = useState(false)
  const [selectedQS, setSelectedQS] = useState(null)
  const [value, setValue] = useState(new Date())
  const [selectedRole, setSelectedRole] = useState(false)
  const [timezoneError, setTimezoneError] = useState("")
  const [deptName, setDeptName] = useState("")
  const [vacancySkills, setVacancySkills] = useState(
    candidateSkillsArr.map((option) => option.value),
  )

  const interviews = useSelector((state) => state.interview)
  const intVuerList = useSelector((state) => state.interviewer)
  const existingQuestionSets = useSelector((state) => state.questions)
  const roleList = useSelector((state) => state.roles)
  const logged = useSelector((state) => state.login)

  const [availableQS, setAvailableQS] = useState(
    existingQuestionSets?.data?.question_sets,
  )
  const [initialState, setInitialState] = useState({
    role: null,
    email: null,
    intvueFormate: null,
    questionSet: null,
    intvueExpiry: fDate(new Date()),
    intvueDate: fDate(new Date()),
    intvueTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    intvueTime: fDateTime(new Date()),
    timezone: null,
    intvueLocation: "",
    interviewers: [],
    candidateNote: "",
    intvueMeetingLink: "",
    candidateSkills: [],
  })

  const [error, setError] = useState("")
  const [candidateSkillsError, setCandidateSkillsError] = useState("")
  const [questionSetSelectedError, setQuestionSetSelectedError] = useState("")
  const [minTime, setMinTime] = useState(new Date())
  const [selectInterviewTypeError, setSelectInterviewTypeError] = useState("")
  const [selectInterviewerError, setSelectInterviewerError] = useState("")

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevationeo={6} ref={ref} variant='filled' {...props} />
  })

  useEffect(() => {
    if (existingQuestionSets?.data?.question_sets) {
      if (values.role !== "") {
        const selectedVacancy = roleList?.data?.vacancies?.find(
          (vacancy) => vacancy.id === values.role,
        )
        setAvailableQS(
          existingQuestionSets?.data?.question_sets?.filter(
            (QS) => QS.department_id === selectedVacancy?.department_id,
          ),
        )
      } else {
        setAvailableQS(existingQuestionSets.data.question_sets)
      }
    }
  }, [existingQuestionSets])

  useEffect(() => {
    if (data) {
      setRoleArr(data?.candidate_vacancy?.vacancy?.title)
      setSelected(
        data?.interviewers?.map(
          (item) => item?.user?.first_name + " " + item?.user?.last_name,
        ),
      )

      if (data?.interview_format === "recorded_by_applicant") {
        setIsRecordByAppRadio(true)
        setIsFacetoFaceRadio(null)
        //   setIsVideoRadio(null)
        setFieldValue("intvueFormate", "recorded_by_applicant")
        setFieldValue("intvueType", 0)
      } else if (data?.interview_format === "on_site") {
        setIsFacetoFaceRadio(true)
        setIsRecordByAppRadio(null)
        //  setIsVideoRadio(null)
        setFieldValue("intvueType", 1)
        setFieldValue("intvueFormate", "on_site")
      }
      // else if (data?.interview_format === "online_video_call") {
      //   setIsFacetoFaceRadio(null)
      //   setIsRecordByAppRadio(null)
      //   setIsVideoRadio(true)
      //   setFieldValue("intvueType", 2)
      //   setFieldValue("intvueFormate", "online_video_call")
      // }

      setTimeZone(data?.timezone)
      const quest = existingQuestionSets?.data?.question_sets.filter(
        (QS) =>
          QS.department_id === data?.candidate_vacancy?.vacancy.department_id,
      )

      const currentQS = quest.find(
        (q) => q.id === data?.interview_questions[0]?.question?.question_set_id,
      )
      setAvailableQS(quest)

      setFieldValue("intvueTimeZone", data?.timezone)
      setFieldValue("email", data?.candidate_vacancy?.candidate?.email)
      setFieldValue("intvueLocation", data?.interview_location ?? "")
      setFieldValue("questionSet", currentQS?.id)
      setSelectedQS(currentQS?.id)
      let name = `${data?.candidate_vacancy?.candidate?.first_name} ${data?.candidate_vacancy?.candidate?.last_name}`
      setCandidateArr(`${name}, ${data?.candidate_vacancy?.candidate?.email}`)
      setFieldValue("intvueMeetingLink", data?.interview_video_link)
      setDateTimeLimts(data?.timezone)
      const currentVacSkills =
        data?.candidate_vacancy?.vacancy.department.skills
      setVacancySkills(currentVacSkills)

      setInitialState({
        email: data?.candidate_vacancy?.candidate?.email,
        candidateSkills: data?.candidate_vacancy?.candidate?.skills,
        role: data?.candidate_vacancy?.vacancy?.id,
        status: data?.status,
        interviewers: data?.interviewers?.map((item) => item?.user?.full_name),
        intvueTimeZone: data?.timezone,
        intvueType: data?.interview_format,
        intvueDate: data?.interview_date,
        intvueTime: data?.interview_time
          ? data?.interview_time.split(".")[0]
          : data?.interview_time,
        intvueExpiry: data?.expires_on,
        intvueLocation: data?.interview_location ?? "",
        candidateNote: data?.message ?? "",
        intvueFormate: data?.interview_format,
        questionSet:
          data?.interview_questions[0]?.question?.question_set_id ?? "",
        intvueMeetingLink: data?.interview_video_link ?? "",
      })
    }
  }, [data])

  useEffect(() => {
    if (isShowingFollowupInt && flag !== false) {
      setIsRecordByAppRadio(true)
      setIsFacetoFaceRadio(false)
      setIsVideoRadio(false)
      setFieldValue("intvueFormate", "recorded_by_applicant")
      setFieldValue("intvueType", 0)
    }
  }, [isShowingFollowupInt])

  const getZoneDate = (timeZone) => {
    const dateTime = moment(new Date())
    return dateTime.clone().tz(timeZone)
  }

  const setDateTimeLimts = (timeZone) => {
    const dateTimeInTimeZone = getZoneDate(timeZone)
    const date = new Date(dateTimeInTimeZone.format("DD MMM yyyy"))

    setMinDate(date)
    setTimeLimit(values?.intvueDate, date)
  }

  const setTimeLimit = (date, minDate) => {
    if (
      moment(date).startOf("day").isSame(moment(minDate).startOf("day")) &&
      isFacetoFaceRadio
    ) {
      const dateTimeInTimeZone = getZoneDate(values?.intvueTimeZone)

      const time = dateTimeInTimeZone.format("HH:mm")
      const minTimeLimit = new Date()
      minTimeLimit.setHours(time.split(":")[0])
      minTimeLimit.setMinutes(time.split(":")[1])
      setMinTime(minTimeLimit)
    } else {
      setMinTime("")
    }
  }

  const handleFormSubmission = () => {
    setDateTimeLimts(values?.intvueTimeZone)
    handleSubmit()
  }

  const submission = (values, { resetForm }) => {
    let error = false
    if (isEmpty(values?.intvueTimeZone)) {
      setTimezoneError(t("ErrSelectIntTimezone"))
      error = true
    } else {
      setTimezoneError("")
    }
    if (
      values?.candidateSkills?.length === 0 ||
      values?.candidateSkills === null ||
      values?.candidateSkills === undefined
    ) {
      setCandidateSkillsError(t("ErrSelect1Skill"))
      error = true
    } else {
      setCandidateSkillsError("")
    }
    if (!isRecordByAppRadio && !isFacetoFaceRadio && !isVideoRadio) {
      error = true
      setSelectInterviewTypeError(t("ErrSelectintType"))
    } else {
      setSelectInterviewTypeError("")
    }

    if (selected.length === 0) {
      setSelectInterviewerError(t("SelectInterviewers"))
      error = true
    } else {
      setSelectInterviewerError("")
    }
    const [hours, minutes] = getZoneDate(values.intvueTimeZone)
      .format("HH:mm")
      .split(":")
      .map(Number)
    const intvueTime = fTime(values?.intvueTime).split(":").map(Number)
    const hoursDiff = intvueTime[0] - hours
    const minutesDiff = intvueTime[1] - minutes
    const formatedMinDate = new Date(Date.parse(minDate)).setHours(0, 0, 0, 0)
    const formatedExpiry = new Date(values?.intvueExpiry).setHours(0, 0, 0, 0)
    const formatedDate = new Date(values?.intvueDate).setHours(0, 0, 0, 0)
    if (
      (((((hoursDiff <= 0 && minutesDiff <= 0) ||
        (hoursDiff < 0 && minutesDiff >= 0)) &&
        formatedMinDate == formatedDate) ||
        formatedMinDate > formatedDate) &&
        isFacetoFaceRadio) ||
      (isRecordByAppRadio && formatedMinDate > formatedExpiry)
    ) {
      error = true
    }

    if (!error) {
      setTimezoneError("")
      setCandidateSkillsError("")
      let ids = []

      for (const interviewerName of values.interviewers) {
        for (const interviewer of intVuerList?.data?.interviewers || []) {
          if (interviewerName === interviewer?.full_name) {
            ids.push(interviewer?.id)
            break
          }
        }
      }

      const payload = new FormData()

      values.candidateSkills.forEach((value) =>
        payload.append("skills" + "[]", value),
      )
      payload.append("email", values.email)
      payload.append("vacancy_id", values.role)
      payload.append("interview_type", "follow_up")
      payload.append("interviewers", ids)
      payload.append("message", values.candidateNote)
      payload.append("interview_format", values.intvueFormate)
      payload.append("timezone", values.intvueTimeZone)
      payload.append("question_set_id", values.questionSet ?? "")

      if (
        values.intvueType === 0 ||
        values.intvueType === "recorded_by_applicant"
      ) {
        payload.append("expires_on", fDate(values.intvueExpiry))
      }
      if (values.intvueType === 1 || values.intvueType === "on_site") {
        const interviewTime =
          fDate(values.intvueDate) + " " + fTime(values.intvueTime)

        payload.append("interview_date", fDate(values.intvueDate))
        payload.append("interview_time", interviewTime)
        payload.append("interview_location", values.intvueLocation)
      }

      // if (
      //   values.intvueType === 2 ||
      //   values.intvueFormate === "online_video_call"
      // ) {
      //   const interviewTime =
      //     fDate(values.intvueDate) + " " + fTime(values.intvueTime)

      //   payload.append("interview_date", fDate(values.intvueDate))
      //   payload.append("interview_time", interviewTime)
      //   payload.append("interview_video_link", values.intvueMeetingLink)
      // }

      if (flag === false) {
        payload.append("key", data?.key)
        dispatch(updateInterview(payload, data?.key))
        hide()
        setError("")
      } else {
        hide()
        dispatch(createInterview(payload))
        setIsRecordByAppRadio(null)
        setIsFacetoFaceRadio(null)
        // setIsVideoRadio(null)
        resetForm({ values: "" })
        setRoleArr([])
        setCandidates([])
        setCandidateArr([])
        setSelected([])
        setError("")
        setCandidateSkillsError("")
        setQuestionSetSelectedError("")
        setSelectedQS(null)
        setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
        setValue(new Date())
        setAvailableQS(existingQuestionSets?.data?.question_sets)
        setVacancySkills(candidateSkillsArr.map((option) => option.value))
      }
    }
  }

  const followUpSchema = Yup.object().shape({
    role: Yup.string().required(t("ErrVacancyRequired")).nullable(),
    email: Yup.string().required(t("ErrCandidateRequired")).nullable(),
    intvueTimeZone: Yup.string().required(t("ErrSelectIntTimezone")),
    candidateSkills: Yup.array().of(Yup.string()).min(1, t("ErrSelect1Skill")),
    intvueType: Yup.string().required(t("SelectTypeInt")),
    questionSet: Yup.string().required(t("ErrSelectQS")).nullable(),
    intvueLocation: Yup.string()
      .nullable()
      .when("intvueType", {
        is: "1" || 1,
        then: Yup.string().required(t("ErrIntLocRequired")).trim().nullable(),
        otherwise: Yup.string().nullable(),
      }),
    candidateNote: Yup.string()
      .trim()
      .max(200, t("TooLong!"))
      .matches(/\w+(?:\s+\w+)+/g, t("TryWriteDecentWord"))
      .nullable(),
    interviewers: Yup.array().of(Yup.string()).min(1, t("IntRequired")),
    intvueMeetingLink: Yup.string()
      .nullable()
      .when("intvueType", {
        is: "2" || 2,
        then: Yup.string().required(t("ErrIntMeetingRequired")).nullable(),
        otherwise: Yup.string().nullable(),
      }),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: followUpSchema,
    onSubmit: submission,
  })
  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    resetForm,
  } = formik

  const existingDate = values.intvueDate
  const interviewDate = existingDate ? fDate(existingDate) : fDate(new Date())

  const existingExpiryDate = values.intvueExpiry

  const expiryDate = existingExpiryDate
    ? fDate(existingExpiryDate)
    : fDate(new Date())

  const onInterviewTypeChange = (event) => {
    setSelectInterviewTypeError("")

    if (event.target.value === "Recorded By Applicant") {
      setIsRecordByAppRadio(true)
      setIsFacetoFaceRadio(null)
      //  setIsVideoRadio(null)
      setFieldValue("intvueFormate", "recorded_by_applicant")
      setFieldValue("intvueType", 0)
      // setFieldValue("status", "pending");
    } else if (event.target.value === "On Site Face To Face") {
      setIsFacetoFaceRadio(true)
      setIsRecordByAppRadio(null)
      //  setIsVideoRadio(null)
      setFieldValue("intvueType", 1)
      // setFieldValue("status", "pending");
      setFieldValue("intvueFormate", "on_site")
    }
    // else if (event.target.value === "Video Call") {
    //   setIsFacetoFaceRadio(null)
    //   setIsRecordByAppRadio(null)
    //   setIsVideoRadio(true)
    //   setFieldValue("intvueType", 2)
    //   setFieldValue("intvueFormate", "online_video_call")
    // }
  }

  const [selectTimeZone, setTimeZone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )
  const [minDate, setMinDate] = useState(new Date())
  const [selected, setSelected] = useState([])
  const selectionChangeHandler = (event) => {
    setSelectInterviewerError("")
    setSelected(event.target.value)
    setFieldValue("interviewers", event.target.value)
  }

  const handleClose = () => {
    hide()
    setRoleArr(t("Select"))
    setCandidates([])
    setCandidateArr("")
    setSelected([])
    setError("")
    values.candidateNote = ""
    values.questionSet = null
    values.intvueLocation = ""
    values.intvueTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    values.candidateSkills = []
    values.role = null
    values.email = null
    setIsRecordByAppRadio(null)
    setIsFacetoFaceRadio(null)
    // setIsVideoRadio(null)
    setQuestionSetSelectedError("")
    setCandidateSkillsError("")
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
    setSelectedQS(null)
    setValue(new Date())
    setAvailableQS(existingQuestionSets?.data?.question_sets)
    setVacancySkills(candidateSkillsArr.map((option) => option.value))
    resetForm()
  }

  const onKeyDown = (e) => {
    e.preventDefault()
  }

  return (
    isShowingFollowupInt && (
      <Dialog
        className='dialog800'
        open={isShowingFollowupInt}
        onClose={handleClose}
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {t("ScheduleFollowUpInterview")}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <FormikProvider value={formik}>
            <Form
              id='scheduleInterview'
              autoComplete='off'
              noValidate
              onSubmit={handleFormSubmission}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("ErrSelectVacancy")} </FormLabel>
                    <Select
                      disabled={flag === false}
                      className='demo-select'
                      name='role'
                      value={roleArr}
                      MenuProps={MenuProps}
                      input={<OutlinedInput />}
                      renderValue={(roleArr) => {
                        return roleArr
                      }}
                      onChange={(e, value) => {
                        const selectedVacancy = roleList?.data?.vacancies.find(
                          (vacancy) => vacancy.id === e.target.value,
                        )
                        setFieldValue("email", "")
                        setCandidateArr([])
                        setRoleArr(value.props.children)
                        setFieldValue("role", e.target.value)
                        setDeptName(selectedVacancy?.department?.name)
                        const followup = interviews?.data?.interviews?.filter(
                          (candidates) =>
                            candidates?.interview_type === "follow_up",
                        )

                        let tempMail = [
                          ...interviews?.data?.interviews?.filter(
                            (candidates) =>
                              candidates?.candidate_vacancy?.vacancy?.title ===
                                value.props.children &&
                              ["completed", "partially_completed"].includes(
                                candidates?.status,
                              ) &&
                              !followup.some(
                                (cand) =>
                                  candidates?.candidate_vacancy?.candidate
                                    ?.email ===
                                  cand?.candidate_vacancy?.candidate?.email,
                              ),
                          ),
                        ]

                        let emails = [
                          ...new Set(
                            tempMail?.map((item) => {
                              return {
                                email:
                                  item?.candidate_vacancy?.candidate?.email,
                                name: `${item?.candidate_vacancy?.candidate?.first_name} ${item?.candidate_vacancy?.candidate?.last_name}`,
                              }
                            }),
                          ),
                        ]

                        setCandidates(emails)
                        setVacancySkills(selectedVacancy?.department?.skills)
                        setAvailableQS(
                          existingQuestionSets?.data?.question_sets?.filter(
                            (QS) =>
                              QS.department_id ===
                              selectedVacancy?.department_id,
                          ),
                        )

                        if (isRecordByAppRadio === true) {
                          setFieldValue("questionSet", "")
                          setSelectedQS(null)
                        }
                      }}
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                    >
                      {roleList?.data?.vacancies?.length !== 0 &&
                        roleList?.data?.vacancies?.filter(
                          (vacancy) => vacancy.status === "active",
                        ) && (
                          <MenuItem
                            sx={{
                              fontWeight: 600,
                              color: "#5082dd",
                              opacity: "1 !important",
                            }}
                            disabled
                          >
                            <em>{t("ChooseVacancy")}</em>
                          </MenuItem>
                        )}
                      {roleList?.data?.vacancies?.length === 0 ? (
                        <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                      ) : (
                        roleList?.data?.vacancies
                          ?.filter((vacancy) => vacancy.status === "active")
                          .map((option) => (
                            <MenuItem
                              disabled={
                                option.status === "active" ? false : true
                              }
                              key={option?.key}
                              value={option?.id}
                              sx={{ whiteSpace: "normal" }}
                            >
                              {option?.title}
                            </MenuItem>
                          ))
                      )}
                    </Select>
                    {touched?.role && errors?.role && (
                      <p className='input-error'>{errors?.role}</p>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("Candidate")}</FormLabel>
                    <Select
                      disabled={flag === false}
                      className='demo-select'
                      name='candidate'
                      MenuProps={MenuProps}
                      sx={{
                        ":first-letter": {
                          textTransform: "capitalize",
                        },
                      }}
                      value={candidateArr}
                      input={<OutlinedInput />}
                      renderValue={(candidateArr) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            <Chip
                              key={candidateArr}
                              label={candidateArr}
                              sx={{ mx: 1 }}
                            />
                          </Box>
                        )
                      }}
                      onChange={(e, value) => {
                        setCandidateArr(value.props.value)
                        setFieldValue("email", value.props.value.split(", ")[1])
                      }}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    >
                      {candidates?.length !== 0 && (
                        <MenuItem
                          sx={{
                            fontWeight: 600,
                            color: "#5082dd",
                            opacity: "1 !important",
                          }}
                          disabled
                        >
                          <em>{t("ChooseCandidate")}</em>
                        </MenuItem>
                      )}
                      {candidates?.length === 0 ? (
                        <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                      ) : (
                        candidates?.map((option) => (
                          <MenuItem
                            key={option.email}
                            value={`${option.name}, ${option.email}`}
                            sx={{ textTransform: "none", whiteSpace: "normal" }}
                          >
                            <Box>
                              <Typography
                                variant='subtitle1'
                                component='h6'
                                sx={{
                                  ml: 1,
                                  fontWeight: 600,
                                  textTransform: "capitalize",
                                }}
                              >
                                {option?.name}
                              </Typography>
                              <Typography
                                variant='subtitle2'
                                component='span'
                                sx={{ ml: 1, fontWeight: 400 }}
                              >
                                {option?.email}
                              </Typography>
                            </Box>
                          </MenuItem>
                        ))
                      )}
                    </Select>
                    {touched?.email && errors?.email && (
                      <p className='input-error'>{errors?.email}</p>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("InterviewType")}</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='candidateInterviewFormat'
                    >
                      <FormControlLabel
                        value='Recorded By Applicant'
                        onChange={onInterviewTypeChange}
                        control={<Radio />}
                        checked={isRecordByAppRadio}
                        label={t("RecordedbyApplicant")}
                      />
                      <FormControlLabel
                        value='On Site Face To Face'
                        onChange={onInterviewTypeChange}
                        control={<Radio />}
                        checked={isFacetoFaceRadio}
                        label={t("OnSite")}
                      />
                      {/* <FormControlLabel
                        value='Video Call'
                        onChange={onInterviewTypeChange}
                        control={<Radio />}
                        checked={isVideoRadio}
                        label={t("VideoCall")}
                        disabled
                      /> */}
                    </RadioGroup>
                  </FormControl>
                  {((errors.intvueType && touched.intvueType) ||
                    selectInterviewTypeError) && (
                    <Typography
                      variant='span'
                      sx={{
                        marginTop: "5px",
                        color: "#FF4842",
                        fontWeight: 400,
                        fontSize: "1rem",
                      }}
                    >
                      {errors.intvueType ?? selectInterviewTypeError}
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("SelectQuestionSet")}</FormLabel>
                    <Select
                      id='demo-simple-select'
                      name='question-sets'
                      value={selectedQS}
                      sx={{ textTransform: "capitalize" }}
                      MenuProps={MenuProps}
                      onChange={(e) => {
                        setSelectedQS(e.target.value)
                        setFieldValue("questionSet", e.target.value)
                        setQuestionSetSelectedError("")
                      }}
                      error={Boolean(touched.questionSet && errors.questionSet)}
                      helperText={touched.questionSet && errors.questionSet}
                    >
                      {availableQS?.length === 0 && (
                        <MenuItem disabled>{t("DataNotFound")}</MenuItem>
                      )}
                      {availableQS?.length > 0 && (
                        <MenuItem
                          sx={{
                            fontWeight: 600,
                            color: "#5082dd",
                            opacity: "1 !important",
                          }}
                          disabled
                          value=''
                        >
                          <em>{t("ChooseQuestionSet")}</em>
                        </MenuItem>
                      )}

                      {availableQS?.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          sx={{
                            textTransform: "capitalize",
                            whiteSpace: "normal",
                          }}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {logged?.user?.profile?.permission?.question_sets_add && (
                      <>
                        <Button
                          size='small'
                          variant='outlined'
                          color='primary'
                          sx={{
                            maxHeight: 38,
                            width: "fit-content",
                            fontWeight: 500,
                            mt: 2,
                            justifyContent: "center",
                            pl: 2,
                            pr: 2,
                          }}
                          onClick={toggleQuestion}
                          startIcon={<AddIcon />}
                        >
                          {t("CreateQuestionSet")}
                        </Button>
                        <AddQuestionModal
                          isShowingQuestion={isShowingQuestion}
                          hide={toggleQuestion}
                          flag={true}
                          depName={deptName}
                        />
                      </>
                    )}
                    {touched?.questionSet && errors?.questionSet && (
                      <p className='input-error'>{errors?.questionSet}</p>
                    )}
                  </FormControl>
                </Grid>
                {isRecordByAppRadio && (
                  <Grid item xs={12} sm={6}>
                    <FormControl sx={{ width: "100%" }}>
                      <FormLabel required>{t("IntLinkExpiryDate")}</FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          inputFormat='MM/dd/yyyy 24:00'
                          value={expiryDate ? expiryDate : value}
                          minDate={minDate}
                          timezone={selectTimeZone}
                          onChange={(val) => {
                            setValue(val)
                            setFieldValue("intvueExpiry", val)
                          }}
                          renderInput={(params) => (
                            <TextField onKeyDown={onKeyDown} {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("InterviewTimezone")}</FormLabel>
                    <TimezonePicker
                      value={selectTimeZone}
                      onChange={(time) => {
                        setTimeZone(time)
                        setFieldValue("intvueTimeZone", time)
                        setDateTimeLimts(time)
                      }}
                      className='select-container'
                    />
                  </FormControl>
                  {(errors.intvueTimeZone || timezoneError) && (
                    <Typography
                      variant='span'
                      sx={{
                        marginTop: "5px",
                        color: "#FF4842",
                        fontWeight: 400,
                        fontSize: "1rem",
                      }}
                    >
                      {errors.intvueTimeZone ?? timezoneError}
                    </Typography>
                  )}
                </Grid>

                {isFacetoFaceRadio && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel required>{t("InterviewDate")}</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            inputFormat='MM/dd/yyyy'
                            value={interviewDate ? interviewDate : value}
                            onChange={(val) => {
                              setValue(val)
                              setFieldValue("intvueDate", val)
                              setTimeLimit(val, minDate)
                            }}
                            timezone={selectTimeZone}
                            minDate={minDate}
                            renderInput={(params) => (
                              <TextField onKeyDown={onKeyDown} {...params} />
                            )}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel required>{t("InterviewTime")}</FormLabel>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <TimePicker
                            value={
                              values?.intvueTime
                                ? values?.intvueTime
                                : fDateTime(new Date())
                            }
                            format='HH:mm:ss'
                            autoOk={true}
                            onChange={(val) => {
                              setValue(val)
                              setFieldValue("intvueTime", val)
                            }}
                            renderInput={(params) => (
                              <TextField {...params} onKeyDown={onKeyDown} />
                            )}
                            minTime={minTime}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl sx={{ width: "100%" }}>
                        <FormLabel required>{t("InterviewLocation")}</FormLabel>
                        <TextField
                          fullWidth
                          variant='outlined'
                          name='interview_location'
                          placeholder=''
                          {...getFieldProps("intvueLocation")}
                          error={Boolean(
                            touched.intvueLocation && errors.intvueLocation,
                          )}
                          helperText={
                            touched.intvueLocation && errors.intvueLocation
                          }
                        />
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("SelectInterviewers")}</FormLabel>
                    <Select
                      multiple
                      sx={{ textTransform: "capitalize" }}
                      value={selected}
                      MenuProps={MenuProps}
                      onChange={selectionChangeHandler}
                      renderValue={(selected) => (
                        <Box sx={{ overflowX: "auto" }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} sx={{ mx: 1 }} />
                          ))}
                        </Box>
                      )}
                      error={Boolean(
                        touched.interviewers && errors.interviewers,
                      )}
                    >
                      {intVuerList?.data?.interviewers.length !== 0 && (
                        <MenuItem
                          sx={{
                            fontWeight: 600,
                            color: "#5082dd",
                            opacity: "1 !important",
                          }}
                          disabled
                        >
                          <em>{t("ChooseInterviewers")}</em>
                        </MenuItem>
                      )}

                      {intVuerList?.data?.interviewers?.map((option) => (
                        <MenuItem
                          sx={{
                            textTransform: "capitalize",
                            whiteSpace: "normal",
                          }}
                          key={option?.id}
                          value={option?.full_name}
                        >
                          {option?.full_name}
                        </MenuItem>
                      ))}
                    </Select>
                    {((errors.interviewers && touched.interviewers) ||
                      selectInterviewerError) && (
                      <Typography
                        variant='span'
                        sx={{
                          marginTop: "5px",
                          color: "#FF4842",
                          fontWeight: 400,
                          fontSize: "1rem",
                        }}
                      >
                        {errors.interviewers ?? selectInterviewerError}
                      </Typography>
                    )}
                    {/*
                        <Button
                          type="button"
                          variant="contained"
                          color="secondary"
                          size="small"
                          sx={{
                            maxHeight: 38,
                            width: "fit-content",
                            fontWeight: 500,
                            mt: 2,
                            justifyContent: "center",
                            pl: 2,
                            pr: 2,
                          }}
                          onClick={toggleInterviewer}
                        >
                          {" "}
                          Create Interviewer
                        </Button> */}
                    <AddInterviewerModal
                      isShowingInterviewer={isShowingInterviewer}
                      hide={toggleInterviewer}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel required>{t("CandidateSkills")}</FormLabel>
                    <Autocomplete
                      multiple
                      onChange={(e, value) => {
                        setCandidateSkillsError("")

                        const isContainEmptyString = value.find(
                          (val) => val.trim() === "",
                        )
                        if (!isContainEmptyString) {
                          handleChange(e)
                          values.candidateSkills = value
                          setFieldValue("candidateSkills", value)
                        }
                      }}
                      value={
                        values?.candidateSkills ? values?.candidateSkills : []
                      }
                      options={vacancySkills}
                      variant='outlined'
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant='outlined'
                            label={option}
                            sx={{ mx: 1 }}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          placeholder={t("TypeToAddSkills")}
                          error={Boolean(
                            touched?.candidateSkills && errors?.candidateSkills,
                          )}
                          helperText={
                            touched?.candidateSkills && errors?.candidateSkills
                          }
                        />
                      )}
                    />
                    {candidateSkillsError && (
                      <Typography
                        variant='span'
                        sx={{
                          marginTop: "5px",
                          color: "#FF4842",
                          fontWeight: 400,
                          fontSize: "1rem",
                        }}
                      >
                        {candidateSkillsError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel>{t("MessagetoCandidate")}</FormLabel>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      variant='outlined'
                      autoComplete=''
                      maxlength={200}
                      placeholder={t("MessagetoCandidate")}
                      name='candidateNote'
                      {...getFieldProps("candidateNote")}
                      error={Boolean(
                        touched.candidateNote && errors.candidateNote,
                      )}
                      helperText={touched.candidateNote && errors.candidateNote}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
          {error && (
            <Grid container sx={{ marginTop: "1vh" }}>
              <Grid item xs={8}></Grid>
              <Grid xs={4}>
                <Alert
                  severity='error'
                  variant='outlined'
                  sx={{ maxWidth: "200px" }}
                >
                  {error}
                </Alert>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleClose}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Cancel")}
          </Button>

          <Button
            onClick={handleFormSubmission}
            autoFocus
            type='submit'
            variant='contained'
            color='secondary'
            size='medium'
            // disabled={
            //   values.questionSet === null || questionSetSelectedError !== ""
            // }
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
