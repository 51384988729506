import * as ActionTypes from "../constants/handler";
const initialState = { isMic: false, isCamera: false, blob: null };
const handlerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MIC:
      return { ...state, isMic: action.payload };
    case ActionTypes.CAMERA:
      return { ...state, isCamera: action.payload };

    default:
      return state;
  }
};

export default handlerReducer;
