import * as ActionTypes from "../constants/roles"
const initialState = {
  data: [],
  message: "",
  error: "",
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  isSucceed: false,
  isCompleted: false,
  isArchived: false,
  isFailure: false,
  created: false,
}
export const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHROLES_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: true,
      }

    case ActionTypes.ARCHIVEDROLE_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        message: action.payload.message,
        isArchived: true,
      }

    case ActionTypes.CREATEROLE_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isCreated: true,
        created: true,
      }

    case ActionTypes.CREATEROLE_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      }

    case ActionTypes.UPDATEROLE_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isUpdated: true,
      }

    case ActionTypes.UPDATEROLE_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      }

    case ActionTypes.DELETEROLE_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isDeleted: true,
      }
    case ActionTypes.COMPLETEROLE_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isCompleted: true,
      }
    case ActionTypes.DELETE_SELECTED_ROLLS_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        message: action.payload.message,
        isArchived: true,
      }
    case ActionTypes.RESET_CREATED:
      return {
        ...state,
        created: false,
      }
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        message: "",
        isCreated: false,
        isUpdated: false,
        isDeleted: false,
        isSucceed: false,
        isCompleted: false,
        isArchived: false,
        isFailure: false,
      }
    default:
      return state
  }
}

export const rolesSkillsReducer = (state = { data: [] }, action) => {
  if (action.type === ActionTypes.ROLESKILLS_ON_SUCCESS) {
    return { ...state, data: action.payload }
  }
  return state
}
