import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/paymentMethod";

export const addCard = (data) => {
  const endpoint = "/api/admin/payment_methods";
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.ADDCARD_ON_SUCCESS,
    ActionTypes.ADDCARD_ON_FAILURE,
  ];
  return request(options);
};

export const RemoveCard = () => {
  const endpoint = "/api/admin/payment_methods/remove_card";
  const method = "DELETE";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.types = [
    ActionTypes.REMOVE_CARD_ON_SUCCESS,
    ActionTypes.REMOVE_CARD_ON_FAILURE,
  ];
  return request(options);
}

export const validateCoupon = (data) => {
  const endpoint = "/api/admin/payment_methods/validate_coupon";
  const method = "GET";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));
  const options = { action: endpoint, method: method, headers: headers };

  options.params = data
  options.types = [
    ActionTypes.VALIDATE_COUPON_ON_SUCCESS,
    ActionTypes.VALIDATE_COUPON_ON_FAILURE,
  ];
  return request(options);
}

export const resetCardFlag = () => {
  return { type: ActionTypes.RESET_FLAGS };
}
