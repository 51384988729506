const departmentArr = [
  {
    id: "dept01",
    key: "99vrk5ix",
    name: "Sample Department",
    description:
      "We offer experienced technical resources in an Extended Team Model to provide our clients the capacity they need to accelerate product development We offer experienced technical resources in an Extended Team Model to provide our clients the capacity they need to accelerate product development We offer experienced technical resources in an Extended Team Model to provide our clients the capacity they need to accelerate product development We offer experienced technical resources in an Extended Team Model to provide our clients the capacity they need to accelerate product development ",
    skills: [],
  },
]

export default departmentArr
