const questionSetArr = {
  id: 0,
  key: "ot36",
  name: "Sample Question Set",
  description: null,
  user_id: 1,
  department_id: 155,
  updated_at: "2023-08-22T10:21:06.300Z",
  total_questions: 6,
  department: {
    id: 1,
    key: "99vrk5ix",
    name: "Sample Department",
    description:
      "We offer experienced technical resources in an Extended Team Model to provide our clients the capacity they need to accelerate product development We offer experienced technical resources in an Extended Team Model to provide our clients the capacity they need to accelerate product development We offer experienced technical resources in an Extended Team Model to provide our clients the capacity they need to accelerate product development We offer experienced technical resources in an Extended Team Model to provide our clients the capacity they need to accelerate product development ",
    account_id: 1,
    deleted_at: null,
    skills: [],
  },

  questions: [
    {
      id: 326,
      key: "bz3mb2po",
      text: "Tell me about yourself.",
      choices: [
        "Contrary to popular belief, Lorem Ipsum is not simply random text",
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      ],
      question_type: "Multiple Choice",
      correct_answer:
        "Contrary to popular belief, Lorem Ipsum is not simply random text",
    },
    {
      id: 327,
      key: "j23179",
      text: "Why are you interested in this role?",
      choices: [],
      question_type: "Problem Solving",
      correct_answer: null,
    },

    {
      id: 708,
      key: "ipqu667j",
      text: "How many years you have been working?",
      choices: ["One", "Two", "Three", "Four"],
      question_type: "Multiple Choice",
      correct_answer: "Three",
    },
  ],
}

export default questionSetArr
