import * as ActionTypes from "../constants/signup";
import * as APICASE from "../constants/errorManagement";

const initialState = { data: [], signed: null, message: "", isCreated: false };
const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SIGNUP_ON_SUCCESS:
      const ob1 = {
        ...state,
        data: action.payload.data,
        isCreated: true,
        status: action.payload.status,
      };
      return ob1;
    case ActionTypes.SIGNUP_ON_FAILURE:
      const obj2 = {
        ...state,
        data: action.payload.data,
        message: action.payload.data.error,
      };
      return obj2;
    case APICASE.API_FAILURE:
      const obj3 = {
        status: action?.error?.response?.data?.success,
        message: action?.error?.response?.data?.errors,
      };
      return obj3;
    case ActionTypes.RESET_FLAGS:
      return { ...state, isCreated: false,
        data: [], status: action?.payload?.status };

    default:
      return state;
  }
};

export default signupReducer;
