import * as ActionTypes from "../constants/dashboard"

const initialState = { data: [], message: "" }
const dashboardReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.FETCHDASHBOARD_ON_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  }
  return state
}

export default dashboardReducer
