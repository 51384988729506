import { useState } from "react"
import { Outlet } from "react-router-dom"
import { styled } from "@mui/material/styles"
import XpageNavbar from "./XpageNavbar"
import XpageFooter from "./XpageFooter"

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  width: "100%",
  overflow: "hidden",
})
const RootStylePage = styled("div")({
  maxWidth: "100%",
  width: "100%",
})
const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "auto",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
  },
}))

// ----------------------------------------------------------------------

export default function XpageLayout() {
  const [open, setOpen] = useState(false)

  return (
    <RootStyle className='body-container'>
      <RootStylePage>
        <XpageNavbar onOpenSidebar={() => setOpen(!open)} />
        <MainStyle className='main-area'>
          <Outlet />
        </MainStyle>
        <XpageFooter />
      </RootStylePage>
    </RootStyle>
  )
}
