import * as ActionTypes from "../constants/stripe"
const initialState = { details: [], cardErr: [], isVerified: false }
const stripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CARD_DETAILS:
      state.details = action.payload
      return state
    case ActionTypes.CARD_ERROR:
      state.cardErr = action.payload
      return state
    case ActionTypes.CARD_VERIFIED:
      let obj = { ...state, isVerified: action.payload }
      return obj

    default:
      return state
  }
}

export default stripeReducer
