import { isError } from "lodash";
import * as ActionTypes from "../constants/paymentMethod";
import { isValid } from "date-fns";
const initialState = { data: [] };
const paymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADDCARD_ON_SUCCESS:
      return { ...state, cardAdded: true };
    case ActionTypes.ADDCARD_ON_FAILURE:
      return { ...state, cardAdded: false, error: action?.payload };

    default:
      return state;
  }
};

export const removeCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REMOVE_CARD_ON_SUCCESS:
      return { ...state, cardRemoved: true, message: action?.payload?.message };
    case ActionTypes.REMOVE_CARD_ON_FAILURE:
      return { ...state, cardRemoved: false, error: action?.payload };
    case ActionTypes.RESET_FLAGS:
      return  { ...state, cardRemoved: false }

    default:
      return state;
  }
}

export const validateCoupon = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.VALIDATE_COUPON_ON_SUCCESS:
      return { ...state, isValid: true, isError: false, data: action?.payload?.code };
    case ActionTypes.VALIDATE_COUPON_ON_FAILURE:
      return { ...state, isValid: false, isError: true, error: action?.payload?.errors };
    case ActionTypes.RESET_FLAGS:
      return  { ...state, isValid: false, isError: false }

    default:
      return state;
  }
}

export default paymentMethodReducer;
