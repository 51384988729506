import React from "react"
import PropTypes from "prop-types"
import SvgIcon from "@mui/material/SvgIcon"
import { Box } from "@mui/material"

Icons.propTypes = {
  icon: PropTypes.elementType.isRequired,
  sx: PropTypes.object,
}

export default function Icons({ icon: IconComponent, sx, ...other }) {
  return (
    <Box>
      <SvgIcon
        component={IconComponent}
        sx={{ fontSize: 24, ...sx }}
        {...other}
      />
    </Box>
  )
}
