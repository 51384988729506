import { LiveHeader } from ".."
import { styled } from "@mui/material/styles"
import { useCamera } from "src/hooks/useCamera"
import { useNavigate, useLocation } from "react-router-dom"
import { Box, Container, Button, Typography, Grid, Stack } from "@mui/material"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { updateInterviewStatus } from "src/redux/actions/interviews"
import { useDispatch } from "react-redux"
import { fDateTime, fDate } from "src/utils/formatTime"
import { useNetwork } from "src/hooks/useNetwork"

const RootStyle = styled("div")(({ theme }) => ({
  border: " 1px solid #e2e8f0",
  background: "white",
  boxSizing: "border-box",
  borderRadius: "10px",
}))

const Panel = styled("div")(({ theme }) => ({
  padding: "1.8rem 2.8rem 0 2.8rem ",
}))

export const QuestionBlock = styled("div")(({ theme }) => ({
  margin: "0 1.4rem -52px 1.4rem",
  padding: "1.05rem 2.36rem",
  background: "#F8FAFC",
  border: "1px solid #E5E7EB",
  borderRadius: "5px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "row",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}))

export default function StartScreen() {
  const navigate = useNavigate()
  const location = useLocation()
  const { camera, detected: cameraDetected } = useCamera()
  const dispatch = useDispatch()
  const { interview, isInterviewer } = location.state
  const splitScreen = false
  const isOnline = useNetwork()

  const startInterview = () => {
    if (splitScreen === true) {
      navigate("/live-interview/start", {
        replace: true,
        state: { interview: interview },
      })
    } else if (splitScreen === false) {
      dispatch(updateInterviewStatus(interview.code))
      navigate("/record-interview/start", {
        replace: true,
        state: { interview: interview },
      })
    }
  }

  const handleBack = () => {
    navigate("/candidate-interview-detail", {
      replace: true,
      state: {
        interview: interview,
        active: true,
        isInterviewer: isInterviewer,
      },
    })
  }

  const { expires_on: expiresOn, interview_time: createdAt } = interview

  return (
    <Container
      className='int-startup'
      maxWidth='md'
      spacing={3}
      sx={{
        minHeight: "calc(100vh - 100px )",
        pt: 2,
        pb: 2,
        boxSizing: "border-box",
      }}
    >
      <Box
        className='candidate-sc-logo'
        sx={{ justifyContent: "center", display: "flex" }}
      >
        <LiveHeader />
      </Box>
      <RootStyle>
        <Panel>
          <Stack
            direction='row'
            justifyContent='center'
            className='video-for-candidate'
          >
            {camera()}
          </Stack>

          <Typography
            variant='subtitle2'
            component='p'
            sx={{
              mb: 1,
              mt: 1,
              color: "#475569",
              lineHeight: "24px",
              fontSize: "16px !important",
              fontWeight: 400,
              wordBreak: "break-word",
            }}
          >
            {isInterviewer !== "true" ? (
              <>
                This interview expires at{" "}
                <strong>{fDate(expiresOn)} 24:00</strong>.
              </>
            ) : (
              <>
                This interview is scheduled for{" "}
                <strong>{fDateTime(createdAt)}</strong>.
              </>
            )}
            <br />
            By clicking start you consent to the recording of your voice and
            video during your interview
          </Typography>
        </Panel>

        <Box className='stepper-footer'>
          <Button
            color='inherit'
            onClick={handleBack}
            variant='contained'
            sx={{ mr: 1 }}
            startIcon={
              <ArrowBackIosNewIcon sx={{ fontSize: "14px !important" }} />
            }
          >
            Previous
          </Button>

          <Box sx={{ flex: "1 1 auto" }} />

          <Button
            variant='contained'
            onClick={startInterview}
            disabled={!cameraDetected || !isOnline}
            endIcon={
              <ArrowForwardIosIcon sx={{ fontSize: "14px !important" }} />
            }
          >
            Start Interview
          </Button>
        </Box>
      </RootStyle>
    </Container>
  )
}
