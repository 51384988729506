import * as ActionTypes from "../constants/questions";

const initialState = {
  data: [],
  message: "",
  error: "",
  isCreated: false,
  isUpdated: false,
  isDeleted: false,
  isSucceed: false,
  isFailure: false,
  created: false
};
const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.QUESTIONSETS_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: true,
      };

    case ActionTypes.CREATEQUESTIONSET_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isCreated: true,
        created: true
      };

    case ActionTypes.CREATEQUESTIONSET_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      };

    case ActionTypes.UPDATEQUESTIONSET_ON_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        isUpdated: true,
      };
    case ActionTypes.UPDATEQUESTIONSET_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      };

    case ActionTypes.DELETEQUESTIONSET_ON_SUCCESS:
      return { ...state, message: action.payload.message, isDeleted: true };
    case ActionTypes.DELETEQUESTIONSET_ON_FAILURE:
      return {
        ...state,
        error: action.payload.errors,
        isFailure: true,
      };

    case ActionTypes.RESET_CREATED:
      return {
        ...state,
        created: false
      }
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        message: "",
        error: "",
        isCreated: false,
        isUpdated: false,
        isDeleted: false,
        isSucceed: false,
        isFailure: false,
      };
    default:
      return state;
  }
};

export default questionReducer;
