import { faker } from "@faker-js/faker"

// ----------------------------------------------------------------------

const PLANSFEATURE_TITLES = [
  "Unlimited Question Sets",
  "Interactive Dashboard",
  "Feedback Management",
  "Company Branding",
  "Custom Email Messages",
  "Download Interviews",
]
const PF_IMAGES = [
  { iconName: "info-icon", icWidth: "41px", icHeight: "41px" },
  { iconName: "dashboard", icWidth: "37px", icHeight: "37px" },
  { iconName: "comment", icWidth: "41px", icHeight: "41px" },
  { iconName: "theme", icWidth: "41px", icHeight: "41px" },
  { iconName: "iconEmail", icWidth: "39px", icHeight: "30px" },
  { iconName: "icondownload", icWidth: "38px", icHeight: "41px" },
]

const plansInclude = [...Array(6)].map((_, index) => ({
  id: faker.datatype.uuid(),
  cover: `/static/mock-images/plans_iclude/${PF_IMAGES[index].iconName}.svg`,
  pftitle: PLANSFEATURE_TITLES[index],
  iconWidth: PF_IMAGES[index].icWidth,
  iconHeight: PF_IMAGES[index].icHeight,
}))

export default plansInclude
