export const ACTIVATEMEMBER_ON_SUCCESS = "ACTIVATEMEMBER_ON_SUCCESS";
export const ACTIVATEMEMBER_ON_FAILURE = "ACTIVATEMEMBER_ON_FAILURE";
export const FETCHMEMBERS_ON_SUCCESS = "FETCHMEMBERS_ON_SUCCESS";
export const FETCHMEMBERS_ON_FAILURE = "FETCHMEMBERS_ON_FAILURE";
export const CREATEMEMBER_ON_SUCCESS = "CREATEMEMBER_ON_SUCCESS";
export const CREATEMEMBER_ON_FAILURE = "CREATEMEMBER_ON_FAILURE";
export const DELETEMEMBER_ON_SUCCESS = "DELETEMEMBER_ON_SUCCESS";
export const DELETEMEMBER_ON_FAILURE = "DELETEMEMBER_ON_FAILURE";
export const EDITEMEMBER_ON_SUCCESS = "EDITEMEMBER_ON_SUCCESS";
export const EDITEMEMBER_ON_FAILURE = "EDITEMEMBER_ON_FAILURE";
export const RESET_FLAGS = "RESET_FLAGS";
export const MEMBER_PRIVILEGES = "MEMBER_PRIVILEGES";
export const INVITEMEMBER_ON_SUCCESS = "INVITEMEMBER_ON_SUCCESS";
export const INVITEMEMBER_ON_FAILURE = "INVITEMEMBER_ON_FAILURE";
export const EDITPROFILE_ON_SUCCESS = "EDITPROFILE_ON_SUCCESS";
export const EDITPROFILE_ON_FAILURE = "EDITPROFILE_ON_FAILURE";
