import { combineReducers } from "redux"
import departmentReducer, { createDepartmentReducer } from "./department"
import questionReducer from "./questions"
import { loginReducer, languageReducer } from "./login"
import signupReducer from "./signup"
import dashboardReducer from "./dashboard"
import userQuestionReducer from "./userQuestion"
import {
  interviewReducer,
  getInterviewDetailReducer,
  finishInterviewReducer,
  getInterviewFeedbackReducer,
  addInterviewCommentReducer,
  viewQuestionReducer,
  deleteQuestionReducer,
  editCommentReducer,
  candidateHistoryReducer,
  updateIntFeedbackReducer,
  onsiteIntFeedbackReducer,
  flagIntQuestionReducer,
  onsiteIntStatusReducer,
  filteredInterviewReducer,
  getRecommendationsReducer,
} from "./interviews"
import interviewersReducer from "./interviewer"
import flaggedQuestionReducer from "./flagQuestions"
import { rolesReducer, rolesSkillsReducer } from "./roles"
import accountConfigReducer from "./accountConfig"
import invoiceReducer from "./invoices"
import profileReducer from "./profile"
import paymentMethodReducer, { removeCardReducer, validateCoupon } from "./paymentMethod"
import membersReducer from "./member"
import {
  candidateReducer,
  notHiredCandidateReducer,
  candidateSkillsReducer,
} from "./candidate"
import userTypeReducer from "./userType"
import stripeReducer from "./stripe"
import landingReducer from "./landing"
import { getCompanyInfoReducer, updateCompanyInfoReducer } from "./general"
import {
  activateBillingReducer,
  subscriptionReducer,
  getPlansReducer,
  getSubscriptionInfoReducer,
  createSubscriptionReducer,
  cancelSubscriptionReducer,
  buyInterviewsReducer,
} from "./subscription"
import containerReducer, { awaitFeedbackReducer } from "./container"
import handlerReducer from "./handler"
import resetPasswordReducer from "./resetPassword"
import updatePasswordReducer from "./updatePassword"
import errorsReducer from "./errorManagement"
import addMemberReducer from "./addMember"
import teamReducer from "./team"
import userNeedHelpReducer from "./needHelp"
import loadingReducer from "./loading"

const rootReducer = combineReducers({
  plans: subscriptionReducer,
  departments: departmentReducer,
  questions: questionReducer,
  login: loginReducer,
  signup: signupReducer,

  dashboard: dashboardReducer,
  userQuestion: userQuestionReducer,
  interview: interviewReducer,
  filteredInterview: filteredInterviewReducer,
  getInterviewDetail: getInterviewDetailReducer,
  finishInterview: finishInterviewReducer,
  interviewer: interviewersReducer,
  flagTheQuestion: flaggedQuestionReducer,
  roles: rolesReducer,
  accountConfigurations: accountConfigReducer,
  invoice: invoiceReducer,
  profile: profileReducer,
  addCard: paymentMethodReducer,
  removeCard: removeCardReducer,
  members: membersReducer,
  userType: userTypeReducer,
  cardBucket: stripeReducer,
  landing: landingReducer,
  candidateDetails: candidateReducer,
  notHiredCandidates: notHiredCandidateReducer,
  getCompanyInfo: getCompanyInfoReducer,
  updateCompanyInfo: updateCompanyInfoReducer,
  activateBilling: activateBillingReducer,
  plansList: getPlansReducer,
  subscriptionInfo: getSubscriptionInfoReducer,
  createSubscription: createSubscriptionReducer,
  cancelSubscription: cancelSubscriptionReducer,
  buyInterviews: buyInterviewsReducer,
  container: containerReducer,
  resetPassword: resetPasswordReducer,
  updatePassword: updatePasswordReducer,
  handler: handlerReducer,
  addMember: addMemberReducer,
  getIntFeedback: getInterviewFeedbackReducer,
  addIntComment: addInterviewCommentReducer,
  viewIntQuestion: viewQuestionReducer,
  deleteIntQuestion: deleteQuestionReducer,
  editIntComment: editCommentReducer,
  viewCandidateHistory: candidateHistoryReducer,
  team: teamReducer,
  updateIntFeedback: updateIntFeedbackReducer,
  onsiteIntFeedbackData: onsiteIntFeedbackReducer,
  crashManagement: errorsReducer,
  loadingManagement: loadingReducer,
  helpNeeded: userNeedHelpReducer,
  createDepart: createDepartmentReducer,
  onsiteIntStatus: onsiteIntStatusReducer,
  flagInt: flagIntQuestionReducer,
  language: languageReducer,
  skills: rolesSkillsReducer,
  candidateSkills: candidateSkillsReducer,
  recommendations: getRecommendationsReducer,
  validCoupon: validateCoupon,
})

export default rootReducer
