import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import _ from "lodash"
import { Link as RouterLink } from "react-router-dom"
import {
  Card,
  Box,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tab,
  Checkbox,
} from "@mui/material"

import PropTypes from "prop-types"
import Snackbar from "@mui/material/Snackbar"
import Alert from "src/components/Alert"
import Page from "../components/Page"
import Scrollbar from "../components/Scrollbar"
import AddIcon from "@mui/icons-material/Add"
import SearchNotFound from "../components/SearchNotFound"
import {
  RolesListHead,
  RolesMoreMenu,
  AddRoleModal,
  RolesListToolbar,
} from "../sections/@dashboard/roles"
import { roleParsing } from "../utils/dataParsing"
import { useDispatch, useSelector } from "react-redux"
import {
  getAllRoles,
  resetRolesFlag,
  roleSkills,
} from "src/redux/actions/roles"
import { resetErrors } from "src/redux/actions/resetError"
import {
  getAllDepartment,
  resetDepartmentFlag,
} from "src/redux/actions/department"
import {
  getAllQuestionSet,
  resetQuestionsFlag,
} from "src/redux/actions/questions"
import { styled, useTheme } from "@mui/material/styles"
import Label from "src/components/Label"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import PulseLoader from "react-spinners/PulseLoader"
import { getAllMembers } from "src/redux/actions/member"
import vacanciesArr from "src/_mock/vacancies"
import { resetConfigurationsFlag } from "src/redux/actions/accountConfig"
import Tabs, { tabsClasses } from "@mui/material/Tabs"
import useMediaQuery from "@mui/material/useMediaQuery"

// ----------------------------------------------------------------------
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const TABLE_HEAD = (t) => [
  { id: "code", label: t("Sr#"), alignRight: false },
  { id: "name", label: t("Title"), alignRight: false },
  { id: "department", label: t("Department"), alignRight: false },
  { id: "reportsTo", label: t("ReportsTo"), alignRight: false },
  {
    id: "totalExperience",
    label: t("Experience"),
    alignRight: false,
  },
  {
    id: "hiredCandidate",
    label: t("HiredCandidate"),
    alignRight: false,
  },
  { id: "", label: t("Action"), alignRight: true },
]

//-----------------------------------------

const tabs = [
  { label: "Open Vacancies" },
  { label: "Closed Vacancies" },
  { label: "Archived Vacancies" },
]

function TabPanel(props) {
  const { children, value, index, tabs, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 3, paddingBottom: 3, pl: 0, pr: 0 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

function getStyles(name, departmentName, theme) {
  return {
    fontWeight:
      departmentName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export default function Roles(props) {
  const dispatch = useDispatch()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const [roleList, setRole] = useState([])
  const [displayCrash, setDisplayCrash] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorSeverity, setSeverity] = useState("")
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("asc")
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState("title")
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [selectedItemIndex, setSelectedItemIndex] = useState(null)
  const { t } = useTranslation()
  const [valuetab, setValuetab] = useState(0)
  const [filters, setFilters] = useState(null)
  const [isShowingRole, toggleRole] = useModal()

  const createDepartment = useSelector((state) => state.createDepart)
  const quest = useSelector((state) => state.questions)
  const checkCrash = useSelector((state) => state.crashManagement)
  const existingRoles = useSelector((state) => state.roles)
  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission
  const [firstRender, setFirstRender] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const members = useSelector((state) => state.members)
  const skills = useSelector((state) => state.skills)
  const config = useSelector((state) => state.accountConfigurations)

  let creationInfo = JSON.parse(sessionStorage.getItem("creation_info"))
  const [vacancyShows, setVacancyShows] = useState(
    creationInfo?.vacancy ?? false,
  )

  const handleItemClick = (index) => {
    setSelectedItemIndex(index === selectedItemIndex ? null : index)
  }
  const [openFilter, setOpenFilter] = useState(false)
  const handleOpenFilter = () => {
    setOpenFilter(true)
  }
  const handleCloseFilter = () => {
    setOpenFilter(false)
  }

  const applyFilter = (filter = null) => {
    setFilters(filter)
    setRowsPerPage(5)
    setPage(0)
    dispatch(getAllRoles(renderFlag(valuetab), filter))
    setIsLoading(true)
  }

  useEffect(() => {
    if (skills?.data?.length === 0) {
      dispatch(roleSkills())
    }
  }, [skills])

  useEffect(() => {
    if (members?.data?.length === 0) {
      dispatch(getAllMembers())
    }
  }, [members])

  useEffect(() => {
    if (createDepartment?.data?.lenght === 0) {
      dispatch(getAllDepartment())
    }
    if (createDepartment?.isCreated) {
      setOpenSnackbar(true)
      setDisplayCrash(createDepartment?.message)
      setSeverity("success")
      dispatch(resetDepartmentFlag())
    }
    if (createDepartment?.isFailure && createDepartment?.error) {
      setOpenSnackbar(true)
      setDisplayCrash(createDepartment?.error)
      setSeverity("error")
      dispatch(resetDepartmentFlag())
    }
  }, [createDepartment])

  useEffect(() => {
    if (existingRoles?.data?.length === 0 || firstRender) {
      setFirstRender(false)
      setIsLoading(true)
      dispatch(getAllRoles(renderFlag(valuetab)))
    } else {
      if (existingRoles?.isCreated) {
        dispatch(getAllRoles(renderFlag(valuetab), filters))
        setOpenSnackbar(true)

        setDisplayCrash(existingRoles?.message)
        setSeverity("success")
        dispatch(resetRolesFlag())
      }

      if (existingRoles?.isUpdated) {
        dispatch(getAllRoles(renderFlag(valuetab), filters))
        setOpenSnackbar(true)

        setDisplayCrash(existingRoles?.message)
        setSeverity("success")
        dispatch(resetRolesFlag())
      }
      if (existingRoles?.isCompleted) {
        dispatch(getAllRoles(renderFlag(valuetab), filters))
        setOpenSnackbar(true)

        setDisplayCrash(existingRoles?.message)
        setSeverity("success")
        dispatch(resetRolesFlag())
      }

      if (existingRoles?.isArchived || existingRoles?.isDeleted) {
        dispatch(getAllRoles(renderFlag(valuetab), filters))
        setOpenSnackbar(true)

        setDisplayCrash(existingRoles?.message)
        setSeverity("success")
        dispatch(resetRolesFlag())
      }

      if (existingRoles?.isFailure && existingRoles?.error) {
        setOpenSnackbar(true)
        setDisplayCrash(existingRoles?.error)
        setSeverity("error")
        dispatch(resetRolesFlag())
      }

      if (existingRoles?.data?.success) {
        if (existingRoles?.data?.vacancies === undefined) {
          // console.log("do nothing")
        } else {
          if (valuetab === 0 && vacancyShows !== true) {
            setRole([
              ...roleParsing(existingRoles?.data?.vacancies),
              vacanciesArr,
            ])
          } else {
            setRole(roleParsing(existingRoles?.data?.vacancies))
          }
        }
        setIsLoading(false)
      }
    }
  }, [existingRoles, vacancyShows])

  useEffect(() => {
    if (
      checkCrash?.url?.split("/")?.includes("vacancies") ||
      checkCrash?.url?.split("/")?.includes("candidates")
    ) {
      if (checkCrash?.succeed === false) {
        setOpenSnackbar(true)
        setDisplayCrash(checkCrash.message)
        setSeverity("error")

        dispatch(resetErrors())
      }
    }
  }, [checkCrash])

  useEffect(() => {
    if (config?.edited) {
      setVacancyShows(true)
      setSeverity("success")
      setOpenSnackbar(true)
      setDisplayCrash(config.message)
      dispatch(resetConfigurationsFlag())
    }
  }, [config])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = roleList?.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = [...selected, id]
    } else {
      newSelected = selected.filter((selectedId) => selectedId !== id)
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [searchName, setSearchName] = useState("")

  const handleFilterByName = (event) => {
    setFilterName(event)
    setPage(0)
  }

  const handleFilterSearch = (event) => {
    setSearchName(event.target.value)
    setPage(0)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - roleList?.length) : 0

  const isUserNotFound = roleList?.length === 0

  const handleChangetab = (event, newValue) => {
    if (!isLoading) {
      setPage(0)
      setValuetab(newValue)
      setIsLoading(true)
      setSelected([])
      dispatch(getAllRoles(renderFlag(newValue), filters))
    }
  }

  const uniqueDept = Array.from(
    new Set(roleList?.map((item) => item?.rolesDepartmentName)),
  )

  const renderFlag = (param) => {
    switch (param) {
      case 0:
        return "active"
      case 1:
        return "completed"
      case 2:
        return "archived"
      default:
    }
  }
  const handlePagination = (value) => {
    return roleList?.filter(
      (item) =>
        item?.title?.toLowerCase()?.includes(searchName?.toLowerCase()) ||
        item?.reportTo?.toLowerCase()?.includes(searchName?.toLowerCase()),
    ).length
  }

  const refreshRole = () => {
    setIsLoading(true)
    dispatch(getAllRoles(renderFlag(valuetab), filters))
  }

  const getCurrentTabVacanciesCount = () => {
    let status = "active"

    if (valuetab === 0) {
      status = "active"
    } else if (valuetab === 1) {
      status = "completed"
    } else if (valuetab === 2) {
      status = "archived"
    }
    if (roleList) {
      return roleList.filter((role) => role?.status === status).length
    }
    return 0
  }

  const matchedRole = (id) => {
    for (let i = 0; i < existingRoles?.data?.vacancies?.length; i++) {
      if (id === existingRoles?.data?.vacancies[i]?.id) {
        return existingRoles?.data?.vacancies[i]
      }
    }
  }

  ///----------------------------
  return (
    <Page title={t("Vacancies")} className='roles-page'>
      <Container maxWidth='xl' spacing={3}>
        {userPermissions?.vacancy_module ? (
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              sx={{ mb: { xs: 2, sm: 4, md: 5 } }}
            >
              <Typography
                variant='h5'
                sx={{
                  mb: 0,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  color: "#434e58",
                }}
              >
                {t("Vacancies")}:&nbsp;
                {isLoading ? 0 : handlePagination(valuetab)}
              </Typography>

              {userPermissions?.vacancy_add_new_vacancy && (
                <Button
                  onClick={toggleRole}
                  variant='contained'
                  component={RouterLink}
                  className='add-btn'
                  to='#'
                  startIcon={<AddIcon />}
                >
                  {t("AddNewVacancyBtn")}
                </Button>
              )}

              <AddRoleModal
                isShowingRole={isShowingRole}
                hide={toggleRole}
                flag={true}
              />
            </Stack>

            <Snackbar
              open={openSnackbar}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleCloseSnackbar}
                severity={errorSeverity}
                sx={{ width: "100%" }}
              >
                {displayCrash}
              </Alert>
            </Snackbar>

            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  flexGrow: 1,
                  maxWidth: { xs: 320, sm: 480, md: "100%" },
                }}
              >
                <Tabs
                  value={valuetab}
                  onChange={handleChangetab}
                  aria-label='basic tabs'
                  variant='scrollable'
                  scrollButtons='auto'
                  allowScrollButtonsMobile
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      "&.Mui-disabled": { opacity: 0.3 },
                    },
                  }}
                >
                  {logged?.user?.profile?.permission?.vacancy_module && (
                    <Tab label={t("OpenVacancy")} {...a11yProps(0)} />
                  )}
                  {logged?.user?.profile?.permission
                    ?.vacancy_completed_vacancy_tab && (
                    <Tab label={t("ClosedVacancy")} {...a11yProps(1)} />
                  )}
                  {logged?.user?.profile?.permission
                    ?.vacancy_archived_vacancy_tab && (
                    <Tab label={t("ArchivedVacancy")} {...a11yProps(2)} />
                  )}
                </Tabs>
              </Box>

              <TabPanel>
                <Card>
                  <RolesListToolbar
                    numSelected={selected?.length}
                    roleList={selected}
                    resetSelected={setSelected}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                    searchName={searchName}
                    onFilterSearch={handleFilterSearch}
                    uniqueDept={uniqueDept}
                    refreshRole={refreshRole}
                    canSearch={userPermissions?.vacancy_search_bar}
                    canFilterDept={
                      userPermissions?.vacancy_departments_dropdown_filter
                    }
                    openFilter={openFilter}
                    setOpenFilter={setOpenFilter}
                    handleOpenFilter={handleOpenFilter}
                    handleCloseFilter={handleCloseFilter}
                    setRoles={applyFilter}
                  />

                  <Scrollbar>
                    <TableContainer
                      sx={{ minWidth: isSmallScreen ? "100%" : 800 }}
                    >
                      <Table className='simple-table' stickyHeader>
                        <RolesListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD(t)}
                          rowCount={roleList?.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />

                        {!isLoading && (
                          <TableBody>
                            {roleList
                              ?.filter(
                                (item) =>
                                  item?.title
                                    ?.toLowerCase()
                                    ?.includes(searchName?.toLowerCase()) ||
                                  item?.reportTo
                                    ?.toLowerCase()
                                    ?.includes(searchName?.toLowerCase()),
                              )
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage,
                              )
                              .map((row, index) => {
                                const {
                                  id,
                                  title,
                                  rolesDepartmentName,
                                  reportTo,
                                  yearsExperience,
                                  maxExperience,
                                  hired,
                                  code,
                                } = row

                                const isItemSelected =
                                  selected.indexOf(id) !== -1

                                const firstFiveHiredCandidates = _.slice(
                                  hired,
                                  0,
                                  5,
                                )
                                const restOfHiredCandidates = _.slice(hired, 5)

                                return row?.id === "001v" ? (
                                  valuetab === 0 && (
                                    <TableRow
                                      hover
                                      key={id + "-" + code}
                                      tabIndex={-1}
                                      role='checkbox'
                                      selected={isItemSelected}
                                      aria-checked={isItemSelected}
                                    >
                                      <TableCell padding='checkbox'>
                                        <Checkbox
                                          checked={isItemSelected}
                                          onChange={(event) =>
                                            handleClick(event, id)
                                          }
                                        />
                                      </TableCell>
                                      <TableCell
                                        align='left'
                                        sx={{ whiteSpace: "nowrap" }}
                                      >
                                        {code}
                                      </TableCell>
                                      <TableCell
                                        component='th'
                                        scope='row'
                                        padding='none'
                                      >
                                        <Stack
                                          direction='row'
                                          alignItems='center'
                                          spacing={2}
                                        >
                                          <Typography
                                            variant='subtitle2'
                                            sx={{
                                              textTransform: "capitalize",
                                            }}
                                            noWrap
                                          >
                                            {title}
                                          </Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell align='left'>
                                        {row.id === "001v"
                                          ? row?.department?.name
                                          : rolesDepartmentName}
                                      </TableCell>
                                      <TableCell align='left'>
                                        {row.id === "001v"
                                          ? row?.reports_to?.report_to
                                          : reportTo}
                                      </TableCell>
                                      <TableCell align='left'>
                                        {row.id === "001v"
                                          ? row?.years_experience +
                                            " - " +
                                            row?.max_experience
                                          : yearsExperience +
                                            (maxExperience &&
                                              " - " + maxExperience)}
                                        &nbsp;{t("years")}
                                      </TableCell>

                                      <TableCell align='left'>
                                        {row.id === "001v" && (
                                          <Label
                                            variant='ghost'
                                            color='success'
                                          >
                                            {t("inProgress")}
                                          </Label>
                                        )}
                                      </TableCell>

                                      <TableCell
                                        align='right'
                                        className='stickyCell'
                                        width='8%'
                                        sx={{ width: "150px" }}
                                      >
                                        <RolesMoreMenu
                                          disabled={selected?.length > 0}
                                          id={id}
                                          flag={renderFlag(valuetab)}
                                          match={
                                            matchedRole(id) ?? vacanciesArr
                                          }
                                          isSample={vacanciesArr.id === id}
                                          canViewInterviewModule={
                                            userPermissions?.interviews_module
                                          }
                                          canViewCompletedInterview={
                                            userPermissions?.vacancy_active_vacancy_view_interviews
                                          }
                                          canViewCandidateModule={
                                            userPermissions?.candidates_module
                                          }
                                          canViewCandidateActiveVac={
                                            userPermissions?.vacancy_active_vacancy_view_candidates
                                          }
                                          canViewCandidateCompletedVac={
                                            userPermissions?.vacancy_completed_vacancy_view_candidates
                                          }
                                          canArchiveCompleted={
                                            userPermissions?.vacancy_completed_vacancy_archive_vacancy
                                          }
                                          canCloseOpenVac={
                                            userPermissions?.vacancy_active_vacancy_complete_vacancy
                                          }
                                          canDeleteArchiveVac={
                                            userPermissions?.vacancy_archive_vacancy_delete_vacancy
                                          }
                                          canEditOpenVac={
                                            userPermissions?.vacancy_active_vacancy_edit_vacancy
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                                ) : (
                                  <TableRow
                                    hover
                                    key={id + "-" + code}
                                    tabIndex={-1}
                                    role='checkbox'
                                    selected={isItemSelected}
                                    aria-checked={isItemSelected}
                                  >
                                    <TableCell padding='checkbox'>
                                      <Checkbox
                                        checked={isItemSelected}
                                        onChange={(event) =>
                                          handleClick(event, id)
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      align='left'
                                      sx={{ whiteSpace: "nowrap" }}
                                    >
                                      {code}
                                    </TableCell>
                                    <TableCell
                                      component='th'
                                      scope='row'
                                      padding='none'
                                    >
                                      <Stack
                                        direction='row'
                                        alignItems='center'
                                        spacing={2}
                                      >
                                        <Typography
                                          variant='subtitle2'
                                          sx={{ textTransform: "capitalize" }}
                                          noWrap
                                        >
                                          {title}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell align='left'>
                                      {row.id === "001v"
                                        ? row?.department?.name
                                        : rolesDepartmentName}
                                    </TableCell>
                                    <TableCell align='left'>
                                      {row.id === "001v"
                                        ? row?.reports_to?.report_to
                                        : reportTo}
                                    </TableCell>
                                    <TableCell align='left'>
                                      {row.id === "001v"
                                        ? row?.years_experience +
                                          " - " +
                                          row?.max_experience
                                        : yearsExperience +
                                          (maxExperience &&
                                            " - " + maxExperience)}
                                      &nbsp;{t("years")}
                                    </TableCell>
                                    <TableCell align='left'>
                                      {row.id === "001v" ? (
                                        <Label variant='ghost' color='success'>
                                          {t("inProgress")}
                                        </Label>
                                      ) : (
                                        <div className='mulitpleDpt'>
                                          {hired?.length > 0 ? (
                                            firstFiveHiredCandidates?.map(
                                              (item) => (
                                                <Typography
                                                  key={
                                                    item.id +
                                                    "_" +
                                                    item.candidate_id
                                                  }
                                                  sx={{
                                                    fontWeight: 400,
                                                    marginRight: "6px",
                                                    textTransform: "capitalize",
                                                  }}
                                                  component='span'
                                                  variant='subtitle2'
                                                >
                                                  {item?.candidate?.first_name +
                                                    " " +
                                                    item?.candidate?.last_name}
                                                </Typography>
                                              ),
                                            )
                                          ) : valuetab === 0 ? (
                                            <Label
                                              variant='ghost'
                                              color='success'
                                            >
                                              {t("inProgress")}
                                            </Label>
                                          ) : (
                                            <Label
                                              variant='ghost'
                                              color='error'
                                            >
                                              {t("Hiringclosed-unengaged")}
                                            </Label>
                                          )}
                                          {index === selectedItemIndex &&
                                            restOfHiredCandidates?.map(
                                              (item) => (
                                                <Typography
                                                  key={
                                                    item.id +
                                                    "_" +
                                                    item.candidate_id
                                                  }
                                                  sx={{
                                                    fontWeight: 400,
                                                    marginRight: "6px",
                                                    textTransform: "capitalize",
                                                  }}
                                                  component='span'
                                                  variant='subtitle2'
                                                >
                                                  {item?.candidate?.first_name +
                                                    " " +
                                                    item?.candidate?.last_name}
                                                </Typography>
                                              ),
                                            )}
                                          {hired.length > 5 && (
                                            <Button
                                              variant='outlined'
                                              sx={{ mt: 1 }}
                                              color='info'
                                              size='small'
                                              onClick={() => {
                                                handleItemClick(index)
                                              }}
                                            >
                                              {index === selectedItemIndex
                                                ? t("ViewLess")
                                                : t("ViewMore")}
                                            </Button>
                                          )}
                                        </div>
                                      )}
                                    </TableCell>

                                    <TableCell
                                      align='right'
                                      className='stickyCell'
                                      width='8%'
                                      sx={{ width: "150px" }}
                                    >
                                      {userPermissions?.vacancy_active_vacancy_view_interviews ||
                                      userPermissions?.vacancy_active_vacancy_view_candidates ||
                                      userPermissions?.vacancy_active_vacancy_complete_vacancy ||
                                      userPermissions?.vacancy_active_vacancy_edit_vacancy ||
                                      userPermissions?.vacancy_completed_vacancy_archive_vacancy ||
                                      userPermissions?.candidates_module ||
                                      userPermissions?.vacancy_completed_vacancy_view_candidates ? (
                                        <RolesMoreMenu
                                          disabled={selected?.length > 0}
                                          id={id}
                                          flag={renderFlag(valuetab)}
                                          match={
                                            matchedRole(id) ?? vacanciesArr
                                          }
                                          canViewInterviewModule={
                                            userPermissions?.interviews_module
                                          }
                                          canViewCompletedInterview={
                                            userPermissions?.vacancy_active_vacancy_view_interviews
                                          }
                                          canViewCandidateModule={
                                            userPermissions?.candidates_module
                                          }
                                          canViewCandidateActiveVac={
                                            userPermissions?.vacancy_active_vacancy_view_candidates
                                          }
                                          canViewCandidateCompletedVac={
                                            userPermissions?.vacancy_completed_vacancy_view_candidates
                                          }
                                          canArchiveCompleted={
                                            userPermissions?.vacancy_completed_vacancy_archive_vacancy
                                          }
                                          canCloseOpenVac={
                                            userPermissions?.vacancy_active_vacancy_complete_vacancy
                                          }
                                          canDeleteArchiveVac={
                                            userPermissions?.vacancy_archive_vacancy_delete_vacancy
                                          }
                                          canEditOpenVac={
                                            userPermissions?.vacancy_active_vacancy_edit_vacancy
                                          }
                                        />
                                      ) : null}
                                    </TableCell>
                                  </TableRow>
                                )
                              })}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        )}

                        {(isUserNotFound ||
                          getCurrentTabVacanciesCount() === 0) &&
                          valuetab !== 0 &&
                          !isLoading && (
                            <TableBody>
                              <TableRow>
                                <TableCell className='searchCell' colSpan={7}>
                                  <SearchNotFound searchQuery={filterName} />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}

                        {isLoading && (
                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={12}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    mb: 3,
                                  }}
                                >
                                  <PulseLoader
                                    color={"#0066E9"}
                                    loading={true}
                                    size={16}
                                    speedMultiplier='1'
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    labelRowsPerPage={
                      !isSmallScreen ? t("RowsPerPage") : t("Rows")
                    }
                    count={isLoading ? 0 : handlePagination(valuetab)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </TabPanel>
            </Box>
          </>
        ) : (
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("NoAccessPAge")}
            </Typography>
            <Box
              component='img'
              src='/static/illustrations/illustration_404.svg'
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </ContentStyle>
        )}
      </Container>
    </Page>
  )
}
