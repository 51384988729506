import * as ActionTypes from "../constants/handler";
export const checks = (action, data) => {
  if (action === ActionTypes.MIC) {
    return {
      type: action,
      payload: data,
    };
  }

  if (action === ActionTypes.CAMERA) {
    return {
      type: action,
      payload: data,
    };
  }
};
