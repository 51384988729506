import React, { useState } from "react"
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Box,
  tableCellClasses,
  Stack,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

import { styled } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"
import jsPDF from "jspdf"

import "jspdf-autotable"

import { isEmpty } from "lodash"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "react-redux"
import Scrollbar from "src/components/Scrollbar"
import { useAuth } from "src/hooks/useAuth"
import { useTranslation } from "react-i18next"
import Label from "src/components/Label"
//************************** */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

/************** */
export default function ViewVcancyModal({ isViewVacancy, hide, match }) {
  const { user } = useAuth()
  const { t } = useTranslation()

  const theme = useTheme()
  const handleClose = () => {
    hide()
  }
  const logged = useSelector((state) => state.login)
  const userLoggedPermissions = logged?.user?.profile?.permission

  // download pdf
  // Define the adjustLetterSpacing function
  const adjustLetterSpacing = (text, spacing) => {
    const characters = text.split("")

    // Adjust letter spacing for each character
    const adjustedText = characters
      .map((char) => {
        return char + "\u200A".repeat(spacing) // Use a narrow space character for spacing
      })
      .join("")

    return adjustedText
  }

  const generatePdfContent = (match) => {
    const doc = new jsPDF()

    doc.setProperties({ title: "Vacancy Details" })

    let tableData = [
      [`Vacancy Title: `, `${match.id} - ${match.title}`],
      [
        `Vacancy Description`,
        `${adjustLetterSpacing(
          `${isEmpty(match?.description) ? "Not Given" : match?.description}`,
          0.05, // Adjust the letter spacing value
        )}`,
      ],

      [`Department Name: `, `${match.department.name}`],

      [`Lenght of Interview: `, `${match.interview_time} minutes`],
      [
        `Experience Required: `,
        `${match.years_experience} to ${match.max_experience} years `,
      ],
      [
        `Status of Hiring: `,
        `${match.is_hired === false ? t("inProgress") : "Hiring Done"}`,
      ],
      [
        `Required Qualification: `,
        `${isEmpty(match?.qualification) ? "Not Given" : match?.qualification}`,
      ],
      [`Reporting Mananger: `, `${match.reports_to.report_to} `],
      [
        `Salary Range: `,
        `${match.salary_currency?.split(/\(|\)/)[0]?.trim()} ${
          match.salary_range_from
        } - ${match.salary_currency?.split(/\(|\)/)[0]?.trim()} ${
          match.salary_range_to
        } / ${match.salary_period}`,
      ],
      [`Skills: `, `${match.skills} `],
      [
        `Status: `,
        `${match.status === "active" ? "Position Open" : "Closed"} `,
      ],
      [`Total Hired Candidates: `, `${match.vacancy_hired_candidates.length} `],
    ]

    // const maxFirstColumnWidth = tableData.reduce(
    //   (maxWidth, [label]) => Math.max(maxWidth, doc.getTextWidth(label)),
    //   0,
    // )
    const maxFirstColumnWidth = 60
    const availableSecondColumnWidth =
      doc.internal.pageSize.width - maxFirstColumnWidth - 20

    doc.autoTable({
      body: tableData,
      startY: 10,
      margin: { top: 10 },
      styles: {
        fontSize: 12,
        cellPadding: 4,
        whitespace: "break-spaces",
        wordBreak: "break-word",
      },
      columnStyles: {
        0: { fontStyle: "bold", cellWidth: maxFirstColumnWidth },
        1: { cellWidth: availableSecondColumnWidth, letterSpacing: "0.2px" },
      },
    })

    return doc
  }

  const handleDownloadPdf = () => {
    const doc = generatePdfContent(match)
    doc.save(`${match.title}_${match.id}.pdf`)
  }

  //download pdf end

  return (
    isViewVacancy && (
      <Dialog
        className='dialog800'
        open={isViewVacancy}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {t("Vacancy Details")}
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Scrollbar>
                <TableContainer
                  sx={{
                    minWidth: { xs: "100%", sm: 320, md: 600 },
                    border: "1px solid #8080803d",
                  }}
                >
                  <List className='simple-table responsive-tbl'>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {" "}
                          {t("VacancyTitle")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {match.title}
                        </Typography>
                      </Box>
                    </ListItem>

                    {match.description && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {" "}
                            {t("JobDescription")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component='p'
                            variant='body1'
                            sx={{
                              fontWeight: 400,
                            }}
                          >
                            {match.description}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    {match.department.name && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {" "}
                            {t("Department")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component='p'
                            variant='body1'
                            sx={{
                              fontWeight: 400,
                            }}
                          >
                            {match.department.name}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    {match.interview_time && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {" "}
                            {t("LengthInterview")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component='p'
                            variant='body1'
                            sx={{
                              fontWeight: 400,
                            }}
                          >
                            {match.interview_time}&nbsp;minutes
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    {match.years_experience && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {" "}
                            {t("ExperienceRequired")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component='p'
                            variant='body1'
                            className='res-mx'
                          >
                            {match.years_experience || ""} years to{" "}
                            {match.max_experience || ""} years
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    {match?.reports_to?.report_to && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {t("ReportsTo")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography component='p' variant='body1'>
                            {match?.reports_to?.report_to}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    {match?.skills && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {t("RequiredSkills")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography component='p' variant='body1'>
                            {match?.skills.map((singleSkill, index) => {
                              return (
                                <Label
                                  sx={{
                                    mx: "3px",
                                    fontSize: "0.875rem",
                                    whiteSpace: "break-spaces",
                                    wordBreak: "break-word",
                                    lineHeight: "1.2",
                                    height: "auto",
                                    py: { xs: 1, sm: "5px" },
                                    mb: 1,
                                  }}
                                  key={index}
                                  variant='ghost'
                                  color='info'
                                >
                                  {singleSkill}{" "}
                                </Label>
                              )
                            })}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    {match?.qualification && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {t("Qualification")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography component='p' variant='body1'>
                            {match.qualification}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    {match?.status && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>{t("Status")}</Typography>
                        </Box>
                        <Box>
                          <Typography component='p' variant='body1'>
                            {match?.status === "active"
                              ? t("Position Open")
                              : t("Vacancy Closed")}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("Status of Hiring")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component='p' variant='body1'>
                          {match?.is_hired === true
                            ? t("Hiring Done")
                            : t("inProgress")}
                        </Typography>
                      </Box>
                    </ListItem>

                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("Total Hired Candidates")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component='p' variant='body1'>
                          {match?.vacancy_hired_candidates?.length >= 1
                            ? match?.vacancy_hired_candidates?.length
                            : t("None")}
                        </Typography>
                      </Box>
                    </ListItem>

                    {(userLoggedPermissions?.user_can_view_vacancy_salary ||
                      user?.user_type === "admin") &&
                      match.salary_range_from && (
                        <ListItem className='row' disablePadding>
                          <Box className='prime'>
                            <Typography component='th'>
                              {t("SalaryRange")}
                            </Typography>
                          </Box>
                          <Box>
                            <Typography
                              component='p'
                              variant='body1'
                              className='res-mx'
                            >
                              {match.salary_currency?.split(/\(|\)/)[1]?.trim()}{" "}
                              {match.salary_range_from} to{" "}
                              {match.salary_currency?.split(/\(|\)/)[1]?.trim()}{" "}
                              {match.salary_range_to || ""}{" "}
                              {match?.salary_period &&
                                "/" + match?.salary_period}
                            </Typography>
                          </Box>
                        </ListItem>
                      )}
                  </List>
                </TableContainer>
              </Scrollbar>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleDownloadPdf}
            size='medium'
            autoFocus
            variant='contained'
            color='secondary'
          >
            {t("Download")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
