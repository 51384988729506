import * as ActionTypes from "../constants/invoices"
const initialState = { data: [], message: "" }
const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHINVOICES_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      }

    case ActionTypes.MONTHLYSTATEMENT_ON_SUCCESS:
      return {
        ...state,
        monthlyStatement: action.payload,
        isSucceed: action.payload.success,
      }

    default:
      return state
  }
}

export default invoiceReducer
