import { styled } from "@mui/material/styles"
import { Typography, Container, Link, List, ListItem, Box } from "@mui/material"
import Page from "../components/Page"

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: "4rem",
  marginTop: 150,
  marginBottom: 50,
  boxShadow: "0 0 14px rgb(0 0 0 / 4%)",
  background: "#fff",
}))

// ----------------------------------------------------------------------

export default function Policy() {
  return (
    <Page title='Policy'>
      <Container maxWidth='lg'>
        <ContentStyle className='page-content'>
          <Typography variant='h2' component='h2' sx={{ mb: 3 }}>
            Privacy Policy
          </Typography>
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Introduction
          </Typography>
          <Typography variant='body1'>
            Welcome to IntVue's Privacy Policy. This Policy outlines how we
            collect, share, and use Personal Information ("PI" or "personal
            data") provided by our users ("Candidate", "Employer", "Staffing
            companies", "Job Seekers", "User", "you", or "your") through our
            Website and Services at www.intvue.com. PI refers to any information
            that can identify an individual or be associated with them. The
            Policy also explains how you can access and update your PI and make
            choices about its use. Note that this Policy does not apply to
            companies or individuals outside of our ownership or management. At
            Intvue, we value your privacy and strive to improve our users'
            experience by using PI to enhance operational efficiency. Please
            read this Policy alongside our{" "}
            <Link
              underline='none'
              href={`${process.env.REACT_APP_FE_BASE_URL}/termsconditions/app`}
              rel='noreferrer'
            >
              Terms and Conditions
            </Link>{" "}
            before accessing our platform or affiliated services.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            What does IntVue do?{" "}
          </Typography>
          <Typography variant='body1'>
            IntVue is a web-based video interview technology that enables
            virtual hiring interviews. Our system includes advanced features
            such as Candidate Recommendations and Screening to streamline and
            enhance the recruitment process. With IntVue, companies can create
            job vacancies, invite relevant individuals for virtual interviews,
            and have them record responses without the need for an in-person
            interviewer. Responses are automatically shared with hiring teams
            and recruiters for convenient review and feedback.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Data We Collect from You
          </Typography>
          <Typography variant='body1'>
            IntVue collects personal data from our users ("candidates",
            "employers", or "users") through our web-based video interview
            platform. This data includes:
          </Typography>
          <List sx={{ width: "100%", py: 0, pb: 2 }}>
            <ListItem>Full Name</ListItem>
            <ListItem>
              Contact Information (email address, telephone number)
            </ListItem>
            <ListItem>Demographic information (address)</ListItem>
            <ListItem>
              Financial information (credit / debit card number)
            </ListItem>
            <ListItem>
              Account data (username and password when registering an account)
            </ListItem>
            <ListItem>User responses in online video interviews</ListItem>
            <ListItem>IP address (automatically collected)</ListItem>

            <ListItem>
              Web browser type and version (automatically collected)
            </ListItem>
            <ListItem>Operating system (automatically collected)</ListItem>
            <ListItem>Language preferences (automatically collected)</ListItem>
            <ListItem>
              A list of URLs starting with a referring site, user activity on
              our website, and the site they exit to (automatically collected)
            </ListItem>
          </List>
          <Typography variant='body1'>
            We collect this data in accordance with our Privacy Policy.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            How We Collect Data
          </Typography>
          <Typography variant='body1'>
            IntVue collects data in three ways whenever users interact with our
            service:
          </Typography>
          <List sx={{ width: "100%", py: 0, pb: 2 }}>
            <ListItem>Users provide us with data </ListItem>
            <ListItem>Data is automatically collected</ListItem>
            <ListItem>We receive data from third-party sources.</ListItem>
            {/**3 */}
          </List>
          <hr />
          <Typography variant='h5' component='h5' sx={{ mb: 2, mt: 1 }}>
            1. Users provide us with data
          </Typography>
          <Typography variant='body1'>
            We receive and store any information you knowingly provide to us
            when you create an account, publish content, or fill out an online
            form on IntVue. When required, this information may include the
            following:
          </Typography>
          <List sx={{ width: "100%", py: 0, pb: 2 }}>
            <ListItem>
              Personal details such as name, country of residence, etc.
            </ListItem>
            <ListItem>
              Contact information such as postal address, email address, phone
              number, etc.
            </ListItem>
            <ListItem>
              Account details, such as user name, unique user ID, password, etc.
            </ListItem>
            <ListItem>Information about others.</ListItem>
            <ListItem>
              Any materials you willingly submit to us, such as articles,
              images, feedback, etc.
            </ListItem>
          </List>
          <Typography variant='body1'>
            IntVue collects your personal data through various means such as
            contacting us through our website, registering an account,
            submitting video responses, providing feedback or completing
            surveys, entering competitions or promotions, making payments, and
            opting to receive marketing communications. We comply with our
            privacy policy in each case. Users can contact us if they are unsure
            about mandatory information.
          </Typography>
          <hr />
          <Typography variant='h5' component='h5' sx={{ mb: 2, mt: 1 }}>
            2. Data is automatically collected
          </Typography>
          <Typography variant='body1'>
            We collect certain data automatically when you use IntVue services
            or visit our website, such as your IP address, browser type, usage
            frequency, and interactions with our content. This helps us
            understand how our users engage with our services and improve the
            user experience. We also collect your data automatically through
            cookies, based on your browser settings. We use the information
            collected automatically to identify potential abuse cases and
            compile statistical data about website usage, which is not used to
            identify individual users. For more information about cookies,
            please refer to the "Cookies" section below.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Our Use of Data{" "}
          </Typography>
          <Typography variant='body1'>
            To ensure seamless availability of our website and services, and to
            comply with legal obligations, we collect and use your Personal
            Information. Failure to provide requested information may result in
            inability to provide requested products or services. We collect
            information for purposes including managing user accounts, sending
            administrative information, responding to inquiries and support,
            requesting feedback, improving user experience, enforcing terms and
            conditions and privacy policies, protecting from abuse and malicious
            users, responding to legal requests and preventing harm, running and
            operating our website and services, internal record keeping, and
            transmitting marketing materials with opt-out options. We may also
            contact you for market research purposes through email, telephone,
            fax, or mail.
          </Typography>
          <Typography variant='body1'>
            We process your Personal Information based on your interaction with
            IntVue and your location. This processing depends on whether one of
            the following applies:
          </Typography>
          <List sx={{ width: "100%", py: 0, pb: 2 }}>
            <ListItem>
              Your consent applies to one or more specific purposes, except when
              the processing of personal information is governed by the
              California Consumer Privacy Act or European data protection law.
            </ListItem>
            <ListItem>
              Providing information is required for fulfilling an agreement with
              you and/or meeting any pre-contractual obligations.
            </ListItem>
            <ListItem>
              We must process your information to comply with a legal obligation
              that applies to you.
            </ListItem>
            <ListItem>
              We process data in the public interest or in the exercise of
              official authority vested in us.
            </ListItem>
            <ListItem>
              We process data based on our legitimate interests or those of a
              third party.
            </ListItem>
          </List>
          <Typography variant='body1'>
            Please note that in certain legal contexts, we may process your
            information until you object (by opting out), without requiring
            consent or any other legal basis. We will provide clarification on
            the specific legal basis for the processing, including whether the
            provision of personal information is a statutory or contractual
            requirement, or necessary to enter into a contract.
          </Typography>
          <Typography variant='body1'>
            We may utilize your data for the stated purposes if we consider it
            necessary to protect our legitimate interests. In case of any
            dissatisfaction, you have the right to object under certain
            circumstances, as detailed in the "The Rights of Users" section
            below.
          </Typography>
          <Typography variant='body1'>
            In order to send you direct marketing emails, we require your
            consent, either through opt-in or soft opt-in:
          </Typography>
          <List sx={{ width: "100%", py: 0, pb: 2 }}>
            <ListItem>
              Soft opt-in is a type of consent where we assume your consent is
              given if you have previously engaged with us, but you have the
              option to opt-out.
            </ListItem>
            <ListItem>
              For other types of e-marketing, explicit consent is required. This
              means you need to take positive and affirmative action, such as
              checking a tick box we provide.
            </ListItem>
            <ListItem>
              You can withdraw your consent at any time if you are not satisfied
              with our marketing approach.
            </ListItem>
          </List>
          <Typography variant='body1'>
            The legal basis for processing your personal information is the
            performance of a contract or the initiation of a contract at your
            request when you register with us. We may use your data to display
            IntVue advertisements and relevant content on other websites. If you
            do not wish to receive these ads, you can disable the related
            cookies. Please refer to the "Cookies" section below for more
            information.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Who we share Data with
          </Typography>
          <Typography variant='body1'>
            IntVue does not sell the information collected from our service
            users. However, we may share your Personal Information with the
            following categories of recipients as part of our regular business
            operations:
          </Typography>
          <Typography variant='h5' component='h5' sx={{ mb: 2, mt: 1 }}>
            Employers{" "}
          </Typography>
          <Typography variant='body1'>
            Intvue may disclose candidates' Personal Information and
            automatically-collected data to prospective employers.
          </Typography>
          <Typography variant='h5' component='h5' sx={{ mb: 2, mt: 1 }}>
            Third-Party Affiliate Companies
          </Typography>
          <List sx={{ width: "100%", py: 0, pb: 2 }}>
            <ListItem>
              Intvue shares Personal Information with third-party service
              providers and partners for data processing purposes, such as
              supporting website delivery, providing functionality, and
              enhancing security features.
            </ListItem>
            <ListItem>
              Personal Information is also shared with affiliates who process
              information for purposes described in this Privacy Notice or
              notified at the time of data collection.
            </ListItem>
          </List>
          <Typography variant='h5' component='h5' sx={{ mb: 2, mt: 1 }}>
            Legal Authorities
          </Typography>
          <Typography variant='body1'>
            IntVue may disclose your Personal Information to law enforcement
            agencies, government bodies, courts, or other third parties if we
            believe it is required by law or necessary to protect your or
            another person's vital interests, establish or defend our legal
            rights.
          </Typography>
          <Typography variant='h5' component='h5' sx={{ mb: 2, mt: 1 }}>
            Business Transfer
          </Typography>
          <Typography variant='body1'>
            In the event of a proposed purchase, reorganization, merger, or
            acquisition of any part of our business, we will disclose your
            Personal Information to the buyer and require them to use it only
            for the purposes stated in this Privacy Notice. We may also disclose
            your Personal Information to other individuals or entities with your
            consent. If you are a Candidate or Employee, we collect your
            Personal Information on behalf of our customers under our agreements
            with them. For more details on how your Personal Information is
            shared with third parties, please contact the relevant Employer or
            refer to their separate privacy policy.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Email Marketing
          </Typography>
          <Typography variant='body1'>
            Subscribe to our electronic newsletters at any time, with a
            commitment to keep your email address confidential. We won't
            disclose it to third parties except as allowed in our information
            use and processing section or to utilize a third-party provider to
            send emails. All emails will comply with the CAN-SPAM Act and
            provide clear information on the sender and how to contact them. To
            unsubscribe, contact us. However, you'll still receive essential
            transactional emails.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Managing Information
          </Typography>
          <Typography variant='body1'>
            You can delete some of your Personal Information on IntVue, but the
            options may change as the site evolves. Even after deletion, we may
            retain some Personal Information to comply with obligations to our
            affiliates and partners. To delete your Personal Information or
            account, please contact us.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Retention of Information
          </Typography>
          <Typography variant='body1'>
            We will keep and utilize your Personal Information for as long as
            necessary to comply with legal, regulatory, reporting, or accounting
            requirements, resolve disputes, and enforce agreements. If allowed
            by law, we may retain it for a longer period.
          </Typography>
          <Typography variant='body1'>
            We may use any aggregated data that contains your Personal
            Information in a way that doesn't personally identify you after you
            update or delete it.
          </Typography>
          <Typography variant='body1'>
            After the retention period, your Personal Information will be
            deleted. This means that you won't be able to exercise your rights
            of access, erasure, rectification, objection, processing
            restriction, withdrawal of consent, and data portability.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Transfer of Information
          </Typography>
          <Typography variant='body1'>
            Data transfers may require us to store and transfer your information
            to a country other than your own, depending on your location. You
            have the right to know about the legal basis and security measures
            we use to protect your information during transfers to countries
            outside the European Union or international organizations like the
            UN. Check the relevant sections of our Privacy Policy or contact
            IntVue for more information.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            The Rights of Users{" "}
          </Typography>
          <Typography variant='body1'>
            You have certain rights that you can exercise regarding your
            information processed by IntVue, including:
          </Typography>

          <List sx={{ width: "100%", py: 0, pb: 2 }}>
            <ListItem>
              You can withdraw your consent to process your information if you
              had given it previously.
            </ListItem>
            <ListItem>
              <Box>
                You can object to the processing of your Personal Information if
                it's being processed for the public interest, the exercise of
                official authority, or for IntVue's legitimate interests.
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                You can object to this processing if it's based on a legal basis
                other than your given consent, or if you have a specific reason
                that justifies the objection based on your situation.
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                You have the right to access and verify if we are processing
                your Personal Information. You may also obtain information about
                how we are processing it, and request a copy of your information
                that is being processed.
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                You have the right to request correction or updates to your
                information to ensure its accuracy. This is known as the right
                to rectification.
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                In certain situations, you can restrict the processing of your
                Personal Information. This means we will only store it and not
                use it for any other purpose.
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                You can request that we delete your Personal Information under
                certain circumstances. If we remove any processing restrictions
                in the future, we'll notify you. If we've shared your Personal
                Information with a third-party service provider for legitimate
                reasons, we'll inform them of the restriction wherever possible.
              </Box>
            </ListItem>
            <ListItem>
              <Box>
                You can exercise your right to data portability, which allows
                you to receive your Personal Information in a structured,
                commonly used, and machine-readable format. If technically
                possible, we can transmit this data to another controller
                without hindrance.
              </Box>
            </ListItem>
          </List>
          <Typography variant='body1'>
            This provision applies when your information is processed by
            automated means, and the processing is based on your consent, a
            contract you're part of, or pre-contractual obligations.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Data Protection Rights Under the EU’s General Data Protection
            Regulation (GDPR){" "}
          </Typography>
          <Typography variant='body1'>
            As an EEA resident, you have specific data protection rights that
            IntVue strives to respect. You can request corrections, amendments,
            deletion, or limited use of your Personal Information, and we will
            make reasonable efforts to accommodate you. If you want to know what
            Personal Information we hold about you and want it removed from our
            systems, please contact us. You also have the following data
            protection rights in certain circumstances:
          </Typography>
          <List sx={{ width: "100%", py: 0, pb: 2 }}>
            <ListItem>
              You can request access to your stored Personal Information and
              access it.
            </ListItem>
            <ListItem>
              You can request us to correct any inaccurate or incomplete
              Personal Information.
            </ListItem>
            <ListItem>
              You can request the erasure of your Personal Information under
              specific conditions mentioned in this Privacy Policy.
            </ListItem>
            <ListItem>
              You can object to the processing of your Personal Information.
            </ListItem>
            <ListItem>
              You can request restrictions on the processing of your Personal
              Information, and we will store it without processing it further.
            </ListItem>
            <ListItem>
              You can receive a structured, machine-readable, and commonly used
              copy of the information we have about you.
            </ListItem>
            <ListItem>
              You can withdraw your consent to the processing of your Personal
              Information at any time if we relied on your consent to process
              it.
            </ListItem>
            <ListItem>
              You can lodge a complaint with a Data Protection Authority about
              our collection and use of your Personal Information. Contact your
              local data protection authority in the European Economic Area
              (EEA) for more information.
            </ListItem>
          </List>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            California Residents’ Privacy Rights{" "}
          </Typography>
          <Typography variant='body1'>
            California residents who provide Personal Information to IntVue for
            web-based services have the right to request, once per calendar
            year, information about the Personal Information we shared with
            other businesses for legitimate purposes. This includes the
            categories of Personal Information and the names and addresses of
            those businesses with which we shared such Personal Information for
            the prior calendar year. To obtain this information, please contact
            us.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            How to Exercise These Rights?{" "}
          </Typography>
          <Typography variant='body1'>
            To exercise your data protection rights, please contact IntVue using
            the information provided under the "How to contact us" header. We
            will respond to all requests in accordance with applicable data
            protection laws and take reasonable steps to verify your identity
            before granting access to your account or making corrections to your
            information.
          </Typography>
          <Typography variant='body1'>
            We may request verification of your identity before responding to
            your request. Your request should contain sufficient information to
            verify your identity or authority to act on behalf of the person
            concerned. We cannot respond to your request or provide you with
            Personal Information until we confirm your identity or authority and
            confirm that the Personal Information pertains to you.
          </Typography>

          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Privacy of Children
          </Typography>
          <Typography variant='body1'>
            IntVue does not collect Personal Information from children under 18.
            Please do not submit Personal Information if you are under 18. We
            urge parents to monitor their children's internet use and instruct
            them not to share Personal Information on our website or service
            without permission. If you suspect a child has shared Personal
            Information with us, please contact us to remove the information.
            You must be at least 16 years old to consent to Personal Information
            processing (in some countries, parents or guardians may consent on
            behalf of minors).
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Cookies
          </Typography>
          <Typography variant='body1'>
            Our website utilizes "cookies" to enhance your online experience.
            These are small text files placed on your hard drive by a web server
            and cannot run programs or transmit viruses. They are unique to you
            and can only be read by the web server in the domain that issued
            them.
          </Typography>
          <Typography variant='body1'>
            IntVue may use cookies for statistical purposes to operate our
            website and services. You have the option to accept or decline
            cookies. While most web browsers automatically accept cookies, you
            can adjust your browser settings to decline them. For further
            information on cookies and managing them, please visit
            internetcookies.org.
          </Typography>
          <Typography variant='body1'>
            Please be aware that choosing to delete or decline cookies from
            IntVue.com may limit your access to our service features.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Do Not Track Signals
          </Typography>
          <Typography variant='body1'>
            Our website does not track visitors' online activity over time and
            across third-party websites. However, third-party sites may collect
            information about your browsing behavior to customize the content
            they present to you. Note that some web browsers include a Do Not
            Track feature to signal to websites that you do not want your
            activity tracked.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Links to Other Websites{" "}
          </Typography>
          <Typography variant='body1'>
            Our website includes links to third-party websites not owned or
            controlled by IntVue. We are not accountable for the privacy
            practices of these websites or third-party entities. We suggest that
            you take note when leaving our website and review the privacy
            policies of each website that may obtain your personal information.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Information Security
          </Typography>
          <Typography variant='body1'>
            At IntVue, we take the security of your information seriously. We
            store your data on secure computer servers and use measures to
            prevent unauthorized access, usage, or disclosure. We have
            implemented reasonable administrative, technical, and physical
            safeguards to protect your Personal Information while it is under
            our control and custody.
          </Typography>
          <Typography variant='body1'>
            However, please be aware that the security and privacy of the
            internet are beyond our control. We cannot fully guarantee the
            security, integrity, and privacy of any information exchanged
            between you and our website. Data transmission over the internet or
            wireless networks may be viewed or tampered with by a third party,
            despite our efforts to protect your information.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Data Breach
          </Typography>
          <Typography variant='body1'>
            If we discover a security breach or unauthorized disclosure of
            users' personal information due to external activity, we may take
            necessary actions including investigation, reporting, and
            cooperation with law enforcement. In case of a data breach, we will
            make reasonable efforts to notify affected individuals through
            email, phone, or mail if there is a reasonable risk of harm or if
            required by law.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Changes and Amendments{" "}
          </Typography>
          <Typography variant='body1'>
            Intvue reserves the right to modify this Privacy Policy at any time,
            and will provide notice of any material changes through our services
            or other means. The "last updated" date displayed at the top of this
            Privacy Notice indicates the most recent update.
          </Typography>
          <Typography variant='body1'>
            Any revised Privacy Policy will be effective immediately upon
            posting unless otherwise specified. By continuing to use the website
            or services after the effective date of the revised policy, you
            consent to the changes.
          </Typography>
          <Typography variant='body1'>
            However, we will not use your Personal Information in a materially
            different manner without your consent. If you disagree with any
            changes, you may close your account at any time.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Acceptance of this Policy{" "}
          </Typography>
          <Typography variant='body1'>
            Your continued use of our website and services following the
            publication or transmission of a notice regarding changes to our
            Privacy Policy signifies your acceptance of the revised terms. This
            includes the collection, utilization, and sharing of your personal
            data in accordance with the updated policy, effective immediately.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Copyright Disclaimer{" "}
          </Typography>
          <Typography variant='body1'>
            IntVue holds the exclusive rights to all information and content on
            our website. No reproduction, alteration, or copying of such content
            is permitted without our explicit consent. Our trademarks and
            service marks, as well as those of our affiliates or partners, may
            be featured in the form of text, images, visuals, and symbols on our
            website.
          </Typography>
          <Typography variant='body1'>
            Using our website or services does not grant you the authority or
            license to use or mimic our trademarks or service offerings without
            our prior written consent. All content accessible on our website,
            blog, and services is protected by copyright laws in various
            countries, including the UK and the US. You are strictly prohibited
            from copying, redistributing, using, or publishing any such content.
          </Typography>
          <Typography variant='body1'>
            Please note that your use of our website or services does not grant
            you any ownership rights to any IntVue content.
          </Typography>
          <hr />

          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            CHANGES TO OUR PRIVACY POLICY
          </Typography>
          <Typography variant='body1'>
            Any changes we may make to our Privacy Policy in the future will be
            posted on this page. Please check frequently for any updates or
            changes to our Privacy Policy. This Privacy Policy was last updated
            on the 15th of December, 2023.
          </Typography>

          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            How to Contact Us
          </Typography>

          <Typography variant='body1'>
            If you have any questions or concerns about our use of your personal
            information, please contact us using the following details:
          </Typography>
          <Typography variant='body1'>
            <address>
              IntVue
              <br />
              2670 S White Road Suite #125, San Jose, CA 95148 USA
              <br />
              <b>Privacy Policy Inquiries: </b>{" "}
              <Link underline='none' href='mailto:support@intvue.com;'>
                support@intvue.com
              </Link>
              <br />
              <b>
                Intvue Data Protection Officer: (Chief Security Officer: )
              </b>{" "}
              <Link underline='none' href='mailto:support@intvue.com;'>
                support@intvue.com
              </Link>{" "}
              <br />
            </address>
          </Typography>
        </ContentStyle>
      </Container>
    </Page>
  )
}
