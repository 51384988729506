import { useTheme } from "@mui/material/styles"
import React, { useEffect, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import LoadingButton from "@mui/lab/LoadingButton"
import { useDispatch, useSelector } from "react-redux"
import { addCard } from "src/redux/actions/paymentMethod"
import { useLocalStorage } from "src/hooks/useLocalStorage"
import { loadStripe } from "@stripe/stripe-js/pure"
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import {
  Button,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Alert,
  TextField,
} from "@mui/material"
import { useTranslation, withTranslation, Trans } from "react-i18next"

loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY)

const WrapperChangeCardModal = (props) => (
  <Elements stripe={stripePromise}>
    <ChangeCardModal {...props} />
  </Elements>
)

function ChangeCardModal({ open, isCardAttached, onCardChange, onClose }) {
  const theme = useTheme()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const [user, setUser] = useLocalStorage("user")
  const [message, setMessage] = useState("")

  const [isValidatingCard, setIsValidatingCard] = useState(false)
  const [updatedCard, setUpdatedCard] = useState(null)
  const [isCardElementReady, setIsCardElementReady] = useState(false)
  const [cardHolderName, setCardHolderName] = useState("")

  const addUpdateCardState = useSelector((state) => state.addCard)

  const CardElementOptions = {
    style: {
      base: {},
      invalid: {},
    },
    hidePostalCode: true,
  }

  useEffect(() => {
    if (addUpdateCardState.cardAdded) {
      if (updatedCard) {
        const userToUpdate = user
        userToUpdate.user.card_brand = updatedCard.brand
        userToUpdate.user.card_expiration_month = updatedCard.expMonth
        userToUpdate.user.card_expiration_year = updatedCard.expYear
        userToUpdate.user.card_last_digts = updatedCard.last4
        setUser(userToUpdate)

        if (onCardChange) {
          setIsCardElementReady(false)
          onCardChange(updatedCard)
        }
      }
    } else {
      setIsValidatingCard(false)
      if (
        addUpdateCardState?.error?.errors &&
        addUpdateCardState?.error?.errors.length > 0
      ) {
        setMessage(addUpdateCardState?.error?.errors[0])
      } else if (isValidatingCard) {
        setMessage(`Failed to ${isCardAttached ? "update" : "add"} card.`)
      }
    }
    setIsValidatingCard(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addUpdateCardState])

  const handleSubmit = async () => {
    if (stripe && elements) {
      setMessage("")
      setIsValidatingCard(true)
      const card = elements.getElement(CardElement)

      await stripe
        .createToken(card, { name: cardHolderName })
        .then((result) => {
          if (result.token) {
            const { name, last4, exp_month, exp_year, brand } =
              result.token.card
            const cardData = {
              name: name,
              last4,
              expMonth: exp_month,
              expYear: exp_year,
              brand,
            }
            setUpdatedCard(cardData)
            const addCardBody = {
              card_token: result.token.id,
            }
            dispatch(addCard(addCardBody))
          } else if (result.error) {
            setIsValidatingCard(false)
            setMessage(result.error.message)
          }
        })
    }
  }

  const handleClose = () => {
    setMessage("")
    setIsValidatingCard(false)
    setIsCardElementReady(false)
    onClose()
  }

  const handleCardHolderNameChange = (e) => {
    setCardHolderName(e.target.value)
  }

  return (
    <Dialog
      className='dialog400'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='md'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        {isCardAttached ? t("UpdateCardNumber") : t("AddCardNumber")}
        <IconButton
          aria-label='close'
          onClick={!isValidatingCard && handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className='dialog-content' dividers={true}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl sx={{ width: "100%" }}>
              <FormLabel
                htmlFor='card-holder-name'
                sx={{ marginBottom: "5px" }}
              >
                {t("CardHolderName")}
              </FormLabel>
              <TextField
                id='card-holder-name'
                fullWidth
                variant='outlined'
                name='name'
                placeholder={t("CardHolderName")}
                value={cardHolderName}
                onChange={handleCardHolderNameChange}
              />

              <FormLabel
                required
                htmlFor='card-element'
                sx={{ marginTop: "20px", marginBottom: "10px" }}
              >
                {t("CardNumber")}
              </FormLabel>
              <CardElement
                id='card-element'
                option={CardElementOptions}
                onReady={() => setIsCardElementReady(true)}
              />
            </FormControl>
          </Grid>
          {message && (
            <Grid item xs={12}>
              <Alert variant='outlined' severity='error'>
                {t(message)}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button
          onClick={handleClose}
          disabled={isValidatingCard}
          variant='outlined'
          color='secondary'
          sx={{
            backgroundColor: "transparent",
            borderColor: "#8b8e92",
            color: "#64748b",
            "&:hover": {
              opacity: "0.7",
              backgroundColor: "#8b8e92",
              borderColor: "#8b8e92",
              color: "#fff",
            },
          }}
          size='medium'
        >
          {t("Cancel")}
        </Button>

        <LoadingButton
          onClick={handleSubmit}
          loading={isValidatingCard}
          autoFocus
          variant='contained'
          color='secondary'
          size='medium'
          disabled={!isCardElementReady}
        >
          {isCardAttached ? t("Update") : t("Add")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default WrapperChangeCardModal
