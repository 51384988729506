import React, { useState, useEffect } from "react"
import { slice } from "lodash"
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TablePagination,
  Box,
} from "@mui/material"

import Label from "src/components/Label"
import { useDispatch, useSelector } from "react-redux"
import { getAllRoles } from "src/redux/actions/roles"
import CloseIcon from "@mui/icons-material/Close"

import { useTranslation } from "react-i18next"
import Scrollbar from "src/components/Scrollbar"
import PulseLoader from "react-spinners/PulseLoader"

export default function ViewActiveVacancy({ hide, open }) {
  const dispatch = useDispatch()
  const renderFlag = "active"
  const { t } = useTranslation()
  const [rows, setRows] = useState([])
  const [firstRender, setFirstRender] = useState(true)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const existingRoles = useSelector((state) => state.roles)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const closeModal = () => {
    setFirstRender(true)
    hide()
  }
  useEffect(() => {
    if (open && firstRender) {
      setFirstRender(false)
      setIsDataLoading(true)
      dispatch(getAllRoles(renderFlag))
    } else if(open && !firstRender) {
      setRows(existingRoles?.data?.vacancies ?? [])
      setIsDataLoading(false)
    }
  }, [existingRoles, open])

  return (
    <Dialog
      className='dialog800'
      open={open}
      onClose={closeModal}
      scroll='paper'
      maxWidth='xl'
    >
      <DialogTitle
        className='dialog-title'
        sx={{ justifyContent: "space-between", display: "flex" }}
      >
        {t("Active vacancy")}

        <IconButton
          aria-label='close'
          onClick={closeModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className='dialog-content'
        dividers={true}
        sx={{ borderTop: 0 }}
      >
        <Scrollbar>
          <TableContainer>
            <Table
              className='simple-table'
              sx={{ minWidth: 650 }}
              aria-label='simple table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t("Title")}</TableCell>
                  <TableCell>{t("Experience")}</TableCell>
                  <TableCell>{t("HiredCandidate")}</TableCell>
                  <TableCell>{t("JobManager")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isDataLoading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mb: 3,
                        }}
                      >
                        <PulseLoader
                          color={"#0066E9"}
                          loading={true}
                          size={16}
                          speedMultiplier='1'
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {rows?.length > 0 ? (
                      rows
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const firstFiveHiredCandidates = slice(
                          row?.vacancy_hired_candidates,
                          0,
                          5,
                        )

                        return (
                          <TableRow hover key={row.id}>
                            <TableCell component='th' scope='row'>
                              <Typography
                                variant='subtitle2'
                                sx={{
                                  textTransform: "capitalize",
                                }}
                                noWrap
                              >
                                {row.title}{" "}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              {row?.years_experience} {t("to")}{" "}
                              {row?.max_experience} {t("years")}
                            </TableCell>
                            <TableCell>
                              {row?.vacancy_hired_candidates?.length > 0 ? (
                                firstFiveHiredCandidates.map((item) => (
                                  <Typography
                                    key={item.index + item?.candidate?.first_name}
                                    sx={{
                                      fontWeight: 400,
                                      marginRight: "6px",
                                      textTransform: "capitalize",
                                    }}
                                    component='span'
                                    variant='subtitle2'
                                  >
                                    {item?.candidate?.first_name +
                                      " " +
                                      item?.candidate?.last_name}
                                  </Typography>
                                ))
                              ) : (
                                <Label variant='ghost' color='success'>
                                  {t("inProgress")}
                                </Label>
                              )}
                            </TableCell>
                            <TableCell>{row?.reports_to?.report_to}</TableCell>
                          </TableRow>
                        )
                      })
                      ) : (
                        <TableRow>
                          <TableCell className='searchCell' colSpan={10}>
                            <Typography variant='body2' align='left'>
                              {t("ActiveVacNotFound")} &nbsp;
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )
                    }
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Scrollbar>
      </DialogContent>
      <DialogActions className='dialog-footer'>
        <Button onClick={closeModal} variant='outlined' color='primary' size='medium'>
          {t("Close")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
