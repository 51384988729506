import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"

import {
  Box,
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tabs,
  Tab,
  Avatar,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

import { useTheme } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import Label from "src/components/Label"
import { useNavigate } from "react-router-dom"
import { convertTimeZone } from "src/utils/formatTime"
import { DBTimeZone } from "src/utils/constants"

//************************** */

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3, px: 0 }}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

/************** */
export default function IntDetailModal({
  data,
  dashbord,
  isShowingIntReview,
  type,
  hide,
  open,
}) {
  const { t } = useTranslation()
  const [isError, setIsError] = useState(false)
  const theme = useTheme()
  const [initialState, setInitialState] = useState({})
  const navigate = useNavigate()
  const [value, setValue] = useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const descriptionElementRef = useRef(null)
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [open])

  const userData = data[0]

  const userVacancy = userData?.vacancy

  const handleIntReview = (id, key) => {
    const obj = { id: id, key: key }
    localStorage.setItem("match", JSON.stringify(obj))

    window.history.pushState(
      null,
      "",
      dashbord === true ? "/dashboard/app" : "/dashboard/interviews",
    )

    navigate(`/interview-feedback/app/${key}`, { replace: false })
  }

  return (
    isShowingIntReview && (
      <Dialog
        open={isShowingIntReview}
        onClose={hide}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
        className='dialog800'
      >
        <DialogTitle sx={{ justifyContent: "space-between", display: "flex" }}>
          {t("InterviewDetails")}
          <IconButton
            aria-label='close'
            onClick={hide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <Container maxWidth='md' spacing={0}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction={{ xs: "column", sm: "row" }}>
                  <Avatar
                    className='avatar-lg'
                    sx={{
                      width: { xs: 50, sm: 80, md: 125 },
                      height: { xs: 50, sm: 80, md: 125 },
                      bgcolor: "#1890FF",
                      mr: 3,
                      fontSize: "1.7rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {userData?.name?.slice(0, 1)}
                  </Avatar>
                  <Box
                    sx={{
                      flexDirection: "column",
                      display: "inline-flex",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant='h3'
                      component='h3'
                      sx={{ textTransform: "capitalize" }}
                    >
                      {userData?.name}
                    </Typography>
                    <Typography
                      variant='h5'
                      component='h5'
                      sx={{
                        color: "#969a9d",
                        textTransform: "capitalize",
                        mb: 1,
                      }}
                    >
                      {userData?.role}
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                      >
                        <Typography variant='subtitle2' component='div' sx={{}}>
                          {t("ScheduledDate")}:
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          component='span'
                          sx={{ fontWeight: 400, mx: 1 }}
                        >
                          {convertTimeZone(
                            userData?.scheduleDate,
                            userData?.timeZone,
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", sm: "row" },
                        }}
                      >
                        <Typography variant='subtitle2' component='span'>
                          {t("CompletedOn")}:
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          component='span'
                          sx={{ fontWeight: 400, mx: 1 }}
                        >
                          {convertTimeZone(
                            userData?.completedOn,
                            DBTimeZone,
                            userData?.timeZone,
                          )}
                        </Typography>
                      </Grid>
                      {userVacancy?.years_experience && (
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                          }}
                        >
                          <Typography
                            variant='subtitle2'
                            component='span'
                            sx={{}}
                          >
                            {t("RequiredExperience")}:
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            component='span'
                            sx={{ fontWeight: 400, mx: 1 }}
                          >
                            {userVacancy?.years_experience} {t("years")}
                          </Typography>
                        </Grid>
                      )}
                      {userVacancy?.qualification && (
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                          }}
                        >
                          <Typography
                            variant='subtitle2'
                            component='span'
                            sx={{}}
                          >
                            {t("RequiredQualification")}:
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            component='span'
                            sx={{ fontWeight: 400, mx: 1 }}
                          >
                            {userVacancy?.qualification}
                          </Typography>
                        </Grid>
                      )}
                      {userVacancy?.skills.length !== null && (
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "column", sm: "row" },
                          }}
                        >
                          <Typography
                            variant='subtitle2'
                            component='span'
                            sx={{}}
                          >
                            {t("RequiredSkills")}:
                          </Typography>
                          <Typography
                            variant='subtitle2'
                            component='span'
                            sx={{ fontWeight: 400, mx: 1 }}
                          >
                            {userVacancy?.skills.map((sk, index) => (
                              <Label
                                key={index}
                                variant='ghost'
                                color='info'
                                sx={{ mr: 1, mb: 1 }}
                              >
                                {sk}
                              </Label>
                            ))}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    value={value}
                    onChange={handleChange}
                  >
                    <Tab label={t("CandidateInfoTitle")} {...a11yProps(0)} />
                  </Tabs>

                  <TabPanel value={value} index={0}>
                    <Box sx={{ width: "100%", px: 0 }}>
                      <FormControl
                        sx={{
                          flexDirection: { xs: "column", sm: "row" },
                          width: "100%",
                          alignItems: { xs: "flex-start", sm: "center" },
                          mb: 1,
                        }}
                      >
                        <FormLabel
                          sx={{
                            mb: "0 !important",
                            mr: 1,
                            width: "150px",
                            minWidth: "150px",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                          }}
                        >
                          {t("Email")}:
                        </FormLabel>
                        <Typography sm={9} variant='subtitle2' component='span'>
                          {userData?.email}
                        </Typography>
                      </FormControl>
                      <FormControl
                        sx={{
                          flexDirection: { xs: "column", sm: "row" },
                          width: "100%",
                          alignItems: { xs: "flex-start", sm: "center" },
                          mb: 1,
                        }}
                      >
                        <FormLabel
                          sx={{
                            mb: "0 !important",
                            mr: 1,
                            width: "150px",
                            minWidth: "150px",
                            fontSize: "0.9rem",
                            fontWeight: 600,
                          }}
                        >
                          {t("Experience")}:
                        </FormLabel>
                        <Typography variant='subtitle2' component='span'>
                          {userData?.experience + " " + t("years")}
                        </Typography>
                      </FormControl>

                      {userData?.skills?.length !== 0 && (
                        <FormControl
                          sx={{
                            flexDirection: { xs: "column", sm: "row" },
                            width: "100%",
                            alignItems: { xs: "flex-start", sm: "center" },
                            mb: 1,
                          }}
                        >
                          <FormLabel
                            sx={{
                              width: "150px",
                              minWidth: "150px",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                            }}
                          >
                            {t("Skills")}
                          </FormLabel>

                          <Typography variant='subtitle2' component='span'>
                            {userData?.skills?.map((experties, index) => (
                              <Label
                                key={index}
                                variant='ghost'
                                color='warning'
                                sx={{ marginRight: 2 }}
                              >
                                {experties}
                              </Label>
                            ))}
                          </Typography>
                        </FormControl>
                      )}
                      {userData?.qualification && (
                        <FormControl
                          sx={{
                            flexDirection: { xs: "column", sm: "row" },
                            width: "100%",
                            alignItems: { xs: "flex-start", sm: "center" },
                            mb: 1,
                          }}
                        >
                          <FormLabel
                            sx={{
                              mb: "0 !important",
                              mr: 1,
                              width: "150px",
                              minWidth: "150px",
                              fontSize: "0.9rem",
                              fontWeight: 600,
                            }}
                          >
                            {t("Qualification")}:
                          </FormLabel>
                          <Typography variant='subtitle2' component='span'>
                            {userData?.qualification}
                          </Typography>
                        </FormControl>
                      )}
                    </Box>
                  </TabPanel>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions className='dialog-footer'>
          {userData?.intStatus === "pending" ||
          userData?.intStatus === "expired" ? null : (
            <Button
              variant='contained'
              sx={{ m: 1 }}
              onClick={() => handleIntReview(userData?.id, userData?.key)}
            >
              {t("ReviewInterview")}
            </Button>
          )}
          <Button
            onClick={hide}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Close")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
