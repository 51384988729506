import * as ActionTypes from "../constants/team"
const initialState = {
  data: null,
}

const teamReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.ACTIVE_TEAM_SUCCESS) {
    return { ...state, data: action.payload, isSucceed: true }
  }
  return state
}

export default teamReducer
