/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react"
import Images from "src/assets/img/Images"
import CloseIcon from "@mui/icons-material/Close"
import LoadingButton from "@mui/lab/LoadingButton"
import { useDispatch, useSelector } from "react-redux"
import { applyDiscountPlan } from "src/utils/userHelper"
import {
  activateBilling,
  createSubscription,
} from "src/redux/actions/subscription"
import {
  Box,
  Button,
  Typography,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material"
import { validateCoupon } from "src/redux/actions/paymentMethod"
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep"

export default function SubscribePlanModal({
  open,
  plans,
  planIdToSubscribe,
  isOnTrial,
  onSubscribed,
  onClose,
}) {
  const dispatch = useDispatch()
  const [message, setMessage] = useState("")
  const [isActivatingBilling, setIsActivatingBilling] = useState(false)
  const [isSubscribing, setIsSubscribing] = useState(false)
  const [discountPrice, setDiscountPrice] = useState(null)
  const [code, setCode] = useState("")
  const [appliedCode, setAppliedCode] = useState("")
  const [codeError, setCodeError] = useState("")

  const activateBillingStatus = useSelector((state) => state.activateBilling)
  const subscriptionStatus = useSelector((state) => state.createSubscription)
  const validCoupon = useSelector((state) => state.validCoupon)

  useEffect(() => {
    setCode("")
    setMessage("")
    setCodeError("")
    resetDiscountFields()
  }, [open])

  useEffect(() => {
    if (validCoupon.isValid) {
      let val = validCoupon?.data?.coupon?.percent_off
      applyDiscountPlan(val, getPriceOfPlanToSubscribe(), setDiscountPrice)
      setCodeError("")
      setAppliedCode(validCoupon?.data)
    } else if (validCoupon.isError) {
      resetDiscountFields()
      setCode("")
      setCodeError(validCoupon.error)
    }
  }, [validCoupon])

  useEffect(() => {
    if (isSubscribing) {
      setIsSubscribing(false)
      const failedMessage = "Failed to subscribe. Please try again."

      if (subscriptionStatus?.subscription_info?.success) {
        if (onSubscribed) {
          onSubscribed(subscriptionStatus.subscription_info?.subscription)
        }
      } else {
        setMessage(failedMessage)
      }
    }
  }, [subscriptionStatus])

  useEffect(() => {
    if (isActivatingBilling) {
      if (activateBillingStatus.activated) {
        dispatch(createSubscription({ plan_id: planIdToSubscribe }))
      } else {
        setMessage("Failed to activate your billing. Please try again.")
      }
      setIsActivatingBilling(false)
    }
  }, [activateBillingStatus])

  const getTitleOfPlanToSubscribe = () => {
    if (plans.length > 0) {
      return plans.find((p) => p.id === planIdToSubscribe)?.name
    }
    return ""
  }

  const getPriceOfPlanToSubscribe = () => {
    if (plans.length > 0) {
      return plans.find((p) => p.id === planIdToSubscribe)?.price
    }
    return ""
  }

  const confirmButtonClicked = () => {
    if (planIdToSubscribe) {
      setMessage("")
      setIsSubscribing(true)

      if (isOnTrial) {
        setIsActivatingBilling(true)
        dispatch(activateBilling())
      } else {
        let promo_code = {
          id: appliedCode?.id,
          coupon_id: appliedCode?.coupon?.id,
        }
        dispatch(
          createSubscription({
            plan_id: planIdToSubscribe,
            promo_code: promo_code,
          }),
        )
      }
    }
  }
  const resetDiscountFields = () => {
    setAppliedCode({})
    setDiscountPrice(null)
  }

  const handleClear = () => {
    setCode("")
    setCodeError("")
    resetDiscountFields()
  }

  const handleClose = () => {
    setMessage("")
    setIsSubscribing(false)
    setCode("")
    setMessage("")
    setCodeError("")
    resetDiscountFields()
    onClose()
  }

  const validateCode = () => {
    if (!code) {
      setCodeError("No Code Entered")
      return
    }

    const payload = {
      code: code,
      plan_id: planIdToSubscribe,
    }

    dispatch(validateCoupon(payload))
  }

  return (
    <Dialog
      className='dialog400'
      open={open}
      onClose={handleClose}
      scroll='paper'
      maxWidth='md'
    >
      <DialogTitle className='dialog-title' sx={{ height: "2px", padding: 0 }}>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 5,
            top: 5,
            color: (theme) => theme.palette.grey[500],
          }}
          disabled={isSubscribing}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        className='dialog-content'
        dividers={true}
        sx={{ border: 0 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 1 }}
              className='image-banner'
            >
              <img src={Images.NoPlanSubs} alt='SubscribeImg' />
            </Box>
            <Typography
              variant='h4'
              component='h4'
              sx={{ textAlign: "center", fontWeight: 600, px: 2 }}
            >
              You are subscribing to{" "}
              <span className='blue-typo'>{getTitleOfPlanToSubscribe()}</span>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert
              severity='warning'
              icon={false}
              sx={{
                justifyContent: "center",
                color: "rgb(106 64 59)",
                backgroundColor: "rgb(245 221 200 / 45%)",
                textAlign: "center",
                px: 5,
              }}
            >
              <Stack
                direction='row'
                justifyContent='space-between'
                sx={{ my: 1 }}
              >
                <TextField
                  variant='outlined'
                  name='coupon-code'
                  placeholder='Enter Code'
                  size='small'
                  fullWidth
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value)
                    resetDiscountFields()
                  }}
                />
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  {" "}
                  <Button
                    variant='outlined'
                    sx={{ mx: 1 }}
                    onClick={validateCode}
                  >
                    {discountPrice !== null ? "Applied" : "Apply"}
                  </Button>
                  {discountPrice !== null && (
                    <DeleteSweepIcon
                      sx={{
                        cursor: "pointer",
                        color: "var(--primary)",
                      }}
                      onClick={handleClear}
                    />
                  )}
                </Stack>
              </Stack>
              {codeError && (
                <Typography
                  sx={{
                    marginTop: "5px",
                    color: "#FF4842 !important",
                    fontWeight: 400,
                    fontSize: "1rem",
                  }}
                >
                  {codeError}
                </Typography>
              )}
              You will be charged:
              <Typography
                component='span'
                fontWeight='bold'
                sx={{
                  textDecoration:
                    discountPrice !== null ? "line-through" : "none",
                }}
              >
                {" $"}
                {getPriceOfPlanToSubscribe()}
              </Typography>
              {discountPrice !== null && (
                <Typography component='span' sx={{ mx: 1, fontWeight: 600 }}>
                  {" $"}
                  {discountPrice.toFixed(2)}
                </Typography>
              )}
            </Alert>
          </Grid>
          {message && (
            <Grid item xs={12}>
              <Alert
                severity='error'
                icon={false}
                sx={{ justifyContent: "center", textAlign: "center", px: 5 }}
              >
                {message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions
        className='dialog-footer'
        sx={{ justifyContent: "center", pb: 2 }}
      >
        <Button
          onClick={handleClose}
          variant='outlined'
          color='secondary'
          sx={{
            mr: 1,
            backgroundColor: "transparent",
            borderColor: "#8b8e92",
            color: "#64748b",
            "&:hover": {
              opacity: "0.7",
              backgroundColor: "#8b8e92",
              borderColor: "#8b8e92",
              color: "#fff",
            },
          }}
          size='medium'
        >
          Cancel
        </Button>

        <LoadingButton
          onClick={confirmButtonClicked}
          loading={isSubscribing}
          variant='contained'
          color='info'
          size='medium'
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
