import * as ActionTypes from "src/redux/constants/landing"

const initialState = {
  msg: "",
  flag: null,
}
const landingReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.SUGGESTION_ON_SUCCESS) {
    return {
      ...state,
      data: action.payload,
      isSucceed: action.payload.success,
    }
  } else {
    return state
  }
}

export default landingReducer
