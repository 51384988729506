import { isEmpty } from "lodash"
import React, { useState, useEffect } from "react"
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Tabs,
  Tab,
} from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import Alert from "src/components/Alert"
import AddMemberModal from "src/sections/@dashboard/user/modal/addMemberModal"
import Page from "../components/Page"
import Scrollbar from "../components/Scrollbar"
import AddIcon from "@mui/icons-material/Add"
import SearchNotFound from "../components/SearchNotFound"
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../sections/@dashboard/user"
import { useSelector, useDispatch } from "react-redux"
import { teamDataParsing } from "../utils/dataParsing"
import { getAllDepartment } from "src/redux/actions/department"
import { getAllMembers, resetMembersFlag } from "src/redux/actions/member"
import { getAllProfiles } from "src/redux/actions/profile"
import { resetErrors } from "src/redux/actions/resetError"
import Loader from "src/components/Loader"
import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import { useNavigate } from "react-router-dom"
import ConfirmationModal from "src/sections/@dashboard/roles/modal/ConfirmationModal"
import { logout } from "src/redux/actions/logout"
// ----------------------------------------------------------------------

const TABLE_HEAD_ACTIVE = (t) => [
  { id: "name", label: t("UserName"), alignRight: false },
  { id: "email", label: t("EmailAddress"), alignRight: false },
  { id: "department", label: t("Departments"), alignItems: false },
  { id: "role", label: t("Role"), alignRight: false },
  { id: "", label: t("Action"), alignRight: true },
]

// ----------------------------------------------------------------------
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))
function TabPanel(props) {
  const { children, value, index, tabs, ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3, pl: 0, pr: 0 }}>{children}</Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function Team(props) {
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("asc")
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState("name")
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [isShowingMember, toggleMember] = useModal()
  const [isShowingModal, setIsShowing] = useModal()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const depts = useSelector((state) => state.departments)
  const members = useSelector((state) => state.members)
  const [memberList, setMemberList] = useState([])
  const dispatch = useDispatch()
  const checkCrash = useSelector((state) => state.crashManagement)
  const [displayCrash, setDisplayCrash] = useState("")
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [errorSeverity, setSeverity] = useState("")

  const updateCompanyInfoState = useSelector((state) => state.updateCompanyInfo)
  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission
  // Invoking Redux Structure
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  useEffect(() => {
    if (updateCompanyInfoState?.ownershipTransfer === true) {
      setOpenSnackbar(true)
      setDisplayCrash(updateCompanyInfoState?.message)
      setSeverity("success")

      setTimeout(() => {
        dispatch(logout())
      }, 5000)
    }
  }, [updateCompanyInfoState])

  useEffect(() => {
    if (checkCrash?.url?.split("/")?.includes("members") && !isEmpty(checkCrash?.message)) {
      if (checkCrash?.succeed === false || checkCrash?.apiError === true) {
        setOpenSnackbar(true)
        setDisplayCrash(checkCrash?.message)

        setSeverity("error")
        dispatch(resetErrors())
      }
    }
  }, [checkCrash])

  useEffect(() => {
    if (depts?.data?.length === 0) {
      dispatch(getAllDepartment())
    }
  }, [depts])

  const addMemberRes = useSelector((state) => state.addMember)
  const team = useSelector((state) => state.members)

  useEffect(() => {
    if (team?.isUpdated) {
      dispatch(getAllMembers())
      dispatch(resetMembersFlag())
    }

    if (team?.isCreated) {
      dispatch(getAllMembers())
      dispatch(resetMembersFlag())
    }

    if (team?.isDeleted) {
      dispatch(getAllMembers())
      dispatch(resetMembersFlag())
    }
  }, [team])

  useEffect(() => {
    if (addMemberRes?.status === 200 || addMemberRes?.data?.success === true) {
      dispatch(getAllProfiles())
    }
  }, [addMemberRes])

  useEffect(() => {
    if (members?.isFailure) {
      setOpenSnackbar(true)
      setDisplayCrash(members?.error)
      setSeverity("error")
      dispatch(resetMembersFlag())
    }

    if (members?.isActivated) {
      setOpenSnackbar(true)
      setDisplayCrash(members?.message)
      setSeverity("success")
      dispatch(resetMembersFlag())
    }

    if (members?.isCreated) {
      setOpenSnackbar(true)
      setDisplayCrash(members?.message)
      setSeverity("success")
      dispatch(resetMembersFlag())
    }

    if (members?.isUpdated) {
      setOpenSnackbar(true)
      setDisplayCrash(members?.message)
      setSeverity("success")
      dispatch(resetMembersFlag())
    }

    if (members?.isResend) {
      setOpenSnackbar(true)
      setDisplayCrash(members?.message)
      setSeverity("success")
      dispatch(resetMembersFlag())
    }

    if (members?.isDeleted) {
      setOpenSnackbar(true)
      setDisplayCrash(members?.message)
      setSeverity("success")
      dispatch(resetMembersFlag())
    }

    if (members?.data?.length === 0) {
      dispatch(getAllMembers())
    }
    if (!members?.data?.users) {
      setMemberList([])
    } else {
      setMemberList(teamDataParsing(members?.data?.users))
    }
  }, [members])

  const userType = useSelector((state) => state.profile)
  useEffect(() => {
    if (userType?.data?.length === 0) {
      dispatch(getAllProfiles())
    }
  }, [userType])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = memberList?.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
    setPage(0)
  }

  const showModal = () => {
    // const isConfigured = sessionStorage.getItem("smtp_configured")
    // if (
    //   logged?.user?.user_type === "admin" &&
    //   isConfigured !== "true" &&
    //   isEmpty(logged?.user?.account?.configuration.smtp_settings)
    // ) {
    //   setIsShowing()
    // } else {
    //   toggleMember()
    // }
    toggleMember()
  }

  const naviagateToSMTP = () => {
    window.history.pushState(null, "", "/dashboard/companyUsers")
    navigate("/dashboard/settings", { replace: true, state: { smtp: true } })
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - memberList?.length) : 0

  const isUserNotFound = memberList?.length === 0

  const [valuetab, setValuetab] = useState(0)

  const handleChangetab = (event, newValue) => {
    setPage(0)
    setValuetab(newValue)
  }

  const handlePagination = (value) => {
    switch (value) {
      case 0:
        return memberList
          ?.filter((item) =>
            item?.first_name
              ?.toLowerCase()
              ?.includes(filterName?.toLowerCase()),
          )
          ?.filter((user) => user.status === true)?.length
      case 1:
        return memberList
          ?.filter((item) =>
            item?.first_name
              ?.toLowerCase()
              ?.includes(filterName?.toLowerCase()),
          )
          ?.filter((user) => user.status === false)?.length

      default:
        return 0
    }
  }

  const loadingState = useSelector((state) => state.loadingManagement)

  const refreshTeam = () => {
    dispatch(getAllMembers())
  }

  const getCurrentTabUsersCount = () => {
    let status = false

    if (valuetab === 0) {
      status = true
    } else if (valuetab === 1) {
      status = false
    }
    if (memberList) {
      return memberList.filter((member) => member?.status === status).length
    }
    return 0
  }

  return (
    <Page title={t("CompanyUsers")} className='roles-page'>
      <Loader respLoading={loadingState?.loading} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={errorSeverity}
          sx={{ width: "100%" }}
        >
          {displayCrash}
        </Alert>
      </Snackbar>
      <Container maxWidth='xl' spacing={3}>
        {userPermissions?.company_user_module ? (
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              mb={5}
            >
              <Typography
                variant='h5'
                sx={{
                  mb: 0,
                  fontWeight: 500,
                  display: "flex",
                  flexDirection: "row",
                  color: "#434e58",
                }}
              >
                {t("CompanyUsers")}: {handlePagination(valuetab)}
              </Typography>
              {userPermissions?.company_user_user_can_add_company_users && (
                <Button
                  variant='contained'
                  className='add-btn'
                  startIcon={<AddIcon />}
                  onClick={showModal}
                >
                  <span className='hidden-xs'>{t("NewUser")}</span>
                  <span className='visible-xs'>{t("User")}</span>
                </Button>
              )}
            </Stack>

            <AddMemberModal
              isShowingMember={isShowingMember}
              hide={toggleMember}
              flag={true}
            />
            <ConfirmationModal
              isShowingModal={isShowingModal}
              hide={setIsShowing}
              isSMPT={true}
              message='Please configure your smtp in Settings.'
              confirmAction={naviagateToSMTP}
            />

            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={valuetab}
                  onChange={handleChangetab}
                  aria-label='basic tabs example'
                >
                  <Tab label={t("ActiveUsers")} {...a11yProps(0)} />
                  <Tab label={t("InActiveUsers")} {...a11yProps(1)} />
                </Tabs>
              </Box>

              <TabPanel>
                <Card>
                  <UserListToolbar
                    refreshTeam={refreshTeam}
                    numSelected={selected.length}
                    filterName={filterName}
                    members={selected}
                    onFilterName={handleFilterByName}
                    canSearch={
                      userPermissions?.company_user_user_can_search_company_users
                    }
                  />

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table className='simple-table'>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD_ACTIVE(t)}
                          rowCount={members?.data?.users?.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                          canEditHead={
                            userPermissions?.company_user_user_can_edit_company_users
                          }
                        />
                        <TableBody>
                          {memberList
                            ?.filter((item) =>
                              item?.name
                                ?.toLowerCase()
                                ?.replace(/ +/g, " ")
                                ?.includes(filterName?.toLowerCase()),
                            )
                            ?.filter((user) => {
                              if (valuetab === 0) {
                                return user?.status === true
                              }
                              if (valuetab === 1) {
                                return user?.status === false
                              }
                            })
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                            .map((row) => {
                              const {
                                id,
                                name,
                                email,
                                department,
                                role,
                                first_name,
                                last_name,
                              } = row

                              const isItemSelected =
                                selected.indexOf(name) !== -1

                              return (
                                <TableRow
                                  hover
                                  key={id}
                                  tabIndex={-1}
                                  role='checkbox'
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell
                                    component='th'
                                    scope='row'
                                    padding='none'
                                    sx={{
                                      width: "260px",
                                      maxWidth: "260px",
                                      paddingLeft: "16px",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <Typography
                                      variant='subtitle2'
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        textTransform: "capitalize",
                                      }}
                                      noWrap
                                    >
                                      <Avatar
                                        sx={{
                                          width: 30,
                                          height: 30,
                                          mr: 2,
                                          textTransform: "uppercase",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {first_name?.slice(0, 1) +
                                          last_name?.slice(0, 1)}
                                      </Avatar>
                                      {name}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    align='left'
                                    sx={{ width: "350px", maxWidth: "400px" }}
                                  >
                                    {email}
                                  </TableCell>
                                  <TableCell
                                    align='left'
                                    sx={{
                                      width: "360px",
                                      maxWidth: "360px",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {department.length > 1 ? (
                                      <div className='mulitpleDpt'>
                                        {department.map((item, index) => (
                                          <Typography
                                            key={item}
                                            sx={{
                                              display: "inline-block",
                                              fontWeight: 400,
                                              marginRight: "3px",
                                            }}
                                            component='span'
                                            variant='subtitle2'
                                          >
                                            {item}
                                          </Typography>
                                        ))}
                                      </div>
                                    ) : (
                                      <div> {department}</div>
                                    )}
                                  </TableCell>
                                  <TableCell
                                    align='left'
                                    sx={{ width: "160px", maxWidth: "160px" }}
                                  >
                                    {role}
                                  </TableCell>

                                  <TableCell
                                    align='right'
                                    width='8%'
                                    sx={{ width: "8%", maxWidth: "75px" }}
                                    className='stickyCell'
                                  >
                                    {(userPermissions?.company_user_user_can_edit_company_users ||
                                      userPermissions?.company_user_user_can_delete_company_users) ===
                                      true && (
                                      <UserMoreMenu
                                        id={id}
                                        tabing={
                                          valuetab === 0 ? "active" : "inactive"
                                        }
                                        canEdit={
                                          userPermissions?.company_user_user_can_edit_company_users
                                        }
                                        canDelete={
                                          userPermissions?.company_user_user_can_delete_company_users
                                        }
                                      />
                                    )}
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>

                        {(isUserNotFound ||
                          getCurrentTabUsersCount() === 0) && (
                          <TableBody>
                            <TableRow>
                              <TableCell className='searchCell' colSpan={5}>
                                <SearchNotFound searchQuery={filterName} />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component='div'
                    labelRowsPerPage={t("RowsPerPage")}
                    count={handlePagination(valuetab)}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </TabPanel>
            </Box>
          </>
        ) : (
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("NoAccessPAge")}
            </Typography>
            <Box
              component='img'
              src='/static/illustrations/illustration_404.svg'
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </ContentStyle>
        )}
      </Container>
    </Page>
  )
}
