import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import {
  Toolbar,
  Tooltip,
  IconButton,
  OutlinedInput,
  InputAdornment,
  Box,
} from "@mui/material"
import { useDispatch } from "react-redux"
import { deleteRole } from "src/redux/actions/roles"
import { useTranslation } from "react-i18next"
import RefreshIcon from "@mui/icons-material/Refresh"
import SearchIcon from "@mui/icons-material/Search"
import DeleteIcon from "@mui/icons-material/Delete"
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "&.Mui-focused": {
      width: "100%",
    },
  },
}))

// ----------------------------------------------------------------------

CandidateListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  handleFilterByName: PropTypes.func,
  uniqueRole: PropTypes.array,
  roles: PropTypes.array,
  roleCrud: PropTypes.object,
}

export default function CandidateListToolbar({
  numSelected,
  filterName,
  onFilterName,
  handleFilterByName,
  uniqueRole,
  roles,
  roleCrud,
  refreshCandidates,
  searchName,
  onFilterSearch,

  canSearch = true,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const matchedQuestionSet = (checkedName) => {
    for (let i = 0; i < roleCrud.length; i++) {
      if (checkedName.match(roleCrud[i].title)) {
        return [roleCrud[i], i]
      }
    }
  }
  const handleDelete = () => {
    roles.map((checkedName) => {
      const [match, index] = matchedQuestionSet(checkedName)
      dispatch(deleteRole(match.key))
      roleCrud.splice(index, 1)
    })
  }
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      <Box>
        {canSearch && (
          <SearchStyle
            id='search'
            value={searchName}
            onChange={onFilterSearch}
            placeholder={t("Search")}
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            }
          />
        )}
      </Box>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
        }}
      >
        {/* <Autocomplete
              onChange={(event, newValue, reason) => {
                handleFilterByName(newValue);
                if (reason === "clear") {
                  handleFilterByName("");
                }
              }}
              id="controllable-states-demo"
              options={uniqueRole?.filter((element) => element !== undefined)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="All Vacancies" />
              )}
            /> */}
        <Tooltip title={t("Refresh")} onClick={refreshCandidates}>
          <IconButton>
            <RefreshIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {numSelected > 0 ? (
        <Tooltip title={t("Delete")}>
          <IconButton onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </RootStyle>
  )
}
