import { useTheme, styled } from "@mui/material/styles"
import { useMemo, useState, useEffect } from "react"
import Page from "../components/Page"
import Label from "src/components/Label"
import { fDateShort, convertTimeZone } from "src/utils/formatTime"
import {
  AppCurrentVisits,
  AppConversionRates,
  AppStates,
} from "../sections/@dashboard/app"
import WelcomeModal from "src/sections/@dashboard/welcome/modal/welcomeModal"
import Scrollbar from "../components/Scrollbar"
import CompletedInterviewHead from "../sections/@dashboard/app/CompletedInterviews"
import { dashboardDataParsing } from "../utils/dataParsing"
import { activeUser } from "src/utils/userUtilities"
import { useDispatch, useSelector } from "react-redux"
import { dashboardInfo } from "src/redux/actions/dashboard"
import { changeProps } from "find-and"
import { compact, filter, parseInt } from "lodash"
import { resetErrors } from "src/redux/actions/resetError"
import {
  Table,
  Container,
  Grid,
  Typography,
  Box,
  CardHeader,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Card,
  TablePagination,
  TextField,
  Stack,
} from "@mui/material"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import Loader from "src/components/Loader"
import { IntDetailModal } from "src/sections/@dashboard/interview"
import { useTranslation } from "react-i18next"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { subMonths } from "date-fns"
import moment from "moment"
import {
  ViewActiveVacancy,
  ViewAwaitsInt,
  ViewCompanyUser,
  ViewPendingInt,
  ViewQuestionSet,
} from "src/sections/@dashboard/viewModals/index"
import { DBTimeZone } from "src/utils/constants"
import { currentBrowserName } from "src/utils/userHelper"
// ----------------------------------------------------------------------
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))
function descendingComparator(a, b, orderBy) {
  if (orderBy === "") return 0
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}
export default function DashboardApp({ isComplete = false }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isLoading, setIsLoading] = useState(true)
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [name] = useState(activeUser())
  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission
  const [pageLoaded, setPageLoaded] = useState(false)
  const [stats, setStats] = useState({
    active_roles: 0,
    interviews_pending: 0,
    interviews_archived: 0,
    completed_interviews: 0,
    active_users: 0,
    question_sets: 0,
  })

  const [dashboardList, setParsedDashboard] = useState([])

  let monthStart = new Date()
  monthStart = subMonths(monthStart, 1)

  if (
    moment(monthStart)
      .startOf("day")
      .isBefore(moment(logged?.user?.account?.created_at).startOf("day"))
  ) {
    monthStart = logged?.user?.account?.created_at
  }

  const [startDate, setStartDate] = useState(monthStart)
  const [endDate, setEndDate] = useState(new Date())
  const dashboard = useSelector((state) => state.dashboard)

  let trimSkills = dashboard?.data?.data?.skills

  trimSkills = trimSkills?.filter(function (str) {
    return /\S/.test(str)
  })
  // montly count
  const monthlyInterviewsCount = [
    { label: "January", value: 0 },
    { label: "February", value: 0 },
    { label: "March", value: 0 },
    { label: "April", value: 0 },
    { label: "May", value: 0 },
    { label: "June", value: 0 },
    { label: "July", value: 0 },
    { label: "August", value: 0 },
    { label: "September", value: 0 },
    { label: "October", value: 0 },
    { label: "November", value: 0 },
    { label: "December", value: 0 },
  ]
  const monthlyInterviewsData =
    dashboard?.data?.data?.interviews_by_monthly_count || {}

  const getMonthInterviewsData = useMemo(() => {
    let monthlyData = [...monthlyInterviewsCount]
    if (
      monthlyInterviewsData &&
      Object.prototype.toString.call(monthlyInterviewsData) ===
        "[object Object]" &&
      Object.keys(monthlyInterviewsData).length > 0
    ) {
      for (const [key, value] of Object.entries(monthlyInterviewsData)) {
        if (value !== undefined) {
          monthlyData = changeProps(
            monthlyData,
            { label: key },
            { value: value },
          )
        }
      }
      setIsLoading(false)
    }
    return monthlyData
  }, [monthlyInterviewsData])

  //recommended_candidate_rating
  const recommendedCandRatingArr = [
    { label: "0 Star", value: 0 },
    { label: "1 Star", value: 0 },
    { label: "2 Star", value: 0 },
    { label: "3 Star", value: 0 },
    { label: "4 Star", value: 0 },
    { label: "5 Star", value: 0 },
  ]

  const recommendedCandRatingData =
    dashboard?.data?.data?.recommended_candidate_rating || {}

  const getRecommendedCandData = useMemo(() => {
    let candidateData = [...recommendedCandRatingArr]

    if (
      recommendedCandRatingData &&
      Object.prototype.toString.call(recommendedCandRatingData) ===
        "[object Object]" &&
      Object.keys(recommendedCandRatingData).length > 0
    ) {
      for (const [key, value] of Object.entries(recommendedCandRatingData)) {
        if (value !== undefined) {
          candidateData = changeProps(
            candidateData,
            { label: key },
            { value: value },
          )
        }
      }
    }
    return candidateData
  }, [recommendedCandRatingData])

  //--------
  const totalCompletedInt = dashboard?.data?.data?.total_completed_interviews
  const totalPendingInt = dashboard?.data?.data?.current_pending_interviews
  const userSkills = trimSkills?.map((item, index) => {
    return (
      <Label
        variant='ghost'
        key={item}
        sx={{
          marginRight: "8px",
          marginBottom: "4px",
          marginTop: "6px",
          fontWeight: 500,
          fontSize: "12px",
          color: "#fff",
          background: "#2d99ff",
          padding: "1.0rem",
          maxWidth: " 247px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
        }}
      >
        {t(item)}
      </Label>
    )
  })

  const [module, setModule] = useState([])
  const dispatch = useDispatch()
  const goToRoles = () => {
    if (userPermissions?.vacancy_module === true) {
      toggleActiveVacancy()
    }
  }
  const goToPendingInterviews = () => {
    if (
      userPermissions?.interviews_module === true &&
      userPermissions?.candidates_user_can_view_feedback === true
    ) {
      togglePendingInt()
    }
  }
  const goToAwatingFeedback = () => {
    if (userPermissions?.interviews_module === true) {
      toggleAwaitInt()
    }
  }
  const goToQuestionSets = () => {
    if (userPermissions?.question_sets_module === true) {
      toggleQustionSet()
    }
  }

  const goToUsers = () => {
    if (userPermissions?.company_user_module === true) {
      toggleCompanyUser()
    }
  }
  const onKeyDown = (e) => {
    e.preventDefault()
  }

  const handleChange = (start, end) => {
    localStorage.setItem("dashSdate", start)
    localStorage.setItem("dashEdate", end)

    dispatch(dashboardInfo({ start: start, end: end }))
  }

  const handleStartChange = (value) => {
    setStartDate(value)
    handleChange(value, endDate)
  }

  const handleEndChange = (value) => {
    setEndDate(value)
    handleChange(startDate, value)
  }

  useEffect(() => {
    if (dashboard?.data?.length === 0 && pageLoaded) {
      localStorage.setItem("dashSdate", startDate)
      localStorage.setItem("dashEdate", endDate)
      setIsDataLoading(true)
      dispatch(dashboardInfo({ start: startDate, end: endDate }))
    }

    if (dashboard?.data?.success) {
      setIsDataLoading(false)
      setStartDate(localStorage.getItem("dashSdate"))
      setEndDate(localStorage.getItem("dashEdate"))
      setParsedDashboard(
        dashboardDataParsing(dashboard?.data?.data?.completed_interviews),
      )

      setStats({
        active_roles: dashboard?.data?.data?.total_opened_positions,
        interviews_pending: dashboard?.data?.data?.total_pending_interviews,
        interviews_archived: dashboard?.data?.data?.total_archived_interviews,
        completed_interviews: dashboard?.data?.data?.total_completed_interviews,
        active_users: dashboard?.data?.data?.users,
        question_sets: dashboard?.data?.data?.total_question_sets,
        awaiting_interviews: dashboard?.data?.data?.awaiting_interviews,
      })
    }
  }, [dashboard, pageLoaded])

  useEffect(() => {
    setModule(permissionManager())
  }, [stats])

  const permissionManager = () => {
    let arrList = []
    for (let key in userPermissions) {
      if (key.endsWith("counter")) {
        if (userPermissions[key] === true) {
          dashboardStats.forEach((val) => {
            if (key.match(val.key)) {
              arrList.push({
                title: val.title,
                color: val.color,
                func: val.func,
                icon: val.icon,
                total: val.total,
              })
            }
          })
        }
      }
    }
    return arrList
  }

  const checkCrash = useSelector((state) => state.crashManagement)
  const [isShowingCompanyUser, toggleCompanyUser] = useModal()
  const [isShowingActiveVacancy, toggleActiveVacancy] = useModal()
  const [isShowingPendingInt, togglePendingInt] = useModal()
  const [isShowingAwaitInt, toggleAwaitInt] = useModal()
  const [isShowingQustionSet, toggleQustionSet] = useModal()

  useEffect(() => {
    if (checkCrash?.succeed === false) {
      dispatch(resetErrors())
    }
  }, [checkCrash])

  const dashboardStats = [
    {
      id: 1,
      title: "ActiveVacancies",
      func: goToRoles,
      total: stats?.active_roles,
      color: "#0066e9",
      icon: "stats_1",
      key: "dashboard_active_vacancies_counter",
    },
    {
      id: 2,
      title: "InterviewsPending",
      func: goToPendingInterviews,
      total: stats?.interviews_pending,
      color: "#2e89ff",
      icon: "stats_2",
      key: "dashboard_interview_pending_counter",
    },
    {
      id: 3,
      title: "InterviewsAwaitingFeedback",
      func: goToAwatingFeedback,
      total: stats?.awaiting_interviews,
      color: "#5256ff",
      icon: "stats_3",
      key: "dashboard_interview_awaiting_feedbacks_counter",
    },

    {
      id: 5,
      title: "ActiveCompanyUsers",
      func: goToUsers,

      total: stats?.active_users,
      color: "#913aff",
      icon: "stats_5",
      key: "dashboard_active_users_counter",
    },
    {
      id: 6,
      title: "QuestionSets",
      func: goToQuestionSets,
      total: stats?.question_sets,
      color: "white",
      icon: "stats_6",
      key: "dashboard_question_sets_counter",
    },
  ]
  const [order] = useState("asc")
  const [orderBy] = useState("")
  const [filterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [page, setPage] = useState(0)

  const TABLE_HEAD = [
    { id: "name", label: t("Candidate"), alignRight: false },
    { id: "email", label: t("Email"), alignRight: false },
    { id: "title", label: t("Vacancy"), alignRight: false },
    { id: "scheduledDate", label: t("ScheduledDate"), alignRight: false },
    { id: "completedOn", label: t("CompletedOn"), alignRight: false },
  ]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1,
      )
    }
    return stabilizedThis.map((el) => el[0])
  }

  const filteredUsers = applySortFilter(
    dashboardList,
    getComparator(order, orderBy),
    filterName,
  )
  const isUserNotFound = filteredUsers.length === 0

  const [isShowingRole, toggleRole] = useModal()

  const currentUser = JSON.parse(localStorage.getItem("user"))

  useEffect(() => {
    if (
      document.readyState === "interactive" ||
      (document.readyState === "complete" &&
        (!isComplete || currentBrowserName() === "Safari"))
    ) {
      setPageLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (
      currentUser?.user?.first_login === false &&
      pageLoaded &&
      isDataLoading === false
    ) {
      toggleRole()
      currentUser.user.first_login = true
      sessionStorage.setItem("user", JSON.stringify(currentUser))
      localStorage.setItem("user", JSON.stringify(currentUser))
    }
  }, [pageLoaded, isDataLoading])

  const [userItem, setItem] = useState([])
  const handleInterview = (id, dash) => {
    let arrList = []
    const vacancy =
      dash?.data?.data?.completed_interviews[0]?.candidate_vacancy?.vacancy

    const filteredUsersData = filteredUsers
      .filter((user) => user?.id === id)
      .map((user) => ({
        id: user?.id,
        key: user?.key,
        interviewFormat: user?.interviewFormat,
        name: user?.name,
        email: user?.email,
        role: user?.role,
        intStatus: user?.intStatus,
        scheduleDate: user?.scheduleDate,
        completedOn: user?.completedOn,
        intExpireOn: user?.intExpireOn,
        department: user?.department,
        qualification: user?.qualification,
        skills: user?.skills,
        experience: user?.experience,
        vacancy: vacancy,
      }))

    arrList.push(...filteredUsersData)

    if (arrList?.length !== 0) {
      setItem(arrList)
    }
    toggleIntReview()
  }
  const [isShowingIntReview, toggleIntReview] = useModal()

  const completedIntList = userPermissions?.dashboard_completed_interviews_list
  const completedIntGraph =
    userPermissions?.dashboard_completed_interviews_graph
  const candidateRating = userPermissions?.dashboard_candidate_ratings
  const intByStatus = userPermissions?.dashboard_interviews_by_status
  const popularSkills = userPermissions?.dashboard_popular_skills

  return (
    <Page title={t("Dashboard")}>
      <Loader respLoading={isDataLoading} />
      <WelcomeModal isShowingRole={isShowingRole} hide={toggleRole} />
      <Container maxWidth='xl'>
        {userPermissions?.dashboard_module ? (
          <>
            <Typography
              variant='h4'
              sx={{ mb: 5, textTransform: "capitalize" }}
            >
              {t("Hi")}, {name?.first_name + " " + name?.last_name}
            </Typography>

            <Grid
              container
              className='state-box-list'
              spacing={3}
              mb={3}
              columns={{ xs: 12, sm: 6, md: 12 }}
            >
              {module?.map((dstats) => (
                <Grid key={dstats?.icon} item xs className='col'>
                  <AppStates dstats={dstats} t={t} />
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={3}>
              {completedIntList && (
                <Grid item xs={12} md={completedIntGraph ? 8 : 12}>
                  <Card className='colum-xs' sx={{ height: "100%" }}>
                    <CardHeader
                      action={
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Stack
                            className='disable-caret'
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                            sx={{ direction: "ltr " }}
                          >
                            <DesktopDatePicker
                              label='From'
                              inputFormat='dd/MM/yy'
                              value={startDate}
                              name='startDate'
                              minDate={
                                new Date(logged?.user?.account?.created_at)
                              }
                              maxDate={new Date(endDate)}
                              onChange={handleStartChange}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  sx={{ width: "124px", mr: 1 }}
                                  onKeyDown={onKeyDown}
                                  {...params}
                                />
                              )}
                              slotProps={{
                                textField: {
                                  helperText: "MM/DD/YYYY",
                                },
                              }}
                            />

                            <DesktopDatePicker
                              label='To'
                              inputFormat='dd/MM/yy'
                              value={endDate}
                              name='endDate'
                              minDate={new Date(startDate)}
                              maxDate={new Date()}
                              onChange={handleEndChange}
                              renderInput={(params) => (
                                <TextField
                                  size='small'
                                  sx={{ width: "124px" }}
                                  onKeyDown={onKeyDown}
                                  {...params}
                                />
                              )}
                            />
                          </Stack>
                        </LocalizationProvider>
                      }
                      title={t("CompletedInterviews")}
                      subheader={`${fDateShort(startDate)} to ${fDateShort(
                        endDate,
                      )}`}
                      sx={{ mb: 1 }}
                    />

                    <Box sx={{ height: "calc(100% - 160px)" }}>
                      <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                          <Table className='card-table'>
                            <CompletedInterviewHead
                              order={order}
                              orderBy={orderBy}
                              headLabel={TABLE_HEAD}
                              rowCount={dashboard?.length}
                            />
                            <TableBody>
                              {filteredUsers
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage,
                                )
                                .map((row) => {
                                  return (
                                    <TableRow
                                      hover
                                      key={row.id}
                                      tabIndex={-1}
                                      role='checkbox'
                                      sx={{
                                        cursor: "pointer",
                                        "&:hover": {
                                          background: "#c2d3f0 !important",
                                        },
                                      }}
                                      onClick={() =>
                                        handleInterview(row.id, dashboard)
                                      }
                                    >
                                      <TableCell
                                        align='left'
                                        sx={{ textTransform: "capitalize" }}
                                      >
                                        {row.name}
                                      </TableCell>
                                      <TableCell align='left'>
                                        {row.email}
                                      </TableCell>
                                      <TableCell align='left'>
                                        {row.role}
                                      </TableCell>
                                      <TableCell align='left'>
                                        {convertTimeZone(
                                          row.scheduleDate,
                                          row.timeZone,
                                        )}
                                      </TableCell>
                                      <TableCell align='left'>
                                        {convertTimeZone(
                                          row.completedOn,
                                          DBTimeZone,
                                          row.timeZone,
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>

                            {isUserNotFound && (
                              <TableBody>
                                <TableRow>
                                  <TableCell className='searchCell' colSpan={5}>
                                    <Typography variant='body2' align='left'>
                                      {t("NoCompletedIntFound")} &nbsp;
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </Table>
                          <IntDetailModal
                            isShowingIntReview={isShowingIntReview}
                            hide={toggleIntReview}
                            data={userItem}
                            type={""}
                            dashbord={true}
                          />
                        </TableContainer>
                      </Scrollbar>
                    </Box>

                    <TablePagination
                      rowsPerPageOptions={[5]}
                      component='div'
                      labelRowsPerPage={t("RowsPerPage")}
                      count={dashboardList?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Card>
                </Grid>
              )}
              {completedIntGraph &&
                dashboard?.data?.data?.completed_interviews && (
                  <Grid
                    item
                    xs={12}
                    md={completedIntList ? 4 : 12}
                    className='graph-int'
                  >
                    <AppConversionRates
                      t={t}
                      isLoading={isLoading}
                      title={t("CompletedInterviews")}
                      subheader={t("CompletedintPerMonth")}
                      chartData={getMonthInterviewsData}
                    />
                  </Grid>
                )}
              {candidateRating &&
                dashboard?.data?.data?.recommended_candidate_rating && (
                  <Grid item xs={12} md={6} lg={4} className='dash-card'>
                    <AppCurrentVisits
                      title={t("CandidatesRating")}
                      chartData={getRecommendedCandData}
                      chartColors={[
                        theme.palette.primary.main,
                        theme.palette.chart.blue[0],
                        theme.palette.chart.violet[0],
                        theme.palette.chart.yellow[0],
                        theme.palette.chart.blue[0],
                      ]}
                    />
                  </Grid>
                )}

              {/* test */}

              {intByStatus &&
                dashboard?.data?.data?.total_pending_interviews.length !== 0 &&
                dashboard?.data?.data?.total_completed_interviews.length !==
                  0 && (
                  <Grid item xs={12} md={6} lg={4} className='dash-card'>
                    <AppCurrentVisits
                      title={t("InterviewProgressByStatus")}
                      chartData={[
                        {
                          label: t("Completed"),
                          value: totalCompletedInt ? totalCompletedInt : 0,
                        },
                        {
                          label: t("Pending"),
                          value: totalPendingInt ? totalPendingInt : 0,
                        },
                      ]}
                      chartColors={[
                        theme.palette.primary.main,
                        theme.palette.chart.blue[0],
                        theme.palette.chart.violet[0],
                        theme.palette.chart.yellow[0],
                        theme.palette.chart.blue[0],
                      ]}
                    />
                  </Grid>
                )}

              {popularSkills && (
                <Grid item xs={12} md={6} lg={4} className='dash-card'>
                  <Card sx={{ height: "100%" }}>
                    <CardHeader title={t("TopSkills")} />
                    <Box
                      sx={{
                        px: 3,
                        pt: { xs: 1, sm: 3 },
                        pb: 1,
                        maxHeight: "350px",
                        overflowY: "auto",
                      }}
                    >
                      {userSkills?.length == 0 ? (
                        <Box>{t("DataNotFound")}</Box>
                      ) : (
                        <div>{compact(userSkills)}</div>
                      )}
                    </Box>
                  </Card>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("WelcomeProceedText")}
            </Typography>
            <Box
              component='img'
              src='/static/illustrations/welcome_illustration.png'
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </ContentStyle>
        )}
      </Container>
      {pageLoaded && (
        <>
          <ViewCompanyUser
            open={isShowingCompanyUser}
            hide={toggleCompanyUser}
            activeUser={stats?.active_users}
          />
          <ViewAwaitsInt open={isShowingAwaitInt} hide={toggleAwaitInt} />
          <ViewPendingInt open={isShowingPendingInt} hide={togglePendingInt} />
          <ViewActiveVacancy
            open={isShowingActiveVacancy}
            hide={toggleActiveVacancy}
          />
          <ViewQuestionSet open={isShowingQustionSet} hide={toggleQustionSet} />
        </>
      )}
    </Page>
  )
}
