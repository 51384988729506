export const FETCHINTERVIEWS_ON_SUCCESS = "FETCHINTERVIEWS_ON_SUCCESS";
export const FETCHINTERVIEWS_ON_FAILURE = "FETCHINTERVIEWS_ON_FAILURE";
export const FETCH_FILTERD_INTERVIEWS_ON_SUCCESS = "FETCH_FILTERD_INTERVIEWS_ON_SUCCESS";
export const FETCH_FILTERD_INTERVIEWS_ON_FAILURE = "FETCH_FILTERD_INTERVIEWS_ON_FAILURE";
export const CREATEINTERVIEW_ON_SUCCESS = "CREATEINTERVIEW_ON_SUCCESS";
export const CREATEINTERVIEW_ON_FAILURE = "CREATEINTERVIEW_ON_FAILURE";
export const FETCHINTERVIEWDETAIL_ON_SUCCESS =
  "FETCHINTERVIEWDETAIL_ON_SUCCESS";
export const FETCHINTERVIEWDETAIL_ON_FAILURE =
  "FETCHINTERVIEWDETAIL_ON_FAILURE";
export const SENDINVITATION_ON_SUCCESS = "SENDINVITATION_ON_SUCCESS";
export const SENDINVITATION_ON_FAILURE = "SENDINVITATION_ON_FAILURE";
export const DELETEINTERVIEW_ON_SUCCESS = "DELETEINTERVIEW_ON_SUCCESS";
export const DELETEINTERVIEW_ON_FAILURE = "DELETEINTERVIEW_ON_FAILURE";
export const RESET_FLAGS = "RESET_FLAGS";
export const UPDATEINTERVIEW_ON_SUCCESS = "UPDATEINTERVIEW_ON_SUCCESS";
export const UPDATEINTERVIEW_ON_FAILURE = "UPDATEINTERVIEW_ON_FAILURE";
export const ARCHIVEINTERVIEW_ON_SUCCESS = "ARCHIVEINTERVIEW_ON_SUCCESS";
export const ARCHIVEINTERVIEW_ON_FAILURE = "ARCHIVEINTERVIEW_ON_FAILURE";

export const FINISHINTERVIEW_ON_SUCCESS = "FINISHINTERVIEW_ON_SUCCESS";
export const FINISHINTERVIEW_ON_FAILURE = "FINISHINTERVIEW_ON_FAILURE";

export const GET_INTERVIEW_FEEDBACK_SUCCESS = "GET_INTERVIEW_FEEDBACK_SUCCESS";
export const GET_INTERVIEW_FEEDBACK_FAILURE = "GET_INTERVIEW_FEEDBACK_FAILURE";

export const ADD_INTERVIEW_COMMENT_SUCCESS = "ADD_INTERVIEW_COMMENT_SUCCESS";
export const ADD_INTERVIEW_COMMENT_FAILURE = "ADD_INTERVIEW_COMMENT_FAILURE";

export const VIEW_INTERVIEW_QUESTION_SUCCESS = "VIEW_INTERVIEW_QUESTION";
export const VIEW_INTERVIEW_QUESTION_FAILURE =
  "VIEW_INTERVIEW_QUESTION_FAILURE";

export const DELETE_INTERVIEW_COMMENT_SUCCESS =
  "DELETE_INTERVIEW_COMMENT_SUCCESS";
export const DELETE_INTERVIEW_COMMENT_FAILURE =
  "DELETE_INTERVIEW_COMMENT_FAILURE";

export const EDIT_COMMENT_SUCCESS = "EDIT_COMMENT_SUCCESS";
export const EDIT_COMMENT_FAILURE = "EDIT_COMMENT_FAILURE";

export const VIEW_CANDIDATE_HISTORY_SUCCESS = "VIEW_CANDIDATE_HISTORY";
export const VIEW_CANDIDATE_HISTORY_FAILURE = "VIEW_CANDIDATE_HISTORY_FAILURE";

export const UPDATE_INT_FEEDBACK_SUCCESS = "UPDATE_INT_FEEDBACK_SUCCESS";
export const UPDATE_INT_FEEDBACK_FAILURE = "UPDATE_INT_FEEDBACK_FAILURE";

export const ONSITE_INT_FEEDBACK_SUCCESS = "ONSITE_INT_FEEDBACK_SUCCESS";
export const ONSITE_INT_FEEDBACK_FAILURE = "ONSITE_INT_FEEDBACK_FAILURE";

export const ADD_INTERVIEW_QUESTION_FLAG_SUCCESS =
  "ADD_INTERVIEW_QUESTION_FLAG_SUCCESS";
export const ADD_INTERVIEW_QUESTION_FLAG_FAILURE =
  "ADD_INTERVIEW_QUESTION_FLAG_FAILURE";
export const CHANGE_INTERVIEW_STATUS_SUCCEESS =
  "CHANGE_INTERVIEW_STATUS_SUCCEESS";
export const CHANGE_INTERVIEW_STATUS_FAILURE =
  "CHANGE_INTERVIEW_STATUS_FAILURE";

export const DELETE_SELECTED_INTERVIEWS_ON_SUCCESS = 'DELETE_SELECTED_INTERVIEWS_ON_SUCCESS'
export const DELETE_SELECTED_INTERVIEWS_ON_FAILURE = 'DELETE_SELECTED_INTERVIEWS_ON_FAILURE'

export const RECOMMENDATIONS_SUCCESS = "RECOMMENDATIONS_SUCCESS";
export const RECOMMENDATIONS_FAILURE = "RECOMMENDATIONS_FAILURE";
