import React, { useState, useEffect, useRef } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Box,
  Checkbox,
} from "@mui/material"
import Snackbar from "@mui/material/Snackbar"
import MuiAlert from "@mui/material/Alert"
import Page from "../components/Page"
import Scrollbar from "../components/Scrollbar"
import SearchNotFound from "../components/SearchNotFound"
import {
  DepartmentListHead,
  DepartmentListToolbar,
  DepartmentMoreMenu,
} from "../sections/@dashboard/department"
import { useDispatch, useSelector } from "react-redux"
import { departmentDataParsing } from "../utils/dataParsing"
import {
  getAllDepartment,
  resetDepartmentFlag,
} from "src/redux/actions/department"
import { CreateDeptModal } from "src/sections/@dashboard/department"
import { resetErrors } from "src/redux/actions/resetError"
import Loader from "src/components/Loader"
import { styled } from "@mui/material/styles"
import ParagraphWithReadMore from "src/components/ParagraphWithReadMore"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import departmentArr from "src/_mock/departmentArr"
import { resetConfigurationsFlag } from "src/redux/actions/accountConfig"
import AddIcon from "@mui/icons-material/Add"
// ----------------------------------------------------------------------

const TABLE_HEAD = (t) => [
  { id: "name", label: t("DepartmentName"), alignRight: false },
  { id: "skills", label: t("Skills"), alignRight: false },
  { id: "description", label: t("Description"), alignRight: false },
  { id: "", label: t("Action"), alignRight: true },
]
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))
// ----------------------------------------------------------------

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export default function User() {
  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission
  let creationInfo = JSON.parse(sessionStorage.getItem("creation_info"))
  const [departmentShows, setDepartmentShows] = useState(
    creationInfo?.departments ?? false,
  )

  const depts = useSelector((state) => state.departments)
  const config = useSelector((state) => state.accountConfigurations)
  const [departmentList, setParsedDepartments] = useState([])

  const dispatch = useDispatch()
  const createDepartment = useSelector((state) => state.createDepart)

  useEffect(() => {
    if (createDepartment?.data?.lenght === 0) {
      dispatch(getAllDepartment())
    }
    if (createDepartment?.isCreated) {
      setOpen(true)
      setDisplayCrash(createDepartment?.message)
      setSeverity("success")
      dispatch(resetDepartmentFlag())
    }
    if (createDepartment?.isFailure) {
      setOpen(true)
      setDisplayCrash(createDepartment?.error)
      setSeverity("error")
      dispatch(resetDepartmentFlag())
    }
  }, [createDepartment])

  useEffect(() => {
    if (depts?.data?.length === 0) {
      dispatch(getAllDepartment())
    }
    if (depts?.data?.success) {
      if (departmentShows !== true)
        setParsedDepartments([
          ...departmentDataParsing(depts?.data?.departments),
          ...departmentArr,
        ])
      else setParsedDepartments(departmentDataParsing(depts?.data?.departments))
    }

    if (depts?.isUpdated || depts?.isDeleted) {
      dispatch(getAllDepartment())

      setOpen(true)
      setDisplayCrash(depts.message)
      setSeverity("success")
      dispatch(resetDepartmentFlag())
    }
  }, [depts, departmentShows])

  useEffect(() => {
    if (config?.edited) {
      setDepartmentShows(true)
      setSeverity("success")
      setOpen(true)
      setDisplayCrash(config.message)
      dispatch(resetConfigurationsFlag())
    }
  }, [config])

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const [displayCrash, setDisplayCrash] = useState("")
  const [errorSeverity, setSeverity] = useState("")
  const { t } = useTranslation()
  const checkCrash = useSelector((state) => state.crashManagement)
  useEffect(() => {
    if (checkCrash?.url?.split("/")?.includes("departments")) {
      if (checkCrash?.succeed === false || checkCrash?.apiError === true) {
        setOpen(true)
        setDisplayCrash(checkCrash?.message)

        setSeverity("error")

        dispatch(resetErrors())
      }
    }
  }, [checkCrash])

  const [open, setOpen] = useState(false)
  const [page, setPage] = useState(0)

  const [order, setOrder] = useState("asc")
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState("name")
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = departmentList?.map((n) => n.id)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = [...selected, id]
    } else {
      newSelected = selected.filter((selectedId) => selectedId !== id)
    }
    setSelected(newSelected)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value)
    setPage(0)
  }

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - departmentList?.length)
      : 0

  const isUserNotFound = departmentList?.length === 0

  const [isShowingDpt, toggleDpt] = useModal()
  const loadingState = useSelector((state) => state.loadingManagement)

  const refresh = () => {
    dispatch(getAllDepartment())
  }

  const maxLength = 150
  const [rowHeight, setRowHeight] = useState(0)
  const cellRef = useRef(null)
  useEffect(() => {
    if (cellRef.current) {
      const newHeight = cellRef.current.clientHeight
      setRowHeight(newHeight > 68 ? newHeight : 68)
    }
  }, [depts, departmentShows])

  return (
    <Page title={t("Departments")} className='question-page'>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={errorSeverity}
          sx={{ width: "100%" }}
        >
          {displayCrash}
        </Alert>
      </Snackbar>
      <Loader respLoading={loadingState?.loading} />
      <Container maxWidth='xl' spacing={3}>
        {userPermissions?.departments_module ? (
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
              mb={5}
            >
              <Typography
                variant='h5'
                sx={{
                  mb: 0,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  color: "#434e58",
                }}
              >
                {t("Departments")}: {departmentList?.length}
              </Typography>

              {userPermissions?.departments_user_can_add_department && (
                <Button
                  variant='contained'
                  component={RouterLink}
                  to='#'
                  startIcon={<AddIcon />}
                  onClick={toggleDpt}
                  className='add-btn'
                >
                  <span className='hidden-xs '>{t("NewDepartment")}</span>
                  <span className='visible-xs'>{t("Add")}</span>
                </Button>
              )}
            </Stack>

            <Card>
              <DepartmentListToolbar
                filterName={filterName}
                refresh={refresh}
                onFilterName={handleFilterByName}
                departmentIds={selected}
                resetSelected={setSelected}
                canSearch={
                  userPermissions?.departments_user_can_search_department
                }
                numSelected={selected.length}
              />

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table className='simple-table'>
                    <DepartmentListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD(t)}
                      rowCount={departmentList?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {departmentList
                        ?.filter((item) =>
                          item.name
                            .toLowerCase()
                            .includes(filterName.toLowerCase()),
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row) => {
                          const { id, name, description, skills } = row
                          const isItemSelected = selected.indexOf(id) !== -1

                          return (
                            <TableRow
                              hover
                              key={id}
                              tabIndex={-1}
                              role='checkbox'
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              ref={cellRef}
                              sx={{
                                verticalAlign:
                                  rowHeight > 68 ? "text-top" : "inherit",
                              }}
                            >
                              <TableCell padding='checkbox'>
                                <Checkbox
                                  key={id}
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, id)}
                                />
                              </TableCell>
                              <TableCell
                                component='th'
                                className=''
                                scope='row'
                                padding='none'
                                sx={{ width: "200px", maxWidth: "260px" }}
                              >
                                <Typography
                                  variant='subtitle3'
                                  sx={{
                                    minWidth: { sm: "150px", md: "260px" },
                                  }}
                                >
                                  {name}
                                </Typography>
                              </TableCell>

                              <TableCell align='left' width='230'>
                                <Stack minWidth='230px'>
                                  {skills?.join(", ")}
                                </Stack>
                              </TableCell>
                              <TableCell
                                align='left'
                                sx={{ wordBreak: "break-word" }}
                              >
                                <ParagraphWithReadMore
                                  text={description}
                                  maxLength={maxLength}
                                />
                              </TableCell>
                              <TableCell
                                align='right'
                                width='8%'
                                className='stickyCell'
                                sx={{ width: "150px" }}
                              >
                                {(userPermissions?.departments_user_can_edit_department ||
                                  userPermissions?.departments_user_can_delete_department) ===
                                  true && (
                                  <DepartmentMoreMenu
                                    name={name}
                                    deptCrud={depts}
                                    userPermissions={userPermissions}
                                    isSample={id === "dept01"}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          )
                        })}

                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell className='searchCell' colSpan={3}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                labelRowsPerPage={t("RowsPerPage")}
                count={
                  departmentList?.filter((item) =>
                    item.name.toLowerCase().includes(filterName.toLowerCase()),
                  ).length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            <CreateDeptModal
              isShowingDpt={isShowingDpt}
              hide={toggleDpt}
              flag={true}
            />
          </>
        ) : (
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("NoAccessPAge")}
            </Typography>
            <Box
              component='img'
              src='/static/illustrations/illustration_404.svg'
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </ContentStyle>
        )}
      </Container>
    </Page>
  )
}
