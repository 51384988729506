import * as ActionTypes from "../constants/resetPassword";
import * as ERRORS from "../constants/errorManagement";

const initialState = { data: [], status: null };

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RESET_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: action.payload.status,
      };

    case ActionTypes.RESET_ON_FAILURE:
      return {
        ...state,
        message: action.payload?.data?.errors ?? action.payload?.errors,
        status: action.payload.status,
        success: action.payload.data?.success ?? action.payload?.success,
      };
    case ERRORS.API_FAILURE:
      const obj3 = {
        ...state,
        status:action?.error?.response?.data?.success,
        message: action?.error?.response?.data?.errors
        // status: action.error.response.status,
        // message: action.error.response.data.errors,
      };
      return obj3;

    default:
      return state;
  }
};

export default resetPasswordReducer;
