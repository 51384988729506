import React from "react"
import PropTypes from "prop-types"
import { alpha, styled } from "@mui/material/styles"
import { Card, Typography } from "@mui/material"
import plansInclude from "../../../_mock/plansInclude"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}))

// ----------------------------------------------------------------------

AppWidgetPlansInclude.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
}

export default function AppWidgetPlansInclude({
  title,
  icon,
  color = "primary",
  sx,
  iconWidth,
  iconHeight,
  ...other
}) {
  const { t } = useTranslation()
  return (
    <Card
      className='h-100 w-100 mini-feature '
      sx={{
        px: 2,
        pb: 4,
        boxShadow: 0,
        textAlign: "center",
        justifyContent: "center",
        display: "flex",
        color: "white",
        bgcolor: "transparent",
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        className='image-box'
        sx={{
          justifyContent: "center",
          alignContent: "center",
          display: "flex",
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(
              theme.palette[color].dark,
              0,
            )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
        }}
      >
        <img src={icon} alt={title} width={iconWidth} height={iconHeight} />
      </IconWrapperStyle>
      <Typography variant='h4'>{t(title)}</Typography>
    </Card>
  )
}
