export const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessHeader");
  localStorage.setItem("logged", false);
  sessionStorage.setItem("logged", false);
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("smtp_configured");
  sessionStorage.removeItem("creation_info");
  sessionStorage.removeItem("accessHeader");
  window.location.pathname = "./login";
};
