import { Outlet } from "react-router-dom"
import {
  Grid,
  Container,
  Typography,
  Box,
  Link,
  Toolbar,
  Stack,
} from "@mui/material"
import React, { useState } from "react"
import { styled } from "@mui/material/styles"
import Images from "../../../assets/img/Images"
import ContactForm from "../../../sections/@landingPage/form/ContactForm"
import { useTranslation } from "react-i18next"
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined"
// ----------------------------------------------------------------------

const FooterStyle = styled("footer")(({ theme }) => ({
  width: "100%",
  padding: "24px 0 0.4rem 0",
}))

function Copyright() {
  const { t } = useTranslation()
  return (
    <Typography
      variant='body2'
      className='copyright'
      sx={{
        color: "#9498a8",
        fontSize: "14px !important",
        mt: 0,
        lineHeight: "1.2444em",
        minHeight: "20px",
        pb: "0.5rem",
      }}
    >
      {"© "}
      {new Date().getFullYear()} IntVue. {t("AllRightsReserved")}
    </Typography>
  )
}

export default function LandingFooter() {
  const [visible, setVisible] = useState(false)

  const { t } = useTranslation()

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop
    if (scrolled > 300) {
      setVisible(true)
    } else if (scrolled <= 300) {
      setVisible(false)
    }
  }
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  window.addEventListener("scroll", toggleVisible)
  return (
    <Container
      maxWidth='xxl'
      spacing={3}
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important" }}
    >
      <FooterStyle>
        <Container maxWidth='xl'>
          <Grid container spacing={3} id='contactDiv'>
            <Grid
              item
              sx={{ pt: 6, pb: { xs: 0, sm: 0, md: 5 }, mt: 2 }}
              md={4}
              lg={4}
            >
              <Typography
                className='dashboard-head'
                variant='h2'
                sx={{ mb: 2 }}
              >
                <span className='font300'>Talk To</span> Us
              </Typography>
              <Typography sx={{ pb: 4 }} variant='body1'>
                {t("GetInTouch")}
              </Typography>
              <Box
                className='landing-footer-logo'
                sx={{ display: { sm: "none", xs: "none", md: "block" } }}
              >
                <img src={Images.LogoMain} width='190' alt='txt' />
              </Box>
            </Grid>
            <Grid
              item
              sx={{
                pt: { xs: 0, md: 6, sm: 0 },
                pb: 5,
                mt: { md: 2, sm: 0 },
                ml: "auto",
                xs: { ml: 0 },
              }}
              xs={12}
              md={7}
              lg={7}
            >
              <ContactForm />
            </Grid>
          </Grid>
        </Container>
        <hr className='ftr-hr' />
        <Container maxWidth='xl' sx={{ pb: 0 }}>
          <Grid
            container
            spacing={0}
            sx={{
              justifyContent: {
                xs: "center",
                sm: "space-between",
                md: "space-between",
              },
            }}
          >
            <Grid item xs={12}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems='center'
                justifyContent={{ xs: "center", sm: "space-between" }}
              >
                <Copyright t={t} />
                <Toolbar
                  component='nav'
                  variant='dense'
                  className='footer-links'
                  sx={{
                    justifyContent: "flex-end",
                    overflowX: "auto",
                    minHeight: "20px",
                  }}
                >
                  <Link
                    color='inherit'
                    underline='none'
                    noWrap
                    variant='body2'
                    href='/termsconditions/app'
                    sx={{ p: 1, flexShrink: 0 }}
                  >
                    {t("TermsService")}
                  </Link>
                  <Link
                    color='inherit'
                    underline='none'
                    noWrap
                    variant='body2'
                    href='/privacypolicy/app'
                    sx={{ p: 1, flexShrink: 0 }}
                  >
                    {t("PrivacyPolicy")}
                  </Link>
                  <Link
                    color='inherit'
                    underline='none'
                    noWrap
                    variant='body2'
                    href='/cookies-policy/app'
                    sx={{ p: 1, flexShrink: 0 }}
                  >
                    {t("Cookie Policy")}
                  </Link>
                </Toolbar>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </FooterStyle>
      <button
        type='button'
        className='btn-scroll'
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      >
        <ArrowCircleUpOutlinedIcon sx={{ width: "40px", height: "40px" }} />
      </button>
      <Outlet />
    </Container>
  )
}
