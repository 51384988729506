import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Navigate } from "react-router-dom"
import { styled } from "@mui/material/styles"
import { Card, Container, Typography, Grid, Box } from "@mui/material"
import Slider from "react-slick"
import Page from "src/components/Page"
import Logo from "src/components/Logo/Logo"
import Images from "src/assets/img/Images"
import { SignUpForm } from "src/sections/auth/signup"
import { planDataParsing } from "src/utils/dataParsing"
import { getPlans } from "src/redux/actions/subscription"
import useResponsive from "src/hooks/useResponsive"
import { selectedPlan } from "src/redux/actions/pickedPlan"

//-----------------------
const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}))

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 920,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: 0,
  minHeight: 830,
  height: "100vh",
}))

const ContentStyle = styled("div")(({ theme }) => ({
  width: "100%",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  margin: 0,
  paddingRight: "3rem",
  height: "100%",
  paddingLeft: "2rem",
}))

const BannerBox = styled("div")(({ theme }) => ({}))

const BannerBoxImg = styled("img")(({ theme }) => ({
  maxWidth: 400,
  width: "100%",
}))

export default function SignUp() {
  const getPlansData = useSelector((state) => state.plansList)
  const [parsedPlans, setParsedPlans] = useState([])

  const [signedUp, setSignedUp] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    if (parsedPlans.length === 0) {
      dispatch(getPlans())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const plansList = getPlansData?.plans

    if (plansList && plansList.length > 0) {
      const allPlans = planDataParsing(plansList)
      const basicPlan = allPlans.find((plan) => plan.id === 1)
      setParsedPlans([basicPlan])
      dispatch(selectedPlan(basicPlan))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPlansData])

  const showSuccessMsg = false
  const lgUp = useResponsive("up", "md")

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 3000,
    autoplay: true,
    className: "slider-text",
  }

  const user = localStorage.getItem("user")
  if (user?.success === true) {
    return <Navigate to='/dashboard/app' />
  }

  return (
    <Page title='Signup' className='auth-screen'>
      <RootStyle className='auth-screen-row'>
        <Container
          maxWidth='xxl'
          spacing={0}
          sx={{ padding: "0 !important" }}
          className='w-100'
        >
          <Grid container spacing={2}>
            {lgUp && (
              <Grid
                item
                xl={5}
                lg={5}
                md={5}
                sm={5}
                sx={{
                  display: {
                    xl: "flex",
                    sm: "flex",
                    xs: "none",
                  },
                }}
              >
                <SectionStyle
                  className='left-panel'
                  sm={5}
                  sx={{
                    height: "100%",
                    maxWidth: { xl: "80%", lg: "100%" },
                    backgroundColor: "",
                  }}
                >
                  <div className='left-panel-inner'>
                    <Typography
                      className='logo-box'
                      variant='h3'
                      sx={{ pr: 5, pl: 0, pt: 10, mb: 4 }}
                    >
                      <Logo icon={Images.LogoWhite} />
                    </Typography>
                    <Box>
                      <BannerBox className='banner' sx={{ mt: 4, mb: 2 }}>
                        <BannerBoxImg src={Images.SingupImage} alt='login' />
                      </BannerBox>
                      <Slider {...settings}>
                        <div className='carousel-item active'>
                          <Typography variant='h4'>
                            No Credit Card Needed
                          </Typography>
                          <Typography component='p'>
                            To avail the free trial for 30 days straight, credit
                            card is not a requirement.
                          </Typography>
                        </div>

                        <div className='carousel-item '>
                          <Typography variant='h4'>Private & Secure</Typography>
                          <Typography component='p'>
                            IntVue provides stringent data protection. Your data
                            will remain secure and confidential at all times.
                          </Typography>
                        </div>

                        <div className='carousel-item '>
                          <Typography variant='h4'>
                            Easy Cancellation
                          </Typography>
                          <Typography component='p'>
                            Easily unsubscribe from the desired plan in case you
                            decide that it isn’t working out for you.
                          </Typography>
                        </div>
                      </Slider>
                    </Box>
                  </div>
                </SectionStyle>
              </Grid>
            )}
            <Grid
              item
              xl={7}
              lg={7}
              md={7}
              sm={7}
              xs={12}
              className='right-panel'
              sx={{ background: "#fff" }}
            >
              <ContentStyle className='right-panel-inner' sx={{ py: 2 }}>
                <Typography
                  className='logo-mb'
                  variant='h3'
                  sx={{
                    px: 0,
                    pt: { xs: 2, sm: 4, md: 10 },
                    mb: { xs: 2, sm: 4, md: 5 },
                    display: { sm: "block", lg: "block", xl: "none" },
                  }}
                >
                  <img src={Images.LogoMain} alt='LogoMain' />
                </Typography>
                {signedUp === false && (
                  <>
                    <Typography
                      variant='h3'
                      sx={{
                        fontSize: { xs: "1.5rem", sm: "2.6rem" },
                        color: "#041121",
                      }}
                      gutterBottom
                    >
                      Welcome to IntVue.
                    </Typography>
                    <Typography
                      sx={{
                        color: "#041121",
                        mb: 3,
                        mt: { xs: 1, sm: 2 },
                        fontSize: { xs: "1.0rem", sm: "1.2rem", md: "18px" },
                      }}
                      hidden={showSuccessMsg}
                    >
                      To set up your account and sign up for the best-suited
                      plan for your company, please enter some basic
                      information. You can get great benefits from the selected
                      plan.
                    </Typography>
                  </>
                )}
                <SignUpForm
                  parsedPlans={parsedPlans}
                  onSignUpSuccess={() => setSignedUp(true)}
                />
              </ContentStyle>
            </Grid>
          </Grid>
        </Container>
      </RootStyle>
    </Page>
  )
}
