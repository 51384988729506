export const verifyData = async (key, value) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL

  try {
    const response = await fetch(
      `${BASE_URL}/api/admin/general/verify_email_presence?${key}=${value}`
    )
    const data = await response.text()

    return data ? JSON.parse(data) : {}
  } catch (error) {
    return {}
  }
};

