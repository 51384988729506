import Label from "src/components/Label"
import { Box, Button, Typography, Rating } from "@mui/material"
import ParagraphWithSeeMoreLink from "src/components/ParagraphWithSeeMoreLink"
// ----------------------------------------------------------------------
export default function RecommendationTooltip({
  data,
  isRecommeded,
  setDataRec,
  toggleRecModal,
}) {
  const maxLength = 40
  return data?.map((rec, index) => {
    return (
      <Box
        key={rec.id + rec.key}
        className='listing'
        sx={{
          mb: 1,
          pb: 1,
        }}
      >
        <Typography
          variant='body1'
          sx={{
            marginBottom: "5px",
            fontWeight: "600 !important",
            wordWrap: "normal",
          }}
        >
          {rec?.user?.first_name + " " + rec?.user?.last_name}
        </Typography>

        <Typography
          variant='body1'
          sx={{
            mb: { xs: 2, sm: 0 },
            display: "flex",
            alignItems: "center",
          }}
        >
          <Rating name='read-only' value={rec?.rating} readOnly />
        </Typography>
        <div>
          <Label
            sx={{
              fontWeight: "600 !important",
              my: 1,
              marginBottom: "5px",
            }}
            variant='ghost'
            color={rec?.is_recommended ? "success" : "error"}
          >
            {rec?.is_recommended ? "Recommended" : "Not Recommended"}
          </Label>
        </div>
        {rec?.text && (
          <Box className='mini-box'>
            <ParagraphWithSeeMoreLink text={rec?.text} maxLength={maxLength} />
          </Box>
        )}
        {rec?.feedback_details && (
          <Button
            variant='outlined'
            sx={{ mt: 1, mb: 1 }}
            color='info'
            size='small'
            onClick={(e) => {
              toggleRecModal(e)
              setDataRec(rec.id)
            }}
          >
            Details
          </Button>
        )}
      </Box>
    )
  })
}
