import React, { useEffect, useState } from "react"
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Rating,
  Stack,
  Select,
  MenuItem,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Form, FormikProvider, useFormik } from "formik"
import { useTranslation } from "react-i18next"
//-----------------------------------------

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
}

const rating = [
  { id: 1, name: "Excellent" },
  { id: 2, name: "Good" },
  { id: 3, name: "Fair" },
  { id: 4, name: "Poor" },
]
//-----------------------------------------
export default function ViewFeedbackModal({
  hide,
  isShowingViewFeedback,
  data = null,
}) {
  const { t } = useTranslation()
  const [initialState, setInitialState] = useState({
    text: "",
    rating: 0,
    is_recommended: "",
    time: "12321",
    user_id: "",
    feedback_details: {},
  })

  const resetFields = () => {
    hide()
  }

  const getKeys = () => {
    let keysToKeep = []
    Object.keys(initialState.feedback_details).forEach((key) => {
      if (
        key !== "user_id" &&
        !keysToKeep.includes(key.replace(" Comment", ""))
      ) {
        keysToKeep.push(key.replace(" Comment", ""))
      }
    })
    return keysToKeep
  }

  useEffect(() => {
    if (data) {
      setInitialState({
        text: data?.text,
        rating: data?.rating,
        is_recommended: data.is_recommended,
        feedback_details: data.feedback_details,
      })
    }
  }, [data])

  const formik = useFormik({
    initialValues: initialState,
  })

  return (
    isShowingViewFeedback && (
      <Dialog
        className='dialog800'
        open={isShowingViewFeedback}
        onClose={resetFields}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {t("Recommendation")}{" "}
          <IconButton
            aria-label='close'
            onClick={resetFields}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <FormikProvider value={formik}>
            <Form id='completeRole' autoComplete='off' noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography sx={{ mb: 1 }} component='h6' variant='h6'>
                    {t("RateCandidate?")}
                  </Typography>
                  <Rating
                    readOnly
                    name='half-rating'
                    value={initialState.rating}
                    sx={{ display: "inline-flex", alignItems: "center" }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                    }}
                  >
                    <FormLabel
                      sx={{
                        mr: { xs: 0, sm: 2 },

                        fontWeight: 500,
                        color: "rgba(0, 0, 0, 0.6)",
                        "&:focus": {
                          color: "rgba(0, 0, 0, 0.6)",
                        },
                      }}
                      required
                    >
                      {t("RecommendCandidate")}
                    </FormLabel>

                    <RadioGroup
                      row
                      sx={{ flexDirection: "row" }}
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      id=''
                    >
                      <FormControlLabel
                        disabled
                        value='true'
                        checked={initialState.is_recommended === true}
                        control={<Radio />}
                        label={t("Yes")}
                      />
                      <FormControlLabel
                        disabled
                        value='false'
                        checked={initialState.is_recommended === false}
                        control={<Radio />}
                        label={t("No")}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl sx={{ width: "100%" }}>
                    <FormLabel
                      sx={{
                        fontWeight: 500,
                        mb: 1,
                        mr: 2,

                        color: "rgba(0, 0, 0, 0.6)",
                        "&:focus": {
                          color: "rgba(0, 0, 0, 0.6)",
                        },
                      }}
                    >
                      {t("EnterComment")}
                    </FormLabel>
                    <TextField
                      disabled
                      fullWidth
                      multiline
                      value={initialState.text}
                      rows={2}
                      variant='outlined'
                      autoComplete=''
                      placeholder={t("ShareThoughts")}
                      name='text'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <>
                    <Typography sx={{ mt: 1 }} component='h6' variant='h6'>
                      Additional Feedback
                    </Typography>
                    <Grid container spacing={2}>
                      {getKeys().map((feedbackItem) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sx={{
                              pt: 1,
                            }}
                          >
                            <Stack
                              direction={{ xs: "column", sm: "row" }}
                              justifyContent='space-between'
                            >
                              <Typography
                                variant='body2'
                                component='span'
                                sx={{
                                  fontWeight: 500,
                                  fontSize: "1.0rem",
                                  my: 1,
                                  display: "inline-flex",
                                  alignItems: "center",
                                }}
                              >
                                {feedbackItem}
                              </Typography>

                              <FormControl sx={{ mb: 1 }}>
                                <Select
                                  displayEmpty
                                  id={`${feedbackItem} select`}
                                  name={`${feedbackItem}`}
                                  MenuProps={MenuProps}
                                  value={
                                    initialState?.feedback_details
                                      ? initialState?.feedback_details[
                                          feedbackItem
                                        ]
                                      : ""
                                  }
                                  disabled
                                  size='small'
                                  sx={{
                                    width: { xs: "100%", sm: "180px" },
                                    cursor: "auto",
                                  }}
                                >
                                  <MenuItem
                                    sx={{
                                      fontWeight: 600,
                                      color: "#5082dd",
                                      opacity: "1 !important",
                                    }}
                                    value=''
                                    disabled
                                  >
                                    <em>Select... </em>
                                  </MenuItem>

                                  {rating?.map((option) => (
                                    <MenuItem
                                      key={option.id}
                                      value={option.name.toLowerCase()}
                                    >
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Stack>

                            <FormControl sx={{ width: "100%" }}>
                              <TextField
                                disabled
                                fullWidth
                                multiline
                                rows={2}
                                type='text'
                                variant='outlined'
                                autoComplete=''
                                placeholder={t("AddComment")}
                                name={`${feedbackItem} Comment`}
                                value={
                                  initialState?.feedback_details
                                    ? initialState?.feedback_details[
                                        `${feedbackItem} Comment`
                                      ]
                                    : ""
                                }
                              />
                            </FormControl>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>

        <DialogActions className='dialog-footer'>
          <Button
            onClick={resetFields}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
