import RecordRTC from "recordrtc"
import Measure from "react-measure"
import { useUserMedia } from "./useUserMedia"
import ClipLoader from "react-spinners/ClipLoader"
import { useState, useEffect, useRef } from "react"
import { useCameraOffsets } from "./useCameraOffsets"
import { Box, Button, Typography } from "@mui/material"
import { useCameraCardRatio } from "./useCameraCardRatio"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { useTranslation } from "react-i18next"

export function useCamera() {
  const videoRef = useRef()
  const recorder = useRef()

  const { t } = useTranslation()
  const [detected, setDetected] = useState(false)
  const [container, setContainer] = useState({ height: 0, width: 0 })

  const [aspectRatio, calculateRatio] = useCameraCardRatio(1.567)
  const { loading, mediaStream, retry: retryGetUserMedia } = useUserMedia()

  const offsets = useCameraOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height,
  )

  useEffect(() => {
    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
      videoRef.current.srcObject = mediaStream
      recorder.current = RecordRTC(mediaStream, {
        type: "video",
        mimeType: getSupportedMediaType(),
        ondataavailable: function (blob) {
          recorder.current.reset()
        },
      })
    }
    setDetected(mediaStream?.active ?? false)
  }, [mediaStream])

  const getSupportedMediaType = () => {
    const userAgent = navigator.userAgent

    if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      return "video/mp4"
    } else {
      return "video/webm"
    }
  }

  const handleResize = (contentRect) => {
    setContainer({
      height: Math.round(contentRect.bounds.width / aspectRatio),
      width: contentRect.bounds.width,
    })
  }

  const handleCanPlay = () => {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth)
    videoRef.current.play()
  }

  const startRecording = () => {
    if (recorder.current) {
      recorder.current.startRecording()
    }
  }

  const stopRecording = (callback) => {
    if (recorder.current) {
      recorder.current.stopRecording(() => {
        const blob = recorder.current.getBlob()
        callback(blob)
      })
    }
  }

  const camera = () => {
    return loading ? (
      <ClipLoader
        color={"#fff"}
        loading={loading}
        cssOverride={{
          display: "block",
          margin: "0 auto",
          borderColor: "red",
        }}
        size={150}
      />
    ) : (
      <Measure bounds onResize={handleResize}>
        {({ measureRef }) => (
          <div
            className='video-box'
            ref={measureRef}
            style={{
              height: `${container.height}px`,
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {mediaStream ? (
              <video
                ref={videoRef}
                onCanPlay={handleCanPlay}
                style={{
                  top: `-${offsets.y}px`,
                  left: `-${offsets.x}px`,

                  background: "#d4d4d8",
                  border: "1px solid #E2E8F0",
                  boxShadow:
                    "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
                  borderRadius: "8px",
                }}
                autoPlay={true}
                playsInline
                muted={true}
                loop
              />
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <WarningAmberIcon sx={{ color: "#dc2626", fontSize: 30 }} />
                  <Typography
                    variant='subtitle1'
                    component='p'
                    sx={{
                      color: "#1E293B",
                      lineHeight: "28px",
                      fontSize: "18px !important",
                      fontWeight: 400,
                      ml: 2,
                    }}
                  >
                    Sorry! we couldn’t find your camera
                  </Typography>
                </Box>
                <Button variant='outlined' onClick={() => retryGetUserMedia()}>
                  Retry
                </Button>
              </Box>
            )}
          </div>
        )}
      </Measure>
    )
  }

  return {
    camera,
    recorder,
    loading,
    detected,
    startRecording,
    stopRecording,
    getSupportedMediaType,
  }
}
