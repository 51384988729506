import { useState } from "react"
import * as Yup from "yup"
import {
  Button,
  MenuItem,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useFormik, Form, FormikProvider } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import { transferOwnership } from "src/redux/actions/general"
import { useLocalStorage } from "src/hooks/useLocalStorage"

//************************** */

export default function OwnershipModal({ isShowingOwnersip, hide }) {
  const [userKey] = useLocalStorage("key", "")
  const { t } = useTranslation()
  const [ownership, setOwnerShip] = useState("---")
  const members = useSelector((state) => state.members)
  const logged = useSelector((state) => state.login)

  let shape = {
    ownership: Yup.string().required(t("MemberTypeRequired")),
  }

  const teamMemberSchema = Yup.object().shape(shape)

  const dispatch = useDispatch()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: teamMemberSchema,
    onSubmit: (values) => {
      const newFormData = new FormData()
      newFormData.append("email", values.email)

      dispatch(transferOwnership(userKey, newFormData))
      handleCloseSave()
    },
  })

  const {
    errors,
    touched,
    resetForm,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  const handleCloseSave = () => {
    hide()
    setOwnerShip("---")
    resetForm()
  }

  return (
    isShowingOwnersip && (
      <Dialog
        className='dialog800'
        open={isShowingOwnersip}
        onClose={handleCloseSave}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {t("Transfer Ownership")}
          <IconButton
            aria-label='close'
            onClick={handleCloseSave}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <FormikProvider value={formik}>
                <Form
                  id='addMember'
                  autoComplete='off'
                  noValidate
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='body1'>
                        {t(
                          "You can transfer the ownership of this account to a team member. Once transmitted, you will become the normal 'User'.",
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <FormLabel required>{t("SelectTeamMember")}</FormLabel>

                        <TextField
                          select
                          id='outlined-basic'
                          name='ownership'
                          size='large'
                          sx={{
                            width: "100%",
                            textTransform: "capitalize",
                          }}
                          {...getFieldProps("ownership")}
                          error={touched.ownership && errors.ownership}
                          helpertext={touched.ownership && errors.ownership}
                          value={ownership}
                          onChange={(e) => {
                            let user = members.data.users.find(
                              (usr) => usr.id === e.target.value,
                            )

                            setOwnerShip(e.target.value)
                            setFieldValue("ownership", e.target.value)
                            setFieldValue("email", user?.email)
                          }}
                        >
                          {members?.data?.users?.filter(
                            (usr) =>
                              usr.is_active === true &&
                              logged?.user?.id !== usr.id,
                          )?.length > 0 && (
                            <MenuItem disabled value=''>
                              <em>{t("ChooseTeamMember")}</em>
                            </MenuItem>
                          )}
                          {members?.data?.users
                            ?.filter(
                              (usr) =>
                                usr.is_active === true &&
                                logged?.user?.id !== usr.id,
                            )
                            ?.map((option) => (
                              <MenuItem
                                sx={{ textTransform: "capitalize" }}
                                key={option?.id}
                                value={option?.id}
                              >
                                <Typography sx={{ whiteSpace: "normal" }}>
                                  {option?.full_name +
                                    ` - ${option?.profile?.name} - ${option?.email}`}
                                </Typography>
                              </MenuItem>
                            ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Form>
              </FormikProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleCloseSave}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Cancel")}
          </Button>

          <Button
            autoFocus
            onClick={handleSubmit}
            variant='contained'
            size='medium'
            type='submit'
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
