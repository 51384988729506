import moment from "moment"
import React, { useState, useEffect } from "react"
import { filter } from "lodash"
import {
  Table,
  Stack,
  Link,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from "@mui/material"
import ChargesListHead from "./ChargesListHead"
import Scrollbar from "src/components/Scrollbar"
import SearchNotFound from "src/components/SearchNotFound"
import { userDetail } from "src/utils/userHelper"
import { chargesDataParsing } from "src/utils/dataParsing"

import { useDispatch, useSelector } from "react-redux"
import { getAllInvoices } from "src/redux/actions/invoices"
import { useTranslation } from "react-i18next"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"
// ----------------------------------------------------------------------

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

const TABLE_HEAD = (t) => [
  { id: "name", label: t("FirstName"), alignRight: false },
  { id: "lastName", label: t("LastName"), alignRight: false },
  { id: "email", label: t("Email"), alignRight: false },
  { id: "amountPaid", label: t("AmountPaid"), alignRight: false },
  { id: "paidOn", label: t("PaidOn"), alignRight: false },
  { id: "description", label: t("Description"), alignRight: false },
  { id: "card", label: t("Card"), alignRight: false },
  { id: "", label: t("Action"), alignRight: true },
]

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index])
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  if (query) {
    return filter(
      array,
      (_user) => _user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    )
  }
  return stabilizedThis?.map((el) => el[0])
}

export default function Charges(props) {
  const { t } = useTranslation()
  const { children, value, index, ...other } = props
  const [page, setPage] = useState(0)
  const [invoiceList, setIList] = useState([])
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("title")
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - invoiceList?.length) : 0

  const filteredUsers = applySortFilter(
    invoiceList,
    getComparator(order, orderBy),
    filterName,
  )

  const isUserNotFound = filteredUsers?.length === 0
  const [valuetab] = useState(0)

  const [firstName, setFName] = useState("")
  const [lastName, setLName] = useState("")

  const invoices = useSelector((state) => state.invoice)
  const dispatch = useDispatch()
  const logged = useSelector((state) => state.login)
  const userLoggedPermissions = logged?.user?.profile?.permission

  useEffect(() => {
    const [f, l] = userDetail()
    setFName(f)
    setLName(l)
    if (invoices?.data?.length === 0) {
      // Action initiated
      dispatch(getAllInvoices())
    }
    if (invoices?.data?.success) {
      setIList(chargesDataParsing(invoices?.data?.invoices?.data))
      // ----new api need keys-
      // setIList(chargesDataParsing(invoices?.data?.charges_detail));
    } else {
      setIList([])
    }
  }, [invoices])

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table className='simple-table'>
            <ChargesListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD(t)}
              rowCount={invoiceList?.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers
                ?.filter((invoice) => {
                  if (!valuetab) {
                    return invoice
                  }

                  if (valuetab === 1) {
                    return invoice.paid === false
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, keycharges) => {
                  const parseDescription = (description) => {
                    const regexUnusedTime =
                      /Unused time on (\d+) × (.+?) after (\d{1,2} \w+ \d{4})/
                    const matchUnusedTime = description.match(regexUnusedTime)

                    if (matchUnusedTime) {
                      const count = matchUnusedTime[1]
                      const plan = matchUnusedTime[2]
                      const date = matchUnusedTime[3]

                      return t(
                        "Unused time on {{count}} × {{plan}} after {{date}}",
                        {
                          count,
                          plan,
                          date,
                        },
                      )
                    }

                    if (description.includes("Charges for extra interview")) {
                      return t("Charges for extra interview")
                    }
                    if (
                      description.includes("Trial period for Monthly Basic")
                    ) {
                      return t("Trial period for Monthly Basic")
                    }

                    const substrings = [
                      "Monthly Basic (at $49.90 / month)",
                      "Monthly Pro (at $99.75 / month)",
                      "Monthly Pro Plus (at $174.50 / month)",
                      "Yearly Basic (at $508.98 / year)",
                      "Yearly Pro (at $1,017.45 / year)",
                      "Yearly Pro Plus (at $1,779.90 / year)",
                    ]

                    for (const substring of substrings) {
                      if (description.includes(substring)) {
                        const index = description.indexOf(substring)
                        if (index !== -1) {
                          const count = description.substring(0, index).trim()
                          return t(`{{count}} × ${substring}`, { count })
                        }
                      }
                    }

                    return description
                  }

                  const translatedDescription = parseDescription(
                    row.description,
                  )

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      role='checkbox'
                      key={keycharges}
                    >
                      <TableCell component='th' scope='row' padding='none'>
                        <Stack direction='row' alignItems='right' spacing={2}>
                          <Typography variant='subtitle2' noWrap>
                            {firstName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align='left'>{lastName}</TableCell>
                      <TableCell align='left'>{row.email}</TableCell>
                      <TableCell align='left'>{row.amountPaid / 100}</TableCell>
                      <TableCell align='left'>
                        {moment.unix(row.paidOn).format("YYYY-MM-DD")}
                      </TableCell>

                      <TableCell align='left'>
                        {" "}
                        {translatedDescription}
                      </TableCell>

                      <TableCell align='left'>
                        {"***" + row.card.slice(-4)}
                      </TableCell>
                      <TableCell align='right'>
                        {userLoggedPermissions?.settings_user_can_download_charges_receipt && (
                          <Link
                            href={row.receiptPdf}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <FileDownloadOutlinedIcon
                              sx={{ marginRight: "5px" }}
                            />
                            {t("receipt")}
                          </Link>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>

            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell className='searchCell' colSpan={8}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        labelRowsPerPage={t("RowsPerPage")}
        count={invoiceList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
}
