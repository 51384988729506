import { LiveHeader } from "../.."
import { styled } from "@mui/material/styles"
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Alert,
} from "@mui/material"
import DoneIcon from "@mui/icons-material/Done"
const PanelInner = styled("div")(({ theme }) => ({
  // padding: '1.8rem 2.8rem 1rem 2.8rem ',
}))

const Jumbotron = styled("div")(({ theme }) => ({
  // padding: '2rem',
}))

const IntructionListing = styled("div")(({ theme }) => ({}))

export default function Thanks({ interview }) {
  const {
    candidate_vacancy: {
      candidate: { first_name: firstName, last_name: lastName },
      vacancy: {
        title: title,
        interview_time: interviewTime,
        department: {
          account: {
            logo: { url: logoUrl },
            company_name: companyName,
          },
        },
      },
    },
    interview_questions: questions,

    interview_format: interviewFormat,
  } = interview
  return (
    <PanelInner>
      <Grid container spacing={2}>
        <Grid
          item
          sx={{ pt: 6, pb: { sm: "1", md: "5" }, mt: 2 }}
          md={6}
          lg={5}
        >
          <Jumbotron>
            <LiveHeader className='live-logo' logoUrl={logoUrl} />
            <Typography
              variant='h5'
              sx={{
                marginBottom: "0.29rem",
                color: "#2563EB",
                lineHeight: "24px",
                fontSize: "16px !important",
                fontWeight: 600,
                mt: 1,
                textTransform: "capitalize",
              }}
            >
              Welcome {firstName} {lastName}
            </Typography>
            <Typography
              variant='h2'
              sx={{
                marginBottom: "1rem",
                color: "#1E293B",
                lineHeight: "32px",
                fontSize: "24px !important",
                fontWeight: 600,
              }}
            >
              Good luck with your interview!
            </Typography>
            <Typography
              variant='subtitle2'
              component='p'
              sx={{
                mb: 1,
                color: "#475569",
                lineHeight: "24px",
                fontSize: "16px !important",
                fontWeight: 400,
              }}
            >
              Thank you for applying for the role of <strong>{title}</strong>{" "}
              with {companyName}.
            </Typography>
          </Jumbotron>
        </Grid>
        <Grid item sx={{ pt: 6, pb: 5, mt: 2 }} md={6} lg={7}>
          <IntructionListing className='welcome-area'>
            {interviewFormat === "recorded_by_applicant" ? (
              <List>
                <ListItem sx={{ px: 0, pt: 0 }}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <DoneIcon sx={{ color: "#059669", fontSize: 20, mr: 1 }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: "24px",
                      color: "#475569",
                    }}
                  >
                    This interview consists of{" "}
                    <strong>{questions?.length}</strong> questions
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ px: 0, pt: 0 }}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <DoneIcon sx={{ color: "#059669", fontSize: 20, mr: 1 }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: "24px",
                      color: "#475569",
                    }}
                  >
                    This interview will last{" "}
                    <strong>{interviewTime.replace(" min", "")}</strong> minutes
                  </ListItemText>
                </ListItem>
                <ListItem sx={{ px: 0, pt: 0 }}>
                  <ListItemIcon sx={{ minWidth: 30 }}>
                    <DoneIcon sx={{ color: "#059669", fontSize: 20, mr: 1 }} />
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: 16,
                      fontWeight: 400,
                      lineHeight: "24px",
                      color: "#475569",
                    }}
                  >
                    Your answers will be <strong>recorded individually</strong>
                  </ListItemText>
                </ListItem>
              </List>
            ) : (
              <div className='live-intvw-info'>
                <Typography
                  variant='h5'
                  sx={{
                    mb: 1,
                    lineHeight: "24px",
                    fontSize: "16px !important",
                    fontWeight: 400,
                  }}
                >
                  LIVE PART
                </Typography>

                <List>
                  <ListItem sx={{ px: 0, pt: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <DoneIcon
                        sx={{ color: "#059669", fontSize: 20, mr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "24px",
                        color: "#475569",
                      }}
                    >
                      This interview will last{" "}
                      <strong>{interviewTime.replace(" min", "")}</strong>{" "}
                      minutes,
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ px: 0, pt: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <DoneIcon
                        sx={{ color: "#059669", fontSize: 20, mr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "24px",
                        color: "#475569",
                      }}
                    >
                      This simulates a <strong>live interview</strong>
                    </ListItemText>
                  </ListItem>
                </List>

                <hr />
                <Typography
                  variant='h5'
                  sx={{
                    mb: 1,
                    mt: 4,
                    lineHeight: "24px",
                    fontSize: "16px !important",
                    fontWeight: 400,
                  }}
                >
                  QUESTION'S PART
                </Typography>
                <List>
                  <ListItem sx={{ px: 0, pt: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <DoneIcon
                        sx={{ color: "#059669", fontSize: 20, mr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "24px",
                        color: "#475569",
                      }}
                    >
                      This interview consists of{" "}
                      <strong>{questions?.length}</strong> questions
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ px: 0, pt: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <DoneIcon
                        sx={{ color: "#059669", fontSize: 20, mr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "24px",
                        color: "#475569",
                      }}
                    >
                      This interview will last{" "}
                      <strong>{interviewTime.replace(" min", "")}</strong>{" "}
                      minutes
                    </ListItemText>
                  </ListItem>
                  <ListItem sx={{ px: 0, pt: 0 }}>
                    <ListItemIcon sx={{ minWidth: 30 }}>
                      <DoneIcon
                        sx={{ color: "#059669", fontSize: 20, mr: 1 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        fontSize: 16,
                        fontWeight: 400,
                        lineHeight: "24px",
                        color: "#475569",
                      }}
                    >
                      Your answers will be{" "}
                      <strong>recorded individually</strong>
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            )}
          </IntructionListing>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <Alert
            variant='filled'
            severity='warning'
            sx={{
              fontSize: 14,
              lineHeight: "20px",
              fontWeight: 400,
            }}
          >
            Once you start the interview, you will not be able to
            <b> pause</b> it. <br />
          </Alert>
        </Grid>
      </Grid>
    </PanelInner>
  )
}
