import React, { useState } from "react"
import {
  IconButton,
  Grid,
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material"
import { Link as RouterLink, Navigate } from "react-router-dom"
import CloseIcon from "@mui/icons-material/Close"

export default function GuideModal({ isShowingGuide, handleClose }) {
  return (
    isShowingGuide && (
      <Dialog
        className='modal-dialog-small'
        open={isShowingGuide}
        scroll='paper'
        maxWidth='md'
      >
        <DialogContent
          className='guide-modal-content'
          dividers={true}
          sx={{ padding: "0px" }}
        >
          <Grid container spacing={0}>
            <Grid item sm={12}>
              <Box
                className='modal-body'
                sx={{
                  maxWidth: "300px",
                  minWidth: "250px",
                  margin: "0 auto",
                }}
              >
                <IconButton
                  aria-label='close'
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant='h4' sx={{ color: " var(--primary)" }}>
                  Vacancies
                </Typography>
                <Typography
                  variant='subtitle2'
                  component='p'
                  sx={{
                    color: "rgba(112, 125, 142, 0.6)",
                    lineHeight: "1.4em",
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  Create a new vacancy from here.
                </Typography>
                <Button
                  component={RouterLink}
                  variant='contained'
                  type='submit'
                  to='/dashboard/vacancies'
                >
                  Create a new vacancy
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  )
}
