import React, { useEffect, useState } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useFormik, Form, FormikProvider } from "formik"
import * as Yup from "yup"
import {
  Link,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Typography,
  Alert,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff"
import VisibilityIcon from "@mui/icons-material/Visibility"
import login, { resetLoginMessage } from "src/redux/actions/login"
import { useSelector, useDispatch } from "react-redux"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import PasswordResetModal from "../passwordReset/passwordResetModal"
import { useTranslation } from "react-i18next"
import { dashboardInfo } from "src/redux/actions/dashboard"
import { subMonths } from "date-fns"

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate()
  let monthStart = new Date()
  monthStart = subMonths(monthStart, 1)
  const logged = useSelector((state) => state.login)
  const dispatch = useDispatch()

  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("EmailmustbevalidEmail"))
      .required(t("ErrEmailRequired")),
    password: Yup.string().required(t("ErrPasswordRequired")),
  })

  const [startDate, setStartDate] = useState(monthStart);
  const [endDate, setEndDate] = useState(new Date());
  const [flag, setFlag] = useState(null)
  const [error, setError] = useState("")
  const [isShowingResetPassword, toggleResetPassword] = useModal()

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,

    onSubmit: (values) => {
      setLoading(true)
      const formData = {
        email: values.email.toLowerCase(),
        password: values.password,
      }
      dispatch(login(formData))
    },
  })

  const { errors, touched, resetForm, handleSubmit, getFieldProps } = formik

  useEffect(() => {
    if (
      logged?.status === 401 ||
      logged?.status === 404 ||
      logged?.status === 500
    ) {
      if (logged?.status === 500) {
        setFlag(true)
        setError("Internal Server Error")
        setLoading(false)
      }

      if (logged?.status === 401 || logged?.status === 404) {
        if (!isShowingResetPassword) {
          setFlag(true)
          setError(logged?.message)
          setLoading(false)
        }
      }
    }

    if (logged?.status === 200) {
      if (logged?.loggedIn) {
        navigate("/dashboard/app", { replace: true, state: { isComplete: true } })
        setLoading(false)
        resetForm({ values: "" })
        dispatch(dashboardInfo({ start: startDate, end: endDate }))
        // window.location.reload()
      } else {
        setError(logged?.message)
        setFlag(true)
        setLoading(false)
      }
    }
    const timer = setTimeout(() => {
      setFlag(false)
      setError("")
    }, 5000)

    return () => clearTimeout(timer)
  }, [logged])

  const handleShowPassword = () => {
    setShowPassword((show) => !show)
  }

  const removeError = () => {
    setFlag(false)
    setError("")
  }
  const handleSignUp = () => {
    dispatch(resetLoginMessage())
  }
  const handleChangePaste = (e) => {
    e.preventDefault()
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel required>Email Address</FormLabel>
            <TextField
              onClick={removeError}
              fullWidth
              autoComplete='username'
              type='email'
              {...getFieldProps("email")}
              placeholder='Email Address'
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel required>Password</FormLabel>
            <TextField
              onClick={removeError}
              fullWidth
              autoComplete='new-password'
              type={showPassword ? "text" : "password"}
              {...getFieldProps("password")}
              placeholder='Password'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={handleShowPassword} edge='end'>
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onPaste={handleChangePaste}
              onCopy={handleChangePaste}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </FormControl>
        </Stack>

        <Stack
          alignItems='center'
          justifyContent='space-between'
          className='row-links'
          sx={{ my: 2 }}
        >
          <Link
            onClick={toggleResetPassword}
            variant='subtitle2'
            component={RouterLink}
            to='#'
            underline='hover'
            sx={{ my: 1 }}
          >
            Forgot password?
          </Link>

          <PasswordResetModal
            isShowingResetPassword={isShowingResetPassword}
            hide={toggleResetPassword}
          />

          <Typography variant='body2' align='center' sx={{ my: 1 }}>
            New to IntVue?
            <Link
              variant='subtitle2'
              sx={{ marginLeft: "3px" }}
              underline='hover'
              component={RouterLink}
              onClick={handleSignUp}
              to='/signup'
            >
              Sign Up
            </Link>
          </Typography>
        </Stack>
        {flag ? <Alert severity='error'>{error}</Alert> : null}

        <Stack
          direction='row'
          justifyContent='flex-start'
          alignItems='flex-start'
          spacing={4}
        >
          <LoadingButton
            size='medium'
            type='submit'
            variant='contained'
            disabled={loading}
            onClick={handleSubmit}
            loading={loading}
            loadingIndicator={<CircularProgress size={15} />}
          >
            {t("Login")}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
