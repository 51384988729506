import { format, formatDistanceToNow } from "date-fns"
import moment from "moment"
import "moment-timezone"
// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), "dd MMMM yyyy")
}

export function fDateShort(date) {
  return format(new Date(date), "dd-MMM-yy")
}
export function fDateFormatYear(date) {
  return format(new Date(date), "yy-MM-dd")
}
export function fDateFormatYearlong(date) {
  return format(new Date(date), "yyyy-MM-dd")
}
export function fTime(date) {
  return format(new Date(date), "HH:mm")
}

export function fDateTime(date) {
  return format(new Date(date), "dd MMM yyyy HH:mm")
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p")
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  })
}

export function fSecToHMS(sec) {
  let resultSecond = new Date(sec * 1000).toISOString().slice(11, 19)
  return resultSecond
}

export function fDateShortYM(date) {
  return format(new Date(date), "MMM yyyy")
}

export function secondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = Math.floor(seconds % 60)

  let result = ""

  if (hours > 0) {
    result += `${hours} hr${hours !== 1 ? "s" : ""}, `
  }
  if (minutes > 0 || result !== "") {
    result += `${minutes} min${minutes !== 1 ? "s" : ""}, `
  }
  if (remainingSeconds > 0 || result === "") {
    result += `${remainingSeconds} sec${remainingSeconds !== 1 ? "s" : ""}`
  }

  return result
}

export function changeTimeZone(date, toTimeZone) {
  const newDate = new Date(
    date.toLocaleString("en-US", {
      timeZone: toTimeZone,
    }),
  )
  const diff = date.getTime() - newDate.getTime()

  return new Date(date.getTime() - diff)
}

export function timeSince(date) {
  let seconds = Math.floor((new Date() - date) / 1000)

  let interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + " years ago"
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + " months ago"
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + " days ago"
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + " hours ago"
  }
  interval = Math.floor(seconds / 60)
  if (interval >= 1) {
    return interval + " minute" + (interval > 1 ? "s  ago" : " ago")
  }
  return Math.floor(seconds) + " seconds ago"
}

export function convertTimeZone(date, fromTimeZone, toTimeZone=null) {
  if (!date) return
  let dateTimeInTimeZone = moment.tz(date.split(".")[0], fromTimeZone)
  let convertedDate = fDateTime(dateTimeInTimeZone.toDate())

  if(toTimeZone) {
    dateTimeInTimeZone = dateTimeInTimeZone.tz(toTimeZone)
    convertedDate = fDateTime(dateTimeInTimeZone.toDate())
  }

  convertedDate = convertedDate.replace("23:59", "24:00")
  return convertedDate
}
