import React, { useState, useEffect } from "react"
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TableRow,
  TableCell,
  TableContainer,
  Box,
  tableCellClasses,
  List,
  ListItem,
} from "@mui/material"

import { styled } from "@mui/material/styles"
import CloseIcon from "@mui/icons-material/Close"
import jsPDF from "jspdf"

import "jspdf-autotable"

import { isEmpty } from "lodash"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "react-redux"
import Scrollbar from "src/components/Scrollbar"
import { useAuth } from "src/hooks/useAuth"
import { useTranslation } from "react-i18next"
import Label from "src/components/Label"
import { fDateTime } from "src/utils/formatTime"
import moment from "moment"
import "moment-timezone"
//************************** */
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}))

/************** */
export default function ViewInterviewModal({
  isShowingInt,
  hide,

  data,
  interviews = [],
}) {
  const { user } = useAuth()
  const { t } = useTranslation()
  const theme = useTheme()
  const [questionSet, setQuestionSet] = useState({})
  const existingQuestionSets = useSelector((state) => state.questions)
  const logged = useSelector((state) => state.login)
  const userLoggedPermissions = logged?.user?.profile?.permission

  useEffect(() => {
    if (data) {
      let questionSetId =
        data?.interview_questions[0]?.question?.question_set_id

      setQuestionSet(
        existingQuestionSets?.data?.question_sets.find(
          (QS) => QS?.id === questionSetId,
        ),
      )
    }
  }, [data])
  const handleClose = () => {
    hide()
  }

  // download pdf
  // Define the adjustLetterSpacing function
  const adjustLetterSpacing = (text, spacing) => {
    const characters = text.split("")

    // Adjust letter spacing for each character
    const adjustedText = characters
      .map((char) => {
        return char + "\u200A".repeat(spacing) // Use a narrow space character for spacing
      })
      .join("")

    return adjustedText
  }

  const generatePdfContent = (data) => {
    const doc = new jsPDF()

    doc.setProperties({ title: "Interview Details" })

    let tableData = [
      [`Interview Code: `, `${data.code}`],

      [
        `Candidate Name: `,
        `${
          data?.candidate_vacancy?.candidate?.first_name +
          " " +
          data?.candidate_vacancy?.candidate?.last_name
        }`,
      ],
      [`Email: `, `${data?.candidate_vacancy?.candidate?.email}`],
      [
        `Qualification: `,
        `${data?.candidate_vacancy?.candidate?.qualification}`,
      ],
      [`Phone: `, `${data?.candidate_vacancy?.candidate?.phone}`],

      [
        `Experience: `,
        `${data?.candidate_vacancy?.candidate?.experience} ${
          data?.candidate_vacancy?.candidate?.experience > 1
            ? " years"
            : " year"
        }`,
      ],
      [
        `Skills: `,
        `${data?.candidate_vacancy?.candidate?.skills.map((singleSkill) => {
          return singleSkill
        })}`,
      ],
      [`Vacancy Title: `, `${data?.candidate_vacancy?.vacancy?.title}`],
      [
        `Interview Type: `,
        `${
          data?.interview_type === "first_contact"
            ? "First Contact"
            : "Followup"
        }`,
      ],
      [
        `Interview Format: `,
        `${data?.interview_format === "on_site" ? "On Site" : "Recorded"}`,
      ],
      [
        `Interview Date: `,
        `${
          data?.interview_format === "recorded_by_applicant"
            ? convertTimeZone(data?.expires_on)
            : convertTimeZone(data?.interview_time)
        }`,
      ],
      [
        `Interview Date: `,
        `${data?.status === "completed" ? "completed" : "Pending"}`,
      ],

      // [
      //   `Vacancy Description`,
      //   `${adjustLetterSpacing(
      //     `${isEmpty(data?.description) ? "Not Given" : data?.description}`,
      //     0.05, // Adjust the letter spacing value
      //   )}`,
      // ],

      //   [
      //     `Required Qualification: `,
      //     `${isEmpty(match?.qualification) ? "Not Given" : match?.qualification}`,
      //   ],
    ]

    const maxFirstColumnWidth = 60
    const availableSecondColumnWidth =
      doc.internal.pageSize.width - maxFirstColumnWidth - 20

    doc.autoTable({
      body: tableData,
      startY: 10,
      margin: { top: 10 },
      styles: {
        fontSize: 12,
        cellPadding: 4,
        whitespace: "break-spaces",
        wordBreak: "break-word",
      },
      columnStyles: {
        0: { fontStyle: "bold", cellWidth: maxFirstColumnWidth },
        1: { cellWidth: availableSecondColumnWidth, letterSpacing: "0.2px" },
      },
    })

    return doc
  }

  const handleDownloadPdf = () => {
    const doc = generatePdfContent(data)
    doc.save(
      `${data?.candidate_vacancy?.candidate?.first_name}_${data?.candidate_vacancy?.candidate?.last_name}.pdf`,
    )
  }
  const convertTimeZone = (date, fromTimeZone) => {
    if (!date) return
    const dateTimeInTimeZone = moment.tz(date.split(".")[0], fromTimeZone)

    let convertedDate = fDateTime(dateTimeInTimeZone.toDate())
    convertedDate = convertedDate.replace("23:59", "24:00")
    return convertedDate
  }
  //download pdf end

  return (
    isShowingInt && (
      <Dialog
        className='dialog800'
        open={isShowingInt}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          Interview Details
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Scrollbar>
                <TableContainer
                  sx={{
                    minWidth: { xs: "100%", sm: 320, md: 600 },
                    border: "1px solid #8080803d",
                  }}
                >
                  <List className='simple-table responsive-tbl'>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("Candidate Full Name")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.candidate_vacancy?.candidate?.first_name +
                            " " +
                            data?.candidate_vacancy?.candidate?.last_name}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>{t("Email")}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.candidate_vacancy?.candidate?.email}
                        </Typography>
                      </Box>
                    </ListItem>
                    {data?.candidate_vacancy?.candidate?.phone && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>{t("Phone")}</Typography>
                        </Box>
                        <Box>
                          <Typography
                            component='p'
                            variant='body1'
                            sx={{
                              fontWeight: 400,
                            }}
                          >
                            {data?.candidate_vacancy?.candidate?.phone}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}
                    {data?.candidate_vacancy?.candidate?.qualification && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {t("qualification")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component='p'
                            variant='body1'
                            sx={{
                              fontWeight: 400,
                            }}
                          >
                            {data?.candidate_vacancy?.candidate?.qualification}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}
                    {data?.candidate_vacancy?.candidate?.experience && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {t("Experience")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component='p'
                            variant='body1'
                            sx={{
                              fontWeight: 400,
                            }}
                          >
                            {data?.candidate_vacancy?.candidate?.experience}
                            {data?.candidate_vacancy?.candidate?.experience > 1
                              ? " years"
                              : " year"}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>{t("skills")}</Typography>
                      </Box>
                      <Box>
                        <Typography component='p' variant='body1'>
                          {data?.candidate_vacancy?.candidate?.skills.map(
                            (singleSkill, index) => {
                              return (
                                <Label
                                  sx={{
                                    mx: "3px",
                                    fontSize: "0.875rem",
                                    whiteSpace: "break-spaces",
                                    wordBreak: "break-word",
                                    lineHeight: "1.2",
                                    height: "auto",
                                    py: { xs: 1, sm: "5px" },
                                    mb: 1,
                                  }}
                                  key={index}
                                  variant='ghost'
                                  color='info'
                                >
                                  {singleSkill}{" "}
                                </Label>
                              )
                            },
                          )}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("department")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.candidate_vacancy?.vacancy?.department?.name}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>{t("vacancy")}</Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.candidate_vacancy?.vacancy?.title}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("interview format")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.interview_format === "recorded_by_applicant"
                            ? "Recorded"
                            : "On-Site"}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("interview Type")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.interview_type === "first_contact"
                            ? "First Contact"
                            : "Followup"}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("Interview Date & Time")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.interview_format === "recorded_by_applicant"
                            ? convertTimeZone(data?.expires_on)
                            : convertTimeZone(data?.interview_time)}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("Interview Code")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.code}
                        </Typography>
                      </Box>
                    </ListItem>

                    {data?.interview_format === "on_site" && (
                      <ListItem className='row' disablePadding>
                        <Box className='prime'>
                          <Typography component='th'>
                            {t("interview Location")}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography
                            component='p'
                            variant='body1'
                            sx={{
                              fontWeight: 400,
                            }}
                          >
                            {data?.interview_location}
                          </Typography>
                        </Box>
                      </ListItem>
                    )}

                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("interview Panel")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography component='p' variant='body1'>
                          {data?.interviewers?.map((intPanel, index) => {
                            return (
                              <Label
                                sx={{
                                  mx: "3px",
                                  fontSize: "0.875rem",
                                  whiteSpace: "break-spaces",
                                  wordBreak: "break-word",
                                  lineHeight: "1.2",
                                  height: "auto",
                                  py: { xs: 1, sm: "5px" },
                                  mb: 1,
                                }}
                                key={index}
                                variant='ghost'
                                color='info'
                              >
                                {intPanel?.user?.first_name +
                                  " " +
                                  intPanel?.user?.last_name}
                              </Label>
                            )
                          })}
                        </Typography>
                      </Box>
                    </ListItem>

                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          {t("scheduled On")}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {data?.scheduled_on}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>
                          Question Set & Questions
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          component='p'
                          variant='body1'
                          sx={{
                            fontWeight: 400,
                          }}
                        >
                          {questionSet.name}
                          <Box sx={{ maxHeight: "120px", overflowY: "auto" }}>
                            <ul className='decimal'>
                              {data?.interview_questions.map((intQS, i) => (
                                <li key={i}>
                                  Q {i + 1}: {intQS?.question?.text}
                                </li>
                              ))}
                            </ul>
                          </Box>
                        </Typography>
                      </Box>
                    </ListItem>

                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>{t("Status")}</Typography>
                      </Box>
                      <Box>
                        <Typography component='p' variant='body1'>
                          {data?.status === "completed"
                            ? "completed"
                            : "Pending"}
                        </Typography>
                      </Box>
                    </ListItem>
                    <ListItem className='row' disablePadding>
                      <Box className='prime'>
                        <Typography component='th'>{t("Votes")}</Typography>
                      </Box>
                      <Box>
                        <Typography component='p' variant='body1'>
                          {data?.votes_count.map((vc, index) => {
                            return (
                              <>
                                <Label
                                  sx={{
                                    mx: "3px",
                                    fontSize: "0.875rem",
                                    whiteSpace: "break-spaces",
                                    wordBreak: "break-word",
                                    lineHeight: "1.2",
                                    height: "auto",
                                    py: { xs: 1, sm: "5px" },
                                    mb: 1,
                                  }}
                                  key={index}
                                  variant='ghost'
                                  color='info'
                                >
                                  recomended: {vc?.recomend_votes}
                                </Label>
                                <Label
                                  sx={{
                                    mx: "3px",
                                    fontSize: "0.875rem",
                                    whiteSpace: "break-spaces",
                                    wordBreak: "break-word",
                                    lineHeight: "1.2",
                                    height: "auto",
                                    py: { xs: 1, sm: "5px" },
                                    mb: 1,
                                  }}
                                  key={index}
                                  variant='ghost'
                                  color='error'
                                >
                                  denied: {vc?.denied_votes}
                                </Label>
                              </>
                            )
                          })}
                        </Typography>
                      </Box>
                    </ListItem>
                  </List>
                </TableContainer>
              </Scrollbar>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleDownloadPdf}
            size='medium'
            autoFocus
            variant='contained'
            color='secondary'
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
