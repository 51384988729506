import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { NavLink as RouterLink, matchPath, useLocation } from "react-router-dom"
import { alpha, useTheme, styled } from "@mui/material/styles"
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "8px",
})
NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
}

function NavItem({ item, active }) {
  const theme = useTheme()
  const { t } = useTranslation()
  const isActiveRoot = active(item.path)
  const { title, path, icon, info } = item

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon ? icon : ""}</ListItemIconStyle>

      <ListItemText disableTypography primary={t(title)} />
      {info ? info : null}
    </ListItemStyle>
  )
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
}

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation()
  const [module, setModule] = useState([])

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false

  const logged = useSelector((state) => state.login)
  useEffect(() => {
    let arrList = []

    navConfig.forEach((val) => {
      let key = logged?.user?.profile?.permission[val.key]
      if (key) {
        arrList.push({ title: val.title, path: val.path, icon: val.icon })
      }
    })

    if (arrList.length !== 0) {
      setModule(arrList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged])
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {module?.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  )
}
