import React, { useState } from "react"
import {
  Grid,
  TextField,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import "react-phone-input-2/lib/style.css"
import { verifyData } from "src/utils/verifyData"

const StepperInner = styled("div")(({ theme }) => ({
  margin: "0 0 1.5rem 0",
}))

function Step1({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  getFieldProps,
  handleNameAvailabilityCheck,
  handleWebsiteAvailabilityCheck,
  ...props
}) {
  const [isNameAvailable, setIsNameAvailable] = useState(null)
  const [isWebsiteAvailable, setIsWebsiteAvailable] = useState(null)
  const [nameError, setNameError] = useState("")
  const [webError, setWebError] = useState("")

  const handleDetailsChange = async (name, value) => {
    const result = await verifyData(name, value)
    if (name === "company_name") {
      setIsNameAvailable(result?.success)
      setNameError(result?.error)
      handleNameAvailabilityCheck(result?.success)
    } else if (name === "website") {
      setIsWebsiteAvailable(result?.success)
      setWebError(result?.error)
      handleWebsiteAvailabilityCheck(result?.success)
    }
  }

  return (
    <StepperInner>
      <Typography variant='h4' sx={{ mb: 2 }}>
        Company Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel htmlFor='companyName' required>
              Company Name
            </FormLabel>
            <TextField
              fullWidth
              id='companyName'
              name='companyName'
              {...getFieldProps("companyName")}
              value={values.companyName}
              onBlur={(e) => {
                handleDetailsChange("company_name", e.target.value)
                handleBlur(e)
              }}
              onChange={handleChange}
              error={touched.companyName && Boolean(errors.companyName)}
              helperText={touched.companyName && errors.companyName}
              placeholder='Company Name'
            />
            {isNameAvailable === false && (
              <Typography sx={{ color: "red" }}>{nameError}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormLabel htmlFor='website' required>
              Company Website
            </FormLabel>
            <TextField
              fullWidth
              id='website'
              name='website'
              {...getFieldProps("website")}
              value={values.website}
              onBlur={(e) => {
                handleDetailsChange("website", e.target.value)
                handleBlur(e)
              }}
              onChange={handleChange}
              error={touched.website && Boolean(errors.website)}
              helperText={touched.website && errors.website}
              placeholder='website address'
            />
            {isWebsiteAvailable === false && (
              <Typography sx={{ color: "red" }}>{webError}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </StepperInner>
  )
}

export default Step1
