import ArrowCorverBlue from "./signup-imgs/arrow-corner.png"
import SingupImage from "./signup-imgs/leftbanner.png"
import LeftBannerBlue from "./signup-imgs/left-panel-bg.jpg"
import LogoBlue from "./signup-imgs/logo-blue.jpg"
import DownGradeImg from "./modals-actions/downgrade.png"
import PurchaseIcon from "./modals-actions/purchase-icon.png"
import RunoutImg from "./modals-actions/runout.png"
import SessionExpired from "./modals-actions/session-expired.png"
import SessionExpired2 from "./modals-actions/session_expired_2.png"
import UnsubImg from "./modals-actions/unsub.png"
import UpgradeImg from "./modals-actions/upgrade.png"
import Completion from "./stats/stats_4.svg"
import likeThumbIcon from "./stats/stats_3.svg"
import QuestionIcon from "./stats/stats_6.svg"
import RolesIcon from "./stats/stats_1.svg"
import TimeOutIcon from "./stats/stats_2.svg"
import UserIcon from "./stats/stats_5.svg"
import ImageBanner1 from "./landingpage/image-banner1.png"
import ImageBanner2 from "./landingpage/image-banner2.png"
import ImageBanner3 from "./landingpage/image-banner3.png"
import ImageBanner4 from "./landingpage/image-banner4.png"
import ImageBanner5 from "./landingpage/image-banner5.png"
import ImageBanner6 from "./landingpage/image-banner6.png"
import hero1 from "./landingpage/banner-small.png"
import largeImage from "./landingpage/large-image.png"
import interviewIcon from "./landingpage/interview.svg"
import outline from "./landingpage/outline.svg"
import lossIcon from "./landingpage/loss.svg"
import mldicon from "./landingpage/XMLID_1648_.svg"
import connectionIcon from "./landingpage/connection.svg"
import peaceIcon from "./landingpage/peace.svg"
import iconDashboard from "./landingpage/dashboard.svg"
import iconEmail from "./landingpage/iconEmail.svg"
import featuresIcon from "./landingpage/featuresIcon.png"
import iconDownload from "./landingpage/icondownload.svg"
import iconInfo from "./landingpage/info-icon.svg"
import iconBrand from "./landingpage/theme.svg"
import iconFeedback from "./landingpage/comment.svg"
import LogoMini from "./logo/logo.png"
import LogoMain from "./logo/logo.svg"
import LogoWhite from "./logo/intvuewhite_logo.svg"
import LogoSm from "./landingpage/intvue.svg"
import BlueBadge from "./plan/blue-badge.png"
import CartLg from "./stats/cart-lg-icon.png"
import TrashLg from ".//stats/bucket-lg-icon.png"
import ClipLg from "./stats/clip-lg-icon.png"
import NoPlanSubs from "./modals-actions/notsubs.png"
import Check from "./conductInt/check.png"
import RedCross from "./conductInt/cross-red.svg"
import Recording from "./conductInt/recording.gif"
import PlayBlue from "./conductInt/PlayIcon.svg"
import PlayRound from "./conductInt/mini-play.svg"
import Welcome from "./modals-actions/welcome.png"

const Images = {
  ArrowCorverBlue,
  Check,
  RedCross,
  Recording,
  PlayBlue,
  PlayRound,
  iconInfo,
  iconBrand,
  iconFeedback,
  LogoWhite,
  LogoSm,
  NoPlanSubs,
  iconDownload,
  BlueBadge,
  CartLg,
  TrashLg,
  ClipLg,
  iconDashboard,
  iconEmail,
  LogoMain,
  LogoMini,
  connectionIcon,
  peaceIcon,
  lossIcon,
  outline,
  mldicon,
  SingupImage,
  LeftBannerBlue,
  LogoBlue,
  DownGradeImg,
  PurchaseIcon,
  RunoutImg,
  SessionExpired,
  SessionExpired2,
  UnsubImg,
  UpgradeImg,
  Completion,
  likeThumbIcon,
  QuestionIcon,
  RolesIcon,
  TimeOutIcon,
  UserIcon,
  ImageBanner1,
  ImageBanner2,
  ImageBanner3,
  ImageBanner4,
  ImageBanner5,
  ImageBanner6,
  hero1,
  largeImage,
  interviewIcon,
  featuresIcon,
  Welcome,
}

export default Images
