import * as ActionTypes from "../constants/flaggedQuestion";
const initialState = { data: [] };
const flaggedQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FLAGGEDQUESTION_ON_SUCCESS:
      return { ...state, flagged: true };
    case ActionTypes.FLAGGEDQUESTION_ON_FAILURE:
      return action.error;

    default:
      return state;
  }
};

export default flaggedQuestionReducer;
