export const LOGIN_ON_SUCCESS = "LOGIN_ON_SUCCESS";
export const LOGIN_ON_FAILURE = "LOGIN_ON_FAILURE";
export const RESET_lOGIN_ERROR = "RESET_LOGIN_ERROR";
export const LANGUAGE_ON_SUCCESS = "LANGUAGE_ON_SUCCESS"
export const LANGUAGE_ON_FAILURE = "LANGUAGE_ON_FAILURE"
export const RENEWSESSION_ON_SUCCESS = "RENEWSESSION_ON_SUCCESS"
export const RENEWSESSION_ON_FAILURE = "RENEWSESSION_ON_FAILURE"
export const RESET_USER = "RESET_USER"
export const RESET_SUBSCRIPTION = "RESET_SUBSCRIPTION"
export const RESET_PROFILE = "RESET_PROFILE"
export const REINTITALIZE_SESSION = "REINTITALIZE_SESSION"
