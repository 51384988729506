import * as ActionTypes from "../constants/accountConfig";

const initialState = { data: [], message: "" };
const accountConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHACCOUNTCONFIG_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      };

    case ActionTypes.EDITACCOUNT_ON_SUCCESS:
      return { ...state, edited: true, message: action.payload.message };
    case ActionTypes.SMTP_ON_SUCCESS:
      sessionStorage.setItem("smtp_configured", true)
      return { ...state, edited: true, message: action.payload.message };
    case ActionTypes.SMTP_ON_FAILURE:
      return { ...state, edited: true, error: action.payload.errors, isFailed: true };
    case ActionTypes.SAMPLE_ON_SUCCESS:
      return { ...state, edited: true, message: action.payload.message };
    case ActionTypes.RESET_FLAGS:
      return {
        ...state,
        message: "",
        edited: false,
        isFailed: false
      };

    default:
      return state;
  }
};

export default accountConfigReducer;
