import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  FormGroup,
  IconButton,
  Typography,
  RadioGroup,
  FormControlLabel,
  Autocomplete,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Slider,
} from "@mui/material"
import Scrollbar from "src/components/Scrollbar"
import { useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import { useSelector } from "react-redux"
import ManageHistoryOutlinedIcon from "@mui/icons-material/ManageHistoryOutlined"
import ClearAllOutlinedIcon from "@mui/icons-material/ClearAllOutlined"
import CloseIcon from "@mui/icons-material/Close"
import FilterListIcon from "@mui/icons-material/FilterList"
// ----------------------------------------------------------------------
const FILTER_CANDIDATE = ["Hired", "NotConsidered"]
// ----------------------------------------------------------------------

FilterSidebar.propTypes = {
  openFilter: PropTypes.bool,
  onOpenFilter: PropTypes.func,
  onCloseFilter: PropTypes.func,
  setRoles: PropTypes.func,
}

export default function FilterSidebar({
  openFilter,
  onOpenFilter,
  onCloseFilter,
  setRoles,
}) {
  const { t } = useTranslation()

  const MAX_LIMIT = 70
  const MAX_SALARY_LIMIT = 999999
  const ITEM_HEIGHT = 40
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const [manager, setManager] = useState("")
  const [minSalary, setMinSalary] = useState()
  const [maxSalary, setMaxSalary] = useState()
  const [experience, setExperience] = useState([1, MAX_LIMIT])
  const [candidate, setCandidate] = useState("")
  const [reqSkills, setReqSkills] = useState([])
  const [department, setDepartment] = useState([])
  const [salaryError, setSalaryError] = useState("")

  const departmentsInfo = useSelector((state) => state.departments)
  const members = useSelector((state) => state.members)
  const skills = useSelector((state) => state.skills)

  const departmentsData = departmentsInfo?.data?.departments || []
  const mappedDepartments = departmentsData.map(({ name }) => name)

  const handleClear = () => {
    setManager("")
    setMinSalary()
    setMaxSalary()
    setExperience([1, MAX_LIMIT])
    setCandidate("")
    setReqSkills([])
    setDepartment([])
    setRoles()
    onCloseFilter()
  }

  const applyFilter = () => {
    if ((minSalary && !maxSalary) || (!minSalary && maxSalary)) {
      setSalaryError(t("AddBothvalues"))
    } else if (minSalary > maxSalary) {
      setSalaryError(t("SalaryRangeError"))
    } else {
      let filters = {
        manager: manager,
        skills: reqSkills,
        candidate: candidate,
        department: department,
        salary: { min: minSalary, max: maxSalary },
        experience: { min: experience[0], max: experience[1] },
      }
      setSalaryError("")
      setRoles(filters)
      onCloseFilter()
    }
  }

  return (
    <>
      <Button
        disableRipple
        color='inherit'
        endIcon={<FilterListIcon />}
        onClick={onOpenFilter}
      >
        {t("Filters")}&nbsp;
      </Button>

      <Drawer
        anchor='right'
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: 280, border: "none", overflow: "hidden" },
        }}
      >
        <Stack
          direction='row'
          alignItems='center'
          justifyContent='space-between'
          sx={{ px: 1, py: 2 }}
        >
          <Typography variant='subtitle1' sx={{ ml: 1 }}>
            {t("Filters")}
          </Typography>
          <IconButton onClick={onCloseFilter}>
            <CloseIcon sx={{ mx: 1 }} />
          </IconButton>
        </Stack>

        <Divider />

        <Scrollbar>
          <Stack spacing={3} sx={{ p: 3 }}>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("Department")}
              </Typography>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id='department-search'
                  value={department}
                  MenuProps={MenuProps}
                  options={mappedDepartments}
                  filterSelectedOptions
                  onChange={(e, value) => setDepartment(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder={t("AllDepartments")}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("HiringStatus")}
              </Typography>
              <RadioGroup
                value={candidate}
                onChange={(e) => setCandidate(e.target.value)}
              >
                {FILTER_CANDIDATE.map((item) => (
                  <FormControlLabel
                    key={item}
                    value={item}
                    control={<Radio />}
                    label={t(item)}
                  />
                ))}
              </RadioGroup>
            </div>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("RequiredExperience")}
              </Typography>
              <Box sx={{ mx: 1 }}>
                <Slider
                  value={experience}
                  step={1}
                  min={1}
                  max={MAX_LIMIT}
                  valueLabelDisplay='auto'
                  onChange={(e) => setExperience(e.target.value)}
                />
              </Box>
            </div>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("SalaryRange")}
              </Typography>
              <FormGroup>
                <FormControl sx={{ flexDirection: "row" }}>
                  <NumericFormat
                    className='res-mx'
                    value={minSalary}
                    placeholder={"Min"}
                    name='minSalary'
                    allowNegative={false}
                    customInput={TextField}
                    onValueChange={(values, sourceInfo) =>
                      setMinSalary(values.floatValue)
                    }
                    isAllowed={(values) => {
                      const { floatValue } = values
                      return (
                        floatValue < MAX_SALARY_LIMIT ||
                        [null, undefined].includes(floatValue)
                      )
                    }}
                  />
                  <NumericFormat
                    className='res-mx'
                    value={maxSalary}
                    placeholder={"Max"}
                    name='maxSalary'
                    allowNegative={false}
                    customInput={TextField}
                    onValueChange={(values, sourceInfo) =>
                      setMaxSalary(values.floatValue)
                    }
                    isAllowed={(values) => {
                      const { floatValue } = values
                      return (
                        floatValue < MAX_SALARY_LIMIT ||
                        [null, undefined].includes(floatValue)
                      )
                    }}
                  />
                </FormControl>
              </FormGroup>
              {salaryError && (
                <Typography
                  variant='span'
                  sx={{
                    marginTop: "5px",
                    color: "#FF4842",
                    fontWeight: 400,
                    fontSize: "1rem",
                  }}
                >
                  {salaryError}
                </Typography>
              )}
            </div>

            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("RequiredSkills")}
              </Typography>

              <FormControl sx={{ width: "100%" }}>
                <Autocomplete
                  multiple
                  id='tags-outlined'
                  value={reqSkills}
                  MenuProps={MenuProps}
                  options={skills?.data?.skills ?? []}
                  filterSelectedOptions
                  onChange={(e, value) => setReqSkills(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder={t("Skills")}
                    />
                  )}
                />
              </FormControl>
            </div>
            <div>
              <Typography variant='subtitle1' gutterBottom>
                {t("JobManager")}
              </Typography>
              <FormControl fullWidth>
                <Select
                  displayEmpty
                  MenuProps={MenuProps}
                  id='selectManager'
                  value={manager}
                  onChange={(e) => setManager(e.target.value)}
                >
                  <MenuItem
                    sx={{
                      fontWeight: 600,
                      color: "#5082dd",
                      opacity: "1 !important",
                    }}
                    disabled
                    value=''
                  >
                    <em>{t("JobManager")} </em>
                  </MenuItem>
                  {members?.data?.users
                    ?.filter((user) => user.is_active)
                    .map((option) => [
                      <MenuItem key={option.id} value={option.id}>
                        {option.first_name} {option.last_name}
                      </MenuItem>,
                    ])}
                </Select>
              </FormControl>
            </div>
          </Stack>
        </Scrollbar>

        <Box
          className='filter-ftr'
          justifyContent='space-evenly'
          sx={{ display: "flex", p: 3 }}
        >
          <Button
            type='reset'
            color='inherit'
            variant='outlined'
            startIcon={<ClearAllOutlinedIcon />}
            onClick={handleClear}
          >
            {t("ClearAll")}
          </Button>
          <Button
            type='submit'
            color='primary'
            variant='contained'
            startIcon={<ManageHistoryOutlinedIcon />}
            onClick={applyFilter}
          >
            {t("Apply")}
          </Button>
        </Box>
      </Drawer>
    </>
  )
}
