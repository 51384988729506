import * as ActionTypes from "../constants/userQuestion";
const initialState = { data: [] };
const userQuestionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCHMARKEDQUESTION_ON_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSucceed: action.payload.success,
      };
    case ActionTypes.MARKQUESTION_ON_SUCCESS:
      return { ...state, marked: true };

    default:
      return state;
  }
};

export default userQuestionReducer;
