import React, { useEffect, useState } from "react"
import LiveHeader from "../../liveHeader"
import { styled } from "@mui/material/styles"
import { Checking, About } from "../.."
import { useNavigate, useLocation } from "react-router-dom"
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import {
  Box,
  Container,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Grid,
} from "@mui/material"

const RootStyle = styled("div")(({ theme }) => ({
  border: " 1px solid #e2e8f0",
  borderBottom: 0,
  background: "white",
  boxSizing: "border-box",
  borderTopLeftRadius: " 10px",
  borderTopRightRadius: " 10px",
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}))

const Panel = styled("div")(({ theme }) => ({
  padding: "1.8rem 2.8rem 1rem 2.8rem ",
}))

const Jumbotron = styled("div")(({ theme }) => ({
  // padding: '2rem',
}))

export default function InterviewDetailsScreen() {
  const [activeStep, setActiveStep] = useState(0)
  const [canProceed, setCanProceed] = useState(true)
  const interviewFormat = "Live Video Call followed by Question"

  const navigate = useNavigate()
  const location = useLocation()
  const { interview, active, isInterviewer } = location.state

  useEffect(() => {
    window.sessionStorage.removeItem("recordings")
  }, [])

  useEffect(() => {
    if (active === true) {
      interview?.interview_format !== "on_site"
        ? setActiveStep(1)
        : setActiveStep(0)
      setCanProceed(true)
    }
  }, [active])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)

    const steps = getSteps()
    if (activeStep === steps.length - 1) {
      if (interviewFormat === "live_video_call_followed_by_question") {
        return navigate("/live-interview/start", {
          replace: true,
          state: { interview: interview },
        })
      } else {
        return navigate("/interview/start", {
          replace: true,
          state: { interview: interview, isInterviewer: isInterviewer },
        })
      }
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const getSteps = () => {
    if (interview?.interview_format !== "on_site") {
      return ["Job Details and Connection Testing", "Company Overview"]
    } else {
      return ["Job Details and Connection Testing"]
    }
  }
  const isCandidate = false

  const getStepContent = (step) => {
    if (interview?.interview_format !== "on_site") {
      switch (step) {
        case 0:
          return (
            <Checking
              interview={interview}
              onCanProceed={setCanProceed}
              isInterviewer={isInterviewer}
            />
          )
        case 1:
          return <About interview={interview} />
        default:
          return "Unknown step"
      }
    } else {
      if (step === 0) {
        return (
          <Checking
            interview={interview}
            onCanProceed={setCanProceed}
            isInterviewer={isInterviewer}
          />
        )
      }
    }
  }

  const steps = getSteps()

  return (
    <Container
      maxWidth='md'
      spacing={3}
      sx={{
        minHeight: " 100vh",
        paddingBottom: "100px",
        pt: 3,
        boxSizing: "border-box",
      }}
    >
      <Stepper
        className='stepper-head'
        activeStep={activeStep}
        sx={{
          justifyContent: { xs: "flex-start", sm: "space-between" },
          mb: 2,
          alignItems: { xs: "flex-start", sm: "center" },
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {steps.map((label, index) => {
          const stepProps = {}
          const labelProps = {}

          return (
            <Step key={label} {...stepProps} sx={{ mr: 2 }}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <RootStyle>
          <Panel>
            <Grid container spacing={2}>
              <Grid item sx={{ pt: 6, pb: 5, mt: 2 }} xs={12}>
                <Jumbotron>
                  <LiveHeader />
                  <Typography
                    variant='subtitle2'
                    component='p'
                    sx={{
                      mb: 1,
                      color: "#475569",
                      lineHeight: "24px",
                      fontSize: "16px !important",
                      fontWeight: 400,
                    }}
                  >
                    All steps completed - you&apos; re finished
                  </Typography>
                </Jumbotron>
              </Grid>
            </Grid>
          </Panel>

          <Box className='stepper-footer' sx={{ display: "flex" }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={handleReset}
              variant='contained'
              endIcon={
                <ArrowForwardIosIcon sx={{ fontSize: "14px !important" }} />
              }
            >
              Reset
            </Button>
          </Box>
        </RootStyle>
      ) : (
        <>
          <RootStyle>
            <Panel>
              <div id='inner-panel'>{getStepContent(activeStep)}</div>
            </Panel>
          </RootStyle>

          <Box
            className='stepper-footer'
            sx={{ display: "flex", justifyContent: "space-around" }}
          >
            {[1, 2].includes(activeStep) && (
              <Button
                color='inherit'
                onClick={handleBack}
                variant='contained'
                sx={{ mr: 1 }}
                startIcon={
                  <ArrowBackIosNewIcon sx={{ fontSize: "14px !important" }} />
                }
              >
                Previous
              </Button>
            )}

            <Box sx={{ flex: "1 1 auto" }} />

            <Button
              variant='contained'
              onClick={handleNext}
              disabled={!canProceed}
              endIcon={
                <ArrowForwardIosIcon sx={{ fontSize: "14px !important" }} />
              }
            >
              Next
            </Button>
          </Box>
        </>
      )}
    </Container>
  )
}
