import { useState, useEffect } from "react"
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  FormLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useFormik, Form, FormikProvider } from "formik"
import { useDispatch, useSelector } from "react-redux"
import { updateProfile, resetMembersFlag } from "src/redux/actions/member"
import { useTranslation } from "react-i18next"
import DragDrop from "src/components/dragndrop/DragDrop"

//************************** */
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 1073741824 //1 gb file

const ALLOWED_IMAGES_FORMAT = [
  "image/svg",
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
]
export default function ProfileModal({ isShowingModal, hide }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [imageFileError, setImageFileError] = useState("")
  const [initialState, setInitialState] = useState({
    firstName: "",
    lastName: "",
    uploadAvatar: {},
  })

  const logged = useSelector((state) => state.login)
  const members = useSelector((state) => state.members)

  useEffect(() => {
    return () => {
      hide()
      resetForm({ values: "" })
    }
  }, [])

  useEffect(() => {
    if (isShowingModal) {
      setInitialState({
        firstName: logged?.user?.first_name,
        lastName: logged?.user?.last_name,
        uploadAvatar: logged?.user.avatar_url ?? {},
      })
    }
  }, [logged, isShowingModal])

  useEffect(() => {
    if (members?.isFailure) {
      dispatch(resetMembersFlag())
    }

    if (members?.profileEdit) {
      dispatch(resetMembersFlag())
      dispatch({ type: "REINTITALIZE_SESSION", payload: members?.user })
    }
  }, [members])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    onSubmit: (values) => {
      const formData = new FormData()

      if (values.uploadAvatar.file === undefined) {
        if (values.uploadAvatar.removed) {
          formData.append("avatar", "")
        }
      } else {
        formData.append("avatar", values.uploadAvatar.file)
      }

      handleCloseSave()
      dispatch(updateProfile(logged?.user?.key, formData))
    },
  })

  const {
    errors,
    touched,
    values,
    resetForm,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleBlur,
  } = formik

  const handleCloseSave = () => {
    hide()
  }

  return (
    isShowingModal && (
      <Dialog
        className='dialog800'
        open={isShowingModal}
        onClose={handleCloseSave}
        scroll='paper'
        maxWidth='xl'
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {t("EditProfile")}
          <IconButton
            aria-label='close'
            onClick={handleCloseSave}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <FormikProvider value={formik}>
            <Form
              id='addMember'
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor='firstName' required>
                      {t("FirstName")}
                    </FormLabel>
                    <TextField
                      disabled
                      fullWidth
                      type='text'
                      variant='outlined'
                      autoComplete='firstName'
                      placeholder={t("FirstName")}
                      name='firstName'
                      {...getFieldProps("firstName")}
                      value={values.firstName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                      aria-describedby='firstName-helper-text'
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <FormLabel htmlFor='lastName' required>
                      {t("LastName")}
                    </FormLabel>
                    <TextField
                      disabled
                      fullWidth
                      type='text'
                      variant='outlined'
                      autoComplete='lastName'
                      placeholder={t("LastName")}
                      name='lastName'
                      {...getFieldProps("lastName")}
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                      aria-describedby='lastName-helper-text'
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl sx={{ mb: 1 }} className='avatar-field'>
                    <FormLabel>{t("Avatar")}</FormLabel>
                    <DragDrop
                      accept='image/*'
                      value={values.uploadAvatar}
                      url={values.uploadAvatar?.url}
                      name='uploadAvatar'
                      onChange={(file) => {
                        setFieldValue("uploadAvatar", file)
                      }}
                      onError={(error) => setImageFileError(error)}
                      validFileTypes={ALLOWED_IMAGES_FORMAT}
                      maxFileSizeInBytes={DEFAULT_MAX_FILE_SIZE_IN_BYTES}
                      isImage={true}
                    />
                    {imageFileError && (
                      <Typography sx={{ color: "red" }}>
                        {imageFileError}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>

        <DialogActions className='dialog-footer'>
          <Button
            onClick={handleCloseSave}
            variant='outlined'
            color='primary'
            size='medium'
          >
            {t("Cancel")}
          </Button>

          <Button
            autoFocus
            onClick={handleSubmit}
            variant='contained'
            size='medium'
            type='submit'
          >
            {t("Save")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
