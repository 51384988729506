import React from "react";
import Countdown from "react-countdown";
import { Box, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

const Panel = styled("div")(({ theme }) => ({
  padding: "1.8rem 2.8rem 0 2.8rem ",
}));

const TimeProgress = React.memo(({ totalTime, stop }) => {
  const _totalTime = stop ? 0 : totalTime;

  const interviewTimeHasEnded = () => {
    return (
      <Stack
        className='countdown-timer finished'
        direction='row'
        justifyContent='center'
        alignItems='center'
      >
        <Box className='cd-block'>
          0<span>Hours</span>
        </Box>
        <Box className='colon'>.</Box>
        <Box className='cd-block' sx={{}}>
          0<span>Minutes</span>
        </Box>
        <Box className='colon'>.</Box>
        <Box className='cd-block' sx={{}}>
          0<span>Seconds</span>
        </Box>
      </Stack>
    );
  };

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return interviewTimeHasEnded();
    } else {
      return (
        <Stack
          className='countdown-timer'
          direction='row'
          justifyContent='center'
          alignItems='center'
        >
          <Box className='cd-block'>
            {hours}
            <span>Hours</span>
          </Box>
          <Box className='colon'>.</Box>
          <Box className='cd-block'>
            {minutes}
            <span>Minutes</span>
          </Box>
          <Box className='colon'>.</Box>
          <Box className='cd-block'>
            {seconds}
            <span>Seconds</span>
          </Box>
        </Stack>
      );
    }
  };

  return (
    <Panel>
      <Countdown date={Date.now() + _totalTime} renderer={renderer} />
    </Panel>
  );
});

export default TimeProgress;
