import React, { useEffect, useState } from "react"
// material
import {
  IconButton,
  Grid,
  TextField,
  FormLabel,
  FormControl,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Autocomplete,
  Chip,
} from "@mui/material"
import { useFormik, Form, FormikProvider } from "formik"
import * as Yup from "yup"
import CloseIcon from "@mui/icons-material/Close"
import { createTheme } from "@mui/material/styles"
import { grey } from "@mui/material/colors"
// redux action
import {
  createDepartment,
  updateDepartment,
  getAllDepartment,
  resetCreateDepartmentFlag,
} from "../../../../redux/actions/department"
import { useDispatch, useSelector } from "react-redux"

import { useTranslation } from "react-i18next"

const theme = createTheme({
  palette: {
    secondary: {
      main: grey[400],
    },
  },
})

const candidateSkillsArr = [
  { name: "hr", value: "HR" },
  { name: "java", value: "JAVA" },
  { name: "csharp", value: "C#" },
  { name: "jscript", value: "JavaScript" },
  { name: "angular", value: "Angular" },
  { name: "reactjs", value: "ReactJs" },
  { name: "reactnative", value: "React Native" },
  { name: "designing", value: "Designing" },
]

export default function CreateDeptModal({
  isShowingDpt,
  hide,
  flag,
  initialVal,
  data,
}) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isError, setIsError] = useState(false)
  const createDept = useSelector((state) => state.createDepart)
  const depts = useSelector((state) => state.departments)

  const DepartmentSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, t("TooShort!"))
      .max(50, t("TooLong!"))
      .required(t("ErrDepartmentNameReq"))
      .matches(/[^\s]/, t("BlankNotAllowed")),
    description: Yup.string().min(2, t("TooShort!")).max(5000, t("TooLong!")),
  })

  const submission = (values, { resetForm }) => {
    if(deptNameError !== '')
      return

    let formData = {
      name: values?.name,
      description: values?.description,
      skills: values.skills,
    }

    if (flag) {
      dispatch(createDepartment(formData))
      hide()
    } else {
      dispatch(updateDepartment(data?.key, formData))
      hide()
    }

    resetForm({ values: "" })
  }
  const [deptNameError, setDeptNameError] = useState('')
  const [initialState, setInitialState] = useState({
    name: "",
    description: "",
    skills: [],
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialState,
    validationSchema: DepartmentSchema,
    onSubmit: submission,
  })

  const {
    errors,
    touched,
    values,
    resetForm,
    handleChange,
    handleSubmit,
    getFieldProps,
    setFieldValue,
  } = formik

  useEffect(() => {
    if (createDept?.created && flag) {
      dispatch(resetCreateDepartmentFlag())
      dispatch(getAllDepartment())
    }
  }, [createDept])

  useEffect(() => {
    values.name = initialVal?.name ?? ""
    values.description = initialVal?.description ?? ""
    values.skills = initialVal?.skills ?? []

    setInitialState({
      name: initialVal?.name ?? "",
      description: initialVal?.description ?? "",
      skills: initialVal?.skills ?? [],
    })
  }, [initialVal, isShowingDpt])

  useEffect(() => {
    if (flag !== true)
      setInitialState({
        name: data?.name ?? "",
        description: data?.description ?? "",
        skills: data?.skills ?? [],
      })
  }, [data])

  const handleCloseSave = () => {
    hide()
    resetForm()
  }

  return (
    <>
      {isShowingDpt && (
        <>
          <Dialog
            className='dialog800'
            open={isShowingDpt}
            onClose={handleCloseSave}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            scroll='paper'
            maxWidth='xl'
          >
            <DialogTitle
              className='dialog-title'
              sx={{ justifyContent: "space-between", display: "flex" }}
            >
              {flag ? t("AddNewDepartment") : t("EditDepartmentTitle")}
              <IconButton
                aria-label='close'
                onClick={handleCloseSave}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className='dialog-content' dividers={true}>
              <Grid container spacing={0}>
                <Grid item sm={12}>
                  <FormikProvider value={formik}>
                    <Form
                      id='addDepartment'
                      autoComplete='off'
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <Stack spacing={3}>
                        <FormControl>
                          <FormLabel htmlFor='name' required>
                            {t("DepartmentTitle")}
                          </FormLabel>
                          <TextField
                            fullWidth
                            type='text'
                            variant='outlined'
                            autoComplete=''
                            placeholder={t("DepartmentTitle")}
                            name='name'
                            // {...getFieldProps("name")}
                            onChange={handleChange}
                            onBlur={(e) => {
                              if (depts?.data?.departments?.find(dept => dept.name.toLowerCase() === e.target.value.toLowerCase())) {
                                setDeptNameError(t('DepartmentWithSameNameExist'))
                              } else {
                                setDeptNameError('')
                              }
                            }}
                            value={values.name}
                            error={Boolean(touched.name && errors.name) || deptNameError !== ''}
                            helperText={(touched.name && errors.name) || deptNameError}
                          />
                        </FormControl>

                        <FormControl sx={{ width: "100%" }}>
                          <FormLabel>{t("Skills")}</FormLabel>
                          <Autocomplete
                            multiple
                            onChange={(e, value) => {
                              const isContainEmptyString = value.find(
                                (val) => val.trim() === "",
                              )
                              if (!isContainEmptyString) {
                                handleChange(e)
                                values.skills = value
                                setFieldValue("skills", value)
                              }
                            }}
                            value={values.skills}
                            options={candidateSkillsArr.map(
                              (option) => option.value,
                            )}
                            variant='outlined'
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  variant='outlined'
                                  label={option}
                                  sx={{ mx: 1 }}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant='outlined'
                                placeholder={t("TypeToAddSkills")}
                              />
                            )}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>{t("DepartmentDescription")}</FormLabel>
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            type='text'
                            variant='outlined'
                            autoComplete=''
                            placeholder={t("DepartmentDescription")}
                            name='description'
                            // {...getFieldProps("description")}
                            {...(isError ? "error" : "")}
                            value={values.description}
                            onChange={handleChange}
                            error={Boolean(
                              touched.description && errors.description,
                            )}
                            helperText={
                              touched.description && errors.description
                            }
                          />
                        </FormControl>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className='dialog-footer'>
              <Button
                onClick={handleCloseSave}
                variant='outlined'
                color='primary'
                size='medium'
              >
                {t("Cancel")}
              </Button>

              <Button
                onClick={handleSubmit}
                autoFocus
                variant='contained'
                size='medium'
              >
                {t("Save")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}
