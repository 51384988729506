import { Link as RouterLink } from "react-router-dom"
import { React } from "react"
import { styled } from "@mui/material/styles"
import { Typography, Container, Link, List, ListItem } from "@mui/material"
import Page from "../components/Page"
import { useTranslation } from "react-i18next"

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: 50,
  borderRadius: 16,
  boxShadow: "0 0 14px rgb(0 0 0 / 4%)",
  background: "#fff",
  [theme.breakpoints.up("md")]: {
    marginTop: 150,
    padding: "4rem",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: 100,
    padding: "2rem 2.5rem",
  },
}))

// ----------------------------------------------------------------------

export default function Terms(props) {
  const { t } = useTranslation()
  return (
    <Page title='Terms of Service'>
      <Container maxWidth='lg'>
        <ContentStyle className='page-content'>
          <Typography variant='h2' component='h2' sx={{ mb: 3 }}>
            Terms of Service
          </Typography>
          <Typography variant='body1'>
            Welcome to <strong>IntVue</strong>, the intelligent video interview
            platform developed by IntVue.
          </Typography>
          <Typography variant='body1'>
            Please read these Terms of Service in addition to our{" "}
            <Link
              underline='none'
              href={`${process.env.REACT_APP_FE_BASE_URL}/privacypolicy/app`}
              rel='noreferrer'
              sx={{ fontWeight: 600 }}
            >
              Privacy Policy
            </Link>
            , which is available here. If you do not agree with any of these
            terms, you are not authorized to access or use IntVue.
          </Typography>
          <Typography variant='body1'>
            By accessing or using IntVue, including conducting interviews or
            providing information through the Service, you (either the
            "Employer" or "Candidate") agree to comply with these Terms of
            Service. These Terms constitute a legally binding agreement between
            you ("you," "your," "Employer," "Candidate," or "User") and IntVue
            ("us," "we," "our," "owner," or "IntVue"). These Terms do not
            attempt to override any legally binding agreements between the
            Employer and the Candidate. The Employer's terms may govern the
            Candidate's relationship with the Employer.
          </Typography>
          <Typography variant='body1'>
            We offer support to our users through IntVue's designated services,
            subject to California's governing laws. Our language usage,
            including singular/plural terms and gender pronouns, is
            interchangeable and represents the same concept.
          </Typography>

          <Typography variant='body1'>
            The content on{" "}
            <Link
              underline='none'
              href={`${process.env.REACT_APP_FE_BASE_URL}`}
              rel='noreferrer'
            >
              Intvue website
            </Link>{" "}
            is protected by copyright and trademark laws.
          </Typography>
          <Typography variant='body1'>
            Our Service is exclusively for individuals 18 years of age or older.
          </Typography>
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Updates to and Acceptance of Revised Terms and Conditions
          </Typography>

          <Typography variant='body1'>
            We may change this Agreement or our policies for the Website or
            Services without prior notice. The changes will become effective
            once we post an updated version of this Agreement on the Website,
            and we will update the effective date at the top of this page. Your
            continued use of the Website or Services after any changes indicates
            your acceptance of those changes.
          </Typography>
          <hr />

          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Your Account and Information{" "}
          </Typography>
          <Typography variant='body1'>
            To use IntVue, register an account and provide accurate contact and
            personal information. Notify us of any changes to this information.
            We reserve the right to refuse account creation at our discretion.
            You are responsible for maintaining account security and all
            activities associated with it. False information may result in
            account termination. Alert us of any unauthorized use or security
            breaches. We are not liable for any actions or damages resulting
            from your use of the account.
          </Typography>

          <Typography variant='body1'>
            We can suspend, disable, or delete your account if you violate this
            Agreement or harm our reputation. If we delete your account, you
            cannot re-register. We may block your email and IP address to
            prevent future registration.
          </Typography>
          <Typography variant='body1'>
            You give us the right to use, copy, and modify the information and
            content you provide for IntVue. We may use it to provide our service
            and share it with the Employer. This is outlined in our Terms and
            Privacy Policy.
          </Typography>

          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            License to Use IntVue
          </Typography>

          <Typography variant='body1'>
            IntVue grants you a limited, non-transferable license to access and
            use IntVue solely for the purpose of communicating and interviewing
            with an Employer. You may not use IntVue or its contents for any
            commercial purposes without IntVue's express written consent.
          </Typography>

          <hr />

          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Intellectual Property and Acceptable Use
          </Typography>
          <Typography variant='body1'>
            All content on the website is owned by IntVue, our affiliates, or
            third parties unless otherwise uploaded by users. Content includes
            text, graphics, images, audio, video, software, data compilations,
            page layout, underlying code, and software. This includes all
            content on the IntVue website, including user-uploaded content.
          </Typography>
          <Typography variant='body1'>
            By using our website, you acknowledge that the content is protected
            by copyright, trademark, and other intellectual property laws.
            Therefore, you may not use any material on this site, including
            trademarks, logos, or service marks, without prior written
            permission from the owner. We do not grant any license or right to
            use them unless specifically authorized by the owner.
          </Typography>
          <Typography variant='body1'>
            You can use the content of our website for your own personal and
            non-commercial use. This means that you can do the following:
          </Typography>
          <List sx={{ width: "100%", py: 0, mb: 1 }}>
            <ListItem>
              Look at and read the content on a computer screen.
            </ListItem>
            <ListItem>
              Save and store the content on a disk, but not on any server or
              storage device connected to a network.
            </ListItem>
            <ListItem>
              Print one copy of the content for your personal use.
            </ListItem>
          </List>
          <Typography variant='body1'>
            You cannot use our content for commercial purposes unless you have
            written permission from IntVue. This means you can not reproduce,
            change, copy, or distribute any content on our website to make
            money.
          </Typography>
          <Typography variant='body1'>
            When you submit content on our website, you take responsibility for
            its legality, reliability, appropriateness, originality, and
            copyright.
          </Typography>
          <Typography variant='body1'>
            You can not upload, distribute, or publish any content on our
            website that:
          </Typography>
          <List sx={{ width: "100%", py: 0 }}>
            <ListItem>
              Contains false information, is fraudulent, or violates someone's
              privacy or intellectual property rights. It also can not be
              abusive, illegal, or offensive in any way.
            </ListItem>
            <ListItem>
              Encourages illegal activity, violates someone's rights, or breaks
              any laws.
            </ListItem>
            <ListItem>
              Contains viruses, spam, chain letters, or other types of unwanted
              content.
            </ListItem>
            <ListItem>
              You can not use a fake email or pretend to be someone else. You
              also can not upload any commercial content to our website.
            </ListItem>
          </List>
          <Typography variant='body1'>
            You also need to acknowledge that you promise and guarantee that:
          </Typography>
          <List sx={{ width: "100%", py: 0 }}>
            <ListItem>
              You own or control all the rights to the content you post.
            </ListItem>
            <ListItem>
              The content you provide is truthful and accurate.
            </ListItem>
            <ListItem>
              The content you provide follows our terms and conditions and will
              not harm anyone.
            </ListItem>
            <ListItem>
              You will protect IntVue from any claims resulting from the content
              you provide.
            </ListItem>
          </List>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Prohibited Use{" "}
          </Typography>
          <Typography variant='body1'>
            The Agreement includes rules that you must follow when using the
            Website or its Content. These rules prohibit you from:
          </Typography>
          <List sx={{ width: "100%", py: 0 }}>
            <ListItem>
              Using the Website or its Content for any illegal activities.
            </ListItem>
            <ListItem>
              Asking or encouraging others to participate in illegal activities.
            </ListItem>
            <ListItem>
              Breaking international, federal, provincial, or state laws or
              local rules and regulations.
            </ListItem>
            <ListItem>
              Infringing on our or anyone else's intellectual property rights.
            </ListItem>
            <ListItem>
              Harassing, abusing, insulting, harming, defaming, slandering,
              disparaging, intimidating, or discriminating against others based
              on their gender, sexual orientation, religion, ethnicity, race,
              age, national origin, or disability.
            </ListItem>
            <ListItem>Providing false or misleading information.</ListItem>
            <ListItem>
              Uploading or transmitting viruses or any other harmful code that
              could damage the Service, other websites, or the Internet.
            </ListItem>
            <ListItem>
              Sending spam or engaging in phishing, pretexting, spidering,
              crawling, or scraping.
            </ListItem>
            <ListItem>
              Using the Website or its Content for immoral or obscene purposes.
            </ListItem>
            <ListItem>
              Attempting to bypass or interfere with the Service's security
              features or those of other websites or the Internet.
            </ListItem>
          </List>
          <Typography variant='body1'>
            We may end your access to our Service or associated website if you
            break any of the rules we've set.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Registration
          </Typography>
          <List sx={{ width: "100%", py: 0 }}>
            <ListItem>
              Make sure the information you give us when you sign up, or at any
              other time, is accurate and complete.
            </ListItem>
            <ListItem>
              Tell us right away if anything changes in the personal information
              you gave us during registration, so we can contact you easily.
            </ListItem>
            <ListItem>
              We can stop your registration immediately and for valid reasons if
              you break our rules.
            </ListItem>
            <ListItem>
              You can end your registration by sending us a written notice to
              the address provided at the end of these terms and conditions. If
              you cancel, you must stop using IntVue right away.
            </ListItem>
            <ListItem>
              If we cancel or suspend your registration, it doesn't affect any
              of your legal rights.
            </ListItem>
          </List>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Password and security
          </Typography>
          <List sx={{ width: "100%", py: 0 }}>
            <ListItem>
              When signing up for IntVue, you'll need to create a password that
              you should keep secret and not share with anyone.
            </ListItem>
            <ListItem>
              If we suspect any misuse of the website or account, or if we
              suspect a security breach, we may ask you to change your password
              or temporarily stop using your account.
            </ListItem>
          </List>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Links to other websites
          </Typography>
          <Typography variant='body1'>
            While our website may include links to other websites, we are not
            suggesting or implying that we approve, sponsor, endorse, or have
            any connection with those sites unless we say so specifically.
          </Typography>
          <Typography variant='body1'>
            IntVue isn't responsible for the actions, products, services, or
            content of any other third parties. You understand and agree that
            we're not responsible for checking, reviewing, or guaranteeing the
            offerings of any person or business or the content on their
            websites. We suggest that you read the legal statements and
            Conditions of Use of any third-party website or service you access
            through a link on our website. You assume all risks when linking to
            any off-site websites.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Video and Audio Content
          </Typography>
          <Typography variant='body1'>
            By using IntVue for video and audio communication and turning on
            your webcam and microphone, you agree to let others see and hear
            you. IntVue may also record and store this information on its
            servers in your Account.
          </Typography>
          <Typography variant='body1'>
            IntVue will not use your recorded content for anything that's not
            related to your Account. But anyone who you show this content to
            will be able to hear and see it.
          </Typography>
          {/* <Typography variant='body1'>
            IntVue will not share or let anyone see your content unless you give
            them permission, except when required by a subpoena or court order.
            If IntVue ever gets a subpoena for your Account's content, they will
            notify you and give you a chance to object before sharing anything.
          </Typography> */}
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Accuracy of Data
          </Typography>
          <Typography variant='body1'>
            IntVue doesn't guarantee that the materials or content on their
            website are accurate, complete, or up-to-date. IntVue doesn't
            provide any warranty, either explicit or implied, about the
            accuracy, quality, completeness, legality, reliability, or
            usefulness of any materials or content.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Security of Communications{" "}
          </Typography>
          <Typography variant='body1'>
            At IntVue, we take the security of your information seriously and do
            everything we can to protect your personal data. However, since we
            operate online, there is always a chance that someone else might
            intercept your messages.
            {/* Despite this risk, we are committed to
            taking reasonable measures to keep your data safe. */}
          </Typography>
          <Typography variant='body1'>
            IntVue cannot promise that messages sent over the internet will
            always be secure and private. By using IntVue's service, you agree
            not to hold IntVue responsible if there is a security breach. You
            also agree not to hold IntVue responsible if you experience any harm
            or loss of privacy due to any communication sent over the internet.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Availability of the Website and Disclaimers
          </Typography>
          <Typography variant='body1'>
            IntVue provides online services, tools, information, and facilities
            through its website. These services are offered "AS IS" and may not
            always be available. We do not guarantee that the Service will be
            error-free. We are not responsible for ensuring that the Service
            meets your specific needs, is accurate, compatible, or of
            satisfactory quality. Additionally, we are not obligated to update
            any information on the website.
          </Typography>
          <Typography variant='body1'>
            IntVue does its best to ensure that the website is safe and free
            from mistakes, viruses, and other harmful programs. However, we
            cannot guarantee this, so it's up to users to keep their personal
            information and devices secure.
          </Typography>
          <Typography variant='body1'>
            IntVue is not responsible if the website is not available or
            experiences problems.
          </Typography>
          <Typography variant='body1'>
            IntVue and its staff, contractors, or anyone involved in creating or
            providing IntVue will not be responsible for any misuse of the
            platform.
          </Typography>
          <Typography variant='body1'>
            IntVue has the right to change or stop any part of the website,
            including products and services. These terms and conditions will
            still apply to any changes unless stated otherwise.
          </Typography>

          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Privacy{" "}
          </Typography>
          <Typography variant='body1'>
            You can find IntVue's Privacy Policy{" "}
            <Link
              underline='none'
              href={`${process.env.REACT_APP_FE_BASE_URL}/privacypolicy`}
              rel='noreferrer'
            >
              here
            </Link>
            . Remember to check it often since it may change over time. The
            Privacy Policy explains how IntVue collects, uses, and shares
            information you provide while using the platform. By using IntVue,
            you agree to let us collect, use, and share your information.
          </Typography>
          <Typography variant='body1'>
            Please note that the IntVue Privacy Policy only applies to IntVue
            and not to the Employer who may access the information and content
            you provide. The Employer may have their own privacy policy that
            covers how they handle your information. It's up to you to review
            the Employer's privacy policy, and IntVue is not responsible for how
            the Employer uses your information.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Feedback
          </Typography>
          <Typography variant='body1'>
            If you give Intvue any comments, ideas, or other material about
            website (intvue.com) (as long as it's legal), you're giving
            ownership of that material to IntVue. This means that IntVue can use
            it however they want, without having to keep it secret, give you
            credit, or pay you. If the first part of this agreement isn't enough
            to transfer ownership, you're also giving IntVue permission to use,
            display, copy, modify, and distribute your material in any way they
            want, forever, in any kind of media. By giving feedback, you promise
            that you have the right to give that feedback and that it doesn't
            violate anyone else's rights or contain any illegal information.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Limitation of Liability{" "}
          </Typography>

          <ul>
            <li>
              These terms and conditions will not stop us or you from being
              responsible if someone gets hurt or dies because of our or your
              carelessness. We also can not avoid liability for dishonest
              behavior or deceive. And, we can not limit or exclude any of our
              or your legal responsibilities beyond what the law allows.
            </li>
            <li>
              Since we provide the Website and its Content for free, we cannot
              be held responsible for any harm or loss you may experience while
              using it.
            </li>
            <li>
              We will not be responsible for any losses you suffer due to events
              that are outside our control and cannot be reasonably predicted or
              prevented.
            </li>
            <li>
              Within the limits allowed by law, IntVue will not be held
              responsible for any business-related losses that may occur, such
              as loss of profits, income, revenue, expected savings, business
              opportunities, goodwill, or commercial contracts.
            </li>
            <li>
              IntVue will not be liable for any loss or damage caused to data,
              databases, or software due to any reason.
            </li>
            <li>
              We will not be responsible for any special, indirect, or
              consequential loss or damage that may arise due to any reason.
            </li>
          </ul>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Indemnity
          </Typography>
          <Typography variant='body1'>
            By using IntVue, you promise to protect and not hold IntVue and its
            partners responsible for any trouble or costs that arise from your
            actions on the platform. This includes any claims or legal actions
            against IntVue because of what you say or do on the site, or if you
            break any rules or laws.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Severability
          </Typography>
          <Typography variant='body1'>
            The terms and conditions outlined in this agreement are subject to
            applicable laws and are limited in scope to avoid rendering the
            agreement unenforceable or in violation of the law. In the event
            that a court determines any provision or portion thereof to be
            illegal, invalid, or unenforceable, the parties intend for the
            remaining provisions or portions to remain valid and govern the
            subject matter of this agreement.
          </Typography>
          <hr />

          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Dispute Resolution{" "}
          </Typography>
          <Typography variant='body1'>
            This Agreement and any disputes that arise will be governed by the
            laws of California, United States. The courts located in California
            will have the exclusive power to hear and decide any legal actions
            related to this Agreement. By agreeing to this, you give up the
            right to a jury trial. This Agreement is not subject to the United
            Nations Convention on Contracts for the International Sale of Goods.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Suspension or Termination by IntVue
          </Typography>
          <Typography variant='body1'>
            IntVue reserves the right to suspend or terminate your access to our
            platform at any time, including if you violate our Terms and
            Conditions. If your access is terminated, you cannot use or access
            IntVue anymore and we are not responsible for any consequences.
            However, our commitments to warranties, confidentiality, and
            limitation of liability still apply even after termination.
          </Typography>
          <Typography variant='body1'>
            IntVue may change, end or pause its services without warning or
            responsibility, for any reason, at any time, at the company's sole
            discretion.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Cancellation of the Account by You
          </Typography>

          <Typography variant='body1'>
            You have the option to terminate your account by reaching out to
            IntVue through the provided contact information. In the event that
            you choose to do so, any personal data collected by IntVue may be
            erased.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            DMCA
          </Typography>
          <Typography variant='body1'>
            The Digital Millennium Copyright Act of 1998 (DMCA) is a law that
            helps copyright owners protect their work from being used without
            permission on websites. If you think that your copyright has been
            violated on IntVue platform, you can contact us with your complaint
            and we'll look into it.
          </Typography>
          <Typography variant='body1'>
            To make a complaint, you need to provide certain information like
            your name and contact details, a description of the infringing
            material, and proof that you own the copyright or are authorized to
            act on the owner's behalf. If you don't provide all the necessary
            information, it might take longer to resolve your complaint.
          </Typography>
          <Typography variant='body1'>
            Failure to include all of the above-listed information may result in
            the delay of the processing of your complaint.
          </Typography>
          <Typography variant='body1'>Notices should be sent to:</Typography>
          <Typography component='address' sx={{ mb: 2 }}>
            2670 S White Road Suite #125, San Jose, CA 95148 USA or{" "}
            <Link underline='none' href='mailto:support@intvue.com;'>
              {" "}
              support@intvue.com{" "}
            </Link>{" "}
          </Typography>
          <Typography variant='body1'>
            If we believe in good faith that the posted material violates
            applicable law, we will remove or disable access to such material,
            and we will notify the posting party that the material has been
            blocked or removed.
          </Typography>
          <hr />
          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            Acceptance of these terms
          </Typography>
          <Typography variant='body1'>
            By using the Website or its Services, you agree to all the terms and
            conditions outlined in this Agreement. If you do not agree with
            these terms, you are not permitted to use or access the Website or
            its Services.
          </Typography>
          <hr />

          <Typography variant='h4' component='h4' sx={{ mb: 2, mt: 1 }}>
            {t("contactusHeading")}
          </Typography>
          <Typography variant='body1'>
            {t("contactUsPara")}
            <Link
              underline='none'
              component={RouterLink}
              to='mailto:support@intvue.com;'
            >
              {" "}
              support@intvue.com{" "}
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </Page>
  )
}
