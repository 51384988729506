// constants will be added here

export const QUESTIONSETS_ON_SUCCESS = "QUESTIONSETS_ON_SUCCESS";
export const QUESTIONSETS_ON_FAILURE = "QUESTIONSETS_ON_FAILURE";
export const CREATEQUESTIONSET_ON_SUCCESS = "CREATEQUESTIONSET_ON_SUCCESS";
export const CREATEQUESTIONSET_ON_FAILURE = "CREATEQUESTIONSET_ON_FAILURE";
export const DELETEQUESTIONSET_ON_SUCCESS = "DELETEQUESTIONSET_ON_SUCCESS";
export const DELETEQUESTIONSET_ON_FAILURE = "DELETEQUESTIONSET_ON_FAILURE";
export const UPDATEQUESTIONSET_ON_SUCCESS = "UPDATEQUESTIONSET_ON_SUCCESS";
export const UPDATEQUESTIONSET_ON_FAILURE = "UPDATEQUESTIONSET_ON_FAILURE";
export const RESET_FLAGS = "RESET_FLAGS";
export const RESET_CREATED = "RESET_QUESTION_CREATED"
