import React, { useState } from "react"
import PropTypes from "prop-types"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { styled } from "@mui/material/styles"
import {
  Container,
  Link,
  Stack,
  IconButton,
  AppBar,
  Drawer,
  Toolbar,
  Box,
  Button,
} from "@mui/material"

import Images from "../../assets/img/Images"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Logo from "src/components/Logo/Logo"
import { useScrollPosition } from "src/hooks/useScrollPosotion"
import MenuOpenIcon from "@mui/icons-material/MenuOpen"
import useResponsive from "src/hooks/useResponsive"
import { useTranslation } from "react-i18next"
import LanguagePopover from "src/layouts/dashboard/LanguagePopover"
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92
const drawerWidth = 200

const RootStyle = styled(AppBar)(({ theme }) => ({
  right: 0,
  left: 0,
  zIndex: 1030,
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: "transparent",
  [theme.breakpoints.up("lg")]: {
    width: `100%`,
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  backgroundColor: "inherit",
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  width: "100%",
  borderBottom: "1px solid #aea9a9",
  borderTop: 0,
  marginBottom: "1.5rem",
}))
// ----------------------------------------------------------------------

LandingNavbar.propTypes = {
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.string,
}

export default function LandingNavbar({ setSelectedItem, selectedItem }) {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { t } = useTranslation()
  const scrollPosition = useScrollPosition()

  const xlUp = useResponsive("up", "xl")

  const checkLogin = () => {
    const user = JSON.parse(localStorage.getItem("logged"))
    return !user ? false : true
  }

  const [selectedLink, setSelectedLink] = useState(null)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }
  const handleLinkClick = (id) => {
    setSelectedLink(id)
  }

  const navItems = (
    <>
      <AnchorLink
        href='#homeDiv'
        offset={() => 100}
        onClick={() => handleLinkClick("homeDiv")}
        className={`anchor-link ${selectedLink === "homeDiv" ? "active" : ""}`}
      >
        {t("Home")}
      </AnchorLink>
      <AnchorLink
        href='#aboutDiv'
        offset={() => 100}
        onClick={() => handleLinkClick("aboutDiv")}
        className={`anchor-link ${selectedLink === "aboutDiv" ? "active" : ""}`}
      >
        {t("About")}
      </AnchorLink>
      <AnchorLink
        href='#featureDiv'
        offset={() => 100}
        onClick={() => handleLinkClick("featureDiv")}
        className={`anchor-link ${
          selectedLink === "featureDiv" ? "active" : ""
        }`}
      >
        {t("Features")}
      </AnchorLink>
      <AnchorLink
        href='#pricingDiv'
        offset={() => 100}
        onClick={() => handleLinkClick("pricingDiv")}
        className={`anchor-link ${
          selectedLink === "pricingDiv" ? "active" : ""
        }`}
      >
        {t("Pricing")}
      </AnchorLink>
      <AnchorLink
        href='#contactDiv'
        offset={() => 100}
        onClick={() => handleLinkClick("contactDiv")}
        className={`anchor-link ${
          selectedLink === "contactDiv" ? "active" : ""
        }`}
      >
        {t("Contact")}
      </AnchorLink>
      {checkLogin() === false ? (
        <>
          <Link
            underline='none'
            href='/signup'
            onClick={() => handleLinkClick("signup")}
            className={`anchor-link ${
              selectedLink === "signup" ? "active" : ""
            }`}
          >
            {t("SignUp")}
          </Link>

          <Button
            href='/login'
            variant='contained'
            color='primary'
            size='medium'
            className='nav-btn menu-btn'
            sx={{
              fontSize: "15px",
              minWidth: "84px",
              textAlign: { xs: "left", sm: "left", md: "center" },
            }}
          >
            {t("Login")}
          </Button>
        </>
      ) : (
        <Button
          href='/dashboard/app'
          variant='contained'
          color='primary'
          size='medium'
          className='nav-btn'
          sx={{
            borderRadius: {
              xs: "0 !important",
              sm: "0 !important",
              md: "6px !important",
            },
            width: { xs: "100%" },
            display: { xs: "block" },
          }}
        >
          {t("Go2Dashboard")}
        </Button>
      )}
    </>
  )

  const drawer = (
    <Box onClick={handleDrawerToggle}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerToggle}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Stack
        sx={{ textAlign: "left", width: "100%" }}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <Toolbar
          sx={{
            display: { xs: "flex", sm: "flex" },
            flexDirection: "column",
            alignItems: "flex-start",
            width: "100%",
            px: { xs: "0 !important" },
          }}
          className='navbar-nav'
          component='nav'
          variant='dense'
          // onClick={checkPress}
        >
          {navItems}
        </Toolbar>
      </Stack>
    </Box>
  )

  return (
    <RootStyle component='nav'>
      <AppBar
        className={`navbar transition-shadow ${
          scrollPosition > 0 ? "shaded" : "noShaded"
        } `}
      >
        <ToolbarStyle sx={{ padding: { xs: 0, sm: 0, md: "0", lg: "0 40px" } }}>
          <Container maxWidth={xlUp ? "xl" : "lg"} className='container1370'>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={{ xs: 0, sm: 1.5 }}
            >
              {scrollPosition > 0 ? (
                <Logo icon={Images.LogoMain} />
              ) : (
                <Logo icon={Images.LogoWhite} />
              )}
              <Stack direction='row'>
                <Box
                  sx={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LanguagePopover
                    setSelectedItem={setSelectedItem}
                    selectedItem={selectedItem}
                  />
                  <IconButton
                    aria-label='open drawer'
                    className='menuBtn'
                    edge='end'
                    color='inherit'
                    onClick={handleDrawerToggle}
                    sx={{
                      pb: { xs: 0, sm: 0, md: "inherit" },
                      mr: 1,
                      color: "text.primary",
                      fontSize: "2rem",
                      zIndex: 1,
                      paddingTop: 0,
                      display: { lg: "none", md: "none" },
                    }}
                  >
                    <MenuOpenIcon />
                  </IconButton>
                </Box>

                <Toolbar
                  sx={{
                    display: { xs: "none", sm: "none", md: "flex" },
                    justifyContent: "space-between",
                    overflowX: "auto",
                    paddingLeft: "0 !important",
                  }}
                  className='navbar-nav'
                  component='nav'
                  variant='dense'
                >
                  {navItems}
                </Toolbar>
              </Stack>
            </Stack>
          </Container>
        </ToolbarStyle>
      </AppBar>
      <Box component='nav'>
        <Drawer
          id='mobileDrawer'
          variant='temporary'
          anchor='left'
          open={mobileOpen}
          transitionDuration={700}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              overflowY: "auto",
              minHeight: 250,
              width: drawerWidth,
              backgroundColor: "hsla(0, 0%, 100%, 0.9)",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </RootStyle>
  )
}
