import { filter } from "lodash"
import React, { useState, useEffect } from "react"
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Button,
  Typography,
  TableContainer,
  TablePagination,
  Box,
} from "@mui/material"

import { useLocation } from "react-router-dom"
import TableHead from "@mui/material/TableHead"
import Page from "../components/Page"
import Scrollbar from "../components/Scrollbar"
import SearchNotFound from "../components/SearchNotFound"
import { candidateDataParsing } from "src/utils/dataParsing"
import { useSelector, useDispatch } from "react-redux"
import { getCandidates } from "src/redux/actions/candidate"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import Loader from "src/components/Loader"
import { CandidateListToolbar } from "src/sections/@dashboard/candidate"
import ViewCandidateDetails from "src/sections/@dashboard/candidate/modal/ViewCandidateDetails"
import { viewCandidateHistory } from "src/redux/actions/interviews"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
// ----------------------------------------------------------------------
const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}))

export default function Candidates(props) {
  const [page, setPage] = useState(0)
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [firstRender, setFirstRender] = useState(true)
  const [participant, setParsedParticipant] = useState([])
  const [searchName, setSearchName] = useState("")

  const candidates = useSelector((state) => state.candidateDetails)
  const loadingState = useSelector((state) => state.loadingManagement)

  const dispatch = useDispatch()
  const { state } = useLocation()
  const { t } = useTranslation()

  const [isShowingCandid, toggleCandid] = useModal()
  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - participant?.length) : 0

  const isUserNotFound = participant?.length === 0

  const uniqueRole = Array.from(
    new Set(
      participant?.map((item) =>
        item?.interviews?.map((user) => user?.roleAppliedFor),
      ),
    ),
  )

  const handleChangePage = (_event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleFilterSearch = (event) => {
    setSearchName(event.target.value)
    setPage(0)
  }

  const handleFilterByName = (event) => {
    setFilterName(event[0])
    setPage(0)
  }

  const refreshCandidates = () => {
    dispatch(getCandidates())
  }

  const handleCandidate = (key) => {
    toggleCandid()
    dispatch(viewCandidateHistory(key))
  }

  useEffect(() => {
    if (candidates?.data?.length === 0 || firstRender) {
      setFirstRender(false)
      dispatch(getCandidates(state?.vacancyId))
    }

    if (candidates?.data?.success) {
      setParsedParticipant(candidateDataParsing(candidates?.data?.candidates))
    } else {
      setParsedParticipant([])
    }
  }, [candidates, state])

  ///----------------------------
  return (
    <Page title={t("Candidates")} className='roles-page'>
      <Loader respLoading={loadingState?.loading} />
      <Container maxWidth='xl' spacing={3}>
        {userPermissions?.candidates_module ? (
          <>
            <Stack
              direction='row'
              alignItems='center'
              sx={{ mb: { xs: 2, sm: 4, md: 5 } }}
            >
              <Typography
                variant='h5'
                sx={{
                  mb: 0,
                  fontWeight: 600,
                  display: "flex",
                  flexDirection: "row",
                  color: "#434e58",
                }}
              >
                {t("Candidates")}: {participant?.length}
              </Typography>
            </Stack>

            <Card>
              <CandidateListToolbar
                filterName={filterName}
                handleFilterByName={handleFilterByName}
                searchName={searchName}
                onFilterSearch={handleFilterSearch}
                uniqueRole={uniqueRole}
                refreshCandidates={refreshCandidates}
                canSearch={
                  userPermissions?.candidates_user_can_search_for_candidates
                }
              />
              <Scrollbar>
                <TableContainer sx={{ minWidth: "100%" }}>
                  <Table className='simple-table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell align='left'>{t("FullName")}</TableCell>
                        <TableCell align='left'>{t("Email")}</TableCell>
                        <TableCell align='left'>
                          {t("VacancyAppliedFor")}
                        </TableCell>
                        <TableCell align='right'>{t("Action")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {participant
                        ?.filter(
                          (item) =>
                            item?.name
                              ?.toLowerCase()
                              ?.includes(searchName?.toLowerCase()) ||
                            item?.email
                              ?.toLowerCase()
                              ?.includes(searchName?.toLowerCase()),
                        )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row, index) => (
                          <TableRow key={row.id}>
                            <TableCell
                              component='th'
                              scope='row'
                              align='left'
                              sx={{
                                minWidth: "150px",
                              }}
                            >
                              <Stack
                                direction='row'
                                alignItems='center'
                                spacing={2}
                              >
                                <Typography
                                  variant='subtitle2'
                                  sx={{ textTransform: "capitalize" }}
                                  noWrap
                                >
                                  {row.name}
                                </Typography>
                              </Stack>
                            </TableCell>

                            <TableCell align='left'>{row.email}</TableCell>
                            <TableCell
                              align='left'
                              sx={{ width: "230px", whiteSpace: "nowrap" }}
                            >
                              <div className='mulitpleDpt'>
                                {row?.candidate_vacancies?.map((item) => (
                                  <Typography
                                    key={item.id}
                                    sx={{
                                      textTransform: "capitalize",
                                      fontWeight: 400,
                                      marginRight: "3px",
                                    }}
                                    component='span'
                                    variant='subtitle2'
                                  >
                                    {item?.roleAppliedFor}
                                  </Typography>
                                ))}
                              </div>
                            </TableCell>
                            <TableCell align='right'>
                              <Button
                                variant='outlined'
                                onClick={() => handleCandidate(row.key)}
                                sx={{ minWidth: "106px" }}
                              >
                                {t("ViewDetails")}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={4} />
                      </TableRow>
                    )}
                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={4} className='searchCell'>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component='div'
                labelRowsPerPage={t("RowsPerPage")}
                count={
                  participant?.filter((item) =>
                    item?.name
                      ?.toLowerCase()
                      ?.includes(searchName?.toLowerCase()),
                  )?.length
                }
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
          </>
        ) : (
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography sx={{ color: "text.secondary" }}>
              {t("NoAccessPAge")}
            </Typography>

            <Box
              component='img'
              src='/static/illustrations/illustration_404.svg'
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
            />
          </ContentStyle>
        )}
      </Container>
      <ViewCandidateDetails
        open={isShowingCandid}
        onClose={toggleCandid}
        canViewRecomendation={
          userPermissions?.candidates_user_can_view_recommendations
        }
        canViewFeedback={userPermissions?.candidates_user_can_view_feedback}
      />
    </Page>
  )
}
