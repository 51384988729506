import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Editor } from "@tinymce/tinymce-react"
import LoadingButton from "@mui/lab/LoadingButton"
import {
  Box,
  Tab,
  Alert,
  Stack,
  Button,
  Tabs,
  Typography,
  FormControl,
  TextField,
  Grid,
  Paper,
} from "@mui/material"

import * as Keys from "../../../../redux/constants/settings"
import { styled } from "@mui/material/styles"
import { useDispatch, useSelector } from "react-redux"
import { userKey } from "src/utils/userHelper"
import {
  getAccountConfig,
  updateAccountConfig,
  resetConfigurationsFlag
} from "src/redux/actions/accountConfig"
import { useTranslation } from "react-i18next"

// ----------------------------------------------------------------------

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ px: 0, py: 3 }}>{children}</Box>}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number,
  value: PropTypes.number,
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

const TEMPLATE_INFO = [
  "interviewCompletion",
  "interviewCompletion",
  "interviewInviteToCandidate",
  "interviewReceivedTemplate",
  "feedbackRequiredTemplate",
  "jobOfferTemplate",
]

const TEMPLATE_LABEL = [
  "IntCompletion",
  "RecordedIntInvitation",
  "InvitationtoOn-SiteInt",
  "RecordedIntReceivedConfirmation",
  "RejectionNotification",
  "JobOfferletter",
]

export default function EmailTemaplate({
  selectedItem,
  setSelectedItem,
  setDisplayCrash,
  setOpenSnackbar,
  setSeverity,
}) {
  const [value, setValue] = useState(0)
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const config = useSelector((state) => state.accountConfigurations)
  const [configAccount, setConfigAccount] = useState([])
  const [templateSubject, setTemplateSubject] = useState("")
  const [templateMail, setTemplateMail] = useState("")
  const [templateInfo, setTemplateInfo] = useState(TEMPLATE_INFO[0])
  const [templateLable, setTemplateLabel] = useState(TEMPLATE_LABEL[0])

  const [key, setKey] = useState(userKey())
  const logged = useSelector((state) => state.login)
  const userPermissions = logged?.user?.profile?.permission
  const [saving, setSaving] = useState(false)

  const accountConfigurations = useSelector(
    (state) => state.accountConfigurations,
  )

  useEffect(() => {
    if (accountConfigurations?.isSucceed && saving) {
      setOpenSnackbar(true)
      setDisplayCrash(config.message)
      setSeverity("success")
      setSaving(false)
      dispatch(getAccountConfig(key))
      dispatch(resetConfigurationsFlag())
    }
  }, [accountConfigurations])

  useEffect(() => {
    if (config?.data.length === 0) {
      // action initiated
      dispatch(getAccountConfig(key))
    }

    if (config.data.success) {
      const content = getTableVaue(0)
      setConfigAccount(config.data.account_configuration)
      setTemplateMail(config.data.account_configuration[content[0]])
      setTemplateSubject(config.data.account_configuration[content[1]])
    }
  }, [config])
  const getTableVaue = (value) => {
    switch (value) {
      case 0:
        return [
          "interview_completed_message",
          "interview_completed_message_subject",
        ]
      case 1:
        return ["invitation_to_interview", "invitation_to_interview_subject"]
      case 2:
        return ["invitation_to_onsite", "invitation_to_onsite_subject"]
      case 3:
        return [
          "interview_received_confirmation",
          "interview_received_confirmation_subject",
        ]
      case 4:
        return ["rejection_of_candidate", "rejection_of_candidate_subject"]
      case 5:
        return [
          "acceptance_of_candidate_message",
          "acceptance_of_candidate_subject",
        ]
      default:
    }
  }

  const handleChange = (event, newValue) => {
    const content = getTableVaue(newValue)
    setValue(newValue)

    setTemplateMail(configAccount[content[0]])
    setTemplateSubject(configAccount[content[1]] ?? "")
    setTemplateInfo(TEMPLATE_INFO[newValue])
    setTemplateLabel(TEMPLATE_LABEL[newValue])
  }

  const handleSubjectChange = (event) => {
    const content = getTableVaue(value)
    setConfigAccount({ ...configAccount, [content[1]]: event.target.value })
    setTemplateSubject(event.target.value ?? "")
  }

  const handleMessageChange = (val, editor) => {
    const content = getTableVaue(value)
    setConfigAccount({ ...configAccount, [content[0]]: val })
  }

  const saveConfigurationMessages = () => {
    const payload = {
      interview_completed_message: configAccount.interview_completed_message,
      interview_completed_message_subject:
        configAccount.interview_completed_message_subject,

      invitation_to_interview: configAccount.invitation_to_interview,
      invitation_to_interview_subject:
        configAccount.invitation_to_interview_subject,

      invitation_to_onsite: configAccount.invitation_to_onsite,
      invitation_to_onsite_subject: configAccount.invitation_to_onsite_subject,

      interview_received_confirmation:
        configAccount.interview_received_confirmation,
      interview_received_confirmation_subject:
        configAccount.interview_received_confirmation_subject,

      rejection_of_candidate: configAccount.rejection_of_candidate,
      rejection_of_candidate_subject:
        configAccount.rejection_of_candidate_subject,

      acceptance_of_candidate_message:
        configAccount.acceptance_of_candidate_message,
      acceptance_of_candidate_subject:
        configAccount.acceptance_of_candidate_subject,
    }

    setSaving(true)
    dispatch(updateAccountConfig(key, payload))
  }
  return (
    <>
      <Box
        className='verticleTabs'
        sx={{
          bgcolor: "background.paper",
          minHeight: 500,
          width: "100%",
        }}
      >
        <Tabs
          scrollButtons='auto'
          variant='scrollable'
          allowScrollButtonsMobile
          value={value}
          onChange={handleChange}
        >
          <Tab
            label={t("IntCompletion")}
            sx={{
              width: { xs: "150px", md: "auto" },
              px: { xs: 0, sm: "10px", md: "15px" },
            }}
            {...a11yProps(0)}
          />
          <Tab
            label={t("RecordedIntInvitation")}
            sx={{
              width: {
                xs: "150px",
                md: "auto",
                px: { xs: 0, sm: "10px", md: "15px" },
              },
            }}
            {...a11yProps(1)}
          />
          <Tab
            label={t("InvitationtoOn-SiteInt")}
            sx={{
              width: { xs: "150px", md: "auto" },
              px: { xs: 0, sm: "10px", md: "15px" },
            }}
            {...a11yProps(2)}
          />
          <Tab
            label={t("RecordedIntReceivedConfirmation")}
            sx={{
              width: { xs: "150px", md: "auto" },
              px: { xs: 0, sm: "10px", md: "15px" },
            }}
            {...a11yProps(3)}
          />
          <Tab
            label={t("RejectionNotification")}
            sx={{
              width: { xs: "150px", md: "auto" },
              px: { xs: 0, sm: "10px", md: "15px" },
            }}
            {...a11yProps(4)}
          />
          <Tab
            label={t("JobOfferletter")}
            sx={{
              width: { xs: "150px", md: "auto" },
              px: { xs: 0, sm: "10px", md: "15px" },
            }}
            {...a11yProps(5)}
          />
        </Tabs>
        <TabPanel className='tabPanel'>
          <Box id='intMsg'>
            <Alert severity='info' sx={{ mb: 1 }}>
              {t(templateInfo)}.
            </Alert>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              className='row-dir'
              sx={{ xs: " my: 0", sm: " my: 2" }}
            >
              <Typography
                variant='h6'
                component='h6'
                mt={2}
                mb={3}
                sx={{ fontWeight: 600 }}
              >
                {t(templateLable)}
              </Typography>
              <FormControl
                sx={{ ml: 1, marginBottom: { xs: "8px", sm: "8px" } }}
              >
                <TextField
                  fullWidth
                  id='invitation_to_interview_subject'
                  name='invitation_to_interview_subject'
                  onChange={(e) => handleSubjectChange(e)}
                  type='text'
                  value={templateSubject}
                  placeholder='Enter subject'
                  disabled={
                    !userPermissions?.settings_user_can_edit_email_templates
                  }
                />
              </FormControl>
            </Stack>
            <Grid container spacing={3}>
              <Grid item md={9} xs={12}>
                <div id='ICM'>
                  <Editor
                    apiKey={Keys.TINY_EDITOR_API_KEY}
                    id='intCompleteMsgEditor'
                    initialValue={templateMail}
                    isRtl={false}
                    language={selectedItem}
                    translate={true}
                    init={{
                      height: 300,
                      min_width: 400,
                      skin: "oxide",
                      autosave_interval: "30s",
                      autosave_ask_before_unload: true,
                      plugins: [
                        "advlist",
                        "anchor",
                        "autolink",
                        "help",
                        "image",
                        "link",
                        "lists",
                        "autosave",
                        " save",
                        "searchreplace",
                        "table",
                        "wordcount",
                        "code",
                      ],
                      toolbar:
                        "undo redo | bold italic | alignleft aligncenter alignright | code",
                      branding: false,
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                    onEditorChange={handleMessageChange}
                    disabled={
                      !userPermissions?.settings_user_can_edit_email_templates
                    }
                  />
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <Alert severity='info' sx={{ mb: 1 }}>
                  {t("varForEmails")}.
                  <Stack direction='column' sx={{ display: "flex", mt: 1 }}>
                    <Typography
                      variant='subtitle2'
                      component='p'
                      sx={{ fontWeight: 400, mb: 1 }}
                    >
                      Candidate Name: <strong>first_name</strong>
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      component='p'
                      sx={{ fontWeight: 400, mb: 1 }}
                    >
                      Candidate Unique id for interview:{" "}
                      <strong>candidate_id</strong>
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      component='p'
                      sx={{ fontWeight: 400, mb: 1 }}
                    >
                      Role Title: <strong>role_name</strong>
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      component='p'
                      sx={{ fontWeight: 400, mb: 1 }}
                    >
                      Interviewer name: <strong>interviewer_first_name</strong>
                    </Typography>
                    <Typography
                      variant='subtitle2'
                      component='p'
                      sx={{ fontWeight: 400, mb: 1 }}
                    >
                      Company Email ID: <strong>default_contact_email</strong>
                    </Typography>

                    <Typography
                      variant='subtitle2'
                      component='p'
                      sx={{ fontWeight: 400, mb: 1 }}
                    >
                      Company Contact Number:{" "}
                      <strong>default_contact_number </strong>
                    </Typography>
                  </Stack>
                </Alert>
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </Box>

      <LoadingButton
        type='submit'
        onClick={saveConfigurationMessages}
        variant='contained'
        color='primary'
        loading={saving}
        disabled={!userPermissions?.settings_user_can_edit_email_templates}
      >
        {t("Save")}
      </LoadingButton>
    </>
  )
}
