import { auth_request } from "../middlewares/auth_service";
import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/login";
import * as ERRORS from "../constants/errorManagement";

const login = (user, successCB = () => {}) => {
  const endpoint = "/api/auth/sign_in";
  const method = "POST";
  const headers = "login";

  const options = { action: endpoint, method: method, headers: headers };
  options.body = user;
  options.callback = successCB;
  options.types = [
    ActionTypes.LOGIN_ON_SUCCESS,
    ActionTypes.LOGIN_ON_FAILURE,
    ERRORS.API_FAILURE,
    ActionTypes.RESET_lOGIN_ERROR
  ];
  return auth_request(options);
};

export default login;

export const resetLoginMessage = () => {
  return { type: ActionTypes.RESET_lOGIN_ERROR };
};

export const updateLanguage = (data) => {
  const endpoint = `/api/v1/language/update_language`
  const method = "PUT"
  const headers = JSON.parse(localStorage.getItem("accessHeader"))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.types = [
    ActionTypes.LANGUAGE_ON_SUCCESS,
    ActionTypes.LANGUAGE_ON_FAILURE
  ]
  return request(options)
}

export const renewSession = (data) => {
  const endpoint = `/api/admin/members/renew`
  const method = 'POST'
  const headers = JSON.parse(localStorage.getItem('accessHeader'))

  const options = { action: endpoint, method: method, headers: headers }

  options.body = data
  options.request_type = 'renew_session'
  options.types = [
    ActionTypes.RENEWSESSION_ON_SUCCESS,
    ActionTypes.RENEWSESSION_ON_FAILURE
  ]
  return request(options)
}
