import React, { useState } from "react"
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { useTranslation } from "react-i18next"

export default function ConfirmationModal({
  isShowingModal,
  hide,
  confirmAction,
  isStatus = false,
  isSMPT = false,
  message = "",
}) {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    isShowingModal && (
      <Dialog
        className='dialog400'
        open={isShowingModal}
        onClose={hide}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='md'
      >
        <DialogTitle
          className='dialog-title'
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#ff4960",
          }}
        >
          {isStatus === true ? "Please Confirm" : t("Confirmation")}
        </DialogTitle>

        <DialogContent className='dialog-content' dividers={true}>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ textAlign: "left" }}>
              <Typography
                variant='p'
                component='p'
                className='dialog-txt'
                sx={{ paddingLeft: "5px", paddingRight: "5px" }}
              >
                {message}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className='dialog-footer' sx={{ textAlign: "center" }}>
          {!isSMPT && (
            <Button
              onClick={hide}
              variant='outlined'
              color='primary'
              size='medium'
            >
              {t("No")}
            </Button>
          )}
          <Button
            onClick={() => {
              confirmAction()
              hide()
            }}
            autoFocus
            variant='contained'
            color='secondary'
            size='medium'
          >
            {t("Yes")}
          </Button>
        </DialogActions>
      </Dialog>
    )
  )
}
