import React, { useState, useRef, useEffect } from "react"
import { LiveHeader } from "../.."
import { styled } from "@mui/material/styles"
import { Grid, Typography, Box } from "@mui/material"
import ParagraphWithReadMore from "src/components/ParagraphWithReadMore"

const PanelInner = styled("div")(({ theme }) => ({}))

const Jumbotron = styled("div")(({ theme }) => ({}))

const IntructionListing = styled("div")(({ theme }) => ({}))
export default function About({ interview }) {
  const {
    user: { email: userEmail },
    candidate_vacancy: {
      vacancy: {
        department: {
          account: {
            logo: { url: logoUrl },
            introduction: companyIntroduction,
            default_video: { url: videoUrl },
            company_name: copanyName,
            default_contact_email: companyEmail,
            default_contact_num: copmanyPhone,
          },
        },
      },
    },
    interview_format: { format },
  } = interview

  const maxLength = 150
  return (
    <PanelInner>
      <Grid container spacing={2}>
        <Grid item sx={{ pt: 6, pb: 5, mt: 2 }} md={12}>
          <Jumbotron>
            <LiveHeader logoUrl={logoUrl} />
            {companyIntroduction ? (
              <>
                <Typography
                  variant='h4'
                  component='h4'
                  sx={{
                    marginBottom: "1rem",
                    color: "#1E293B",
                    fontWeight: 600,
                    mb: 1,
                    mt: 1,
                  }}
                >
                  Company Overview
                </Typography>

                <Box className='video-paragraph'>
                  {videoUrl && (
                    <video
                      className='videoPlayer'
                      width='100%'
                      sx={{ maxWidth: "400px" }}
                      height='240'
                      autoPlay={false}
                      src={videoUrl}
                      playsInline
                      controls
                      muted={true}
                      loop
                      // poster='static/illustrations/illustration_register.png'
                    />
                  )}

                  <Typography
                    variant='subtitle2'
                    component='p'
                    sx={{
                      mb: 1,
                      color: "#475569",
                      lineHeight: "24px",
                      fontSize: "16px !important",
                      fontWeight: 400,
                      wordBreak: "break-word",
                    }}
                  >
                    <ParagraphWithReadMore
                      text={companyIntroduction}
                      maxLength={maxLength}
                    />
                  </Typography>
                  <Box sx={{ clear: "both" }}></Box>
                </Box>
              </>
            ) : (
              <Typography
                variant='subtitle2'
                component='p'
                sx={{
                  pt: 2,
                  mb: 1,
                  color: "#475569",
                  lineHeight: "24px",
                  fontSize: "16px !important",
                  fontWeight: 400,
                }}
              >
                No overview available.
                <br /> If you have any queries, please contact us at{" "}
                <a href={`mailto:${userEmail}`}>{userEmail}</a>
                {/* If you have any queries, please contact us <b>{copanyName}</b>{" "}
                at{" "}
                {companyEmail ? (
                  <>
                    email: <a href={`mailto:${companyEmail}`}>{companyEmail}</a>
                  </>
                ) : null}{" "}
                {![null, undefined, "", "null", "+"].includes(copmanyPhone) &&
                  copmanyPhone.length < 5 &&
                  ` or phone number  ${copmanyPhone}`}
                . */}
              </Typography>
            )}
          </Jumbotron>
        </Grid>
      </Grid>
    </PanelInner>
  )
}
