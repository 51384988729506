import React, { useState, useRef, useEffect } from "react"
import {
  FileUploadContainer,
  FormField,
  UploadFileBtn,
  FilePreviewContainer,
} from "../file-upload/file-upload.styles"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import FileUploadIcon from "@mui/icons-material/FileUpload"
import { useTranslation } from "react-i18next"

//---------------------------------------------

const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 5000000
const ALLOWED_VIDEOS_FORMAT = [
  "video/webm",
  "video/x-flv",
  "video/x-matroska",
  "video/mp4",
  "video/quicktime",
  "video/x-ms-wmv",
  "video/mpeg",
  "video/x-msvideo",
]

const VideoUploadComapnyPage = ({
  label,
  height,
  value,
  url,
  updateFilesCb,
  onChange,
  onError,
  maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
  canEdit = true,
  ...otherProps
}) => {
  const inputRef = useRef()
  const { t } = useTranslation()
  const [fileName, setfileName] = useState(url ? t("UploadedFile") : null)
  const [source, setSource] = useState(null)

  const addNewFiles = (file) => {
    return { file }
  }

  useEffect(() => {
    if ((typeof url === "string" || url instanceof String) && !source) {
      setSource(url)
      setfileName(value.name)
    } else if (url && url.file) {
      const file = url.file
      setfileName(file.name)
      const objectUrl = URL.createObjectURL(file)
      setSource(null)
      setTimeout(() => {
        setSource(objectUrl)
      }, 200)
    }
  }, [url])

  const removeFile = (fileName) => {
    delete value.file
    setSource(null)
    setfileName(null)
    onChange({ ...value })
  }

  const isValidFileSize = (file) => {
    if (file?.size) {
      return file.size <= maxFileSizeInBytes
    }
    return false
  }

  const isValidFileFormat = (file) => {
    if (file?.type) {
      return ALLOWED_VIDEOS_FORMAT.includes(file.type)
    }
    return false
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]

    if (isValidFileSize(file)) {
      if (isValidFileFormat(file)) {
        const url = URL.createObjectURL(file)
        setfileName(file.name)
        setSource(url)
        let updatedFiles = addNewFiles(file)
        onChange(updatedFiles)
        onError && onError("")
      } else {
        if (onError) {
          onError(t("ErrFileBeMP4"))
          removeFile()
        }
      }
    } else {
      if (onError) {
        onError(t("ErrFileSizeGreater5MB"))
        removeFile()
      }
    }
  }

  const handleChoose = (event) => {
    inputRef.current.click()
  }

  const getFileName = (fileName) => {
    return fileName && fileName.length > 25
      ? `${fileName.slice(0, 20)}...${fileName.split(".").reverse()[0]}`
      : fileName ?? t("NothingSelected")
  }

  return (
    <>
      {canEdit && (
        <>
          {" "}
          <FileUploadContainer>
            <UploadFileBtn type='button' onClick={handleChoose}>
              <i>
                <FileUploadIcon />
              </i>
              <span>{t("Uploadfile")}</span>
            </UploadFileBtn>
            <FormField
              type='file'
              ref={inputRef}
              className='VideoInput_input'
              onChange={handleFileChange}
              accept='video/*'
            />
          </FileUploadContainer>
        </>
      )}

      <FilePreviewContainer>
        {source && (
          <>
            <span> </span>
            <video
              playsInline
              autoPlay={false}
              className='VideoInput_video'
              width='100%'
              height={height}
              controls
              muted={false}
              src={source}
              loop
            ></video>
          </>
        )}
        {canEdit && (
          <>
            {fileName && (
              <div className='VideoInput_footer center-content'>
                {getFileName(fileName)}
                <DeleteOutlineIcon
                  sx={{ mx: 1 }}
                  onClick={() => removeFile(fileName)}
                />
              </div>
            )}
          </>
        )}
      </FilePreviewContainer>
    </>
  )
}
export default VideoUploadComapnyPage
