import React, { useRef, useState, useEffect, useCallback } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Avatar,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Table,
  Stack,
} from "@mui/material"
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt"
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt"
import Label from "src/components/Label"

import CloseIcon from "@mui/icons-material/Close"
import { useSelector } from "react-redux"
import { useTheme } from "@mui/material/styles"
import { viewCandidateHistory } from "src/redux/actions/interviews"
import CandidateHistoryHead from "../../candidateHistoryListHead"
import Scrollbar from "src/components/Scrollbar"
import SearchNotFound from "src/components/SearchNotFound"
import {
  candidateHistoryDataParsing,
  candidateHistroy,
} from "src/utils/dataParsing"
import { CompleteRoleModal } from "src/sections/@dashboard/roles"
import Loader from "src/components/Loader"
import { useTranslation } from "react-i18next"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import { convertTimeZone } from "src/utils/formatTime"
import { DBTimeZone } from "src/utils/constants"

export default function CandidateHistory({
  isShowingCandidateHis,
  hide,
  invoke,
}) {
  const { t } = useTranslation()
  const candidateData = useSelector((state) => state?.viewCandidateHistory)
  const candidateHistory = candidateData?.data?.candidates
  const [parsedCandidate, setParsedCandidate] = useState([])
  const [page, setPage] = useState(0)
  const [order, setOrder] = useState("asc")
  const [selected, setSelected] = useState([])
  const [orderBy, setOrderBy] = useState("name")
  const [filterName, setFilterName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [data, setData] = useState()

  const TABLE_HEAD = (t) => [
    {
      id: "interviewFormat",
      label: t("InterviewFormat"),
      alignRight: false,
    },
    { id: "interviewType", label: t("InterviewType"), alignRight: false },
    { id: "scheduleDate", label: t("ScheduledDate"), alignRight: false },
    { id: "completedOn", label: t("CompletedOn"), alignRight: false },
    { id: "votes", label: t("Votes"), alignRight: false },
  ]
  const loadingState = useSelector((state) => state.loadingManagement)

  const dummyHistory = [
    {
      interviews: [
        // {
        //   scheduled_on: "12/34/2002",
        //   completed_on: "12/23/2022",
        //   votes_count: [{ denied_votes: 1 }],
        // },
        // {
        //   scheduled_on: "12/34/2002",
        //   completed_on: "12/23/2022",
        //   votes_count: [{ denied_votes: 1 }],
        // },
      ],
    },
  ]

  useEffect(() => {
    if (candidateHistory?.length === 0) {
    }
    setData(
      candidateHistroy(
        candidateHistory?.candidate_vacancies !== undefined
          ? candidateHistory?.candidate_vacancies
          : dummyHistory,
      ),
    )
  }, [candidateData])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data?.map((n) => n.name)
      setSelected(newSelecteds)
      return
    }
    setSelected([])
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data?.length) : 0

  const isUserNotFound = data?.length === 0

  return (
    isShowingCandidateHis && (
      <Dialog
        className='dialog800'
        open={isShowingCandidateHis}
        onClose={hide}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        scroll='paper'
        maxWidth='md'
      >
        <Loader respLoading={loadingState?.loading} />

        <DialogTitle
          id='alert-dialog-title'
          className='dialog-title'
          sx={{ justifyContent: "space-between", display: "flex" }}
        >
          {t("CandidateHistory")}
          <IconButton
            aria-label='close'
            onClick={hide}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className='dialog-content' dividers={true}>
          <Grid item xs={12}>
            {candidateHistory && (
              <Box
                sx={{
                  background: "#F8F8F8",
                  padding: "11px",
                  borderRadius: "18px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Avatar
                  sx={{
                    width: 80,
                    height: 80,
                    bgcolor: "#1890FF",
                    mx: 3,
                    fontSize: "1.7rem",
                    textTransform: "uppercase",
                  }}
                >
                  {candidateHistory?.first_name?.slice(0, 1).toUpperCase() +
                    candidateHistory?.last_name?.slice(0, 1).toUpperCase()}
                </Avatar>
                <Box sx={{ flexDirection: "column", display: "flex" }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Stack direction='row'>
                        <Typography
                          variant='subtitle1'
                          sx={{
                            color: "text.primary",
                            textTransform: "capitalize",
                            mt: 1,
                          }}
                        >
                          {`${candidateHistory?.first_name} ${candidateHistory?.last_name}`}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack direction={{ xs: "column", sm: "row" }}>
                        <Typography
                          variant='subtitle2'
                          sx={{ color: "text.secondary", mr: 1 }}
                        >
                          {t("Email")}&nbsp;:
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          sx={{
                            color: "text.primary",
                            fontWeight: 500,
                            mr: 1,
                            wordBreak: "break-all",
                          }}
                        >
                          {candidateHistory?.email}
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Stack direction={{ xs: "column", sm: "row" }}>
                        <Typography
                          variant='subtitle2'
                          sx={{ color: "text.secondary", mr: 1 }}
                        >
                          {t("Vacancy")}&nbsp;:
                        </Typography>
                        <Typography
                          variant='subtitle2'
                          sx={{
                            color: "text.primary",
                            fontWeight: 500,
                            textTransform: "capitalize",
                          }}
                        >
                          {candidateHistory?.candidate_vacancies?.map(
                            (item) => (
                              <Label
                                key={item.id + item.candidate_id}
                                variant='ghost'
                                color='info'
                                sx={{ mr: 1, mb: 1 }}
                              >
                                {item?.vacancy?.title}
                              </Label>
                            ),
                          )}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            )}
          </Grid>

          <Scrollbar sx={{ marginTop: 2 }}>
            <TableContainer>
              <Table className='simple-table'>
                <CandidateHistoryHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD(t)}
                  rowCount={data?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {data?.map((item) =>
                    item?.interviews
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      ?.map((row, index) => {
                        const {
                          id,
                          interviewFormat,
                          interviewType,
                          recomend_votes,
                          interview_type,
                          completedOn,
                          scheduleDate,
                        } = row

                        const isItemSelected =
                          selected?.indexOf(scheduleDate) !== -1
                        return (
                          <TableRow
                            hover
                            key={item?.vacancy?.code + scheduleDate}
                            tabIndex={-1}
                            role='checkbox'
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell>
                              {interviewFormat === "recorded_by_applicant"
                                ? t("RecordedbyApplicant")
                                : t("OnSite")}
                            </TableCell>
                            <TableCell>
                              {interviewType === "first_contact"
                                ? t("firstinterview")
                                : t("followup")}
                            </TableCell>
                            <TableCell
                              component='th'
                              scope='row'
                              padding='none'
                              width='180'
                              align='center'
                            >
                              {convertTimeZone(scheduleDate, row.timeZone)}
                            </TableCell>
                            <TableCell align='center' width='180'>
                              {completedOn === null ? (
                                <Label variant='ghost'>{t("Pending")}</Label>
                              ) : (
                                convertTimeZone(completedOn, DBTimeZone, row.timeZone)
                              )}
                            </TableCell>
                            <TableCell align='right' sx={{ width: "160px" }}>
                              <Stack direction='row' alignContent='center'>
                                {row?.votes?.recomend_votes === 0 &&
                                row?.votes?.denied_votes === 0 ? (
                                  <Label variant='ghost' color='info'>
                                    {t("FeedbackRequired")}
                                  </Label>
                                ) : (
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mx: 1,
                                      }}
                                    >
                                      <Label variant='ghost' color='success'>
                                        <ThumbUpOffAltIcon
                                          sx={{
                                            color: "#64748b",
                                            fontSize: "16px",
                                            mx: 1,
                                          }}
                                        />
                                        {row?.votes?.recomend_votes}
                                      </Label>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        mx: 1,
                                      }}
                                    >
                                      <Label variant='ghost' color='error'>
                                        {row?.votes?.denied_votes}
                                        <ThumbDownOffAltIcon
                                          sx={{
                                            color: "#64748b",
                                            fontSize: "16px",
                                            mx: 1,
                                          }}
                                        />
                                      </Label>
                                    </Box>
                                  </>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        )
                      }),
                  )}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell className='searchCell' colSpan={4}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component='div'
            labelRowsPerPage={t("RowsPerPage")}
            count={data?.map((item) => item?.interviews)?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </DialogContent>
      </Dialog>
    )
  )
}
