import PropTypes from "prop-types"
import { alpha, styled } from "@mui/material/styles"
import { Box, Stack, AppBar, Toolbar, IconButton } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import AccountPopover from "./AccountPopover"
import LanguagePopover from "./LanguagePopover"

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280
const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 64

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "0 0 13px rgb(0 0 0 / 20%)",
  backdropFilter: "blur(2px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
  setSelectedItem: PropTypes.func,
  selectedItem: PropTypes.string,
}

export default function DashboardNavbar({
  onOpenSidebar,
  setSelectedItem,
  selectedItem,
}) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        {/* <Searchbar /> */}
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction='row'
          alignItems='center'
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <LanguagePopover
            setSelectedItem={setSelectedItem}
            selectedItem={selectedItem}
          />
          {/* <NotificationsPopover /> */}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  )
}
