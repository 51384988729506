import { styled } from "@mui/material/styles"
import React from "react"
import {
  Stack,
  Typography,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material"
import { useTranslation } from "react-i18next"
// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
}
// ----------------------------------------------------------------------

export default function ReviewForm({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  getFieldProps,
  intKey,
  isVacancyOpen,
  userInfo,
  userFeedback,
  customColumns,
  ...props
}) {
  const rating = [
    { id: 1, name: "Excellent" },
    { id: 2, name: "Good" },
    { id: 3, name: "Fair" },
    { id: 4, name: "Poor" },
  ]

  const { t } = useTranslation()

  return (
    <>
      <Typography sx={{ mt: 1 }} component='h6' variant='h6'>
        Additional Feedback
      </Typography>
      <Grid container spacing={2}>
        {customColumns().map((feedbackItem) => {
          return (
            <Grid
              item
              xs={12}
              sx={{
                pt: 1,
              }}
            >
              <Stack
                direction={{ xs: "column", sm: "row" }}
                justifyContent='space-between'
              >
                <Typography
                  variant='body2'
                  component='span'
                  sx={{
                    fontWeight: 500,
                    fontSize: "1.0rem",
                    my: 1,
                    display: "inline-flex",
                    alignItems: "center",
                  }}
                >
                  {feedbackItem}
                </Typography>

                <FormControl sx={{ mb: 1 }}>
                  <Select
                    displayEmpty
                    id={`${feedbackItem} select`}
                    name={`${feedbackItem}`}
                    MenuProps={MenuProps}
                    value={
                      values?.feedback_details
                        ? values?.feedback_details[feedbackItem]
                        : ""
                    }
                    disabled={!isVacancyOpen}
                    size='small'
                    sx={{
                      width: { xs: "100%", sm: "180px" },
                    }}
                    onChange={(e) => {
                      let val = {
                        ...values?.feedback_details,
                        [feedbackItem]: e.target.value,
                      }

                      setFieldValue("feedback_details", val)
                      setFieldValue(feedbackItem, e.target.value)
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontWeight: 600,
                        color: "#5082dd",
                        opacity: "1 !important",
                      }}
                      value=''
                      disabled
                    >
                      <em>Select </em>
                    </MenuItem>

                    {rating?.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.name.toLowerCase()}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {errors[feedbackItem] && (
                    <FormHelperText
                      className='error'
                      sx={{
                        fontWeight: 400,
                        fontSize: "1rem",
                        ml: 0,
                        mb: 1,
                      }}
                    >
                      {errors[feedbackItem]}
                    </FormHelperText>
                  )}
                </FormControl>
              </Stack>

              <FormControl sx={{ width: "100%" }}>
                <TextField
                  fullWidth
                  multiline
                  disabled={!isVacancyOpen}
                  rows={2}
                  type='text'
                  variant='outlined'
                  autoComplete=''
                  placeholder={t("AddComment")}
                  name={`${feedbackItem} Comment`}
                  value={
                    values?.feedback_details
                      ? values?.feedback_details[`${feedbackItem} Comment`]
                      : ""
                  }
                  onChange={(e) => {
                    let val = {
                      ...values?.feedback_details,
                      [`${feedbackItem} Comment`]: e.target.value,
                    }

                    setFieldValue("feedback_details", val)
                  }}
                />
              </FormControl>
            </Grid>
          )
        })}
      </Grid>
    </>
  )
}
