import { request } from "../middlewares/service";
import * as ActionTypes from "../constants/addMember";

export const addMemberType = (data) => {
  const endpoint = `/api/admin/profiles`;
  const method = "POST";
  const headers = JSON.parse(localStorage.getItem("accessHeader"));

  const options = { action: endpoint, method: method, headers: headers };

  options.body = data;
  options.types = [
    ActionTypes.ADD_MEMBER_ON_SUCCESS,
    ActionTypes.ADD_MEMBER_ON_FAILURE,
  ];
  return request(options);
};
