const modulesArr = [
  {
    id: "dashboard_module",
    name: "Dashboard",
    list: [
      {
        listname: "View Dashboard module",
        listid: "dashboard_module",
      },
      {
        listname: "View Active Vacancies Counter",
        listid: "dashboard_active_vacancies_counter",
      },
      {
        listname: "View Pending Interviews Counter",
        listid: "dashboard_interview_pending_counter",
      },
      {
        listname: "View interviews that require feedback",
        listid: "dashboard_interview_awaiting_feedbacks_counter",
      },
      {
        listname: "View Completed Interviews Counter",
        listid: "dashboard_completed_interviews_counter",
      },
      {
        listname: "View Active Users Counter",
        listid: "dashboard_active_users_counter",
      },
      {
        listname: "View Question Set Counter",
        listid: "dashboard_question_sets_counter",
      },
      {
        listname: "View Completed Interviews Graph",
        listid: "dashboard_completed_interviews_graph",
      },
      {
        listname: "View Top Skill's Section",
        listid: "dashboard_popular_skills",
      },
      {
        listname: "View Candidate's Rating Chart",
        listid: "dashboard_candidate_ratings",
      },
      {
        listname: "View Interviews Status Chart",
        listid: "dashboard_interviews_by_status",
      },
      {
        listname: "View Completed Interviews List",
        listid: "dashboard_completed_interviews_list",
      },
    ],
  },
  {
    id: "question_sets_module",
    name: "Questions",
    list: [
      {
        listname: "View Questions module",
        listid: "question_sets_module",
      },
      {
        listname: "Search Questions",
        listid: "question_sets_search_bar",
      },
      {
        listname: "Add Question set",
        listid: "question_sets_add",
      },

      {
        listname: "Edit Question set",
        listid: "question_sets_update",
      },
      {
        listname: "Delete Question set",
        listid: "question_sets_delete",
      },
      {
        listname: "Departments Dropdown Filter",
        listid: "question_sets_departments_dropdown_filter",
      },
    ],
  },

  {
    id: "vacancy_module",
    name: "Vacancies",
    list: [
      {
        listname: "View Vacancy module",
        listid: "vacancy_module",
      },
      {
        listname: "Search Vacancies",
        listid: "vacancy_search_bar",
      },
      {
        listname: "Departments Dropdown Filter",
        listid: "vacancy_departments_dropdown_filter",
      },
      {
        listname: "Add Vacancy",
        listid: "vacancy_add_new_vacancy",
      },
      {
        listname: "Edit Open Vacancy",
        listid: "vacancy_active_vacancy_edit_vacancy",
      },
      {
        listname: "View completed interviews",
        listid: "vacancy_active_vacancy_view_interviews",
      },
      {
        listname: "View vacancy salary",
        listid: "user_can_view_vacancy_salary",
      },

      {
        listname: "View candidates in Open vacancy",
        listid: "vacancy_active_vacancy_view_candidates",
      },
      {
        listname: "Close an opened vacancy",
        listid: "vacancy_active_vacancy_complete_vacancy",
      },
      {
        listname: "View closed vacancy tab",
        listid: "vacancy_completed_vacancy_tab",
      },
      {
        listname: "View candidates in completed vacancies",
        listid: "vacancy_completed_vacancy_view_candidates",
      },
      {
        listname: "Archive the Vacancies",
        listid: "vacancy_completed_vacancy_archive_vacancy",
      },
      {
        listname: "View archived vacancy tab",
        listid: "vacancy_archived_vacancy_tab",
      },
      {
        listname: "Delete Archived Vacancy",
        listid: "vacancy_archive_vacancy_delete_vacancy",
      },
    ],
  },
  {
    id: "interviews_module",
    name: "Interviews",
    list: [
      {
        listname: "View interview module",
        listid: "interviews_module",
      },
      {
        listname: "Search interviews",
        listid: "interviews_search_bar",
      },
      {
        listname: "Departments Dropdown Filter",
        listid: "interviews_departments_dropdown_filter",
      },
      {
        listname: "Schedule interviews",
        listid: "interviews_schedule_interview_action",
      },
      {
        listname: "Assign interviewer",
        listid: "interviews_schedule_interview_create_interviewer_action",
      },
      {
        listname: "Schedule follow-up interviews",
        listid: "interviews_schedule_follow_up_action",
      },

      {
        listname: "Edit scheduled interviews",
        listid: "interviews_schedule_interviews_edit_interview",
      },
      {
        listname: "Delete scheduled interview",
        listid: "interviews_schedule_interviews_delete_interview",
      },
      {
        listname: "Resend interview invite to candidate",
        listid: "interviews_schedule_interviews_resend_invitation",
      },

      {
        listname: "View completed interviews",
        listid: "interviews_completed_interviews_tab",
      },
      {
        listname: "Give feedback on Interview",
        listid: "interviews_completed_interviews_feedback_required",
      },
      {
        listname: "Archive completed interviews",
        listid: "interviews_completed_interviews_archive_interview",
      },
      {
        listname: "View candidate history",
        listid: "interviews_completed_interviews_candidate_history",
      },
      {
        listname: "Archived Interviews Tab",
        listid: "interviews_archived_interviews_tab",
      },

      {
        listname: "Delete archived interviews",
        listid: "interviews_archived_interviews_delete_interview",
      },

      {
        listname: "Expired Interviews Tab",
        listid: "interviews_expired_interviews_tab",
      },
      {
        listname: "Delete Expired Interviews",
        listid: "interviews_expired_interviews_delete_interview",
      },
    ],
  },
  {
    id: "candidates_module",
    name: "Candidates",
    list: [
      {
        listname: "View candidate module",
        listid: "candidates_module",
      },
      {
        listname: "Search candidates",
        listid: "candidates_user_can_search_for_candidates",
      },
      {
        listname: "View feedback",
        listid: "candidates_user_can_view_feedback",
      },
      // {
      //   listname:
      //     'User can submit feedback for candidates that have completed their interviews',
      //   listid: 'candidates_provide_feedback_action'
      // },

      {
        listname: "Give recommendations",
        listid: "candidates_user_can_give_recommendations",
      },
      {
        listname: "View recommendations",
        listid: "candidates_user_can_view_recommendations",
      },
      {
        listname: "Edit comments",
        listid: "candidates_user_can_edit_comments",
      },
      {
        listname: "Delete comments",
        listid: "candidates_user_can_delete_comments",
      },
      {
        listname: "Add comments in interview",
        listid: "candidates_user_can_add_comments_to_interview",
      },
    ],
  },
  {
    id: "company_user_module",
    name: "Company users",
    list: [
      {
        listname: "View company users",
        listid: "company_user_module",
      },

      {
        listname: "Add company users",
        listid: "company_user_user_can_add_company_users",
      },
      {
        listname: "Edit company users",
        listid: "company_user_user_can_edit_company_users",
      },
      {
        listname: "Delete company users",
        listid: "company_user_user_can_delete_company_users",
      },
      {
        listname: "Search company users",
        listid: "company_user_user_can_search_company_users",
      },
    ],
  },
  {
    id: "departments_module",
    name: "Departments",
    list: [
      {
        listname: "View Department Module",
        listid: "departments_module",
      },
      {
        listname: "Add departments",
        listid: "departments_user_can_add_department",
      },
      {
        listname: "Delete departments",
        listid: "departments_user_can_delete_department",
      },
      {
        listname: "Edit departments",
        listid: "departments_user_can_edit_department",
      },
      {
        listname: "Search department",
        listid: "departments_user_can_search_department",
      },
    ],
  },
  {
    id: "settings_module",
    name: "Settings",
    list: [
      {
        listname: "View settings module",
        listid: "settings_module",
      },
      {
        listname: "Edit company profile",
        listid: "settings_user_can_edit_the_company_profile",
      },
      {
        listname: "View email templates",
        listid: "settings_user_can_view_email",
      },
      {
        listname: "Edit email templates",
        listid: "settings_user_can_edit_email_templates",
      },
      {
        listname: "View permissions",
        listid: "settings_user_can_view_permissions",
      },
      {
        listname: "Edit permissions",
        listid: "settings_user_can_edit_permissions",
      },
      {
        listname: "Add member type",
        listid: "settings_user_can_add_member_type",
      },

      {
        listname: "View subscription",
        listid: "settings_user_can_view_subscribe",
      },
      {
        listname: "Subscribe Plan",
        listid: "settings_user_can_subscribe_to_other_plans",
      },
      {
        listname: "Add/Edit card",
        listid: "settings_user_can_add_or_edit_the_card",
      },
      {
        listname: "View charges",
        listid: "settings_user_can_view_charges",
      },
      {
        listname: "Download charges receipt",
        listid: "settings_user_can_download_charges_receipt",
      },

      {
        listname: "Contact super admin",
        listid: "settings_user_can_contact_the_super_admin",
      },
    ],
  },
]

const MemberPermissions = [...modulesArr].map((_, index) => {
  return {
    id: modulesArr[index].id,
    name: modulesArr[index].name,
    listM: modulesArr[index].list,
    itemName: modulesArr[index].list.map((item) => item.listname),
    itemid: modulesArr[index].list.map((item) => item.listid),
  }
})
export default MemberPermissions
