import React, { useRef, useState } from "react"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import {
  resendInvitation,
  viewCandidateHistory,
} from "src/redux/actions/interviews"
import DeleteInterviewModal from "./modal/deleteInterview/DeleteInterviewModal"
import ScheduleIntModal from "./modal/scheduleInterview/scheduleIntModal"
import FollowUpIntModal from "./modal/followUpInterview/FollowUpIntModal"
import CandidateHistory from "./modal/candidateHistory/candidateHistory"
import UpdateStatusModal from "./modal/UpdateStatusModal"
import { useTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import useModal from "src/sections/@dashboard/settings/subscription/Modals/useModal"
import { deleteSampleData } from "src/redux/actions/accountConfig"
import ViewInterviewModal from "./modal/viewInterview"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import EditIcon from "@mui/icons-material/Edit"
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined"
import UpdateIcon from "@mui/icons-material/Update"
import HistoryIcon from "@mui/icons-material/History"
import StartIcon from "@mui/icons-material/Start"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
// ----------------------------------------------------------------------

export default function InterviewMoreMenu({
  tabing,
  id,
  intVueFormat,
  intVuekey,
  intStatus,
  intVue,
  disabled = false,
  isSample = false,
}) {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const ref = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [isShowingDeleteInt, toggleDeleteInt] = useModal()
  const [isShowingScheduleInt, toggleScheduleInt] = useModal()
  const [isShowingFollowupInt, toggleFollowUpInt] = useModal()
  const [isShowingCandidateHis, toggleFeedbackInt] = useModal()
  const [isShowingStatus, toggleShowingStatus] = useModal()
  const [isShowingInt, toggleShowingInt] = useModal()
  const [matched, setMatched] = useState()
  const [type, settype] = useState()
  const [isArc, setIsArc] = useState(false)
  const navigate = useNavigate()

  const isVacancyOpen = intVue?.candidate_vacancy?.vacancy?.status === "active"

  const handleSampleDelete = () => {
    let creationInfo = JSON.parse(sessionStorage.getItem("creation_info"))
    creationInfo = { ...creationInfo, interviews: true }
    sessionStorage.setItem("creation_info", JSON.stringify(creationInfo))
    const formData = {
      sample_data: creationInfo,
    }
    dispatch(deleteSampleData(formData))
  }

  const handleDelete = () => {
    if (!isSample) {
      setMatched(intVue)
    }

    toggleDeleteInt()
    settype("interview")
    setIsOpen(false)
  }

  const handleEdit = () => {
    intVue.interview_type === "first_contact"
      ? toggleScheduleInt()
      : toggleFollowUpInt()
    setMatched(intVue)
    setIsOpen(false)
  }
  const handleOnsiteStart = () => {
    localStorage.setItem("match", JSON.stringify(intVue))
    window.open(
      `${process.env.REACT_APP_FE_BASE_URL}/candidate-interview/candidate/${intVue.code}/true`,
      "_blank",
    )
    setMatched(intVue)
    setIsOpen(false)
  }

  const handleFeedback = () => {
    localStorage.setItem("match", JSON.stringify(intVue))
    const intKey = intVue?.key
    window.history.pushState(null, "", "/dashboard/interviews")

    navigate(`/interview-feedback/app/${intKey}`, {
      replace: true,
      state: { isSample: isSample },
    })

    setMatched(intVue)
    setIsOpen(false)
  }
  const handleResendInvitation = () => {
    dispatch(resendInvitation(intVue.key))
    setIsOpen(false)
  }

  const handleArchived = () => {
    toggleDeleteInt()
    setMatched(intVue)
    settype("interview-archive")
    setIsArc(true)
    setIsOpen(false)
  }

  const handleRequireFeedback = () => {
    toggleFeedbackInt()
    setMatched(intVue)
    setIsOpen(false)

    const userId = intVue?.candidate_vacancy?.candidate?.key
    dispatch(viewCandidateHistory(userId))
  }

  const logged = useSelector((state) => state.login)

  const canViewFeedback = () => {
    return logged?.user?.profile?.permission?.candidates_user_can_view_feedback
  }

  const canGiveFeedback = () => {
    return (
      intVue?.recommendations?.filter(
        (recommendation) => recommendation.user_id === logged?.user?.id,
      ).length === 0 &&
      (intVue?.interviewers?.filter(
        (interviewer) => interviewer.user_id === logged?.user?.id,
      ).length !== 0 ||
        logged?.user?.user_type === "admin") &&
      logged?.user?.profile?.permission
        ?.interviews_completed_interviews_feedback_required &&
      isVacancyOpen
    )
  }

  const canStartInterview = () => {
    return (
      intVue?.interviewers?.filter(
        (interviewer) => interviewer.user_id === logged?.user?.id,
      ).length !== 0 || logged?.user?.user_type === "admin"
    )
  }

  const handleViewInterview = () => {
    toggleShowingInt()
    setMatched(intVue)
    setIsOpen(false)
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)} disabled={disabled}>
        <MoreVertIcon width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {tabing === "active" && (
          <>
            {logged?.user?.profile?.permission
              ?.interviews_schedule_interviews_resend_invitation &&
              intStatus !== "timed_out" && [
                <MenuItem
                  sx={{ color: "text.secondary" }}
                  onClick={handleResendInvitation}
                  disabled={isSample}
                >
                  <ListItemIcon>
                    <ForwardToInboxIcon width={24} height={24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("ResendInvitation")}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </MenuItem>,
              ]}
            {canStartInterview() &&
              intVueFormat === "on_site" &&
              intStatus !== "timed_out" && [
                <MenuItem
                  sx={{ color: "text.secondary" }}
                  onClick={handleOnsiteStart}
                >
                  <ListItemIcon>
                    <StartIcon width={24} height={24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Start interview")}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </MenuItem>,
              ]}

            {canStartInterview() &&
              intVueFormat === "on_site" &&
              intStatus === "timed_out" && [
                <MenuItem
                  sx={{ color: "text.secondary" }}
                  onClick={toggleShowingStatus}
                >
                  <ListItemIcon>
                    <UpdateIcon width={24} height={24} />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("Update Status")}
                    primaryTypographyProps={{ variant: "body2" }}
                  />
                </MenuItem>,
              ]}

            {logged?.user?.profile?.permission
              ?.interviews_schedule_interviews_edit_interview && [
              <MenuItem
                component={RouterLink}
                to='#'
                sx={{ color: "text.secondary" }}
                onClick={handleEdit}
                disabled={isSample}
              >
                <ListItemIcon>
                  <EditIcon fontSize='medium' width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("Edit")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ]}
            {logged?.user?.profile?.permission
              ?.interviews_schedule_interviews_delete_interview && [
              <MenuItem sx={{ color: "text.secondary" }} onClick={handleDelete}>
                <ListItemIcon>
                  <DeleteOutlineIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("Delete")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ]}
          </>
        )}

        {tabing === "completed" && (
          <>
            {logged?.user?.profile?.permission
              ?.interviews_completed_interviews_candidate_history && [
              <MenuItem
                component={RouterLink}
                to='#'
                sx={{ color: "text.secondary" }}
                onClick={handleRequireFeedback}
                disabled={isSample}
              >
                <ListItemIcon>
                  <HistoryIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("CandidateHistory")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ]}

            {canGiveFeedback() || canViewFeedback()
              ? [
                  <MenuItem
                    sx={{ color: "text.secondary" }}
                    onClick={handleFeedback}
                  >
                    <ListItemIcon>
                      {(canGiveFeedback() && (
                        <RateReviewOutlinedIcon width={24} height={24} />
                      )) ||
                        (canViewFeedback() && (
                          <FeedbackOutlinedIcon width={24} height={24} />
                        ))}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        (canGiveFeedback() && t("GiveFeedback")) ||
                        (canViewFeedback() && t("ViewFeedback"))
                      }
                      primaryTypographyProps={{ variant: "body2" }}
                    />
                  </MenuItem>,
                ]
              : ""}
            {logged?.user?.profile?.permission
              ?.interviews_completed_interviews_archive_interview && [
              <MenuItem
                component={RouterLink}
                to='#'
                sx={{ color: "text.secondary" }}
                onClick={handleArchived}
                disabled={isSample}
              >
                <ListItemIcon>
                  <Inventory2OutlinedIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("Archive")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ]}
          </>
        )}

        {tabing === "archived" &&
          logged?.user?.profile?.permission
            ?.interviews_archived_interviews_delete_interview && [
            <MenuItem
              sx={{ color: "text.secondary" }}
              onClick={handleDelete}
              disabled={isSample}
            >
              <ListItemIcon>
                <DeleteOutlineIcon width={24} height={24} />
              </ListItemIcon>
              <ListItemText
                primary={t("Delete")}
                primaryTypographyProps={{ variant: "body2" }}
              />
            </MenuItem>,
          ]}

        {tabing === "expired" && (
          <>
            {logged?.user?.profile?.permission
              ?.interviews_schedule_interviews_edit_interview && [
              <MenuItem
                component={RouterLink}
                to='#'
                sx={{ color: "text.secondary" }}
                onClick={handleEdit}
                disabled={isSample}
              >
                <ListItemIcon>
                  <EditIcon fontSize='medium' width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("Edit")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ]}

            {logged?.user?.profile?.permission
              ?.interviews_expired_interviews_delete_interview && [
              <MenuItem
                sx={{ color: "text.secondary" }}
                onClick={handleDelete}
                disabled={isSample}
              >
                <ListItemIcon>
                  <DeleteOutlineIcon width={24} height={24} />
                </ListItemIcon>
                <ListItemText
                  primary={t("Delete")}
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ]}
          </>
        )}

        {tabing === "awaiting" &&
          (canGiveFeedback() ||
            (canViewFeedback() && [
              <MenuItem
                sx={{ color: "text.secondary" }}
                onClick={handleFeedback}
                disabled={isSample}
              >
                <ListItemIcon>
                  {(canGiveFeedback() && (
                    <RateReviewOutlinedIcon width={24} height={24} />
                  )) ||
                    (canViewFeedback() && (
                      <FeedbackOutlinedIcon width={24} height={24} />
                    ))}
                </ListItemIcon>
                <ListItemText
                  primary={
                    (canGiveFeedback() && t("GiveFeedback")) ||
                    (canViewFeedback() && t("ViewFeedback"))
                  }
                  primaryTypographyProps={{ variant: "body2" }}
                />
              </MenuItem>,
            ]))}

        <MenuItem
          sx={{ color: "text.secondary", display: "none" }}
          onClick={handleViewInterview}
        >
          <ListItemIcon>
            <SimCardDownloadOutlinedIcon width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={t("View Interview")}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Menu>
      <DeleteInterviewModal
        isShowingDeleteInt={isShowingDeleteInt}
        hide={toggleDeleteInt}
        data={matched}
        invoke={type}
        isArc={isArc}
        isSample={isSample}
        confirmAction={handleSampleDelete}
      />
      <ScheduleIntModal
        isShowingScheduleInt={isShowingScheduleInt}
        hide={toggleScheduleInt}
        flag={false}
        data={matched}
      />
      <FollowUpIntModal
        isShowingFollowupInt={isShowingFollowupInt}
        hide={toggleFollowUpInt}
        flag={false}
        data={matched}
      />
      <CandidateHistory
        isShowingCandidateHis={isShowingCandidateHis}
        hide={toggleFeedbackInt}
        data={matched}
      />

      <UpdateStatusModal
        isShowingModal={isShowingStatus}
        hide={toggleShowingStatus}
        intKey={intVuekey}
      />
      <ViewInterviewModal
        isShowingInt={isShowingInt}
        hide={toggleShowingInt}
        intKey={intVuekey}
        data={matched}
      />
    </>
  )
}
