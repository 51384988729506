import React, { useState } from "react"
import { Box, Typography, Button } from "@mui/material"
import { useTranslation } from "react-i18next"

const ParagraphWithReadMore = ({ text, maxLength }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const displayText = expanded ? text : text.slice(0, maxLength)

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Box className='readmore'>
      <Typography component='span'>{displayText}</Typography>
      {text.length > maxLength && (
        <Button
          variant='outlined'
          sx={{ mt: 1 }}
          color='info'
          size='small'
          onClick={toggleExpand}
        >
          {expanded ? t("ReadLess") : t("ReadMore")}
        </Button>
      )}
    </Box>
  )
}

export default ParagraphWithReadMore
