import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  Box,
  InputAdornment,
} from "@mui/material"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  deleteDepartment,
  deleteSelectedDepartments,
} from "src/redux/actions/department"
import { useSelector, useDispatch } from "react-redux"
import RefreshIcon from "@mui/icons-material/Refresh"
import SearchIcon from "@mui/icons-material/Search"
import DeleteIcon from "@mui/icons-material/Delete"
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}))

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "&.Mui-focused": { width: 320, boxShadow: theme.customShadows.z8 },
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    "&.Mui-focused": {
      width: "100%",
    },
  },
}))

// ----------------------------------------------------------------------

DepartmentListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  dept: PropTypes.array,
  departmentIds: PropTypes.array,
}

export default function DepartmentListToolbar({
  numSelected,
  resetSelected,
  filterName,
  onFilterName,
  refresh,
  departmentIds,
  canSearch = true,
}) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: "primary.main",
          bgcolor: "primary.lighter",
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component='div' variant='subtitle1'>
          {numSelected} selected
        </Typography>
      ) : (
        canSearch && (
          <SearchStyle
            value={filterName}
            onChange={onFilterName}
            placeholder={t("Search")}
            startAdornment={
              <InputAdornment position='start'>
                <SearchIcon
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            }
          />
        )
      )}
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          display: "flex",
        }}
      >
        {numSelected > 0 ? (
          <Tooltip title='Delete'>
            <IconButton
              onClick={(e) => {
                resetSelected([])
                dispatch(deleteSelectedDepartments(departmentIds))
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title={t("Refresh")} onClick={refresh}>
              <IconButton>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    </RootStyle>
  )
}
