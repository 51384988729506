export const CHANGEPLAN_ON_SUCCESS = "CHANGEPLAN_ON_SUCCESS";
export const CHANGEPLAN_ON_FAILURE = "CHANGEPLAN_ON_FAILURE";
export const ACTIVATEBILL_ON_SUCCESS = "ACTIVATEBILL_ON_SUCCESS";
export const ACTIVATEBILL_ON_FAILURE = "ACTIVATEBILL_ON_FAILURE";
export const BUYINTERVIEW_ON_SUCCESS = "BUYINTERVIEW_ON_SUCCESS";
export const BUYINTERVIEW_ON_FAILURE = "BUYINTERVIEW_ON_FAILURE";
export const UPGRADESUBSCRIPTION_ON_SUCCESS = "UPGRADESUBSCRIPTION_ON_SUCCESS";
export const UPGRADESUBSCRIPTION_ON_FAILURE = "UPGRADESUBSCRIPTION_ON_FAILURE";
export const PLANS_ON_SUCCESS = "PLANS_ON_SUCCESS";
export const PLANS_ON_FAILURE = "PLANS_ON_FAILURE";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const SUBSCRIPTIONINFO_ON_SUCCESS = "SUBSCRIPTIONINFO_ON_SUCCESS";
export const SUBSCRIPTIONINFO_ON_FAILURE = "SUBSCRIPTIONINFO_ON_FAILURE";
export const CREATESUBSCRIPTION_ON_SUCCESS = "CREATESUBSCRIPTION_ON_SUCCESS";
export const CREATESUBSCRIPTION_ON_FAILURE = "CREATESUBSCRIPTION_ON_FAILURE";
export const CANCELSUBSCRIPTION_ON_SUCCESS = "CANCELSUBSCRIPTION_ON_SUCCESS";
export const CANCELSUBSCRIPTION_ON_FAILURE = "CANCELSUBSCRIPTION_ON_FAILURE";
